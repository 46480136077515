import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import InputV2 from "../../../UIElements/InputV2";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import WIgetError from "../../../Public/UniversalWidgets/WigetError";
import WigetResetPassword from "../WigetResetPassword";
import { emailSignInThunk } from "../../../../slice/general/auth/signIn/emailSignInThunk";

function EmailSignInV2() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [isResetPasswordVisible, setIsResetPasswordVisible] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleOpenResetPassword = () => {
    setIsResetPasswordVisible(true);
  };

  // Обработчик для входа по электронной почте и паролю
  const handleEmailSignIn = async (event) => {
    event.preventDefault();

    dispatch(emailSignInThunk({ email, password }))
      .unwrap()
      .then(({ currentUser }) => {
        navigate("/profile");
      })
      .catch((error) => {
        console.error("Error during registration: ", error.errorMessage);
      });
  };

  // Обработчик изменения значения поля электронной почты
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  // Обработчик изменения значения поля пароля
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flex: "1",
      }}
    >
      {/* Компонент для вывода ошибок */}
      <WIgetError
        error={error}
        onClose={() => setError(null)}
        img="https://firebasestorage.googleapis.com/v0/b/testbikestripster.appspot.com/o/imgStatic%2FErr2.jpg?alt=media&token=98f06522-4e5f-4b94-8879-ef7729b3079f&_gl=1*1ktuklr*_ga*NzE3MzY1ODU1LjE2ODQ3NTEzMTQ.*_ga_CW55HF8NVT*MTY5NzQzODQ5OC40LjEuMTY5NzQzOTQwNi41OC4wLjA."
      />

      {/* Компонент для сброса пароля */}
      <WigetResetPassword
        visible={isResetPasswordVisible}
        onClose={() => setIsResetPasswordVisible(null)}
        img="https://firebasestorage.googleapis.com/v0/b/testbikestripster.appspot.com/o/imgStatic%2FResetPassword3.jpg?alt=media&token=da6d29aa-a729-440e-acea-464eda078e78&_gl=1*1hqxsdd*_ga*NzE3MzY1ODU1LjE2ODQ3NTEzMTQ.*_ga_CW55HF8NVT*MTY5NzQ1MzcyMi42LjEuMTY5NzQ1NDYwNS41Mi4wLjA."
      />

      <form
        autoComplete="off"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: "24px",
          height: "100%",
        }}
        onSubmit={handleEmailSignIn}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
          }}
        >
          {/* Поле электронной почты */}
          <InputV2
            type="email"
            label="Email"
            value={email}
            onChange={handleEmailChange}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              gap: "8px",
            }}
          >
            {/* Поле пароля */}
            <InputV2
              type="password"
              label="Password"
              value={password}
              onChange={handlePasswordChange}
            />

            {/* Ссылка для восстановления пароля */}
            <a
              href="#"
              style={{
                cursor: "pointer",
              }}
              onClick={handleOpenResetPassword}
              className="p-mini"
            >
              Have you forgotten your password?
            </a>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "8px",
            }}
          >
            {/* Соглашение с условиями */}
            <p
              style={{
                textAlign: "center",
              }}
              className="p-mini"
            >
              By creating an account you agree with our &nbsp;
              <Link to="/terms-of-service">Terms of Service</Link>
              ,&nbsp;
              <Link to="/privacy-policy">Privacy Policy</Link>
              ,&nbsp; and our default Notification Settings.
            </p>
          </div>

          {/* Кнопка входа */}
          <button className="popupButton" type="submit">
            Sign In
          </button>

          <p
            style={{
              textAlign: "center",
            }}
            className="p-mini"
          >
            Already have an account? &nbsp;
            <Link to="/login?page=signUp">Sign Up</Link>
          </p>
        </div>
      </form>
    </div>
  );
}

export default EmailSignInV2;
