import React from "react";
import { WigetImageCarousel2 } from "../UniversalWidgets/WigetImageCarousel";
import DateFormat from "../UniversalWidgets/supportFunctions/functionDateFormat";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import WidgetStatistic from "../UniversalWidgets/WidgetStatistic";
import { useSubscribeData } from "../../../services/firebase/crudFirebaseStorage";
import WidgetPopularСomment from "../UniversalWidgets/WidgetPopularСomment";
import Preloader from "../../UIElements/Preloader";
const ReantalPointInform = ({
  rentalPoint,
  rentalPointId,
  commentsData,
  loadingCommentsData,
}) => {
  const isLoading = useSelector((state) => state.stateFrontGUISlice.isLoading);

  const currentBike = useSelector(
    (state) => state.stateFrontGUISlice.currentBike
  );

  return (
    <div className="wigetBikes-content-grid-left-content">
      {isLoading || loadingCommentsData ? (
        <div className="wigetProdictCard-in-box">
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Preloader />
          </div>
        </div>
      ) : (
        <>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gridTemplateRows: "repeat(2, 1fr)",
              padding: "8px",
              gap: "8px",
            }}
          >
            <div
              style={{
                gridColumn: "1",
                gridRow: "span 2",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                borderRadius: "8px",
                gap: "8px",
                padding: "24px",
                boxSizing: "border-box",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <h5>{currentBike?.rentalPoint?.title}</h5>

                <p
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxHeight: "calc(10 * 1.4em)",
                    lineHeight: "1.4em",
                    color: "var(--color-gray-900)",
                  }}
                >
                  {currentBike?.rentalPoint?.description}
                </p>
              </div>
              {currentBike?.rentalPoint?.title[0] && (
                <Link
                  to={`/rentalPoint/${
                    currentBike?.rentalPoint?.title &&
                    currentBike.rentalPoint.title[0].replace(/ /g, "-")
                  }/${currentBike?.rentalPoint?.id}`}
                >
                  <button
                    style={{
                      background: "var(--gradient-primary)",
                      color: "var(--color-white)",
                      height: "32px",
                      border: "0",
                      borderRadius: "80px",
                      padding: "0 16px",
                    }}
                  >
                    Подробнее
                  </button>
                </Link>
              )}
            </div>

            <div
              style={{
                gridColumn: "2",
                gridRow: "1",
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                backgroundColor: "var(--color-priority)",
                minHeight: "150px",
                borderRadius: "16px",
                padding: "16px",
                boxSizing: "border-box",
              }}
            >
              <WidgetStatistic
                rentalPointId={rentalPointId}
                commentsData={commentsData}
                loadingCommentsData={loadingCommentsData}
              />
            </div>

            <div
              style={{
                gridColumn: "2",
                gridRow: "2",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "8px",
                backgroundColor: "var(--color-gray-100)",
                minHeight: "150px",
                borderRadius: "16px",
                padding: "16px",
                boxSizing: "border-box",
              }}
            >
              <WidgetPopularСomment
                commentsData={commentsData}
                rentalPoint={rentalPoint}
              />

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              ></div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ReantalPointInform;
