import { createAsyncThunk } from "@reduxjs/toolkit";
import interpretAuthErrorV2 from "../../../functions/interpretAuthErrorV2";
import { removeFiles } from "../../../services/firebase/crudFirestore";

export const removeFilesThunk = createAsyncThunk(
  "private/files/removeFilesThunk",
  async (
    { collectionName, documentId, fieldName, fileIds },
    { rejectWithValue }
  ) => {
    try {
      await removeFiles(collectionName, documentId, fieldName, fileIds);
      console.log(
        "private/files/removeFilesThunk",
        collectionName,
        documentId,
        fieldName,
        fileIds
      );
      return fileIds; // Возвращаем удаленные fileIds для обновления состояния
    } catch (error) {
      const errorMessage = interpretAuthErrorV2(error.code);
      return rejectWithValue({ errorMessage });
    }
  }
);
