import React, { useEffect } from "react";
import "./popup.css";
const PopupDropdownList = ({ isOpen, onClose, children, title }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className="popup__content-box">
      {/* <div className="popup__button-bar">
        <div className="popup__button--close" onClick={onClose}>
          <span className="material-symbols-outlined">close</span>
        </div>
      </div> */}

      <div className="popup-dropdown-list__content">
        <div className="popup__header">
          <h5>{title}</h5>
          <div className="popup__button--close" onClick={onClose}>
            <span className="material-symbols-outlined">close</span>
          </div>
        </div>

        <div className="popup__options">{children}</div>
      </div>
    </div>
  );
};

export default PopupDropdownList;
