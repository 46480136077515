import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchData } from "../../services/firebase/crudFirebaseStorage";

export const fetchRentalPointsData = createAsyncThunk(
  "bike/rentalPoints/front",
  async () => {
    try {
      // Используем setTimeout для добавления задержки в 1 секунду
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // После задержки получаем данные о велосипедах с помощью функции fetchData из Firebase
      const rentalPointsData = await fetchData("rentalPoints");
      console.log("rentalPointsData ✅", rentalPointsData);

      // Возвращаем полученные данные
      return rentalPointsData;
    } catch (error) {
      // Если произошла ошибка при получении данных, выбрасываем ошибку с сообщением
      throw new Error(
        `Ошибка при получении данных rentalPointsData: ${error.message}`
      );
    }
  }
);
