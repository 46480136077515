// Импорт компонентов и хуков из библиотек и модулей React
import SearchBars from "../../../../../UIComponents/bars/searchBars/SearchBars";
import Icon44 from "../../../../../UIComponents/icons/Icon44";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import ImageWithLoader from "../../../../../UIComponents/bars/gallery/ImageWithLoader";
import {
  setPrivateSideBar,
  setCurrentRentalPoint,
} from "../../../../../../slice/stateGUISlice";

// Получение API-ключа Google Maps из переменных окружения
const googleAPIKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

// Компонент WigetBikesRentalPointList
const WigetBikesRentalPointList = () => {
  const dispatch = useDispatch();

  const [selectedItemIndex, setSelectedItemIndex] = useState(null);

  // Получение данных о пользователе
  const currentUser = useSelector((state) => state.userSliceV2.currentUser);
  const role = currentUser ? currentUser.role : null;
  const isLoading = useSelector((state) => state.userSliceV2.isLoading);
  const userId = currentUser ? currentUser.uid : null;

  // Локальное состояние компонента (Исключая точки проката с нулевыми координатами)
  const [
    filteredCurrentUsersRentalPoints,
    setFilteredCurrentUsersRentalPoints,
  ] = useState(null);

  // Получение данных о точках аренды из Redux-стейта
  const currentUsersRentalPoints = useSelector(
    (state) => state.stateGUISlice.currentUsersRentalPoints
  );
  const idCurrentRentalPoint = useSelector(
    (state) => state.stateGUISlice.currentRentalPoint.id
  );

  // Эффект для фильтрации точек аренды с нулевыми координатами
  useEffect(() => {
    const filteredPoints = currentUsersRentalPoints?.filter(
      (point) => point?.pointAddress?.location?.lat !== null
    );
    setFilteredCurrentUsersRentalPoints(filteredPoints);
  }, [currentUsersRentalPoints]);

  // Эффект для управления активной точкой аренды
  useEffect(() => {
    if (currentUsersRentalPoints && currentUsersRentalPoints?.length > 0) {
      // Если выбранная точка аренды уже сохранена, установите её
      const selectedPoint = currentUsersRentalPoints?.find(
        (point) => point.id === idCurrentRentalPoint
      );

      if (selectedPoint) {
        dispatch(setCurrentRentalPoint(selectedPoint));
      } else {
        // В противном случае, установите первую точку аренды
        dispatch(setCurrentRentalPoint(currentUsersRentalPoints[0]));
      }
    }
  }, [currentUsersRentalPoints, idCurrentRentalPoint, dispatch]);

  // Функция для формирования URL статической карты Google Maps
  const MapUrl = (location) => {
    if (!location || !location?.lat || !location?.lng) {
      // Обработка ситуации, когда location не определено или не содержит нужных свойств
      return null;
    }

    // Смещение центральных координат для изображения
    const centerShiftX = 0;
    const shiftedCenterCoordinates = {
      lat: location.lat,
      lng:
        location.lng +
        centerShiftX / (111320 * Math.cos((location.lat * Math.PI) / 180)),
    };

    // Параметры карты
    const zoomMap = 14;
    const width = 400;
    const aspectRatioWidth = 16; // соотношение сторон ширина
    const aspectRatioHeight = 9; // соотношение сторон высриа

    const height = (width * aspectRatioHeight) / aspectRatioWidth;

    const sizeMapPrewiew = `${width}x${Math.round(height)}`;
    const style = "feature:all|element:labels|visibility:off";

    // Составление URL карты
    const imageUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${shiftedCenterCoordinates.lat},${shiftedCenterCoordinates.lng}&zoom=${zoomMap}&size=${sizeMapPrewiew}&maptype=roadmap&markers=${location.lat},${location.lng}&format=png&style=${style}&key=${googleAPIKey}`;

    // Возвращаем URL
    return imageUrl;
  };

  const currentIndex = () => {
    const index = filteredCurrentUsersRentalPoints?.findIndex(
      (bike) => bike.id === idCurrentRentalPoint
    );
    if (index !== -1) {
      console.log(
        `Элемент с ID ${idCurrentRentalPoint} найден, его индекс: ${index}`
      );
    } else {
      console.log(`Элемент с ID ${idCurrentRentalPoint} не найден в массиве.`);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        // height: 'calc(100vh - 56px)',
        height: "100%",
        width: "100%",
        boxSizing: "border-box",
        backgroundColor: "var(--color-white)",
        // borderRadius: '8px',
        overflow: "hidden",
      }}
    >
      {/* Компонент SearchBars для поиска */}
      <SearchBars
        title="Rental points"
        iconLeft={
          <Icon44
            click={() => dispatch(setPrivateSideBar(true))}
            icon={
              <span className="material-symbols-outlined">dock_to_right</span>
            }
          />
        }
        iconRight={<div />}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          gap: "0",
        }}
        className="scrollBox"
      >
        {filteredCurrentUsersRentalPoints &&
          filteredCurrentUsersRentalPoints?.length > 0 &&
          filteredCurrentUsersRentalPoints?.map((rentalPointItem, index) => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                padding: "0 8px",
                boxSizing: "border-box",
              }}
              key={rentalPointItem.id}
              onClick={() => {
                dispatch(setCurrentRentalPoint(rentalPointItem));
                setSelectedItemIndex(index);
                currentIndex();
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  gap: "16px",
                  backgroundColor:
                    idCurrentRentalPoint === rentalPointItem?.id ||
                    index === selectedItemIndex
                      ? "var(--color-primary)"
                      : "var(--color-white)",
                  borderRadius: "8px",
                }}
                className="itemScroll"
              >
                <div
                  style={{
                    width: "100px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0 0 0 8px",
                  }}
                >
                  <div
                    style={{
                      width: "80px",
                      aspectRatio: "1/1",
                      borderRadius: "24px",
                      overflow: "hidden",
                      boxSizing: "border-box",
                    }}
                  >
                    <ImageWithLoader
                      url={rentalPointItem?.file_logo}
                      alt={"PreviewRentalPoint"}
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                    height: "100px",
                    borderBottom:
                      idCurrentRentalPoint === rentalPointItem?.id ||
                      index === selectedItemIndex ||
                      index === selectedItemIndex - 1
                        ? "0px var(--color-gray-200) solid"
                        : "1px var(--color-gray-200) solid",
                    color:
                      (idCurrentRentalPoint === rentalPointItem?.id ||
                        index === selectedItemIndex) &&
                      "var(--color-white)",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "center",
                    }}
                  >
                    <h5
                      style={{
                        color:
                          (idCurrentRentalPoint === rentalPointItem?.id ||
                            index === selectedItemIndex) &&
                          "var(--color-white)",
                      }}
                    >
                      {rentalPointItem?.title[0]
                        ? rentalPointItem?.title
                        : "No name"}
                    </h5>
                    <p
                      style={{
                        color:
                          (idCurrentRentalPoint === rentalPointItem.id ||
                            index === selectedItemIndex) &&
                          "var(--color-white)",
                      }}
                      className="p-light"
                    >
                      Quantity bike: {rentalPointItem?.bikes_id.length}
                    </p>
                  </div>
                  <span className="material-symbols-outlined">
                    chevron_right
                  </span>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

// Экспорт компонента WigetBikesRentalPointList
export default WigetBikesRentalPointList;
