import React, { useState } from "react";
import PopupDirrectory from "../../../Private/Login/Profile/WidgetsProfile/Popups/PopupDirrectory";
import WindowCalendar from "./WindowCalendar";

const DateInterval = ({ mode, label, description, dates }) => {
  const [isOpenCalendar, setIsOpenCalendar] = useState(false);

  console.log("DateInterval dates", dates);

  const handleOpenCalendar = () => {
    setIsOpenCalendar(true);
  };

  const handleClose = () => {
    setIsOpenCalendar(false);
  };

  return (
    <>
      <div
        className="profile-widget__content-box"
        style={{
          gap: "8px",
        }}
      >
        {label && (
          <div
            style={{
              width: "100%",
            }}
          >
            <p>{label}</p>
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "8px",
            backgroundColor: "var(--color-gray-100)",
            borderRadius: "8px",
            cursor: "pointer",
            height: "56px",
            width: "100%",
          }}
          onClick={handleOpenCalendar}
        >
          <>
            <div
              style={{
                display: "flex",
                flex: "1",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "8px 16px",
                gap: "8px",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "8px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "4px",
                  }}
                >
                  <p>
                    {dates[0]
                      ? new Date(dates[0]).getDate()
                      : "Date not selected"}
                  </p>
                  <p>
                    {dates[0]
                      ? new Date(dates[0]).toLocaleString("en-US", {
                          month: "long",
                        })
                      : "Month"}
                  </p>
                </div>
                <p>—</p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "4px",
                  }}
                >
                  <p>
                    {dates[1]
                      ? new Date(dates[1]).getDate()
                      : "Date not selected"}
                  </p>

                  <p>
                    {dates[1]
                      ? new Date(dates[1]).toLocaleString("en-US", {
                          month: "long",
                        })
                      : "Month"}
                  </p>
                </div>
              </div>

              <span className="material-symbols-outlined">chevron_right</span>
            </div>
          </>
        </div>
        {description && (
          <div
            style={{
              width: "100%",
            }}
          >
            <p
              style={{
                width: "70%",
              }}
              className="p-light"
            >
              {description}
            </p>
          </div>
        )}
      </div>

      <PopupDirrectory
        isOpen={isOpenCalendar}
        onClose={handleClose}
        title="Specify the date of your reservation"
      >
        <WindowCalendar mode={mode} onClose={handleClose} />
      </PopupDirrectory>
    </>
  );
};

export default DateInterval;
