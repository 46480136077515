import React, { useState } from "react";
import Preloader from "../../UIElements/Preloader";
import { Link } from "react-router-dom";
import PopupWindow from "../PublickWidgets/Windows/PopupWindow";
import WindowLogIn from "./WindowLogIn";

const WidgetNavigateUser = ({ isLoading, role, onClose }) => {
  const [windowAuth, setWindowAuth] = useState(false);

  const handleOpenWindowAuth = () => {
    setWindowAuth(true);
  };

  const handleClose = () => {
    setWindowAuth(false);
  };
  return (
    <>
      <div
        style={{
          gridColumn: "1 / span 3",
          gridRow: "1 / span 1",
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gap: "8px",
          backgroundColor: "var(--color-gray-100)",
          borderRadius: "16px",
        }}
      >
        {isLoading ? (
          <Preloader design="white" />
        ) : role ? (
          <div
            style={{
              backgroundColor: "var(--color-gray-100)",
              aspectRatio: "1/1",
              gridColumn: "1 / span 1",
              gridRow: "1 / span 1",
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              padding: "16px",
              borderRadius: "16px",
            }}
          >
            <Link to="/profile" onClick={onClose}>
              <h5>Profile</h5>
            </Link>
            {/* <h5 onClick={handleOpenWindowAuth}>Auth</h5> */}
          </div>
        ) : (
          <div
            style={{
              backgroundColor: "var(--color-gray-100)",
              aspectRatio: "1/1",
              gridColumn: "1 / span 1",
              gridRow: "1 / span 1",
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              padding: "16px",
              borderRadius: "16px",
            }}
          >
            <h5 onClick={handleOpenWindowAuth}>Sign In</h5>
            <h5 onClick={handleOpenWindowAuth}>Sign Up</h5>
            {/* <Link to="/login?page=signIn" onClick={onClose}>
              <h5>Sign In</h5>
            </Link>
            <Link to="/login?page=signUp" onClick={onClose}>
              <h5>Sign Up</h5>
            </Link> */}
          </div>
        )}
      </div>

      <PopupWindow isOpen={windowAuth} onClose={handleClose} title="">
        <WindowLogIn />
      </PopupWindow>
    </>
  );
};

export default WidgetNavigateUser;
