import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { calculateBikeCost } from "../../../functions/calculateBikeCost";
import Voucher from "./Voucher";
import Preloader from "../../UIElements/Preloader";
import WigetSearch from "../UniversalWidgets/WigetSearch";

const FixedBlock = () => {
  const [bikeCostDetails, setBikeCostDetails] = useState(null);

  console.log("FixedBlock-bikeCostDetails", bikeCostDetails);

  const selectedAdditioanlServices = useSelector(
    (state) => state.stateFrontGUISlice.selectedAdditioanlServices
  );

  const currentBike = useSelector(
    (state) => state.stateFrontGUISlice.currentBike
  );

  const currentDateInterval = useSelector(
    (state) => state.stateFrontGUISlice.currentDateInterval
  );

  const quantityBike = useSelector(
    (state) => state.stateFrontGUISlice.quantityBike
  );

  useEffect(() => {
    if (
      currentDateInterval &&
      currentBike?.rate &&
      quantityBike &&
      selectedAdditioanlServices
    ) {
      setBikeCostDetails(
        calculateBikeCost(
          currentDateInterval,
          currentBike?.rate,
          quantityBike,
          selectedAdditioanlServices
        )
      );
    }
  }, [
    currentDateInterval,
    currentBike,
    quantityBike,
    selectedAdditioanlServices,
  ]);

  // Средняя скидка за период
  const averageDiscountRounded = parseFloat(
    bikeCostDetails?.averageDiscount.toFixed(2)
  );

  // Стоимость ваучера
  const voucherPrice = bikeCostDetails?.voucherPrice?.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });

  // Стоимость со скидкой
  const discountedVoucherPrice =
    bikeCostDetails?.discountedVoucherPrice?.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });

  // Общая стоимость аренды
  const totalPrice = bikeCostDetails?.totalPrice?.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });

  // Общая стоимость аренды со скидкой
  const discountedTotalPrice =
    bikeCostDetails?.discountedTotalPrice?.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });

  // Оплата в точке проката
  const paymentAtTheRentalPoint =
    bikeCostDetails?.paymentAtTheRentalPoint?.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });

  // Оплата в точке проката со скидкой
  const discountedPaymentAtTheRentalPoint =
    bikeCostDetails?.discountedPaymentAtTheRentalPoint?.toLocaleString(
      "en-US",
      {
        style: "currency",
        currency: "USD",
      }
    );

  // Полная экономия благодаря скидке
  const priceBenefit = bikeCostDetails?.priceBenefit.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });

  // состояние загрузки данных
  const isLoading = useSelector((state) => state.stateFrontGUISlice.isLoading);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleReservationClick = () => {
    // Open the modal
    setIsModalOpen(true);
  };

  const filterResultsSlice = useSelector(
    (state) => state.filterResultsSlice.data
  );

  const foundItem = filterResultsSlice.find(
    (item) => item.bike.id === currentBike.id
  );

  const priorityIcon = currentBike?.category?.file_icon?.find(
    (icon) => icon.priority === true
  );
  const priorityIconUrl = priorityIcon?.url || "";

  // СКРОЛЛ

  const [isTransform, setIsTransform] = useState(false);
  const [quantityBikes, setQuantityBikes] = useState(0);

  const handleScroll = () => {
    const scrollThreshold = 600; // Уровень скролла, после которого блок будет показан

    if (window.scrollY > scrollThreshold) {
      setIsTransform(true);
    } else {
      setIsTransform(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const quantityBikes = localStorage.getItem("quantityBikes");

    if (quantityBikes) {
      setQuantityBikes(quantityBikes);
    }
  }, [currentBike]);

  const handleDecreaseQuantity = () => {
    if (quantityBikes > 0) {
      setQuantityBikes(parseInt(quantityBikes) - 1);
      localStorage.setItem("quantityBikes", parseInt(quantityBikes) - 1);
    }
  };

  const handleIncreaseQuantity = () => {
    setQuantityBikes(parseInt(quantityBikes) + 1);
    localStorage.setItem("quantityBikes", parseInt(quantityBikes) + 1);
  };

  return (
    <>
      <div
        className={`wigetProductList-price-contaiber ${
          isTransform ? "transform" : ""
        }`}
      >
        {currentBike && (
          <>
            <div className="wigetProdictCard-price-item">
              <div className="wigetProdictCard-payToRental">
                <WigetSearch />
                <Voucher
                  bikeCostDetails={bikeCostDetails}
                  currentBike={currentBike}
                  quantityBike={quantityBike}
                />
                <div className="wigetProdictCard-inform">
                  <div
                    // className="wigetProdictCard-price-payToRental"
                    style={{
                      // padding: "8px",
                      height: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2, 1fr)",
                        gap: "8px",
                        height: "100%",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "var(--color-gray-100)",
                          padding: "16px",
                          borderRadius: "16px",
                          gridColumn: "2",
                          gridRow: "1",
                          ...(bikeCostDetails?.priceBenefit > 0
                            ? {
                                gridColumn: "2",
                                gridRow: "1",
                                aspectRatio: "1/1",
                              }
                            : {
                                gridColumn: "span 2",
                                gridRow: " 1",
                                height: "153px",
                              }),
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          justifyContent: "space-between",
                          gap: "4px",
                          boxSizing: "border-box",
                        }}
                      >
                        {isLoading ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "100%",
                              height: "100%",
                            }}
                          >
                            <Preloader />
                          </div>
                        ) : (
                          <>
                            <p className="p-light">Total price</p>
                            <div>
                              <h5>{discountedTotalPrice}</h5>
                              {totalPrice && discountedTotalPrice && (
                                <p
                                  className="p-light"
                                  style={{
                                    textDecoration: "line-through",
                                    color: "var(--color-gray-700)",
                                  }}
                                >
                                  {totalPrice}
                                </p>
                              )}
                            </div>
                          </>
                        )}
                      </div>

                      <div
                        style={{
                          backgroundColor: "var(--color-gray-100)",
                          padding: "16px",
                          borderRadius: "16px",
                          ...(bikeCostDetails?.priceBenefit > 0
                            ? {
                                gridColumn: "2",
                                gridRow: "2",
                                aspectRatio: "1/1",
                              }
                            : {
                                gridColumn: "span 2",
                                gridRow: " 2",
                                height: "153px",
                              }),
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          justifyContent: "space-between",
                          gap: "4px",
                          boxSizing: "border-box",
                        }}
                      >
                        {isLoading ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "100%",
                              height: "100%",
                            }}
                          >
                            <Preloader />
                          </div>
                        ) : (
                          <>
                            <p className="p-light">Pay on arrival</p>
                            <div>
                              <h5>{discountedPaymentAtTheRentalPoint}</h5>
                              {paymentAtTheRentalPoint &&
                                discountedPaymentAtTheRentalPoint && (
                                  <p
                                    className="p-light"
                                    style={{
                                      textDecoration: "line-through",
                                      color: "var(--color-gray-700)",
                                    }}
                                  >
                                    {paymentAtTheRentalPoint}
                                  </p>
                                )}
                            </div>
                          </>
                        )}
                      </div>
                      {bikeCostDetails?.priceBenefit > 0 && (
                        <div
                          style={{
                            // backgroundColor: "var(--color-white)",
                            padding: "16px",
                            borderRadius: "16px",
                            gridColumn: "1",
                            gridRow: "span 2",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                            gap: "4px",
                            backgroundColor: "var(--color-gray-100)",
                            // background:
                            //   "linear-gradient(45deg, #FB5085 0%, #C740CA 30%, #3478FF 100%)",
                            color: "var(--color-white)",
                            overflow: "hidden",
                          }}
                        >
                          {isLoading ? (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "100%",
                                height: "100%",
                              }}
                            >
                              <Preloader />
                            </div>
                          ) : (
                            <>
                              <p
                                className="p-light"
                                // style={{ color: "var(--volor-white)" }}
                              >
                                Benefit:
                              </p>

                              <h5
                                style={
                                  {
                                    // color: "var(--color-white)",
                                  }
                                }
                              >
                                {priceBenefit}
                              </h5>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* {isModalOpen && (
              <WigetReservation
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                handleIncreaseQuantity={handleIncreaseQuantity}
                handleDecreaseQuantity={handleDecreaseQuantity}
              />
            )} */}
          </>
        )}
      </div>
    </>
  );
};

export default FixedBlock;
