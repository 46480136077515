// export const convertDate = (currentDateInterval) => {
//   // Проверяем, что массив содержит два элемента
//   if (currentDateInterval.length !== 2) {
//     throw new Error("The currentDateInterval array must have two elements.");
//   }

//   // Создаем объекты Date из миллисекунд UNIX времени
//   const startDate = new Date(currentDateInterval[0]);
//   const endDate = new Date(currentDateInterval[1]);

//   // Форматируем даты в формат 'en-US'
//   const startDay = startDate.toLocaleDateString("en-US", {
//     day: "2-digit",
//     month: "2-digit",
//     year: "numeric",
//   });
//   const endDay = endDate.toLocaleDateString("en-US", {
//     day: "2-digit",
//     month: "2-digit",
//     year: "numeric",
//   });

//   // Возвращаем объект с начальной и конечной датой
//   return { startDay, endDay };
// };

export const dateFormat = (timestamp) => {
  return new Date(timestamp).toLocaleDateString("en-US", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};
