import React from "react";

const OrderDateInterval = ({ orderDetail, title, description }) => {
  // Извлекаем временные метки начала и конца из данных заказа
  const startTimestamp =
    orderDetail?.order_info?.dateInterval[0]?.seconds * 1000;
  const endTimestamp = orderDetail?.order_info?.dateInterval[1]?.seconds * 1000;

  // Получаем год начала и конца диапазона
  const startYear = new Date(startTimestamp).getFullYear();
  const endYear = new Date(endTimestamp).getFullYear();

  // Функция для получения всех месяцев для каждого года в диапазоне
  const getMonthsInYear = (startYear, endYear) => {
    const monthsInYear = {};
    for (let year = startYear; year <= endYear; year++) {
      monthsInYear[year] = Array.from({ length: 12 }, (_, i) => i); // Создаем массив месяцев от 0 до 11 (январь - декабрь)
    }
    return monthsInYear;
  };

  // Получаем объект, содержащий массив месяцев для каждого года в диапазоне
  const monthsInYear = getMonthsInYear(startYear, endYear);

  // Функция для получения всех дней в указанном месяце и годе
  const getDaysInMonth = (year, month) => {
    const date = new Date(year, month, 1); // Устанавливаем дату на первое число месяца
    const days = [];
    while (date.getMonth() === month) {
      days.push(new Date(date)); // Добавляем каждый день месяца в массив
      date.setDate(date.getDate() + 1); // Переходим к следующему дню
    }
    return days;
  };

  // Массив для хранения информации о каждом месяце в диапазоне
  const months = [];
  let currentMonth = new Date(startTimestamp);

  // Заполняем массив months информацией о каждом месяце в диапазоне
  while (currentMonth.getTime() <= endTimestamp) {
    months.push({
      year: currentMonth.getFullYear(),
      month: currentMonth.getMonth(),
    });

    // Переходим к следующему месяцу
    currentMonth.setMonth(currentMonth.getMonth() + 1);

    // Устанавливаем первое число месяца, чтобы избежать возможных проблем с переходом на следующий год
    currentMonth.setDate(1);
  }

  // Массив дней недели для отображения в календаре
  const weekdays = ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "0 16px",
        boxSizing: "border-box",
      }}
    >
      <div
        style={{
          backgroundColor: "var(--color-white)",
          boxSizing: "border-box",
          borderRadius: "8px",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            height: "48px",
            padding: "0 16px",
            borderBottom: "1px var(--color-gray-200) solid",
          }}
        >
          <p
            style={{
              textTransform: "uppercase",
            }}
            className="p-light"
          >
            {title}
          </p>
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(350px, 1fr))",
            gap: "4px",
            overflow: "hidden",
            padding: "16px",
          }}
        >
          {months.map(({ year, month }, index) => {
            const days = getDaysInMonth(year, month);
            const firstDayOfMonth = new Date(year, month, 1).getDay();
            const emptyCells = [];

            // Заполняем пустые ячейки для выравнивания по дням недели
            for (let i = 0; i < (firstDayOfMonth || 7) - 1; i++) {
              emptyCells.push(
                <div
                  key={`empty-${i}`}
                  style={{
                    padding: "8px 0",
                  }}
                />
              );
            }

            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
                key={index}
              >
                <p
                  style={{
                    fontWeight: "600",
                  }}
                >
                  {new Date(year, month).toLocaleString("default", {
                    month: "long",
                  })}{" "}
                  {year}
                </p>

                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(7, 1fr)",
                    gap: "4px",
                  }}
                >
                  {weekdays.map((weekday, idx) => (
                    <div
                      key={idx}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        aspectRatio: "1/1",
                        fontWeight: "600",
                      }}
                    >
                      {weekday
                        ? weekday.charAt(0).toUpperCase() +
                          weekday.slice(1).toLowerCase()
                        : ""}
                    </div>
                  ))}

                  {emptyCells}

                  {days.map((day) => {
                    // Определение начала и конца каждого дня для сравнения с диапазоном
                    const startOfDay = new Date(
                      day.getFullYear(),
                      day.getMonth(),
                      day.getDate()
                    ).setHours(0, 0, 0, 0);
                    const endOfDay = new Date(
                      day.getFullYear(),
                      day.getMonth(),
                      day.getDate()
                    ).setHours(23, 59, 59, 999);

                    // Проверяем, попадает ли день в диапазон
                    const isInRange =
                      startOfDay <= endTimestamp && endOfDay >= startTimestamp;

                    return (
                      <div
                        key={`${day.getFullYear()}-${
                          day.getMonth() + 1
                        }-${day.getDate()}`}
                        style={{
                          padding: "8px",
                          aspectRatio: "1/1",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                          borderRadius: "8px",
                          ...(isInRange
                            ? {
                                backgroundColor: "var(--color-success)",
                                fontWeight: "600",
                                color: "var(--color-white)",
                              }
                            : {
                                backgroundColor: "transparent",
                                fontWeight: "300",
                                fontSize: "1rem",
                              }),
                        }}
                      >
                        {day.getDate()}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {description && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "8px 16px",
          }}
        >
          <p className="p-light">{description}</p>
        </div>
      )}
    </div>
  );
};

export default OrderDateInterval;
