import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchData } from "../../services/firebase/crudFirebaseStorage";

export const fetchStoriesData = createAsyncThunk(
  "stories/storiesData/front",
  async () => {
    try {
      // Используем setTimeout для добавления задержки в 1 секунду
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // После задержки получаем данные о велосипедах с помощью функции fetchData из Firebase
      const stories = await fetchData("stories");
      console.log("stories ✅", stories);

      // Возвращаем полученные данные
      return stories;
    } catch (error) {
      // Если произошла ошибка при получении данных, выбрасываем ошибку с сообщением
      throw new Error(
        `Ошибка при получении данных storiesData: ${error.message}`
      );
    }
  }
);

// import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import { fetchData } from "../services/firebase/crudFirebaseStorage";

// // Создаем async thunk для получения данных о странах
// export const fetchStoriesData = createAsyncThunk(
//   "bike/fetchStoriesData/front",
//   async () => {
//     try {
//       const storiesData = await fetchData("stories");
//       console.log("storiesData🍊", storiesData);
//       return storiesData;
//     } catch (error) {
//       throw new Error(`Error fetching stories data: ${error.message}`);
//     }
//   }
// );

// // Создаем начальное состояние для кеширования данных о странах
// const initialState = {
//   data: [],
//   isLoading: false,
//   error: null,
// };

// // Создаем slice для управления данными о странах
// const storiesSlice = createSlice({
//   name: "stories",
//   initialState,
//   reducers: {},

//   extraReducers: (builder) => {
//     builder
//       .addCase(fetchStoriesData.pending, (state) => {
//         state.isLoading = true;
//       })
//       .addCase(fetchStoriesData.fulfilled, (state, action) => {
//         state.isLoading = false;
//         state.data = action.payload; // Обновляем данные в состоянии при успешном получении
//       })
//       .addCase(fetchStoriesData.rejected, (state, action) => {
//         state.isLoading = false;
//         state.error = action.error.message; // Обновляем ошибку, если запрос не удался
//       });
//   },
// });

// export default storiesSlice.reducer;
