import React, { useEffect, useState } from "react";
import InputText from "../WidgetsProfile/Inputs/InputText";
import Textarea from "../WidgetsProfile/Textarea/Textarea";
import Button from "../WidgetsProfile/Buttons/Button";
import { useSelector, useDispatch } from "react-redux";

import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import {
  setNewBikeBasicInformation,
  setSelectedBikeBasicInformation,
} from "../../../../../reducersToolkit/bikesSliceV2";
import ButtonsBar from "../WidgetsProfile/ButtonsBar/ButtonsBar";

const WindowBikeBasicInformation = ({ onClose, mode }) => {
  const dispatch = useDispatch();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const { newBike, selectedBike } = useSelector((state) => state.bikesSliceV2);

  useEffect(() => {
    if (mode === "new") {
      setTitle(newBike?.basicInformation?.title?.titleOriginal);
      setDescription(
        newBike?.basicInformation.description?.descriptionOriginal
      );
    } else if (mode === "edit") {
      setTitle(selectedBike?.basicInformation?.title?.titleOriginal);
      setDescription(
        selectedBike?.basicInformation?.description?.descriptionOriginal
      );
    }
  }, [mode, newBike, selectedBike]);

  const handleConfirm = () => {
    if (mode === "new") {
      dispatch(
        setNewBikeBasicInformation({
          title: title,
          description: description,
        })
      );
    } else if (mode === "edit") {
      dispatch(
        setSelectedBikeBasicInformation({
          title: title,
          description: description,
        })
      );
    }
    onClose();
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "8px",
          paddingBottom: "72px",
        }}
      >
        <ContentBox position="start">
          <InputText
            label="Bike name"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            value={title}
            onChange={(event) => setTitle(event.target.value)}
          />

          <Textarea
            label="Bike description"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            maxLength={1000}
            value={description}
            onChange={(event) => setDescription(event.target.value)}
          />
        </ContentBox>
      </div>
      <ButtonsBar>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "16px",
            width: "100%",
          }}
        >
          <Button
            type="small"
            label="Cancel"
            active={true}
            color="--color-black"
            onClick={onClose}
          />
          <Button
            type="small"
            label="Apply"
            active={true}
            onClick={handleConfirm}
          />
        </div>
      </ButtonsBar>
    </>
  );
};

export default WindowBikeBasicInformation;
