import React, { useState, useEffect, useCallback } from "react";
import {
  uploadFile,
  subscribeDocumentId,
  deleteFiles,
  updateDocumentById,
} from "../../../../services/firebase/crudFirebaseStorage";
import Button from "../../../UIElements/Button";
import Popup from "../../../UIElements/Popup";
// import WigetButtonAdd from "./WigetButtonAdd";
import ContextMenu from "../../../UIElements/ContextualMenus";
import Checkbox from "../../../UIElements/Checkbox";
// import { SegmentedControl } from "../UIElements/SegmentedControl";
// import WigetButtonDelete from "./WigetButtonDelete";
import Loading from "../../../UIElements/Loading";
import { useAlert } from "../../../UIElements/Alerts";
import ImageWithLoader from "./ImageWithLoader";

const PreviewGallery = ({
  collectionName,
  idDocument,
  fieldName,
  title,
  gallery,
  description,
  aspectRatio,
}) => {
  const [imageURLs, setImageURLs] = useState([]);
  const [file, setFile] = useState(null);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [selectedFileIds, setSelectedFileIds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fileToDelete, setFileToDelete] = useState(null);

  const { showAlert } = useAlert();

  const toggleCheckboxes = () => {
    setSelectedFileIds((prevIds) => {
      if (prevIds.length === imageURLs.length) {
        return [];
      } else {
        return imageURLs.map((image) => image.id);
      }
    });
  };

  // Подписка на обновления документа и обновление состояния imageURLs и isLoading
  useEffect(() => {
    setLoading(true);
    if (idDocument) {
      const unsubscribe = subscribeDocumentId(
        collectionName,
        idDocument,
        (data) => {
          if (data && data[fieldName]) {
            setImageURLs(data[fieldName]);
            setLoading(false);
          } else {
            setLoading(false);
          }
        }
      );
      return () => unsubscribe();
    }
  }, [idDocument, collectionName, fieldName]);

  // Управление открытием и закрытием попапа
  const handleOpenPopup = useCallback(() => setPopupOpen(true), []);
  const handleClosePopup = useCallback(() => setPopupOpen(false), []);

  // Обработчик изменения файла для загрузки
  const handleFileChange = useCallback((event) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile && uploadedFile.type.startsWith("image/")) {
      setFile(uploadedFile);
    } else {
      alert("В этом разделе доступна загрузка только изображений");
    }
  }, []);

  // Загрузка файла
  const handleFileUpload = useCallback(
    async (event) => {
      setLoading(true);
      event.preventDefault();
      if (file) {
        await uploadFile(collectionName, idDocument, fieldName, file);
        setFile(null);
      }
      setLoading(false);
    },
    [file, collectionName, idDocument, fieldName]
  );

  // Удаление выбранных файлов
  const handleDeleteSelectedFiles = useCallback(async () => {
    if (selectedFileIds.length > 0) {
      showAlert("Вы уверены, что хотите удалить выбранные файлы?", async () => {
        try {
          setLoading(true);
          await deleteFiles(
            collectionName,
            [idDocument],
            fieldName,
            selectedFileIds
          );
          setSelectedFileIds([]);
          setLoading(false);
        } catch (error) {
          console.error("Ошибка при удалении выбранных файлов:", error);
          setLoading(false);
        }
      });
    }
  }, [
    selectedFileIds,
    collectionName,
    idDocument,
    fieldName,
    setLoading,
    setSelectedFileIds,
    showAlert,
  ]);

  // Удаление отдельного файла
  const handleDeleteFile = useCallback(
    async (fileId) => {
      setFileToDelete(fileId); // Устанавливаем файл, который удаляется
      setLoading(true);
      await deleteFiles(collectionName, [idDocument], fieldName, [fileId]);
      setLoading(false);
      setFileToDelete(null); // Обнуляем файл, который удалялся
    },
    [collectionName, idDocument, fieldName]
  );

  // Удаление всех файлов
  const handleDeleteAllFiles = useCallback(async () => {
    showAlert("Вы уверены, что хотите удалить все файлы?", async () => {
      try {
        setLoading(true);
        const allIds = imageURLs.map(({ id }) => id);
        await deleteFiles(collectionName, [idDocument], fieldName, allIds);
        setSelectedFileIds([]);
        setLoading(false);
      } catch (error) {
        console.error("Ошибка при удалении файлов:", error);
        setLoading(false);
      }
    });
  }, [
    imageURLs,
    collectionName,
    idDocument,
    fieldName,
    setLoading,
    setSelectedFileIds,
  ]);

  // Обработчик изменения состояния чекбоксов для выбора файлов для удаления
  // Adjust the handleCheckboxChange function in PreviewGallery
  const handleCheckboxChange = useCallback((id) => {
    setSelectedFileIds((prevIds) => {
      if (prevIds.includes(id)) {
        return prevIds.filter((prevId) => prevId !== id);
      } else {
        return [...prevIds, id];
      }
    });
  }, []);

  // Обновление приоритета файла
  const handleUpdatePriority = useCallback(
    async (fileId) => {
      const newData = imageURLs.map((file) => ({
        ...file,
        priority: file.id === fileId ? !file.priority : false,
      }));

      await updateDocumentById(
        { [fieldName]: newData },
        collectionName,
        idDocument
      );
    },
    [imageURLs, fieldName, collectionName, idDocument]
  );

  // Отображение картинок в зависимости от выбранной галереи и их приоритетности
  const displayImages = [...imageURLs]
    .sort((a, b) => b.priority - a.priority)
    .slice(0, gallery ? 8 : 1);

  return (
    <>
      {/* <WigetInformer
      // title={title}
      // description={description}
      > */}
      {gallery ? (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "0 16px",
              boxSizing: "border-box",
            }}
          >
            <div
              style={{
                backgroundColor: "var(--color-white)",
                boxSizing: "border-box",
                borderRadius: "8px",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height: "48px",
                  padding: "0 16px",
                  ...(displayImages.slice(0).length > 0 && {
                    borderBottom: "1px var(--color-gray-200) solid",
                  }),
                }}
              >
                <p
                  style={{
                    textTransform: "uppercase",
                  }}
                  className="p-light"
                >
                  {title}
                </p>

                <button onClick={() => handleOpenPopup(`popu_${fieldName}`)}>
                  Edit
                </button>
              </div>
              {displayImages.slice(0).length > 0 && (
                <>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns:
                        "repeat(auto-fill, minmax(200px, 1fr))",
                      gap: "4px",
                      overflow: "hidden",
                      padding: "8px",
                    }}
                  >
                    {displayImages.slice(0).map((image, index) => (
                      <div
                        style={{
                          overflow: "hidden",
                          borderRadius: "4px",
                        }}
                        key={image.id}
                      >
                        <ImageWithLoader
                          aspectRatio="16/9"
                          url={image.url}
                          alt={image.url}
                        />
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
            {description && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "8px 16px",
                }}
              >
                <p className="p-light">{description}</p>
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "0 16px",
              boxSizing: "border-box",
            }}
          >
            <div
              style={{
                backgroundColor: "var(--color-white)",
                boxSizing: "border-box",
                borderRadius: "8px",
                overflow: "hidden",
              }}
            ></div>
          </div>

          {displayImages[0] ? (
            <div
              className="iconCircle110"
              style={{ backgroundImage: `url(${displayImages[0].url})` }}
            ></div>
          ) : (
            <div className="wigetInput_gallery-main-img">
              <p>Загрузить</p>
            </div>
          )}
        </>
      )}
      {/* </WigetInformer> */}

      {isPopupOpen &&
        (loading ? (
          <Loading title="Ожидайте завершения" />
        ) : (
          <Popup
            isOpen={true}
            onClose={handleClosePopup}
            title={title}
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eleifend ipsum vitae nunc gravida facilisis. Pellentesque finibus, justo id tincidunt accumsan, mauris elit consequat nulla, vel tempus mi lorem vel ipsum. Integer id tincidunt sapien. Cras faucibus euismod massa eget interdum. Sed commodo, ante eu tempor condimentum, elit dui lacinia nisi, in pulvinar enim enim ut orci. Suspendisse potenti."
            leftMenu={
              <>
                <div className="wigetImage-uploadFile-selectedFile">
                  <input type="file" onChange={handleFileChange} />

                  <Button onClick={handleFileUpload}>
                    <div className="button-icon">
                      <p>Upload file</p>
                      <i className="fa-solid fa-cloud-arrow-up"></i>
                    </div>
                  </Button>
                </div>
              </>
            }
            rightMenu={
              <>
                <ul>
                  {selectedFileIds.length > 0 && (
                    <li>
                      <i
                        className="fa-regular fa-trash-can tooltip"
                        onClick={handleDeleteSelectedFiles}
                      >
                        <span className="tooltip-text">
                          Удалить выбранные файлы
                        </span>
                      </i>
                    </li>
                  )}
                  <li>
                    {imageURLs.length > 0 && (
                      <i
                        style={{ color: "var(--color-alarm)" }}
                        className="fa-solid fa-xmark tooltip"
                        onClick={handleDeleteAllFiles}
                      >
                        <span className="tooltip-text">Удалить все файлы</span>
                      </i>
                    )}
                  </li>
                </ul>

                <ul>
                  <li>
                    <i
                      className={
                        selectedFileIds.length > 0
                          ? "fa-regular fa-square-check tooltip"
                          : "fa-regular fa-square tooltip"
                      }
                      onClick={toggleCheckboxes}
                    >
                      <span className="tooltip-text">Выделить все файлы</span>
                    </i>
                  </li>
                </ul>

                <ul>
                  <li>
                    <i className="fa-solid fa-grip tooltip">
                      <span className="tooltip-text">Табличный вид</span>
                    </i>
                  </li>
                  <li>
                    <i className="fa-solid fa-list-ul tooltip">
                      <span className="tooltip-text">Вид список</span>
                    </i>
                  </li>
                </ul>
              </>
            }
          >
            <>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
                  gap: "8px",
                  overflow: "hidden",
                  padding: "4px",
                }}
              >
                {imageURLs.map((image, index) => (
                  <div key={index}>
                    {fileToDelete === image.id ? (
                      <div className="deleting">Удаление...</div>
                    ) : (
                      <>
                        <div
                          className={
                            image.priority
                              ? "wigetImage-img-back wigetImage-img-back-priority"
                              : "wigetImage-img-back"
                          }
                        >
                          <div className="wigetImage-img-menu">
                            <Checkbox
                              label="Выбрать"
                              checked={selectedFileIds.includes(image.id)}
                              onChange={(isChecked, id) =>
                                handleCheckboxChange(isChecked, id)
                              }
                              priority={image.priority}
                              id={image.id}
                            />
                            <ContextMenu
                              priority={image.priority ? true : false}
                            >
                              <span onClick={() => handleDeleteFile(image.id)}>
                                <div className="context-menu-item-component">
                                  <i className="fa-solid fa-trash"></i>
                                  <p>Delete</p>
                                </div>
                              </span>
                              <span
                                onClick={() => handleUpdatePriority(image.id)}
                              >
                                <div className="context-menu-item-component">
                                  <i className="fa-regular fa-star"></i>
                                  <p>Priority</p>
                                </div>
                              </span>
                            </ContextMenu>
                          </div>
                          <div
                            className="wigetImage-img"
                            style={{ backgroundImage: `url(${image.url})` }}
                          >
                            <div
                              className="wigetImage-img-inform-priority"
                              onClick={() => handleUpdatePriority(image.id)}
                            >
                              {image.priority ? (
                                <i className="fa-solid fa-star"></i>
                              ) : (
                                <i className="fa-regular fa-star"></i>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>
            </>
          </Popup>
        ))}
    </>
  );
};

export default PreviewGallery;
