import React, { useState, useEffect } from "react";
import ImageWithLoader from "../../UIComponents/bars/gallery/ImageWithLoader";
import BikesInform from "./BikesInform";
import WidgetStatistic from "../UniversalWidgets/WidgetStatistic";
import { useParams } from "react-router-dom";
import Preloader from "../../UIElements/Preloader";
import WigetSelectedPopupV2 from "../UniversalWidgets/WigetSelectedPopupV2";
import WidgetAddComments from "../UniversalWidgets/Comments/WidgetAddComments";
import { useSelector } from "react-redux";
const AncorNavigation = ({
  rentalPointData,
  commentsData,
  loadingCommentsData,
  isLoading,
}) => {
  const { id } = useParams();

  const currentUser = useSelector((state) => state.userSliceV2.currentUser);
  const role = currentUser ? currentUser.role : null;
  // const isLoading = useSelector((state) => state.userSliceV2.isLoading);

  const [scrollY, setScrollY] = useState(0);
  const [addParentComment, setAddParentComment] = useState(false);

  useEffect(() => {
    // Функция обработчика события скролла
    const handleScroll = () => {
      // Получаем текущую позицию скролла
      const currentScrollY = window.scrollY;
      setScrollY(currentScrollY);
    };

    // Добавляем обработчик события при монтировании компонента
    window.addEventListener("scroll", handleScroll);

    // Удаляем обработчик события при размонтировании компонента
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div
        style={{
          top: "80px",
          width: "330px",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          padding: "8px",
          gap: "8px",
          backgroundColor: "var(--color-white)",
          borderRadius: "24px",
          marginTop: scrollY >= 150 ? "80px" : "0",
          height:
            scrollY >= 150 ? "calc(100vh - 130px)" : "calc(100vh - 220px)",
          minHeight: "750px",
          transition: " margin 0.5s ease, height 1s ease",
        }}
      >
        <div
          style={{
            backgroundColor: "var(--color-gray-100)",
            borderRadius: "16px",
            overflow: "hidden",
            boxSizing: "border-box",
            height: "153px",
          }}
        >
          <ImageWithLoader
            style={{
              height: "100%",
              width: "100%",
              boxSizing: "border-box",
            }}
            // aspectRatio="16/9"
            url={rentalPointData?.file_logo}
            alt={rentalPointData?.file_logo}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "calc(100% - 153px - 8px - 153px - 8px)",
            boxSizing: "border-box",
          }}
        >
          <div
            className="wigetProdictCard-price-payToRental"
            style={{
              overflow: "hidden",
              backgroundColor: "var(--color-priority)",
              height: "100%",
              minHeight: "400px",
              // backgroundColor: "var(--color-primary)",
              backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/biketripste.appspot.com/o/static%2FlogoBikeTripster%2Fcomments.svg?alt=media&token=23fccebf-7183-4e82-9223-b666943a13b4)`,
              backgroundSize: "116% auto",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center 105%",
              padding: "0",
            }}
          >
            {loadingCommentsData ? (
              <div
                style={{
                  display: "flex",
                  flex: "1",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Preloader design="white" />
              </div>
            ) : (
              <>
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    padding: "24px",
                    boxSizing: "border-box",
                  }}
                >
                  <WidgetStatistic
                    rentalPointId={id}
                    commentsData={commentsData}
                    loadingCommentsData={loadingCommentsData}
                  />
                </div>

                <button
                  style={{
                    padding: "0px",
                    position: "absolute",
                    bottom: "16px",
                    left: "16px",
                    width: "calc(100% - 32px)",
                    backgroundColor: "var(--color-success)",
                  }}
                  className="popupButton"
                  onClick={() => setAddParentComment(true)}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      gap: "8px",
                      padding: "0 24px",
                    }}
                  >
                    <h5
                      style={{
                        color: "var(--color-white)",
                      }}
                    >
                      Add comment
                    </h5>
                  </div>
                </button>
              </>
            )}

            {/* <PopularСomment commentsData={commentsData} /> */}
          </div>
        </div>

        <div
          style={{
            backgroundColor: "var(--color-gray-100)",
            borderRadius: "16px",
            overflow: "hidden",
            boxSizing: "border-box",
            height: "153px",
          }}
        >
          <BikesInform
            rentalPointData={rentalPointData}
            isLoading={isLoading}
          />
        </div>
      </div>

      <WigetSelectedPopupV2
        img={
          "https://firebasestorage.googleapis.com/v0/b/biketripste.appspot.com/o/static%2FaddedComment3.jpg?alt=media&token=f88d0e1d-a4e4-4cd9-a450-f0fb8b499ee0"
        }
        windowControlOpen={addParentComment}
        windowControlClose={setAddParentComment}
      >
        <div
          style={{
            padding: "32px",
            height: "100%",
            width: "100%",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <WidgetAddComments
            uid={currentUser?.uid}
            // currentBike={currentBike}
            closePopupSatate={setAddParentComment}
            rentalPointId={id}
          />
        </div>
      </WigetSelectedPopupV2>
    </>
  );
};

export default AncorNavigation;
