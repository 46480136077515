import React from "react";
import { WigetNewsDetail } from "./WigetNews/WigetNewsDetail";
import SearchBars from "../../../../UIComponents/bars/searchBars/SearchBars";
import Icon44 from "../../../../UIComponents/icons/Icon44";
import { setPrivateSideBar } from "../../../../../slice/stateGUISlice";
import WigetButtonAdd from "../../../../UniversalWidgets/WigetButtonAdd";
import { useDispatch } from "react-redux";

const WigetNewsBox = () => {
  const dispatch = useDispatch();

  return (
    <>
      {/* <div className="wigetRentalPointItsBox">
                <h3>Новости</h3>
                <p className='p-primary'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eleifend ipsum vitae nunc gravida facilisis.</p>
            </div> */}
      <SearchBars
        title="News"
        iconLeft={
          <Icon44
            click={() => dispatch(setPrivateSideBar(true))}
            icon={
              <span className="material-symbols-outlined">dock_to_right</span>
            }
          />
        }
        iconRight={
          <WigetButtonAdd
            collectionName="news"
            // dataObject={structureCollection}
            nameButton={`Add news`}
          />
        }
      />
      <div className="scrollBox">
        <WigetNewsDetail collectionName="news" title="News" />
      </div>
    </>
  );
};

export default WigetNewsBox;
