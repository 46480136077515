import React from "react";
import { WigetCityDetail } from "./WigetBikes/WigetCityDetail";
import SearchBars from "../../../../UIComponents/bars/searchBars/SearchBars";
import Icon44 from "../../../../UIComponents/icons/Icon44";
import { setPrivateSideBar } from "../../../../../slice/stateGUISlice";
import { useDispatch } from "react-redux";

const WigetCityBox = () => {
  const dispatch = useDispatch();

  return (
    <>
      {/* <div className="wigetRentalPointItsBox">
                <h3>Cityes</h3>
                <p className='p-primary'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eleifend ipsum vitae nunc gravida facilisis.</p>
            </div> */}
      <div
        style={{
          width: "var(--widthContent)",
          height: "100%",
        }}
      >
        <SearchBars
          title="Cityes"
          iconLeft={
            <Icon44
              click={() => dispatch(setPrivateSideBar(true))}
              icon={
                <span className="material-symbols-outlined">dock_to_right</span>
              }
            />
          }
          iconRight={<></>}
        />

        <div
          style={{
            width: "var(--widthContent)",
            height: "100%",
          }}
          className="scrollBox"
        >
          <WigetCityDetail
            collectionName="country"
            parentCollectionName="city"
            title="Cityes"
          />
        </div>
      </div>
    </>
  );
};
export default WigetCityBox;
