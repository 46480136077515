const prioritiUrl = (fileLogos) => {
  const prioritizedLogo = fileLogos?.find((logo) => logo.priority === true);

  if (prioritizedLogo) {
    return prioritizedLogo.url;
  } else if (fileLogos?.length > 0) {
    return fileLogos[0].url;
  } else {
    return null;
  }
};

export default prioritiUrl;
