import { hover } from "@testing-library/user-event/dist/hover";
import React, { useState, useEffect } from "react";
import ImageWithLoader from "../../UIComponents/bars/gallery/ImageWithLoader";

export const WigetImageCarousel = ({
  images: originalImages,
  quantity = 4,
  height = 200,
}) => {
  useEffect(() => {
    setImages([]);
  }, [originalImages]);

  const visibleSlides = quantity;

  // Изначально удваиваем массив изображений для удобной навигации
  const [images, setImages] = useState([...originalImages, ...originalImages]);

  // Вычисляем ширину слайда на основе видимого количества
  const slideWidth = 100 / visibleSlides;

  // Храним текущее смещение карусели
  const [currentOffset, setCurrentOffset] = useState(0);

  // Определяем эффект перехода для плавной анимации
  const [transition, setTransition] = useState("transform 0.5s ease");

  // Обработчик для перехода к следующему слайду
  const handleNextSlide = () => {
    setTimeout(() => {
      setCurrentOffset((prevOffset) => prevOffset - slideWidth);
    }, 100);
  };

  // Обработчик для перехода к предыдущему слайду
  const handlePrevSlide = () => {
    setTimeout(() => {
      setCurrentOffset((prevOffset) => prevOffset + slideWidth);
    }, 200);
  };

  // Эффект для обработки бесконечного цикла карусели
  useEffect(() => {
    // Если достигли конца списка
    if (currentOffset <= -slideWidth * (images.length - visibleSlides)) {
      // Добавляем изображения из originalImages в конец массива images
      setImages((prevImages) => [...prevImages, ...originalImages]);
    }
    // Если достигли начала списка
    if (currentOffset >= 0) {
      // Добавляем изображения из originalImages в начало массива images
      setImages((prevImages) => [...originalImages, ...prevImages]);
      // Корректируем текущее смещение без анимации на ширину добавленных картинок
      setCurrentOffset(-slideWidth * (images.length - visibleSlides));
      // Устанавливаем нулевую анимацию для перехода
      setTransition("none");
      // Задержка перед восстановлением анимации для создания визуального эффекта
      setTimeout(() => {
        setTransition("transform 0.5s ease");
      }, 500);
    }
  }, [currentOffset, images.length, originalImages, slideWidth, visibleSlides]);

  return (
    <div
      className="image-carousel"
      style={{
        height: `${height}px`,
        boxSizing: "border-box",
      }}
    >
      <div
        className="carousel-container"
        style={{
          transform: `translateX(${currentOffset}%)`,
          transition,
          height: `${height}px`,
        }}
      >
        {images.map((image, index) => (
          <div
            key={index}
            className="carousel-slide"
            style={{
              flex: `0 0 calc(100% / ${quantity})`,
              height: `${height}px`,
            }}
          >
            <ImageWithLoader
              objectFit="cover"
              aspectRatio="1/1"
              url={image?.url}
              alt="Product preview gallery"
            />
          </div>
        ))}
      </div>
      {images.length > 0 && (
        <>
          <button className="prev-button" onClick={handlePrevSlide}>
            <i className="fa-solid fa-chevron-left"></i>
          </button>

          <button className="next-button" onClick={handleNextSlide}>
            <i className="fa-solid fa-chevron-right"></i>
          </button>
        </>
      )}
    </div>
  );
};

export const WigetImageCarousel2 = ({
  images: originalImages,
  quantity = 4,
}) => {
  const visibleSlides = quantity;

  useEffect(() => {
    setImages([]);
  }, [originalImages]);

  // Изначально удваиваем массив изображений для удобной навигации
  const [images, setImages] = useState([...originalImages, ...originalImages]);

  // Вычисляем ширину слайда на основе видимого количества
  const slideWidth = 100 / visibleSlides;

  // Храним текущее смещение карусели
  const [currentOffset, setCurrentOffset] = useState(0);

  // Определяем эффект перехода для плавной анимации
  const [transition, setTransition] = useState("transform 0.5s ease");

  // Обработчик для перехода к следующему слайду
  const handleNextSlide = () => {
    setTimeout(() => {
      setCurrentOffset((prevOffset) => prevOffset - slideWidth * quantity);
    }, 100);
  };

  // Обработчик для перехода к предыдущему слайду
  const handlePrevSlide = () => {
    setTimeout(() => {
      setCurrentOffset((prevOffset) => prevOffset + slideWidth * quantity);
    }, 200);
  };

  // Эффект для обработки бесконечного цикла карусели
  useEffect(() => {
    // Если достигли конца списка
    if (currentOffset <= -slideWidth * (images.length - visibleSlides)) {
      // Добавляем изображения из originalImages в конец массива images
      setImages((prevImages) => [...prevImages, ...originalImages]);
    }
    // Если достигли начала списка
    if (currentOffset >= 0) {
      // Добавляем изображения из originalImages в начало массива images
      setImages((prevImages) => [...originalImages, ...prevImages]);
      // Корректируем текущее смещение без анимации на ширину добавленных картинок
      setCurrentOffset(-slideWidth * (images.length - visibleSlides));
      // Устанавливаем нулевую анимацию для перехода
      setTransition("none");
      // Задержка перед восстановлением анимации для создания визуального эффекта
      setTimeout(() => {
        setTransition("transform 0.5s ease");
      }, 500);
    }
  }, [currentOffset, images.length, originalImages, slideWidth, visibleSlides]);

  return (
    <div className="image-carousel">
      <div
        className="carousel-container"
        style={{ transform: `translateX(${currentOffset}%)`, transition }}
      >
        {images.map((image, index) => (
          <div
            key={index}
            className="carousel-slide"
            style={{
              flex: `0 0 calc(100% / ${quantity})`,
              backgroundImage: `url(${image.url})`,
            }}
          >
            <div
              style={{
                backgroundImage: `url(${image.url})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "500px",
                width: "100%",
              }}
            />
          </div>
        ))}
      </div>

      <div className="carousel-button-container">
        <button className="prev-button" onClick={handlePrevSlide}>
          <i className="fa-solid fa-chevron-left"></i>
        </button>

        <button className="next-button" onClick={handleNextSlide}>
          <i className="fa-solid fa-chevron-right"></i>
        </button>
      </div>
    </div>
  );
};
