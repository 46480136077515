import { createAsyncThunk } from "@reduxjs/toolkit";
import { updateDocumentFields } from "../../../../services/firebase/crudFirestore";

export const updateCityThunk = createAsyncThunk(
  "countryAndCity/updateCity",
  async (
    { countryId, cityId, updatedFields },
    { getState, rejectWithValue }
  ) => {
    console.log("updateCityThunk", countryId, cityId, updatedFields);
    if (!countryId || !cityId || !updatedFields) {
      return rejectWithValue(
        "Invalid data: countryId, cityId, and updatedFields are required."
      );
    }

    try {
      const state = getState();
      const countryDoc = state.countryAndCitySlice.countryWithCities.find(
        (country) => country.id === countryId
      );

      if (!countryDoc) {
        return rejectWithValue("Country not found");
      }

      const cityIndex = countryDoc.cities.findIndex(
        (city) => city.id === cityId
      );

      if (cityIndex === -1) {
        return rejectWithValue("City not found");
      }

      const updatedCity = { ...countryDoc.cities[cityIndex], ...updatedFields };
      const updatedCities = [...countryDoc.cities];
      updatedCities[cityIndex] = updatedCity;

      await updateDocumentFields("country", countryId, {
        cities: updatedCities,
      });
      return { countryId, cityId, updatedFields: updatedCity };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
