import { createAsyncThunk } from "@reduxjs/toolkit";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../../../services/firebase/authFirebase";
import interpretAuthErrorV2 from "../../../../../functions/interpretAuthErrorV2";
import { isDataStale } from "./utils"; // Предположим, что функция isDataStale вынесена в utils.js

export const fetchCountriesDataThunkV4 = createAsyncThunk(
  "catalog/fetchCountriesDataThunkV4",
  async (_, { getState, rejectWithValue }) => {
    const state = getState().catalogSliceV4;
    const currentTimestamp = Date.now();
    let shouldFetch = false;

    console.log("Проверка необходимости обновления данных о странах...");
    if (state.countries.length === 0) {
      console.log("Данные о странах отсутствуют, выполняем запрос.");
      shouldFetch = true;
    } else {
      shouldFetch = state.countries.some((country) => {
        const isStale =
          !country.lastUpdated || isDataStale(country.lastUpdated);
        if (isStale) {
          console.log(
            `Данные страны с id ${country.id} устарели, выполняем запрос.`
          );
        }
        return isStale;
      });
    }

    if (!shouldFetch) {
      console.log("Данные о странах актуальны, запрос не выполняется.");
      return { fromIndexedDB: true };
    }

    try {
      console.log("Запрос данных о странах...");
      const collectionRef = collection(db, "catalog");
      const snapshot = await getDocs(collectionRef);
      const data = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        lastUpdated: currentTimestamp,
      }));
      console.log("Данные о странах успешно получены из Firestore:", data);
      return { data };
    } catch (error) {
      console.error(
        "Ошибка при получении данных о странах из Firestore:",
        error
      );
      const errorMessage = interpretAuthErrorV2(error.code);
      return rejectWithValue({ errorMessage });
    }
  }
);
