import React, { useEffect } from "react";
import { useSubscribeData } from "../../../../../services/firebase/crudFirebaseStorage";
import WigetRentalPointList from "./WigetRentalPoints/WigetRentalPointList";
import WigetRentalPointDetail from "./WigetRentalPoints/WigetRentalPointDetail";
import { setCurrentUsersRentalPoints } from "../../../../../slice/stateGUISlice";
import { useDispatch, useSelector } from "react-redux";

const UIRentalPoint = () => {
  const dispatch = useDispatch();

  // Получаем данные о пользователе"
  const currentUser = useSelector((state) => state.userSliceV2.currentUser);
  const role = currentUser ? currentUser.role : null;
  const isLoading = useSelector((state) => state.userSliceV2.isLoading);
  const userId = currentUser ? currentUser.uid : null;

  // Получаем профиль пользователя
  const { data: userData, isLoading: loadingUserData } = useSubscribeData(
    "user",
    userId
  );

  // Получаем список точек проката
  const { data: rentalPointsData, isLoading: loadingRentalPointsData } =
    useSubscribeData("rentalPoints");

  // Фильтруем точки проката так, чтобы получить прокаты текущего пользователя
  useEffect(() => {
    const filteredData =
      rentalPointsData &&
      rentalPointsData.filter((item) =>
        userData?.rentalPoints_id?.includes(item.id)
      );
    dispatch(setCurrentUsersRentalPoints(filteredData));
  }, [rentalPointsData, userData, dispatch]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flex: "1",
        boxSizing: "border-box",
        // width: 'calc(100vw - 330px)',
        width: "100%",
        // height: 'calc(100vh - 56px)',
        height: "100%",
        overflowY: "hidden",
        backgroundColor: "black",
        gap: "16px",
      }}
    >
      <div
        style={{
          width: "500px",
          height: "100%",
          borderRadius: "8px",
          overflow: "hidden",
        }}
      >
        <WigetRentalPointList />
      </div>

      <div
        style={{
          width: "calc(100% - 500px)",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "space-between",
          height: "100%",
          boxSizing: "border-box",
          borderRadius: "8px",
          overflow: "hidden",
          backgroundColor: "var(--color-white)",
        }}
      >
        <WigetRentalPointDetail />
      </div>
    </div>
  );
};

export default UIRentalPoint;
