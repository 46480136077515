import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Preloader from "../../UIElements/Preloader";
import PopupWindow from "../PublickWidgets/Windows/PopupWindow";
import WindowAuth from "./WindowLogIn";
import WidgetNavigateMenu from "./WidgetNavigateMenu";
import WidgetNavigateUser from "./WidgetNavigateUser";
import ContentBoxPublic from "../PublickWidgets/ContentBox/ContentBoxPublic";

const WindowNavigete = ({ onClose }) => {
  const [role, setRole] = useState(null);

  const { currentUser, isLoading } = useSelector((state) => state.userSliceV2);

  const { mainMenu, authMenu } = useSelector((state) => state.navigateSliceV2);

  useEffect(() => {
    setRole(currentUser.role || null);
  }, [currentUser]);

  return (
    <>
      <ContentBoxPublic>
        <WidgetNavigateUser
          isLoading={isLoading}
          role={role}
          onClose={onClose}
        />
      </ContentBoxPublic>
      <ContentBoxPublic>
        <WidgetNavigateMenu mainMenu={mainMenu} onClose={onClose} />
      </ContentBoxPublic>
    </>
  );
};

export default WindowNavigete;
