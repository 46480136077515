import React, { useState, useEffect } from "react";
import InputText from "../WidgetsProfile/Inputs/InputText";
import Button from "../WidgetsProfile/Buttons/Button";
import { useDispatch, useSelector } from "react-redux";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import {
  removeContactsPhoneNumber,
  setContactsPhoneNumbers,
} from "../../../../../reducersToolkit/rentalPointsSliiceV2";
import {
  removeContactsPhoneNumberModeration,
  setContactsPhoneNumbersModeration,
} from "../../../../../reducersToolkit/moderationSliceV2";
import ButtonsBar from "../WidgetsProfile/ButtonsBar/ButtonsBar";

const WindowEditPhone = ({ mode, onClose }) => {
  const dispatch = useDispatch();
  const [phone, setPhone] = useState("");
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [newPhoneNumbers, setNewPhoneNumbers] = useState([]);

  const currentUser = useSelector((state) => state.userSliceV2.currentUser);

  const { selectedRentalPoint, newRentalPoint } = useSelector(
    (state) => state.rentalPointsSliiceV2
  );

  const { selectedRentalPoint: selectedRentalPointModeration } = useSelector(
    (state) => state.moderationSliceV2
  );

  useEffect(() => {
    if (mode === "new") {
      setPhoneNumbers(newRentalPoint?.contacts?.phoneNumbers || []);
    } else if (mode === "edit") {
      setPhoneNumbers(selectedRentalPoint?.contacts?.phoneNumbers || []);
    } else if (mode === "moderation") {
      setPhoneNumbers(
        selectedRentalPointModeration?.contacts?.phoneNumbers || []
      );
    }
  }, [
    mode,
    newRentalPoint,
    selectedRentalPoint,
    selectedRentalPointModeration,
  ]);

  const handleAddPhone = () => {
    const phoneObject = {
      key: Date.now().toString(),
      phone,
      moderatorId: mode === "moderation" ? currentUser.id : null,
    };
    setNewPhoneNumbers([...newPhoneNumbers, phoneObject]);
    setPhone("");
  };

  const handleRemovePhone = (index) => {
    setNewPhoneNumbers(newPhoneNumbers.filter((_, i) => i !== index));
  };

  const handleRemoveLoadedPhone = (phoneObject) => {
    if (mode === "new") {
      dispatch(removeContactsPhoneNumber({ mode, phoneNumber: phoneObject }));
      setPhoneNumbers(phoneNumbers.filter((p) => p.key !== phoneObject.key));
    } else if (mode === "edit") {
      dispatch(removeContactsPhoneNumber({ mode, phoneNumber: phoneObject }));
      setPhoneNumbers(phoneNumbers.filter((p) => p.key !== phoneObject.key));
    } else if (mode === "moderation") {
      removeContactsPhoneNumberModeration(phoneObject.key);
      setPhoneNumbers(
        phoneNumbers.filter((phone) => phone.key !== phoneObject.key)
      );
    }
  };

  const handleApply = () => {
    if (mode === "new") {
      newPhoneNumbers.forEach((phoneObject) => {
        dispatch(setContactsPhoneNumbers({ mode, phoneNumber: phoneObject }));
      });
    } else if (mode === "edit") {
      newPhoneNumbers.forEach((phoneObject) => {
        dispatch(setContactsPhoneNumbers({ mode, phoneNumber: phoneObject }));
      });
    } else if (mode === "moderation") {
    }
    newPhoneNumbers.forEach((phoneObject) => {
      dispatch(setContactsPhoneNumbersModeration(phoneObject));
    });
    onClose();
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "8px",
          paddingBottom: "72px",
        }}
      >
        <ContentBox position="start">
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              gap: "16px",
            }}
          >
            <InputText
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              label="Phone number"
              description="Enter the phone number."
            />
            <div
              style={{
                width: "auto",
              }}
            >
              <Button
                label="Add"
                active={phone ? true : false}
                onClick={handleAddPhone}
              />
            </div>
          </div>
        </ContentBox>
        {(phoneNumbers.length > 0 || newPhoneNumbers.length > 0) && (
          <ContentBox>
            <ul
              style={{
                width: "100%",
                listStyle: "none",
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              {phoneNumbers.map((phoneObj) => (
                <li
                  key={phoneObj.key}
                  style={{
                    height: "56px",
                    width: "100%",
                    boxSizing: "border-box",
                    borderRadius: "8px",
                    backgroundColor: "var(--color-gray-100)",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "0 8px 0 16px",
                  }}
                >
                  <h6>{phoneObj.phone || "No phone number"}</h6>
                  <div
                    style={{
                      width: "auto",
                    }}
                  >
                    <Button
                      onClick={() => handleRemoveLoadedPhone(phoneObj)}
                      allert={
                        <h1
                          style={{
                            padding: "48px",
                            textAlign: "center",
                          }}
                        >
                          Are you sure you want to delete this phone number?
                          Please confirm your action.
                        </h1>
                      }
                      active={true}
                      color="--color-gray-100"
                      type="icon"
                      icon={
                        <span className="material-symbols-outlined">
                          delete
                        </span>
                      }
                    />
                  </div>
                </li>
              ))}
              {newPhoneNumbers.map((phoneObj, index) => (
                <li
                  key={phoneObj.key}
                  style={{
                    height: "56px",
                    width: "100%",
                    boxSizing: "border-box",
                    borderRadius: "8px",
                    backgroundColor: "var(--color-gray-100)",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "0 8px 0 16px",
                  }}
                >
                  <h6>{phoneObj.phone || "No phone number"}</h6>
                  <div
                    style={{
                      width: "auto",
                    }}
                  >
                    <Button
                      onClick={() => handleRemovePhone(index)}
                      allert={
                        <h1
                          style={{
                            padding: "48px",
                            textAlign: "center",
                          }}
                        >
                          Are you sure you want to delete this phone number?
                          Please confirm your action.
                        </h1>
                      }
                      active={true}
                      color="--color-gray-100"
                      type="icon"
                      icon={
                        <span className="material-symbols-outlined">
                          delete
                        </span>
                      }
                    />
                  </div>
                </li>
              ))}
            </ul>
          </ContentBox>
        )}
      </div>
      <ButtonsBar>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "16px",
            width: "100%",
          }}
        >
          <Button
            type="small"
            label="Cancel"
            active={true}
            color="--color-black"
            onClick={onClose}
          />
          <Button
            type="small"
            label="Apply"
            active={newPhoneNumbers.length > 0 && true}
            onClick={handleApply}
          />
        </div>
      </ButtonsBar>
    </>
  );
};

export default WindowEditPhone;
