import React, { useState } from "react";
import { Link } from "react-router-dom";

const WIgetError = ({ error, onClose, img }) => {
  if (!img) {
    img =
      "https://firebasestorage.googleapis.com/v0/b/testbikestripster.appspot.com/o/imgStatic%2Ferr7.jpg?alt=media&token=e2270e9b-8676-4592-aeb8-5e4182f78f66&_gl=1*11g1cum*_ga*NzE3MzY1ODU1LjE2ODQ3NTEzMTQ.*_ga_CW55HF8NVT*MTY5NzQzODQ5OC40LjEuMTY5NzQzOTg1MS40NC4wLjA.";
  }

  const [isErrorVisible, setIsErrorVisible] = useState(false);

  const handleCloseError = () => {
    setIsErrorVisible(false);
    onClose();
  };

  if (error && !isErrorVisible) {
    setIsErrorVisible(true);
  }

  return (
    isErrorVisible && (
      <div className="popupBg">
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            borderRadius: "24px",
            color: "var(--color-alarm)",
            position: "absolute",
            width: "800px",
            aspectRatio: "16/9",
            backgroundColor: "var(--color-white)",
            backdropFilter: "blur(10px)",
            zIndex: "100",
            boxSizing: "border-box",
            textAlign: "center",
            overflow: "hidden",
            padding: "8px",
          }}
        >
          <div
            style={{
              gridColumn: "span 2",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "24px",
              padding: "40px",
            }}
          >
            <div
              style={{
                backgroundImage:
                  'url("https://firebasestorage.googleapis.com/v0/b/testbikestripster.appspot.com/o/imgStatic%2Foops3.svg?alt=media&token=9ae1b3e5-9e94-45ad-b01a-6f55bfd48d24&_gl=1*isg6mm*_ga*MTYxOTY1MDE1OS4xNjk3Mzc2NDMy*_ga_CW55HF8NVT*MTY5NzM5MjU2My4zLjEuMTY5NzM5MjU5Ni4yNy4wLjA.")',
                backgroundSize: "auto 100% ",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                width: "250px",
                height: "150px",
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
              }}
            >
              <h3
                style={{
                  width: "70%",
                }}
              >
                {error}
              </h3>
              <p className="p-mini">
                If you have problems with the service and you cannot solve these
                problems yourself, &nbsp;
                <Link to={"/support"}>contact technical support</Link>&nbsp; and
                we will try to help you!
              </p>
            </div>

            <button
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "16px",
                width: "auto",
                padding: "8px 24px",
                borderRadius: "80px",
                border: "none",
                fontSize: "1em",
                fontWeight: "500",
                height: "56px",
                backgroundColor: "var(--color-black)",
                color: "var(--color-white)",
                cursor: "pointer",
              }}
              onClick={handleCloseError}
            >
              Confirm
            </button>
          </div>

          <div
            style={{
              gridColumn: "span 1",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "var(--color-gray-200)",
              backgroundImage: `url(${img})`,
              height: "100%",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              borderRadius: "16px",
            }}
          />
        </div>
      </div>
    )
  );
};

export default WIgetError;
