import { createAsyncThunk } from "@reduxjs/toolkit";
import interpretAuthErrorV2 from "../../../functions/interpretAuthErrorV2";
import {
  updateDocumentFields,
  addData,
} from "../../../services/firebase/crudFirestore";

export const addBikesThunkV2 = createAsyncThunk(
  "bikes/addBikesThunkV2",
  async ({ documentId, newBike }, { rejectWithValue }) => {
    try {
      // Добавляем новый байк в коллекцию bikes

      const addedBike = await addData("bikes", newBike);

      // Обновляем документ rentalPoints, добавляя bikeId нового байка в массив bikesIds
      await updateDocumentFields("rentalPoints", documentId, {
        bikesIds: [addedBike.id],
      });

      return { newBike: addedBike };
    } catch (error) {
      const errorMessage = interpretAuthErrorV2(error.code);
      return rejectWithValue({ errorMessage });
    }
  }
);
