import { createAsyncThunk } from "@reduxjs/toolkit";
import { collection, doc, setDoc, updateDoc } from "firebase/firestore";
import { uploadFilesToFirebase } from "../../../../services/firebase/crudFirestore";
import { db } from "../../../../services/firebase/authFirebase";

export const createCountryThunkV4 = createAsyncThunk(
  "countries/createCountryThunkV4",
  async ({ countryId, data, files }, { rejectWithValue }) => {
    try {
      // 1. Создание документа в Firestore
      const docRef = doc(
        db,
        "catalog",
        countryId || doc(collection(db, "catalog")).id
      );

      await setDoc(docRef, data); // Сначала сохраняем данные без файлов

      console.log("Документ успешно создан в Firestore с ID:", docRef.id);

      // 2. Загрузка файлов с использованием вынесенной функции
      const uploadedFiles = await uploadFilesToFirebase(
        `countries/${docRef.id}`,
        files
      );

      // 3. Обновление документа в Firestore с ссылками на загруженные файлы
      const updatedData = {
        files: {
          iconFiles: uploadedFiles,
        },
      };

      await updateDoc(docRef, updatedData);

      console.log("Документ в Firestore обновлен с загруженными файлами.");

      return { countryId: docRef.id, data: { ...data, ...updatedData } };
    } catch (error) {
      console.error("Ошибка при создании страны:", error);
      return rejectWithValue(error.message);
    }
  }
);

// import { createAsyncThunk } from "@reduxjs/toolkit";
// import { db, storage } from "../../../../services/firebase/authFirebase";
// import { collection, doc, setDoc, updateDoc } from "firebase/firestore";
// import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

// export const createCountryThunkV4 = createAsyncThunk(
//   "countries/createCountryThunkV4",
//   async ({ countryId, data, files }, { rejectWithValue }) => {
//     try {
//       // 1. Создание документа в Firestore
//       const docRef = doc(
//         db,
//         "catalog",
//         countryId || doc(collection(db, "catalog")).id
//       );

//       await setDoc(docRef, data); // Сначала сохраняем данные без файлов

//       console.log("Документ успешно создан в Firestore с ID:", docRef.id);

//       // 2. Загрузка файлов в Firebase Storage
//       console.log("Загрузка файлов началась:", files);

//       const fileUploadPromises = files.map(async (file) => {
//         console.log("Загрузка файла:", file.name);

//         const storageRef = ref(storage, `countries/${docRef.id}/${file.name}`);
//         const uploadResult = await uploadBytes(storageRef, file);
//         const downloadURL = await getDownloadURL(uploadResult.ref);

//         console.log("Файл успешно загружен:", {
//           name: file.name,
//           url: downloadURL,
//         });

//         return {
//           id: file.name,
//           url: downloadURL,
//           name: file.name,
//           priority: false,
//         };
//       });

//       const uploadedFiles = await Promise.all(fileUploadPromises);

//       console.log("Все файлы успешно загружены:", uploadedFiles);

//       // 3. Обновление документа в Firestore с ссылками на загруженные файлы
//       const updatedData = {
//         files: {
//           iconFiles: uploadedFiles,
//         },
//       };

//       await updateDoc(docRef, updatedData);

//       console.log("Документ в Firestore обновлен с загруженными файлами.");

//       return { countryId: docRef.id, data: { ...data, ...updatedData } };
//     } catch (error) {
//       console.error("Ошибка при создании страны:", error);
//       return rejectWithValue(error.message);
//     }
//   }
// );

// import { createAsyncThunk } from "@reduxjs/toolkit";
// import { db } from "../../../../services/firebase/authFirebase";
// import { collection, doc, setDoc } from "firebase/firestore"; // Импортируем нужные функции

// export const createCountryThunkV4 = createAsyncThunk(
//   "countries/createCountryThunkV4",
//   async ({ countryId, data }, { rejectWithValue }) => {
//     try {
//       console.log("Передача данных в Firestore:", { countryId, data });

//       // Создание ссылки на документ в коллекции "catalog". Если countryId не передан, генерируется новый ID
//       const docRef = doc(
//         db,
//         "catalog",
//         countryId || doc(collection(db, "catalog")).id
//       );

//       // Сохранение данных в Firestore
//       await setDoc(docRef, { ...data });

//       console.log("Данные успешно добавлены в Firestore:", {
//         countryId: docRef.id,
//         data,
//       });

//       return { countryId: docRef.id, data };
//     } catch (error) {
//       console.error("Ошибка при добавлении данных в Firestore:", error);
//       return rejectWithValue(error.message);
//     }
//   }
// );
