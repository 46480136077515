import React, { useEffect, useState } from "react";
import WindowFilterListing from "./WindowFilterListing";
import PopupDirrectory from "../../Private/Login/Profile/WidgetsProfile/Popups/PopupDirrectory";

const WidgetFilterListing = ({ brandFilter, categoryFilter, active }) => {
  const [isOpenWindowFilterListing, setIsOpenWindowFilterListing] =
    useState(false);
  const [brandTitle, setBrandTitle] = useState("All brand");
  const [categoryTitle, setCategoryTitle] = useState("all category");

  useEffect(() => {
    setBrandTitle(brandFilter?.title || "All brand");
    setCategoryTitle(categoryFilter?.title || "All category");
  }, [brandFilter, categoryFilter]);

  const handleOpenWindowFilterListing = () => {
    setIsOpenWindowFilterListing(true);
  };

  const onCloseWindow = () => {
    setIsOpenWindowFilterListing(false);
  };

  return (
    <>
      <div
        onClick={handleOpenWindowFilterListing}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "8px",
          backgroundColor: "var(--color-white)",
          padding: "8px 16px",
          borderRadius: "80px",
          cursor: "pointer",
        }}
      >
        <p className="p-light">{brandTitle},</p>
        <p className="p-light">{categoryTitle}</p>
      </div>
      <PopupDirrectory
        title="title"
        isOpen={isOpenWindowFilterListing}
        onClose={onCloseWindow}
      >
        <WindowFilterListing onClose={onCloseWindow} />
      </PopupDirrectory>
    </>
  );
};

export default WidgetFilterListing;
