import React, { useEffect, useCallback, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Preloader from "../../UIElements/Preloader";
import "./PublicMenu.css"; // Импортируем CSS файл
import PopupWindow from "../PublickWidgets/Windows/PopupWindow";
import WindowNavigete from "./WindowNavigete";
import WidgetSignIn from "../../Private/Login/Auth/WidgetSignIn";
import WidgetSignUp from "../../Private/Login/Auth/WidgetSignUp";
import WindowLogIn from "./WindowLogIn";
import { logoutThunkV2 } from "../../../reducersToolkit/thunk/user/logoutThunkV2";
import priorityFile from "../../../functions/priorityFile";
import AvatarUser from "../../Private/Login/Profile/WidgetsProfile/Avatars/AvatarUser";
import PopupAllert from "../../Private/Login/Profile/WidgetsProfile/Popups/PopupAllert";
import WindowLanguage from "./WindowLanguage";
import WidgetAlert from "../../Private/Login/Profile/WidgetsProfile/Alerts/WidgetAlert";

const PublicMenu = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [mainNavegate, setMainNavegate] = useState(false);
  const [windowAuth, setWindowAuth] = useState(false);

  const [role, setRole] = useState(null);
  const [url, setURL] = useState(null);
  const [isOpenAllertWindow, setIsOpenAllertWindow] = useState(false);
  const [isOpenWindowLanguage, setIsOpenWindowLanguage] = useState(false);

  const { currentUser, isLoading } = useSelector((state) => state.userSliceV2);

  const { mainMenu, authMenu } = useSelector((state) => state.navigateSliceV2);

  useEffect(() => {
    const priorityFileselected = priorityFile(
      currentUser?.user?.files?.avatarFiles || []
    );

    setRole(currentUser.role || null);
    setURL(priorityFileselected?.url || null);
  }, [currentUser]);

  const handleToggle = () => {
    setIsOpenAllertWindow(true);
  };

  const handleOpenMainNavigate = () => {
    setMainNavegate(true);
  };

  const handleOpenWindowAuth = () => {
    setWindowAuth(true);
  };
  const handleOpenWindowLanguage = () => {
    setIsOpenWindowLanguage(true);
  };

  const handleClose = () => {
    setMainNavegate(false);

    setWindowAuth(false);
    setIsOpenWindowLanguage(false);
  };

  const handleCloseAllertWindow = () => {
    setIsOpenAllertWindow(false);
  };

  const isValidSlug = useCallback(
    (slug) => {
      return (
        mainMenu.some((menuItem) => menuItem.slug === slug) ||
        /^(\/newsDatail\/[A-Za-z0-9-]+\/[A-Za-z0-9]+)$/ ||
        /^(\/rentalPoint\/[A-Za-z0-9-]+\/[A-Za-z0-9]+)$/ ||
        authMenu.some((menuItem) => menuItem.slug === slug) ||
        /^(\/catalog\/productCard\/[A-Za-z0-9]+|\/productCard\/[A-Za-z0-9]+)$/.test(
          slug
        )
      );
    },
    [mainMenu, authMenu]
  );

  // Функция для выхода из системы.
  const handleLogout = async () => {
    dispatch(logoutThunkV2())
      .unwrap()
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        console.error("Error during logout: ", error.errorMessage);
      });
  };

  useEffect(() => {
    if (!isValidSlug(location.pathname)) {
      navigate("/");
    }
  }, [location.pathname, isValidSlug, navigate]);

  const Menu = () => {
    return (
      <>
        <div
          style={{
            width: "100%",
            zIndex: "3999",
            position: "fixed",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "16px",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "16px",
              cursor: "pointer",
            }}
            onClick={handleOpenMainNavigate}
          >
            <span className="material-symbols-outlined">menu</span>
            <div
              style={{
                backgroundColor: "var(--color-black)",
                aspectRatio: "1/1",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "48px",

                borderRadius: "16px",
              }}
            >
              <img
                style={{
                  height: "24px",
                  boxSizing: "border-box",
                }}
                src="https://firebasestorage.googleapis.com/v0/b/biketripste.appspot.com/o/static%2FlogoBikeTripster%2FbikeTripsterWhite.svg?alt=media&token=3409bcaf-ad17-47ff-b3c5-eb83971e650a"
                alt="Logo"
              />
            </div>
          </div>

          {role ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "16px",
              }}
            >
              <span
                style={{
                  cursor: "pointer",
                }}
                onClick={handleOpenWindowLanguage}
                className="material-symbols-outlined"
              >
                translate
              </span>
              <Link
                to="/profile"
                style={{
                  backgroundColor: "var(--color-primary)",
                  height: "48px",
                  aspectRatio: "1/1",
                  borderRadius: "80px",
                  boxSizing: "border-box",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    backgroundColor: "var(--color-white)",
                    height: "44px",
                    aspectRatio: "1/1",
                    borderRadius: "80px",
                    padding: "2px",
                    boxSizing: "border-box",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={handleClose}
                >
                  <div
                    style={{
                      backgroundColor: "var(--color-gray-100)",
                      height: "40px",
                      aspectRatio: "1/1",
                      borderRadius: "80px",
                      boxSizing: "border-box",
                    }}
                  >
                    {isLoading && url ? (
                      <Preloader
                        style={{
                          height: "34px",
                          width: "34px",
                        }}
                      />
                    ) : (
                      <AvatarUser size={40} url={url} />
                    )}
                  </div>
                </div>
              </Link>

              <span
                style={{
                  cursor: "pointer",
                }}
                className="material-symbols-outlined"
                // onClick={handleLogout}
                onClick={handleToggle}
              >
                logout
              </span>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "16px",
              }}
            >
              <span
                style={{
                  cursor: "pointer",
                }}
                className="material-symbols-outlined"
                onClick={handleOpenWindowAuth}
              >
                login
              </span>

              {/* <Link to="/login?page=signIn">SignIn</Link>
              <Link to="/login?page=signUp">SignUp</Link> */}
            </div>
          )}
        </div>

        <PopupWindow isOpen={mainNavegate} onClose={handleClose} title="">
          <WindowNavigete onClose={handleClose} isValidSlug={isValidSlug} />
        </PopupWindow>

        <PopupWindow isOpen={windowAuth} onClose={handleClose} title="">
          <WindowLogIn onClose={handleClose} />
        </PopupWindow>

        <PopupWindow
          isOpen={isOpenWindowLanguage}
          onClose={handleClose}
          title=""
        >
          <WindowLanguage onClose={handleClose} />
        </PopupWindow>

        <PopupAllert
          onConfirm={handleCloseAllertWindow}
          isOpen={isOpenAllertWindow}
          onClose={handleCloseAllertWindow}
          title="Attention!"
        >
          <WidgetAlert
            title="Are you sure you want to log out?"
            description="Oh no, are you sure you want to leave? 🚴‍♂️ We’ll miss you and your bike
          adventures! 😢 But don’t worry, whenever you’re ready to hit the road
          again, we’ll be here with a new set of wheels and plenty of excitement
          waiting for you. 😎 Safe travels! 🌟"
          />
        </PopupAllert>
      </>
    );
  };

  return <Menu />;
};

export default PublicMenu;
