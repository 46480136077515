import React, { useEffect, useState } from "react";
import "../profile.css";
import PopupDirrectory from "../WidgetsProfile/Popups/PopupDirrectory";
import WindowBikeDetail from "./WindowBikeDetail";
import AddData from "../WidgetsProfile/AddData/AddData";
import { useDispatch } from "react-redux";
import { formatCurrencyUSD } from "../../../../../functions/currencyFormatter";
import { setSelectedBike } from "../../../../../reducersToolkit/bikesSliceV2";
import Preloader from "../../../../UIElements/Preloader";
import priorityFile from "../../../../../functions/priorityFile";
import WindowBikeDetailModeration from "../Moderation/WindowBikeDetailModeration";
import { setSelectedBikeModeration } from "../../../../../reducersToolkit/moderationSliceV2";
import PhotosGallery from "../WidgetsProfile/PhotosGallery/PhotosGallery";

const getMonthName = (monthNumber) => {
  const date = new Date(2000, monthNumber, 1);
  const monthName = date.toLocaleString("en-US", { month: "long" });
  return monthName;
};

const BikeItemPreview = ({
  month,
  photos,
  rate,
  title,
  descriptions,
  onClick,
  itemKey,
  brand,
  category,
}) => {
  const monthName = getMonthName(month);
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();

  const urlPriorityFile = priorityFile(photos);

  // Находим максимальную цену
  if (!rate) {
    return;
  }
  const maxPrice = Math.max(...rate?.map((rateItem) => rateItem?.price));

  // Функция для расчета высоты блока
  const calculateHeight = (price) => {
    const maxHeight = 50; // Максимальная высота в пикселях
    return (price / maxPrice) * maxHeight;
  };

  return (
    <div
      key={itemKey}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: "8px",
        height: "100%",
        cursor: "pointer",
        boxSizing: "border-box",
      }}
    >
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "8px",
        }}
      >
        <PhotosGallery photos={photos} onClick={onClick} />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            gap: "8px",
          }}
        >
          <div
            style={{
              padding: "4px 8px",
              backgroundColor: "var(--color-gray-100)",
              borderRadius: "4px",
            }}
          >
            <p className="p-light">{brand ? brand : "No brand"}</p>
          </div>

          <div
            style={{
              padding: "4px 8px",
              backgroundColor: "var(--color-gray-100)",
              borderRadius: "4px",
            }}
          >
            <p className="p-light">{category ? category : "No category"}</p>
          </div>
        </div>

        <h6>{title}</h6>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "end",
          justifyContent: "space-between",
          gap: "2px",
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        {rate?.map((rateItem, index) => (
          <div
            title={`${getMonthName(index)} — ${formatCurrencyUSD(
              rateItem?.price
            )}`}
            key={index}
            style={{
              height: `${calculateHeight(rateItem.price)}px`,
              minHeight: "8px",
              width: "100%",
              borderRadius: "4px",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "flex-end",
              ...(index === currentMonth
                ? {
                    minHeight: "22px",
                    padding: "2px 4px",
                    backgroundColor: "var(--color-success)",
                  }
                : {
                    backgroundColor: "var(--color-gray-100)",
                  }),
            }}
          >
            {index === currentMonth && (
              <p
                className="p-light"
                style={{
                  color: "var(--color-white)",
                }}
              >
                {formatCurrencyUSD(rateItem?.price)}
              </p>
            )}
          </div>
        ))}
      </div>

      {/* <p className="p-light">{descriptions}</p> */}
    </div>
  );
};

const WidgetBikesList = ({
  mode,
  bikesList,
  isLoadingBikes,
  brands,
  categories,
}) => {
  const dispatch = useDispatch();
  const [windowNewBikeDetail, setWindowNewBikeDetail] = useState(false);
  const [windowEditBikeDetail, setWindowEditBikeDetail] = useState(false);
  const [windowBikeDetailModeration, setWindowBikeDetailModeration] =
    useState(false);
  const [windowNewBikeDetailModeration, setWindowNewBikeDetailModeration] =
    useState(false);

  const handleClose = () => {
    setWindowNewBikeDetail(false);
    setWindowEditBikeDetail(false);
    setWindowBikeDetailModeration(false);
    setWindowNewBikeDetailModeration(false);
  };

  const handleToggleNewBike = () => {
    if (mode === "moderation") {
      dispatch(setSelectedBike({}));
      setWindowNewBikeDetailModeration(true);
    } else {
      dispatch(setSelectedBike({}));
      setWindowNewBikeDetail(true);
    }
  };

  const handleToggleEditBike = (data) => {
    if (mode === "moderation") {
      dispatch(setSelectedBikeModeration(data));
      setWindowBikeDetailModeration(true);
    } else {
      dispatch(setSelectedBike(data));
      setWindowEditBikeDetail(true);
    }
  };

  const findBrandById = (id) => {
    if (!brands) return "";
    const brand = brands.find((brand) => brand.id === id);
    const titleBrand = brand?.title?.titleOriginal || "";
    return titleBrand || "";
  };

  const findCategoryById = (id) => {
    if (!categories) return "";
    const category = categories.find((category) => category.id === id);
    const titleCategory = category?.title?.titleOriginal || "";
    return titleCategory || "";
  };

  return isLoadingBikes ? (
    <Preloader />
  ) : (
    <>
      <div className="profile-widget__content-box">
        <AddData
          icon={<span className="material-symbols-outlined">add</span>}
          title="Add bike"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          onClick={handleToggleNewBike}
        />
        {bikesList?.length > 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              backgroundColor: "var(--color-white)",
              boxSizing: "border-box",
            }}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                width: "100%",
                gap: "16px",
              }}
            >
              {bikesList?.map((bikeItem, index) => (
                <BikeItemPreview
                  key={index}
                  itemKey={index}
                  month={bikeItem?.rate?.month}
                  bikeStatus={bikeItem?.bikeStatus}
                  title={bikeItem?.basicInformation?.title?.titleOriginal}
                  descriptions={
                    bikeItem?.basicInformation?.description?.descriptionOriginal
                  }
                  rate={bikeItem?.rate}
                  photos={bikeItem?.files?.bikePhotoFiles}
                  onClick={() => handleToggleEditBike(bikeItem)}
                  brand={findBrandById(bikeItem?.directory?.brandId)}
                  category={findCategoryById(bikeItem?.directory?.categoryId)}
                />
              ))}
            </div>
          </div>
        )}
      </div>

      <PopupDirrectory
        isOpen={windowNewBikeDetail}
        onClose={handleClose}
        title="Add new bike"
      >
        <WindowBikeDetail mode="new" onClose={handleClose} />
      </PopupDirrectory>

      <PopupDirrectory
        isOpen={windowEditBikeDetail}
        onClose={handleClose}
        title="Edit bike"
      >
        <WindowBikeDetail mode="edit" onClose={handleClose} />
      </PopupDirrectory>

      <PopupDirrectory
        isOpen={windowBikeDetailModeration}
        onClose={handleClose}
        title="Edit bike moderation"
      >
        <WindowBikeDetailModeration mode="moderation" onClose={handleClose} />
      </PopupDirrectory>

      <PopupDirrectory
        isOpen={windowNewBikeDetailModeration}
        onClose={handleClose}
        title="Add new bike moderation"
      >
        <WindowBikeDetailModeration
          mode="moderatorAdded"
          onClose={handleClose}
        />
      </PopupDirrectory>
    </>
  );
};

export default WidgetBikesList;
