import { useState } from "react";
import SearchBars from "../../../../../UIComponents/bars/searchBars/SearchBars";
import WigetButtonDelete from "../../../../../UniversalWidgets/WigetButtonDelete";
import { WigetCharacteristics } from "./WigetCharacteristics";
import { WigetRate } from "./WigetRate";
import { WigetBrandCheck } from "../../Directory/Brand/WigetBrand/WigetBrandDetail";
import { WigetСategoriesBikeCheck } from "../../Directory/СategoriesBike/WigetСategoriesBike/WigetСategoriesBikeDetail";
import { useEffect } from "react";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { WigetInputV2 } from "../../../../../UniversalWidgets/WigetInputV2";
import PreviewGallery from "../../../../../UIComponents/bars/gallery/PreviewGallery";
import { WigetTextareaV2 } from "../../../../../UniversalWidgets/WigetTextareaV2";
import Loading from "../../../../../UIElements/Loading";

const WigetBikeDetail = (props) => {
  const activeBikeId = useSelector(
    (storage) => storage.stateGUISlice.currentBike?.id || null
  );
  const activeBikeTitle = useSelector(
    (storage) =>
      storage.stateGUISlice.currentBike?.title?.[0] ||
      "No matching bike name found"
  );

  const [loadingComponent, setLoadingComponent] = useState(false);

  useEffect(() => {
    if (activeBikeId) {
      setLoadingComponent(true);

      const timeoutId = setTimeout(() => {
        setLoadingComponent(false);
      }, 1000);

      return () => clearTimeout(timeoutId);
    }
  }, [activeBikeId]);

  if (loadingComponent) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <Loading />
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "space-between",
        height: "100%",
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      <SearchBars
        title={
          activeBikeTitle ? activeBikeTitle : "No matching bike name found"
        }
        iconLeft={
          <div />
          // <p
          //     onClick={() => props.handleItemRentalPointClick(props.activeRentalPointIndex, props.activeRentalPointId)}
          // >
          //     Назад
          // </p>
        }
        iconRight={
          <WigetButtonDelete
            collectionName={"rentalPoints"}
            documentId={activeBikeId}
            title="Delete"
            icon={<span className="material-symbols-outlined">delete</span>}
          />

          // <WigetButtonDelete
          //     collectionName='bikes'
          //     documentId={activeBikeId}
          //     title="Delete bike"
          //     onClick={() => props.handleItemRentalPointClick(props.activeRentalPointIndex, props.activeRentalPointId)}
          // />
        }
      />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
        className="scrollBox"
      >
        {activeBikeId && (
          <PreviewGallery
            aspectRatio="16/9"
            title="Bike photo"
            collectionName="bikes"
            idDocument={activeBikeId}
            fieldName="file_bikePhoto"
            gallery={true}
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          />
        )}

        {activeBikeId && (
          <WigetInputV2
            title="Bike name"
            collectionName="bikes"
            idDocument={activeBikeId}
            fieldName="title"
            quantity={1}
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          />
        )}

        {activeBikeId && (
          <WigetСategoriesBikeCheck
            title="Select Category"
            collectionName="category"
            fieldName="categoryes_id"
            parentCollectionName="bikes"
            documentId={activeBikeId}
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          />
        )}

        {activeBikeId && (
          <WigetBrandCheck
            title="Select brand"
            collectionName="brand"
            fieldName="brand_id"
            parentCollectionName="bikes"
            documentId={activeBikeId}
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          />
        )}

        {activeBikeId && (
          <WigetRate
            title="Rate bike"
            collectionName="bikes"
            idDocument={activeBikeId}
            fieldName="rate"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          />
        )}

        {activeBikeId && (
          <WigetTextareaV2
            title="Bike description"
            collectionName="bikes"
            idDocument={activeBikeId}
            fieldName="description"
            quantity={1}
            maxLength={3000}
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          />
        )}

        {activeBikeId && (
          <WigetCharacteristics
            title="Characteristics"
            collectionName="bikes"
            idDocument={activeBikeId}
            fieldName="characteristics"
            quantity={10}
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          />
        )}
      </div>
    </div>
  );
};
export default WigetBikeDetail;
