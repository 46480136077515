import React, { useEffect, useState } from "react";
import ContentBox from "../../WidgetsProfile/ContentBox/ContentBox";
import WindgetUploadFiles from "../../WidgetsProfile/UploadFiles/WindgetUploadFiles";
import WidgetListFile from "../../WidgetsProfile/UploadFiles/WidgetListFile";
import { subscribeToDocument } from "../../../../../../services/firebase/crudFirestore";

const WindowUploadFiles = ({ collectionName, documentId, fieldName }) => {
  const [files, setFiles] = useState([]);

  const getNestedField = (obj, path) => {
    return path.split(".").reduce((acc, part) => acc && acc[part], obj);
  };

  useEffect(() => {
    const unsubscribe = subscribeToDocument(
      collectionName,
      documentId,
      null,
      async (data) => {
        setFiles(data ? getNestedField(data, fieldName) : []);
      }
    );
    return () => {
      console.log("unsubscribe");
      unsubscribe();
    };
  }, [documentId, fieldName, collectionName]);

  return (
    <ContentBox position="one">
      <WindgetUploadFiles
        collectionName={collectionName}
        documentId={documentId}
        fieldName={fieldName}
      />

      {files?.length > 0 && (
        <WidgetListFile
          collectionName={collectionName}
          documentId={documentId}
          fieldName={fieldName}
          files={files}
          mode="edit"
        />
      )}
    </ContentBox>
  );
};

export default WindowUploadFiles;
