import React from "react";
// import PrivateMenu from "../Menu/PrivateMenu";
import WigetCityBox from "./WigetCityBox";

const UICity = () => {
  return (
    <div className="private_profile-mainBox">
      {/* <PrivateMenu /> */}
      <div className="private_profile">
        <div className="private_content-box">
          <div
            style={{
              height: "calc(100vh - 64px)",
            }}
            className="private_wiget-big-box"
          >
            <WigetCityBox />
          </div>

          {/* <div className="private_wiget-big-box" >
                        <WigetFAQListBox />
                    </div> */}
        </div>
      </div>
    </div>
  );
};

export default UICity;
