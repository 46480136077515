import React, { useState } from "react";

const WigetLanguage = () => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded((prevExpanded) => !prevExpanded);
    };

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '16px',
            position: 'relative',
            cursor: 'pointer'

        }}
            onClick={toggleExpand}
        >
            {isExpanded && (
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '16px',
                    position: 'absolute',
                    backgroundColor: 'var(--color-black)',
                    bottom: '32px',
                    left: '0',
                    borderRadius: '8px',
                    padding: '24px',
                    boxSizing: 'border-box',
                    color: 'var(--color-white)',
                    width: '135px'
                }}>

                    <div>
                        English
                    </div>

                    <div>
                        French
                    </div>

                    <div>
                        Spanish
                    </div>

                    <div>
                        German
                    </div>

                    <div>
                        Italian
                    </div>

                </div>
            )}


            <span className="material-symbols-outlined">
                g_translate
            </span>

            <p>
                English
            </p>

            <span className="material-symbols-outlined">
                unfold_more
            </span>

        </div>
    )
}

export default WigetLanguage