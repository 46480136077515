import { createAsyncThunk } from "@reduxjs/toolkit";
import { doc, updateDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../../../../services/firebase/authFirebase";

export const deleteCityThunkV4 = createAsyncThunk(
  "city/deleteCityThunkV4",
  async ({ countryId, cityId }, { rejectWithValue }) => {
    try {
      // Получение ссылки на документ города внутри коллекции страны
      const docRef = doc(db, `catalog/${countryId}/cities`, cityId);

      // Обновление поля remove с текущим timestamp
      await updateDoc(docRef, {
        remove: serverTimestamp(),
      });

      console.log(`Город с ID ${cityId} успешно отмечен как удаленный.`);
      return { countryId, cityId, remove: Date.now() };
    } catch (error) {
      console.error("Ошибка при удалении города:", error);
      return rejectWithValue(error.message);
    }
  }
);
