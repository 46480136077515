import { createAsyncThunk } from "@reduxjs/toolkit";

import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

import interpretAuthErrorV2 from "../../../functions/interpretAuthErrorV2";
import { fetchDocumentById } from "../../../services/firebase/crudFirebaseStorage";

export const emailSignInThunkV2 = createAsyncThunk(
  "reducersToolkit/emailSignInThunkV2",
  async ({ email, password }, { rejectWithValue }) => {
    const auth = getAuth();

    try {
      // Аутентифицируем пользователя с помощью Firebase Auth
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      // Проверяем, аутентифицирован ли пользователь
      if (!userCredential.user) {
        console.error("User is not authenticated.");
        throw new Error("User is not authenticated.");
      }

      // Чтение данных пользователя из Firestore и получение ID добавленного документа
      const currentUser = await fetchDocumentById(
        "users",
        userCredential.user.uid
      );

      // Возвращаем данные пользователя вместе с ID добавленного документа
      return { currentUser: currentUser };
    } catch (error) {
      const errorMessage = interpretAuthErrorV2(error.code);
      console.error("Failed to sign in user. Error: ", errorMessage);
      return rejectWithValue({ errorMessage });
    }
  }
);
