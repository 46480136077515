import React from "react";
import PhotosGallery from "../../Private/Login/Profile/WidgetsProfile/PhotosGallery/PhotosGallery";
import WidgetRate from "./WidgetRate";

const WidgetBikeDetail = ({
  bikePhotoFiles = [],
  isLoading = true,
  brandTitle = "No brand",
  categoryTitle = "No title",
  titleBike = "No title",
  descriptionBike = "No description",
  startBookingDates = 0,
  endBookingDates = 0,
  rate = [],
}) => {
  return (
    <>
      <div
        style={{
          width: "100%",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          backgroundColor: "var(--color-white)",
          borderRadius: "24px",
          padding: "8px",
        }}
      >
        <PhotosGallery
          photos={bikePhotoFiles}
          isLoading={isLoading}
          style={{
            width: "100%",
          }}
        />

        <div
          style={{
            padding: "0 16px 16px 16px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "16px",
          }}
        >
          <h5>{titleBike}</h5>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "4px",
            }}
          >
            <p
              style={{
                backgroundColor: "var(--color-gray-100)",
                padding: "4px 8px",
                borderRadius: "4px",
              }}
              className="p-light"
            >
              {brandTitle}
            </p>
            <p
              style={{
                backgroundColor: "var(--color-gray-100)",
                padding: "4px 8px",
                borderRadius: "4px",
              }}
              className="p-light"
            >
              {categoryTitle}
            </p>
          </div>

          <WidgetRate
            startBookingDates={startBookingDates}
            endBookingDates={endBookingDates}
            rate={rate}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "2px",
            }}
          >
            {/* <h5>{titleBike}</h5> */}
            <p className="p-light">{descriptionBike}</p>
          </div>

          {/* <Table
              columns={1}
              title={titleRentalPoint}
              data={characteristicsBike?.map((item) => ({
                typeCell: "column",
                title: item.title.titleOriginal || "no title",
                parameter: <p>{item.description.descriptionOriginal}</p>,
                // parameter: <PhotosGallery photos={bikePhotoFiles} />,
              }))}
            /> */}
        </div>
      </div>
    </>
  );
};

export default WidgetBikeDetail;
