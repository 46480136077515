import React, { useEffect, useState } from "react";
import WidgetLocationModeration from "./WidgetLocationModeration";
import WidgetRentalPointTimetable from "../RentalPointsV3/WidgetRentalPointTimetable";
import WidgetAditionalServices from "../RentalPointsV3/WidgetAditionalServices";
import WidgetRentalPointContact from "../RentalPointsV3/WidgetRentalPointContact";
import WidgetRentalPointBasicInformation from "../RentalPointsV3/WidgetRentalPointBasicInformation";
import WidgetRentalPointFilesBar from "../RentalPointsV3/WidgetRentalPointFilesBar";
import { useDispatch, useSelector } from "react-redux";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import WidgetBikesList from "../BikesV3/WidgetBikesList";
import WidgetRentalPointModeration from "./WidgetRentalPointModeration";

const WindowRentalPointDetailModeration = ({ mode, onClose }) => {
  const dispatch = useDispatch();

  const [user, setUser] = useState({});

  const [basicInformation, setBasicInformation] = useState({});
  const [location, setLocation] = useState({});
  const [contacts, setContacts] = useState({});
  const [additionalServices, setAdditionalServices] = useState({});
  const [timetable, setTimetable] = useState([]);
  const [bikesList, setBikesList] = useState([]);
  const [currentRentalPoint, setCurrentRentalPoint] = useState({});

  const { currentUser } = useSelector((state) => state.userSliceV2);

  const { selectedUserRentalPoints, selectedUser, users, isLoadingBikes } =
    useSelector((state) => state.moderationSliceV2);

  const { selectedRentalPointBikes, selectedRentalPoint, newRentalPoint } =
    useSelector((state) => state.moderationSliceV2);

  const { countries, cities, brands, categories } = useSelector(
    (state) => state.directorySliceV2
  );

  useEffect(() => {
    setUser(selectedUser);
  }, [selectedUser]);

  useEffect(() => {
    if (mode === "moderatorAdded") {
      setBasicInformation(newRentalPoint.basicInformation);
      setLocation(newRentalPoint.location);
      setContacts(newRentalPoint.contacts);
      setAdditionalServices(newRentalPoint.additionalServices);
      setTimetable(newRentalPoint.timetable);
      setCurrentRentalPoint(newRentalPoint);
      setBikesList([]);
    } else if (mode === "moderation") {
      setBasicInformation(selectedRentalPoint.basicInformation);
      setLocation(selectedRentalPoint.location);
      setContacts(selectedRentalPoint.contacts);
      setAdditionalServices(selectedRentalPoint.additionalServices);
      setTimetable(selectedRentalPoint.timetable);
      setCurrentRentalPoint(selectedRentalPoint);
    }
  }, [newRentalPoint, selectedRentalPoint, mode]);

  useEffect(() => {
    if (mode === "moderation") {
      setBikesList(selectedRentalPointBikes);
    }
  }, [selectedRentalPointBikes, mode]);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "8px",
      }}
    >
      {/* ------------- Rental point info ------------- */}
      <div className="block-section  block-section__start-box">
        <div className="profile-block-section__content-box">
          <WidgetRentalPointBasicInformation
            mode={mode}
            basicInformation={basicInformation}
          />
        </div>
        {mode !== "moderatorAdded" && (
          <WidgetRentalPointFilesBar
            selectedRentalPoint={selectedRentalPoint}
          />
        )}
      </div>

      {/* ------------- Moderation location ------------- */}
      <ContentBox>
        <WidgetLocationModeration
          location={location}
          countries={countries}
          cities={cities}
          ancorId="ancor1"
          mode={mode}
        />
      </ContentBox>
      {/* ------------- Bikes ------------- */}
      {mode !== "moderatorAdded" && (
        <ContentBox>
          <WidgetBikesList
            bikesList={bikesList}
            brands={brands}
            categories={categories}
            mode={mode}
          />
        </ContentBox>
      )}

      {/* ------------- Timetable ------------- */}
      <ContentBox>
        <WidgetRentalPointTimetable mode={mode} timetable={timetable} />
      </ContentBox>
      {/* ------------- Contact ------------- */}
      <ContentBox>
        <WidgetRentalPointContact mode={mode} contacts={contacts} />
      </ContentBox>
      {/* ------------- Aditional services ------------- */}
      <ContentBox>
        <WidgetAditionalServices
          mode={mode}
          additionalServices={additionalServices}
        />
      </ContentBox>

      <ContentBox position="end">
        <WidgetRentalPointModeration
          mode={mode}
          newRentalPoint={newRentalPoint}
          selectedRentalPoint={selectedRentalPoint}
          currentRentalPoint={currentRentalPoint}
          currentUser={user}
          onClose={onClose}
        />
      </ContentBox>
    </div>
  );
};

export default WindowRentalPointDetailModeration;
