import React, { useEffect, useState } from "react";
import AddData from "../../WidgetsProfile/AddData/AddData";
import InputText from "../../WidgetsProfile/Inputs/InputText";
import Textarea from "../../WidgetsProfile/Textarea/Textarea";
import Button from "../../WidgetsProfile/Buttons/Button";
import { useDispatch, useSelector } from "react-redux";
import ContentBox from "../../WidgetsProfile/ContentBox/ContentBox";
import PopupDirrectory from "../../WidgetsProfile/Popups/PopupDirrectory";
import WidgetCitiesListV4 from "./WidgetCitiesListV4";
import ButtonsBar from "../../WidgetsProfile/ButtonsBar/ButtonsBar";
import { createCountryThunkV4 } from "../../../../../../reducersToolkit/thunk/catalog/V4/createCountryThunkV4";
import { v4 as uuidv4 } from "uuid";
import WindowCityDetailV4 from "../CitiesV4/WindowCityDetailV4";
import WidgetUploadFilesV4 from "../../WidgetsProfile/UploadFiles/WindgetUploadFilesV4";
import WidgetListFileV4 from "../../WidgetsProfile/UploadFiles/WidgetListFileV4";
import { deleteCountryThunkV4 } from "../../../../../../reducersToolkit/thunk/catalog/V4/deleteCountryThunkV4";
import { setCurrentCity } from "../../../../../../reducersToolkit/catalogSliceV4";
import { updateCountryThunkV4 } from "../../../../../../reducersToolkit/thunk/catalog/V4/updateCountryThunkV4";
import WidgetAlert from "../../WidgetsProfile/Alerts/WidgetAlert";

import { fetchCitiesDataThunkV4 } from "../../../../../../reducersToolkit/thunk/catalog/V4/fetchCatalogData/fetchCitiesDataThunkV4";

const WindowCountryDetailV4 = ({ mode, onClose }) => {
  const dispatch = useDispatch();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [previewPhotoFiles, setPreviewPhotoFiles] = useState([]);
  const [photoFiles, setPhotoFiles] = useState([]);
  const [windowCityDetail, setWindowCityDetail] = useState(false);
  const [countryId, setCountryId] = useState(0);
  const [cities, setCities] = useState([]);

  const [originalTitle, setOriginalTitle] = useState("");
  const [originalDescription, setOriginalDescription] = useState("");

  useEffect(() => {
    if (countryId) {
      // dispatch(fetchDataThunkV4({ countryId }));
      dispatch(fetchCitiesDataThunkV4({ countryId }));
    }
  }, [dispatch, countryId]);

  const {
    countries,
    currentCountry,
    loadingPriority,
    updateCountryLoading,
    createCityLoading,
    createCountryLoading,
    loadingCities,
  } = useSelector((state) => state.catalogSliceV4);

  const { currentUser } = useSelector((state) => state.userSliceV2);

  console.log("WindowCountryDetailV4 countries", countries);

  useEffect(() => {
    const countryTitle = currentCountry?.title?.titleOriginal || "";
    const countryDescription =
      currentCountry?.description?.descriptionOriginal || "";

    setTitle(countryTitle);
    setDescription(countryDescription);
    setOriginalTitle(countryTitle);
    setOriginalDescription(countryDescription);
    setPreviewPhotoFiles(currentCountry?.files?.iconFiles || []);
    setCountryId(currentCountry?.id || 0);
  }, [currentCountry]);

  useEffect(() => {
    setCities(currentCountry?.cities || []);
  }, [currentCountry.cities]);

  const countryData = {
    ...currentCountry,
    title: {
      titleOriginal: title,
    },
    description: {
      descriptionOriginal: description,
    },
    createdat: Date.now(),
    visibility: false,
    remove: null,
  };

  const handleOpenWindowCityDetail = () => {
    dispatch(setCurrentCity({}));
    setWindowCityDetail(true);
  };

  const handleClose = () => {
    setWindowCityDetail(false);
  };

  const handleSetName = (event) => {
    setTitle(event.target.value);
  };

  const handleSetDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleDeleDirectory = () => {
    dispatch(deleteCountryThunkV4({ countryId: countryId }));
    onClose();
  };

  const handleAddDocument = async () => {
    try {
      if (mode === "new") {
        dispatch(
          createCountryThunkV4({
            countryId: uuidv4(),
            data: countryData,
            files: photoFiles,
          })
        )
          .unwrap()
          .then((response) => {
            console.log("Страна успешно создана:", response);
            onClose();
          })
          .catch((error) => {
            console.error("Ошибка при создании страны:", error);
          });
      } else if (mode === "edit") {
        dispatch(
          updateCountryThunkV4({
            countryId, // Передаем идентификатор страны
            updatedData: countryData,
            newFiles: photoFiles, // Передаем новые файлы для загрузки, если они есть
          })
        )
          .unwrap()
          .then((response) => {
            console.log("Страна успешно обновлена:", response);
            onClose();
          })
          .catch((error) => {
            console.error("Ошибка при обновлении страны:", error);
          });
      }
    } catch (error) {
      console.error("Ошибка при создании или обновлении страны:", error);
    }
  };

  const isButtonActive =
    photoFiles.length > 0 ||
    title !== originalTitle ||
    description !== originalDescription;

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          ...(mode === "new" && {
            marginBottom: "72px",
          }),
        }}
      >
        <ContentBox
          position="start"
          isLoading={updateCountryLoading || createCountryLoading}
        >
          <WidgetListFileV4
            isLoading={loadingPriority}
            collectionName="catalog"
            files={previewPhotoFiles}
            documentId={countryId}
            fieldName="files.iconFiles"
            mode="edit"
          />

          <WidgetUploadFilesV4
            id="setPhotoFiles5"
            title="Add photo country"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            files={setPhotoFiles}
          />

          <InputText
            id="Name"
            label="Country"
            description="Enter the name of the country."
            value={title}
            onChange={handleSetName}
            type="text"
          />

          <Textarea
            label="Description"
            maxLength={5000}
            value={description}
            onChange={handleSetDescription}
          />

          {mode === "edit" && (
            <div
              style={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                alignItems: "flex-start",
                gap: "8px",
              }}
            >
              <Button
                type="small"
                label="Cancel"
                active={true}
                onClick={() => onClose()}
                color="--color-black"
              />
              <Button
                type="small"
                label="Delete"
                active={true}
                onClick={() => handleDeleDirectory()}
                color="--color-alarm"
                allert={
                  <WidgetAlert
                    title={`Are you sure you want to add the page "${title}" to the directory?`}
                  />
                }
              />

              <Button
                type="small"
                label="Save"
                active={isButtonActive}
                onClick={() => handleAddDocument()}
                allert={
                  <WidgetAlert
                    title={`Are you sure you want to add the page "${title}" to the directory?`}
                  />
                }
              />
            </div>
          )}
        </ContentBox>

        {mode === "new" && (
          <ButtonsBar>
            <div
              style={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                alignItems: "flex-start",
                gap: "8px",
              }}
            >
              <Button
                type="small"
                label="Cancel"
                active={true}
                onClick={() => onClose()}
                color="--color-black"
              />

              <Button
                type="small"
                label="Save"
                active={title !== "" && description !== ""}
                onClick={() => handleAddDocument()}
                allert={
                  <WidgetAlert
                    title={`Are you sure you want to add the page "${title}" to the directory?`}
                  />
                }
              />
            </div>
          </ButtonsBar>
        )}

        {mode === "edit" && (
          <ContentBox position="end" isLoading={createCityLoading}>
            <AddData
              onClick={handleOpenWindowCityDetail}
              icon={<span className="material-symbols-outlined">add</span>}
              title="Add city"
              description="Manage the files associated with this country."
            />

            <WidgetCitiesListV4 cities={cities} isLoading={loadingCities} />
          </ContentBox>
        )}
      </div>

      <PopupDirrectory
        isOpen={windowCityDetail}
        onClose={handleClose}
        title={`Add city V4`}
      >
        <WindowCityDetailV4 mode="new" onClose={handleClose} />
      </PopupDirrectory>
    </>
  );
};

export default WindowCountryDetailV4;
