import { createAsyncThunk } from "@reduxjs/toolkit";

// Импорты ваших thunk-ов для получения данных
import { fetchBikeData } from "./fetchBikeData";
import { fetchBrandData } from "./fetchBrandData";
import { fetchCategoryData } from "./fetchCategoryData";
import { fetchCityData } from "./fetchCityData";
import { fetchCountryData } from "./fetchCountryData";
import { fetchCurrencyData } from "./fetchCurrencyData";
import { fetchRentalPointsData } from "./fetchRentalPointsData";
import { fetchStoriesData } from "./fetchStoriesData";
import { fetchNewsData } from "./fetchNewsData";
import { fetchCommentsData } from "./fetchCommentsData";
export const fetchAllDataThunk = createAsyncThunk(
  "fetchAllDataThunk/front",
  async (_, { dispatch }) => {
    try {
      // Диспатчим все асинхронные действия и ждем, пока они завершатся
      const [
        bikes,
        brands,
        categories,
        cities,
        countries,
        currencies,
        rentalPoints,
        stories,
        news,
        comments,
      ] = await Promise.all([
        dispatch(fetchBikeData()).then((response) => response.payload),
        dispatch(fetchBrandData()).then((response) => response.payload),
        dispatch(fetchCategoryData()).then((response) => response.payload),
        dispatch(fetchCityData()).then((response) => response.payload),
        dispatch(fetchCountryData()).then((response) => response.payload),
        dispatch(fetchCurrencyData()).then((response) => response.payload),
        dispatch(fetchRentalPointsData()).then((response) => response.payload),
        dispatch(fetchStoriesData()).then((response) => response.payload),
        dispatch(fetchNewsData()).then((response) => response.payload),
        dispatch(fetchCommentsData()).then((response) => response.payload),
      ]);
      //-----------------------
      // Функция для создания полной структуры данных на основе точки проката
      // ----------------------
      const createFullDataRentalPoints = () => {
        console.log("fetchAllData-fullDataRentalPoints-start");
        // console.log(
        //   "fetchAllData-fullDataRentalPoints-rentalPoints",
        //   rentalPoints
        // );

        return rentalPoints.map((rentalPoint) => {
          // Преобразование массива id велосипедов в массив данных велосипедов
          const bikesData =
            rentalPoint.bikes_id?.map((bikeId) => {
              // Находим велосипед по его id
              const bike = bikes.find((bike) => bike.id === bikeId);
              // Находим категорию по id категории велосипеда
              const bikeCategory =
                categories.find(
                  (category) => category.id === bike.categoryes_id
                ) || {};
              // Находим бренд по id бренда велосипеда
              const bikeBrand =
                brands.find((brand) => brand.id === bike.brand_id) || {};
              // Возвращаем новый объект велосипеда с добавленной информацией о категории и бренде
              return {
                ...bike,
                category: bikeCategory, // Добавляем информацию о категории
                brand: bikeBrand, // Добавляем информацию о бренде
              };
            }) || [];

          const cityData =
            cities.find((city) => city.id === rentalPoint.city_id[0]) || [];

          const countryData =
            countries.find((country) =>
              country.city_id?.includes(rentalPoint.city_id[0])
            ) || [];

          const currencyData =
            rentalPoint.currency_id?.map((currencyId) =>
              currencies.find((currency) => currency.id === currencyId)
            ) || [];

          return {
            ...rentalPoint,
            bikes: bikesData,
            city: cityData,
            country: countryData,
            currency: currencyData,
          };
        });
      };

      // Создание массива данных
      const fullDataRentalPoints = createFullDataRentalPoints();
      // console.log("fetchAllData-fullDataRentalPoints", fullDataRentalPoints);
      //-----------------------
      // Функция для создания полной структуры данных на основе байка
      // -----------------------
      // Функция для создания полной структуры данных о велосипедах
      const createFullDataBike = () => {
        return bikes.map((bike) => {
          // Находим категорию по id категории велосипеда
          const bikeCategory =
            categories.find((category) => category.id === bike.categoryes_id) ||
            {};

          // Находим бренд по id бренда велосипеда
          const bikeBrand =
            brands.find((brand) => brand.id === bike.brand_id) || {};

          // Находим все точки аренды, где доступен велосипед
          const bikeRentalPoint =
            rentalPoints.find((point) => point.bikes_id.includes(bike.id)) ||
            null;

          // Если точка аренды найдена, находим город и страну
          let bikeCity = {};
          let bikeCountry = {};
          if (bikeRentalPoint) {
            bikeCity =
              cities.find((city) => city.id === bikeRentalPoint.city_id[0]) ||
              {};
            bikeCountry =
              countries.find((country) =>
                country.city_id.includes(bikeRentalPoint.city_id[0])
              ) || {};
          }

          // Если точка аренды найдена, находим валюту
          let bikeCurrency = {};
          if (bikeRentalPoint && bikeRentalPoint.currency_id?.length > 0) {
            bikeCurrency =
              currencies.find(
                (currency) => currency.id === bikeRentalPoint.currency_id[0]
              ) || {};
          }

          // Возвращаем новый объект с полной структурой данных о велосипеде
          return {
            ...bike,
            category: bikeCategory,
            brand: bikeBrand,
            rentalPoint: bikeRentalPoint,
            city: bikeCity,
            country: bikeCountry,
            currency: bikeCurrency,
          };
        });
      };

      // Создание массива данных о велосипедах
      const fullDataBike = createFullDataBike();
      // console.log("fetchAllData-fullDataBike", fullDataBike);

      // -----------------------
      // НАЙДЕМ СТРАНУ С МАКСИМАЛЬНЫМ КОЛИЧЕСТВОМ БАЙКОВ
      // -----------------------
      // Найдем страну с максимальным количеством городов

      // Создаем карту для подсчета количества байков по странам
      const bikeCountByCountry = {};

      // Инициализируем счетчики для каждой страны
      countries.forEach((country) => {
        bikeCountByCountry[country.id] = 0;
      });

      // Подсчитываем байки в каждой стране
      rentalPoints.forEach((point) => {
        // Найти страну, которой принадлежит точка аренды
        const countryOfRentalPoint = countries.find((country) =>
          country.city_id.includes(point.city_id[0])
        );

        if (countryOfRentalPoint) {
          // Увеличиваем счетчик байков для найденной страны на количество байков в текущей точке аренды
          bikeCountByCountry[countryOfRentalPoint.id] += point.bikes_id.length;
        }
      });

      // Найти страну с максимальным количеством байков
      let maxBikes1 = 0;
      let countryWithMaxBikes = null;

      for (const [countryId, bikeCount] of Object.entries(bikeCountByCountry)) {
        if (bikeCount > maxBikes1) {
          maxBikes1 = bikeCount;
          countryWithMaxBikes = countries.find(
            (country) => country.id === countryId
          );
        }
      }

      const defaultCountry = countryWithMaxBikes;
      // console.log("fetchAllData-defaultCountry", defaultCountry);

      // -------------------------
      // НАЙДЕМ ГОРОД С МАКСИМАЛЬНЫМ КОЛИЧЕСТВОМ БАЙКОВ ИЗ РАНЕЕ НАЙДЕНОЙ СТРАНЕ
      // -----------------------

      // Найдем город с максимальным количеством байков
      let maxBikes = 0;
      let cityWithMostBikes = null;

      countryWithMaxBikes.city_id.forEach((cityId) => {
        // Находим все точки аренды для данного города
        const rentalPointsInCity = rentalPoints.filter((point) =>
          point.city_id.includes(cityId)
        );

        // Подсчитываем уникальные bikes_id
        const uniqueBikesIds = new Set();
        rentalPointsInCity.forEach((point) => {
          point.bikes_id.forEach((bikeId) => uniqueBikesIds.add(bikeId));
        });

        // Если количество уникальных bikes больше текущего максимума, обновляем максимум и сохраняем город
        if (uniqueBikesIds.size > maxBikes) {
          maxBikes = uniqueBikesIds.size;
          cityWithMostBikes = cities.find((city) => city.id === cityId);
        }
      });

      const defaultCity = cityWithMostBikes;
      // console.log("fetchAllData-defaultCity", defaultCity);

      // -------------------------
      // WigetSelectLocation
      // -------------------------

      const countriesData = countries.map((country) => {
        // Находим все города в этой стране
        const countryCities = cities.filter((city) =>
          country.city_id.includes(city.id)
        );

        // Для каждого города находим точки аренды и подсчитываем количество велосипедов
        const citiesWithRentalPoints = countryCities.map((city) => {
          const rentalPointsInCity = rentalPoints.filter((point) =>
            point.city_id.includes(city.id)
          );

          // Подсчитываем количество велосипедов в каждой точке аренды
          const quantityBike = rentalPointsInCity.reduce((count, point) => {
            // Фильтруем велосипеды, которые доступны в данной точке аренды
            const availableBikes = bikes.filter((bike) =>
              point.bikes_id.includes(bike.id)
            );
            return count + availableBikes.length; // Суммируем количество велосипедов
          }, 0);

          return { ...city, quantityBike };
        });

        return {
          ...country,
          cities: citiesWithRentalPoints,
        };
      });
      const fullDataLocation = countriesData;
      // console.log("fetchAllData-fullDataLocation", fullDataLocation);
      // console.log(countriesData);

      // -------------------------
      // WigetSelectLocation end
      // -------------------------
      // -------------------------
      // PriceStatisticsForRentalPoint
      // -------------------------
      const groupedData = countries.map((country) => {
        // Найдем все города в этой стране
        const filtredCities = cities.filter((city) =>
          country.city_id.includes(city.id)
        );

        // Найдем все точки аренды в этих городах
        const filtredRentalPoints = rentalPoints.filter((point) =>
          filtredCities.some((city) => point.city_id.includes(city.id))
        );

        // Найдем все байки в этих точках аренды
        const filterdBikes = bikes.filter((bike) =>
          filtredRentalPoints.some((point) => point.bikes_id.includes(bike.id))
        );

        // Добавляем данные категории и бренда в каждый байк
        const bikesWithDetails = filterdBikes.map((bike) => {
          // Находим категорию байка
          const filterdCategory = categories.find(
            (category) => category.id === bike.categoryes_id
          );
          // Находим бренд байка
          const filterdBrand = brands.find(
            (brand) => brand.id === bike.brand_id
          );
          // Возвращаем новый объект байка с информацией о категории и бренде
          return {
            ...bike,
            category: filterdCategory, // Добавляем объект категории целиком, либо только необходимые поля
            brand: filterdBrand, // Добавляем объект бренда целиком, либо только необходимые поля
          };
        });

        // Найдем среднюю цену велосипедов в стране
        const prices = filterdBikes.flatMap((bike) =>
          bike.rate.map((rateItem) => Number(rateItem.price))
        );

        const totalPrices = prices.reduce((sum, price) => sum + price, 0);
        const avragePriceBikesInCountry =
          prices.length > 0 ? totalPrices / prices.length : 0;

        // Найдем среднюю скидку велосипедов в стране
        const discount = filterdBikes.flatMap((bike) =>
          bike.rate.map((rateItem) => Number(rateItem.discount))
        );
        const totaldiscount = discount.reduce(
          (sum, discount) => sum + discount,
          0
        );
        const avrageDiscountBikesInCountry =
          discount.length > 0 ? totaldiscount / discount.length : 0;

        // Инициализируем массив для сбора средней цены и скидки за каждый месяц

        const avrageRate = Array.from({ length: 12 }, () => ({
          totalPrice: 0,
          totalDiscount: 0,
          count: 0,
        }));

        // Собираем данные по ценам и скидкам для каждого месяца
        bikes.forEach((bike) => {
          bike.rate.forEach((rateItem) => {
            const monthIndex = rateItem.month - 1; // Месяцы начинаются с 1, индексы массива с 0
            const price = Number(rateItem.price);
            const discount = Number(rateItem.discount) || 0; // Обработка случаев, когда скидка не указана
            if (!isNaN(price)) {
              avrageRate[monthIndex].totalPrice += price;
              avrageRate[monthIndex].totalDiscount += discount;
              avrageRate[monthIndex].count += 1;
            }
          });
        });

        // Рассчитываем среднюю цену и скидку за каждый месяц
        const avrageRatePerMonth = avrageRate.map((rate, index) => {
          const averagePrice =
            rate.count > 0 ? rate.totalPrice / rate.count : 0;
          const averageDiscount =
            rate.count > 0 ? rate.totalDiscount / rate.count : 0;
          return {
            month: index + 1, // Возвращаем к естественному отсчету месяцев
            averagePrice: averagePrice,
            averageDiscount: averageDiscount,
          };
        });

        return {
          country: country,
          cities: cities,
          rentalPoints: rentalPoints,
          bikes: bikesWithDetails,
          avragePriceBikesInCountry: avragePriceBikesInCountry,
          avrageDiscountBikesInCountry: avrageDiscountBikesInCountry,
          avrageRate: avrageRatePerMonth,
        };
      });

      const statistiscBikesByCountry = groupedData;
      // console.log(
      //   "fetchAllData-statistiscBikesByCountry",
      //   statistiscBikesByCountry
      // );
      // -------------------------
      // PriceStatisticsForRentalPoint end
      // -------------------------
      // console.log("fetchAllData-stories", news);
      // Возвращаем обработанные данные
      return {
        comments,
        news,
        stories,
        bikes,
        brands,
        categories,
        cities,
        countries,
        currencies,
        rentalPoints,
        defaultCountry,
        defaultCity,
        fullDataRentalPoints,
        fullDataLocation,
        fullDataBike,
        statistiscBikesByCountry,
        // ... любые другие обработанные данные ...
      };
    } catch (error) {
      // Обработка ошибок
      console.error(error);
      throw error;
    }
  }
);
