import React, { useState } from "react";
import "./buttons.css";
import PopupAllert from "../Popups/PopupAllert";

const Button = ({
  label,
  icon,
  onClick,
  color,
  allert,
  active,
  type,
  description,
}) => {
  const [isOpenAllertWindow, setIsOpenAllertWindow] = useState(false);

  const handleClose = () => {
    setIsOpenAllertWindow(false);
  };
  const handleToggle = () => {
    setIsOpenAllertWindow(true);
  };

  const handleClickButton = () => {
    if (allert) {
      handleToggle();
    } else {
      onClick && onClick();
    }
  };

  return (
    <>
      <button
        disabled={!active}
        className={`${
          type === "small"
            ? "button__box-small"
            : type === "icon"
            ? "button__icon-box"
            : "button__box"
        }`}
        onClick={handleClickButton}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          gap: "8px",
          ...(active
            ? { backgroundColor: `var(${color ? color : "--color-primary"})` }
            : {
                backgroundColor: "var(--color-gray-200)",
                cursor: "auto",
                color: "var(--color-gray-600)",
              }),
        }}
      >
        {icon && icon}
        {label}
      </button>

      {/* {description && (
        <div
          style={{
            width: "100%",
            padding: "8px 0 0 0",
          }}
        >
          <p className="p-light">{description}</p>
        </div>
      )} */}
      <PopupAllert
        onConfirm={onClick}
        isOpen={isOpenAllertWindow}
        onClose={handleClose}
        title="Attention!"
      >
        {allert}
      </PopupAllert>
    </>
  );
};

export default Button;
