// Импортируем необходимые зависимости и функции
import React, { useState } from "react";
import { structureDb } from "../../services/firebase/structureDb";
import {
  fetchDocumentById,
  addData,
  updateData,
  updateDocumentById,
} from "../../services/firebase/crudFirebaseStorage";
import Loading from "../UIElements/Loading";
import Button from "../UIElements/Button";

/**
 * Компонент WigetButtonAdd, реализующий кнопку для добавления новых элементов или обновления существующих в Firebase.
 *
 * @param {string} collectionName - Название коллекции в Firebase - основная коллекция, в которой находится основная структура данных.
 * @param {string} documentId - Идентификатор документа в Firebase - указывается в том случае, если требуется обновить данные коллекции.
 * @param {string} nameButton - Название кнопки.
 * @param {Object} dataObject - Объект с данными, которые будут добавлены в Firebase - данные для обновления или новые данные для добавления.
 * @param {string} parentCollectionName - Название родительской коллекции в Firebase - родительская коллекция, в которой будет сохранен id на collectionName.
 * @param {string} parentDocumentId - Идентификатор родительского документа в Firebase - документ родительской коллекции, в которой будет сохранен id на collectionName.
 *
 * @returns {JSX.Element} JSX-элемент кнопки.
 */

const WigetButtonAdd = ({
  collectionName,
  documentId,
  nameButton,
  icon,
  dataObject,
  parentCollectionName,
  parentDocumentId,
  freeze,
}) => {
  // Используем хук состояния для отслеживания состояния загрузки
  const [loading, setLoading] = useState(false);

  /**
   * Функция addButton, вызываемая при нажатии на кнопку.
   *
   * @param {string} collectionName - Название коллекции в Firebase.
   * @param {string} documentId - Идентификатор документа в Firebase.
   * @returns {Promise<void>} Промис, указывающий на успешное завершение операции.
   */
  const addButton = async (collectionName, documentId) => {
    // console.log('collectionName', collectionName,
    //     'documentId', documentId,
    //     'nameButton', nameButton,
    //     'dataObject', dataObject,
    //     'parentCollectionName', parentCollectionName,
    //     'parentDocumentId', parentDocumentId)

    try {
      // Начинаем загрузку
      setLoading(true);

      // Проверяем, существует ли данная коллекция в структуре базы данных
      if (!(collectionName in structureDb)) {
        console.log(`Объекта с именем ${collectionName} нет в structureDb.`);
        return;
      }

      // Получаем структуру данных для данной коллекции из объекта structureDb
      const dataObjectStructure = structureDb[collectionName];

      // Проверяем, существует ли документ с данным id в коллекции
      if (documentId) {
        const documentData = await fetchDocumentById(
          collectionName,
          documentId,
          "1 Вызывается из: WigetButtonAdd.js"
        );

        if (!documentData) {
          console.log(`Документ с ID ${documentId} не существует.`);
          return;
        }

        // Проверяем, соответствует ли структура данных объекта структуре данных коллекции
        // if (!validateDataStructure(dataObject, structureDb[collectionName])) {
        //     console.log("Структура объекта данных не соответствует указанной коллекции.");
        //     return;
        // }

        // Обновляем данные в существующем документе
        await updateData(collectionName, documentId, dataObject);
        console.log(`Данные успешно обновлены в документе с ID ${documentId}.`);
      } else {
        // Если документ не существует, создаем новый документ в коллекции с данными по умолчанию
        if (
          !validateDataStructure(
            dataObjectStructure,
            structureDb[collectionName]
          )
        ) {
          console.log(
            "Структура объекта данных не соответствует указанной коллекции.",
            dataObjectStructure
          );
          return;
        }

        const addedData = await addData(collectionName, dataObjectStructure);
        console.log(
          `Новый документ добавлен с ID ${addedData.id} в коллекцию ${collectionName}.`
        );

        // Если задано название родительской коллекции, обновляем родительский документ с новым id
        if (parentCollectionName) {
          // Проверяем наличие родительской коллекции в структуре
          if (parentCollectionName in structureDb) {
            // Проверяем наличие поля с постфиксом _id и collectionName в структуре
            if (`${collectionName}_id` in structureDb[parentCollectionName]) {
              const parentDocument = await fetchDocumentById(
                parentCollectionName,
                parentDocumentId,
                "2 Вызывается из: WigetButtonAdd.js"
              );

              if (!parentDocument) {
                console.log(
                  `Родительский документ с идентификатором ${parentDocumentId} не существует.`
                );
                return;
              }

              // Обновляем родительский документ с новым id
              const updateField = {
                [`${collectionName}_id`]: Array.isArray(
                  parentDocument[`${collectionName}_id`]
                )
                  ? [...parentDocument[`${collectionName}_id`], addedData.id]
                  : [addedData.id],
              };
              await updateDocumentById(
                updateField,
                parentCollectionName,
                parentDocumentId
              );
              console.log(
                `Документ с id ${parentDocumentId} в коллекции ${parentCollectionName} обновлено с id: ${collectionName}_id ${addedData.id}`
              );
            }
          } else {
            console.log(
              `Родительской коллекции с именем ${parentCollectionName} не существует в structureDb.`
            );
            setLoading(false);
            return;
          }
        }
      }

      // Завершаем загрузку
      setLoading(false);
    } catch (error) {
      // В случае ошибки выводим ее в консоль и завершаем загрузку
      console.error("Ошибка при добавлении/обновлении данных:", error);
      setLoading(false);
    }
  };

  /**
   * Функция validateDataStructure используется для валидации структуры данных объекта с данными относительно структуры коллекции.
   *
   * @param {Object} dataObject - Объект с данными.
   * @param {Object} structure - Структура коллекции.
   * @returns {boolean} Результат валидации.
   */
  const validateDataStructure = (dataObject, structure) => {
    for (const key in structure) {
      // Если типы данных ключей не совпадают, возвращаем false
      if (typeof dataObject[key] !== typeof structure[key]) {
        return false;
      }

      // Если значение ключа является объектом, не являющимся массивом, рекурсивно проверяем этот объект
      if (
        typeof structure[key] === "object" &&
        !Array.isArray(structure[key])
      ) {
        if (!validateDataStructure(dataObject[key], structure[key])) {
          return false;
        }
      }
    }

    return true;
  };

  // Возвращаем JSX
  return (
    <>
      {loading ? (
        <Loading />
      ) : icon ? (
        <div
          className={freeze ? `icon44freeze` : "icon44"}
          onClick={() =>
            addButton(collectionName, documentId, nameButton, dataObject)
          }
        >
          {icon}
        </div>
      ) : (
        <Button
          onClick={() =>
            addButton(collectionName, documentId, nameButton, dataObject)
          }
        >
          {nameButton}
        </Button>
      )

      //    <button onClick={() => addButton(collectionName, documentId, nameButton, dataObject)}>
      //         {nameButton}
      //     </button>
      }
    </>
  );
};

export default WigetButtonAdd;

/**
 * Как добавить данные с помощью компонента WigetButtonAdd
 * 1. Импортируйте компонент WigetButtonAdd в файл
 * 2. Используйте компонент WigetButtonAdd и передайте необходимые пропсы:
 * <WigetButtonAdd
        collectionName="название_коллекции"
        nameButton="название_кнопки"
        dataObject={данные_для_добавления}
        parentCollectionName="название_родительской_коллекции"
        parentDocumentId="идентификатор_родительского_документа"
    />
 * collectionName (обязательный): Название коллекции в Firebase, в которую вы хотите добавить данные.
 * nameButton (обязательный): Название кнопки, которая будет отображаться на экране.
 * dataObject (обязательный): Объект с данными, которые вы хотите добавить в коллекцию.
 * parentCollectionName (опциональный): Название родительской коллекции, если вы хотите создать связь между текущей коллекцией и родительской коллекцией.
 * parentDocumentId (опциональный): Идентификатор родительского документа, к которому вы хотите установить связь.
 * 3. При нажатии на кнопку, данные, указанные в dataObject, будут добавлены в указанную коллекцию в Firebase. Если указана родительская коллекция и идентификатор родительского документа, то будет установлена связь между текущей коллекцией и родительской коллекцией.    
 */

/**
 * Как обновить данные с помощью компонента WigetButtonAdd
 * 1. Импортируйте компонент WigetButtonAdd в файл
 * 2. Используйте компонент WigetButtonAdd и передайте необходимые пропсы:
 *  <WigetButtonAdd
        collectionName="название_коллекции"
        documentId="идентификатор_документа"
        nameButton="название_кнопки"
        dataObject={данные_для_обновления}
    />
 * collectionName (обязательный): Название коллекции в Firebase, в которой вы хотите обновить данные.
 * documentId (обязательный): Идентификатор документа, который нужно обновить.
 * nameButton (обязательный): Название кнопки, которая будет выполнять действие обновления данных.
 * dataObject (обязательный): Объект с данными, которые будут обновлены в документе. Объект должен соответствовать структуре коллекции в Firebase.
 */
