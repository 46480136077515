import React, { useState } from "react";

const WigetCollapseComponent = ({ item, openItem, children }) => {
  const [isOpen, setIsOpen] = useState(openItem ? openItem : false);

  const toggleCollapse = () => {
    setIsOpen((prevState) => !prevState);
  };

  // if (!item) {
  //   return null;
  // }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          borderTop: "1px var(--color-gray-100) solid",
          padding: "16px 0",
        }}
      >
        <div
          style={{
            padding: "0 24px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            boxSizing: "border-box",
          }}
        >
          {children}
          {item && (
            <div
              onClick={() => toggleCollapse()}
              className={`fa-solid fa-angle-down ${isOpen ? "rotate" : ""}`}
            />
          )}
        </div>

        <div
          className={`wigetCollapseComponent-collapse ${isOpen ? "open" : ""}`}
        >
          <div
            style={{
              minHeight: "0",
            }}
          >
            {item}
          </div>
        </div>
      </div>
    </>
  );
};

export default WigetCollapseComponent;
