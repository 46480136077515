import React from "react";

const Preloader = ({
  title,
  style,
  design,
  color,
  colorPrimary,
  colorSecond,
}) => {
  let imgSrc;

  switch (design) {
    case "white":
      imgSrc =
        "https://firebasestorage.googleapis.com/v0/b/biketripste.appspot.com/o/static%2FlogoBikeTripster%2FrollingPreloader.svg?alt=media&token=1e7ba6e6-8e6d-450a-aea0-e5e126d06c8b";
      break;
    default:
      imgSrc =
        "https://firebasestorage.googleapis.com/v0/b/bikestripster.appspot.com/o/ui%2FRolling-1s-40px.svg?alt=media&token=e580246b-0f05-4e9c-8712-506cf21d4451";
  }

  return (
    <div
      style={{
        width: "32px",
        height: "32px",
        aspectRatio: "1/1",
        border: `${
          colorSecond
            ? `3px solid var(${colorSecond})`
            : "3px solid var(--color-gray-100)"
        }`,
        borderTop: `${
          colorPrimary
            ? `3px solid var(${colorPrimary})`
            : "3px solid var(--color-primary)"
        }`,
        borderRadius: "80%",
        animation: "spin 1s linear infinite",
        ...style,
      }}
    ></div>
  );
};

export default Preloader;

// const Preloader = ({ title, style, design }) => {
//   let imgSrc;

//   switch (design) {
//     case "white":
//       imgSrc =
//         "https://firebasestorage.googleapis.com/v0/b/biketripste.appspot.com/o/static%2FlogoBikeTripster%2FrollingPreloader.svg?alt=media&token=1e7ba6e6-8e6d-450a-aea0-e5e126d06c8b";
//       break;
//     default:
//       imgSrc =
//         "https://firebasestorage.googleapis.com/v0/b/bikestripster.appspot.com/o/ui%2FRolling-1s-40px.svg?alt=media&token=e580246b-0f05-4e9c-8712-506cf21d4451";
//   }

//   return (
//     <img
//       src={imgSrc}
//       alt={title}
//       style={{
//         width: "40px",
//         height: "40px",
//         ...style,
//       }}
//     />
//   );
// };

// export default Preloader;
