import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Icon44 from "../../icons/Icon44";

const SearchBars = ({ title = 'Title', back = 'Back', iconLeft = null, iconRight = null }) => {

    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY || document.documentElement.scrollTop;
            setIsScrolled(scrollY > 20);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, []);

    return (
        <div className="searchBar">
            <div className="navigationMenu">
                {iconLeft ? iconLeft : (
                    <div className="buttonBack">
                        <span className="material-symbols-outlined">
                            chevron_left
                        </span>

                        <p className="xSmallHeadline">
                            {back}
                        </p>
                    </div>
                )}
                <div
                    className="buttonNavigationBar"
                >
                    {iconRight && iconRight}
                </div>
            </div>
            <h1>{title}</h1>
        </div>
    );
};
export default SearchBars




// import React from "react";
// import { useEffect } from "react";
// import { useState } from "react";
// import Icon44 from "../../icons/Icon44";

// const SearchBars = ({ title = 'Title', back = 'Back', }) => {
//     const [isScrolled, setIsScrolled] = useState(false);

//     useEffect(() => {
//         const handleScroll = () => {
//             const scrollY = window.scrollY || document.documentElement.scrollTop;
//             setIsScrolled(scrollY > 20);
//         };
//         window.addEventListener('scroll', handleScroll);
//         return () => {
//             window.removeEventListener('scroll', handleScroll)
//         }
//     }, []);

//     return (
//         <div className="searchBar">

//             <div className="navigationMenu">

//                 <div className="buttonBack">
//                     <span className="material-symbols-outlined">
//                         chevron_left
//                     </span>
//                     <p className="xSmallHeadline">
//                         {back}
//                     </p>
//                 </div>

//                 <div className="buttonNavigationBar">

//                     <Icon44
//                         icon={
//                             <span className="material-symbols-outlined">
//                                 favorite
//                             </span>
//                         } />

//                     <Icon44
//                         icon={
//                             <span className="material-symbols-outlined">
//                                 favorite
//                             </span>
//                         } />

//                 </div>

//             </div>

//             <h1>{title}</h1>

//         </div>
//     );
// };
// export default SearchBars