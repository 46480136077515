import React, { useState, useEffect, useRef } from "react";
import OdrderDetailBike from "./OdrderDetailBike";
import OdrderDetailClient from "./OdrderDetailClient";
import OdrderDetailPrice from "./OdrderDetailPrice";
import OrderAdditionalServicesList from "./OrderAdditionalServicesList";
import { useSelector } from "react-redux";
import SearchBars from "../../../../../UIComponents/bars/searchBars/SearchBars";
import Icon44 from "../../../../../UIComponents/icons/Icon44";
import OrderDateInterval from "./OrderDateInterval";
import OrderTimeline from "./OrderTimeline";

const OrderDetails = () => {
    const currentOrder = useSelector((state) => state.stateGUISlice.currentOrder);

    const scrollRef = useRef(null);

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [currentOrder]);

    return (
        currentOrder && Object.keys(currentOrder).length > 0 ? (
            <div

                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    // height: 'calc(100vh - 56px)',
                    height: '100%',
                    width: '100%',
                    boxSizing: 'border-box',
                }}>
                <SearchBars
                    title='Order detail'
                    iconLeft={
                        <div style={{
                            height: '44px'
                        }} />
                        // <Icon44
                        //     icon={
                        //         <span className="material-symbols-outlined">
                        //             dock_to_right
                        //         </span>
                        //     }
                        // />
                    }

                    iconRight={<></>}
                />
                {currentOrder && (
                    <>

                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '16px',
                                alignContent: 'flex-start',
                                justifyContent: 'flex-start',
                                height: '100%'
                            }}
                            className="scrollBox"
                        >

                            <div ref={scrollRef}>
                                <OdrderDetailBike
                                    title='Order Bike Detail'
                                    orderDetail={currentOrder}
                                    description='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eleifend ipsum vitae nunc gravida facilisis.'
                                />

                                <OrderAdditionalServicesList
                                    title='Order Additional Services List'
                                    orderDetail={currentOrder}
                                    description='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eleifend ipsum vitae nunc gravida facilisis.'
                                />

                                <OrderDateInterval
                                    title='Order Additional Services List'
                                    orderDetail={currentOrder}
                                    description='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eleifend ipsum vitae nunc gravida facilisis.'
                                />

                                {/* <OrderTimeline
                            orderDetail={currentOrder}
                        /> */}

                                <OdrderDetailPrice
                                    title='Odrder Detail Price'
                                    orderDetail={currentOrder}
                                    description='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eleifend ipsum vitae nunc gravida facilisis.'
                                />

                                <OdrderDetailClient
                                    title='Client'
                                    orderDetail={currentOrder}
                                    description='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eleifend ipsum vitae nunc gravida facilisis.'
                                />


                                {/* <div className="contentItemBox">
                            <h3>Price</h3>
                            <OdrderDetailPrice orderDetail={currentOrder} />
                        </div> */}
                            </div>


                        </div>
                    </>
                )}
            </div>
        ) : (
            <div style={{
                textAlign: 'center',
                padding: '16px',
                width: '100%',
                height: '100%'
            }}>
                <p className='p-light'>
                    No data
                </p>
            </div>
        )

    )
}
export default OrderDetails