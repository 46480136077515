import { createSlice } from "@reduxjs/toolkit";
import { fetchUsersThunk } from "./fetchUsersThunk";
import { fetchRentalPointsThunk } from "./fetchRentalPointThunk";

const getCurrentTimestampInSeconds = () => Math.floor(Date.now() / 1000);

const sortUsers = (users) => {
  const newUsers = [];
  const approvedUsers = [];
  const bannedUsers = [];
  users.forEach((user) => {
    switch (user.stateProfile) {
      case "new":
        newUsers.push(user);
        break;
      case "approved":
        approvedUsers.push(user);
        break;
      case "ban":
        bannedUsers.push(user);
        break;
      default:
        break;
    }
  });
  return { newUsers, approvedUsers, bannedUsers };
};

const initialState = {
  users: [],
  newUsers: [],
  approvedUsers: [],
  bannedUsers: [],
  createNewUser: {
    createdat: getCurrentTimestampInSeconds(),
    company: {
      companyName: "",
      mailingAddress: "",
      timeZone: "",
      description: {
        descriptionOriginal: "",
      },
    },
    offerConfirm: "",
    rentalPointsIds: [],
    role: "rental",
    stateProfile: "new",
    user: {
      files: {
        avatarFiles: [],
      },
      contactingTheUser: "",
      gender: "",
      yourGender: "",
      ownerName: "",
      ownerSurname: "",
    },
    userContacts: {
      email: "",
      phoneNumber: "",
    },
  },
  selectedUser: {},
  selectedUserRentalPointsList: [],
  moderationSelectedRentalPoint: {},
  isLoading: false,
  error: null,
};

const moderationSlice = createSlice({
  name: "moderationSlice",
  initialState,
  reducers: {
    setUsers(state, action) {
      const { newUsers, approvedUsers, bannedUsers } = sortUsers(
        action.payload
      );
      state.newUsers = newUsers;
      state.approvedUsers = approvedUsers;
      state.bannedUsers = bannedUsers;
    },
    setSelectedUser(state, action) {
      state.selectedUser = action.payload;
    },
    setModerationSelectedRentalPoint(state, action) {
      state.moderationSelectedRentalPoint = action.payload;
    },
    setTimetableModerationSelectedRentalPoint(state, action) {
      const { index, newTimetable } = action.payload;
      if (
        state.moderationSelectedRentalPoint.timetable &&
        state.moderationSelectedRentalPoint.timetable.length > index
      ) {
        state.moderationSelectedRentalPoint.timetable[index] = newTimetable;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsersThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUsersThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.users = action.payload.users;
        state.newUsers = action.payload.newUsers;
        state.approvedUsers = action.payload.approvedUsers;
        state.bannedUsers = action.payload.bannedUsers;
      })
      .addCase(fetchUsersThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchRentalPointsThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchRentalPointsThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selectedUserRentalPointsList = action.payload.rentalPoints;
      })
      .addCase(fetchRentalPointsThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload.errorMessage;
      });
  },
});

export const {
  setSelectedUser,
  setUsers,
  setModerationSelectedRentalPoint,
  setTimetableModerationSelectedRentalPoint,
} = moderationSlice.actions;
export default moderationSlice.reducer;
