import React, { useState } from "react";
import "../profile.css";
import AddData from "../WidgetsProfile/AddData/AddData";
import PopupDirrectory from "../WidgetsProfile/Popups/PopupDirrectory";
import WidgetAditionalServicesList from "./WidgetAditionalServicesList";
import WindowAditionalServiceDetail from "./WindowAditionalServiceDetail";
import WindowAditionalServiceDetailModeration from "../Moderation/WindowAditionalServiceDetailModeration";

const WidgetAditionalServices = ({ mode, additionalServices }) => {
  const [isOpenWindowAddAditionalService, setIsOpenWindowAddAditionalService] =
    useState(false);

  const [
    isOpenWindowAddAditionalServiceModeration,
    setIsOpenWindowAddAditionalServiceModeration,
  ] = useState(false);

  const handleCloseWidgetAddAditionalService = () => {
    setIsOpenWindowAddAditionalService(false);
    setIsOpenWindowAddAditionalServiceModeration(false);
  };

  const handleToggleWidgetAddAditionalService = () => {
    if (mode === "new" || mode === "edit") {
      setIsOpenWindowAddAditionalService(true);
    } else if (mode === "moderation" || mode === "moderatorAdded") {
      setIsOpenWindowAddAditionalServiceModeration(true);
    }
  };

  return (
    <>
      <div className="profile-widget__content-box">
        <AddData
          icon={<span className="material-symbols-outlined">add</span>}
          title="Add aditional services"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
          onClick={() => handleToggleWidgetAddAditionalService(null)}
        />

        <WidgetAditionalServicesList
          mode={mode}
          additionalServices={additionalServices.services}
        />
      </div>

      <PopupDirrectory
        isOpen={isOpenWindowAddAditionalService}
        onClose={handleCloseWidgetAddAditionalService}
        title="Add aditional services"
      >
        <WindowAditionalServiceDetail
          mode={mode}
          onClose={handleCloseWidgetAddAditionalService}
        />
      </PopupDirrectory>

      <PopupDirrectory
        isOpen={isOpenWindowAddAditionalServiceModeration}
        onClose={handleCloseWidgetAddAditionalService}
        title="Add aditional services moderation"
      >
        <WindowAditionalServiceDetailModeration
          mode={mode}
          onClose={handleCloseWidgetAddAditionalService}
        />
      </PopupDirrectory>
    </>
  );
};

export default WidgetAditionalServices;
