import React, { useState, useEffect } from "react";
import "firebase/compat/firestore";
import { InputWrite } from "../../../../../UIElements/Input";
import Button from "../../../../../UIElements/Button";
import Popup from "../../../../../UIElements/Popup";
import {
  fetchDocumentById,
  updateDocumentById,
} from "../../../../../../services/firebase/crudFirebaseStorage";
import SwitcherOnOff from "../../../../../UIElements/Switcher";
import { TextareaWrite } from "../../../../../UIElements/Textarea";

/**
 * Компонент WigetTimetableL для работы с расписанием.
 * @param {string} collectionName - Название коллекции в Firestore.
 * @param {string} idDocument - Идентификатор документа в коллекции.
 * @param {string} fieldName - Имя поля, содержащего расписание.
 * @param {string} title - Заголовок компонента.
 * @param {string} description - Описание компонента.
 */
export const WigetTimetableL = ({
  collectionName,
  idDocument,
  fieldName,
  title,
  description,
}) => {
  // Инициализация состояний компонента
  const [activePopup, setActivePopup] = useState(null);
  const [formData, setFormData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [markedForDelete, setMarkedForDelete] = useState({});

  const maxLength = 1000; // Максимальная длина значения

  // Получение данных при монтировании компонента
  useEffect(() => {
    // Инициализация формы с начальными данными
    const initialFormData = [
      {
        day: 1,
        weekday: "Monday",
        open: 0,
        close: 0,
        description: "",
        holiday: false,
      },
      {
        day: 2,
        weekday: "Tuesday",
        open: 0,
        close: 0,
        description: "",
        holiday: false,
      },
      {
        day: 3,
        weekday: "Wednesday",
        open: 0,
        close: 0,
        description: "",
        holiday: false,
      },
      {
        day: 4,
        weekday: "Thursday",
        open: 0,
        close: 0,
        description: "",
        holiday: false,
      },
      {
        day: 5,
        weekday: "Friday",
        open: 0,
        close: 0,
        description: "",
        holiday: false,
      },
      {
        day: 6,
        weekday: "Saturday",
        open: 0,
        close: 0,
        description: "",
        holiday: false,
      },
      {
        day: 7,
        weekday: "Sunday",
        open: 0,
        close: 0,
        description: "",
        holiday: false,
      },
    ];

    /**
     * Функция для получения данных из Firestore.
     */
    const fetchData = async () => {
      setIsLoading(true);
      const data = await fetchDocumentById(collectionName, idDocument);
      if (data && data[fieldName]) {
        const timetable = Array.isArray(data[fieldName])
          ? data[fieldName]
          : [data[fieldName]];
        setFormData(timetable);
      } else {
        setFormData(initialFormData);
      }
      setIsLoading(false);
    };

    // Вызов функции для получения данных при монтировании компонента
    fetchData();
  }, [collectionName, idDocument, fieldName]);

  /**
   * Обработчик удаления отмеченных элементов.
   */
  const handleDeleteMarked = async () => {
    setIsLoading(true);
    const updatedData = formData.filter((_, index) => !markedForDelete[index]);
    setFormData(updatedData);
    await updateDocumentById(
      { [fieldName]: updatedData },
      collectionName,
      idDocument
    );
    setIsLoading(false);
    setIsChanged(false);
    setMarkedForDelete({});
    console.log("Удаленные данные успешно обновлены");
  };

  /**
   * Обработчик изменения значений полей формы.
   * @param {object} e - Событие изменения поля ввода.
   * @param {number} index - Индекс элемента в форме.
   * @param {string} field - Название поля.
   */
  const handleChange = (newValue, index, field) => {
    setFormData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index][field] = newValue;
      return updatedData;
    });
    setIsChanged(true);
  };
  // const handleChange = (newValue, index, field) => {
  //     setFormData(prevData => {
  //         const updatedData = [...prevData];
  //         updatedData[index][field] = newValue;
  //         return updatedData;
  //     });
  //     setIsChanged(true);
  // };

  /**
   * Обработчик отметки элементов для удаления.
   * @param {number} index - Индекс элемента.
   * @param {boolean} isChecked - Флаг отметки.
   */
  const handleMarkForDelete = (index, isChecked) => {
    setMarkedForDelete((prevState) => ({
      ...prevState,
      [index]: isChecked,
    }));
  };

  /**
   * Обработчик отправки формы.
   * @param {object} e - Событие отправки формы.
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await updateDocumentById(
      { [fieldName]: formData },
      collectionName,
      idDocument
    );
    setIsLoading(false);
    setIsChanged(false);
    console.log("Данные успешно обновлены");
  };

  /**
   * Обработчики открытия и закрытия всплывающего окна.
   * @param {number} index - Индекс элемента.
   */
  const handleOpenPopup = (index) => setActivePopup(index);
  const handleClosePopup = () => setActivePopup(null);

  // Рендеринг компонента
  return (
    <>
      <div className="inform-box">
        <div className="inform-box-header">
          <p>{title}</p>
        </div>
        <div className="inform-box-item">
          {formData.map((item, index) => (
            <div
              className="inform-box-content"
              key={index}
              onClick={() => handleOpenPopup(index)}
            >
              <div className="inform-box-content-left">{item.weekday}</div>
              {item.holiday ? (
                <>
                  <div className="inform-box-content-right">
                    <p
                      style={{
                        backgroundColor: "var(--color-alarm)",
                        color: "var(--color-white)",
                      }}
                      className="wigetTimetablel-box"
                    >
                      holiday
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <div className="inform-box-content-right">
                    {item.open && item.close ? (
                      <>
                        <p
                          style={{
                            backgroundColor: "var(--color-success)",
                            color: "var(--color-white)",
                          }}
                          className="wigetTimetablel-box"
                        >
                          from:{item.open}
                        </p>
                        <p
                          style={{
                            backgroundColor: "var(--color-success)",
                            color: "var(--color-white)",
                          }}
                          className="wigetTimetablel-box"
                        >
                          to:{item.close}
                        </p>
                      </>
                    ) : (
                      <div className="inform-box-icon-right">
                        <i className="fa-solid fa-pen"></i>
                      </div>
                    )}
                  </div>
                </>
              )}

              {/* <div className='inform-box-icon-right'>
                                <i className="fa-solid fa-pen"></i>
                            </div> */}
            </div>
          ))}
        </div>
        <div className="inform-box-footer">{description}</div>
      </div>
      {formData.map((item, index) => (
        <Popup
          key={index}
          isOpen={activePopup === index}
          onClose={handleClosePopup}
          title={`${item.weekday} `}
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eleifend ipsum vitae nunc gravida facilisis. Pellentesque finibus, justo id tincidunt accumsan, mauris elit consequat nulla, vel tempus mi lorem vel ipsum. Integer id tincidunt sapien. Cras faucibus euismod massa eget interdum. Sed commodo, ante eu tempor condimentum, elit dui lacinia nisi, in pulvinar enim enim ut orci. Suspendisse potenti."
        >
          <form onSubmit={handleSubmit}>
            {/* <input
                            type="checkbox"
                            checked={!!markedForDelete[index]}
                            onChange={(e) => handleMarkForDelete(index, e.target.checked)}
                        /> */}

            <InputWrite
              label="Время открытия"
              type="text"
              value={item.open}
              onChange={(e) => handleChange(e.target.value, index, "open")}
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            />
            <InputWrite
              label="Время закрытия"
              type="text"
              value={item.close}
              onChange={(e) => handleChange(e.target.value, index, "close")}
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            />
            {/* <InputWrite
                            label="Описание"
                            type="text"
                            value={item.description}
                            onChange={(e) => handleChange(e, index, 'description')}
                            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                        /> */}

            <TextareaWrite
              label={`Описание`}
              value={item.description}
              onChange={(e) =>
                handleChange(e.target.value, index, "description")
              }
              maxLength={maxLength}
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            />

            <SwitcherOnOff
              isChecked={item.holiday}
              onChange={(newValue) => handleChange(newValue, index, "holiday")}
              label="holiday"
            />
            {/* {isChanged && <Button type="submit" disabled={isLoading}>{isLoading ? '...Loading' : 'Send'}</Button>} */}
            {/* {Object.values(markedForDelete).some(value => value) && (
                            <Button type="button" onClick={handleDeleteMarked} disabled={isLoading}>
                                {isLoading ? '...Loading' : 'Delete marked'}
                            </Button>
                        )} */}
          </form>
          <div className="send-view">
            <div></div>
            <div className="send-view-right">
              {isChanged ? (
                <Button
                  onClick={handleSubmit}
                  disabled={isLoading}
                  children={isLoading ? "...Loading" : "Send"}
                />
              ) : (
                <Button type={"disable"} children={"Send"} />
              )}
            </div>
          </div>
        </Popup>
      ))}
    </>
  );
};

export default WigetTimetableL;
