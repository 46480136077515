import React from "react";
// import Menu from "../../Menu/PrivateMenu";
import WigetBrandBox from "./WigetBrandBox";
// import { Outlet } from "react-router-dom";

const UICurrency = () => {
  return (
    <div className="private_profile-mainBox">
      {/* <Menu /> */}
      <div className="private_profile">
        <div className="private_content-box">
          <div
            style={{
              height: "calc(100vh - 64px)",
            }}
            className="private_wiget-big-box"
          >
            <WigetBrandBox />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UICurrency;
