import React, { memo, useEffect, useState } from "react";
import "./table.css"; // Подключаем стили CSS

const Table = ({ data = [], columns, title }) => {
  const [visibleDescriptionIndex, setVisibleDescriptionIndex] = useState(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  useEffect(() => {
    let timer;
    if (hoveredIndex !== null) {
      timer = setTimeout(() => {
        setVisibleDescriptionIndex(hoveredIndex);
      }, 1000); // задержка в 1 секунду
    } else {
      setVisibleDescriptionIndex(null);
    }
    return () => clearTimeout(timer);
  }, [hoveredIndex]);

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <h5
        style={{
          width: "100%",
        }}
      >
        {title}
      </h5>
      <ul
        className="table__container"
        style={{
          gridTemplateColumns: `repeat(${columns}, 1fr)`,
        }}
      >
        {data.map((item, index) => (
          <li
            key={index}
            className="table__cell"
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
          >
            <div
              style={{
                ...(item.description !== undefined && { cursor: "pointer" }),
              }}
              className={`cell__content ${
                item.typeCell === "column"
                  ? "cell__content--column"
                  : "cell__content--row"
              }`}
            >
              <span className="p-light">{item.title}</span>
              <span>{item.parameter}</span>
            </div>
            {index + 1 !== data.length && (
              <div
                style={{
                  width: "100%",
                  height: "1px",
                  backgroundColor: "var(--color-gray-200)",
                }}
              >
                <div
                  className={`progress-bar ${
                    item.description !== undefined && hoveredIndex === index
                      ? "animate"
                      : ""
                  }`}
                />
              </div>
            )}

            {item.description !== undefined &&
              visibleDescriptionIndex === index && (
                <div className="cell-description">{item.description}</div>
              )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default memo(Table);
