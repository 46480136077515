import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  deleteData,
  removeFiles,
} from "../../../services/firebase/crudFirestore";
import interpretAuthErrorV2 from "../../../functions/interpretAuthErrorV2";

// Создаем асинхронный thunk для удаления данных бренда
export const deleteDirectoryThunk = createAsyncThunk(
  "directory/deleteDirectoryThunk",
  async (
    { collectionName, documentId, fieldName, fileIds },
    { rejectWithValue }
  ) => {
    console.log(
      "collectionName, documentId, fieldName, fileIds",
      collectionName,
      documentId,
      fieldName,
      fileIds
    );
    // Проверка валидности имени коллекции
    if (typeof collectionName !== "string" || collectionName.trim() === "") {
      return rejectWithValue("Invalid collection name");
    }

    // Проверка валидности идентификатора документа
    if (typeof documentId !== "string" || documentId.trim() === "") {
      return rejectWithValue("Invalid document ID");
    }

    // Проверка валидности имени поля
    if (typeof fieldName !== "string" || fieldName.trim() === "") {
      return rejectWithValue("Invalid field name");
    }

    try {
      // Если массив fileIds существует и не пуст, удаляем файлы
      if (Array.isArray(fileIds) && fileIds.length > 0) {
        await removeFiles(collectionName, documentId, fieldName, fileIds);
        console.log("Files successfully deleted!");
      }

      // Удаляем документ
      await deleteData(collectionName, documentId);
      console.log("Document successfully deleted!");

      // Возвращаем идентификатор удаленного документа
      return { documentId };
    } catch (error) {
      // Интерпретируем и возвращаем ошибку
      const errorMessage = interpretAuthErrorV2(error.code);
      return rejectWithValue({ errorMessage });
    }
  }
);
