import React, { useEffect, useState } from "react";
import InputText from "../WidgetsProfile/Inputs/InputText";
import Button from "../WidgetsProfile/Buttons/Button";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import { useDispatch, useSelector } from "react-redux";

import ButtonsBar from "../WidgetsProfile/ButtonsBar/ButtonsBar";
import DropdownList from "../WidgetsProfile/DropdownList/DropdownList";
import { setLocationAddressV4 } from "../../../../../reducersToolkit/rentalPointsSliiceV4";

const WindowRentalPontLocationV4 = ({ mode, onClose }) => {
  const dispatch = useDispatch();

  const [pointAddress, setPointAddress] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [formattedCountries, setFormattedCountries] = useState([]);
  const [formattedCities, setFormattedCities] = useState([]);

  const { selectedRentalPoint, newRentalPoint } = useSelector(
    (state) => state.rentalPointsSliiceV4
  );

  console.log("WindowRentalPontLocationV4 newRentalPoint", newRentalPoint);

  const { locations } = useSelector((state) => state.catalogSliceV4);

  useEffect(() => {
    if (locations[0]?.data) {
      // Форматируем данные стран
      const countries = locations[0].data.map((country) => ({
        id: country.id,
        title: country.title.titleOriginal,
      }));

      setFormattedCountries(countries);
    }
  }, [locations]);

  useEffect(() => {
    if (mode === "new") {
      setPointAddress(newRentalPoint.location.pointAddress);
      setSelectedCountry(newRentalPoint.location.country);
      setSelectedCity(newRentalPoint.location.city);
    } else if (mode === "edit") {
      setPointAddress(selectedRentalPoint.location.pointAddress);
      setSelectedCountry(newRentalPoint.location.country);
      setSelectedCity(newRentalPoint.location.city);
    }
  }, [newRentalPoint, selectedRentalPoint, mode]);

  const handleSetLocation = (event) => {
    setPointAddress(event.target.value);
  };

  const handleCountrySelect = (selectedOption) => {
    const country = locations[0]?.data.find(
      (country) => country.id === selectedOption.id
    );

    setSelectedCountry(selectedOption);

    // Формируем массив городов для выбранной страны
    if (country && country.cities) {
      const cities = country.cities.map((city) => ({
        id: city.id,
        title: city.title.titleOriginal,
      }));
      setFormattedCities(cities);
    } else {
      setFormattedCities([]);
    }

    setSelectedCity(null); // Сбрасываем выбранный город при смене страны
  };

  const handleCitySelect = (selectedOption) => {
    setSelectedCity(selectedOption);
  };

  const handleApply = () => {
    if (!selectedCountry || !selectedCity) {
      console.error("Country and city must be selected.");
      return;
    }

    dispatch(
      setLocationAddressV4({
        mode,
        pointAddress,
        country: selectedCountry,
        city: selectedCity,
      })
    );
    onClose();
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "8px",
          paddingBottom: "72px",
        }}
      >
        <ContentBox position="start">
          <InputText
            label="Address"
            description="Enter the address of the rental point."
            value={pointAddress}
            onChange={handleSetLocation}
          />
          <DropdownList
            options={formattedCountries}
            onSelect={handleCountrySelect}
            label="Select country"
            description="Please select a country."
            fieldView="title"
            fieldId="id"
            initialSelectedOption={selectedCountry}
          />
          {selectedCountry && (
            <DropdownList
              options={formattedCities}
              onSelect={handleCitySelect}
              label="Select city"
              description="Please select a city."
              fieldView="title"
              fieldId="id"
              initialSelectedOption={selectedCity}
            />
          )}
        </ContentBox>
      </div>
      <ButtonsBar>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "16px",
            width: "100%",
          }}
        >
          <Button
            type="small"
            label="Cancel"
            active={true}
            color="--color-black"
            onClick={handleCancel}
          />
          <Button
            type="small"
            label="Apply"
            active={true}
            onClick={handleApply}
          />
        </div>
      </ButtonsBar>
    </>
  );
};

export default WindowRentalPontLocationV4;

// import React, { useEffect, useState } from "react";
// import InputText from "../WidgetsProfile/Inputs/InputText";
// import Button from "../WidgetsProfile/Buttons/Button";
// import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
// import { useDispatch, useSelector } from "react-redux";
// import { setLocationAddress } from "../../../../../reducersToolkit/rentalPointsSliiceV2";
// import ButtonsBar from "../WidgetsProfile/ButtonsBar/ButtonsBar";
// import DropdownList from "../WidgetsProfile/DropdownList/DropdownList";

// const WindowRentalPontLocationV4 = ({ mode, onClose }) => {
//   const dispatch = useDispatch();

//   const [pointAddress, setPointAddress] = useState("");
//   const [locationsData, setLocationsData] = useState([]);

//   const { selectedRentalPoint, newRentalPoint } = useSelector(
//     (state) => state.rentalPointsSliiceV2
//   );

//   const { locations } = useSelector((state) => state.catalogSliceV4);
//   console.log("WindowRentalPontLocationV4 locationsData", locationsData);

//   useEffect(() => {
//     if (mode === "new") {
//       setPointAddress(newRentalPoint.location.pointAddress);
//       setLocationsData(locations);
//     } else if (mode === "edit") {
//       setPointAddress(selectedRentalPoint.location.pointAddress);
//     }
//   }, [newRentalPoint, selectedRentalPoint, locations, mode]);

//   const handleSetLocation = (event) => {
//     setPointAddress(event.target.value);
//   };

//   const handleApply = () => {
//     dispatch(setLocationAddress({ mode, pointAddress }));
//     onClose();
//   };

//   const handleCancel = () => {
//     onClose();
//   };

//   return (
//     <>
//       <div
//         style={{
//           width: "100%",
//           height: "auto",
//           display: "flex",
//           flexDirection: "column",
//           alignItems: "center",
//           gap: "8px",
//           paddingBottom: "72px",
//         }}
//       >
//         <ContentBox position="start">
//           <InputText
//             label="Address"
//             description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
//             value={pointAddress}
//             onChange={handleSetLocation}
//           />
//           <DropdownList
//             // options={countryOptions}
//             // onSelect={handleCountrySelect}
//             label="Select country"
//             description="Please select a country."
//             fieldView="title"
//             fieldId="id"
//             // initialSelectedOption={selectedCountry}
//           />
//           <DropdownList
//             // options={countryOptions}
//             // onSelect={handleCountrySelect}
//             label="Select city"
//             description="Please select a city."
//             fieldView="title"
//             fieldId="id"
//             // initialSelectedOption={selectedCountry}
//           />
//         </ContentBox>
//       </div>
//       <ButtonsBar>
//         <div
//           style={{
//             display: "grid",
//             gridTemplateColumns: "repeat(2, 1fr)",
//             gap: "16px",
//             width: "100%",
//           }}
//         >
//           <Button
//             type="small"
//             label="Cancel"
//             active={true}
//             color="--color-black"
//             onClick={handleCancel}
//           />
//           <Button
//             type="small"
//             label="Apply"
//             active={true}
//             onClick={handleApply}
//           />
//         </div>
//       </ButtonsBar>
//     </>
//   );
// };

// export default WindowRentalPontLocationV4;
