import React, { useState, useEffect } from "react";
import InputText from "../WidgetsProfile/Inputs/InputText";
import Button from "../WidgetsProfile/Buttons/Button";
import { useDispatch, useSelector } from "react-redux";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import {
  removeContactsEmailAddress,
  setContactsEmailAddresses,
} from "../../../../../reducersToolkit/rentalPointsSliiceV2";
import {
  removeContactsEmailAddressModeration,
  setContactsEmailAddressesModeration,
} from "../../../../../reducersToolkit/moderationSliceV2";
import ButtonsBar from "../WidgetsProfile/ButtonsBar/ButtonsBar";
import {
  removeContactsEmailAddressV4,
  setContactsEmailAddressesV4,
} from "../../../../../reducersToolkit/rentalPointsSliiceV4";

const WindowEditEmailV4 = ({ mode, onClose }) => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [emailAddresses, setEmailAddresses] = useState([]);
  const [newEmailAddresses, setNewEmailAddresses] = useState([]);

  const currentUser = useSelector((state) => state.userSliceV2.currentUser);

  const { selectedRentalPoint, newRentalPoint } = useSelector(
    (state) => state.rentalPointsSliiceV4
  );

  const { selectedRentalPoint: selectedRentalPointModeration } = useSelector(
    (state) => state.moderationSliceV2
  );

  useEffect(() => {
    if (mode === "new") {
      setEmailAddresses(newRentalPoint?.contacts?.emailAddresses || []);
    } else if (mode === "edit") {
      setEmailAddresses(selectedRentalPoint?.contacts?.emailAddresses || []);
    } else if (mode === "moderation") {
      setEmailAddresses(
        selectedRentalPointModeration?.contacts?.emailAddresses || []
      );
    }
  }, [
    mode,
    newRentalPoint,
    selectedRentalPoint,
    selectedRentalPointModeration,
  ]);

  const handleAddEmail = () => {
    const emailObject = {
      key: Date.now().toString(),
      email,
      moderatorId: mode === "moderation" ? currentUser.id : null,
    };
    setNewEmailAddresses([...newEmailAddresses, emailObject]);
    setEmail("");
  };

  const handleRemoveEmail = (index) => {
    console.log("filteredBikes", index);
    setNewEmailAddresses(newEmailAddresses.filter((_, i) => i !== index));
  };

  const handleRemoveLoadedEmail = (emailObject) => {
    console.log("filteredBikes", emailObject);
    if (mode === "new") {
      dispatch(removeContactsEmailAddressV4(emailObject.key));
      setEmailAddresses(
        emailAddresses.filter((e) => e.key !== emailObject.key)
      );
    } else if (mode === "edit") {
      dispatch(removeContactsEmailAddressV4(emailObject.key));
      setEmailAddresses(
        emailAddresses.filter((e) => e.key !== emailObject.key)
      );
    } else if (mode === "moderation") {
      dispatch(removeContactsEmailAddressModeration(emailObject.key));
      setEmailAddresses(
        emailAddresses.filter((e) => e.key !== emailObject.key)
      );
    }
  };

  const handleApply = () => {
    if (mode === "new") {
      newEmailAddresses.forEach((emailObject) => {
        dispatch(
          setContactsEmailAddressesV4({ mode, emailAddresse: emailObject })
        );
      });
    } else if (mode === "edit") {
      newEmailAddresses.forEach((emailObject) => {
        dispatch(
          setContactsEmailAddressesV4({ mode, emailAddresse: emailObject })
        );
      });
    } else if (mode === "moderation") {
      newEmailAddresses.forEach((emailObject) => {
        dispatch(setContactsEmailAddressesModeration(emailObject));
      });
    }

    onClose();
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "8px",
          paddingBottom: "72px",
        }}
      >
        <ContentBox position="start">
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              gap: "16px",
            }}
          >
            <InputText
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              label="Email address"
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            />
            <div
              style={{
                width: "auto",
              }}
            >
              <Button
                label="Add"
                active={email ? true : false}
                onClick={handleAddEmail}
              />
            </div>
          </div>
        </ContentBox>
        {(emailAddresses.length > 0 || newEmailAddresses.length > 0) && (
          <ContentBox>
            <ul
              style={{
                width: "100%",
                listStyle: "none",
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              {emailAddresses.map((emailObj) => (
                <li
                  key={emailObj.key}
                  style={{
                    height: "56px",
                    width: "100%",
                    boxSizing: "border-box",
                    borderRadius: "8px",
                    backgroundColor: "var(--color-gray-100)",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "0 8px 0 16px",
                  }}
                >
                  <h6>{emailObj.email}</h6>
                  <div
                    style={{
                      width: "auto",
                    }}
                  >
                    <Button
                      onClick={() => handleRemoveLoadedEmail(emailObj)}
                      allert={
                        <h1
                          style={{
                            padding: "48px",
                            textAlign: "center",
                          }}
                        >
                          Are you sure you want to delete this email address?
                          Please confirm your action.
                        </h1>
                      }
                      active={true}
                      color="--color-gray-100"
                      type="icon"
                      icon={
                        <span className="material-symbols-outlined">
                          delete
                        </span>
                      }
                    />
                  </div>
                </li>
              ))}
              {newEmailAddresses.map((emailObj, index) => (
                <li
                  key={emailObj.key}
                  style={{
                    height: "56px",
                    width: "100%",
                    boxSizing: "border-box",
                    borderRadius: "8px",
                    backgroundColor: "var(--color-gray-100)",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "0 8px 0 16px",
                  }}
                >
                  <h6>{emailObj.email}</h6>
                  <div
                    style={{
                      width: "auto",
                    }}
                  >
                    <Button
                      onClick={() => handleRemoveEmail(index)}
                      allert={
                        <h1
                          style={{
                            padding: "48px",
                            textAlign: "center",
                          }}
                        >
                          Are you sure you want to delete this email address?
                          Please confirm your action.
                        </h1>
                      }
                      active={true}
                      color="--color-gray-100"
                      type="icon"
                      icon={
                        <span className="material-symbols-outlined">
                          delete
                        </span>
                      }
                    />
                  </div>
                </li>
              ))}
            </ul>
          </ContentBox>
        )}
      </div>

      <ButtonsBar>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "16px",
            width: "100%",
          }}
        >
          <Button
            type="small"
            label="Cancel"
            active={true}
            color="--color-black"
            onClick={onClose}
          />
          <Button
            type="small"
            label="Apply"
            active={newEmailAddresses.length > 0 && true}
            onClick={handleApply}
          />
        </div>
      </ButtonsBar>
    </>
  );
};

export default WindowEditEmailV4;
