import { structureDb } from "./structureDb";
import {
  fetchDocumentById,
  addData,
  updateData,
  updateDocumentById,
} from "./crudFirebaseStorage";

const saveDataFirebase = async (
  collectionName,
  documentId,
  dataObject,
  parentCollectionName,
  parentDocumentId
) => {
  try {
    // Проверяем, существует ли данная коллекция в структуре базы данных
    if (!(collectionName in structureDb)) {
      console.log(`Объекта с именем ${collectionName} нет в structureDb.`);
      return false;
    }

    // Получаем структуру данных для данной коллекции из объекта structureDb
    const dataObjectStructure = structureDb[collectionName];

    // Проверяем, существует ли документ с данным id в коллекции
    if (documentId) {
      const documentData = await fetchDocumentById(collectionName, documentId);

      if (!documentData) {
        console.log(`Документ с ID ${documentId} не существует.`);
        return false;
      }

      // Проверяем, соответствует ли структура данных объекта структуре данных коллекции
      if (!validateDataStructure(dataObject, structureDb[collectionName])) {
        console.log(
          "Структура объекта данных не соответствует указанной коллекции."
        );
        return false;
      }

      // Обновляем данные в существующем документе
      await updateData(collectionName, documentId, dataObject);
      console.log(`Данные успешно обновлены в документе с ID ${documentId}.`);
    } else {
      // Если документ не существует, создаем новый документ в коллекции с данными по умолчанию
      if (
        !validateDataStructure(dataObjectStructure, structureDb[collectionName])
      ) {
        console.log(
          "Структура объекта данных не соответствует указанной коллекции.",
          dataObjectStructure
        );
        return false;
      }

      const addedData = await addData(collectionName, dataObjectStructure);
      console.log(
        `Новый документ добавлен с ID ${addedData.id} в коллекцию ${collectionName}.`
      );

      await updateData(collectionName, addedData.id, dataObject);
      console.log(`Данные успешно сохранены в документе с ID ${addedData.id}.`);

      // Если задано название родительской коллекции, обновляем родительский документ с новым id
      if (parentCollectionName) {
        // Проверяем наличие родительской коллекции в структуре
        if (parentCollectionName in structureDb) {
          // Проверяем наличие поля с постфиксом _id и collectionName в структуре
          if (`${collectionName}_id` in structureDb[parentCollectionName]) {
            const parentDocument = await fetchDocumentById(
              parentCollectionName,
              parentDocumentId
            );
            if (!parentDocument) {
              console.log(
                `Родительский документ с идентификатором ${parentDocumentId} не существует.`
              );
              return false;
            }

            // Обновляем родительский документ с новым id
            const updateField = {
              [`${collectionName}_id`]: Array.isArray(
                parentDocument[`${collectionName}_id`]
              )
                ? [...parentDocument[`${collectionName}_id`], addedData.id]
                : [addedData.id],
            };
            await updateDocumentById(
              updateField,
              parentCollectionName,
              parentDocumentId
            );
            console.log(
              `Документ с id ${parentDocumentId} в коллекции ${parentCollectionName} обновлено с id: ${collectionName}_id ${addedData.id}`
            );
          }
        } else {
          console.log(
            `Родительской коллекции с именем ${parentCollectionName} не существует в structureDb.`
          );
          return false;
        }
      }
    }

    return true;
  } catch (error) {
    // В случае ошибки выводим ее в консоль и возвращаем false
    console.error("Ошибка при добавлении/обновлении данных:", error);
    return false;
  }
};

// Функция для валидации структуры данных объекта с данными структурой коллекции
const validateDataStructure = (dataObject, structure) => {
  for (const key in structure) {
    // Если типы данных ключей не совпадают, возвращаем false
    if (typeof dataObject[key] !== typeof structure[key]) {
      return false;
    }

    // Если значение ключа является объектом, не являющимся массивом, рекурсивно проверяем этот объект
    if (typeof structure[key] === "object" && !Array.isArray(structure[key])) {
      if (!validateDataStructure(dataObject[key], structure[key])) {
        return false;
      }
    }
  }

  return true;
};

export default saveDataFirebase;
