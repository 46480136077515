import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import FixedBlock from "./FixedBlock";
import Title from "./Title";
import Timetable from "./Timetable";
import PricesGradient from "./PricesGradient";
import BikeInfo from "./BikeInfo";
import AdditionalServices from "./AdditionalServices";
import ReantalPointInform from "./ReantalPointInform";
import { useDispatch } from "react-redux";
import { setCurrentBike } from "../../../slice/stateFrontGUISlice";
import { calculatePricePercentage } from "../../../functions/calculatePricePercentage";
import GoogleMapsFilters from "../../UIComponents/maps/GoogleMapsFilters";
import { useSubscribeData } from "../../../services/firebase/crudFirebaseStorage";
import WidgetFooter from "../UniversalWidgets/WidgetFooter";

const UIProductCard = () => {
  const dispatch = useDispatch();

  const { data: commentsData, isLoading: loadingCommentsData } =
    useSubscribeData("comments");

  const { id } = useParams();

  const fullDataBike = useSelector(
    (state) => state.stateFrontGUISlice.fullDataBike
  );
  const currentBike = useSelector(
    (state) => state.stateFrontGUISlice.currentBike
  );

  const currentDateInterval = useSelector(
    (state) => state.stateFrontGUISlice.currentDateInterval
  );

  const pricePercentage = calculatePricePercentage(currentBike?.rate);

  const currentRentalPoint = currentBike.rentalPoint;

  useEffect(() => {
    if (id && fullDataBike.length > 0) {
      dispatch(setCurrentBike(fullDataBike.find((bike) => bike.id === id)));
    } else {
      dispatch(setCurrentBike({}));
    }
  }, [id, fullDataBike, dispatch]);

  return (
    <>
      <div className="content-container">
        <div className="content-body">
          <div
            style={{
              gridColumn: "span 2",
            }}
          >
            <h1
              style={{
                fontSize: "3em",
                fontWeight: "300",
              }}
            >
              {currentBike.title}
            </h1>
          </div>

          <Title />

          <div
            style={{
              gridColumn: "1",
              display: "flex",
              flexDirection: "column",
              boxSizing: "border-box",
              width: "100%",
              zIndex: "105",
            }}
          >
            <div
              style={{
                position: "sticky",
                top: "0",
              }}
            >
              <FixedBlock />
            </div>
          </div>

          <div
            style={{
              gridColumn: "2",
              display: "flex",
              flexDirection: "column",
              gap: "32px",
            }}
          >
            <ReantalPointInform
              rentalPoint={currentRentalPoint}
              rentalPointId={currentRentalPoint?.id}
              commentsData={commentsData}
              loadingCommentsData={loadingCommentsData}
            />

            <PricesGradient
              pricePercentage={pricePercentage}
              currentDateInterval={currentDateInterval}
              currentBike={currentBike}
            />

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                backgroundColor: "var(--color-white)",
                borderRadius: "24px",
                overflow: "hidden",
              }}
            >
              <Timetable timetableData={currentBike?.rentalPoint?.timetableL} />
              <div
                style={{
                  aspectRatio: "1/1",
                  width: "100%",
                }}
              >
                <GoogleMapsFilters rentalPoint={currentBike.rentalPoint} />
              </div>
            </div>
            <AdditionalServices />
            <BikeInfo />
          </div>
        </div>
      </div>
      <WidgetFooter />
    </>
  );
};

export default UIProductCard;
