import React, { useEffect, useState } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import { addData } from "../../../../services/firebase/crudFirebaseStorage";
import ReCAPTCHAButton from "../../../UIElements/ReCAPTCHAButton";
import { TextareaWrite } from "../../../UIElements/Textarea";
import WigetRating from "./WigetRating";
import objectIsEmpty from "../../../../functions/objectIsEmpty";
import { useSelector } from "react-redux";
import WIgetInform from "../WigetInform";

const WidgetAddComments = ({
  uid,
  rentalPointId,
  currentBike,
  commentId,
  closePopupSatate,
}) => {
  const commentsData = useSelector(
    (state) => state.stateFrontGUISlice.commentsData
  );

  const widgetAddCommentsState = useSelector(
    (state) => state.stateFrontGUISlice.widgetAddCommentsState
  );

  const [activateAddCommentSucces, setActivateAddCommentSucces] =
    useState(false);
  const [activateAddCommentError, setActivateAddCommentError] = useState(false);

  // Комментарий
  const [comment, setComment] = useState("");
  // Оценка
  const [grade, setGrade] = useState(0);
  // Для отслеживания, был ли комментарий успешно добавлен
  const [commentAddState, setCommentAddState] = useState(false);
  // Для хранения сообщения об ошибке
  const [error, setError] = useState("");

  useEffect(() => {
    const storedComment = localStorage.getItem("storedComment");
    if (storedComment) {
      setComment(storedComment);
    }
  }, []);

  useEffect(() => {
    if (comment !== "") {
      localStorage.setItem("storedComment", comment);
      setError("");
    }
  }, [comment]);

  const closeWindows = () => {
    setActivateAddCommentSucces(false);
    if (typeof closePopupSatate === "function") {
      closePopupSatate(false);
    }
  };

  const handleSubmit = async () => {
    if (!comment || !grade) {
      setError("Kindly share your feedback. Your input is highly appreciated!");
      return false;
    }

    setCommentAddState(true);
    const functions = getFunctions();
    const addOrUpdateComment = httpsCallable(functions, "comments100-comments");

    try {
      const response = await addOrUpdateComment({
        // Параметры, которые вы хотите передать в функцию
        rentalPointId: rentalPointId ? rentalPointId : "",
        currentBike: !objectIsEmpty(currentBike) ? currentBike : {},
        comment: comment,
        moderated: false,
        commentModerator: "",
        date: Date.now(),
        grade: grade,
        uid: uid ? uid : "",
        commentId: commentId ? commentId : null,
      });
      console.log("Function response:", response.data.success);
      setCommentAddState(false);
      setComment("");
      setError("");
      setGrade(0);
      setActivateAddCommentSucces(true);
      localStorage.removeItem("storedComment");
      return true;
    } catch (error) {
      console.error(error);
      setError(
        "Oops! There was an error while adding your comment. Please try again."
      );
      setActivateAddCommentError(true);
      setCommentAddState(true);
      return false;
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          height: "100%",
        }}
      >
        <div
          style={{
            boxSizing: "border-box",
            padding: "16px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            gap: "16px",
          }}
        >
          <h5>Rate and leave a comment, we value your feedback!</h5>
        </div>

        <TextareaWrite
          errDescription={error}
          maxLength={1000}
          label="Comment"
          name="comment"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          description="Rate and leave a comment, we value your feedback!"
        />

        <div
          style={{
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "16px",
            width: "100%",
          }}
        >
          <WigetRating
            grade={grade}
            setGrade={setGrade}
            errDescription={error}
          />

          <ReCAPTCHAButton
            reCapthaActionName="sendComment"
            grade={0.5}
            textButton="Post a review"
            description="Description"
            styleButton={{
              backgroundColor: "var(--color-success)",
              color: "var(--color-white)",
              width: "auto",
            }}
            buttonFunction={handleSubmit}
            buttonFunctionState={commentAddState}
          />
        </div>
      </div>

      <WIgetInform
        windowControlOpen={activateAddCommentSucces}
        windowControlClose={setActivateAddCommentSucces}
        img="https://firebasestorage.googleapis.com/v0/b/biketripste.appspot.com/o/static%2FlogoBikeTripster%2FaddedComment.jpg?alt=media&token=3aa139b3-301d-470f-9b2e-eb2ed73e76c2"
      >
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "24px",
          }}
        >
          <div
            style={{
              backgroundImage:
                "url(https://firebasestorage.googleapis.com/v0/b/biketripste.appspot.com/o/static%2FlogoBikeTripster%2Fhappy.svg?alt=media&token=a30fa95c-e823-46f5-a986-1568771720f7)",
              backgroundSize: "auto 70%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              width: "100%",
              height: "150px",
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
            }}
          >
            <h3
              style={{
                width: "70%",
              }}
            >
              Thank you for your review!
            </h3>
            <p
              className="p-light"
              style={{
                width: "70%",
              }}
            >
              It has been submitted and will be available after moderation. Your
              feedback is important to us!
            </p>
          </div>
          <button
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "16px",
              width: "auto",
              padding: "8px 24px",
              borderRadius: "80px",
              border: "none",
              fontSize: "1em",
              fontWeight: "500",
              height: "56px",
              backgroundColor: "var(--color-black)",
              color: "var(--color-white)",
              cursor: "pointer",
            }}
            onClick={() => closeWindows()}
          >
            Confirm
          </button>
        </div>
      </WIgetInform>
      <WIgetInform
        windowControlOpen={activateAddCommentError}
        windowControlClose={setActivateAddCommentError}
        img="https://firebasestorage.googleapis.com/v0/b/biketripste.appspot.com/o/static%2FlogoBikeTripster%2FerrComment2.jpg?alt=media&token=cee67c39-29e6-43c5-a37a-8ded747dede5"
      >
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "24px",
          }}
        >
          <div
            style={{
              backgroundImage:
                "url(https://firebasestorage.googleapis.com/v0/b/biketripste.appspot.com/o/static%2FlogoBikeTripster%2FverySurprised.svg?alt=media&token=288cbde6-a536-45d3-afe1-9595cf8d326a)",
              backgroundSize: "auto 100%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              width: "100%",
              height: "150px",
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
            }}
          >
            <h3
              style={{
                width: "70%",
              }}
            >
              Oops! There was an error while adding your comment.
            </h3>
            <p
              className="p-light"
              style={{
                width: "70%",
              }}
            >
              We apologize for the inconvenience. An unexpected error occurred,
              and our team is working to fix it. Please try again later. Thank
              you for your support!
            </p>
          </div>
          <button
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "16px",
              width: "auto",
              padding: "8px 24px",
              borderRadius: "80px",
              border: "none",
              fontSize: "1em",
              fontWeight: "500",
              height: "56px",
              backgroundColor: "var(--color-black)",
              color: "var(--color-white)",
              cursor: "pointer",
            }}
            onClick={() => closeWindows()}
          >
            Confirm
          </button>
        </div>
      </WIgetInform>
    </>
  );
};

export default WidgetAddComments;
