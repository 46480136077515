import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchDocumentsByIds } from "../../../services/firebase/crudFirestore";
import { fetchDocumentById } from "../../../services/firebase/crudFirebaseStorage";

export const getUserDataThunkV2 = createAsyncThunk(
  "reducersToolkit/getUserData",
  async (uid, { rejectWithValue }) => {
    try {
      // Получаем данные пользователя из Firebase
      const currentUser = await fetchDocumentById("users", uid);
      return { currentUser };
    } catch (error) {
      // Если произошла ошибка, передаем ее в обработчик ошибок
      return rejectWithValue(error.message);
    }
  }
);
