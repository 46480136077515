import React, { useEffect, useState } from "react";
import InputText from "../WidgetsProfile/Inputs/InputText";
import Textarea from "../WidgetsProfile/Textarea/Textarea";
import Button from "../WidgetsProfile/Buttons/Button";
import {
  setNewBasicInformationRentalPoint,
  setEditBasicInformationRentalPoint,
  setModerationRentalPoint,
} from "../../../../../reducersToolkit/rentalPointsSliiceV2";
import { useDispatch, useSelector } from "react-redux";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import {
  setBasicInformationModeration,
  // setModeration,
} from "../../../../../reducersToolkit/moderationSliceV2";
import ButtonsBar from "../WidgetsProfile/ButtonsBar/ButtonsBar";
import {
  setEditBasicInformationRentalPointV4,
  setModerationRentalPointV4,
  setNewBasicInformationRentalPointV4,
} from "../../../../../reducersToolkit/rentalPointsSliiceV4";

const WindowEditBasicInformationV4 = ({ mode, onClose }) => {
  const dispatch = useDispatch();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const { selectedRentalPoint, newRentalPoint } = useSelector(
    (state) => state.rentalPointsSliiceV4
  );

  const currentUser = useSelector((state) => state.userSliceV2.currentUser);

  const userChanges = {
    title: title,
    description: description,
    userId: currentUser.id,
    created: Date.now(),
  };

  const { selectedRentalPoint: selectedRentalPointModeration } = useSelector(
    (state) => state.moderationSliceV2
  );

  useEffect(() => {
    if (mode === "new") {
      setTitle(newRentalPoint.basicInformation.title.titleOriginal);
      setDescription(
        newRentalPoint.basicInformation.description.descriptionOriginal
      );
    } else if (mode === "edit") {
      setTitle(selectedRentalPoint.basicInformation.title.titleOriginal);
      setDescription(
        selectedRentalPoint.basicInformation.description.descriptionOriginal
      );
    } else if (mode === "moderation") {
      setTitle(
        selectedRentalPointModeration.basicInformation.title.titleOriginal
      );
      setDescription(
        selectedRentalPointModeration.basicInformation.description
          .descriptionOriginal
      );
    }
  }, [
    newRentalPoint,
    selectedRentalPoint,
    selectedRentalPointModeration,
    mode,
  ]);

  const handleSetTitle = (event) => {
    setTitle(event.target.value);
  };

  const handleSetDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleApply = () => {
    if (mode === "new") {
      dispatch(setNewBasicInformationRentalPointV4({ title, description }));
      dispatch(
        setModerationRentalPointV4({
          fildPatch: "selectedRentalPoint.basicInformation",
          fildName: "userChanges",
          data: userChanges,
        })
      );
    } else if (mode === "edit") {
      dispatch(setEditBasicInformationRentalPointV4({ title, description }));
      dispatch(
        setModerationRentalPointV4({
          fildPatch: "selectedRentalPoint.basicInformation",
          fildName: "userChanges",
          data: userChanges,
        })
      );
    } else if (mode === "moderation") {
      dispatch(
        setBasicInformationModeration({
          title,
          description,
        })
      );
      // dispatch(
      //   setModeration({
      //     fildPatch: "selectedRentalPoint.basicInformation",
      //     fildName: "userChanges",
      //     data: userChanges,
      //   })
      // );
    }
    onClose();
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "8px",
          paddingBottom: "72px",
        }}
      >
        <ContentBox position="start">
          <InputText
            label="Rental point name"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            value={title}
            onChange={handleSetTitle}
          />
          <Textarea
            label="Description rental point"
            maxLength={500}
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            value={description}
            onChange={handleSetDescription}
          />
        </ContentBox>
      </div>

      <ButtonsBar>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            alignItems: "center",
            gap: "16px",
            width: "100%",
          }}
        >
          <Button
            type="small"
            label="Cancel"
            active={true}
            color="--color-black"
            onClick={handleCancel}
          />
          <Button
            type="small"
            label="Apply"
            active={true}
            onClick={handleApply}
          />
        </div>
      </ButtonsBar>
    </>
  );
};

export default WindowEditBasicInformationV4;
