import React from "react";

const Checkbox = ({ label, checked, onChange, priority, id }) => {
  const handleCheckboxChange = () => {
    if (onChange) {
      if (id !== undefined) {
        onChange(id);
      } else {
        onChange(!checked); // Инвертируем текущее состояние, если id не указан
      }
    }
  };

  return (
    <label className={priority ? "checkbox-priority" : "checkbox"}>
      <input
        type="checkbox"
        checked={checked || false}
        onChange={handleCheckboxChange}
      />
      <span className={priority ? "checkbox-priority-label" : "checkbox-label"}>
        {label}
      </span>
    </label>
  );
};

export default Checkbox;

// const Checkbox = ({ label, checked, onChange, priority, id }) => {
//   const handleCheckboxChange = () => {
//     if (onChange) {
//       onChange(id); // Передаем идентификатор в обработчик изменения
//     }
//   };

//   return (
//     <label className={priority ? "checkbox-priority" : "checkbox"}>
//       <input
//         type="checkbox"
//         checked={checked || false}
//         onChange={handleCheckboxChange}
//       />
//       <span className={priority ? "checkbox-priority-label" : "checkbox-label"}>
//         {label}
//       </span>
//     </label>
//   );
// };

// export default Checkbox;
