import React, { useEffect, useState } from "react";
import PopupDirrectory from "../../WidgetsProfile/Popups/PopupDirrectory";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import PriorityImage from "../../WidgetsProfile/PhotosGallery/PriorityImage";
import WindowCityDetailV4 from "../CitiesV4/WindowCityDetailV4";
import { setCurrentCity } from "../../../../../../reducersToolkit/catalogSliceV4";
import Preloader from "../../../../../UIElements/Preloader";

const CityItem = ({ cityItem }) => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [windowCityDetail, setWindowCityDetail] = useState(false);
  const [files, setFiles] = useState([]);

  const handleClose = () => {
    setWindowCityDetail(false);
  };

  const handleOpenWindowCityDetail = (cityItem) => {
    dispatch(setCurrentCity(cityItem));
    setWindowCityDetail(true);
  };

  useEffect(() => {
    setTitle(cityItem?.title?.titleOriginal || "");
    setDescription(cityItem?.description?.descriptionOriginal || "");
    setFiles(cityItem?.files?.iconFiles || []);
  }, [cityItem]);

  return (
    <>
      <div
        key={Date.now().toString()}
        onClick={() => handleOpenWindowCityDetail(cityItem)}
        style={{
          width: "100%",
          backgroundColor: "var(--color-gray-100)",
          padding: "16px 8px 16px 16px ",
          boxSizing: "border-box",
          borderRadius: "16px ",
          cursor: "pointer",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "16px",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "16px",
          }}
        >
          <div>
            <PriorityImage
              photos={files}
              style={{
                aspectRatio: "1/1",
                borderRadius: "100%",
                width: "48px",
              }}
            />
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "4px",
            }}
          >
            <p
              style={{
                fontWeight: "600",
                display: "-webkit-box",
                WebkitLineClamp: 1,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {title}
            </p>
            <p
              className="p-light"
              style={{
                display: "-webkit-box",
                WebkitLineClamp: 3,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {description}
            </p>
          </div>
        </div>

        <span className="material-symbols-outlined">chevron_right</span>
      </div>
      <PopupDirrectory
        isOpen={windowCityDetail}
        onClose={handleClose}
        title="Add city V4"
      >
        <WindowCityDetailV4 mode="edit" onClose={handleClose} />
      </PopupDirrectory>
    </>
  );
};
const WidgetCitiesListV4 = ({ cities, isLoading }) => {
  const [sortedCities, setSortedCities] = useState([]);
  console.log("WidgetCitiesListV4 isLoading", isLoading);
  useEffect(() => {
    setSortedCities([...cities].sort((a, b) => b.createdat - a.createdat));
  }, [cities]);

  return isLoading ? (
    <Preloader />
  ) : (
    cities?.length > 0 && (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        {sortedCities?.map((cityItem) => (
          <CityItem key={cityItem.id || uuidv4()} cityItem={cityItem} />
        ))}
      </div>
    )
  );
};

export default WidgetCitiesListV4;
