import React, { useState, useEffect } from "react";
import "../profile.css";
import AddData from "../WidgetsProfile/AddData/AddData";
import PopupDirrectory from "../WidgetsProfile/Popups/PopupDirrectory";
import WindowEditEmailV4 from "./WindowEditEmailV4";
import WindowEditPhoneV4 from "./WindowEditPhoneV4";
import WindowEditPhoneModerationV4 from "../Moderation/WindowEditPhoneModerationV4";
import WindowEditEmailModerationV4 from "../Moderation/WindowEditEmailModerationV4";

const EmailList = ({ emailAddresses }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {emailAddresses?.map((emailObject, index) => (
        <p key={index}>{emailObject?.email}</p>
      ))}
    </div>
  );
};

const PhonesList = ({ phoneNumbers }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {phoneNumbers?.map((phoneObject, index) => (
        <p key={index}>{phoneObject?.phone}</p>
      ))}
    </div>
  );
};

const WidgetRentalPointContactV4 = ({ mode, contacts }) => {
  const [isOpenEmail, setIsOpenEmail] = useState(false);
  const [isOpenPhone, setIsOpenPhone] = useState(false);
  const [isOpenEmailModeration, setIsOpenEmailModeration] = useState(false);
  const [isOpenPhoneModeration, setIsOpenPhoneModeration] = useState(false);
  const [emailAddresses, setEmailAddresses] = useState([]);
  const [phoneNumbers, srtPhoneNumbers] = useState([]);

  useEffect(() => {
    setEmailAddresses(contacts?.emailAddresses || []);
    srtPhoneNumbers(contacts?.phoneNumbers || []);
  }, [contacts]);

  const handleClose = () => {
    setIsOpenEmail(false);
    setIsOpenPhone(false);
    setIsOpenEmailModeration(false);
    setIsOpenPhoneModeration(false);
  };
  const handleToggleEmail = () => {
    if (mode === "new" || mode === "edit") {
      setIsOpenEmail(true);
    } else if (mode === "moderation" || mode === "moderatorAdded") {
      setIsOpenEmailModeration(true);
    }
  };

  const handleTogglePhone = () => {
    if (mode === "new" || mode === "edit") {
      setIsOpenPhone(true);
    } else if (mode === "moderation" || mode === "moderatorAdded") {
      setIsOpenPhoneModeration(true);
    }
  };

  return (
    <>
      <div className="profile-widget__content-box">
        <AddData
          icon={
            emailAddresses.length > 0 ? (
              <span className="material-symbols-outlined">edit</span>
            ) : (
              <span className="material-symbols-outlined">add</span>
            )
          }
          title={`${
            emailAddresses.length > 0 ? "Contact emails:" : "Add email"
          }`}
          description={
            emailAddresses.length > 0 ? (
              <EmailList emailAddresses={emailAddresses} />
            ) : (
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            )
          }
          onClick={handleToggleEmail}
        />
        <AddData
          icon={
            phoneNumbers.length > 0 ? (
              <span className="material-symbols-outlined">edit</span>
            ) : (
              <span className="material-symbols-outlined">add</span>
            )
          }
          title={`${phoneNumbers.length > 0 ? "Contact phones:" : "Add phone"}`}
          description={
            phoneNumbers.length > 0 ? (
              <PhonesList phoneNumbers={phoneNumbers} />
            ) : (
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            )
          }
          onClick={handleTogglePhone}
        />
      </div>
      <PopupDirrectory
        isOpen={isOpenEmail}
        onClose={handleClose}
        title="Edit emails"
      >
        <WindowEditEmailV4 mode={mode} onClose={handleClose} />
      </PopupDirrectory>

      <PopupDirrectory
        isOpen={isOpenPhone}
        onClose={handleClose}
        title="Edit phones"
      >
        <WindowEditPhoneV4 mode={mode} onClose={handleClose} />
      </PopupDirrectory>

      <PopupDirrectory
        isOpen={isOpenPhoneModeration}
        onClose={handleClose}
        title="Edit phones Moderation"
      >
        <WindowEditPhoneModerationV4 mode={mode} onClose={handleClose} />
      </PopupDirrectory>

      <PopupDirrectory
        isOpen={isOpenEmailModeration}
        onClose={handleClose}
        title="Edit elails Moderation"
      >
        <WindowEditEmailModerationV4 mode={mode} onClose={handleClose} />
      </PopupDirrectory>
    </>
  );
};

export default WidgetRentalPointContactV4;
