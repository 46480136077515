// Импорт необходимых зависимостей
import React, { useState, useEffect } from "react";
import "firebase/compat/firestore";
import WigetButtonAdd from "../../../../../UniversalWidgets/WigetButtonAdd";
import WigetButtonDelete from "../../../../../UniversalWidgets/WigetButtonDelete";
import { useSubscribeData } from "../../../../../../services/firebase/crudFirebaseStorage";
import Popup from "../../../../../UIElements/Popup";

import Loading from "../../../../../UIElements/Loading";
import { useDispatch } from "react-redux";
import PreviewGallery from "../../../../../UIComponents/bars/gallery/PreviewGallery";
import { WigetTextareaV2 } from "../../../../../UniversalWidgets/WigetTextareaV2";

export const WigetFAQDetail = ({
  title,
  collectionName,
  structureCollection,
}) => {
  // Используем наш кастомный хук для подписки на данные Firestore и отслеживания состояния загрузки
  const { data: dataList, isLoading: loadingState } =
    useSubscribeData(collectionName);
  const dispatch = useDispatch();

  return (
    <div className="private_profile-mainBox">
      {/* <WigetButtonAdd
                collectionName={collectionName}
                dataObject={structureCollection}
                nameButton={<i className="fa-solid fa-plus"></i>}
            /> */}
      {/* <div className='item-menu'>
                <div className='item-menu-content'>
                    <div className='item-menu-content-left'>
                        <Icon44
                            click={() => dispatch(setPrivateSideBar(true))}
                            icon={
                                <span className="material-symbols-outlined">
                                    dock_to_right
                                </span>
                            }
                        />
                    </div>
                    <div className='item-menu-content-right'>
                        <WigetButtonAdd
                            collectionName={collectionName}
                            dataObject={structureCollection}
                            nameButton={`Добавить FAQ`}
                        />
                    </div>
                </div>
            </div> */}

      {/* Если данные все еще загружаются, отображаем сообщение "Loading..." */}
      {/* Как только данные загружены, отображаем список брендов */}
      {loadingState ? (
        <Loading />
      ) : (
        dataList.map((directoryItem, index) => (
          <div className="directory" key={index}>
            <div className="rentalPoints_content-box">
              <div
                className="rentalPoints_content-box-left"
                style={{ width: "50%" }}
              >
                <PreviewGallery
                  aspectRatio="16/9"
                  title="Иконка"
                  collectionName={collectionName}
                  idDocument={directoryItem.id}
                  fieldName="file_icon"
                  gallery={true}
                  description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eleifend ipsum vitae nunc gravida facilisis."
                />
              </div>

              <div
                className="rentalPoints_content-box-right"
                style={{ width: "50%" }}
              >
                <WigetTextareaV2
                  title="Вопрос"
                  collectionName={collectionName}
                  id={directoryItem.id}
                  fieldName="question"
                  quantity={1}
                  maxLength={1000}
                  description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eleifend ipsum vitae nunc gravida facilisis."
                />

                <WigetTextareaV2
                  title="Ответ"
                  collectionName={collectionName}
                  id={directoryItem.id}
                  fieldName="answer"
                  quantity={1}
                  maxLength={1000}
                  description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eleifend ipsum vitae nunc gravida facilisis."
                />

                <div
                  style={{
                    padding: "0 16px",
                  }}
                >
                  <div className="wigetButtonDelete-imform">
                    <div className="wigetButtonDelete-imform-left">
                      <p className="p-light">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Fusce eleifend ipsum vitae nunc gravida facilisis.
                      </p>
                    </div>

                    <div className="wigetButtonDelete-imform-right">
                      <WigetButtonDelete
                        collectionName={collectionName}
                        documentId={directoryItem.id}
                        title="Удалить"
                        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eleifend ipsum vitae nunc gravida facilisis."
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export const WigetFAQList = ({ title, collectionName, description }) => {
  const [activePopup, setActivePopup] = useState(null);
  const [faqList, setFaqList] = useState([]);

  const { data, isLoading } = useSubscribeData(collectionName);
  const [expandedIndex, setExpandedIndex] = useState(null);

  useEffect(() => {
    if (data) {
      setFaqList(data);
    }
  }, [data]);

  const handleToggleAnswer = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(null); // Закрыть элемент, если он уже развернут
    } else {
      setExpandedIndex(index); // Развернуть элемент
    }
  };

  const handleOpenPopup = (popupName) => {
    setActivePopup(popupName);
  };

  const handleClosePopup = () => {
    setActivePopup(null);
  };

  return (
    <>
      <div className="inform-box">
        <div className="inform-box-header">{title}</div>

        <div className="inform-box-item">
          {faqList.length > 0 &&
            faqList.map((faq, index) => (
              <React.Fragment key={index}>
                <div className="inform-box-content inform-box-content-cursor">
                  <div
                    className="inform-box-icon-left"
                    style={{ backgroundImage: `url(${faq.file_icon[0]?.url})` }}
                  ></div>

                  <div
                    className="inform-box-content-left"
                    onClick={() => handleToggleAnswer(index)}
                  >
                    <p>{faq.question}</p>
                  </div>

                  <div className="inform-box-icon-right">
                    <i
                      className={`fa-solid ${
                        expandedIndex === index
                          ? "fa-angle-up"
                          : "fa-angle-down"
                      }`}
                    ></i>
                  </div>
                </div>

                {expandedIndex === index && (
                  <div
                    className="inform-box-content nform-box-content-answer"
                    key={index}
                  >
                    <div className="inform-box-content-left">{faq.answer}</div>
                  </div>
                )}
              </React.Fragment>
            ))}
        </div>

        {description && (
          <div className="inform-box-footer">
            <p>{description}</p>
          </div>
        )}
      </div>

      {activePopup === `popu_${title}` && (
        <Popup isOpen={true} onClose={handleClosePopup} title={title}>
          <div className="inform-box">
            <div className="inform-box-header">{title}</div>
            <div className="inform-box-item">
              {isLoading ? (
                <div>Loading...</div>
              ) : (
                faqList.map((faq, index) => (
                  <div className="inform-box-content" key={index}>
                    <div className="inform-box-content-left">
                      {faq.question}
                    </div>

                    <div className="inform-box-content-left">{faq.answer}</div>
                  </div>
                ))
              )}
            </div>
          </div>

          <div className="send-view">
            <div className="send-view-left"></div>
            <div className="send-view-right">
              <WigetButtonAdd
                collectionName={collectionName}
                nameButton="Выбрать"
                dataObject={{ faqList }}
              />
            </div>
          </div>
        </Popup>
      )}
    </>
  );
};
