import { useEffect } from "react";
import { useState } from "react";

const WigetProportions = ({ idBlock }) => {

    const [resizing, setResizing] = useState(false);
    const [resizeStartX, setResizeStartX] = useState(0);
    const [initialWidth, setInitialWidth] = useState(0);

    const handleResizeStart = (e) => {
        setResizing(true);
        setResizeStartX(e.clientX);
        setInitialWidth(document.getElementById(`${idBlock}`).offsetWidth);
    };

    const handleResizeMove = (e) => {
        if (resizing) {
            const newWidth = initialWidth + (e.clientX - resizeStartX);
            document.getElementById(`${idBlock}`).style.width = `${newWidth}px`;
        }
    };

    const handleResizeEnd = () => {
        setResizing(false);
    };

    useEffect(() => {
        document.addEventListener("mousemove", handleResizeMove);
        document.addEventListener("mouseup", handleResizeEnd);

        return () => {
            document.removeEventListener("mousemove", handleResizeMove);
            document.removeEventListener("mouseup", handleResizeEnd);
        };
    }, [resizing]);

    return (
        <div
            style={{
                height: "100%",
                width: "16px",
                cursor: "ew-resize",
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'var(--color-gray-400)'
            }}
            onMouseDown={handleResizeStart}
        >
            <div style={{
                height: '50px',
                width: '4px',
                backgroundColor: 'rgba(245, 245, 245, 1)',
                borderRadius: '80px'
            }} />


        </div>
    )
};
export default WigetProportions