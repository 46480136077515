import { createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import interpretAuthErrorV2 from "../../../functions/interpretAuthErrorV2";

export const filterBikesThunkV2 = createAsyncThunk(
  "catalog/filterBikesThunkV2",
  async ({
    countryIdFilter = null,
    cityIdFilter = null,
    qualityBikeFilter = null,
    dateIntervalFilter = null,
    brandIdFilter = null,
    categoryIdFilter = null,
    bikes,
  }) => {
    try {
      // Преобразование dateIntervalFilter в месяцы, если он задан
      const startMonth = dateIntervalFilter
        ? new Date(dateIntervalFilter[0]).getMonth() + 1 // добавляем +1, т.к. getMonth() возвращает индекс месяца (0-11)
        : null;
      const endMonth = dateIntervalFilter
        ? new Date(dateIntervalFilter[1]).getMonth() + 1 // добавляем +1, т.к. getMonth() возвращает индекс месяца (0-11)
        : null;

      // Фильтрация велосипедов по заданным параметрам
      const filteredBikes = bikes.filter((bike) => {
        const matchesCountry = countryIdFilter
          ? bike?.rentalPoint?.location?.countryId === countryIdFilter
          : true;

        const matchesCity = cityIdFilter
          ? bike?.rentalPoint?.location?.cityid === cityIdFilter
          : true;

        const matchesQuality = qualityBikeFilter
          ? bike?.metricsBike?.quantityBikes !== undefined &&
            bike?.metricsBike?.quantityBikes >= qualityBikeFilter
          : true;

        const matchesDate = dateIntervalFilter
          ? bike?.rate?.some((rate) => {
              const inMonthRange =
                rate?.month &&
                rate?.month >= startMonth &&
                rate?.month <= endMonth;
              const priceValid = rate?.price > 0;

              return inMonthRange && priceValid;
            })
          : true;

        const matchesBrand = brandIdFilter
          ? bike?.directory?.brandId === brandIdFilter
          : true;

        const matchesCategory = categoryIdFilter
          ? bike?.directory?.categoryId === categoryIdFilter
          : true;

        return (
          matchesCountry &&
          matchesCity &&
          matchesQuality &&
          matchesDate &&
          matchesBrand &&
          matchesCategory
        );
      });

      return filteredBikes;
    } catch (error) {
      const errorMessage = interpretAuthErrorV2(error.code);
      return isRejectedWithValue({ errorMessage });
    }
  }
);

// import { createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
// import interpretAuthErrorV2 from "../../../functions/interpretAuthErrorV2";

// export const filterBikesThunkV2 = createAsyncThunk(
//   "catalog/filterBikesThunkV2",
//   async ({
//     countryIdFilter = null,
//     cityIdFilter = null,
//     qualityBikeFilter = null,
//     dateIntervalFilter = null,
//     brandIdFilter = null,
//     categoryIdFilter = null,
//     bikes,
//   }) => {
//     console.log(
//       "catalog/filterBikesThunkV2 bikes",
//       brandIdFilter,
//       categoryIdFilter
//     );
//     try {
//       // Преобразование dateIntervalFilter в месяцы, если он задан
//       const startMonth = dateIntervalFilter
//         ? new Date(dateIntervalFilter[0]).getMonth() + 1 // добавляем +1, т.к. getMonth() возвращает индекс месяца (0-11)
//         : null;
//       const endMonth = dateIntervalFilter
//         ? new Date(dateIntervalFilter[1]).getMonth() + 1 // добавляем +1, т.к. getMonth() возвращает индекс месяца (0-11)
//         : null;

//       // Фильтрация велосипедов по заданным параметрам
//       const filteredBikes = bikes.filter((bike) => {
//         const matchesCountry = countryIdFilter
//           ? bike?.rentalPoint?.location?.countryId === countryIdFilter
//           : true;

//         const matchesCity = cityIdFilter
//           ? bike?.rentalPoint?.location?.cityid === cityIdFilter
//           : true;

//         const matchesQuality = qualityBikeFilter
//           ? bike?.metricsBike?.quantityBikes !== undefined &&
//             bike?.metricsBike?.quantityBikes >= qualityBikeFilter
//           : true;

//         const matchesDate = dateIntervalFilter
//           ? bike?.rate?.some((rate) => {
//               const inMonthRange =
//                 rate?.month &&
//                 rate?.month >= startMonth &&
//                 rate?.month <= endMonth;
//               const priceValid = rate?.price > 0;

//               return inMonthRange && priceValid;
//             })
//           : true;

//         return matchesCountry && matchesCity && matchesQuality && matchesDate;
//       });

//       return filteredBikes;
//     } catch (error) {
//       const errorMessage = interpretAuthErrorV2(error.code);
//       return isRejectedWithValue({ errorMessage });
//     }
//   }
// );
