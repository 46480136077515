import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addData,
  updateDocumentFields,
} from "../../../services/firebase/crudFirestore";

export const addRentalPointsModerationThunkV2 = createAsyncThunk(
  "reducersToolkit/thunk/moderation/addRentalPointsModerationThunkV2",
  async ({ collectionName, newData, userId = null }, { rejectWithValue }) => {
    if (!userId) {
      return rejectWithValue("User ID not found");
    }
    try {
      const addedData = await addData(collectionName, newData);
      const rentalPointId = addedData.id;

      // Обновляем документ пользователя, добавляя новый rentalPointId в массив
      await updateDocumentFields("users", userId, {
        rentalPointsIds: [rentalPointId],
      });

      return addedData;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
