import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveUserDataThank } from "../../../../../slice/general/user/saveUserDataThank";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import WidgetUserAvatar from "../UserProfile/WidgetUserAvatar";
import InputText from "../WidgetsProfile/Inputs/InputText";
import SubtitleBlockSection from "../WidgetsProfile/SubtitleBlockSection/SubtitleBlockSection";
import SegmentedControls from "../WidgetsProfile/SegmentedControls/SegmentedControls";
import DropdownList from "../WidgetsProfile/DropdownList/DropdownList";
import moment from "moment-timezone";
import Textarea from "../WidgetsProfile/Textarea/Textarea";
import Switcher from "../WidgetsProfile/Switcher/Switcher";
import Button from "../WidgetsProfile/Buttons/Button";
import { saveUserModerationDataThankV2 } from "../../../../../reducersToolkit/thunk/moderation/saveUserModerationDataThankV2";

const WindowUserProfileModeration = () => {
  const dispatch = useDispatch();

  const [acceptOffer, setAcceptOffer] = useState(false);
  const [uId, setUId] = useState(null);
  const [contactingTheUser, setContactingTheUser] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [ownerSurname, setOwnerSurname] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [gender, setGender] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [mailingAddress, setMailingAddress] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [yourGender, setYourGender] = useState("");
  const [descriptionCompany, setDescriptionCompany] = useState("");
  const [offerConfirm, setOfferConfirm] = useState("");

  // const { isLoading, currentUser } = useSelector((state) => state.userSliceV2);

  const { selectedUser, isLoading } = useSelector(
    (state) => state.moderationSliceV2
  );

  useEffect(() => {
    setUId(selectedUser?.id);
    setContactingTheUser(selectedUser?.user?.contactingTheUser || "");
    setOwnerName(selectedUser?.user?.ownerName || "");
    setOwnerSurname(selectedUser?.user?.ownerSurname || "");
    setGender(selectedUser?.user?.gender || "");
    setYourGender(selectedUser?.user?.yourGender || "");
    //
    setEmail(selectedUser?.userContacts?.email || "");
    setPhoneNumber(selectedUser?.userContacts?.phoneNumber || "");
    //
    setCompanyName(selectedUser?.company?.companyName || "");
    setMailingAddress(selectedUser?.company?.mailingAddress || "");
    setTimeZone(selectedUser?.company?.timeZone || "");
    setDescriptionCompany(
      selectedUser?.company?.description?.descriptionOriginal || ""
    );

    setOfferConfirm(selectedUser?.offerConfirm || "");
  }, [selectedUser]);

  const handleUpdateFields = () => {
    const collectionName = "users";
    const docId = uId;
    const fieldsToUpdate = {
      user: {
        contactingTheUser: contactingTheUser,
        ownerName: ownerName,
        ownerSurname: ownerSurname,
        gender: gender,
        yourGender: yourGender,
      },
      userContacts: {
        email: email,
        phoneNumber: phoneNumber,
      },
      company: {
        companyName: companyName,
        mailingAddress: mailingAddress,
        timeZone: timeZone,
        description: {
          descriptionOriginal: descriptionCompany,
        },
      },

      offerConfirm: Date.now().toString(),
    };

    dispatch(
      saveUserModerationDataThankV2({ collectionName, docId, fieldsToUpdate })
    )
      .then(() => {
        console.log("Fields updated successfully");
      })
      .catch((error) => {
        console.error("Failed to update fields:", error);
      });
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "8px",
      }}
    >
      <ContentBox isLoading={isLoading} position="start" label="User avatar">
        <WidgetUserAvatar
          currentUser={selectedUser}
          collectionName="users"
          documentId={uId}
          fieldName="user.files.avatarFiles"
          iconAddphoto={true}
          size={160}
        />

        <InputText
          type="text"
          label="How can I call you?"
          description="Please enter your name as you would like to be addressed."
          value={contactingTheUser}
          onChange={(event) => setContactingTheUser(event.target.value)}
        />
      </ContentBox>

      <ContentBox isLoading={isLoading} label="User contacts">
        <SubtitleBlockSection
          title="User contacts"
          description="Contact information for quick communication with you. Please provide your email address and phone number. This information will be used for notifications and communication with you on important matters."
        />

        <InputText
          type="email"
          label="Email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />

        <InputText
          type="text"
          label="Phone"
          value={phoneNumber}
          onChange={(event) => setPhoneNumber(event.target.value)}
        />
      </ContentBox>

      <ContentBox isLoading={isLoading} label="How can I call you?">
        <SubtitleBlockSection
          title="How can I call you?"
          description="We use this data to send you important notifications and official messages."
        />

        <InputText
          type="text"
          label="Owner's name"
          value={ownerName}
          onChange={(event) => setOwnerName(event.target.value)}
        />

        <InputText
          type="text"
          label="Surname"
          value={ownerSurname}
          onChange={(event) => setOwnerSurname(event.target.value)}
        />

        <SegmentedControls
          options={["male", "female", "another"]}
          label="Your gender"
          description="Please specify your gender for accurate addressing in our notifications and messages. Your data will remain confidential and secure."
          selected={setGender}
          onSelect={gender}
        />

        {gender && gender === "another" && (
          <InputText
            type="text"
            label="Your gender"
            value={yourGender}
            onChange={(event) => setYourGender(event.target.value)}
          />
        )}
      </ContentBox>

      <ContentBox
        isLoading={isLoading}
        position="end"
        label="Company information"
      >
        <SubtitleBlockSection
          title="Company information"
          description="Please provide information about your company: name, mailing address, and select your timezone."
        />

        <InputText
          type="text"
          label="Company name"
          value={companyName}
          onChange={(event) => setCompanyName(event.target.value)}
        />

        <DropdownList
          options={moment.tz.names()}
          onSelect={setTimeZone}
          label={`${timeZone ? timeZone : "Timezone"}`}
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
        />

        <Textarea
          type="text"
          label="Description company"
          description="Please provide the address where you would like to receive official documents and notifications."
          value={descriptionCompany}
          onChange={(event) => setDescriptionCompany(event.target.value)}
          maxLength={2000}
        />

        <InputText
          type="text"
          label="Mailing address"
          description="Please provide the address where you would like to receive official documents and notifications."
          value={mailingAddress}
          onChange={(event) => setMailingAddress(event.target.value)}
        />

        <Switcher
          isChecked={acceptOffer} // Используем состояние isOpen для определения состояния Switcher
          onChange={setAcceptOffer} // При изменении Switcher вызываем функцию для открытия/закрытия PopupDropdownList
          label="I confirm my agreement with the offer"
          description="I confirm that I agree to the terms of the offer."
        />

        <Button
          active={acceptOffer}
          label="Save"
          onClick={handleUpdateFields}
          allert={
            <h1
              style={{
                padding: "80px",
                textAlign: "center",
              }}
            >
              Please confirm that you want to update the user information.
            </h1>
          }
        />
      </ContentBox>
    </div>
  );
};

export default WindowUserProfileModeration;
