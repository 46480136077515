import React, { useState, useEffect, useRef } from "react";

const SearchAddewssInput = ({
  label,
  type,
  name,
  value,
  onChange,
  description,
  maxLength,
  title,
  error,
}) => {
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          width: "100%",
          gap: "8px",
        }}
      >
        <div className="input-container">
          <label
            htmlFor={name}
            className={`input-label ${
              isFocused || value ? "input-label-up" : ""
            }`}
          >
            {label}
          </label>

          <input
            style={{}}
            className="input-write"
            type={type}
            id={name}
            name={name}
            placeholder={name}
            value={value && value}
            onChange={onChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            // onChange={(e) => setInputValue(e.target.value)}
            // onInput={handleInput}
            ref={inputRef}
            required={true}
            autoomplete={
              type === "password"
                ? "new-password"
                : "off" || type === "email"
                ? "new-email"
                : "off"
            }
            minLength={6}
          />
        </div>

        {description && (
          <div
            style={{
              padding: "0 16px",
            }}
          >
            <p className="input-clue">{description}</p>
          </div>
        )}
      </div>
    </>
  );
};

export default SearchAddewssInput;
