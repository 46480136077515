import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const WidgetStatistic = ({
  rentalPointId,
  commentsData,
  loadingCommentsData,
}) => {
  const [filteredComments, setFilteredComments] = useState([]);
  const [averageGrade, setAverageGrade] = useState(0);
  const [color, setColor] = useState("");
  const [gradesSummary, setGradesSummary] = useState([]);

  const isLoading = useSelector((state) => state.stateFrontGUISlice.isLoading);

  useEffect(() => {
    // Вызываем функцию и получаем цвет и среднюю оценку
    const { color, roundedAverageGrade } =
      calculateAverageGradeColor(filteredComments);

    // Устанавливаем полученные значения в состояние компонента
    setColor(color);
    setAverageGrade(roundedAverageGrade);
  }, [filteredComments]); // Перезапуск эффекта при изменении commentsData

  const calculateAverageGradeColor = (commentsArray) => {
    let totalGrade = 0;
    let totalComments = 0;

    if (Array.isArray(commentsArray)) {
      commentsArray.forEach((item) => {
        // Добавляем оценку из masterComment
        totalGrade += item.masterComment?.grade ?? 0; // Добавлена проверка на undefined с помощью оператора "??".
        totalComments += 1;

        // Проверяем, является ли item.comments массивом перед вызовом forEach
        if (Array.isArray(item.comments)) {
          item.comments.forEach((comment) => {
            totalGrade += comment.grade;
            totalComments += 1;
          });
        }
      });
    }

    // Вычисляем среднее значение
    const averageGrade = totalComments > 0 ? totalGrade / totalComments : 0; // Избегаем деления на 0

    // Округляем среднюю оценку до одного знака после запятой
    const roundedAverageGrade = parseFloat(averageGrade.toFixed(1));

    // Определяем цвет в зависимости от средней оценки
    let color;
    if (roundedAverageGrade >= 4) {
      color = "var(--color-success)"; // Зеленый цвет для высоких оценок
    } else if (roundedAverageGrade >= 3) {
      color = "var(--color-warning)"; // Желтый цвет для средних оценок
    } else {
      color = "var(--color-alarm)"; // Красный цвет для низких оценок
    }

    // Возвращаем цвет
    return { color, roundedAverageGrade };
  };

  useEffect(() => {
    if (
      !loadingCommentsData &&
      Array.isArray(commentsData) &&
      commentsData.length > 0
    ) {
      const filteredComments = commentsData.filter(
        (comment) => comment.rentalPointId === rentalPointId
      );

      setFilteredComments(filteredComments);

      const gradesCount = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };

      filteredComments.forEach((comment) => {
        // Учитываем оценку из masterComment
        if (comment.masterComment?.grade) {
          gradesCount[comment.masterComment.grade] += 1;
        }

        // Учитываем оценки из вложенных комментариев
        if (Array.isArray(comment.comments)) {
          comment.comments.forEach((subComment) => {
            if (subComment?.grade) {
              gradesCount[subComment.grade] += 1;
            }
          });
        }
      });

      // Расчёт и установка суммарных данных
      const maxCount = Math.max(...Object.values(gradesCount));

      const summary = Object.keys(gradesCount).map((grade) => ({
        grade,
        gradesCount: gradesCount[grade],
        gradesPercentage:
          maxCount > 0
            ? ((gradesCount[grade] / maxCount) * 100).toFixed(2)
            : "0.00",
      }));

      setGradesSummary(summary);
    }
  }, [commentsData, loadingCommentsData, rentalPointId]);

  const Grades = ({ grade }) => {
    // Создаем массив с количеством элементов, равным значению grade

    const gradesSpans = Array.from({ length: grade }, (_, index) => (
      <span
        key={index}
        className="material-symbols-outlined"
        style={{ fontSize: "1rem", color: "var(--color-white)" }}
      >
        grade
      </span>
    ));

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        {gradesSpans}
      </div>
    );
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          // backgroundColor: "var(--color-gray-100)",
          // backgroundColor: "violet",
          // padding: "24px",
          borderRadius: "16px",
          boxSizing: "border-box",
          width: "100%",
          gap: "16px",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            right: "8px",
            top: "8px",
            backgroundColor: color,
            height: "40px",
            aspectRatio: "1/1",
            borderRadius: "80px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h6
            style={{
              letterSpacing: "-1px",
              color: "var(--color-white)",
            }}
          >
            {averageGrade}
          </h6>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h5>Rental point rating</h5>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: "4px",
            }}
          >
            {gradesSummary &&
              gradesSummary.map(({ grade, gradesCount, gradesPercentage }) => (
                <div
                  key={grade}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "8px",
                  }}
                >
                  <p
                    className="p-mini"
                    style={{ color: "var(--color-gray-800)" }}
                  >
                    {gradesCount}
                  </p>

                  <div
                    style={{
                      width: "200px",
                      // backgroundColor: "var(--color-white)",
                      borderRadius: "80px",
                      overflow: "hidden",
                      display: "flex",
                      flexDirection: "row",
                      gap: "4px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "var(--color-success)",
                        borderRadius: "80px",
                        width: `${Math.round(parseFloat(gradesPercentage))}%`,
                        height: "4px",
                      }}
                    />
                    <div
                      style={{
                        backgroundColor: "var(--color-white)",
                        borderRadius: "80px",
                        width: `calc(100% - ${Math.round(
                          parseFloat(gradesPercentage)
                        )}%)`,
                        height: "4px",
                      }}
                    />
                  </div>

                  <div
                    style={{
                      width: "100px",
                      boxSizing: "border-box",
                    }}
                  >
                    <Grades grade={grade} />
                  </div>
                </div>
              ))}
          </div>
        </div>
        {/* {averageGrade} */}
      </div>
    </>
  );
};
export default WidgetStatistic;
