import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchData } from "../../services/firebase/crudFirebaseStorage";

// Currency
export const fetchCurrencyData = createAsyncThunk(
  "currency/fetchCurrencyData/front",
  async () => {
    try {
      // Используем setTimeout для создания задержки в 1 секунду
      await new Promise((resolve) => setTimeout(resolve, 1000));

      const currencyData = await fetchData("currency");
      console.log("currencyData ✅", currencyData);
      return currencyData;
    } catch (error) {
      throw new Error(
        `Ошибка при получении данных currencyData: ${error.message}`
      );
    }
  }
);
