import { createSlice } from "@reduxjs/toolkit";
import { filterOrdersByIdsThunkV2 } from "./thunk/orders/filterOrdersByIdsThunkV2";
import { confirmOrderStatusThunk } from "./thunk/orders/confirmOrderStatusThunk";

const serializeTimestamp = (timestamp) => {
  if (
    timestamp &&
    typeof timestamp === "object" &&
    timestamp.seconds !== undefined &&
    timestamp.nanoseconds !== undefined
  ) {
    return {
      seconds: timestamp.seconds,
      nanoseconds: timestamp.nanoseconds,
    };
  }
  return timestamp;
};

const order = {
  createdat: 0,
  orderRentalPoint: {},
  orderBike: {},
  userCustomer: {},
  bookingDates: {
    startDay: "",
    endDay: "",
  },
  totals: {},
  quantityBikes: 0,
  orderStatuses: {
    // currentStatus: "",
    // historyStatus: [
    //   {
    //     status: "",
    //     userId: "",
    //     timestamp: 0,
    //     comment: "",
    //   },
    // ],
  },
  replacementBikes: [],
};

const basket = {
  clientData: {},
  orders: {
    historyStatus: [
      {
        userId: "",
        timestamp: 0,
        status: "",
      },
    ],
  },
};

const orderSliceV2 = createSlice({
  name: "reducersToolkit/orderSliceV2",
  initialState: {
    isLoading: false,
    error: null,
    orders: [],
    users: [],

    userOrders: [],

    currentOrder: [],

    newOrdersUser: [],
    openOrdersUser: [],
    closeOrdersUser: [],

    selectedOrder: {},
    newOrder: { ...order },
  },
  reducers: {
    setCurrentOrder(state, action) {
      state.currentOrder = action.payload;
    },

    setOrderTotals(state, action) {
      const { mode, totals } = action.payload;
      if (mode === "new") {
        state.newOrder.totals = { ...totals };
      } else if (mode === "edit") {
        state.selectedOrder.totals = totals;
      }
    },

    setOrdersList(state, action) {
      state.orders = action.payload.map((order) => {
        // Преобразуем все несериализуемые данные внутри заказа
        if (order.order_info?.bikeCostDetails?.dateIntervalTimestamp) {
          order.order_info.bikeCostDetails.dateIntervalTimestamp =
            order.order_info.bikeCostDetails.dateIntervalTimestamp.map(
              serializeTimestamp
            );
        }
        return order;
      });
    },
    setOrderBike(state, action) {
      const { mode, orderBike, createdat, orderRentalPoint, userCustomer } =
        action.payload;
      if (mode === "new") {
        state.newOrder.orderBike = orderBike;
        state.newOrder.createdat = createdat;
        state.newOrder.orderRentalPoint = orderRentalPoint;
        state.newOrder.userCustomer = userCustomer;
      } else if (mode === "edit") {
        state.selectedOrder.orderBike = orderBike;
        state.selectedOrder.createdat = createdat;
        state.selectedOrder.orderRentalPoint = orderRentalPoint;
        state.selectedOrder.userCustomer = userCustomer;
      }
    },

    setBookingDates(state, action) {
      const { mode, startDay, endDay } = action.payload;
      if (mode === "new") {
        state.newOrder.bookingDates.startDay = startDay;
        state.newOrder.bookingDates.endDay = endDay;
      } else if (mode === "edit") {
        state.selectedOrder.bookingDates.startDay = startDay;
        state.selectedOrder.bookingDates.endDay = endDay;
      }
    },

    setQuantityBikes(state, action) {
      const { mode, quantityBike } = action.payload;
      if (mode === "new") {
        state.newOrder.quantityBikes = quantityBike;
      } else if (mode === "edit") {
        state.selectedOrder.selectedOrder = quantityBike;
      }
    },

    setOrderAdditionalServices(state, action) {
      const { mode, additionalServices } = action.payload;
      if (mode === "new") {
        state.newOrder.orderRentalPoint.additionalServices.services =
          additionalServices;
      } else if (mode === "edit") {
        state.selectedOrder.orderRentalPoint.additionalServices.services =
          additionalServices;
      }
    },

    setOrderService(state, action) {
      const { mode, service, serviceId } = action.payload;
      const updateServices = (services) =>
        services.map((item) =>
          item.id === serviceId ? { ...item, ...service } : item
        );

      if (mode === "new") {
        state.newOrder.orderRentalPoint.additionalServices.services =
          updateServices(
            state.newOrder.orderRentalPoint.additionalServices.services
          );
      } else if (mode === "edit") {
        state.selectedOrder.orderRentalPoint.additionalServices.services =
          updateServices(
            state.selectedOrder.orderRentalPoint.additionalServices.services
          );
      }
    },

    setOrderStatus(state, action) {
      const { mode, status, userId, timestamp, comment } = action.payload;

      const newHistoryStatus = {
        status,
        userId,
        timestamp,
        comment,
      };

      if (mode === "new") {
        if (!state.newOrder.orderStatuses) {
          state.newOrder.orderStatuses = {
            currentStatus: "",
            historyStatus: [],
          };
        }
        if (!Array.isArray(state.newOrder.orderStatuses.historyStatus)) {
          state.newOrder.orderStatuses.historyStatus = [];
        }
        state.newOrder.orderStatuses.currentStatus = status;
        state.newOrder.orderStatuses.historyStatus.push(newHistoryStatus);
      } else if (mode === "edit") {
        if (!state.selectedOrder.orderStatuses) {
          state.selectedOrder.orderStatuses = {
            currentStatus: "",
            historyStatus: [],
          };
        }
        if (!Array.isArray(state.selectedOrder.orderStatuses.historyStatus)) {
          state.selectedOrder.orderStatuses.historyStatus = [];
        }
        state.selectedOrder.orderStatuses.currentStatus = status;
        state.selectedOrder.orderStatuses.historyStatus.push(newHistoryStatus);
      }
    },
  },

  extraReducers: (builder) => {
    builder
      // .addCase(createOrderThunkV2.pending, (state) => {
      // т.к. в UiProfileV3.js уэе есть подписка на коллекцию orders которая автоматически обновляет state, получается дублирование данных в state

      //   state.isLoading = true;
      //   state.error = null;
      // })
      // .addCase(createOrderThunkV2.fulfilled, (state, action) => {
      //   state.isLoading = false;
      //   state.orders.push(action.payload.newOrder);
      // })
      // .addCase(createOrderThunkV2.rejected, (state, action) => {
      //   state.isLoading = false;
      //   state.error = action.payload;
      // })
      .addCase(filterOrdersByIdsThunkV2.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(filterOrdersByIdsThunkV2.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userOrders = action.payload.map((order) => {
          // Преобразуем все несериализуемые данные внутри заказа
          if (order.order_info?.bikeCostDetails?.dateIntervalTimestamp) {
            order.order_info.bikeCostDetails.dateIntervalTimestamp =
              order.order_info.bikeCostDetails.dateIntervalTimestamp.map(
                serializeTimestamp
              );
          }
          return order;
        });
      })
      .addCase(filterOrdersByIdsThunkV2.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(confirmOrderStatusThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(confirmOrderStatusThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        // Логика обновления состояния после успешного обновления заказа
      })
      .addCase(confirmOrderStatusThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const {
  setOrderTotals,
  setCurrentOrder,
  setOrdersList,
  setOrderBike,
  setBookingDates,
  setQuantityBikes,
  setOrderAdditionalServices,
  setOrderService,
  setOrderStatus,
} = orderSliceV2.actions;
export default orderSliceV2.reducer;
