import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchData } from "../../services/firebase/crudFirebaseStorage";

export const fetchCommentsData = createAsyncThunk(
  "comments/fetchCommentsData/front",
  async () => {
    try {
      // Используем setTimeout для создания задержки в 1 секунду
      await new Promise((resolve) => setTimeout(resolve, 1000));

      const comments = await fetchData("comments");

      // Преобразование значений Timestamp в миллисекунды
      const commentsData = comments.map((comment) => ({
        ...comment,
        comments: comment.comments?.map((comments) => ({
          ...comments,
          date: comments.date.toMillis(), // Преобразование Timestamp в миллисекунды
        })),
        masterComment: {
          ...comment.masterComment,
          date: comment.masterComment.date.toMillis(), // Преобразование Timestamp в миллисекунды
        },
      }));
      // console.log("comments serializedCommentsData✅", serializedCommentsData);

      console.log("comments ✅", commentsData);
      return commentsData;
    } catch (error) {
      throw new Error(`Ошибка при получении данных comments: ${error.message}`);
    }
  }
);

// import { createAsyncThunk } from "@reduxjs/toolkit";
// import { fetchData } from "../../services/firebase/crudFirebaseStorage";

// export const fetchCommentsData = createAsyncThunk(
//   "comments/fetchCommentsData/front",
//   async () => {
//     try {
//       // Используем setTimeout для создания задержки в 1 секунду
//       await new Promise((resolve) => setTimeout(resolve, 1000));

//       const commentsData = await fetchData("comments");
//       console.log("comments ✅", commentsData);
//       return commentsData;
//     } catch (error) {
//       throw new Error(`Ошибка при получении данных comments: ${error.message}`);
//     }
//   }
// );
