import React, { useState, useEffect } from "react";
import { formatCurrencyUSD } from "../../../../../functions/currencyFormatter";
import PopupDirrectory from "../WidgetsProfile/Popups/PopupDirrectory";
import WindowOrderAddToCartAdditionalService from "./WindowOrderAddToCartAdditionalService";
import TitleBlockSection from "../WidgetsProfile/Titles/TitleBlockSection";
import WidgetOrderAditionalServicesTotal from "./WidgetOrderAditionalServicesTotal";

const WidgetOrderAdditionalServicesList = ({ mode, additionalServices }) => {
  const [
    windowOrderAddToCartAdditionalService,
    setWindowOrderAddToCartAdditionalService,
  ] = useState(false);
  const [additionalServiceId, setAdditionalServiceId] = useState(null);

  const handleOpen = (additionalServiceId) => {
    setAdditionalServiceId(additionalServiceId);
    setWindowOrderAddToCartAdditionalService(true);
  };

  const handleClose = () => {
    setWindowOrderAddToCartAdditionalService(false);
  };

  return (
    <>
      {additionalServices?.length > 0 && (
        <>
          <TitleBlockSection
            title="List of additional services"
            description="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
          />
          <WidgetOrderAditionalServicesTotal
            mode={mode}
            orderedAditionalServices={additionalServices}
          />
          <div
            style={{
              width: "100%",
              display: "grid",
              gridTemplateColumns: "repeat(1, 1fr)",
              gap: "8px",
              flex: "1",
            }}
          >
            {additionalServices?.map((additionalServiceItem, index) => (
              <div
                key={index}
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  gap: "16px",
                  cursor: "pointer",
                  backgroundColor: "var(--color-gray-100)",
                  borderRadius: "16px",
                }}
                onClick={() => handleOpen(additionalServiceItem.id)}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: "16px",
                    boxSizing: "border-box",
                  }}
                >
                  <p
                    className="p-light"
                    style={{
                      ...(additionalServiceItem?.counter > 0
                        ? {
                            color: "var(--color-black)",
                            backgroundColor: "var(--color-gray-200)",
                            padding: "4px 10px",
                            borderRadius: "80px",
                          }
                        : { color: "var(--color-gray-500)" }),
                    }}
                  >
                    {additionalServiceItem?.counter || "0"}
                    &nbsp;available
                  </p>

                  <p>{additionalServiceItem?.title?.titleOriginal}</p>
                </div>
                <div
                  style={{
                    padding: "16px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <p
                      style={{
                        fontWeight: "600",
                      }}
                    >
                      {formatCurrencyUSD(additionalServiceItem?.price)}
                    </p>

                    {additionalServiceItem.ordered !== undefined &&
                    additionalServiceItem.ordered !== 0 ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "4px",
                          padding: "4px 4px 4px 12px",
                          borderRadius: "80px",
                          backgroundColor: "var(--color-success)",
                          color: "var(--color-white)",
                        }}
                      >
                        <p
                          className="p-light"
                          style={{
                            color: "var(--color-white)",
                          }}
                        >
                          ordered&nbsp;{additionalServiceItem.ordered}
                        </p>

                        <span className="material-symbols-outlined">add</span>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          padding: "4px 4px 4px 12px",
                          borderRadius: "80px",
                        }}
                      >
                        <span className="material-symbols-outlined">add</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}

      <PopupDirrectory
        isOpen={windowOrderAddToCartAdditionalService}
        onClose={handleClose}
        title="Order additional services"
      >
        <WindowOrderAddToCartAdditionalService
          mode={mode}
          additionalServiceId={additionalServiceId}
          onClose={handleClose}
        />
      </PopupDirrectory>
    </>
  );
};

export default WidgetOrderAdditionalServicesList;
