import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchDocumentsByIds } from "../../../services/firebase/crudFirestore";

// Thunk для получения всех пользователей
export const fetchUsersThunk = createAsyncThunk(
  "moderationSlice/fetchUsers",
  async (_, { rejectWithValue }) => {
    try {
      const users = await fetchDocumentsByIds("users");

      const newUsers = [];
      const approvedUsers = [];
      const bannedUsers = [];

      users.forEach((user) => {
        switch (user.stateProfile) {
          case "new":
            newUsers.push(user);
            break;
          case "approved":
            approvedUsers.push(user);
            break;
          case "ban":
            bannedUsers.push(user);
            break;
          default:
            break;
        }
      });

      return { users, newUsers, approvedUsers, bannedUsers };
    } catch (error) {
      console.error("Ошибка при получении пользователей:", error);
      return rejectWithValue(error.message);
    }
  }
);
