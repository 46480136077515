import React, { useState } from "react";

const CollapsableText = ({ text }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "8px",
      }}
    >
      <div
        style={{
          maxHeight: isExpanded ? "1000px" : "calc(1.2em * 3)",
          overflow: "hidden",
          transition: "max-height 0.5s ease-out",
        }}
      >
        <p
          style={{
            lineHeight: "1.2em",
            margin: "0",
          }}
        >
          {text}
        </p>
      </div>

      <div
        style={{
          cursor: "pointer",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "var(--color-gray-200-alpha-50)",
          gap: "8px",
          padding: "4px 10px",
          borderRadius: "80px",
          width: "70px",
        }}
        onClick={toggleExpanded}
      >
        <p className="p-light">{isExpanded ? "Collapse" : "Expand"}</p>
        <span className="material-symbols-outlined p-light">
          {isExpanded ? "unfold_less" : "unfold_more"}
        </span>
      </div>
    </div>
  );
};

export default CollapsableText;
