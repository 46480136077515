import React, { useState, useEffect } from "react";
import "firebase/compat/firestore";
import WigetButtonAdd from "../../../../../UniversalWidgets/WigetButtonAdd";
import WigetButtonDelete from "../../../../../UniversalWidgets/WigetButtonDelete";
import { useSubscribeData } from "../../../../../../services/firebase/crudFirebaseStorage";
import Loading from "../../../../../UIElements/Loading";
import Popup from "../../../../../UIElements/Popup";
import Radio from "../../../../../UIElements/Radio";
import { useDispatch } from "react-redux";
import PreviewGallery from "../../../../../UIComponents/bars/gallery/PreviewGallery";
import { WigetInputV2 } from "../../../../../UniversalWidgets/WigetInputV2";
import { WigetTextareaV2 } from "../../../../../UniversalWidgets/WigetTextareaV2";
const { v4: uuidv4 } = require("uuid");

export const WigetCityDetail = ({
  title,
  collectionName,
  parentCollectionName,
}) => {
  const dispatch = useDispatch();
  //country
  const { data: country, isLoading: loadingRentalPonts } =
    useSubscribeData(collectionName);

  //city
  const { data: city, isLoading: loadingState } =
    useSubscribeData(parentCollectionName);

  const [expandedIndex, setExpandedIndex] = useState(null);

  const filteredDataList =
    city && country && country.city_id
      ? city.filter((city) => country.city_id.includes(city.id))
      : [];

  return (
    <div className="private_profile-mainBox ">
      {loadingState && loadingRentalPonts ? (
        <Loading />
      ) : (
        <>
          {country?.map((country, index) => (
            <React.Fragment key={uuidv4()}>
              {country.title ? (
                <React.Fragment>
                  <div
                    key={country.id}
                    className={`wigetItem ${
                      index === filteredDataList.length - 1
                        ? "wigetItem_finaly"
                        : ""
                    } ${expandedIndex === index ? "wigetItem_open" : ""}`}
                    onClick={() =>
                      setExpandedIndex(expandedIndex === index ? null : index)
                    }
                  >
                    <div className="rentalPoints_icon-location">
                      <div className="rentalPoints_title">
                        <div
                          className="rentalPoints_icon-container"
                          style={{
                            backgroundImage: `url(${
                              country.file_photo[0] && country.file_photo[0].url
                            })`,
                          }}
                        />

                        <p className="p-primary">
                          {country.title}
                          {country.city_id.length}
                        </p>
                      </div>

                      <WigetButtonAdd
                        nameButton="Add new city"
                        collectionName={parentCollectionName}
                        parentCollectionName={collectionName}
                        parentDocumentId={country.id}
                      />
                    </div>

                    {expandedIndex === index ? (
                      <i className="fa-solid fa-angle-up"></i>
                    ) : (
                      <i className="fa-solid fa-angle-down"></i>
                    )}
                  </div>
                </React.Fragment>
              ) : (
                ""
              )}

              {expandedIndex === index && (
                <div className="private_profile-mainBox">
                  {country.city_id &&
                    country.city_id.length > 0 &&
                    country.city_id.map((dataId) => (
                      <React.Fragment key={uuidv4()}>
                        <div className="rentalPoints_content-box">
                          <div
                            className="rentalPoints_content-box-left"
                            style={{ width: "50%" }}
                          >
                            <div
                              style={{
                                width: "100%",
                              }}
                            >
                              <PreviewGallery
                                aspectRatio="16/9"
                                title="photo city"
                                collectionName={parentCollectionName}
                                idDocument={dataId}
                                fieldName="file_photo"
                                gallery={true}
                                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eleifend ipsum vitae nunc gravida facilisis."
                              />
                            </div>
                          </div>

                          <div
                            className="rentalPoints_content-box-right"
                            style={{
                              width: "50%",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                gap: "16px",
                              }}
                            >
                              <WigetInputV2
                                title="Город"
                                collectionName={parentCollectionName}
                                idDocument={dataId}
                                fieldName="title"
                                quantity={1}
                                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                              />

                              <WigetTextareaV2
                                title="Укажите описание"
                                maxLength={1000}
                                collectionName={parentCollectionName}
                                idDocument={dataId}
                                fieldName="description"
                                quantity={1}
                                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eleifend ipsum vitae nunc gravida facilisis."
                              />
                            </div>

                            <div
                              style={{
                                padding: "0 16px",
                              }}
                            >
                              <div className="wigetButtonDelete-imform">
                                <div className="wigetButtonDelete-imform-left">
                                  <p className="p-light">
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit. Fusce eleifend ipsum vitae
                                    nunc gravida facilisis.
                                  </p>
                                </div>

                                <div className="wigetButtonDelete-imform-right">
                                  <WigetButtonDelete
                                    collectionName={parentCollectionName}
                                    documentId={dataId}
                                    title="Delete"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    ))}
                </div>
              )}
            </React.Fragment>
          ))}
        </>
      )}
    </div>
  );
};

/**
 * Компонент WigetCityCheck для отображения списка городов с возможностью выбора бренда.
 * @param {Object} props - Параметры компонента.
 * @param {string} props.title - Заголовок компонента.
 * @param {string} props.collectionName - Название коллекции с данными о брендах.
 * @param {string} props.fieldName - Имя поля, содержащего идентификатор выбранного бренда.
 * @param {string} props.parentCollectionName - Название родительской коллекции, из которой получаются данные.
 * @param {string} props.documentId - Идентификатор документа в родительской коллекции.
 * @param {string} props.description - Описание компонента.
 * @returns {JSX.Element} JSX-элемент компонента WigetCityCheck.
 */
export const WigetCityCheck = ({
  title,
  collectionName,
  fieldName,
  parentCollectionName,
  documentId,
  description,
}) => {
  const [activePopup, setActivePopup] = useState(null);
  const [citiesBySelectedCountry, setСitiesBySelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [cityIdFromRentalPoint, setCityIdFromRentalPoint] = useState(null);

  const [currentCountry, setCurrentCountry] = useState(null);
  const [currentCity, setCurrentCity] = useState(null);

  const { data: countryData, isLoadingCountry } =
    useSubscribeData(collectionName);

  const { data: cityData, isLoadingCity } =
    useSubscribeData(parentCollectionName);

  const { data: rentalPointsData, isLoadingRentalPoints } = useSubscribeData(
    "rentalPoints",
    documentId
  );

  useEffect(() => {
    if (rentalPointsData?.city_id && countryData && cityData) {
      setCityIdFromRentalPoint(rentalPointsData.city_id);

      const countryByCity = countryData.find((cnt) =>
        cnt.city_id.includes(rentalPointsData.city_id[0])
      );

      setSelectedCity(countryByCity?.id);
      setCurrentCountry(countryByCity?.title);

      if (countryByCity) {
        setСitiesBySelectedCountry(
          cityData.filter((cityData) =>
            countryByCity.city_id.includes(cityData.id)
          )
        );
        setCurrentCity(
          cityData.filter((item) => rentalPointsData.city_id.includes(item.id))
        );
      }
    }
  }, [rentalPointsData, countryData, cityData]);

  const handleOpenPopup = (popupName) => {
    setActivePopup(popupName);
  };

  const handleClosePopup = () => {
    setActivePopup(null);
  };

  const handleSelectCountry = (dataId) => {
    setSelectedCity(dataId);

    if (Array.isArray(cityData)) {
      const selectedData = countryData.find(
        (countryItem) => countryItem.id === dataId
      );
      const cityIds = selectedData ? selectedData.city_id : [];
      setСitiesBySelectedCountry(
        cityData.filter((item) => cityIds.includes(item.id))
      );
    }
  };

  const handleRadioButtonChangeCity = (dataId) => {
    setCityIdFromRentalPoint(dataId);
  };

  return (
    <>
      {/* Компонент с выбранным городом и страной */}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "0 16px",
          boxSizing: "border-box",
        }}
      >
        <div
          style={{
            backgroundColor: "var(--color-white)",
            boxSizing: "border-box",
            borderRadius: "8px",
            overflow: "hidden",
          }}
          onClick={() => handleOpenPopup(`popu_${title}`)}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              height: "48px",
              padding: "0 16px",
              borderBottom: "1px var(--color-gray-200) solid",
            }}
          >
            <p
              style={{
                textTransform: "uppercase",
              }}
              className="p-light"
            >
              {title}
            </p>

            <button onClick={() => handleOpenPopup(`popu_${fieldName}`)}>
              {/* {items.length > 0 ? 'Edit' : 'Add'} */}
              Edit
            </button>
          </div>

          {(rentalPointsData && rentalPointsData.city_id.length > 0
            ? rentalPointsData.city_id
            : []
          ).map((directoryItem) => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                marginLeft: "16px",
                boxSizing: "border-box",
                height: "48px",
              }}
              key={uuidv4()}
              onClick={() => handleOpenPopup(`popu_${fieldName}`)}
            >
              <div className="inform-box-content-left">
                {rentalPointsData ? (
                  <div>
                    {currentCountry && currentCountry[0]} -{" "}
                    {currentCity && currentCity[0].title[0]}
                  </div>
                ) : (
                  <div>
                    <p>Loading...</p>
                  </div>
                )}
              </div>

              <div className="inform-box-content-right">
                <span
                  style={{
                    color: "var(--color-gray-400)",
                  }}
                  className="material-symbols-outlined"
                >
                  chevron_right
                </span>
              </div>
            </div>
          ))}
        </div>

        {description && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "8px 16px",
            }}
          >
            <p className="p-light">{description}</p>
          </div>
        )}
      </div>

      {/* Попап для выбора страны и города */}
      {activePopup === `popu_${title}` && (
        <Popup
          isOpen={true}
          onClose={handleClosePopup}
          title={title}
          leftMenu={<></>}
          rightMenu={
            <>
              <WigetButtonAdd
                collectionName="rentalPoints"
                documentId={documentId}
                nameButton="Save"
                dataObject={{
                  [fieldName]: [cityIdFromRentalPoint],
                }}
              />
            </>
          }
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eleifend ipsum vitae nunc gravida facilisis. Pellentesque finibus, justo id tincidunt accumsan, mauris elit consequat nulla, vel tempus mi lorem vel ipsum. Integer id tincidunt sapien. Cras faucibus euismod massa eget interdum. Sed commodo, ante eu tempor condimentum, elit dui lacinia nisi, in pulvinar enim enim ut orci. Suspendisse potenti."
        >
          <div className="inform-box-inputItem">
            <div className="inform-box">
              <div className="inform-box-header">{title}</div>
              <div className="inform-box-item">
                {isLoadingCountry ? (
                  <div>Loading...</div>
                ) : (
                  countryData.map((countryItem) => (
                    <React.Fragment key={uuidv4()}>
                      <div className="inform-box-content">
                        <div
                          className="inform-box-content-left"
                          onClick={() => handleSelectCountry(countryItem.id)}
                        >
                          {countryItem.title}
                        </div>
                        <div className="inform-box-content-right">
                          {countryItem.file_photo &&
                            countryItem.file_photo
                              .filter((icon) => icon.priority)
                              .map((icon) => (
                                <div
                                  key={uuidv4()}
                                  className="inform-box-icon-right"
                                  style={{
                                    backgroundImage: `url(${icon.url})`,
                                  }}
                                />
                              ))}
                        </div>
                        {/* Остальной код для отображения элемента */}
                      </div>
                      {selectedCity === countryItem.id &&
                        citiesBySelectedCountry &&
                        citiesBySelectedCountry.length > 0 &&
                        citiesBySelectedCountry.map((item) => (
                          <div className="inform-box-content" key={uuidv4()}>
                            <div className="inform-box-content-left">
                              <Radio
                                value={item.title}
                                id={item.id}
                                checked={
                                  cityIdFromRentalPoint
                                    ? cityIdFromRentalPoint.includes(item.id)
                                    : false
                                }
                                onChange={handleRadioButtonChangeCity}
                              />
                            </div>
                            <div className="inform-box-content-right">
                              {item.file_photo &&
                                item.file_photo
                                  .filter((icon) => icon.priority)
                                  .map((icon) => (
                                    <div
                                      key={uuidv4()}
                                      className="inform-box-icon-right"
                                      style={{
                                        backgroundImage: `url(${icon.url})`,
                                      }}
                                    />
                                  ))}
                            </div>
                          </div>
                        ))}
                    </React.Fragment>
                  ))
                )}
              </div>
            </div>
          </div>
          {/* <div className="send-view">
            <div className="send-view-left" />
            <div className="send-view-right">
              <WigetButtonAdd
                collectionName="rentalPoints"
                documentId={documentId}
                nameButton="Выбрать"
                dataObject={{
                  [fieldName]: [cityIdFromRentalPoint],
                }}
              />
            </div>
          </div> */}
        </Popup>
      )}
    </>
  );
};
