import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux/es/hooks/useSelector";
import UIBikes from "./Bikes/UIBikes";
import UIOrders from "./Orders/UIOrders";
import UIBrand from "./Directory/Brand/UIBrand";
import UIСategoriesBike from "./Directory/СategoriesBike/UIСategoriesBike";
import UICountry from "./Country/UICountry";
import UICity from "./City/UICity";
import UIFAQ from "./FAQ/UIFAQ";
import UINews from "./News/UINews";
import UIStories from "./Stories/UIStories";
import UICurrency from "./Directory/Currency/UICurrency";
import GeterateJSON from "../../../Public/chatGPT/GeterateJSON";
import Translater from "./Translate/Translater";
import ChatbotGPTV2 from "../../../Public/chatGPT/ChatbotGPTV2";
import SideBarV3 from "../../../UIComponents/bars/sidebarV3/SideBarV3";
// import UIRentalPointV3 from "./RentalPointsV3/UIRentalPointV3";
import UserProfile from "./UserProfile/WindowUserProfile";
import WidgetUserPreview from "./UserProfile/WidgetUserPreview";
import WidgetRentalPoints from "./RentalPointsV3/WidgetRentalPoints";
import WidgetOrders from "./OrdersV3/WidgetOrders";
import WidgetModeration from "./Moderation/WidgetModeration.js";
import WidgetDirectory from "./DirectoryV3/WidgetDirectory";
import { useDispatch } from "react-redux";
import {
  fetchDocumentsByIds,
  subscribeToDocument,
} from "../../../../services/firebase/crudFirestore";
import { fetchRentalPointsThunkV2 } from "../../../../reducersToolkit/thunk/rentalPoints/fetchRentalPointsThunkV2";
import { fetchDirectoriesThunkV2 } from "../../../../reducersToolkit/thunk/directories/fetchDirectoriesThunkV2";
import {
  setBikesList,
  setRentalPointsList,
  setUsersList,
} from "../../../../reducersToolkit/moderationSliceV2";
import { setOrdersList } from "../../../../reducersToolkit/orderSliceV2";
import { filterOrdersByIdsThunkV2 } from "../../../../reducersToolkit/thunk/orders/filterOrdersByIdsThunkV2.js";
import { setCurrentUser } from "../../../../reducersToolkit/userSliceV2.js";
import WidgetRentalOffer from "./Offer/WidgetRentalOffer.js";
import { setNullcatalogSliceV4 } from "../../../../reducersToolkit/catalogSliceV4.js";
import WidgetRentalPointsV4 from "./RentalPointsV3/WidgetRentalPointsV4.js";

const UIProfileV3 = () => {
  const dispatch = useDispatch();
  const [userOrdersIds, setUserOrdersIds] = useState();

  const {
    isLoading: isLoadingUser,
    currentUser,
    error: errorUser,
  } = useSelector((state) => state.userSliceV2);

  const {
    isLoading: isLoadingUsers,
    users,
    usersModeration,
    usersApproved,
    usersBanned,
  } = useSelector((state) => state.moderationSliceV2);

  const {
    isLoading: isLoadingOrders,
    orders,
    userOrders,
  } = useSelector((state) => state.orderSliceV2);

  const { isLoading, rentalPointsList } = useSelector(
    (state) => state.rentalPointsSliiceV2
  );

  const userId = currentUser ? currentUser.id : null;
  const userRole = currentUser ? currentUser.role.currentRole : null;
  // const ordersIds = currentUser ? currentUser.ordersIds : null;

  // useEffect(() => {
  //   dispatch(fetchDirectoriesThunkV2());
  // }, [dispatch]);
  // // ------- CLIENT -------
  // useEffect(() => {
  //   if (userRole === "client") {
  //     const unsubscribe = subscribeToDocument(
  //       "users",
  //       userId,
  //       null,
  //       async (data) => {
  //         setUserOrdersIds(data.ordersIds || null);
  //         dispatch(setCurrentUser(data || {}));
  //         dispatch(fetchRentalPointsThunkV2(data.rentalPointsIds));
  //       }
  //     );

  //     return () => {
  //       console.log("unsubscribe users");
  //       unsubscribe();
  //     };
  //   }
  // }, [userRole, userId, dispatch]);

  // useEffect(() => {
  //   if (userRole === "client") {
  //     const unsubscribeOrders = subscribeToDocument(
  //       "orders",
  //       null,
  //       null,
  //       async (data) => {
  //         if (userOrdersIds?.length > 0) {
  //           dispatch(
  //             filterOrdersByIdsThunkV2({
  //               ordersList: data,
  //               userOrdersIds: userOrdersIds,
  //             })
  //           );
  //         }
  //       }
  //     );

  //     return () => {
  //       console.log("unsubscribe  orders");
  //       unsubscribeOrders();
  //     };
  //   }
  // }, [userRole, userOrdersIds, dispatch]);
  // // ------- RENTAL -------
  // useEffect(() => {
  //   if (userRole === "rental") {
  //     const unsubscribe = subscribeToDocument(
  //       "users",
  //       userId,
  //       null,
  //       async (data) => {
  //         setUserOrdersIds(data.ordersIds || null);
  //         dispatch(setCurrentUser(data || {}));
  //         dispatch(fetchRentalPointsThunkV2(data.rentalPointsIds));
  //       }
  //     );

  //     return () => {
  //       console.log("unsubscribe users");
  //       unsubscribe();
  //     };
  //   }
  // }, [userRole, userId, dispatch]);

  // useEffect(() => {
  //   if (userRole === "rental") {
  //     const unsubscribeOrders = subscribeToDocument(
  //       "orders",
  //       null,
  //       null,
  //       async (data) => {
  //         if (userOrdersIds?.length > 0) {
  //           dispatch(
  //             filterOrdersByIdsThunkV2({
  //               ordersList: data,
  //               userOrdersIds: userOrdersIds,
  //             })
  //           );
  //         }
  //       }
  //     );

  //     return () => {
  //       console.log("unsubscribe  orders");
  //       unsubscribeOrders();
  //     };
  //   }
  // }, [userRole, userOrdersIds, dispatch]);
  // ------- RENTAL END -------
  // ------- ADMIN -------
  useEffect(() => {
    if (userRole === "admin") {
      // Подписываемся на всех пользователей
      // const unsubscribeUsers = subscribeToDocument(
      //   "users",
      //   null,
      //   null,
      //   async (data) => {
      //     dispatch(setUsersList(data));
      //     const user = data.find((user) => user.id === userId);
      //     dispatch(fetchRentalPointsThunkV2(user.rentalPointsIds || []));
      //   }
      // );

      // const unsubscribeRentalPoints = subscribeToDocument(
      //   "rentalPoints",
      //   null,
      //   null,
      //   async (data) => {
      //     dispatch(setRentalPointsList(data));
      //   }
      // );

      // const unsubscribeBikes = subscribeToDocument(
      //   "bikes",
      //   null,
      //   null,
      //   async (data) => {
      //     dispatch(setBikesList(data));
      //   }
      // );

      // const unsubscribeOrders = subscribeToDocument(
      //   "orders",
      //   null,
      //   null,
      //   async (data) => {
      //     dispatch(setOrdersList(data));
      //   }
      // );
      return () => {
        console.log("unsubscribe all users, bikes, rentalPoints, orders");
        // unsubscribeUsers();
        // unsubscribeRentalPoints();
        // unsubscribeBikes();
        // unsubscribeOrders();
      };
    }
  }, [userId, userOrdersIds, userRole, dispatch]);

  // switch (currentUser.role) {
  //   case "rental":
  //     console.log("UIProfileV3", "rental");
  //     break;
  //   case "admin":
  //     console.log("UIProfileV3", "admin");
  //     break;
  //   case "manager":
  //     console.log("UIProfileV3", "manager");
  //     break;
  //   default:
  //     console.log("UIProfileV3", "default");
  // }
  // Подписка на коллекции: users - для получкения списка id точек проката
  // useEffect(() => {
  //   const unsubscribe = subscribeToDocument(
  //     "users",
  //     userId,
  //     null,
  //     async (data) => {
  //       dispatch(fetchRentalPointsThunkV2(data.rentalPointsIds));
  //     }
  //   );
  //   return () => {
  //     console.log("unsubscribe users");
  //     unsubscribe();
  //   };
  // }, [userId, dispatch]);

  // Подписываемся на всех пользователей, чтобы обновлять список пользователей в online
  // useEffect(() => {
  //   const unsubscribe = subscribeToDocument(
  //     "users",
  //     null,
  //     null,
  //     async (data) => {
  //       // dispatch(setUsers(data));
  //       // dispatch(fetchModerationDataThunkV2());
  //     }
  //   );
  //   return () => {
  //     console.log("unsubscribe all users");
  //     unsubscribe();
  //   };
  // }, [dispatch]);

  // useEffect(() => {
  //   dispatch(fetchDirectoryThunk());
  // }, [dispatch]);

  // const currentSideBarItem = useSelector(
  //   (state) => state.stateGUISlice.currentSideBarItem
  // );

  // const ChapterUi = () => {
  //   switch (currentSideBarItem) {
  //     case undefined:
  //     case "/rentalPoints":
  //     // return <UIRentalPointV3 />;
  //     case "/orders":
  //       return <UIOrders />;
  //     case "/bikes":
  //       return <UIBikes />;
  //     case "/brand":
  //       return <UIBrand />;
  //     case "/currency":
  //       return <UICurrency />;
  //     case "/category":
  //       return <UIСategoriesBike />;
  //     case "/country":
  //       return <UICountry />;
  //     case "/city":
  //       return <UICity />;
  //     case "/faq":
  //       return <UIFAQ />;
  //     case "/editNews":
  //       return <UINews />;
  //     case "/stories":
  //       return <UIStories />;
  //     case "/chatbotGPT":
  //       // Плагин для firebase
  //       return <ChatbotGPTV2 />;

  //     // Интеграция с GPT
  //     // return <OpenAi />;

  //     // Интеграция с ассистентом
  //     // return <BikeTripsterAssistant />;

  //     case "/generateJSON":
  //       return <GeterateJSON />;
  //     case "/translater":
  //       return <Translater />;
  //     default:
  //       return (
  //         <div
  //           style={{
  //             // width: "100%",
  //             // height: "100vh",
  //             display: "flex",
  //             alignItems: "center",
  //             justifyContent: "center",
  //           }}
  //         >
  //           <p className="p-light">Page not found.</p>
  //         </div>
  //       );
  //   }
  // };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      {/* <div
        style={{
          position: "fixed",
          top: "80px",
          left: "0",
          height: "100%",
        }}
      >
        <SideBarV3 />
      </div> */}

      <div
        style={{
          paddingTop: "80px",
          maxWidth: "var(--width-mobile-content)",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "8px",
          boxSizing: "border-box",
        }}
      >
        {/* {userRole === "admin" && ( */}
        {userRole === "rental" && (
          <>
            {/* <WidgetUserPreview isLoading={isLoadingUser} user={currentUser} /> */}
            <WidgetModeration
              isLoading={isLoadingUsers}
              users={{ users, usersModeration, usersApproved, usersBanned }}
            />
            {/*  <WidgetOrders orders={orders} isLoading={isLoadingOrders} /> */}
            <WidgetDirectory />
          </>
        )}

        {userRole === "admin" && (
          // {userRole === "rental" && (

          <>
            {/* <WidgetUserPreview isLoading={isLoadingUser} user={currentUser} /> */}
            <WidgetRentalPointsV4
              isLoading={isLoading}
              rentalPointsList={rentalPointsList}
            />
            {/* <WidgetOrders orders={userOrders} isLoading={isLoadingOrders} /> */}
            <WidgetDirectory />
          </>
        )}

        {userRole === "client" && (
          <>
            {/* <WidgetUserPreview isLoading={isLoadingUser} user={currentUser} />

            <WidgetRentalOffer user={currentUser} />

            <WidgetOrders orders={userOrders} isLoading={isLoadingOrders} /> */}
          </>
        )}
      </div>
    </div>
  );
};

export default UIProfileV3;
