import { createAsyncThunk } from "@reduxjs/toolkit";
import { doc, updateDoc } from "firebase/firestore";
import { uploadFilesToFirebase } from "../../../../services/firebase/crudFirestore";
import { db } from "../../../../services/firebase/authFirebase";

export const updateCountryThunkV4 = createAsyncThunk(
  "countries/updateCountryThunkV4",
  async ({ countryId, updatedData, newFiles }, { rejectWithValue }) => {
    try {
      // 1. Получение ссылки на документ
      const docRef = doc(db, "catalog", countryId);

      // 2. Если есть новые файлы, загружаем их
      let uploadedFiles = [];
      if (newFiles && newFiles.length > 0) {
        uploadedFiles = await uploadFilesToFirebase(
          `countries/${countryId}`,
          newFiles
        );
      }

      // 3. Объединяем старые файлы с новыми
      const filesUpdate =
        uploadedFiles.length > 0
          ? {
              files: {
                iconFiles: [
                  ...(updatedData?.files?.iconFiles || []),
                  ...uploadedFiles,
                ],
              },
            }
          : {};

      // 4. Обновляем документ в Firestore
      const finalData = { ...updatedData, ...filesUpdate };
      await updateDoc(docRef, finalData);

      console.log("Документ в Firestore успешно обновлен.");

      return { countryId, updatedData: finalData };
    } catch (error) {
      console.error("Ошибка при обновлении страны:", error);
      return rejectWithValue(error.message);
    }
  }
);
