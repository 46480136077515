import React, { useState, useEffect } from "react";
import "firebase/compat/firestore";
import { DateWrite } from "../UIElements/Date";
import Button from "../UIElements/Button";
import Popup from "../UIElements/Popup";
import {
  fetchDocumentById,
  updateDocumentById,
} from "../../services/firebase/crudFirebaseStorage";
import Checkbox from "../UIElements/Checkbox";
import Loading from "../UIElements/Loading";
import DateFormat from "../Public/UniversalWidgets/supportFunctions/functionDateFormat";
/**
 * Компонент WigetPhones для работы с телефонами
 * @param {string} collectionName - Имя коллекции в Firebase Firestore
 * @param {string} id - Идентификатор документа
 * @param {string} fieldName - Имя поля, содержащего телефоны
 * @param {string} title - Заголовок компонента
 * @param {number} quantity - Максимальное количество телефонов
 * @param {string} description - Описание компонента
 * @returns {JSX.Element} - JSX-элемент компонента
 */
export const WigetDate = ({
  collectionName,
  id,
  fieldName,
  title,
  quantity,
  description,
}) => {
  // Состояния
  const [activePopup, setActivePopup] = useState(null); // Состояние активного всплывающего окна
  const [existingData, setExistingData] = useState(null); // Состояние существующих данных
  const [isPending, setIsPending] = useState(false); // Состояние ожидания
  const [isLoading, setIsLoading] = useState(false); // Состояние загрузки
  const [isChanged, setIsChanged] = useState(false); // Состояние изменений
  const [markedForDelete, setMarkedForDelete] = useState({}); // Состояние помеченных для удаления данных
  const [isDeleteLoading, setIsDeleteLoading] = useState(false); // Состояние загрузки удаления

  const [formData, setFormData] = useState({
    [fieldName]: [],
  });

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const data = await fetchDocumentById(collectionName, id);

      if (data && data[fieldName]) {
        setExistingData(data);
        const numbers = Array.isArray(data[fieldName])
          ? data[fieldName]
          : [data[fieldName]];
        setFormData((prevFormData) => ({
          ...prevFormData,
          [fieldName]: [...numbers],
        }));
        setIsPending(false);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [collectionName, id, setFormData, fieldName, setIsPending]);

  // Пометить элемент для удаления
  const handleMarkForDelete = (index, isChecked) => {
    setMarkedForDelete((prevMarkedForDelete) => ({
      ...prevMarkedForDelete,
      [index]: isChecked,
    }));
  };

  // Проверить, есть ли помеченные для удаления элементы
  const isAnyMarkedForDelete = () => {
    return Object.values(markedForDelete).some((value) => value);
  };

  // Удалить помеченные элементы
  const handleDeleteMarked = async () => {
    setIsLoading(true);
    setFormData((prevFormData) => {
      const updatedData = prevFormData[fieldName].filter(
        (_, index) => !markedForDelete[index]
      );
      return { ...prevFormData, [fieldName]: updatedData };
    });
    setMarkedForDelete({});

    setIsDeleteLoading(true);
    await updateDocumentById(
      {
        [fieldName]: formData[fieldName].filter(
          (_, index) => !markedForDelete[index]
        ),
      },
      collectionName,
      id
    );
    setIsDeleteLoading(false);
    setIsChanged(false);
    setIsLoading(false);
    setIsPending(false);
    console.log("Удаленные данные успешно обновлены");
  };

  // Изменить значение элемента
  const handleChange = (e, index) => {
    const { value } = e.target;
    setFormData((prevFormData) => {
      const updatedData = prevFormData[fieldName].map((number, i) => {
        if (i === index) {
          return value;
        }
        return number;
      });
      return { ...prevFormData, [fieldName]: updatedData };
    });
    setIsChanged(true);
  };

  // Добавить новый элемент
  const handleAddItemData = () => {
    if (formData[fieldName].length < quantity) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [fieldName]: ["", ...prevFormData[fieldName]],
      }));
      setIsPending(true);
    }
  };

  // Обработать отправку данных
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (existingData) {
      await updateDocumentById(
        {
          [fieldName]: formData[fieldName],
        },
        collectionName,
        id
      );
      console.log("Данные успешно обновлены");
    } else {
      await updateDocumentById(
        {
          [fieldName]: formData[fieldName],
        },
        collectionName,
        id
      );
      setIsLoading(false);
      console.log("Данные успешно добавлены");
    }
    setIsPending(false);
    setIsChanged(false);
    setIsLoading(false);
  };

  // Открыть всплывающее окно
  const handleOpenPopup = (popupName) => {
    setActivePopup(popupName);
  };

  // Закрыть всплывающее окно
  const handleClosePopup = () => {
    setActivePopup(null);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "0 16px",
          boxSizing: "border-box",
        }}
      >
        <div
          style={{
            backgroundColor: "var(--color-white)",
            boxSizing: "border-box",
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              height: "48px",
              padding: "0 16px",
              ...(formData[fieldName].length > 0 && {
                borderBottom: "1px var(--color-gray-200) solid",
              }),
            }}
          >
            <p
              style={{
                textTransform: "uppercase",
              }}
              className="p-light"
            >
              {title}
            </p>

            <button onClick={() => handleOpenPopup(`popu_${fieldName}`)}>
              {formData[fieldName].length > 0 ? "Edit" : "Add"}
            </button>
          </div>

          {(formData[fieldName].length > 0 ? formData[fieldName] : []).map(
            (data, index) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  boxSizing: "border-box",
                  minHeight: "48px",
                  padding: "16px 16px",
                  lineHeight: "20px",
                  ...(index === data.length - 1
                    ? {
                        borderBottom: "none",
                      }
                    : {
                        borderBottom: "1px var(--color-gray-200) solid",
                      }),
                }}
                key={index}
                onClick={() => handleOpenPopup(`popu_${fieldName}`)}
              >
                <div className="inform-box-content-left">
                  <DateFormat timestamp={data} />

                  {/* <DateRead
                  value={`${data ? Date(data) : 'Добавить данные'}`}
                /> */}
                </div>
                <div className="inform-box-content-right">
                  {quantity === 1 ? (
                    ""
                  ) : (
                    <div className="inform-box-content-right-number-params">
                      {index + 1}
                    </div>
                  )}
                </div>
              </div>
            )
          )}
        </div>
        {description && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "8px 16px",
            }}
          >
            <p className="p-light">{description}</p>
          </div>
        )}
      </div>

      {activePopup !== null &&
        (isLoading ? (
          <Loading title="Ожидайте завершения" />
        ) : (
          <Popup
            isOpen={true}
            onClose={handleClosePopup}
            title={title}
            leftMenu={
              <>
                {!isPending &&
                quantity >= 1 &&
                formData[fieldName].length < quantity ? (
                  <Button type="success" onClick={handleAddItemData}>
                    Add
                  </Button>
                ) : (
                  <Button type="disable">Add</Button>
                )}
              </>
            }
            rightMenu={
              <>
                {isChanged === true &&
                formData[fieldName].every((value) => value !== "") ? (
                  <Button
                    onClick={handleSubmit}
                    disabled={isLoading}
                    children={isLoading ? "...Loading" : "Save"}
                  />
                ) : (
                  <Button type={"disable"} children={"Save"} />
                )}
                {isAnyMarkedForDelete() ? (
                  <Button
                    type="alarm"
                    onClick={handleDeleteMarked}
                    disabled={isDeleteLoading}
                  >
                    {isDeleteLoading ? "...Loading" : "Delete"}
                  </Button>
                ) : (
                  <Button type="disable">Delete</Button>
                )}
              </>
            }
          >
            {Array.isArray(formData[fieldName]) &&
              formData[fieldName].map((data, index) => (
                <div key={index} className="inform-box-inputItem">
                  {quantity > 1 && (
                    <Checkbox
                      checked={!!markedForDelete[index]}
                      onChange={(e) =>
                        handleMarkForDelete(index, e.target.checked)
                      }
                    />
                  )}

                  <DateWrite
                    // label={`Название байка ${index + 1 === 1 ? ('') : (index + 1)}`}
                    type="text"
                    value={data}
                    onChange={(e) => handleChange(e, index)}
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                  />
                </div>
              ))}
          </Popup>
        ))}
    </>
  );
};
