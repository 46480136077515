import React from 'react';
import DateFormat from '../../UniversalWidgets/supportFunctions/functionDateFormat';
import { Link } from 'react-router-dom';

const NewsItem = ({ id, date, title, description, file_photo }) => {
    return (
        <div style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            gap: '24px',
            backgroundColor: 'var(--color-white)',
            minHeight: '350px',
            boxSizing: 'border-box',
            borderRadius: '24px',
            overflow: 'hidden',

        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        width: '100%',
                        aspectRatio: '16/9',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        boxSizing: 'border-box',
                        padding: '16px',
                        backgroundImage: `url(${file_photo.map((item) => item.priority ? item.url : item.url)})`,
                        backgroundColor: 'var(--color-gray-200)'
                    }}>

                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            padding: '8px 16px',
                            borderRadius: '80px',
                            backdropFilter: 'blur(10px)'
                        }}>
                            <p className='p-mini'
                                style={{
                                    color: 'var(--color-white)',
                                    opacity: '0.7'
                                }}>
                                <DateFormat timestamp={date && date[0]} />
                            </p>
                        </div>

                    </div>
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '24px',
                    padding: '32px'
                }}>
                    <h3>
                        {title}
                    </h3>
                    <p style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxHeight: 'calc(5 * 1.3em)',
                        lineHeight: '1.3em'
                    }}>
                        {description}
                    </p>
                    <Link to={`/newsDatail/${title[0].replace(/ /g, "-")}/${id}`}>
                        <button style={{
                            background: 'var(--gradient-primary)',
                            color: 'var(--color-white)',
                            height: '32px',
                            border: '0',
                            borderRadius: '80px',
                            padding: '0 16px'
                        }}>
                            Подробнее
                        </button>
                    </Link>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '16px',
                        alignItems: 'center'
                    }}>

                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'var(--color-gray-100)',
                            width: '48px',
                            borderRadius: '80px',
                            aspectRatio: '1/1'
                        }}>
                            <span
                                className="material-symbols-outlined"
                                style={{ fontSize: '2rem' }}
                            >
                                face
                            </span>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                            <p>
                                Надежда Парполита
                            </p>
                            <p className='p-mini'>
                                BikeForYouTrip
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewsItem;