import { createSlice } from "@reduxjs/toolkit";
import { fetchAllDataThunk } from "./thunk/fetchAllDataThunk";
import { filterBikesThunk } from "./searchBar/filterBikesThunk";
import { filterParamsThunk } from "./searchBar/filterParamsThunk";
import { filterParamsPriceBikes } from "./searchBar/filterParamsPriceBikes";
import { filterParamsMapRentalPoint } from "./searchBar/filterParamsMapRentalPoint";
import { fetchUserData } from "./thunk/fetchUserdata";

const stateFrontGUISlice = createSlice({
  name: "stateFrontGUISlice",
  initialState: {
    menuItems: [
      {
        title: "Home",
        slug: "/",
        icon: "fa-regular fa-circle-user",
        allowedRoles: ["rental", "client", "admin"],
      },
      {
        title: "Catalog",
        slug: "/catalog",
        icon: "fa-regular fa-circle-user",
        allowedRoles: ["rental", "client", "admin"],
      },
      {
        title: "About",
        slug: "/about",
        icon: "fa-regular fa-circle-user",
        allowedRoles: ["rental", "client", "admin"],
      },
      {
        title: "Inform",
        slug: "/inform",
        icon: "fa-regular fa-circle-user",
        allowedRoles: ["rental", "client", "admin"],
      },
      {
        title: "Support",
        slug: "/support",
        icon: "fa-regular fa-circle-user",
        allowedRoles: ["rental", "client", "admin"],
      },
      {
        title: "Offer",
        slug: "/offer",
        icon: "fa-regular fa-circle-user",
        allowedRoles: ["rental", "client", "admin"],
      },
      {
        title: "Client",
        slug: "/client",
        icon: "location_on",
        allowedRoles: ["rental", "client", "admin"],
      },
    ],

    widgetAddCommentsState: false,
    currentBike: {},
    isLoadingDefaultDateInterval: true,
    // defaultDateInterval: [],
    currentDateInterval: [],
    erroDefaultDateInterval: "",
    quantityBike: 1,
    defaultCountry: {},
    defaultCity: {},
    countryIdFilter: {},
    currentCountry: {},
    cityIdFilter: [],
    currentCity: {},
    currentMinMaxPrice: [],
    brandIdFilter: [],
    currentBrand: [],
    currentCategory: [],
    categoryIdFilter: [],
    currentRentalPoint: {},
    rentalPointIdFilter: [],
    currentOrderBike: {},
    isLoading: true,
    error: "",
    storiesData: [],
    newsData: [],
    bikeData: [],
    brandData: [],
    categoryData: [],
    cityData: [],
    countryData: [],
    currencyData: [],
    commentsData: [],
    rentalPointsData: [],
    fullDataBike: [],
    fullDataRentalPoints: [],
    fullDataLocation: [],
    // Групировка ценового градиента по странам
    statistiscBikesByCountry: [],
    test: [],
    selectedAdditioanlServices: [],

    // filterParamsThunk
    brandFiltersParams: [],
    categoryesFiltersParams: [],
    isLoadingFilterParamsThunk: true,
    errorFilterParamsThunk: "",
    // filterParamsThunk end

    // filterParamsMapRentalPoint
    currentRentalPointsList: [],
    isLoadingCurrentRentalPointsList: false,
    errorCurrentRentalPointsList: "",
    // filterParamsMapRentalPoint end

    // filterParamsPriceBikes
    rangePriceFilter: [],
    minMaxPrice: [],
    isLoadingFilterParamsPriceBikes: true,
    errorFilterParamsPriceBikes: "",
    // filterParamsPriceBikes end

    // filterBikesThunk
    bikesFiltredList: [],
    // filterBikesThunk end

    // fetchAllDataThunk
    // fetchAllDataThunk end

    // fetchUserData
    userData: {},
    ordersData: [],
    isLoadingOrdersData: true,
    errorOrdersData: "",
    currentOrderData: {},
    // fetchUserData end
  },

  reducers: {
    setCurrentOrderData: (state, action) => {
      state.currentOrderData = action.payload;
    },
    setWidgetAddCommentsState: (state, action) => {
      state.widgetAddCommentsState = action.payload;
    },
    setQuantityBike: (state, action) => {
      state.quantityBike = action.payload;
    },
    setDateIntervalFilter: (state, action) => {
      state.dateIntervalFilter = action.payload;
    },
    setCurrentDateInterval: (state, action) => {
      state.currentDateInterval = action.payload;
    },
    setCurrentCity: (state, action) => {
      state.currentCity = action.payload;
    },
    setCurrentCountry: (state, action) => {
      state.currentCountry = action.payload;
    },
    setCurrentBrand: (state, action) => {
      state.currentBrand = action.payload;
    },
    setCurrentCategory: (state, action) => {
      state.currentCategory = action.payload;
    },
    setCategoryIdFilter: (state, action) => {
      state.categoryIdFilter = action.payload;
    },
    setBrandIdFilter: (state, action) => {
      state.brandIdFilter = action.payload;
    },
    setCityIdFilter: (state, action) => {
      state.cityIdFilter = action.payload;
    },
    setCurrentMinMaxPrice: (state, action) => {
      state.currentMinMaxPrice = action.payload;
    },
    setRentalPointIdFilter: (state, action) => {
      state.rentalPointIdFilter = action.payload;
    },
    setCurrentBike: (state, action) => {
      state.currentBike = action.payload;
    },
    setCurrentRentalPoint: (state, action) => {
      state.currentRentalPoint = action.payload;
    },
    setSelectedAdditioanlServices: (state, action) => {
      state.selectedAdditioanlServices = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      // fetchUserData
      .addCase(fetchUserData.pending, (state) => {
        state.isLoadingOrdersData = true;
        state.errorOrdersData = null;
      })

      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.userData = action.payload.userData;
        state.ordersData = action.payload.ordersData;
        state.isLoadingOrdersData = true;
      })

      .addCase(fetchUserData.rejected, (state, action) => {
        state.isLoadingOrdersData = false;
        state.errorOrdersData = action.error.message;
      })
      // fetchUserData end

      // filterParamsMapRentalPoint
      .addCase(filterParamsMapRentalPoint.pending, (state) => {
        state.isLoadingCurrentRentalPointsList = true;
        state.errorCurrentRentalPointsList = null;
      })
      .addCase(filterParamsMapRentalPoint.fulfilled, (state, action) => {
        state.currentRentalPointsList = action.payload;
        state.isLoadingCurrentRentalPointsList = true;
      })

      .addCase(filterParamsMapRentalPoint.rejected, (state, action) => {
        state.isLoadingCurrentRentalPointsList = false;
        state.errorCurrentRentalPointsList = action.error.message;
      })
      // filterParamsMapRentalPoint end

      // filterParamsPriceBikes
      .addCase(filterParamsPriceBikes.pending, (state) => {
        state.isLoadingFilterParamsPriceBikes = true;
        state.errorFilterParamsPriceBikes = null;
      })
      .addCase(filterParamsPriceBikes.fulfilled, (state, action) => {
        state.rangePriceFilter = action.payload.rangePriceFilter;
        state.minMaxPrice = action.payload.minMaxPrice;
        state.isLoadingFilterParamsPriceBikes = false;
      })

      .addCase(filterParamsPriceBikes.rejected, (state, action) => {
        state.isLoadingFilterParamsPriceBikes = false;
        state.errorFilterParamsPriceBikes = action.error.message;
      })
      // filterParamsPriceBikes end

      // filterParamsThunk
      .addCase(filterParamsThunk.pending, (state) => {
        state.isLoadingFilterParamsThunk = true;
        state.errorFilterParamsThunk = null;
      })
      .addCase(filterParamsThunk.fulfilled, (state, action) => {
        state.brandFiltersParams = action.payload.brandsInBikes;
        state.categoryesFiltersParams = action.payload.categoriesInBikes;
        state.isLoadingFilterParamsThunk = false;
      })

      .addCase(filterParamsThunk.rejected, (state, action) => {
        state.isLoadingFilterParamsThunk = false;
        state.errorFilterParamsThunk = action.error.message;
      })

      // filterParamsThunk end
      // filterBikesThunk
      .addCase(filterBikesThunk.pending, (state) => {
        // state.isLoading = true;
        // state.error = null;
      })
      .addCase(filterBikesThunk.fulfilled, (state, action) => {
        state.bikesFiltredList = action.payload;
        // state.isLoading = false;
      })

      .addCase(filterBikesThunk.rejected, (state, action) => {
        // state.isLoading = false;
        // state.error = action.error.message;
      })
      // filterBikesThunk end
      // fetchAllDataThunk
      .addCase(fetchAllDataThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchAllDataThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        // Здесь вы обновляете состояние данными, полученными из fetchAllData
        const {
          comments,
          news,
          stories,
          bikes,
          brands,
          categories,
          cities,
          countries,
          currencies,
          rentalPoints,
          defaultCountry,
          defaultCity,
          fullDataBike,
          fullDataRentalPoints,
          fullDataLocation,
          statistiscBikesByCountry,
        } = action.payload;

        state.commentsData = comments;
        state.newsData = news;
        state.storiesData = stories;
        state.bikeData = bikes;
        state.brandData = brands;
        state.categoryData = categories;
        state.cityData = cities;
        state.countryData = countries;
        state.currencyData = currencies;
        state.rentalPointsData = rentalPoints;
        state.fullDataBike = fullDataBike;
        state.fullDataLocation = fullDataLocation;
        state.defaultCountry = defaultCountry;
        state.fullDataRentalPoints = fullDataRentalPoints;
        // state.currentCountry = defaultCountry;
        state.defaultCity = defaultCity;
        // state.currentCity = defaultCity;
        state.statistiscBikesByCountry = statistiscBikesByCountry;
      })
      .addCase(fetchAllDataThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
    // fetchAllDataThunk end
    // defaultDateInterval
    // .addCase(defaultDateInterval.pending, (state) => {
    //   state.isLoadingDefaultDateInterval = true;
    //   state.erroDefaultDateInterval = null;
    //   // Устанавливаем состояние загрузки, если необходимо
    // })
    // .addCase(defaultDateInterval.fulfilled, (state, action) => {
    //   state.defaultDateInterval = action.payload;
    //   state.isLoadingDefaultDateInterval = false;
    // })
    // .addCase(defaultDateInterval.rejected, (state, action) => {
    //   state.isLoadingDefaultDateInterval = false;
    //   state.erroDefaultDateInterval = action.error.message;
    // });
    // defaultDateInterval end
  },
});

export const {
  setCurrentOrderData,
  setDateIntervalFilter,
  setCurrentCity,
  setCurrentCountry,
  setQuantityBike,
  setCurrentDateInterval,
  setBrandIdFilter,
  setCategoryIdFilter,
  setCurrentBrand,
  setCurrentCategory,
  setCityIdFilter,
  setCurrentMinMaxPrice,
  setRentalPointIdFilter,
  setCurrentRentalPointsList,
  setCurrentBike,
  setCurrentRentalPoint,
  setSelectedAdditioanlServices,
} = stateFrontGUISlice.actions;

export default stateFrontGUISlice.reducer;
