import { createAsyncThunk } from "@reduxjs/toolkit";

export const filterParamsPriceBikes = createAsyncThunk(
  "filterParamsPriceBikes/front",
  async (_, { getState }) => {
    const { currentDateInterval, bikesFiltredList } =
      getState().stateFrontGUISlice;

    // Определение месяцев из currentDateInterval
    const startMonth = getMonthFromTimestamp(currentDateInterval[0]);
    const endMonth = getMonthFromTimestamp(currentDateInterval[1]);

    // Сбор уникальных цен
    const uniquePrices = collectUniquePrices(
      bikesFiltredList,
      startMonth,
      endMonth
    );
    console.log(
      "filterParamsPriceBikes-uniquePrices:",
      uniquePrices,
      bikesFiltredList,
      currentDateInterval
    );

    // Make sure to return the uniquePrices here
    return uniquePrices;
  }
);

const collectUniquePrices = (bikesFiltredList, startMonth, endMonth) => {
  // Создаем массив для всех цен
  const prices = [];

  // Проходим по каждому байку и собираем цены
  bikesFiltredList.forEach((bike) => {
    bike.rate.forEach((rate) => {
      if (
        rate.month >= startMonth &&
        rate.month <= endMonth &&
        rate.price > 0
      ) {
        prices.push(rate.price);
      }
    });
  });

  // Определяем минимальное и максимальное значения из массива цен
  const minPrice = prices.length ? Math.min(...prices) : undefined;
  const maxPrice = prices.length ? Math.max(...prices) : undefined;
  const minMaxPrice = [minPrice, maxPrice];

  // Превращаем массив цен в Set для получения уникальных значений, затем обратно в массив
  const rangePriceFilter = Array.from(new Set(prices));

  return { rangePriceFilter, minMaxPrice };
};

// Вспомогательные функции
function getMonthFromTimestamp(timestamp) {
  const date = new Date(timestamp);
  return date.getMonth() + 1;
}
