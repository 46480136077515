import React from "react";
import WigetListSinglePhoto from "./WigetListSinglePhoto";
import { Link } from "react-router-dom";
import { calculatePricePercentage } from "../../../functions/calculatePricePercentage";
import { useSelector } from "react-redux";
import Preloader from "../../UIElements/Preloader";
import { v4 as uuidv4 } from "uuid";

const bikePrice = (rate) => {
  const pricePercentage = calculatePricePercentage(rate);
  const currentMonth = new Date().getMonth();

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        alignItems: "end",
        gap: "4px",
        flex: "1",
        width: "100%",
        height: "100%",
      }}
    >
      {pricePercentage?.map((item, index) => (
        <div
          key={uuidv4()}
          style={{
            width: "100%",
          }}
        >
          <div
            title={`${item.monthName} — ${item.price.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })} in day`}
            style={{
              display: "flex",
              height: `calc(${item.pricePercentage}px)`,
              minHeight: "24px",
              // minWidth: currentMonth === item.month - 1 && '50px',
              backgroundColor:
                currentMonth === item.month - 1
                  ? "var(--color-success)"
                  : "var(--color-gray-100)",
              justifyContent:
                currentMonth === item.month - 1 ? "center" : "flex-start",
              alignItems: "end",
              borderRadius: "4px",
              padding: "4px",
              boxSizing: "border-box",
              width: "100%",
            }}
          >
            {item.price !== 0 && (
              <p
                className="p-mini"
                style={{
                  color: "var(--color-white)",
                }}
              >
                {currentMonth === item.month - 1 &&
                  item.price.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
              </p>
            )}
          </div>

          <p
            className="p-mini"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "24px",
            }}
          >
            {currentMonth === item.month - 1 && item.monthName.substring(0, 3)}
          </p>
        </div>
      ))}
    </div>
  );
};

const WigetBikesList = ({ bikes, title }) => {
  // состояние загрузки данных
  const isLoading = useSelector((state) => state.stateFrontGUISlice.isLoading);

  return isLoading ? (
    <>
      <Preloader />
    </>
  ) : (
    <>
      <div
        style={{
          padding: "24px",
          width: "80%",
        }}
      >
        <h5>Assortment of bikes at the rental point</h5>
      </div>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          boxSizing: "border-box",
          width: "100%",
          gap: "32px",
        }}
      >
        {bikes &&
          bikes.map((item) => {
            return (
              <div
                key={uuidv4()}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: "1",
                  gridColumn: "span 1",
                  backgroundColor: "var(--color-white)",
                  width: "100%",
                  height: "100%",
                  borderRadius: "24px",
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    // backgroundColor: "var(--color-white)",
                    // borderRadius: "24px",
                    // overflow: "hidden",
                    width: "100%",
                    height: "100%",
                    // backgroundColor: "yellowgreen",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <WigetListSinglePhoto photos={item.file_bikePhoto} />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "16px",
                      // backgroundColor: "var(--color-white)",
                      // borderRadius: "24px",
                      // overflow: "hidden",
                      padding: "24px",
                      width: "100%",
                      boxSizing: "border-box",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        height: "120px",
                      }}
                    >
                      {bikePrice(item.rate)}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <div className="itemTypeBIke">
                          <p className="p-mini">{item?.category?.title}</p>
                        </div>
                        <div className="itemTypeBIke">
                          <p className="p-mini">{item?.brand?.title}</p>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      <h5>{item.title[0]}</h5>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      <p
                        className="p-light"
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          height: "calc(1.4 * 5em)",
                          lineHeight: "1.4em",
                        }}
                      >
                        {item.description}
                      </p>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      {item.title[0] && (
                        <Link
                          to={`/catalog/productCard/${
                            item.title && item.title[0].replace(/ /g, "-")
                          }/${item.id}`}
                        >
                          <button
                            style={{
                              background: "var(--gradient-primary)",
                              color: "var(--color-white)",
                              height: "32px",
                              border: "0",
                              borderRadius: "80px",
                              padding: "0 16px",
                            }}
                          >
                            Подробнее
                          </button>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default WigetBikesList;
