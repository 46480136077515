import React, { useEffect, useState } from "react";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import { useDispatch, useSelector } from "react-redux";
import WidgetOrderBikeInfo from "./WidgetOrderBikeInfo";
import Button from "../WidgetsProfile/Buttons/Button";
import DropdownList from "../WidgetsProfile/DropdownList/DropdownList";
import WidgetOrderDateInterl from "./WidgetOrderDateInterl";
import WidgetOrderAdditionalServicesList from "./WidgetOrderAdditionalServicesList";
import WidgetOrderAditionalServicesTotal from "./WidgetOrderAditionalServicesTotal";
import Counter from "../WidgetsProfile/Counter/Counter";
import {
  setOrderStatus,
  setOrderTotals,
  setQuantityBikes,
} from "../../../../../reducersToolkit/orderSliceV2";
import { createOrderThunkV2 } from "../../../../../reducersToolkit/thunk/orders/createOrderThunkV2";
import ButtonsBar from "../WidgetsProfile/ButtonsBar/ButtonsBar";

const WindowOrderDetailModeration = ({ mode, onClose, closeParent }) => {
  const dispatch = useDispatch();

  const [metricsBike, setMetricsBike] = useState(1);
  const [countryOptions, setCountryOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);

  const [city, setCity] = useState([]);
  const [country, setCountry] = useState([]);

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [countryId, setCountryId] = useState(null);
  const [cityId, setCityId] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [additionalServices, setAdditionalServices] = useState([]);
  const [quantityBike, setQuantityBike] = useState(1);
  const [totals, setTotals] = useState({});

  const currentUser = useSelector((state) => state.userSliceV2.currentUser);

  const { countries, cities, brands, categories } = useSelector(
    (state) => state.directorySliceV2
  );

  const { newOrder, selectedOrder } = useSelector(
    (state) => state.orderSliceV2
  );

  // const handleCountrySelect = (data) => {
  //   setSelectedCountry(data);
  // };

  // const handleCitySelect = (data) => {
  //   setSelectedCity(data);
  // };

  useEffect(() => {
    if (newOrder.orderBike?.directory?.brandId && brands.length > 0) {
      const foundBrand = brands.find(
        (brand) => brand.id === newOrder.orderBike.directory.brandId
      );
      setSelectedBrand(foundBrand || null);
    }
  }, [newOrder, brands]);

  useEffect(() => {
    if (newOrder.orderBike?.directory?.categoryId && categories.length > 0) {
      const foundCategory = categories.find(
        (category) => category.id === newOrder.orderBike.directory.categoryId
      );
      setSelectedCategory(foundCategory || null);
    }
  }, [newOrder, categories]);

  useEffect(() => {
    const formattedCountries = countries.map((country) => ({
      id: country.id,
      title: country?.title?.titleOriginal,
    }));

    setCountryOptions(formattedCountries);

    const formattedCities = cities.map((city) => ({
      id: city.id,
      title: city?.title?.titleOriginal,
    }));

    setCityOptions(formattedCities);

    const initialCountry = formattedCountries.find(
      (country) => country.id === countryId
    );

    const initialCity = formattedCities.find((city) => city.id === cityId);

    setSelectedCountry(initialCountry || null);
    setSelectedCity(initialCity || null);
  }, [countries, cities, countryId, cityId]);

  useEffect(() => {
    setCountryId(newOrder?.orderRentalPoint?.location?.countryId || null);
    setCityId(newOrder?.orderRentalPoint?.location?.cityId || null);
    setMetricsBike(newOrder?.orderBike?.metricsBike?.quantityBikes || 1);
    setAdditionalServices(
      newOrder?.orderRentalPoint?.additionalServices?.services || []
    );
    setCity(newOrder?.orderBike?.rentalPoint?.location?.city || {});
    setCountry(newOrder?.orderBike?.rentalPoint?.location?.country || null);
  }, [newOrder]);

  useEffect(() => {
    dispatch(setQuantityBikes({ mode: "new", quantityBike: quantityBike }));
  }, [dispatch, quantityBike]);

  useEffect(() => {
    dispatch(setOrderTotals({ mode: "new", totals: totals }));
  }, [dispatch, mode, totals]);

  const createOrder = () => {
    dispatch(
      createOrderThunkV2({ orderData: newOrder, userId: currentUser.id })
    );
    closeParent();
  };

  const close = () => {
    onClose();
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "8px",
          marginBottom: "72px",
        }}
      >
        <ContentBox position="start">
          <WidgetOrderBikeInfo
            // order={newOrder}
            selectedBike={newOrder?.orderBike}
            selectedBrand={selectedBrand}
            selectedCategory={selectedCategory}
            orderedAditionalServices={additionalServices}
            setTotals={setTotals}
            metricsBike={metricsBike}
            quantityBike={quantityBike}
            onChange={setQuantityBike}
            order={newOrder}
          />
        </ContentBox>

        {additionalServices.length > 0 && (
          <ContentBox>
            <WidgetOrderAdditionalServicesList
              mode={mode}
              additionalServices={additionalServices}
            />
          </ContentBox>
        )}
      </div>
      <ButtonsBar>
        <div
          style={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <Button
            type="small"
            color="--color-black"
            label="Cancel"
            onClick={close}
            active={true}
          />

          <Button
            type="small"
            label="Create order"
            onClick={createOrder}
            active={true}
            allert={
              <div
                style={{
                  padding: "24px",
                  boxSizing: "border-box",
                  textAlign: "center",
                }}
              >
                <h1>
                  Are you ready to place your order and send it to the rental
                  point for processing?
                </h1>
              </div>
            }
          />
        </div>
      </ButtonsBar>
    </>
  );
};

export default WindowOrderDetailModeration;
