import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addData,
  updateDocumentFields,
} from "../../../services/firebase/crudFirestore";
import interpretAuthErrorV2 from "../../../functions/interpretAuthErrorV2";

const cleanData = (data) => {
  if (typeof data !== "object" || data === null) {
    return data;
  }

  if (Array.isArray(data)) {
    return data.map(cleanData);
  }

  return Object.entries(data).reduce((acc, [key, value]) => {
    if (value !== undefined) {
      acc[key] = cleanData(value);
    }
    return acc;
  }, {});
};

export const createOrderThunkV2 = createAsyncThunk(
  "orders/createOrderThunkV2",
  async ({ orderData, userId }, { rejectWithValue }) => {
    try {
      // Очистка данных перед добавлением в Firestore
      const cleanedOrderData = cleanData(orderData);

      // Добавляем новый заказ в коллекцию orders
      const newOrder = await addData("orders", cleanedOrderData);

      // Получаем ID нового заказа
      const newOrderId = newOrder.id;

      // Обновляем пользователя, добавляя новый orderId в поле ordersIds
      await updateDocumentFields("users", userId, {
        ordersIds: [newOrderId], // Передаем новый ID заказа в виде массива
      });

      return { newOrder, userId, newOrderId };
    } catch (error) {
      // Обрабатываем возможные ошибки
      const errorMessage = interpretAuthErrorV2(error.code);
      return rejectWithValue({ errorMessage });
    }
  }
);

// import { createAsyncThunk } from "@reduxjs/toolkit";
// import {
//   addData,
//   updateDocumentFields,
// } from "../../../services/firebase/crudFirestore";
// import interpretAuthErrorV2 from "../../../functions/interpretAuthErrorV2";

// export const createOrderThunkV2 = createAsyncThunk(
//   "orders/createOrderThunkV2",
//   async ({ orderData, userId }, { rejectWithValue }) => {
//     try {
//       console.log(
//         "WindowOrderDetailModeration orderData 100:",
//         orderData,
//         "userId:",
//         userId
//       );

//       // Добавляем новый заказ в коллекцию orders
//       const newOrder = await addData("orders", orderData);

//       console.log(
//         "WindowOrderDetailModeration orderData newOrder.id:",
//         newOrder.id
//       );
//       // Получаем ID нового заказа
//       const newOrderId = newOrder.id;

//       // Обновляем пользователя, добавляя новый orderId в поле ordersIds
//       await updateDocumentFields("users", userId, {
//         ordersIds: [newOrderId], // Передаем новый ID заказа в виде массива
//       });

//       return { newOrder, userId, newOrderId };
//     } catch (error) {
//       // Обрабатываем возможные ошибки
//       console.log("WindowOrderDetailModeration orderData 100:", error);
//       const errorMessage = interpretAuthErrorV2(error.code);
//       return rejectWithValue({ errorMessage });
//     }
//   }
// );
