import { createAsyncThunk } from "@reduxjs/toolkit";
import { updateDocument } from "../../../services/firebase/crudFirestore";
import interpretAuthErrorV2 from "../../../functions/interpretAuthErrorV2";

export const updateUserModerationThunkV2 = createAsyncThunk(
  "reducersToolkit/updateUserModerationThunkV2",
  async ({ collectionName, id, newData }, { rejectWithValue }) => {
    try {
      const updatedData = await updateDocument(collectionName, id, newData);

      return updatedData;
    } catch (error) {
      // Обрабатываем возможные ошибки
      const errorMessage = interpretAuthErrorV2(error.code);
      return rejectWithValue({ errorMessage });
    }
  }
);
