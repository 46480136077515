import React, { useEffect, useState } from "react";
import SearchBars from "../../../../../UIComponents/bars/searchBars/SearchBars";
import WigetButtonAdd from "../../../../../UniversalWidgets/WigetButtonAdd";
import { useSelector } from "react-redux";
import { useSubscribeData } from "../../../../../../services/firebase/crudFirebaseStorage";
import { useDispatch } from "react-redux";
import ImageWithLoader from "../../../../../UIComponents/bars/gallery/ImageWithLoader";
import WigetRateBike from "./WigetRateBike";
import SwitcherOnOff from "../../../../../UIElements/Switcher";
import { setCurrentBike } from "../../../../../../slice/stateGUISlice";
const WigetBikesList = () => {
  const dispatch = useDispatch();

  const [filteredBikesData, setFilteredBikesData] = useState([]);

  const { data: bikesData, isLoading: loadingBikesData } =
    useSubscribeData("bikes");

  const brandData = useSelector((state) => state.brand.data);
  const categoryData = useSelector((state) => state.category.data);

  const currentRentalPoint = useSelector(
    (state) => state.stateGUISlice.currentRentalPoint || null
  );

  const currentRentalPointTitle = useSelector(
    (state) => state.stateGUISlice.currentRentalPoint.title || null
  );

  const currentRentalPointId = useSelector(
    (state) => state.stateGUISlice.currentRentalPoint?.id || null
  );

  const currentBikeId = useSelector(
    (state) => state.stateGUISlice.currentBike?.id || null
  );

  const currentBike = useSelector(
    (state) => state.stateGUISlice.currentBike || null
  );

  useEffect(() => {
    if (
      !loadingBikesData &&
      currentRentalPoint &&
      currentRentalPoint.bikes_id
    ) {
      const filteredBikes = bikesData.filter((bike) =>
        currentRentalPoint.bikes_id.includes(bike.id)
      );

      // Если currentBike не установлен или не является частью отфильтрованных велосипедов, установите первый в качестве текущего велосипеда
      if (
        !currentBike ||
        !filteredBikes.some((bike) => bike.id === currentBikeId)
      ) {
        dispatch(setCurrentBike(filteredBikes[0]));
        // setIdcurrentBike(filteredBikes[0]?.id || null);
      }

      setFilteredBikesData(filteredBikes);
    }
  }, [
    bikesData,
    loadingBikesData,
    currentRentalPoint,
    currentBike,
    currentBikeId,
    dispatch,
  ]);

  const BrendBike = ({ bike, typeContent }) => {
    if (bike && bike.brand_id) {
      const filteredBrandData = brandData.filter((brand) =>
        bike.brand_id.includes(brand.id)
      );

      if (typeContent === "img") {
        return (
          <ImageWithLoader
            aspectRatio="1/1"
            url={filteredBrandData[0]?.file_icon}
            alt={"PreviewLogo"}
          />
        );
      } else {
        if (filteredBrandData.length > 0) {
          return <div>{filteredBrandData[0].title[0]}</div>;
        } else {
          return <div>No matching brand found.</div>;
        }
      }
    }

    return null; // Если нет данных, можно вернуть null или другое значение по умолчанию
  };

  const CategoryBike = ({ bike }) => {
    if (bike && bike.categoryes_id) {
      const filteredCategoryData = categoryData.filter((category) =>
        bike.categoryes_id.includes(category.id)
      );

      if (filteredCategoryData.length > 0) {
        return <div>{filteredCategoryData[0].title[0]}</div>;
      } else {
        return <div>No matching category found.</div>;
      }
    }

    return null; // Если нет данных, можно вернуть null или другое значение по умолчанию
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "space-between",
        // height: 'calc(100vh - 56px)',
        height: "100%",
        width: "100%",
        boxSizing: "border-box",
        backgroundColor: "var(--color-gray-100)",
        borderRadius: "0 8px 8px 0",
        overflow: "hidden",
      }}
    >
      <SearchBars
        title={`List of bikes: ${
          currentRentalPointTitle
            ? currentRentalPointTitle
            : "Rental point without name"
        }`}
        iconLeft={
          <div />
          // <Icon44
          //     click={() => dispatch(setPrivateSideBar(true))}
          //     icon={
          //         <span className="material-symbols-outlined">
          //             dock_to_right
          //         </span>
          //     }
          // />
        }
        iconRight={
          <WigetButtonAdd
            nameButton="Новый байк"
            collectionName="bikes"
            parentCollectionName="rentalPoints"
            parentDocumentId={currentRentalPointId}
            icon={<span className="material-symbols-outlined">add_circle</span>}
          />
        }
      />

      <div
        className="scrollBox"
        style={{
          height: "100%",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(600px, 1fr))",
            gap: "16px",
            padding: "16px",
            backgroundColor: "var(--color-gray-100)",
          }}
        >
          {filteredBikesData && filteredBikesData.length > 0 ? (
            filteredBikesData.map((bike) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: "1",
                  boxSizing: "border-box",
                  borderRadius: "16px",
                  backgroundColor:
                    bike?.id === currentBikeId
                      ? "var(--color-white)"
                      : "var(--color-white)",
                }}
                onClick={() => {
                  dispatch(setCurrentBike(bike));
                }}
                className="itemScroll"
                key={bike?.id}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      gap: "16px",
                      padding: "16px",
                    }}
                  >
                    <div
                      style={{
                        width: "60px",
                        aspectRatio: "1/1",
                        borderRadius: "80px",
                        backgroundColor: "var(--color-gray-100)",
                        overflow: "hidden",
                      }}
                    >
                      <BrendBike typeContent="img" bike={bike} />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        // gap: '2px'
                      }}
                    >
                      <h5>
                        {bike?.title.length > 0
                          ? bike.title
                          : "No matching bike name found"}
                      </h5>
                      <div className="p-light">
                        <BrendBike bike={bike} />
                      </div>
                      <div className="p-light">
                        <CategoryBike bike={bike} />
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      padding: "8px",
                    }}
                  >
                    <div
                      style={{
                        borderRadius: "16px",
                        overflow: "hidden",
                      }}
                    >
                      <ImageWithLoader
                        aspectRatio="16/9"
                        url={bike.file_bikePhoto}
                        alt={"PreviewBike"}
                      />
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                  }}
                >
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "16px",
                        padding: "16px",
                      }}
                    >
                      <WigetRateBike bikeRate={bike.rate} />

                      {bike?.description.length > 0 && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                          }}
                        >
                          <h6>Description</h6>
                          <p
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              WebkitLineClamp: 3,
                              maxWidth: "100%",
                              whiteSpace: "normal",
                              width: "80%",
                            }}
                            className="p-light"
                          >
                            {bike?.description}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "16px",
                        boxSizing: "border-box",
                        borderTop: "1px var(--color-gray-200) solid",
                      }}
                    >
                      <button>Edit</button>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <SwitcherOnOff />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div>В случае отсутствия велосипедов покажем сообщение</div>
          )}
        </div>
      </div>
      <div style={{ height: 0 }} />
    </div>
  );
};

export default WigetBikesList;
