import { createAsyncThunk } from "@reduxjs/toolkit";
import { addData } from "../../../../services/firebase/crudFirestore";

export const addCountryThunk = createAsyncThunk(
  "directory/country/addCountryThunk",
  async (newData, { rejectWithValue }) => {
    console.log("addCountryThunk", newData);

    try {
      const addedCountry = await addData("country", newData);
      return addedCountry;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
