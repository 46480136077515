import React, { useState } from "react";
import SegmentedControls from "../WidgetsProfile/SegmentedControls/SegmentedControls";
import Button from "../WidgetsProfile/Buttons/Button";
import Textarea from "../WidgetsProfile/Textarea/Textarea";
import { useDispatch } from "react-redux";
import { updateRentalPointsModerationThunkV2 } from "../../../../../reducersToolkit/thunk/moderation/updateRentalPointsModerationThunkV2";
import { addRentalPointsModerationThunkV2 } from "../../../../../reducersToolkit/thunk/moderation/addRentalPointsModerationThunkV2";

const WidgetRentalPointModeration = ({
  // selectedRentalPoint,
  currentUser,
  onClose,
  mode,
  currentRentalPoint,
}) => {
  const dispatch = useDispatch();
  const [commentModeration, setCommentModeration] = useState("");
  const [commentBanned, setCommentBanned] = useState("");
  const [rentalPointModeration, setRentalPointModeration] = useState(null);

  const handleUpdateRentalPoint = (status) => {
    const { moderation, ...newRentalPoint } = currentRentalPoint;

    const collectionName = "rentalPoints";
    const newData = {
      ...newRentalPoint,
      rentalPointStatus: {
        currentStatus: status,
        historyChanges: [
          ...(currentRentalPoint.rentalPointStatus?.historyChanges || []),
          {
            userId: currentUser.id,
            timestamp: Date.now().toString(),
            comment: commentModeration,
          },
        ],
      },
    };

    const id = currentRentalPoint.id;
    dispatch(
      updateRentalPointsModerationThunkV2({
        collectionName,
        id,
        newData,
      })
    );
    onClose();
  };

  const handleAddRentalPoint = (status) => {
    const { moderation, ...newRentalPoint } = currentRentalPoint;

    const collectionName = "rentalPoints";
    const newData = {
      ...newRentalPoint,
      moderation: {
        status: status,
        historyChanges: [
          ...(currentRentalPoint.moderation?.historyChanges || []),
          {
            userId: currentUser.id,
            timestamp: Date.now(),
            comment: commentModeration,
            adjustments: {},
          },
        ],
      },
    };

    const userId = currentUser.id;
    dispatch(
      addRentalPointsModerationThunkV2({
        collectionName,
        newData,
        userId,
      })
    );
    onClose();
  };

  return mode === "moderation" ? (
    <>
      <Button
        label="Save changes"
        color="--color-primary"
        active={true}
        onClick={() => handleUpdateRentalPoint("moderation")}
        allert={
          <h1
            style={{
              padding: "24px",
              boxSizing: "border-box",
              textAlign: "center",
            }}
          >
            Please confirm your intention to save changes to the rental point
            card
          </h1>
        }
      />

      <SegmentedControls
        options={
          currentRentalPoint?.rentalPointStatus?.currentStatus === "moderation"
            ? [
                {
                  title: "accept",
                  description:
                    currentRentalPoint?.location?.cityid &&
                    currentRentalPoint?.location?.countryId ? (
                      <span>
                        By confirming the moderation of the rental point, you
                        make it available to all users.
                      </span>
                    ) : (
                      <span>
                        In order to undergo moderation of the rental point,
                        please indicate <a href="#ancor1">country and city</a>
                        finding a rental point.
                      </span>
                    ),
                },
                {
                  title: "reject",
                  description:
                    "By rejecting the moderation, the rental point will not be available to users.",
                },
                {
                  title: "bann",
                  description:
                    "By rejecting the moderation, the rental point will not be available to users.",
                },
              ].filter(Boolean)
            : currentRentalPoint?.rentalPointStatus?.currentStatus ===
              "approved"
            ? [
                {
                  title: "reject",
                  description:
                    "By rejecting the moderation, the rental point will not be available to users.",
                },
                {
                  title: "bann",
                  description:
                    "By rejecting the moderation, the rental point will not be available to users.",
                },
              ].filter(Boolean)
            : currentRentalPoint?.rentalPointStatus?.currentStatus ===
                "banned" &&
              [
                {
                  title: "moderation",
                  description:
                    "By rejecting the moderation, the rental point will not be available to users.",
                },
                {
                  title: "accept",
                  description:
                    currentRentalPoint?.location?.cityid &&
                    currentRentalPoint?.location?.countryId ? (
                      <span>
                        By confirming the moderation of the rental point, you
                        make it available to all users.
                      </span>
                    ) : (
                      <span>
                        In order to undergo moderation of the rental point,
                        please indicate <a href="#ancor1">country and city</a>
                        finding a rental point.
                      </span>
                    ),
                },
              ].filter(Boolean)
        }
        onSelect={
          currentRentalPoint?.rentalPointStatus?.currentStatus === "moderation"
            ? "accept"
            : currentRentalPoint?.rentalPointStatus?.currentStatus ===
              "approved"
            ? "reject"
            : currentRentalPoint?.rentalPointStatus?.currentStatus ===
                "banned" && "moderation"
        }
        // onSelect={
        //   selectedRentalPoint?.moderation?.status === "moderation"
        //     ? "reject"
        //     : selectedRentalPoint?.moderation?.status === "approved"
        //     ? "accept"
        //     : selectedRentalPoint?.moderation?.status === "banned" && "accept"
        // }

        label="Rental points"
        selected={setRentalPointModeration}
      />
      {rentalPointModeration && (
        <>
          {rentalPointModeration === "accept" && (
            <Button
              label={
                currentRentalPoint.rentalPointStatus.currentStatus ===
                "approved"
                  ? "Save"
                  : "Accept"
              }
              color="--color-success"
              active={
                currentRentalPoint.location.cityid &&
                currentRentalPoint.location.countryId
              }
              onClick={() => handleUpdateRentalPoint("approved")}
              allert={
                <div
                  style={{
                    padding: "24px",
                    boxSizing: "border-box",
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "16px",
                  }}
                >
                  <h1>
                    After approval of this rental point, users will be able to
                    fulfill orders.
                  </h1>
                  <p className="p-light" style={{ width: "70%" }}>
                    You can always change the status of the rental point in case
                    of violation of any conditions or customer complaints.
                  </p>
                </div>
              }
            />
          )}
          {rentalPointModeration === "reject" && (
            <>
              <Textarea
                label="Rejection reason"
                maxLength={5000}
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                onChange={(event) => setCommentModeration(event.target.value)}
              />

              <Button
                label="Reject"
                color="--color-warning"
                active={commentModeration}
                onClick={() => handleUpdateRentalPoint("moderation")}
                allert={
                  <div
                    style={{
                      padding: "24px",
                      boxSizing: "border-box",
                      textAlign: "center",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "16px",
                    }}
                  >
                    <h1>
                      Do you really want to reject the successful passage of the
                      mogerazzi rental point with the following motivation?
                    </h1>
                    <p className="p-light" style={{ width: "70%" }}>
                      Motivation: &nbsp;{commentModeration}
                    </p>
                  </div>
                }
              />
            </>
          )}
          {rentalPointModeration === "bann" && (
            <>
              <Textarea
                label="Rejection reason"
                maxLength={5000}
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                onChange={(event) => setCommentBanned(event.target.value)}
              />
              <Button
                label="Bann"
                color="--color-alarm"
                active={commentBanned}
                onClick={() => handleUpdateRentalPoint("banned")}
                allert={
                  <div
                    style={{
                      padding: "24px",
                      boxSizing: "border-box",
                      textAlign: "center",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "16px",
                    }}
                  >
                    <h1>
                      Do you really want to move the rental point to "bann"
                      status?
                    </h1>
                    <p className="p-light" style={{ width: "70%" }}>
                      Motivation: &nbsp;{commentBanned}
                    </p>
                  </div>
                }
              />
            </>
          )}
          {/* {rentalPointModeration === "moderation" && (
            <>
              <Textarea
                label="Rejection reason"
                maxLength={5000}
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                onChange={(event) =>
                  setCommentModerationInBann(event.target.value)
                }
              />
              <Button
                label="Moderation"
                color="--color-success"
                active={commentModerationInBann}
                onClick={() => handleUpdateRentalPoint("moderation")}
                allert={
                  <div
                    style={{
                      padding: "24px",
                      boxSizing: "border-box",
                      textAlign: "center",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "16px",
                    }}
                  >
                    <h1>
                      Do you really want to move the rental point from the
                      "banned" status to the "Moderation" status?
                    </h1>
                    <p className="p-light" style={{ width: "70%" }}>
                      Motivation: &nbsp;{commentModerationInBann}
                    </p>
                  </div>
                }
              />
            </>
          )} */}
        </>
      )}
    </>
  ) : mode === "moderatorAdded" ? (
    <>
      <Button
        label="Create rental point"
        color="--color-primary"
        active={true}
        onClick={() => handleAddRentalPoint("new")}
        allert={<div>Create</div>}
      />
    </>
  ) : (
    <>
      <Button
        label="Banned"
        color="--color-primary"
        active={true}
        onClick={() => handleUpdateRentalPoint("banned")}
        allert={<div>Banned</div>}
      />
    </>
  );
};

export default WidgetRentalPointModeration;
