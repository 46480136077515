import React, { useState } from "react";

export const InputWrite = ({
  label,
  type,
  name,
  value,
  onChange,
  description,
  maxLength,
  title,
  collectionName,
  idDocument,
  fieldName,
  index,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const sanitizedValue = value || "";

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        width: "100%",
      }}
    >
      <div
        className={`input-write-container ${
          isFocused || sanitizedValue ? "input-write-focused" : ""
        } ${!isFocused && sanitizedValue ? "input-write-blurred" : ""}`}
      >
        <label
          htmlFor={name}
          className={`input-write-label ${
            isFocused || sanitizedValue ? "input-write-label-up" : ""
          }`}
        >
          {label}
        </label>

        <input
          type={type}
          id={name}
          name={name}
          className="input-write-input"
          value={sanitizedValue}
          onChange={onChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder=""
        />
      </div>
      <p className="input-clue">{description}</p>
    </div>
  );
};

export const InputRead = ({ label, type, name, value, onClick }) => {
  const sanitizedValue = value || ""; // Добавленная проверка

  return (
    <>
      {/* <div className='wigetItem_header' onClick={onClick}> */}
      {/* <p className="p-light">{label}</p> */}
      <p className="p-primary" onClick={onClick}>
        {sanitizedValue}
      </p>
      {/* </div> */}
    </>
  );
};

export const InputReadV2 = ({ label, type, name, value, onClick }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
      onClick={onClick}
    >
      {value.length > 0
        ? value
        : [].map((item, index) => (
            <div
              style={{
                display: "flex",
                // flexDirection: 'row',
                alignItems: "center",
                height: "48px",
                background: "var(--color-white)",
                borderBottom: "1px var(--color-gray-200) solid",
                width: "100%",
              }}
              key={index}
            >
              <p className="p-primary">{item}</p>
            </div>
          ))}
    </div>
  );
};
