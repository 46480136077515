import React, { useEffect, useState } from "react";
import AddData from "../WidgetsProfile/AddData/AddData";
import WidgetRentalPointsList from "./WidgetRentalPointsList";
import { useDispatch, useSelector } from "react-redux";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import WidgetModerationUserPreview from "./WidgetModerationUserPreview";
import PopupDirrectory from "../WidgetsProfile/Popups/PopupDirrectory";
import WindowRentalPointDetailModeration from "./WindowRentalPointDetailModeration";
import WidgetUserModeration from "./WidgetUserModeration";
import WidgetModeratorMessages from "./WidgetModeratorMessages";

const WindowUserDetailModeration = ({ onClose }) => {
  const dispatch = useDispatch();

  const [user, setUser] = useState({});
  const [rentalPointsList, setRentalPointsList] = useState([]);

  const [
    windowRentalPointDetailModeration,
    setWindowRentalPointDetailModeration,
  ] = useState(false);

  // const { currentUser } = useSelector((state) => state.userSliceV2);

  const { selectedUserRentalPoints, selectedUser, users, isLoadingBikes } =
    useSelector((state) => state.moderationSliceV2);

  useEffect(() => {
    setUser(selectedUser);
  }, [selectedUser]);

  useEffect(() => {
    setRentalPointsList(selectedUserRentalPoints);
  }, [selectedUserRentalPoints]);

  const handleClose = () => {
    setWindowRentalPointDetailModeration(false);
  };

  const handleToggle = () => {
    setWindowRentalPointDetailModeration(true);
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "8px",
        }}
      >
        <ContentBox position="start">
          <WidgetModerationUserPreview user={user} />
        </ContentBox>

        <ContentBox>
          <AddData
            onClick={handleToggle}
            icon={<span className="material-symbols-outlined">add</span>}
            title="Create rental point"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          />
          {rentalPointsList && rentalPointsList.length > 0 && (
            <WidgetRentalPointsList
              mode="moderation"
              rentalPointsList={rentalPointsList}
            />
          )}
        </ContentBox>

        <ContentBox>
          <WidgetModeratorMessages selectedUser={selectedUser} users={users} />
        </ContentBox>

        <ContentBox position="end">
          <WidgetUserModeration
            selectedUser={selectedUser}
            onClose={onClose}
            mode="moderatiom"
          />
        </ContentBox>
      </div>
      <PopupDirrectory
        isOpen={windowRentalPointDetailModeration}
        onClose={handleClose}
        title="Create rental point moderation"
      >
        <WindowRentalPointDetailModeration
          mode="moderatorAdded"
          onClose={handleClose}
        />
      </PopupDirrectory>
    </>
  );
};

export default WindowUserDetailModeration;
