import React from "react";
import Preloader from "../../../../../UIElements/Preloader";

const ContentBox = ({ children, position, isLoading, label, onClick }) => {
  return (
    <div
      className={`block-section ${
        position === "start"
          ? "block-section__start-box"
          : position === "end"
          ? "block-section__end-box"
          : position === "one"
          ? "block-section__one-box"
          : "block-section__medium-box"
      }`}
    >
      <div
        onClick={onClick}
        className="profile-block-section__content-box"
        style={{
          width: "100%",
          ...(onClick && {
            cursor: "pointer",
          }),
        }}
      >
        {isLoading ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
            }}
          >
            <Preloader />
            {label && (
              <p className="p-light" style={{ color: "var(--color-gray-500)" }}>
                {label}
              </p>
            )}
          </div>
        ) : (
          children
        )}
      </div>
    </div>
  );
};

export default ContentBox;
