const objectIsEmpty = (obj) => {
  // Проверяем, является ли rentalPoint объектом
  if (typeof obj !== "object" || obj === null) {
    return true; // Не объект или null, считаем что "пустой"
  }
  // Проверяем, есть ли у объекта rentalPoint собственные свойства
  return Object.keys(obj).length === 0;
};

export default objectIsEmpty;
