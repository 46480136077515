import { useEffect, useState, useRef } from "react";
import { useSubscribeData } from "../../../../../../services/firebase/crudFirebaseStorage";
import ImageWithLoader from "../../../../../UIComponents/bars/gallery/ImageWithLoader";

const OdrderDetailClient = ({ orderDetail, title, description }) => {

    const currentUserPhoto = orderDetail?.user?.currentUserPhoto
    const name = orderDetail?.client?.name
    const email = orderDetail?.client?.email
    const phone = orderDetail?.client?.phone
    const comment = orderDetail?.client?.comment

    const atLeastOneNotEmpty = currentUserPhoto || name || email || phone || comment;

    return (
        <div
            // ref={scrollRef}
            style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '0 16px',
                boxSizing: 'border-box',
            }}>

            <div style={{
                backgroundColor: 'var(--color-white)',
                boxSizing: 'border-box',
                borderRadius: '8px',
                overflow: 'hidden'
            }}>

                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    height: '48px',
                    padding: '0 16px',
                    ...(atLeastOneNotEmpty && {
                        borderBottom: '1px var(--color-gray-200) solid',
                    })

                }}>

                    <p style={{
                        textTransform: 'uppercase',
                    }}
                        className='p-light'
                    >
                        {title}
                    </p>
                    {/* 
                        <button
                            onClick={() => handleOpenPopup(`popu`)}
                        >
                            {items.length > 0 ? 'Edit' : 'Add'}
                        </button> */}

                </div>

                <div style={{
                    // padding: '16px',
                    display: 'flex',
                    flexDirection: 'column',
                }}>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        // gap: '16px',
                    }}>

                        <div style={{
                            height: '64px',
                            width: '64px',
                            aspectRatio: '1/1',
                            overflow: 'hidden',
                            borderRadius: '80px',
                            backgroundColor: 'var(--color-gray-100)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            boxSizing: 'border-box',
                            margin: '16px'
                        }}>
                            <ImageWithLoader
                                aspectRatio='1/1'
                                url={currentUserPhoto}
                                alt={currentUserPhoto}
                            />
                        </div>

                        <div className="private_ordersList_itemPrice private_ordersList_itemPrice_borderBottom">
                            <p>Name</p>
                            <p className="p-light">{name?.replace(/./g, '●')}</p>
                        </div>

                        <div className="private_ordersList_itemPrice private_ordersList_itemPrice_borderBottom">
                            <p>Email</p>
                            <p className="p-light">{email?.replace(/./g, '●')}</p>
                        </div>

                        <div className="private_ordersList_itemPrice private_ordersList_itemPrice_borderBottom">
                            <p>Phone</p>
                            <p className="p-light">{phone?.replace(/./g, '●')}</p>
                        </div>

                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '8px',
                            padding: '8px 16px'
                        }}>
                            <p>
                                Comment
                            </p>
                            <p style={{
                                width: '70%'
                            }}
                                className="p-light">
                                {comment}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {description && (
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    padding: '8px 16px'
                }}>
                    <p className='p-light'>
                        {description}
                    </p>
                </div>
            )}
        </div>
    );
}

export default OdrderDetailClient;
