import { createAsyncThunk } from "@reduxjs/toolkit";
import { updateDocument } from "../../../services/firebase/crudFirestore";
import interpretAuthErrorV2 from "../../../functions/interpretAuthErrorV2";

export const updateBikeThunkV2 = createAsyncThunk(
  "bikes/updateBikeThunkV2",
  async ({ idBike, bikeData }, { rejectWithValue }) => {
    try {
      // Вызываем функцию updateData для обновления данных
      const updatedData = await updateDocument("bikes", idBike, bikeData);
      return { idBike, updatedData };
    } catch (error) {
      // Обрабатываем возможные ошибки
      const errorMessage = interpretAuthErrorV2(error.code);
      return rejectWithValue({ errorMessage });
    }
  }
);
