import { createAsyncThunk } from "@reduxjs/toolkit";
import { updateDocument } from "../../../services/firebase/crudFirestore";

export const updateRentalPointsThunkV2 = createAsyncThunk(
  "reducersToolkit/thunk/rentalPoints/updateRentalPointsThunkV2",
  async ({ collectionName, id, newData }, { rejectWithValue }) => {
    console.log("updateRentalPointsThunkV2", collectionName, id, newData);

    try {
      const updatedData = await updateDocument(collectionName, id, newData);

      return updatedData;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
