import { createAsyncThunk } from "@reduxjs/toolkit";
import interpretAuthErrorV2 from "../../../../functions/interpretAuthErrorV2";
import { removeFilesV4 } from "../../../../services/firebase/crudFirestore";

export const deleteFilesThunkV4 = createAsyncThunk(
  "files/deleteFilesThunkV4",
  async (
    { collectionName, documentId, fieldName, fileIds },

    { rejectWithValue }
  ) => {
    console.log("files/deleteFilesThunkV4", fileIds);
    try {
      const finalFiles = await removeFilesV4(
        collectionName,
        documentId,
        fieldName,
        fileIds
      );
      return { documentId, fieldName, finalFiles }; // Возвращаем обновленные файлы
    } catch (error) {
      const errorMessage = interpretAuthErrorV2(error.code);
      return rejectWithValue(errorMessage);
    }
  }
);
