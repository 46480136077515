import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchDocumentsByIds } from "../../../services/firebase/crudFirestore";
import interpretAuthErrorV2 from "../../../functions/interpretAuthErrorV2";

export const fetchRentalPointsThunk = createAsyncThunk(
  "private/moderation/fetchRentalPointsThunk",

  async (rentalPointsIds, { getState, rejectWithValue }) => {
    try {
      const rentalPoints = await fetchDocumentsByIds(
        "rentalPoints",
        rentalPointsIds
      );
      return { rentalPoints };
    } catch (error) {
      const errorMessage = interpretAuthErrorV2(error.code);
      return rejectWithValue({ errorMessage });
    }
  }
);
