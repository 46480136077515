import React, { useState, useEffect, useRef } from "react";
import "./swither.css";

const Switcher = ({ id, isChecked, onChange, label, description }) => {
  const [internalChecked, setInternalChecked] = useState(isChecked);
  const switcherRef = useRef(null);

  useEffect(() => {
    setInternalChecked(isChecked);
  }, [isChecked]);

  const handleChange = () => {
    const newValue = !internalChecked;
    setInternalChecked(newValue);
    onChange(newValue);
  };

  useEffect(() => {
    if (switcherRef.current) {
      switcherRef.current.classList.remove("no-animation");
      // Force reflow to restart the animation
      void switcherRef.current.offsetWidth;
      switcherRef.current.classList.add("no-animation");
    }
  }, [internalChecked]);

  return (
    <div className="swither__content-box">
      <div
        style={{
          width: "70%",
        }}
      >
        {label && <h6>{label}</h6>}
        {description && <p className="p-light">{description}</p>}
      </div>

      <div>
        <div
          ref={switcherRef}
          id={`switcher-control-${id}`}
          className={`swither__control no-animation ${
            internalChecked ? "swither__control--on" : "swither__control--off"
          }`}
          onClick={handleChange}
        >
          <div
            className={`swither__handle--off ${
              internalChecked && "swither__handle--on"
            }`}
          />
        </div>
      </div>
    </div>
  );
};

export default Switcher;
