// Импорт необходимых библиотек и компонентов React
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import WigetOrderRentalPointList from "./WigetOrders/WigetOrderRentalPointList";
import { useSubscribeData } from "../../../../../services/firebase/crudFirebaseStorage";
import OrdersList from "./WigetOrders/OrdersList";
import OrderDetails from "./WigetOrders/OrderDetails";
import WigetProportions from "../../../../UIComponents/proportions/WigetProportions";
import { setCurrentUsersRentalPoints } from "../../../../../slice/stateGUISlice";
import { useRef } from "react";
// import GanttСhart from "./WigetOrders/GanttСhart";
// import OrdersListTimeline from "./WigetOrders/OrdersListTimeline";
import { fetchOrdersThunk } from "../../../../../slice/thunk/fetchOrdersThunk";

// Компонент UIOrders
const UIOrders = () => {
  const dispatch = useDispatch();

  // Состояния компонента
  const [activeRentalPointIndex, setActiveRentalPointIndex] = useState(0);
  const [activeRentalPointId, setActiveRentalPointId] = useState(null);
  const [filteredRentalPointsData, setFilteredRentalPointsData] = useState([]);
  const [orderDetailObject, setOrderDetailObject] = useState();
  const [rentalPointIds, setRentalPointIds] = useState([]);
  const [allOrders, setAllOrders] = useState(false);

  // Получаем данные о пользователе"
  const currentUser = useSelector((state) => state.userSliceV2.currentUser);
  const role = currentUser ? currentUser.user : null;
  const isLoading = useSelector((state) => state.userSliceV2.isLoading);
  const userId = currentUser ? currentUser.uid : null;

  useEffect(() => {
    if (userId) {
      dispatch(fetchOrdersThunk(userId));
    }
  }, [userId, dispatch]);

  // Фильтрация заказов конкретного пользователя
  const matchedOrdersClient = useSelector(
    (state) => state.orderDataSlice.matchedOrdersClient
  );

  // Фильтрация заказов по точкам проката которые зарегистрировал пользователь
  const matchedOrdersManage = useSelector(
    (state) => state.orderDataSlice.matchedOrdersManage
  );

  // Все заказы
  const ordersData = useSelector((state) => state.orderDataSlice.ordersData);

  // Данные о пользователе
  const userData = useSelector((state) => state.orderDataSlice.userData);

  // Статус загрузки данных
  const isLoadingOrdersData = useSelector(
    (state) => state.orderDataSlice.isLoadingOrdersData
  );

  console.log(
    "UIOrders-matchedOrdersClient",
    matchedOrdersClient,
    "matchedOrdersManage",
    matchedOrdersManage,
    "ordersData",
    ordersData,
    "userData",
    userData,
    "isLoadingOrdersData",
    isLoadingOrdersData
  );

  // Запрос данных пользователя, пунктов аренды, велосипедов и заказов с использованием сервиса Firebase
  // const { data: userData, isLoading: loadingUserData } = useSubscribeData(
  //   "user",
  //   userId
  // );

  const { data: rentalPointsData, isLoading: loadingRentalPointsData } =
    useSubscribeData("rentalPoints");
  // const { data: bikesData, isLoading: loadingBikesData } =
  //   useSubscribeData("bikes");

  // Эффект для фильтрации пунктов аренды по данным пользователя
  useEffect(() => {
    const filteredData =
      rentalPointsData &&
      rentalPointsData.filter((item) =>
        userData?.rentalPoints_id?.includes(item.id)
      );
    setFilteredRentalPointsData(filteredData);
  }, [rentalPointsData, userData]);

  // Эффект для получения и установки кнопки all orders
  useEffect(() => {
    const rentalPointIds =
      filteredRentalPointsData &&
      filteredRentalPointsData.map((rentalPoint) => rentalPoint.id);
    setRentalPointIds(rentalPointIds);
  }, [filteredRentalPointsData]);

  // Фильтруем точки проката так, чтобы получить прокаты текущего пользователя
  useEffect(() => {
    if (!loadingRentalPointsData) {
      const filteredData =
        rentalPointsData &&
        rentalPointsData.filter((item) =>
          userData?.rentalPoints_id?.includes(item.id)
        );
      dispatch(setCurrentUsersRentalPoints(filteredData));
      setFilteredRentalPointsData(filteredData);
    }
  }, [rentalPointsData, userData, loadingRentalPointsData, dispatch]);

  // Эффект для открытия по умолчанию данных о заказе в точке проката
  useEffect(() => {
    if (activeRentalPointIndex && activeRentalPointId) {
      setActiveRentalPointIndex(activeRentalPointIndex);
      setActiveRentalPointId(activeRentalPointId);
    } else if (
      filteredRentalPointsData &&
      filteredRentalPointsData.length > 0
    ) {
      const firstRentalPoint = filteredRentalPointsData[0];
      setActiveRentalPointIndex(activeRentalPointIndex);
      setActiveRentalPointId(firstRentalPoint.id);
    }
  }, [filteredRentalPointsData]);

  // Функция для обработки клика по пункту аренды
  // const handleItemRentalPointClick = (index, idRentalPoint) => {
  //   setActiveRentalPointIndex(index);
  //   setActiveRentalPointId(idRentalPoint);
  // };

  // const calendarRef = useRef(null);

  // useEffect(() => {
  //     // Получаем высоту элемента
  //     const elementHeight = calendarRef.current.offsetHeight;
  //     const screenHeight = window.innerHeight;
  //     console.log('Высота блока компонента:', elementHeight, 'пикселей\nвысота окна браузера:', screenHeight);
  // }, []); // Зависимость пуста, так что useEffect выполнится только один раз после монтирования компонента

  // Возвращение разметки компонента
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flex: "1",
        boxSizing: "border-box",
        // width: 'calc(100vw - 330px)',
        width: "100%",
        // height: 'calc(100vh - 56px)',
        height: "100%",
        overflowY: "hidden",
        backgroundColor: "black",
        // gap: '16px',
      }}
    >
      <div
        style={{
          width: "500px",
          height: "100%",
        }}
      >
        <WigetOrderRentalPointList />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          width: "calc(100% - 500px)",
          height: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            boxSizing: "border-box",
            height: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "space-between",
              boxSizing: "border-box",
              overflow: "hidden",
              resize: "horizontal",
              width: "70%",
              borderRadius: "0 8px 8px 0",
              height: "100%",
            }}
            id="idOrdersList"
          >
            <OrdersList />
            {/* <OrdersListTimeline /> */}
          </div>

          <WigetProportions idBlock="idOrdersList" />

          <div
            style={{
              backgroundColor: "var(--color-gray-100)",
              flex: "1",
              borderRadius: "8px",
              overflow: "hidden",
              borderRight: "8px",
              height: "100%",
              minWidth: "600px",
            }}
          >
            <OrderDetails />
          </div>
        </div>

        {/* <div
                    ref={calendarRef}
                    style={{
                        height: '200px',
                        width: '100%',
                    }}>
                    <GanttСhart />
                </div> */}
      </div>
    </div>
  );
};

// Экспорт компонента для использования в других частях приложения
export default UIOrders;
