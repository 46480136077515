import React, { useState, useEffect } from "react";
import InputText from "../WidgetsProfile/Inputs/InputText";
import Button from "../WidgetsProfile/Buttons/Button";
import { useDispatch, useSelector } from "react-redux";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import {
  removeContactsEmailAddress,
  setContactsEmailAddresses,
} from "../../../../../reducersToolkit/rentalPointsSliiceV2";
import {
  removeContactsEmailAddressModeration,
  setContactsEmailAddressesModeration,
} from "../../../../../reducersToolkit/moderationSliceV2";

const WindowEditEmailModeration = ({ mode, onClose }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [emailAddresses, setEmailAddresses] = useState([]);
  const [newEmailAddresses, setNewEmailAddresses] = useState([]);

  const currentUser = useSelector((state) => state.userSliceV2.currentUser);

  const { selectedRentalPoint, newRentalPoint } = useSelector(
    (state) => state.moderationSliceV2
  );

  useEffect(() => {
    if (mode === "moderatorAdded") {
      setEmailAddresses(newRentalPoint?.contacts?.emailAddresses || []);
    } else if (mode === "moderation") {
      setEmailAddresses(selectedRentalPoint?.contacts?.emailAddresses || []);
    }
  }, [mode, newRentalPoint, selectedRentalPoint]);

  const handleAddEmail = () => {
    const emailObject = {
      key: Date.now().toString(),
      email,
      moderatorId: mode === "moderation" ? currentUser.id : null,
    };
    setNewEmailAddresses([...newEmailAddresses, emailObject]);
    setEmail("");
  };

  const handleRemoveEmail = (index) => {
    setNewEmailAddresses(newEmailAddresses.filter((_, i) => i !== index));
  };

  const handleRemoveLoadedEmail = (emailObject) => {
    dispatch(
      removeContactsEmailAddressModeration({ mode: mode, key: emailObject.key })
    );
  };

  const handleApply = () => {
    newEmailAddresses.forEach((emailObject) => {
      dispatch(
        setContactsEmailAddressesModeration({
          mode: mode,
          emailAddresses: emailObject,
        })
      );
    });

    onClose();
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "8px",
      }}
    >
      <ContentBox position="start">
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            gap: "16px",
          }}
        >
          <InputText
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            label="Email address"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          />
          <div
            style={{
              width: "auto",
            }}
          >
            <Button
              label="Add"
              active={email ? true : false}
              onClick={handleAddEmail}
            />
          </div>
        </div>
      </ContentBox>
      {(emailAddresses.length > 0 || newEmailAddresses.length > 0) && (
        <ContentBox>
          <ul
            style={{
              width: "100%",
              listStyle: "none",
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            {emailAddresses.map((emailObj) => (
              <li
                key={emailObj.key}
                style={{
                  height: "56px",
                  width: "100%",
                  boxSizing: "border-box",
                  borderRadius: "8px",
                  backgroundColor: "var(--color-gray-100)",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "0 8px 0 16px",
                }}
              >
                <h6>{emailObj.email}</h6>
                <div
                  style={{
                    width: "auto",
                  }}
                >
                  <Button
                    onClick={() => handleRemoveLoadedEmail(emailObj)}
                    allert={
                      <h1
                        style={{
                          padding: "48px",
                          textAlign: "center",
                        }}
                      >
                        Are you sure you want to delete this email address?
                        Please confirm your action.
                      </h1>
                    }
                    active={true}
                    color="--color-gray-100"
                    type="icon"
                    icon={
                      <span className="material-symbols-outlined">delete</span>
                    }
                  />
                </div>
              </li>
            ))}
            {newEmailAddresses.map((emailObj, index) => (
              <li
                key={emailObj.key}
                style={{
                  height: "56px",
                  width: "100%",
                  boxSizing: "border-box",
                  borderRadius: "8px",
                  backgroundColor: "var(--color-gray-100)",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "0 8px 0 16px",
                }}
              >
                <h6>{emailObj.email}</h6>
                <div
                  style={{
                    width: "auto",
                  }}
                >
                  <Button
                    onClick={() => handleRemoveEmail(index)}
                    allert={
                      <h1
                        style={{
                          padding: "48px",
                          textAlign: "center",
                        }}
                      >
                        Are you sure you want to delete this email address?
                        Please confirm your action.
                      </h1>
                    }
                    active={true}
                    color="--color-gray-100"
                    type="icon"
                    icon={
                      <span className="material-symbols-outlined">delete</span>
                    }
                  />
                </div>
              </li>
            ))}
          </ul>
        </ContentBox>
      )}

      <ContentBox position="end">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "16px",
            width: "100%",
          }}
        >
          <Button
            label="Cancel"
            active={true}
            color="--color-alarm"
            onClick={onClose}
          />
          <Button
            label="Apply"
            active={newEmailAddresses.length > 0 && true}
            onClick={handleApply}
          />
        </div>
      </ContentBox>
    </div>
  );
};

export default WindowEditEmailModeration;
