import { createAsyncThunk } from "@reduxjs/toolkit";
import { addData } from "../../../services/firebase/crudFirestore";
import interpretAuthErrorV2 from "../../../functions/interpretAuthErrorV2";

export const addDirectoryCountryThunkV2 = createAsyncThunk(
  "directories/addDirectoryCountryThunkV2",
  async ({ collectionName, newData }, { rejectWithValue }) => {
    try {
      const addedDocument = await addData(collectionName, newData);
      return addedDocument;
    } catch (error) {
      const errorMessage = interpretAuthErrorV2(error.code);
      return rejectWithValue({ errorMessage });
    }
  }
);
