import { createAsyncThunk } from "@reduxjs/toolkit";
import { doc, updateDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../../../../services/firebase/authFirebase";

export const deleteCountryThunkV4 = createAsyncThunk(
  "countries/deleteCountryThunkV4",
  async ({ countryId }, { rejectWithValue }) => {
    try {
      // Получение ссылки на документ
      const docRef = doc(db, "catalog", countryId);

      // Обновление поля remove с текущим timestamp
      await updateDoc(docRef, {
        remove: serverTimestamp(),
      });

      console.log(`Страна с ID ${countryId} успешно отмечена как удаленная.`);
      return { countryId, remove: Date.now() };
    } catch (error) {
      console.error("Ошибка при удалении страны:", error);
      return rejectWithValue(error.message);
    }
  }
);
