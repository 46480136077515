import React, { useEffect, useState } from "react";
import DropdownList from "../WidgetsProfile/DropdownList/DropdownList";
import { useDispatch } from "react-redux";
import {
  setCityIdModeration,
  setCountryIdModeration,
} from "../../../../../reducersToolkit/moderationSliceV2";
import PopupDirrectory from "../WidgetsProfile/Popups/PopupDirrectory";
import WindowLocationDetailModeration from "./WindowLocationDetailModeration";
import SmallButton from "../WidgetsProfile/Buttons/SmallButton";
import AddData from "../WidgetsProfile/AddData/AddData";

const WidgetLocationModeration = ({
  location,
  countries,
  cities,
  mode,
  ancorId,
}) => {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const [isOpenLocationModeration, setIsOpenLocationModeration] =
    useState(false);

  const handleToggle = () => {
    setIsOpenLocationModeration(true);
  };

  const handleClose = () => {
    setIsOpenLocationModeration(false);
  };

  useEffect(() => {
    // Находим начально выбранную страну
    const initialCountry = countries.find(
      (country) => country?.id === location?.countryId
    );

    // Находим начально выбранный город
    const initialCity = cities.find((city) => city.id === location?.cityid);

    setSelectedCountry(initialCountry);
    setSelectedCity(initialCity);
  }, [countries, cities, location.countryId, location?.cityid]);

  return (
    <>
      <div className="profile-widget__content-box" id={ancorId}>
        <AddData
          onClick={handleToggle}
          icon={
            location?.pointAddress ? (
              <span className="material-symbols-outlined">edit</span>
            ) : (
              <span className="material-symbols-outlined">add</span>
            )
          }
          title={location?.pointAddress || "No address"}
          description={
            <div>
              <p className="p-light">
                City: {selectedCity?.title?.titleOriginal || "No city"}
              </p>
              <p className="p-light">
                Country: {selectedCountry?.title?.titleOriginal || "No country"}
              </p>
            </div>
          }
        />
        <div
          style={{
            width: "100%",
            aspectRatio: "16/9",
            backgroundColor: "var(--color-gray-100)",
            borderRadius: "16px",
            backgroundImage:
              "url(https://firebasestorage.googleapis.com/v0/b/biketripste.appspot.com/o/static%2Fmap%2F2024-05-11_11-05-16.png?alt=media&token=abba679b-3c9a-4dee-bd49-2f6872af8eb7)",
            backgroundSize: "auto 300%",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "8px",
          }}
        >
          <img
            src="https://firebasestorage.googleapis.com/v0/b/biketripste.appspot.com/o/static%2Fmap%2FselectedIconMapPointShadow.svg?alt=media&token=79f1fe50-77cf-4185-9d00-65e758d0f7fb"
            alt=""
            style={{
              width: "80px",
            }}
          />
        </div>
      </div>

      <PopupDirrectory
        isOpen={isOpenLocationModeration}
        onClose={handleClose}
        title="Rental point location moderation"
      >
        <WindowLocationDetailModeration onClose={handleClose} mode={mode} />
      </PopupDirrectory>
    </>
  );
};

export default WidgetLocationModeration;
