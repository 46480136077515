import React from "react";
import { WigetCountryDetail } from "./WigetCountry/WigetCountryDetail";
import SearchBars from "../../../../UIComponents/bars/searchBars/SearchBars";
import Icon44 from "../../../../UIComponents/icons/Icon44";
import WigetButtonAdd from "../../../../UniversalWidgets/WigetButtonAdd";
import { setPrivateSideBar } from "../../../../../slice/stateGUISlice";
import { useDispatch } from "react-redux";

const WigetCountryBox = () => {

    const dispatch = useDispatch()

    return (
        <>
            {/* <div className="wigetRentalPointItsBox">
                <h3>Страны</h3>
                <p className='p-primary'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eleifend ipsum vitae nunc gravida facilisis.</p>
            </div> */}
            <SearchBars
                title='Countryes'

                iconLeft={
                    <Icon44
                        click={() => dispatch(setPrivateSideBar(true))}
                        icon={
                            <span className="material-symbols-outlined">
                                dock_to_right
                            </span>
                        }
                    />
                }
                iconRight={
                    <WigetButtonAdd
                        collectionName='country'
                        // dataObject={structureCollection}
                        nameButton={`Add country`}
                    />
                }
            />
            <div className='scrollBox'>
                <WigetCountryDetail
                    collectionName="country"
                    title="Страны" />
            </div>

        </>
    );
}

export default WigetCountryBox;


