import React, { useEffect, useState } from "react";
import Resizer from "react-image-file-resizer";
import Preloader from "../../../../../UIElements/Preloader";
import WidgetListFileV4 from "./WidgetListFileV4";
import AddData from "../AddData/AddData";

const WidgetUploadFilesV4 = ({ id, files, title, description }) => {
  const [dragging, setDragging] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    files(selectedFiles);
  }, [selectedFiles, files]);

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const files = Array.from(e.dataTransfer.files);
    processFiles(files);
  };

  const handleFileSelect = (e) => {
    const files = Array.from(e.target.files);
    processFiles(files);
  };

  const processFiles = async (files) => {
    setIsUploading(true); // Устанавливаем состояние загрузки

    const resizedFilesPromises = files.map((file) => {
      if (file.type.startsWith("image/") && file.size > 512 * 512) {
        return resizeFile(file);
      }
      return Promise.resolve(file);
    });

    const resizedFiles = await Promise.all(resizedFilesPromises);

    setSelectedFiles((prevFiles) => [...prevFiles, ...resizedFiles]);

    setIsUploading(false); // Сбрасываем состояние загрузки после завершения
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        800, // max width
        800, // max height
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const removeFile = (fileToRemove) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((file) => file !== fileToRemove)
    );
  };

  return (
    <>
      <div
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        style={{
          backgroundColor: dragging
            ? "var(--color-priority)"
            : "var(--color-white)",
          textAlign: "center",
          width: "100%",
          // aspectRatio: "16/9",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "16px",
          // position: "relative",
          boxSizing: "border-box",
        }}
      >
        <input
          type="file"
          onChange={handleFileSelect}
          style={{ display: "none" }}
          multiple
          id={`fileInput-${id}`}
        />

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "8px",
            // padding: "8px",
            boxSizing: "border-box",
          }}
        >
          <AddData
            onClick={() => document.getElementById(`fileInput-${id}`).click()}
            icon={<span className="material-symbols-outlined">add</span>}
            title={title ? title : "Add photos"}
            description={description}
          />
          {selectedFiles.length > 0 && (
            <ul
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                listStyleType: "none",
                gap: "8px",
                flex: "1",
                width: "100%",
                boxSizing: "border-box",
              }}
            >
              {selectedFiles?.map((file, index) => (
                <li
                  key={index}
                  style={{
                    width: "100%",
                    boxSizing: "border-box",
                    ...(index === 2
                      ? {
                          gridColumn: "1 / span 2",
                          gridRow: "1 / span 2",
                        }
                      : {
                          aspectRatio: "16/9",
                        }),
                  }}
                >
                  {file.type.startsWith("image/") ? (
                    <div
                      className="container"
                      style={{
                        backgroundImage: `url(${URL.createObjectURL(file)})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        borderRadius: "8px",
                        position: "relative",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      {!isUploading && (
                        <span
                          className="material-symbols-outlined hidden-element"
                          onClick={() => removeFile(file)}
                          style={{
                            position: "absolute",
                            top: "8px",
                            right: "8px",
                            cursor: "pointer",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            borderRadius: "50%",
                            padding: "4px",
                            color: "white",
                          }}
                        >
                          close
                        </span>
                      )}

                      {isUploading && (
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            backgroundColor: "var(--color-gray-100-alpha-50)",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "8px",
                          }}
                        >
                          <Preloader />
                        </div>
                      )}
                    </div>
                  ) : (
                    <span>{file.name}</span>
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </>
  );
};

export default WidgetUploadFilesV4;

// import React, { useState } from "react";
// import Resizer from "react-image-file-resizer";
// import Preloader from "../../../../../UIElements/Preloader";

// const WidgetUploadFilesV4 = ({ id, setPhotoFiles, photoFiles }) => {
//   const [dragging, setDragging] = useState(false);
//   const [isUploading, setIsUploading] = useState(false);

//   const handleDragOver = (e) => {
//     e.preventDefault();
//     setDragging(true);
//   };

//   const handleDragLeave = () => {
//     setDragging(false);
//   };

//   const handleDrop = (e) => {
//     e.preventDefault();
//     setDragging(false);
//     const files = Array.from(e.dataTransfer.files);
//     processFiles(files);
//   };

//   const handleFileSelect = (e) => {
//     const files = Array.from(e.target.files);
//     processFiles(files);
//   };

//   const processFiles = (files) => {
//     // Выводим все файлы для отладки
//     console.log("Processing files:", files);

//     const resizedFilesPromises = files.map((file, index) => {
//       // Проверяем, что file существует и имеет тип "File"
//       if (file && typeof file === "object" && typeof file.type === "string") {
//         if (file.type.startsWith("image/") && file.size > 512 * 512) {
//           return resizeFile(file);
//         } else {
//           return Promise.resolve(file); // Возвращаем файл, если он меньше или не нуждается в изменении размера
//         }
//       } else {
//         console.warn(`Invalid file at index ${index}:`, file);
//         return Promise.resolve(null); // Пропускаем невалидные файлы
//       }
//     });

//     Promise.all(resizedFilesPromises).then((resizedFiles) => {
//       // Фильтруем возможные null значения
//       const validFiles = resizedFiles.filter((file) => file !== null);
//       setPhotoFiles((prevFiles) => [...prevFiles, ...validFiles]);
//     });
//   };

//   const resizeFile = (file) =>
//     new Promise((resolve) => {
//       Resizer.imageFileResizer(
//         file,
//         800, // max width
//         800, // max height
//         "JPEG",
//         100,
//         0,
//         (uri) => {
//           resolve(uri);
//         },
//         "file"
//       );
//     });

//   const removeFile = (fileToRemove) => {
//     setPhotoFiles((prevFiles) =>
//       prevFiles.filter((file) => file !== fileToRemove)
//     );
//   };

//   return (
//     <>
//       <div
//         onDragOver={handleDragOver}
//         onDragLeave={handleDragLeave}
//         onDrop={handleDrop}
//         style={{
//           backgroundColor: dragging
//             ? "var(--color-priority)"
//             : "var(--color-gray-100)",
//           textAlign: "center",
//           width: "100%",
//           aspectRatio: "16/9",
//           borderRadius: "8px",
//           display: "flex",
//           flexDirection: "column",
//           alignItems: "center",
//           justifyContent: "center",
//           gap: "16px",
//           position: "relative",
//           boxSizing: "border-box",
//         }}
//       >
//         <input
//           type="file"
//           onChange={handleFileSelect}
//           style={{ display: "none" }}
//           multiple
//           id={`fileInput-${id}`} // Уникальный ID для каждого инстанса
//         />
//         <div
//           style={{
//             width: "100%",
//             display: "flex",
//             flexDirection: "column",
//             alignItems: "center",
//             justifyContent: "center",
//             gap: "8px",
//             padding: "8px",
//             boxSizing: "border-box",
//           }}
//         >
//           <div
//             style={{
//               width: "100%",
//               aspectRatio: "16/9",
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "center",
//             }}
//           >
//             <h1
//               style={{
//                 color: dragging
//                   ? "var(--color-white)"
//                   : "var(--color-gray-300)",
//               }}
//             >
//               Drag and drop files here
//             </h1>
//           </div>

//           {photoFiles.length > 0 && (
//             <ul
//               style={{
//                 display: "grid",
//                 gridTemplateColumns: "repeat(3, 1fr)",
//                 listStyleType: "none",
//                 gap: "8px",
//                 flex: "1",
//                 width: "100%",
//                 boxSizing: "border-box",
//               }}
//             >
//               {photoFiles.map((file, index) => (
//                 <li
//                   key={index}
//                   style={{
//                     width: "100%",
//                     boxSizing: "border-box",
//                     ...(index === 2
//                       ? {
//                           gridColumn: "1 / span 2",
//                           gridRow: "1 / span 2",
//                         }
//                       : {
//                           aspectRatio: "16/9",
//                         }),
//                   }}
//                 >
//                   {file.type.startsWith("image/") ? (
//                     <div
//                       className="container"
//                       style={{
//                         backgroundImage: `url(${URL.createObjectURL(file)})`,
//                         backgroundRepeat: "no-repeat",
//                         backgroundPosition: "center",
//                         backgroundSize: "cover",
//                         borderRadius: "8px",
//                         position: "relative",
//                         width: "100%",
//                         height: "100%",
//                       }}
//                     >
//                       {!isUploading && (
//                         <span
//                           className="material-symbols-outlined hidden-element"
//                           onClick={() => removeFile(file)}
//                           style={{
//                             position: "absolute",
//                             top: "8px",
//                             right: "8px",
//                             cursor: "pointer",
//                             backgroundColor: "rgba(0, 0, 0, 0.5)",
//                             borderRadius: "50%",
//                             padding: "4px",
//                             color: "white",
//                           }}
//                         >
//                           close
//                         </span>
//                       )}

//                       {isUploading && (
//                         <div
//                           style={{
//                             position: "absolute",
//                             top: 0,
//                             left: 0,
//                             width: "100%",
//                             height: "100%",
//                             backgroundColor: "var(--color-gray-100-alpha-50)",
//                             display: "flex",
//                             alignItems: "center",
//                             justifyContent: "center",
//                             borderRadius: "8px",
//                           }}
//                         >
//                           <Preloader />
//                         </div>
//                       )}
//                     </div>
//                   ) : (
//                     <span>{file.name}</span>
//                   )}
//                 </li>
//               ))}
//             </ul>
//           )}
//         </div>

//         <button
//           onClick={() => document.getElementById(`fileInput-${id}`).click()} // Используем уникальный ID
//           style={{
//             backgroundColor: "var(--color-primary)",
//             color: "white",
//             border: "none",
//             borderRadius: "80px",
//             // height: "40px",
//             width: "auto",
//             cursor: "pointer",
//             display: "flex",
//             flexDirection: "row",
//             alignItems: "center",
//             justifyContent: "center",
//             gap: "4px",
//             padding: " 16px",
//             position: "absolute",
//             right: "16px",
//             top: "16px",
//             boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
//           }}
//         >
//           <span
//             className="material-symbols-outlined"
//             style={{
//               color: "var(--color-white)",
//             }}
//           >
//             add
//           </span>
//         </button>
//       </div>
//     </>
//   );
// };

// export default WidgetUploadFilesV4;
