import React from "react";
import { WigetBrandDetail } from "./WigetBrand/WigetBrandDetail";
import SearchBars from "../../../../../UIComponents/bars/searchBars/SearchBars";
import Icon44 from "../../../../../UIComponents/icons/Icon44";
import { useDispatch } from "react-redux";
import { setPrivateSideBar } from "../../../../../../slice/stateGUISlice";
import WigetButtonAdd from "../../../../../UniversalWidgets/WigetButtonAdd";

const WigetCurrencyBox = () => {

    const dispatch = useDispatch()
    return (
        <>
            <SearchBars
                title='Brands'

                iconLeft={
                    <Icon44
                        click={() => dispatch(setPrivateSideBar(true))}
                        icon={
                            <span className="material-symbols-outlined">
                                dock_to_right
                            </span>
                        }
                    />
                }
                iconRight={
                    <WigetButtonAdd
                        collectionName='brand'
                        // dataObject={structureCollection}
                        nameButton={`Add brand`}
                    />
                }
            />

            <div
                className='scrollBox'
            >
                <WigetBrandDetail
                    collectionName="brand"
                    title="Brands"
                />
            </div>

        </>
    );
}

export default WigetCurrencyBox;


