import React from "react";

const WindowLanguage = () => {
  return (
    <div>
      <h1>Hello, WindowLanguage!</h1>
    </div>
  );
};

export default WindowLanguage;
