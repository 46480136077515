import React, { useEffect, useState } from "react";
import InputText from "../WidgetsProfile/Inputs/InputText";
import Textarea from "../WidgetsProfile/Textarea/Textarea";
import Button from "../WidgetsProfile/Buttons/Button";
import { useDispatch, useSelector } from "react-redux";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import { setBasicInformationModeration } from "../../../../../reducersToolkit/moderationSliceV2";

const WindowEditBasicInformationModerationV4 = ({ mode, onClose }) => {
  const dispatch = useDispatch();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const currentUser = useSelector((state) => state.userSliceV2.currentUser);

  const { selectedRentalPoint, newRentalPoint } = useSelector(
    (state) => state.moderationSliceV2
  );

  useEffect(() => {
    if (mode === "moderation") {
      setTitle(selectedRentalPoint.basicInformation.title.titleOriginal);
      setDescription(
        selectedRentalPoint.basicInformation.description.descriptionOriginal
      );
    } else if (mode === "moderatorAdded") {
      setTitle(newRentalPoint.basicInformation.title.titleOriginal);
      setDescription(
        newRentalPoint.basicInformation.description.descriptionOriginal
      );
    }
  }, [selectedRentalPoint, newRentalPoint, mode]);

  const handleSetTitle = (event) => {
    setTitle(event.target.value);
  };

  const handleSetDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleApply = () => {
    dispatch(
      setBasicInformationModeration({
        title,
        description,
        userId: currentUser.id,
        mode,
      })
    );
    onClose();
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "8px",
      }}
    >
      <ContentBox position="start">
        <InputText
          label="Rental point name"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          value={title}
          onChange={handleSetTitle}
        />
        <Textarea
          label="Description rental point"
          maxLength={500}
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          value={description}
          onChange={handleSetDescription}
        />
      </ContentBox>

      <ContentBox position="end">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "16px",
            width: "100%",
          }}
        >
          <Button
            label="Cancel"
            active={true}
            color="--color-alarm"
            onClick={handleCancel}
          />
          <Button label="Apply" active={true} onClick={handleApply} />
        </div>
      </ContentBox>
    </div>
  );
};

export default WindowEditBasicInformationModerationV4;
