import { createAsyncThunk } from "@reduxjs/toolkit";
import { updateDocumentFields } from "../../../services/firebase/crudFirestore";

export const saveUserDataThank = createAsyncThunk(
  "document/saveUserDataThank",
  async ({ collectionName, docId, fieldsToUpdate }, thunkAPI) => {
    console.log(
      "collectionName, docId, fieldsToUpdate",
      collectionName,
      docId,
      fieldsToUpdate
    );
    try {
      await updateDocumentFields(collectionName, docId, fieldsToUpdate);
      return { collectionName, docId, fieldsToUpdate };
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
