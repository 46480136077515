import React, { useEffect, useState } from "react";
import AvatarUser from "../WidgetsProfile/Avatars/AvatarUser";
import PopupDirrectory from "../WidgetsProfile/Popups/PopupDirrectory";
import WindowUploadFiles from "../DirectoryV3/Directory/WindowUploadFiles";
import priorityFile from "../../../../../functions/priorityFile";
import { subscribeToDocument } from "../../../../../services/firebase/crudFirestore";

const WidgetUserAvatar = ({
  currentUser,
  collectionName,
  documentId,
  fieldName,
  iconAddphoto,
  size = 80,
}) => {
  const [windowUploadFiles, setWindowUploadFiles] = useState(false);
  const [uId, setUid] = useState("0");
  const [avatarFile, setAvatarFile] = useState("");
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (files && files.length > 0) {
      const avatarFile = priorityFile(files || null);
      setAvatarFile(avatarFile.url || "");
    }

    setUid(currentUser.id);
  }, [files, currentUser]);

  const getNestedField = (obj, path) => {
    return path.split(".").reduce((acc, part) => acc && acc[part], obj);
  };

  useEffect(() => {
    if (collectionName && documentId) {
      const unsubscribe = subscribeToDocument(
        collectionName,
        documentId,
        null,
        async (data) => {
          setFiles(data ? getNestedField(data, fieldName) : []);
        }
      );
      return () => {
        console.log("unsubscribe");
        unsubscribe();
      };
    }
  }, [documentId, fieldName, collectionName]);

  const handleToggle = () => {
    setWindowUploadFiles(true);
  };

  const handleClose = () => {
    setWindowUploadFiles(false);
  };

  return (
    <>
      <div
        style={{
          // width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "16px",
          position: "relative",
        }}
      >
        <AvatarUser size={size} url={avatarFile} />
        {iconAddphoto && (
          <span
            className="material-symbols-outlined"
            onClick={handleToggle}
            style={{
              position: "absolute",
              bottom: "8px",
              cursor: "pointer",
              backgroundColor: "var(--color-white)",
              // backgroundColor: "violet",
              padding: "8px",
              borderRadius: "80px",
            }}
          >
            photo_camera
          </span>
        )}
      </div>

      <PopupDirrectory
        isOpen={windowUploadFiles}
        onClose={handleClose}
        title="Add useer avarar"
      >
        <WindowUploadFiles
          collectionName="users"
          documentId={uId}
          fieldName={"user.files.avatarFiles"}
        />
      </PopupDirrectory>
    </>
  );
};

export default WidgetUserAvatar;
