import { createAsyncThunk } from "@reduxjs/toolkit";
import { updateFieldsFiles } from "../../../../services/firebase/crudFirestore";

export const updateFilePriorityThunkV4 = createAsyncThunk(
  "files/updateFilePriorityThunkV4",
  async (
    { collectionName, documentId, fieldName, filesList, currentFile },
    { rejectWithValue }
  ) => {
    try {
      // Обновляем список файлов: устанавливаем приоритет для текущего файла и сбрасываем для остальных
      const updatedFiles = filesList.map((file) =>
        file.id === currentFile.id
          ? { ...file, priority: true }
          : { ...file, priority: false }
      );

      // Вызываем функцию для обновления полей в Firestore
      await updateFieldsFiles(collectionName, documentId, {
        [fieldName]: updatedFiles,
      });

      console.log("Priority updated successfully", updatedFiles);
      return { updatedFiles, collectionName, documentId }; // Возвращаем обновленный список файлов как результат
    } catch (error) {
      console.error("Error updating priority:", error);
      return rejectWithValue(error.message);
    }
  }
);
