import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchDocumentsByIds } from "../../../../services/firebase/crudFirestore";

export const fetchCountryThunk = createAsyncThunk(
  "directory/countryAndCity/fetchCountryThunk",
  async (_, { rejectWithValue }) => {
    try {
      console.log("countryWithCities countryData", "Start");
      // Получаем данные стран
      const countryData = await fetchDocumentsByIds("country");
      console.log("countryWithCities countryData", countryData);

      // Проверка наличия данных
      if (!countryData) {
        throw new Error("Не удалось получить данные стран");
      }

      // Возвращаем данные стран с вложенными городами
      return countryData;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
