import React, { useEffect, useState } from "react";
import Button from "../WidgetsProfile/Buttons/Button.js";
import WidgetBikeRate from "../BikesV3/WidgetBikeRate.js";
import { useDispatch, useSelector } from "react-redux";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox.js";
import WidgetBikeBasicInformation from "../BikesV3/WidgetBikeBasicInformation.js";
import WidgetBrandCategory from "../BikesV3/WidgetBrandCategory.js";
import { clearForm } from "../../../../../reducersToolkit/bikesSliceV2.js";
import WidgetPhotosBike from "../BikesV3/WidgetPhotosBike.js";
import { updateBikeModerationThunkV2 } from "../../../../../reducersToolkit/thunk/moderation/updateBikeModerationThunkV2.js";
import PopupDirrectory from "../WidgetsProfile/Popups/PopupDirrectory.js";
import WindowOrderDetailModeration from "../OrdersV3/WindowOrderDetailModeration.js";
import WidgetQuntityBike from "../BikesV3/WidgetQuntityBike.js";
import {
  setOrderBike,
  setOrderStatus,
} from "../../../../../reducersToolkit/orderSliceV2.js";
import { addBikeModerationThunkV2 } from "../../../../../reducersToolkit/thunk/moderation/addBikeModerationThunkV2.js";

const WindowBikeDetailModeration = ({ mode, onClose }) => {
  const dispatch = useDispatch();

  const [basicInformation, setBasicInformation] = useState({});
  const [rate, setRate] = useState([]);
  const [brandId, setBrandId] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const [buttonState, setButtonState] = useState(null);
  const [isChanged, setIsChanged] = useState(false);

  const [windowOrderDetailModeration, setWindowOrderDetailModeration] =
    useState(false);
  const [metricsBike, setMetricsBike] = useState({});
  const [quantityBikes, setQuantityBikes] = useState(0);

  const [selectedRentalPointId, setSelectedRentalPointId] = useState(null);

  const currentUser = useSelector((state) => state.userSliceV2.currentUser);

  const { selectedBike, newBike, selectedRentalPoint } = useSelector(
    (state) => state.moderationSliceV2
  );

  // const {
  //   isLoading: isLoadingBike,
  //   newBike,
  //   selectedBike,
  // } = useSelector((state) => state.bikesSliceV2);

  const { countries, brands, categories } = useSelector(
    (state) => state.directorySliceV2
  );

  useEffect(() => {
    // Очистка формы
    dispatch(clearForm());
    // Формируем состояние для кнопки save
    if (mode === "new" || mode === "moderatorAdded") {
      setButtonState(newBike);
    } else if (mode === "moderation") {
      setButtonState(selectedBike);
    }
  }, [mode, dispatch]);

  useEffect(() => {
    if (mode === "new" || mode === "moderatorAdded") {
      setBasicInformation(newBike?.basicInformation || {});
      setRate(newBike?.rate || []);
      setBrandId(newBike?.directory?.brandId || null);
      setCategoryId(newBike?.directory?.categoryesId || null);
      setMetricsBike(newBike?.metricsBike || {});
      setSelectedRentalPointId(selectedRentalPoint?.id || null);
    } else if (mode === "moderation") {
      setBasicInformation(selectedBike?.basicInformation || {});
      setRate(selectedBike?.rate || []);
      setBrandId(selectedBike?.directory?.brandId || null);
      setCategoryId(selectedBike?.directory?.categoryesId || null);
      setMetricsBike(selectedBike?.metricsBike || {});
    }
  }, [selectedRentalPoint, newBike, selectedBike, mode, dispatch]);

  useEffect(() => {
    const hasChanges = () => {
      return (
        JSON.stringify(buttonState?.basicInformation) !==
          JSON.stringify(basicInformation) ||
        JSON.stringify(buttonState?.rate) !== JSON.stringify(rate) ||
        buttonState?.directory?.brandId !== brandId ||
        buttonState?.directory?.categoryesId !== categoryId ||
        JSON.stringify(buttonState?.metricsBike) !== JSON.stringify(metricsBike)
      );
    };

    setIsChanged(hasChanges());
  }, [metricsBike, basicInformation, rate, brandId, categoryId, buttonState]);

  const addBike = async () => {
    dispatch(
      addBikeModerationThunkV2({
        rentalPointId: selectedRentalPointId,
        newData: newBike,
      })
    );
    onClose();
  };

  const cancel = () => {
    onClose();
  };

  const save = async () => {
    dispatch(
      updateBikeModerationThunkV2({
        idBike: selectedBike?.id,
        bikeData: selectedBike,
      })
    );
    onClose();
  };

  const addCart = () => {
    setWindowOrderDetailModeration(true);
    dispatch(
      setOrderStatus({
        mode: "new",
        status: "new",
        userId: currentUser.id,
        timestamp: Date.now(),
        comment: "",
      })
    );

    dispatch(
      setOrderBike({
        mode: "new",
        createdat: Date.now(),
        userCustomer: { ...currentUser.user },
        orderRentalPoint: selectedRentalPoint,
        orderBike: selectedBike,
      })
    );
  };

  const handleClose = () => {
    setWindowOrderDetailModeration(false);
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "8px",
        }}
      >
        <ContentBox position="start">
          <WidgetBikeBasicInformation
            mode={mode}
            basicInformation={basicInformation}
            setBasicInformation={setBasicInformation}
          />
        </ContentBox>

        {mode !== "new" && mode !== "moderatorAdded" && (
          <ContentBox>
            <WidgetPhotosBike mode={mode} selectedBike={selectedBike} />
          </ContentBox>
        )}

        <ContentBox>
          <WidgetBrandCategory
            mode={mode}
            brands={brands}
            categories={categories}
            initialSelectedBrandId={brandId}
            initialSelectedCategoryId={categoryId}
          />
        </ContentBox>

        <ContentBox>
          <WidgetQuntityBike
            mode={mode}
            metricsBike={metricsBike}
            setQuantityBikes={setQuantityBikes}
          />
        </ContentBox>

        <ContentBox>
          <WidgetBikeRate mode={mode} rate={rate} setRate={setRate} />
        </ContentBox>

        <ContentBox position="end">
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              gap: "8px",
            }}
          >
            <Button
              color="--color-black"
              label="Cancel"
              active={true}
              onClick={cancel}
            />
            {mode === "moderation" && (
              <>
                <Button label="Add cart" active={true} onClick={addCart} />
                <Button label="Save" active={isChanged} onClick={save} />
              </>
            )}
            {mode === "moderatorAdded" && (
              <Button label="Add bike" active={true} onClick={addBike} />
            )}

            {mode === "new" && (
              <Button label="Add bike" active={true} onClick={addBike} />
            )}
          </div>
        </ContentBox>
      </div>

      <PopupDirrectory
        isOpen={windowOrderDetailModeration}
        onClose={handleClose}
        title="Add bike to cart moderation"
      >
        <WindowOrderDetailModeration
          mode="new"
          onClose={handleClose}
          closeParent={onClose}
        />
      </PopupDirrectory>
    </>
  );
};

export default WindowBikeDetailModeration;
