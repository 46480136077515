import React, { createContext, useState, useContext, useCallback } from 'react';

const AlertContext = createContext();

export const useAlert = () => {
    return useContext(AlertContext);
};

export const AlertProvider = ({ children }) => {
    const [alert, setAlert] = useState({ isOpen: false, message: '', onConfirm: null });

    const showAlert = useCallback((message, onConfirm) => {
        setAlert({ isOpen: true, message, onConfirm });
    }, []);

    const closeAlert = useCallback(() => {
        setAlert({ isOpen: false, message: '', onConfirm: null });
    }, []);

    return (
        <AlertContext.Provider value={{ alert, showAlert, closeAlert }}>
            {children}
            {alert.isOpen && <AlertPopup message={alert.message} onClose={closeAlert} onConfirm={alert.onConfirm} />}
        </AlertContext.Provider>
    );
};

const AlertPopup = ({ message, onClose, onConfirm }) => {
    const handleConfirm = () => {
        onConfirm();
        onClose();
    }

    return (
        <div className="popup-overlay">
            <div className="popup-content">
                <div>
                    <p>{message}</p>
                    <button onClick={onClose}>Отмена</button>
                    <button onClick={handleConfirm}>Подтвердить</button>
                </div>
            </div>
        </div>
    );
};
