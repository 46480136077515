import React, { useEffect } from "react";
import "./PopupWindow.css";
const PopupWindow = ({ isOpen, onClose, children, title }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className="popupWindow__content-box">
      <div className="popupWindow__content">
        <div className="popupWindow__header">
          <h5 style={{ textTransform: "capitalize" }}>{title}</h5>

          <div className="popupWindow__button-bar">
            <div className="popupWindow__button--close" onClick={onClose}>
              <span className="material-symbols-outlined">close</span>
            </div>
          </div>
        </div>

        <div className="popupWindow__options">
          <div className="popupWindow__options--content">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default PopupWindow;
