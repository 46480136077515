import React, { useEffect, useState } from "react";
import SegmentedControls from "../WidgetsProfile/SegmentedControls/SegmentedControls";
import WidgetUsersListModeration from "./WidgetUsersListModeration";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import TitleBlockSection from "../WidgetsProfile/Titles/TitleBlockSection";
import Preloader from "../../../../UIElements/Preloader";

const WidgetModeration = ({ users, isLoading }) => {
  const [usersList, setUsersList] = useState([]);
  const [userState, setUserState] = useState("moderation");

  useEffect(() => {
    if (userState === "moderation") {
      setUsersList(users.usersModeration);
    } else if (userState === "approved") {
      setUsersList(users.usersApproved);
    } else if (userState === "banned") {
      setUsersList(users.usersBanned);
    }
  }, [userState, users]);

  return isLoading ? (
    <Preloader />
  ) : (
    <ContentBox>
      {users.users.length > 0 && (
        <>
          <SegmentedControls
            options={["moderation", "approved", "banned"]}
            onSelect="moderation"
            label="Partners in project"
            selected={setUserState}
          />
          <WidgetUsersListModeration usersList={usersList} />
        </>
      )}
    </ContentBox>
  );
};

export default WidgetModeration;
