import {
  fetchDocumentById,
  fetchDocumentsByIds,
  fetchDocumentsWithMultipleConditions,
  fetchDocumentsWithMultipleConditionsIncludingId,
} from "../../services/firebase/crudFirebaseStorage";
import { auth } from "../../services/firebase/authFirebase";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchUserData = createAsyncThunk(
  "user/fetchUserData/front",
  async (uid) => {
    // uid в качестве аргумента
    try {
      // Проверяем, авторизован ли пользователь через Google Auth
      if (!uid) {
        throw new Error("Пользователь не авторизован");
      }

      // // Пример использования
      // const documentIds = [
      //   "qaEcDEtNINEYXgEUu61a",
      //   "6qsTD2SkoJFTBg5Nkrls",
      //   "XmWe7Djxtu9Dol9tHfwI",
      // ]; // Пример массива ID
      // const additionalFilters = [
      //   ["client.phone", "==", "100100100"],
      //   // ['category', '==', 'news']
      // ]; // Дополнительные фильтры

      // fetchDocumentsByIds("orders", documentIds, additionalFilters)
      //   .then((documents) => console.log("Документы:", documents))
      //   .catch((error) => console.error("Ошибка:", error));

      console.log("Client-currentUser-uid", uid);
      // Находим данные пользователя в таблице user по UID
      const userData = await fetchDocumentById("user", uid);

      console.log("Client-currentUser-userData", userData);
      // Если пользователь не найден, выбрасываем ошибку

      if (!userData) {
        throw new Error("Пользователь не найден");
      }

      // Получаем массив идентификаторов заказов пользователя
      const ordersIds = userData.orders_id;

      // Инициализируем массив для хранения данных о заказах
      const ordersData = [];
      console.log("Client-currentUser-ordersIds", ordersIds);
      // Получаем данные по каждому идентификатору заказа
      for (const orderId of ordersIds) {
        const orderData = await fetchDocumentById("orders", orderId);
        if (orderData) {
          ordersData.push(orderData);
        }
      }
      console.log(
        "Client-currentUser-userData-ordersData",
        userData,
        ordersData
      );
      // Возвращаем данные пользователя и его заказы
      return { userData, ordersData };
    } catch (error) {
      // В случае ошибки выбрасываем исключение
      throw error;
    }
  }
);
