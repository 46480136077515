export function isDataStale(lastUpdated) {
  if (!lastUpdated) return true;

  const twelveHoursInMillis = 12 * 60 * 60 * 1000; // 12 часов в миллисекундах
  const currentTime = Date.now();

  // Проверяем, прошло ли больше 12 часов с момента последнего обновления
  const isStale = currentTime - lastUpdated > twelveHoursInMillis;
  console.log(
    `Проверка устаревания: lastUpdated = ${new Date(
      lastUpdated
    ).toISOString()}, текущие время = ${new Date(
      currentTime
    ).toISOString()}, прошло 12 часов = ${isStale ? "да" : "нет"}`
  );

  return isStale;
}
