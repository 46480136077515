const Icon44 = ({ icon, freeze, click }) => {
    return (
        <div
            onClick={click}
            className={freeze ? `icon44freeze` : 'icon44'}>
            {icon}
        </div>
    )
}

export default Icon44