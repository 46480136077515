import React, { useEffect, useState } from "react";
import WigetCollapseComponent from "../UniversalWidgets/WigetCollapseComponent";
import SwitcherOnOff from "../../UIElements/Switcher";
import { useSelector, useDispatch } from "react-redux";
import Preloader from "../../UIElements/Preloader";
import { setSelectedAdditioanlServices } from "../../../slice/stateFrontGUISlice";
import objectIsEmpty from "../../../functions/objectIsEmpty";

const AdditionalServices = () => {
  const dispatch = useDispatch();
  const currentBike = useSelector(
    (state) => state.stateFrontGUISlice.currentBike
  );
  const isLoading = useSelector((state) => state.stateFrontGUISlice.isLoading);
  const [selectedServices, setSelectedServices] = useState([]);

  useEffect(() => {
    // Вызываем экшен для обновления Redux store с новым списком выбранных услуг
    dispatch(setSelectedAdditioanlServices(selectedServices));
  }, [selectedServices, dispatch]);

  // Функция для обработки изменения состояния переключателя
  const handleSwitcherChange = (title, price, isChecked) => {
    if (isChecked) {
      // Если переключатель в положении ON - добавляем услугу в список
      setSelectedServices((prevServices) => [
        ...prevServices,
        { serviceName: title, servicePrice: price },
      ]);
    } else {
      // Если переключатель в положении OFF - удаляем услугу из списка
      setSelectedServices(
        (prevServices) =>
          prevServices.filter((service) => service.serviceName !== title) // Исправлено имя свойства на serviceName
      );
    }
  };

  return (
    <div className="wigetBikes-content-grid-left-content">
      {isLoading ? (
        <div className="wigetProdictCard-in-box">
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Preloader />
          </div>
        </div>
      ) : (
        <>
          {!objectIsEmpty(currentBike?.rentalPoint) && (
            <>
              <div className="wigetProdictCard-in-box">
                <h5>Additional services</h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
                  eleifend ipsum vitae nunc gravida facilisis. Pellentesque
                  finibus, justo id tincidunt accumsan, mauris elit consequat
                  nulla, vel tempus mi lorem vel ipsum. Integer id tincidunt
                  sapien. Cras faucibus euismod massa eget interdum. Sed
                  commodo, ante eu tempor condimentum, elit dui lacinia nisi, in
                  pulvinar enim enim ut orci. Suspendisse potenti.
                </p>
              </div>

              <div>
                {currentBike?.rentalPoint?.additionalServices?.map(
                  (item, index) =>
                    item.title &&
                    item.characteristics && (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                          minHeight: "48px",
                        }}
                      >
                        <WigetCollapseComponent
                          item={
                            item.description.length > 0 ? (
                              <div
                                style={{
                                  padding: "24px",
                                }}
                              >
                                <p style={{ lineHeight: "1.5" }}>
                                  {item.description}
                                </p>
                              </div>
                            ) : null
                          }
                          key={index}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                width: "70%",
                              }}
                            >
                              <p style={{ lineHeight: "1.5" }}>{item.title}</p>
                              <p className="wigetProdictCard-characteristics-bottom">
                                {item.characteristics}
                              </p>
                            </div>

                            <SwitcherOnOff
                              align="left"
                              isChecked={!!selectedServices[item.title]}
                              onChange={(isChecked) =>
                                handleSwitcherChange(
                                  item.title,
                                  item.price,
                                  isChecked
                                )
                              }
                              label={
                                item.price > 0
                                  ? item.price.toLocaleString("en-US", {
                                      style: "currency",
                                      currency: "USD",
                                    })
                                  : "Free"
                              }
                            />
                          </div>
                        </WigetCollapseComponent>
                      </div>
                    )
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};
export default AdditionalServices;
