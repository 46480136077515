import { createSlice } from "@reduxjs/toolkit";
import { updateDirectoryThunk } from "./updateDirectoryThunkV2";
import { fetchDirectoryThunk } from "./fetchDirectoryThunk";
import { deleteDirectoryThunk } from "./deleteDirectoryThunk";
import { recursiveSubscriptionThunk } from "./recursiveSubscriptionThunk";

const getCurrentTimestampInSeconds = () => Math.floor(Date.now() / 1000);

const privateDirectorySlice = createSlice({
  name: "brand/private/privateDirectorySlice",
  initialState: {
    // settings
    titleDirectory: "",
    collectionNameDirectory: "",
    sliceNameDirectory: "",
    fieldNameDirectory: "",
    levelCollections: {},
    data: {},
    // settings
    isLoading: false,
    isLoadingFetching: false,
    isLoadingUpdeiting: false,
    isLoadingDeleting: false,
    error: null,
    // currentFiles: [],
    directories: [],
    selectedDirectory: {},
    newDirectory: {
      created: getCurrentTimestampInSeconds(),
      title: {
        titleOriginal: "",
      },
      description: {
        descriptionOriginal: "",
      },
      files: {
        iconFiles: [],
      },
    },
  },

  reducers: {
    updateDirectoryData: (state, action) => {
      const { collectionName, data } = action.payload;
      state.data[collectionName] = data;
    },

    setTitleDirectory(state, action) {
      state.titleDirectory = action.payload;
    },
    setCollectionNameDirectory(state, action) {
      state.collectionNameDirectory = action.payload;
    },
    setSliceNameDirectory(state, action) {
      state.sliceNameDirectory = action.payload;
    },
    setFieldNameDirectory(state, action) {
      state.fieldNameDirectory = action.payload;
    },
    setLevelCollections(state, action) {
      state.levelCollections = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setDirectories(state, action) {
      state.directories = action.payload;
    },
    // setCurrentFiles(state, action) {
    //   state.currentFiles = action.payload;
    // },
    setSelectedDirectory(state, action) {
      state.selectedDirectory = action.payload;
    },
    setName(state, action) {
      state.selectedDirectory.title = action.payload;
    },
    setDescription(state, action) {
      state.selectedDirectory.description = action.payload;
    },
    setPhotoSelectedDirectroyFiles(state, action) {
      state.newDirectory.files.photoFiles = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(updateDirectoryThunk.pending, (state) => {
        state.isLoadingUpdeiting = true;
        state.error = null;
      })
      .addCase(updateDirectoryThunk.fulfilled, (state, action) => {
        state.isLoadingUpdeiting = false;
        const { documentId, updatedFields } = action.payload;
        const index = state.directories.findIndex(
          (brand) => brand.id === documentId
        );
        if (index !== -1) {
          state.directories[index] = {
            ...state.directories[index],
            ...updatedFields,
          };
        }
      })
      .addCase(updateDirectoryThunk.rejected, (state, action) => {
        state.isLoadingUpdeiting = false;
        state.error = action.payload;
      })
      .addCase(fetchDirectoryThunk.pending, (state) => {
        state.isLoadingFetching = true;
        state.error = null;
      })
      .addCase(fetchDirectoryThunk.fulfilled, (state, action) => {
        state.isLoadingFetching = false;
        if (Array.isArray(action.payload)) {
          state.directories = action.payload; // Обновляем directories с данными из fulfilled
        } else {
          console.error("Payload is not an array:", action.payload);
        }
      })
      .addCase(fetchDirectoryThunk.rejected, (state, action) => {
        state.isLoadingFetching = false;
        // state.error = action.payload.errorMessage;
      })
      .addCase(deleteDirectoryThunk.pending, (state) => {
        state.isLoadingDeleting = true;
        state.error = null;
      })
      .addCase(deleteDirectoryThunk.fulfilled, (state, action) => {
        state.isLoadingDeleting = false;
        const { documentId } = action.payload;
        state.directories = state.directories.filter(
          (brand) => brand.id !== documentId
        );
      })
      .addCase(deleteDirectoryThunk.rejected, (state, action) => {
        state.isLoadingDeleting = false;
        state.error = action.payload.errorMessage;
      })
      .addCase(recursiveSubscriptionThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(recursiveSubscriptionThunk.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(recursiveSubscriptionThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload.errorMessage;
      });
  },
});

export const {
  updateDirectoryData,
  setTitleDirectory,
  setCollectionNameDirectory,
  setSliceNameDirectory,
  setFieldNameDirectory,
  setLevelCollections,
  setError,
  setDirectories,
  // setCurrentFiles,
  setSelectedDirectory,
  setName,
  setDescription,
  setPhotoSelectedDirectroyFiles,
} = privateDirectorySlice.actions;

export const { actions, reducer } = privateDirectorySlice;
export default privateDirectorySlice.reducer;
