// maxWidth: "var(--width-mobile-content)",

import React from "react";

const ButtonsBar = ({ children }) => {
  return (
    <div
      style={{
        width: "100%",
        // height: "80vh",
        left: "0",
        bottom: "0",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-end",
        position: "fixed",
        zIndex: "2",
      }}
    >
      <div
        style={{
          width: "100%",
          minHeight: "64px",
          maxWidth: "var(--width-mobile-content)",
          background: "var(--color-gray-200)",
          padding: "0 24px",
          boxSizing: "border-box",
          borderRadius: "24px 24px 0 0",
          // boxShadow: "0px 0px 24px rgba(0, 0, 0, 0.2)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: "100%",
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default ButtonsBar;
