import React, { useEffect, useState } from "react";
import PopupDirrectory from "../../WidgetsProfile/Popups/PopupDirrectory";
import WindowCountryDetailV4 from "./WindowCountryDetailV4";
import { useDispatch } from "react-redux";
import PriorityImage from "../../WidgetsProfile/PhotosGallery/PriorityImage";
import { setCurrentCountry } from "../../../../../../reducersToolkit/catalogSliceV4";
import { v4 as uuidv4 } from "uuid";

const ContryItem = ({ countryItem }) => {
  const dispatch = useDispatch();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [openWindowCountryDetail, setOpenWindowCountryDetail] = useState(false);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    setTitle(countryItem?.title?.titleOriginal || "No title");
    setDescription(
      countryItem?.description?.descriptionOriginal || "No description"
    );
    setFiles(countryItem?.files?.iconFiles || []);
  }, [countryItem]);

  const handleToggleOpenWindowCountryDetail = (countryItem) => {
    dispatch(setCurrentCountry(countryItem));
    setOpenWindowCountryDetail(true);
  };

  const handleClose = () => {
    setOpenWindowCountryDetail(false);
  };

  return (
    <div>
      <div
        style={{
          backgroundColor: "var(--color-gray-100)",
          padding: "16px 8px 16px 16px ",
          boxSizing: "border-box",
          borderRadius: "16px",
          cursor: "pointer",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "16px",
        }}
        onClick={() => handleToggleOpenWindowCountryDetail(countryItem)}
      >
        <div>
          <PriorityImage
            photos={files}
            style={{
              aspectRatio: "1/1",
              borderRadius: "100%",
              width: "48px",
            }}
          />
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "4px",
          }}
        >
          <p
            style={{
              fontWeight: "600",
              display: "-webkit-box",
              WebkitLineClamp: 1,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {title}
          </p>
          <p
            className="p-light"
            style={{
              color: "var(--color-black)",
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {description}
          </p>
        </div>

        <span className="material-symbols-outlined">chevron_right</span>
      </div>
      <PopupDirrectory
        isOpen={openWindowCountryDetail}
        onClose={handleClose}
        title={"Edit country V4"}
      >
        <WindowCountryDetailV4 mode="edit" onClose={handleClose} />
      </PopupDirrectory>
    </div>
  );
};

const WidgetCountriesListV4 = ({ countries }) => {
  const [sortedCountries, setSortedCountries] = useState([]);

  useEffect(() => {
    setSortedCountries(
      [...countries].sort((a, b) => b.createdat - a.createdat)
    );
  }, [countries]);

  return (
    countries.length > 0 && (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        {sortedCountries?.map((countryItem) => (
          <ContryItem
            key={countryItem.id || uuidv4()} // Используем `key` только здесь, не передавая его внутрь компонента
            countryItem={countryItem}
          />
        ))}
      </div>
    )
  );
};

export default WidgetCountriesListV4;
