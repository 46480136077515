import { createAsyncThunk } from "@reduxjs/toolkit";
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";
import { removeFiles } from "../../../services/firebase/crudFirestore";
import interpretAuthErrorV2 from "../../../functions/interpretAuthErrorV2";

// Функция для удаления элемента из массива объектов bikes
const removeItemFromArray = (array, itemId) => {
  return array.filter((item) => item.id !== itemId);
};

// Создаем асинхронный thunk для удаления объекта bike
export const deleteBikeThunk = createAsyncThunk(
  "bikes/deleteBikeThunk",
  async ({ collectionName, documentId, bikeId }, { rejectWithValue }) => {
    // Проверка валидности имени коллекции
    if (typeof collectionName !== "string" || collectionName.trim() === "") {
      return rejectWithValue("Invalid collection name");
    }

    // Проверка валидности идентификатора документа
    if (typeof documentId !== "string" || documentId.trim() === "") {
      return rejectWithValue("Invalid document ID");
    }

    // Проверка валидности идентификатора bike
    if (typeof bikeId !== "string" || bikeId.trim() === "") {
      return rejectWithValue("Invalid bike ID");
    }

    try {
      const db = getFirestore();
      const docRef = doc(db, collectionName, documentId);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
        throw new Error("Document does not exist");
      }

      const data = docSnap.data();
      const bike = data.bikes.find((bike) => bike.id === bikeId);

      if (!bike) {
        throw new Error("Bike not found");
      }

      // Удаляем файлы, связанные с объектом bike
      if (bike.files) {
        for (const field in bike.files) {
          if (
            Array.isArray(bike.files[field]) &&
            bike.files[field].length > 0
          ) {
            await removeFiles(
              collectionName,
              documentId,
              field,
              bike.files[field]
            );
          }
        }
      }

      // Обновляем массив объектов bikes
      const updatedBikes = removeItemFromArray(data.bikes, bikeId);

      // Обновляем документ
      await updateDoc(docRef, { bikes: updatedBikes });

      console.log("Bike successfully deleted!");

      // Возвращаем идентификатор удаленного объекта bike
      return { bikeId };
    } catch (error) {
      // Интерпретируем и возвращаем ошибку
      const errorMessage = interpretAuthErrorV2(error.code);
      return rejectWithValue({ errorMessage });
    }
  }
);
