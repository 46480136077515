import { createSlice } from "@reduxjs/toolkit";
import { fetchDirectoriesThunkV2 } from "./thunk/directories/fetchDirectoriesThunkV2";
import { addDirectoryCountryThunkV2 } from "./thunk/directories/addDirectoryCountryThunkV2";
import {
  updateDirectoryCityThunkV2,
  updateDirectoryCountryThunkV2,
} from "./thunk/directories/updateDirectoryThunkV2";
import { addDirectoryCityThunkV2 } from "./thunk/directories/addDirectoryCityThunkV2";
import { fetchAllDataThunkV2 } from "./thunk/catalog/fetchAllDataThunkV2";
import { deleteCityThunkV2 } from "./thunk/directories/deleteCityThunkV2";
import { deleteCouintryThunkV2 } from "./thunk/directories/deleteCouintryThunkV2";

const directorySliceV2 = createSlice({
  name: "reducersToolkit/directorySliceV2",
  initialState: {
    error: null,
    isLoading: false,

    bikes: [],
    currentBikes: {},

    rentalPoints: [],
    currentRentalPoints: {},

    countries: [],
    curentCountry: {},
    deletingCountryIsLoading: false,
    addingCountryIsLoading: false,

    cities: [],
    currentCity: {},
    cityDeletingIsLoading: false,
    addingCityIsLoading: false,

    brands: [],
    currentBrand: {},

    categories: [],
    currentCategory: {},

    lastUpdated: null,
  },
  reducers: {
    setLastUpdated: (state) => {
      state.lastUpdated = new Date().getTime();
    },
    setCurrentCountry: (state, action) => {
      state.curentCountry = action.payload;
    },
    setCurrentBrand: (state, action) => {
      state.currentBrand = action.payload;
    },
    setCurrentCategory: (state, action) => {
      state.currentCategory = action.payload;
    },
    setCurrentCity: (state, action) => {
      state.currentCity = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllDataThunkV2.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchAllDataThunkV2.fulfilled, (state, action) => {
        const { bikes, rentalPoints, cities, countries, brands, categories } =
          action.payload || {};

        state.bikes = bikes || [];
        state.rentalPoints = rentalPoints || [];
        state.cities = cities || [];
        state.countries = countries || [];
        state.brands = brands || [];
        state.categories = categories || [];
        state.isLoading = false;
      })
      .addCase(fetchAllDataThunkV2.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload?.errorMessage || "Failed to fetch data";
      })
      .addCase(fetchDirectoriesThunkV2.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchDirectoriesThunkV2.fulfilled, (state, action) => {
        state.isLoading = false;
        state.countries = action.payload.countries;
        state.cities = action.payload.cities;
        state.brands = action.payload.brands;
        state.categories = action.payload.categories;
      })
      .addCase(fetchDirectoriesThunkV2.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload.errorMessage;
      })
      .addCase(addDirectoryCountryThunkV2.pending, (state) => {
        state.addingCountryIsLoading = true;
      })
      .addCase(addDirectoryCountryThunkV2.fulfilled, (state, action) => {
        state.addingCountryIsLoading = false;
        state.countries.push(action.payload);
      })
      .addCase(addDirectoryCountryThunkV2.rejected, (state, action) => {
        state.addingCountryIsLoading = false;
        state.error = action.payload;
      })
      .addCase(updateDirectoryCountryThunkV2.pending, (state) => {
        state.addingCountryIsLoading = true;
      })
      .addCase(updateDirectoryCountryThunkV2.fulfilled, (state, action) => {
        state.addingCountryIsLoading = false;
        const updatedCountry = action.payload.updatedFields;
        state.countries = state.countries.map((country) =>
          country.id === updatedCountry.id ? updatedCountry : country
        );
      })
      .addCase(updateDirectoryCountryThunkV2.rejected, (state, action) => {
        state.addingCountryIsLoading = false;
        state.error = action.payload;
      })

      .addCase(updateDirectoryCityThunkV2.pending, (state) => {
        state.addingCityIsLoading = true;
      })
      .addCase(updateDirectoryCityThunkV2.fulfilled, (state, action) => {
        state.addingCityIsLoading = false;
        const updatedCity = action.payload.updatedFields;
        state.cities = state.cities.map((city) =>
          city.id === updatedCity.id ? updatedCity : city
        );
      })
      .addCase(updateDirectoryCityThunkV2.rejected, (state, action) => {
        state.addingCityIsLoading = false;
        state.error = action.payload;
      })

      .addCase(addDirectoryCityThunkV2.pending, (state) => {
        state.addingCityIsLoading = true;
      })
      .addCase(addDirectoryCityThunkV2.fulfilled, (state, action) => {
        state.addingCityIsLoading = false;
        const { addedDocument, documentId } = action.payload;

        // Добавляем новый город в список городов
        state.cities.push(addedDocument);

        if (state.curentCountry) {
          // Добавляем новый cityId в массив citiesIds
          state.curentCountry.citiesIds.push(addedDocument.id);
        }

        // Находим страну с соответствующим documentId
        const country = state.countries.find(
          (country) => country.id === documentId
        );

        if (country) {
          // Убедимся, что citiesIds существует и является массивом
          if (!Array.isArray(country.citiesIds)) {
            country.citiesIds = [];
          }

          // Добавляем новый cityId в массив citiesIds
          country.citiesIds.push(addedDocument.id);
        }
      })
      .addCase(addDirectoryCityThunkV2.rejected, (state, action) => {
        state.addingCityIsLoading = false;
        state.error = action.payload;
      })
      // Обновление состояния после успешного выполнения deleteCityThunkV2
      .addCase(deleteCityThunkV2.pending, (state) => {
        state.cityDeletingIsLoading = true;
      })
      .addCase(deleteCityThunkV2.fulfilled, (state, action) => {
        state.cityDeletingIsLoading = false;
        const { cityId } = action.payload;

        // Удаляем город из списка городов
        state.cities = state.cities.filter((city) => city.id !== cityId);

        // Удаляем город из citiesIds соответствующей страны
        state.countries = state.countries.map((country) => {
          if (Array.isArray(country.citiesIds)) {
            country.citiesIds = country.citiesIds.filter((id) => id !== cityId);
          }
          return country;
        });
      })
      .addCase(deleteCityThunkV2.rejected, (state, action) => {
        state.cityDeletingIsLoading = false;
        state.error = action.payload;
      })
      // Обновление состояния после успешного выполнения deleteCouintryThunkV2
      .addCase(deleteCouintryThunkV2.pending, (state) => {
        state.deletingCountryIsLoading = true;
      })
      .addCase(deleteCouintryThunkV2.fulfilled, (state, action) => {
        state.deletingCountryIsLoading = false;
        const { countryId } = action.payload;

        // Удаляем страну из списка стран
        state.countries = state.countries.filter(
          (country) => country.id !== countryId
        );

        // Если удаляемая страна была выбрана как текущая, сбрасываем currentCountry
        if (state.curentCountry?.id === countryId) {
          state.curentCountry = {};
        }

        // Удаляем города, связанные с этой страной, из списка городов
        if (state.curentCountry.citiesIds) {
          state.cities = state.cities.filter(
            (city) => !state.curentCountry.citiesIds.includes(city.id)
          );
        }
      })
      .addCase(deleteCouintryThunkV2.rejected, (state, action) => {
        state.deletingCountryIsLoading = false;
        state.error = action.payload;
      });
  },
});

export const {
  setCurrentCountry,
  setCurrentBrand,
  setCurrentCategory,
  setCurrentCity,
} = directorySliceV2.actions;
export const { actions, reducer } = directorySliceV2;
export default directorySliceV2.reducer;
