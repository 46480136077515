import React, { useEffect, useState } from "react";
import "../profile.css";
import PopupDirrectory from "../WidgetsProfile/Popups/PopupDirrectory";
import { useDispatch, useSelector } from "react-redux";
import WindowUploadFiles from "../DirectoryV3/Directory/WindowUploadFiles";
import WidgetListFileV4 from "../WidgetsProfile/UploadFiles/WidgetListFileV4";
import WidgetUploadFilesV4 from "../WidgetsProfile/UploadFiles/WindgetUploadFilesV4";
import { setFiles } from "../../../../../reducersToolkit/rentalPointsSliiceV4";

// const ItemImageDirrect = ({ icon, title, onClick }) => {
//   return (
//     <div
//       onClick={onClick}
//       style={{
//         height: "148px",
//         aspectRatio: "3/2",
//         borderRadius: "16px",
//         backgroundColor: "var(--color-gray-100)",
//         cursor: "pointer",
//         position: "relative",
//         display: "flex",
//         alignItems: "flex-start",
//         justifyContent: "start",
//         padding: "16px",
//         boxSizing: "border-box",
//       }}
//     >
//       {icon}
//       <div
//         style={{
//           position: "absolute",
//           bottom: "16px",
//           left: "16px",
//           right: "16px",
//         }}
//       >
//         <p className="p-light">{title}</p>
//       </div>
//     </div>
//   );
// };

const WidgetRentalPointFilesBarV4 = ({ mode, files, setPreviewFiles }) => {
  const dispatch = useDispatch();

  const [countryId, setCountryId] = useState(0);

  const [photoFiles, setPhotoFiles] = useState([]);
  const [photoFilesPreview, setPhotoFilesPreview] = useState([]);

  const [licenseFile, setLicenseFile] = useState([]);
  const [licenseFilePreview, setLicenseFilePreview] = useState([]);

  const [logoFile, setLogoFile] = useState([]);
  const [logoFilePreview, setLogoFilePreview] = useState([]);

  console.log(
    "WidgetRentalPointFilesBarV4 photoFilesPreview",
    photoFilesPreview
  );

  useEffect(() => {
    setPhotoFiles(files?.photoFiles || []);
    setLicenseFile(files?.licenseFile || []);
    setLogoFile(files?.logoFile || []);
  }, [files]);

  useEffect(() => {
    setPreviewFiles({
      photoFiles: photoFilesPreview,
      licenseFile: licenseFilePreview,
      logoFile: logoFilePreview,
    });
  }, [setPreviewFiles, logoFilePreview, licenseFilePreview, photoFilesPreview]);

  return (
    <>
      <WidgetListFileV4
        // isLoading={loadingPriority}
        collectionName="catalog"
        files={photoFiles}
        documentId={countryId}
        fieldName="files.iconFiles"
        mode="edit"
      />

      <WidgetUploadFilesV4
        id="setPhotoFilesPreview"
        title="Add photo rental point"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
        files={setPhotoFilesPreview}
      />

      <WidgetListFileV4
        // isLoading={loadingPriority}
        collectionName="catalog"
        files={licenseFile}
        documentId={countryId}
        fieldName="files.iconFiles"
        mode="edit"
      />

      <WidgetUploadFilesV4
        id="setLogoFilePreview"
        title="Add logo file"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
        files={setLogoFilePreview}
      />

      <WidgetListFileV4
        // isLoading={loadingPriority}
        collectionName="catalog"
        files={logoFile}
        documentId={countryId}
        fieldName="files.iconFiles"
        mode="edit"
      />

      <WidgetUploadFilesV4
        id="setLicenseFilePreview"
        title="Add license file"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
        files={setLicenseFilePreview}
      />
    </>
  );
};

export default WidgetRentalPointFilesBarV4;
