import { createAsyncThunk } from "@reduxjs/toolkit";
import { getFirestore, doc, getDoc, deleteDoc } from "firebase/firestore";
import { removeFiles } from "../../../services/firebase/crudFirestore";
import interpretAuthErrorV2 from "../../../functions/interpretAuthErrorV2";

export const deleteCouintryThunkV2 = createAsyncThunk(
  "directories/deleteCouintryThunkV2",
  async ({ countryId }, { rejectWithValue }) => {
    try {
      const db = getFirestore();

      // Получаем документ страны
      const countryRef = doc(db, "country", countryId);
      const countrySnap = await getDoc(countryRef);

      if (!countrySnap.exists()) {
        throw new Error("Country document does not exist");
      }

      const countryData = countrySnap.data();
      const citiesIds = countryData.citiesIds || [];

      // Получаем документы городов и удаляем их файлы
      for (const cityId of citiesIds) {
        const cityRef = doc(db, "city", cityId);
        const citySnap = await getDoc(cityRef);

        if (citySnap.exists()) {
          const cityData = citySnap.data();

          // Удаляем файлы, связанные с городом
          if (cityData.files) {
            const removeNestedFiles = async (basePath, data) => {
              for (const key in data) {
                const value = data[key];
                const fullPath = basePath ? `${basePath}.${key}` : key;

                if (Array.isArray(value) && value.length > 0) {
                  // Удаляем файлы, используя функцию removeFiles
                  await removeFiles(
                    "city",
                    cityId,
                    fullPath,
                    value.map((file) => file.id)
                  );
                } else if (typeof value === "object" && value !== null) {
                  await removeNestedFiles(fullPath, value);
                }
              }
            };

            await removeNestedFiles("files", cityData.files);
          }

          // Повторная проверка, чтобы убедиться, что все файлы удалены
          const citySnapAfterRemoval = await getDoc(cityRef);
          const cityDataAfterRemoval = citySnapAfterRemoval.data();

          let allFilesCleared = true;
          if (cityDataAfterRemoval?.files) {
            for (const key in cityDataAfterRemoval.files) {
              if (
                Array.isArray(cityDataAfterRemoval.files[key]) &&
                cityDataAfterRemoval.files[key].length > 0
              ) {
                allFilesCleared = false;
                break;
              }
            }
          }

          // Удаляем документ города, только если все файлы действительно были удалены
          if (allFilesCleared) {
            await deleteDoc(cityRef);
            console.log(`City with ID ${cityId} successfully deleted!`);
          } else {
            throw new Error(
              `Not all files were deleted for city with ID ${cityId}.`
            );
          }
        }
      }

      // Удаляем файлы, связанные с документом страны
      if (countryData.files) {
        const removeNestedFiles = async (basePath, data) => {
          for (const key in data) {
            const value = data[key];
            const fullPath = basePath ? `${basePath}.${key}` : key;

            if (Array.isArray(value) && value.length > 0) {
              // Удаляем файлы, используя функцию removeFiles
              await removeFiles(
                "country",
                countryId,
                fullPath,
                value.map((file) => file.id)
              );
            } else if (typeof value === "object" && value !== null) {
              await removeNestedFiles(fullPath, value);
            }
          }
        };

        await removeNestedFiles("files", countryData.files);
      }

      // Повторная проверка, чтобы убедиться, что все файлы удалены из документа страны
      const countrySnapAfterRemoval = await getDoc(countryRef);
      const countryDataAfterRemoval = countrySnapAfterRemoval.data();

      let allCountryFilesCleared = true;
      if (countryDataAfterRemoval?.files) {
        for (const key in countryDataAfterRemoval.files) {
          if (
            Array.isArray(countryDataAfterRemoval.files[key]) &&
            countryDataAfterRemoval.files[key].length > 0
          ) {
            allCountryFilesCleared = false;
            break;
          }
        }
      }

      // Удаляем документ страны, только если все файлы действительно были удалены
      if (allCountryFilesCleared) {
        await deleteDoc(countryRef);
        console.log("Country and its cities successfully deleted!");
      } else {
        throw new Error("Not all files were deleted for the country.");
      }

      return { countryId };
    } catch (error) {
      const errorMessage = interpretAuthErrorV2(error.code);
      return rejectWithValue({ errorMessage });
    }
  }
);
