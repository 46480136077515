import { createAsyncThunk } from "@reduxjs/toolkit";
import { updateDocumentFields } from "../../../services/firebase/crudFirestore";
import interpretAuthErrorV2 from "../../../functions/interpretAuthErrorV2";

export const updateDirectoryCountryThunkV2 = createAsyncThunk(
  "directories/updateDirectoryThunkV2",
  async (
    { collectionName, documentId, updatedFields },
    { rejectWithValue }
  ) => {
    try {
      await updateDocumentFields(collectionName, documentId, updatedFields);
      return { collectionName, documentId, updatedFields };
    } catch (error) {
      const errorMessage = interpretAuthErrorV2(error.code);
      return rejectWithValue({ errorMessage });
    }
  }
);

export const updateDirectoryCityThunkV2 = createAsyncThunk(
  "directories/updateDirectoryCityThunkV2",
  async (
    { collectionName, documentId, updatedFields },
    { rejectWithValue }
  ) => {
    try {
      await updateDocumentFields(collectionName, documentId, updatedFields);
      return { collectionName, documentId, updatedFields };
    } catch (error) {
      const errorMessage = interpretAuthErrorV2(error.code);
      return rejectWithValue({ errorMessage });
    }
  }
);
