import React, { useEffect, useState } from "react";
import AddData from "../WidgetsProfile/AddData/AddData";
import WidgetListFile from "../WidgetsProfile/UploadFiles/WidgetListFile";
import PopupDirrectory from "../WidgetsProfile/Popups/PopupDirrectory";
import { subscribeToDocument } from "../../../../../services/firebase/crudFirestore";
import WindowUploadFiles from "../DirectoryV3/Directory/WindowUploadFiles";

const WidgetPhotosBike = ({ mode, selectedBike }) => {
  const [photoFiles, setPhotoFiles] = useState([]);
  const [isOpenWindgetUploadFiles, setIsOpenWindgetUploadFiles] =
    useState(false);

  useEffect(() => {
    if (mode === "edit" || mode === "moderation") {
      const unsubscribe = subscribeToDocument(
        "bikes",
        selectedBike?.id,
        null,
        async (data) => {
          setPhotoFiles(data?.files?.bikePhotoFiles);
        }
      );
      return () => {
        console.log("unsubscribe ", "rentalPoints");
        unsubscribe();
      };
    }
  }, [selectedBike, mode]);

  const handleToggle = () => {
    setIsOpenWindgetUploadFiles(!isOpenWindgetUploadFiles);
  };

  const handleClose = () => {
    setIsOpenWindgetUploadFiles(false);
  };

  return (
    <>
      <AddData
        onClick={handleToggle}
        icon={
          photoFiles?.length > 0 ? (
            <span className="material-symbols-outlined">edit</span>
          ) : (
            <span className="material-symbols-outlined">add</span>
          )
        }
        title={photoFiles?.length > 0 ? "Edit files" : "Add files"}
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
      />
      {photoFiles?.length > 0 && (
        <WidgetListFile
          collectionName="bikes"
          documentId={selectedBike.id}
          fieldName={"files.bikePhotoFiles"}
          files={photoFiles}
        />
      )}

      <PopupDirrectory
        isOpen={isOpenWindgetUploadFiles}
        onClose={handleClose}
        title={photoFiles?.length > 0 ? `Edit files ` : `Add files `}
      >
        <WindowUploadFiles
          collectionName="bikes"
          documentId={selectedBike.id}
          fieldName={"files.bikePhotoFiles"}
        />
      </PopupDirrectory>
    </>
  );
};

export default WidgetPhotosBike;
