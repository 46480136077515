import React, { useEffect, useState } from "react";
import "./popup.css";
import PopupAllert from "./PopupAllert";
import { all } from "axios";
const PopupDirrectory = ({ isOpen, onClose, children, title, allert }) => {
  const [isOpenAllertWindow, setIsOpenAllertWindow] = useState(false);

  const handleClose = () => {
    setIsOpenAllertWindow(false);
  };
  const handleToggle = () => {
    if (allert) {
      setIsOpenAllertWindow(true);
    } else {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <div className="popup__content-box">
        <div className="popup__content">
          <div className="popup__header">
            <h5 style={{ textTransform: "capitalize" }}>{title}</h5>

            <div className="popup__button-bar">
              <div className="popup__button--close" onClick={handleToggle}>
                <span className="material-symbols-outlined">close</span>
              </div>
            </div>
          </div>

          <div className="popup__options">{children}</div>
        </div>
      </div>

      <PopupAllert
        onConfirm={onClose}
        isOpen={isOpenAllertWindow}
        onClose={handleClose}
        title="Attention!"
      >
        {allert}
      </PopupAllert>
    </>
  );
};

export default PopupDirrectory;
