import { createAsyncThunk } from "@reduxjs/toolkit";
import interpretAuthErrorV2 from "../../../functions/interpretAuthErrorV2";

export const fetchCurrentBikeDataThunkV2 = createAsyncThunk(
  "catalog/fetchCurrentBikeDataThunkV2",
  async ({ idCurrentBike }, { getState, rejectWithValue }) => {
    try {
      // Получаем данные из state вместо повторного запроса из Firestore
      const state = getState();
      const rentalPoints = state.directorySliceV2.rentalPoints;
      const cities = state.directorySliceV2.cities;
      const countries = state.directorySliceV2.countries;
      const bikes = state.directorySliceV2.bikes;
      const brands = state.directorySliceV2.brands;
      const categories = state.directorySliceV2.categories;

      const cityMap = cities.reduce((acc, city) => {
        acc[city.id] = city;
        return acc;
      }, {});

      const countryMap = countries.reduce((acc, country) => {
        acc[country.id] = country;
        return acc;
      }, {});

      const brandMap = brands.reduce((acc, brand) => {
        acc[brand.id] = brand;
        return acc;
      }, {});

      const categoryMap = categories.reduce((acc, category) => {
        acc[category.id] = category;
        return acc;
      }, {});

      let rentalPointsWithDetails = rentalPoints.map((point) => {
        const cityId = point.location?.cityid || "";
        const countryId = point.location?.countryId || "";

        return {
          ...point,
          location: {
            ...point.location,
            city: cityMap[cityId] || {},
            country: countryMap[countryId] || {},
          },
          bikes: [], // Инициализируем пустой массив для bikes
        };
      });

      let bikesWithDetails = bikes.map((bike) => {
        const brandId = bike.directory?.brandId || "";
        const categoryId = bike.directory?.categoryId || "";

        return {
          ...bike,
          directory: {
            ...bike.directory,
            brand: brandMap[brandId] || {},
            category: categoryMap[categoryId] || {},
          },
          rentalPoint: null, // Инициализируем объект для rentalPoint
        };
      });

      rentalPointsWithDetails.forEach((point) => {
        point.bikesIds?.forEach((bikeId) => {
          const bike = bikesWithDetails.find((bike) => bike.id === bikeId);
          if (bike) {
            point.bikes.push(bike);
            bike.rentalPoint = {
              ...point,
              bikes: undefined, // Удаляем циклическую ссылку на велосипеды в rentalPoint
            };
          }
        });
      });

      bikesWithDetails = bikesWithDetails.filter(
        (bike) => bike.rentalPoint !== null
      );

      rentalPointsWithDetails = rentalPointsWithDetails.filter(
        (point) => point.bikes.length > 0
      );

      // Находим велосипед с указанным idCurrentBike
      const currentBike = bikesWithDetails.find(
        (bike) => bike.id === idCurrentBike
      );

      if (!currentBike) {
        throw new Error("Bike not found");
      }

      return currentBike;
    } catch (error) {
      const errorMessage = interpretAuthErrorV2(error.code);
      return rejectWithValue({ errorMessage });
    }
  }
);

// import { createAsyncThunk } from "@reduxjs/toolkit";
// import { fetchDocumentsByIds } from "../../../services/firebase/crudFirestore";
// import interpretAuthErrorV2 from "../../../functions/interpretAuthErrorV2";

// export const fetchCurrentBikeDataThunkV2 = createAsyncThunk(
//   "catalog/fetchCurrentBikeDataThunkV2",
//   async ({ idCurrentBike }, { rejectWithValue }) => {
//     try {
//       const rentalPoints = await fetchDocumentsByIds("rentalPoints");
//       const cities = await fetchDocumentsByIds("city");
//       const countries = await fetchDocumentsByIds("country");
//       const bikes = await fetchDocumentsByIds("bikes");
//       const brands = await fetchDocumentsByIds("brand");
//       const categories = await fetchDocumentsByIds("category");

//       const cityMap = cities.reduce((acc, city) => {
//         acc[city.id] = city;
//         return acc;
//       }, {});

//       const countryMap = countries.reduce((acc, country) => {
//         acc[country.id] = country;
//         return acc;
//       }, {});

//       const brandMap = brands.reduce((acc, brand) => {
//         acc[brand.id] = brand;
//         return acc;
//       }, {});

//       const categoryMap = categories.reduce((acc, category) => {
//         acc[category.id] = category;
//         return acc;
//       }, {});

//       let rentalPointsWithDetails = rentalPoints.map((point) => {
//         const cityId = point.location?.cityid || "";
//         const countryId = point.location?.countryId || "";

//         return {
//           ...point,
//           location: {
//             ...point.location,
//             city: cityMap[cityId] || {},
//             country: countryMap[countryId] || {},
//           },
//           bikes: [], // Инициализируем пустой массив для bikes
//         };
//       });

//       let bikesWithDetails = bikes.map((bike) => {
//         const brandId = bike.directory?.brandId || "";
//         const categoryId = bike.directory?.categoryId || "";

//         return {
//           ...bike,
//           directory: {
//             ...bike.directory,
//             brand: brandMap[brandId] || {},
//             category: categoryMap[categoryId] || {},
//           },
//           rentalPoint: null, // Инициализируем объект для rentalPoint
//         };
//       });

//       rentalPointsWithDetails.forEach((point) => {
//         point.bikesIds?.forEach((bikeId) => {
//           const bike = bikesWithDetails.find((bike) => bike.id === bikeId);
//           if (bike) {
//             point.bikes.push(bike);
//             bike.rentalPoint = {
//               ...point,
//               bikes: undefined, // Удаляем циклическую ссылку на велосипеды в rentalPoint
//             };
//           }
//         });
//       });

//       bikesWithDetails = bikesWithDetails.filter(
//         (bike) => bike.rentalPoint !== null
//       );

//       rentalPointsWithDetails = rentalPointsWithDetails.filter(
//         (point) => point.bikes.length > 0
//       );

//       // Находим велосипед с указанным idCurrentBike
//       const currentBike = bikesWithDetails.find(
//         (bike) => bike.id === idCurrentBike
//       );

//       if (!currentBike) {
//         throw new Error("Bike not found");
//       }
//       console.log(
//         "catalog/fetchCurrentBikeDataThunkV2 currentBike",
//         currentBike
//       );
//       return currentBike;
//     } catch (error) {
//       const errorMessage = interpretAuthErrorV2(error.code);
//       return rejectWithValue({ errorMessage });
//     }
//   }
// );
