import { createSlice } from "@reduxjs/toolkit";

const stateGUISlice = createSlice({
  name: "stateGUISlice",
  initialState: {
    currentUsersRentalPoints: [],
    currentRentalPoint: {},
    currentOrder: {},
    allOrders: true,
    newAddress: {
      address: null,
      location: {
        lat: 0,
        lng: 0,
      },
      city: null,
      country: null,
      countryShortCode: null,
    },
    privateSideBar: false,
    publickSidebar: true,
    currentSideBarItem: "/rentalPoints",
    sideBarMenu: [
      {
        title: "Current orders",
        chapter: "/currentOrders",
        icon: "location_on",
        allowedRoles: ["rental", "admin", "client"],
      },
      {
        title: "Rental Point",
        chapter: "/rentalPoints",
        icon: "location_on",
        allowedRoles: ["rental", "admin"],
      },
      {
        title: "Bikes",
        chapter: "/bikes",
        icon: "directions_bike",
        allowedRoles: ["rental", "admin"],
      },
      {
        title: "Orders",
        chapter: "/orders",
        icon: "shopping_bag",
        allowedRoles: ["rental", "admin"],
      },
      {
        title: "Chatbot GPT",
        chapter: "/chatbotGPT",
        icon: "chat",
        allowedRoles: ["rental", "admin"],
      },
      {
        title: "Generate JSON",
        chapter: "/generateJSON",
        icon: "database",
        allowedRoles: ["rental", "admin"],
      },
      {
        title: "Translater",
        chapter: "/translater",
        icon: "translate",
        allowedRoles: ["rental", "admin"],
      },

      {
        title: "Directory",
        allowedRoles: ["admin"],
        items: [
          {
            title: "Brand",
            chapter: "/brand",
            icon: "category",
            allowedRoles: ["admin"],
          },
          {
            title: "Currency",
            chapter: "/currency",
            icon: "category",
            allowedRoles: ["admin"],
          },
          {
            title: "Сategory",
            chapter: "/category",
            icon: "category",
            allowedRoles: ["admin"],
          },
          {
            title: "Country",
            chapter: "/country",
            icon: "category",
            allowedRoles: ["admin"],
          },
          {
            title: "City",
            chapter: "/city",
            icon: "category",
            allowedRoles: ["admin"],
          },
        ],
      },
      {
        title: "SEO",
        allowedRoles: ["admin"],
        items: [
          {
            title: "FAQ",
            chapter: "/faq",
            icon: "category",
            allowedRoles: ["admin"],
          },
          {
            title: "News",
            chapter: "/editNews",
            icon: "category",
            allowedRoles: ["admin"],
          },
          {
            title: "Stories",
            chapter: "/stories",
            icon: "category",
            allowedRoles: ["admin"],
          },
        ],
      },
    ],
    loadingStateGoogleMapApi: false,
    currentBike: {},
    currentSlug: "",
    systemMessageForGPT: null,
    // public data
    dateIntervalFilter: [],
    quantityBikeFilter: 1,
    countryIdFilter: "",
    cityIdFilter: "",
    rangePriceFilter: [],
    brandIdFilter: "",
    categoryIdFilter: "",
    bikesFilterList: [],
    currentRentalPointsList: [],
    currentRentalPointItem: {},
    currentOrderBike: {},
  },

  reducers: {
    setCurrentUsersRentalPoints: (state, action) => {
      state.currentUsersRentalPoints = action.payload;
    },

    setCurrentRentalPoint: (state, action) => {
      state.currentRentalPoint = action.payload;
    },

    setCurrentOrder: (state, action) => {
      state.currentOrder = action.payload;
    },

    setAllOrders: (state, action) => {
      state.allOrders = action.payload;
    },

    setNewAddress: (state, action) => {
      state.newAddress.address = action.payload;
    },

    setLocation: (state, action) => {
      state.newAddress.location = action.payload;
    },

    setPrivateSideBar: (state, action) => {
      state.privateSideBar = action.payload;
    },

    setPublickSidebar: (state, action) => {
      state.publickSidebar = action.payload;
    },

    setCurrentSideBarItem: (state, action) => {
      state.currentSideBarItem = action.payload;
    },

    setSideBarMenu: (state, action) => {
      state.sideBarMenu = action.payload;
    },

    setLoadingStateGoogleMapApi: (state, action) => {
      state.loadingStateGoogleMapApi = action.payload;
    },

    setCurrentBike: (state, action) => {
      state.currentBike = action.payload;
    },

    setCurrentSlug: (state, action) => {
      state.currentSlug = action.payload;
    },

    setSystemMessageForGPT: (state, action) => {
      state.systemMessageForGPT = action.payload;
    },
    // public data
    setDateIntervalFilter: (state, action) => {
      state.dateIntervalFilter = action.payload;
    },

    setQuantityBikeFilter: (state, action) => {
      state.quantityBikeFilter = action.payload;
    },

    setCountryIdFilter: (state, action) => {
      state.countryIdFilter = action.payload;
    },

    setCityIdFilter: (state, action) => {
      state.cityIdFilter = action.payload;
    },

    setRangePriceFilter: (state, action) => {
      state.rangePriceFilter = action.payload;
    },

    setBrandIdFilter: (state, action) => {
      state.brandIdFilter = action.payload;
    },

    setCategoryIdFilter: (state, action) => {
      state.categoryIdFilter = action.payload;
    },

    setBikesFilterList: (state, action) => {
      state.bikesFilterList = action.payload;
    },

    setCurrentRentalPointsList: (state, action) => {
      state.currentRentalPointsList = action.payload;
    },

    setCurrentRentalPointItem: (state, action) => {
      state.currentRentalPointItem = action.payload;
    },

    setCurrentOrderBike: (state, action) => {
      state.currentOrderBike = action.payload;
    },
  },
});

export const {
  setCurrentUsersRentalPoints,
  setCurrentRentalPoint,
  setCurrentOrder,
  setAllOrders,
  setNewAddress,
  setLocation,
  setPrivateSideBar,
  setPublickSidebar,
  setCurrentSideBarItem,
  setSideBarMenu,
  setLoadingStateGoogleMapApi,
  setCurrentBike,
  setCurrentSlug,
  setSystemMessageForGPT,
  // public data
  setDateIntervalFilter,
  setQuantityBikeFilter,
  setCountryIdFilter,
  setCityIdFilter,
  setRangePriceFilter,
  setBrandIdFilter,
  setCategoryIdFilter,
  setBikesFilterList,
  setCurrentRentalPointsList,
  setCurrentRentalPointItem,
  setCurrentOrderBike,
} = stateGUISlice.actions;

export default stateGUISlice.reducer;
