import { createAsyncThunk } from "@reduxjs/toolkit";
import { getFirestore, doc, getDoc, deleteDoc } from "firebase/firestore";
import {
  deleteData,
  removeFiles,
} from "../../../../services/firebase/crudFirestore";

export const deleteCountryThunk = createAsyncThunk(
  "directory/country/deleteCountryThunk",
  async (selectedCountry, { rejectWithValue }) => {
    const { city: cities, id: countryId } = selectedCountry;

    if (!Array.isArray(cities) || cities.length === 0) {
      return rejectWithValue("Invalid cities array");
    }

    try {
      const db = getFirestore();
      const collectionName = "city";

      // Получаем все документы из коллекции city по city.id
      const cityDocs = await Promise.all(
        cities.map(async (city) => {
          const docRef = doc(db, collectionName, city.id);
          const docSnap = await getDoc(docRef);
          return { id: city.id, data: docSnap.data() };
        })
      );

      // Удаляем файлы из каждого документа
      for (const { id, data } of cityDocs) {
        if (data && data.files) {
          const fileFields = Object.keys(data.files).filter((key) =>
            key.endsWith("Files")
          );

          for (const field of fileFields) {
            const fileIds = data.files[field].map((file) => file.id);
            await removeFiles(collectionName, id, `files.${field}`, fileIds);
          }
        }
      }

      // Удаляем документы из коллекции city
      await Promise.all(
        cities.map((city) => deleteData(collectionName, city.id))
      );

      // Удаляем документ из коллекции country
      const countryDocRef = doc(db, "country", countryId);
      await deleteDoc(countryDocRef);

      return { cities, countryId }; // Возвращаем удаленные cities и countryId
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
