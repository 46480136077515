import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ContentBox from "../../WidgetsProfile/ContentBox/ContentBox";
import InputText from "../../WidgetsProfile/Inputs/InputText";
import Textarea from "../../WidgetsProfile/Textarea/Textarea";
import AddData from "../../WidgetsProfile/AddData/AddData";
import WidgetListFile from "../../WidgetsProfile/UploadFiles/WidgetListFile";
import Button from "../../WidgetsProfile/Buttons/Button";
import PopupDirrectory from "../../WidgetsProfile/Popups/PopupDirrectory";
import WindowUploadFiles from "../Directory/WindowUploadFiles";
import { addDirectoryCityThunkV2 } from "../../../../../../reducersToolkit/thunk/directories/addDirectoryCityThunkV2";
import { updateDirectoryCityThunkV2 } from "../../../../../../reducersToolkit/thunk/directories/updateDirectoryThunkV2";
import { deleteCityThunkV2 } from "../../../../../../reducersToolkit/thunk/directories/deleteCityThunkV2";
import ButtonsBar from "../../WidgetsProfile/ButtonsBar/ButtonsBar";
import WidgetAlert from "../../WidgetsProfile/Alerts/WidgetAlert";
import { createCityThunkV4 } from "../../../../../../reducersToolkit/thunk/catalog/V4/createCityThunkV4";
import { v4 as uuidv4 } from "uuid";
import WidgetUploadFilesV4 from "../../WidgetsProfile/UploadFiles/WindgetUploadFilesV4";
import WidgetListFileV4 from "../../WidgetsProfile/UploadFiles/WidgetListFileV4";
import { updateCityThunkV4 } from "../../../../../../reducersToolkit/thunk/catalog/V4/updateCityThunkV4";
import { deleteCityThunkV4 } from "../../../../../../reducersToolkit/thunk/catalog/V4/deleteCityThunkV4";

const WindowCityDetailV4 = ({ mode, onClose }) => {
  const dispatch = useDispatch();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [photoFiles, setPhotoFiles] = useState([]);
  const [previewPhotoFiles, setPreviewPhotoFiles] = useState([]);

  const [cityId, setCityId] = useState("0");
  const [curentCountryId, setCurentCountryId] = useState("0");

  const [originalTitle, setOriginalTitle] = useState("");
  const [originalDescription, setOriginalDescription] = useState("");

  const {
    currentCountry,
    currentCity,
    loadingPriority,
    createCityLoading,
    updateCityLoading,
  } = useSelector((state) => state.catalogSliceV4);

  const { currentUser } = useSelector((state) => state.userSliceV2);

  useEffect(() => {
    const cityTitle = currentCity?.title?.titleOriginal || "";
    const cityDescription = currentCity?.description?.descriptionOriginal || "";
    setTitle(cityTitle);
    setDescription(cityDescription);
    setOriginalTitle(cityTitle);
    setOriginalDescription(cityDescription);
    setPreviewPhotoFiles(currentCity?.files?.iconFiles || []);
    setCityId(currentCity?.id);
  }, [currentCity]);

  useEffect(() => {
    setCurentCountryId(currentCountry?.id || null);
  }, [currentCountry]);

  const handleSetName = (event) => {
    setTitle(event.target.value);
  };

  const handleSetDescription = (event) => {
    setDescription(event.target.value);
  };

  const cityData = {
    ...currentCity,
    title: {
      titleOriginal: title,
    },
    description: {
      descriptionOriginal: description,
    },
    createdat: Date.now(),
    visibility: false,
    remove: null,
  };

  const handleAddDocument = () => {
    if (mode === "new") {
      dispatch(
        createCityThunkV4({
          countryId: curentCountryId,
          cityId: uuidv4(),
          data: cityData,
          files: photoFiles, // Передаем файлы для загрузки
        })
      ).then(() => onClose());
    } else if (mode === "edit") {
      dispatch(
        updateCityThunkV4({
          countryId: curentCountryId, // Идентификатор страны
          cityId, // Идентификатор города
          updatedData: cityData, // Обновленные данные города
          files: photoFiles, // Новые файлы, если они есть
        })
      ).then(() => onClose());
    }
  };

  const handleDeleDirectory = () => {
    dispatch(
      deleteCityThunkV4({
        countryId: curentCountryId,
        cityId: cityId,
      })
    );
    onClose();
  };

  const isButtonActive =
    photoFiles.length > 0 ||
    title !== originalTitle ||
    description !== originalDescription;

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          marginBottom: "72px",
        }}
      >
        <ContentBox
          position="start"
          isLoading={createCityLoading || updateCityLoading}
        >
          <WidgetListFileV4
            isLoading={loadingPriority}
            collectionName={`catalog/${curentCountryId}/cities`}
            files={previewPhotoFiles}
            documentId={cityId}
            fieldName="files.iconFiles"
            mode="edit"
          />

          <WidgetUploadFilesV4
            id="setPhotoCityFiles"
            title="Add photo city"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            files={setPhotoFiles}
          />

          <InputText
            label="Name"
            description="Enter the name of the country."
            value={title}
            onChange={handleSetName}
            type="text"
          />
          <Textarea
            label="Description"
            maxLength={5000}
            value={description}
            onChange={handleSetDescription}
          />
        </ContentBox>

        {mode === "edit" && (
          <ButtonsBar>
            <div
              style={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                alignItems: "flex-start",
                gap: "8px",
              }}
            >
              <Button
                type="small"
                label="Cancel"
                active={true}
                onClick={() => onClose()}
                color="--color-black"
              />
              <Button
                type="small"
                label="Delete"
                active={true}
                onClick={() => handleDeleDirectory()}
                color="--color-alarm"
                allert={
                  <WidgetAlert
                    title={` Are you sure you want to add the page "${title}" to the directory?`}
                  />
                }
              />
              <Button
                type="small"
                label="Save"
                active={isButtonActive}
                onClick={() => handleAddDocument()}
                allert={
                  <WidgetAlert
                    title={`Are you sure you want to save the changes?`}
                  />
                }
              />
            </div>
          </ButtonsBar>
        )}
        {mode === "new" && (
          <ButtonsBar>
            <div
              style={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                alignItems: "flex-start",
                gap: "8px",
              }}
            >
              <Button
                type="small"
                label="Cancel"
                active={true}
                onClick={() => onClose()}
                color="--color-black"
              />

              <Button
                type="small"
                label="Save"
                active={title !== "" && description !== ""}
                onClick={() => handleAddDocument()}
                allert={
                  <WidgetAlert
                    title={`Are you sure you want to save the changes?`}
                  />
                }
              />
            </div>
          </ButtonsBar>
        )}
      </div>
    </>
  );
};

export default WindowCityDetailV4;
