import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import WIgetError from "../../../Public/UniversalWidgets/WigetError";
import { useDispatch } from "react-redux";
import { googleSignUpThunk } from "../../../../slice/general/auth/signUp/googleSignUpThunk";

function GoogleAuthV2() {
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleGoogleAuth = async (event) => {
    event.preventDefault();

    dispatch(googleSignUpThunk())
      .unwrap()
      .then(({ userData, addedUserDataId }) => {
        navigate("/profile");
      })
      .catch((error) => {
        console.error("Error during registration: ", error.errorMessage);
      });
  };

  return (
    <div>
      <WIgetError
        error={error}
        onClose={() => setError(null)}
        // img='https://firebasestorage.googleapis.com/v0/b/testbikestripster.appspot.com/o/imgStatic%2FErr9.jpg?alt=media&token=ac159c03-413d-4221-a6e5-b72165fdb1cc&_gl=1*1xy0iho*_ga*NzE3MzY1ODU1LjE2ODQ3NTEzMTQ.*_ga_CW55HF8NVT*MTY5NzQzODQ5OC40LjEuMTY5NzQ0MDc1Mi41OC4wLjA.'
      />
      <button
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          gap: "16px",
          width: "100%",
          padding: "8px 16px",
          borderRadius: "80px",
          border: "none",
          fontSize: "1em",
          fontWeight: "500",
          height: "56px",
          backgroundColor: "var(--color-black)",
          color: "var(--color-white)",
          cursor: "pointer",
        }}
        onClick={handleGoogleAuth}
      >
        Sign in with Google
        <div
          style={{
            backgroundImage:
              "url(https://res.cloudinary.com/dv7wszxg2/image/upload/v1695703547/cdnlogo.com_google-icon_nza0n9.svg)",
            width: "16px",
            aspectRatio: "1/1",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
            overflow: "hidden",
          }}
        />
      </button>

      {error && (
        <p
          style={{
            textAlign: "center",
            padding: "24px 24px 0 24px",
            color: "var(--color-alarm)",
          }}
          className="p-light"
        >
          {error}
        </p>
      )}
    </div>
  );
}

export default GoogleAuthV2;
