import { useEffect, useRef } from "react";
import SearchBars from "../../../../../UIComponents/bars/searchBars/SearchBars";
import Icon44 from "../../../../../UIComponents/icons/Icon44";
import WigetButtonAdd from "../../../../../UniversalWidgets/WigetButtonAdd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { setCurrentUsersRentalPoints, setCurrentRentalPoint } from "../../../../../../slice/stateRentalPointSlice"
import { useSubscribeData } from "../../../../../../services/firebase/crudFirebaseStorage";
import ImageWithLoader from "../../../../../UIComponents/bars/gallery/ImageWithLoader";
import {
  setPrivateSideBar,
  setCurrentRentalPoint,
  setAllOrders,
} from "../../../../../../slice/stateGUISlice";
const { v4: uuidv4 } = require("uuid");

const googleAPIKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const WigetRentalPointList = () => {
  const dispatch = useDispatch();
  const activeItemRef = useRef(null);

  // const privateSideBar = useSelector((state) => state.stateGUISlice.privateSideBar)

  // const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  // Локальное состояние компонента (Исключая точки проката с нулевыми координатами)
  // const [filteredCurrentUsersRentalPoints, setFilteredCurrentUsersRentalPoints] = useState(null);

  // Получаем данные о пользователе"
  const currentUser = useSelector((state) => state.userSliceV2.currentUser);
  const role = currentUser ? currentUser.role : null;
  const isLoading = useSelector((state) => state.userSliceV2.isLoading);
  const userId = currentUser ? currentUser.uid : null;

  const [activeRentalPointIndex, setActiveRentalPointIndex] = useState(null);
  const [activeAddButtonState, setActiveAddButtonState] = useState(false);

  const currentUsersRentalPoints = useSelector(
    (state) => state.stateGUISlice.currentUsersRentalPoints
  );

  const idCurrentRentalPoint = useSelector(
    (state) => state.stateGUISlice.currentRentalPoint.id
  );

  const currentRentalPoint = useSelector(
    (state) => state.stateGUISlice.currentRentalPoint
  );

  const getLogoUrl = (fileLogos) => {
    const prioritizedLogo = fileLogos.find((logo) => logo.priority === true);

    if (prioritizedLogo) {
      // Возвращаем URL логотипа с флагом priority равным true
      return prioritizedLogo.url;
    } else if (fileLogos.length > 0) {
      // Возвращаем URL первого логотипа, если массив не пустой
      return fileLogos[0].url;
    } else {
      // Если массив пустой, можно вернуть какое-то значение по умолчанию или null
      return null;
    }
  };

  const indexForCurrentRentalPoint = currentUsersRentalPoints?.findIndex(
    (rentalPoint) => rentalPoint?.id === currentRentalPoint?.id
  );

  const MapUrl = (location) => {
    if (!location || !location.lat || !location.lng) {
      // Обработка ситуации, когда location не определено или не содержит нужных свойств
      return null;
    }

    const centerShiftX = 0;

    const shiftedCenterCoordinates = {
      lat: location.lat,
      lng:
        location.lng +
        centerShiftX / (111320 * Math.cos((location.lat * Math.PI) / 180)),
    };

    const zoomMap = 14;
    const sizeMapPrewiew = "300x300";
    const style = "feature:all|element:labels|visibility:off";

    const imageUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${shiftedCenterCoordinates.lat},${shiftedCenterCoordinates.lng}&zoom=${zoomMap}&size=${sizeMapPrewiew}&maptype=roadmap&markers=${location.lat},${location.lng}&format=png&style=${style}&key=${googleAPIKey}`;

    // Возвращаем не только URL, но и элемент img
    return imageUrl;
    // return <img src={imageUrl} alt="Map Preview" />;
  };

  // Управление активным пунктом WigetRentalPointList
  useEffect(() => {
    if (currentUsersRentalPoints && currentUsersRentalPoints.length > 0) {
      // Если выбранный пункт аренды уже сохранен, установите его
      const selectedPoint = currentUsersRentalPoints.find(
        (point) => point.id === idCurrentRentalPoint
      );

      if (selectedPoint) {
        dispatch(setAllOrders(false));
        dispatch(setCurrentRentalPoint(selectedPoint));
        setActiveRentalPointIndex(
          currentUsersRentalPoints.indexOf(selectedPoint)
        );
      } else {
        // В противном случае, установите первый пункт аренды
        dispatch(setAllOrders(false));
        dispatch(setCurrentRentalPoint(currentUsersRentalPoints[0]));
        setActiveRentalPointIndex(0);
      }
    }
  }, [currentUsersRentalPoints, idCurrentRentalPoint]);

  // Отображение кнопки WigetButtonAdd если нет пустых точек проката
  useEffect(() => {
    const hasValidLocations = currentUsersRentalPoints?.every(
      (point) =>
        point?.pointAddress &&
        point?.pointAddress.location &&
        typeof point?.pointAddress?.location?.lat === "number" &&
        typeof point?.pointAddress?.location?.lng === "number"
    );
    setActiveAddButtonState(hasValidLocations);
  }, [currentUsersRentalPoints]);

  useEffect(() => {
    if (activeItemRef.current) {
      activeItemRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  }, [indexForCurrentRentalPoint]);

  const currentIndex = () => {
    const index = currentUsersRentalPoints.findIndex(
      (bike) => bike.id === idCurrentRentalPoint
    );

    if (index !== -1) {
      console.log(
        `Элемент с ID ${idCurrentRentalPoint} найден, его индекс: ${index}`
      );
    } else {
      console.log(`Элемент с ID ${idCurrentRentalPoint} не найден в массиве.`);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "space-between",
        height: "100%",
        width: "100%",
        boxSizing: "border-box",
        backgroundColor: "var(--color-white)",
      }}
    >
      <SearchBars
        title="Rental points"
        iconLeft={
          <Icon44
            click={() => dispatch(setPrivateSideBar(true))}
            icon={
              <span className="material-symbols-outlined">dock_to_right</span>
            }
          />
        }
        iconRight={
          activeAddButtonState && (
            <WigetButtonAdd
              nameButton={`Add rental point`}
              collectionName={"rentalPoints"}
              parentCollectionName={"user"}
              parentDocumentId={userId}
              icon={
                <span className="material-symbols-outlined">add_circle</span>
              }
            />
          )
        }
      />

      <div
        style={{
          height: "100%",
        }}
        className="scrollBox"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            boxSizing: "border-box",
          }}
        >
          {currentUsersRentalPoints &&
            currentUsersRentalPoints?.length > 0 &&
            currentUsersRentalPoints.map((rentalPointItem, index) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  padding: "0 8px",
                  boxSizing: "border-box",
                }}
                ref={
                  index === indexForCurrentRentalPoint ? activeItemRef : null
                }
                key={uuidv4()}
                onClick={() => {
                  dispatch(setCurrentRentalPoint(rentalPointItem));
                  // setSelectedItemIndex(index);
                  currentIndex();
                  dispatch(setAllOrders(false));
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    gap: "16px",
                    backgroundColor:
                      idCurrentRentalPoint === rentalPointItem.id ||
                      index === indexForCurrentRentalPoint
                        ? "var(--color-primary)"
                        : "var(--color-white)",
                    borderRadius: "8px",
                  }}
                  className="itemScroll"
                >
                  <div
                    style={{
                      width: "100px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "0 0 0 8px",
                    }}
                  >
                    <div
                      style={{
                        width: "80px",
                        aspectRatio: "1/1",
                        borderRadius: "24px",
                        overflow: "hidden",
                        boxSizing: "border-box",
                      }}
                    >
                      <ImageWithLoader
                        url={rentalPointItem?.file_logo}
                        alt={"PreviewRentalPoint"}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                      height: "100px",
                      borderBottom:
                        idCurrentRentalPoint === rentalPointItem.id ||
                        index === indexForCurrentRentalPoint ||
                        index === indexForCurrentRentalPoint - 1
                          ? "0px var(--color-gray-200) solid"
                          : "1px var(--color-gray-200) solid",
                      color:
                        (idCurrentRentalPoint === rentalPointItem.id ||
                          index === indexForCurrentRentalPoint) &&
                        "var(--color-white)",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "center",
                      }}
                    >
                      <h5
                        style={{
                          color:
                            (idCurrentRentalPoint === rentalPointItem.id ||
                              index === indexForCurrentRentalPoint) &&
                            "var(--color-white)",
                        }}
                      >
                        {rentalPointItem.title[0]
                          ? rentalPointItem.title
                          : "No name"}
                      </h5>
                      <p
                        style={{
                          color:
                            (idCurrentRentalPoint === rentalPointItem.id ||
                              index === indexForCurrentRentalPoint) &&
                            "var(--color-white)",
                        }}
                        className="p-light"
                      >
                        {rentalPointItem.pointAddress.address}
                      </p>
                    </div>
                    <span className="material-symbols-outlined">
                      chevron_right
                    </span>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
export default WigetRentalPointList;
