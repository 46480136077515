import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAuth, signOut } from "firebase/auth";
import interpretAuthErrorV2 from "../../../../functions/interpretAuthErrorV2";

// Создаем асинхронный экшн для выхода из учетной записи пользователя
export const logoutThunk = createAsyncThunk(
  "user/signOut",
  async (_, { rejectWithValue }) => {
    const auth = getAuth();
    try {
      // Выполняем операцию выхода из учетной записи
      await signOut(auth);
      // Возвращаем успешное выполнение
      return true;
    } catch (error) {
      const errorMessage = interpretAuthErrorV2(error.code);
      console.error("Logout failed, please retry. Error: ", errorMessage);
      return rejectWithValue({ errorMessage });
    }
  }
);
