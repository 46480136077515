import { createAsyncThunk } from "@reduxjs/toolkit";
import { updateDocument } from "../../../services/firebase/crudFirestore";
import interpretAuthErrorV2 from "../../../functions/interpretAuthErrorV2";

export const updateRentalPointsModerationThunkV2 = createAsyncThunk(
  "reducersToolkit/thunk/moderation/updateRentalPointsModerationThunkV2",
  async ({ collectionName, id, newData }, { rejectWithValue }) => {
    try {
      await updateDocument(collectionName, id, newData);
      return { id, ...newData };
    } catch (error) {
      const errorMessage = interpretAuthErrorV2(error.code);
      return rejectWithValue({ errorMessage });
    }
  }
);
