import { useEffect, useState, useRef } from "react";
import { useSubscribeData } from "../../../../../../services/firebase/crudFirebaseStorage";
import ImageWithLoader from "../../../../../UIComponents/bars/gallery/ImageWithLoader";
import { useSelector } from "react-redux";
import main from "os-browserify";

const OdrderDetailBike = ({ orderDetail, title, description }) => {
    const [bike, setBike] = useState(null)

    const bikeId = orderDetail?.order_info?.bikes_id;
    const quantityBike = orderDetail?.order_info?.quantity;

    const brandData = useSelector((state) => state.brand.data);
    const categoryData = useSelector((state) => state.category.data);
    const bikeData = useSelector((state) => state.bike.data);

    const { data: bikesData, isLoading: loadingBikesData } = useSubscribeData('bikes');


    const atLeastOneNotEmpty = !loadingBikesData || bikeId || quantityBike;

    useEffect(() => {
        if (bikesData) {
            const filteredBike = bikesData.filter(itemBike => itemBike.id === bikeId);
            setBike(filteredBike[0]);
        }
    }, [bikeId, bikesData]);

    const TitleBike = ({ orderItem, typeContent }) => {
        if (orderItem) {
            const orderItemBike = bikeData.find((itemBike) => itemBike?.id === orderItem?.order_info?.bikes_id);

            if (orderItemBike?.title.length > 0) {
                return (
                    orderItemBike.title[0]
                );
            } else {
                return (
                    <>No matching title found</>
                );
            }
        }

        // Если orderItem не существует, вы можете вернуть пустой JSX или null в зависимости от ваших потребностей
        return null;
    };

    const BrendBike = ({ orderItem, typeContent }) => {
        if (orderItem) {
            const orderItemBike = bikeData.find((itemBike) => itemBike?.id === orderItem?.order_info?.bikes_id)

            const filteredBrandData = brandData.find((brand) => orderItemBike?.brand_id.includes(brand.id));

            if (typeContent === 'img') {
                return (
                    <ImageWithLoader
                        aspectRatio='1/1'
                        url={filteredBrandData?.file_icon}
                        alt={'PreviewLogo'}
                    />
                )
            } else {
                if (filteredBrandData) {
                    return (
                        filteredBrandData.title[0]
                    );
                } else {
                    return (
                        <>
                            No matching brand found.
                        </>
                    );
                }
            }
        }
        return null;
    };

    const CategoryBike = ({ orderItem }) => {

        if (orderItem) {

            const orderItemBike = bikeData.find((itemBike) => itemBike?.id === orderItem?.order_info?.bikes_id)

            const filteredCategoryData = categoryData.find(category =>
                orderItemBike?.categoryes_id && orderItemBike.categoryes_id.includes(category.id)
            ) || {};

            if (filteredCategoryData.length > 0) {
                return (
                    filteredCategoryData.title[0]
                );
            } else {
                return (
                    <>
                        No matching category found.
                    </>
                );
            }
        }

        return null;
    };


    return (

        <div

            style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '0 16px',
                boxSizing: 'border-box',
            }}>

            <div style={{
                backgroundColor: 'var(--color-white)',
                boxSizing: 'border-box',
                borderRadius: '8px',
                overflow: 'hidden'
            }}>

                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    height: '48px',
                    padding: '0 16px',
                    ...(atLeastOneNotEmpty && {
                        borderBottom: '1px var(--color-gray-200) solid',
                    })

                }}>

                    <p style={{
                        textTransform: 'uppercase',
                    }}
                        className='p-light'
                    >
                        {title}
                    </p>
                    {/* 
                        <button
                            onClick={() => handleOpenPopup(`popu`)}
                        >
                            {items.length > 0 ? 'Edit' : 'Add'}
                        </button> */}

                </div>
                <div style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fill, minmax(350px, 1fr))',
                    gap: '4px',
                    width: '100%',
                    height: '100%',
                    overflow: 'hidden',
                    boxSizing: 'border-box',
                }}>

                    <div style={{
                        overflow: 'hidden',
                        borderRadius: '4px',
                        // backgroundColor: 'var(--color-gray-100)',
                        width: '100%',
                        aspectRatio: '16/9',
                        // margin: '8px',
                        boxSizing: 'border-box',
                        padding: '8px',
                        height: '100%',
                    }}>
                        <div style={{
                            borderRadius: '8px',
                            overflow: 'hidden',
                        }}>
                            <ImageWithLoader
                                aspectRatio='16/9'
                                url={bike?.file_bikePhoto}
                                alt='BikePhoto'
                            />
                        </div>

                    </div>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center'
                    }}>
                        <div className="private_ordersList_itemPrice private_ordersList_itemPrice_borderBottom">
                            <p>Bike name</p>
                            <p style={{
                                fontWeight: '600'
                            }}>
                                <TitleBike
                                    orderItem={orderDetail}
                                />
                            </p>
                        </div>

                        <div className="private_ordersList_itemPrice private_ordersList_itemPrice_borderBottom">
                            <p>Brend</p>
                            <p style={{
                                fontWeight: '600'
                            }}>
                                <BrendBike
                                    orderItem={orderDetail}
                                />
                            </p>
                        </div>

                        <div className="private_ordersList_itemPrice private_ordersList_itemPrice_borderBottom">
                            <p>Category</p>
                            <p style={{
                                fontWeight: '600'
                            }}>
                                <CategoryBike
                                    orderItem={orderDetail}
                                />
                            </p>
                        </div>

                        <div className="private_ordersList_itemPrice">
                            <p>Quantity bike</p>
                            <p style={{
                                fontWeight: '600'
                            }}>
                                {quantityBike && quantityBike}
                            </p>
                        </div>


                    </div>
                </div>
            </div>
            {description && (
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    padding: '8px 16px'
                }}>
                    <p className='p-light'>
                        {description}
                    </p>
                </div>
            )}
        </div>


    );
}

export default OdrderDetailBike;
