import React, { useState } from "react";
import PopupDirrectory from "../WidgetsProfile/Popups/PopupDirrectory";
import WindowBikeCharacteristicDetail from "./WindowBikeCharacteristicDetail";
import AddData from "../WidgetsProfile/AddData/AddData";
import CollapsableText from "../WidgetsProfile/Collapsable/CollapsableText";

const WidgetBikeCharacteristicsList = ({ mode, characteristics }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isAddCharacteristic, setIsAddCharacteristic] = useState(false);
  const [characteristicId, setCharacteristicId] = useState(null);

  const handleClose = () => {
    setIsOpen(false);
    setIsAddCharacteristic(false);
  };

  const handleToggleNewCharacteristic = (characteristicId) => {
    setCharacteristicId(characteristicId);
    setIsOpen(true);
  };

  const handleToggleEditCharacteristic = () => {
    setIsAddCharacteristic(true);
  };
  return (
    <>
      <AddData
        icon={<span className="material-symbols-outlined">add</span>}
        title="Add Characteristic"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        onClick={handleToggleEditCharacteristic}
      />
      {characteristics?.length > 0 && (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          {characteristics?.map((characteristicItem) => (
            <div
              style={{
                width: "100%",
                backgroundColor: "var(--color-gray-100)",
                padding: "16px 8px 16px 16px",
                borderRadius: "16px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "16px",
                cursor: "pointer",
                boxSizing: "border-box",
              }}
              key={characteristicItem?.id}
              onClick={() =>
                handleToggleNewCharacteristic(characteristicItem?.id)
              }
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "8px",
                  cursor: "pointer",
                  boxSizing: "border-box",
                }}
              >
                <p>{characteristicItem?.title?.titleOriginal}</p>

                <p className="p-light">
                  {characteristicItem?.description?.descriptionOriginal}
                </p>
                <p
                  style={{
                    fontWeight: "600",
                    color: "var(--color-black)",
                  }}
                >
                  {characteristicItem?.characteristic}
                </p>
              </div>
              <div>
                <span className="material-symbols-outlined">chevron_right</span>
              </div>
            </div>
          ))}
        </div>
      )}

      <PopupDirrectory
        isOpen={isOpen}
        onClose={handleClose}
        title="Edit characteristic"
      >
        <WindowBikeCharacteristicDetail
          mode="edit"
          onClose={handleClose}
          characteristicId={characteristicId}
        />
      </PopupDirrectory>

      <PopupDirrectory
        isOpen={isAddCharacteristic}
        onClose={handleClose}
        title="Add characteristic"
      >
        <WindowBikeCharacteristicDetail mode="new" onClose={handleClose} />
      </PopupDirrectory>
    </>
  );
};

export default WidgetBikeCharacteristicsList;
