import React from "react";
import "../../profile.css";

const TitleBlockSection = ({ title, description }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "center",
        gap: "8px",
        width: "100%",
        minHeight: "64px",
        padding: "8px 0",
        boxSizing: "border-box",
      }}
    >
      <h5>{title ? title : "No title"}</h5>
      {description && (
        <p
          style={{
            width: "80%",
          }}
        >
          {description}
        </p>
      )}
    </div>
  );
};

export default TitleBlockSection;
