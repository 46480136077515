// Импорт необходимых зависимостей
import React, { useState, useEffect } from "react";
import "firebase/compat/firestore";
import WigetButtonAdd from "../../../../../UniversalWidgets/WigetButtonAdd";
import WigetButtonDelete from "../../../../../UniversalWidgets/WigetButtonDelete";
import { useSubscribeData } from "../../../../../../services/firebase/crudFirebaseStorage";
import Popup from "../../../../../UIElements/Popup";
import Radio from "../../../../../UIElements/Radio";

import Loading from "../../../../../UIElements/Loading";
import { useDispatch } from "react-redux";
import PreviewGallery from "../../../../../UIComponents/bars/gallery/PreviewGallery";
import { WigetInputV2 } from "../../../../../UniversalWidgets/WigetInputV2";
import { WigetTextareaV2 } from "../../../../../UniversalWidgets/WigetTextareaV2";

export const WigetCountryDetail = ({
  title,
  collectionName,
  structureCollection,
}) => {
  const dispatch = useDispatch();
  // Используем наш кастомный хук для подписки на данные Firestore и отслеживания состояния загрузки
  const { data: dataList, isLoading: loadingState } =
    useSubscribeData(collectionName);

  return (
    <div className="private_profile-mainBox">
      {loadingState ? (
        <Loading />
      ) : (
        dataList.map((directoryItem, index) => (
          <div className="directory" key={directoryItem.id}>
            <div className="rentalPoints_content-box">
              <div
                className="rentalPoints_content-box-left"
                style={{ width: "50%" }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <PreviewGallery
                    aspectRatio="16/9"
                    title={`Flag ${title}`}
                    collectionName={collectionName}
                    idDocument={directoryItem.id}
                    fieldName="file_flag"
                    gallery={true}
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eleifend ipsum vitae nunc gravida facilisis."
                  />

                  <PreviewGallery
                    aspectRatio="16/9"
                    title={`Photo ${title}`}
                    collectionName={collectionName}
                    idDocument={directoryItem.id}
                    fieldName="file_photo"
                    gallery={true}
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eleifend ipsum vitae nunc gravida facilisis."
                  />

                  <PreviewGallery
                    aspectRatio="16/9"
                    title={`Map`}
                    collectionName={collectionName}
                    idDocument={directoryItem.id}
                    fieldName="file_map"
                    gallery={true}
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eleifend ipsum vitae nunc gravida facilisis."
                  />
                </div>
              </div>

              <div
                className="rentalPoints_content-box-right"
                style={{ width: "50%" }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <WigetInputV2
                    title={`Название ${title}`}
                    collectionName={collectionName}
                    idDocument={directoryItem.id}
                    fieldName="title"
                    quantity={1}
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eleifend ipsum vitae nunc gravida facilisis."
                  />

                  <WigetTextareaV2
                    title={`Описание ${title}`}
                    collectionName={collectionName}
                    idDocument={directoryItem.id}
                    fieldName="description"
                    quantity={1}
                    maxLength={1000}
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eleifend ipsum vitae nunc gravida facilisis."
                  />

                  <div
                    style={{
                      padding: "0 16px",
                    }}
                  >
                    <div className="wigetButtonDelete-imform">
                      <div className="wigetButtonDelete-imform-left">
                        <p className="p-light">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Fusce eleifend ipsum vitae nunc gravida
                          facilisis.
                        </p>
                      </div>

                      <div className="wigetButtonDelete-imform-right">
                        <WigetButtonDelete
                          collectionName={collectionName}
                          documentId={directoryItem.id}
                          title="Удалить"
                          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eleifend ipsum vitae nunc gravida facilisis."
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

/**
 * Компонент WigetBrandCheck.
 * Отображает список брендов с радиокнопками и кнопкой выбора.
 * @param {Object} props - Свойства компонента.
 * @param {string} props.title - Заголовок компонента.
 * @param {string} props.collectionName - Название коллекции для получения данных о брендах.
 * @param {string} props.parentCollectionName - Название родительской коллекции для получения данных родительского документа.
 * @param {string} props.documentId - Идентификатор документа родительской коллекции.
 *  @param {string} props.fieldName - Поле в которое выполняется запись выбранных значений, также это значение используется для фильтрации.
 * @returns {JSX.Element} Элемент компонента WigetBrandCheck.
 */
export const WigetCountryCheck = ({
  title,
  collectionName,
  fieldName,
  parentCollectionName,
  documentId,
  description,
}) => {
  // Состояние активного попапа
  const [activePopup, setActivePopup] = useState(null);

  // Состояние выбранного бренда
  const [selectedBrand, setSelectedBrand] = useState(null);

  // Получение данных о брендах из коллекции
  const { data } = useSubscribeData(collectionName);

  // Получение данных родительского документа
  const { data: documentParentCollectionName, isLoading } = useSubscribeData(
    parentCollectionName,
    documentId
  );

  useEffect(() => {
    if (documentParentCollectionName?.[fieldName]) {
      setSelectedBrand(documentParentCollectionName[fieldName]);
    }
  }, [documentParentCollectionName, fieldName]);

  /**
   * Обработчик открытия попапа.
   * @param {string} popupName - Имя попапа.
   */
  const handleOpenPopup = (popupName) => {
    setActivePopup(popupName);
  };

  /**
   * Обработчик закрытия попапа.
   */
  const handleClosePopup = () => {
    setActivePopup(null);
  };

  /**
   * Обработчик изменения состояния радиокнопки.
   * @param {string} dataId - Идентификатор бренда.
   */
  const handleRadioButtonChange = (dataId) => {
    setSelectedBrand(dataId);
  };

  // Фильтруем список брендов на основе выбранного бренда и наличия данных родительского документа
  const filteredData =
    data && documentParentCollectionName?.[fieldName]
      ? data.filter((dataItem) =>
          documentParentCollectionName[fieldName].includes(dataItem.id)
        )
      : [];

  // Возвращаем JSX-элемент компонента
  return (
    <>
      <div className="inform-box">
        <div
          className="inform-box-pick"
          onClick={() => handleOpenPopup(`popu_${title}`)}
        >
          {(filteredData.length > 0 ? filteredData : [title]).map(
            (directoryItem, index) => (
              <div className="inform-box-content-pick" key={index}>
                <div className="inform-box-content-left">
                  {directoryItem.title ? directoryItem.title : title}
                </div>

                <div className="inform-box-content-right">
                  {directoryItem.file_icon ? (
                    directoryItem.file_icon
                      .filter((icon) => icon.priority)
                      .map((icon, index) => (
                        <div key={index}>
                          <div
                            className="inform-box-icon-right"
                            style={{
                              backgroundImage: `url(${icon.url})`,
                            }}
                          ></div>
                        </div>
                      ))
                  ) : (
                    <div className="inform-box-icon-right">
                      <i className="fa-solid fa-chevron-right"></i>
                    </div>
                  )}
                </div>
              </div>
            )
          )}
        </div>
        {description ? (
          <div className="inform-box-footer">{description}</div>
        ) : (
          <div className="inform-box-footer-empty"></div>
        )}
      </div>

      {activePopup === `popu_${title}` && (
        <Popup isOpen={true} onClose={handleClosePopup} title={title}>
          <div className="inform-box-inputItem">
            <div className="inform-box">
              <div className="inform-box-header">{title}</div>
              <div className="inform-box-item">
                {isLoading ? (
                  <div>Loading...</div>
                ) : (
                  data.map((dataItem, index) => (
                    <>
                      <div key={index} className="inform-box-content">
                        <div className="inform-box-content-left">
                          <Radio
                            value={dataItem.title}
                            id={dataItem.id}
                            checked={selectedBrand === dataItem.id}
                            onChange={handleRadioButtonChange}
                          />
                          {/* <input
                                                    type="radio"
                                                    name="brand"
                                                    value={dataItem.id}
                                                    checked={selectedBrand === dataItem.id}
                                                    onChange={() => handleRadioButtonChange(dataItem.id)}
                                                /> */}
                        </div>

                        <div className="inform-box-content-right">
                          {dataItem.file_icon &&
                            dataItem.file_icon
                              .filter((icon) => icon.priority)
                              .map((icon) => (
                                <div
                                  className="inform-box-icon-right"
                                  style={{
                                    backgroundImage: `url(${icon.url})`,
                                  }}
                                ></div>
                              ))}
                        </div>
                      </div>
                    </>
                  ))
                )}
              </div>
            </div>
          </div>

          <div className="send-view">
            <div className="send-view-left"></div>
            <div className="send-view-right">
              <WigetButtonAdd
                collectionName={parentCollectionName}
                documentId={documentId}
                nameButton="Выбрать"
                dataObject={{
                  [fieldName]: selectedBrand,
                }}
              />
            </div>
          </div>
        </Popup>
      )}
    </>
  );
};
