import { createAsyncThunk } from "@reduxjs/toolkit";
import { db } from "../../../../services/firebase/authFirebase";

export const createBikeThunkV4 = createAsyncThunk(
  "bikes/createBikeThunkV4",
  async (
    {
      countryId,
      cityId,
      rentalPointId,
      bikeId,
      model,
      brandId,
      categoryId,
      availability,
      files,
    },
    { rejectWithValue }
  ) => {
    try {
      await db
        .collection("countries")
        .doc(countryId)
        .collection("cities")
        .doc(cityId)
        .collection("rentalPoints")
        .doc(rentalPointId)
        .collection("bikes")
        .doc(bikeId)
        .set({
          model: model,
          brandId: brandId,
          categoryId: categoryId,
          availability: availability,
          files: {
            photos: files.photos || [],
          },
        });
      return {
        countryId,
        cityId,
        rentalPointId,
        bikeId,
        model,
        brandId,
        categoryId,
        availability,
        files,
      };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
