import React from "react";
import ContactItem from "../SupportV2/Contacts/ContactItem";

const PageSupport = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        boxSizing: "border-box",
        backgroundColor: "var(--color-gray-100)",
        padding: "16px",
      }}
    >
      <div
        style={{
          paddingTop: "80px",
          maxWidth: "var(--width-mobile-content)",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "8px",
          boxSizing: "border-box",
          position: "relative",
        }}
      >
        <ContactItem
          title="Нужна помощь с вашим бронированием?"
          linkName="Связаться с центром поддержки партнеров"
          link="/"
          icon={
            <span className="material-symbols-outlined">alternate_email</span>
          }
        />
        <ContactItem
          title="Интересует работа в «BikeTripster.com?"
          linkName="Посмотреть вакансии"
          link="/"
          icon={
            <span className="material-symbols-outlined">alternate_email</span>
          }
        />
        <ContactItem
          title="Хотите узнать больше о «BikeTripster.com?"
          linkName="Посмотреть юридическую информацию"
          link="/"
          icon={
            <span className="material-symbols-outlined">alternate_email</span>
          }
        />
        <ContactItem
          title="Возникли вопросы?"
          linkName="Посмотреть страницу центра поддержки"
          link="/"
          icon={
            <span className="material-symbols-outlined">alternate_email</span>
          }
        />
        <ContactItem
          title="Есть вопросы о «BikeTripster.com для бизнеса»?"
          linkName="Узнать подробнее о нашей услуге"
          link="/"
          icon={
            <span className="material-symbols-outlined">alternate_email</span>
          }
        />
        <ContactItem
          title="Вы представляете прессу?"
          linkName="Посетить наш пресс-центр"
          link="/"
          icon={
            <span className="material-symbols-outlined">alternate_email</span>
          }
        />
        <ContactItem
          title="Вы представляете орган государственной власти?"
          linkName="Отправить официальный запрос"
          link="/"
          icon={
            <span className="material-symbols-outlined">alternate_email</span>
          }
        />
      </div>
    </div>
  );
};

export default PageSupport;
