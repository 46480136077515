import {
  addDoc,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  runTransaction,
  updateDoc,
  where,
} from "firebase/firestore";
import { db, storage } from "./authFirebase";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
  uploadBytesResumable,
} from "firebase/storage";
import { v4 as uuidv4 } from "uuid";

/**
 * Асинхронно извлекает документы из указанной коллекции Firestore. Если предоставлены идентификаторы документов,
 * извлечение будет ограничено этими документами. В противном случае, функция извлечет все документы в коллекции.
 * Поддерживается добавление дополнительных фильтров для уточнения запроса.
 *
 * @param {string} collectionName - Название коллекции в Firestore, откуда будут извлекаться документы.
 * @param {string|string[]|null} documentIds - Опциональный одиночный ID документа или массив ID для извлечения.
 *    Если массив содержит более 10 элементов, он будет разбит на подмассивы для соответствия ограничениям Firestore.
 *    Если параметр не указан, будут извлечены все документы из коллекции.
 * @param {Array} additionalFilters - Опциональные дополнительные фильтры, каждый из которых должен быть массивом в формате пригодном для функции `where` Firestore (например, ['field', '==', 'value']).
 *
 * @returns {Promise<Object[]>} Промис, который разрешается массивом объектов, каждый из которых представляет собой документ из Firestore, включая его `id` и остальные данные.
 *
 * @example
 * // Вызов функции для получения документов по ID
 * fetchDocumentsByIds('users', ['user1', 'user2'], [['status', '==', 'active']])
 *   .then(documents => {
 *     console.log(documents);
 *   })
 *   .catch(error => {
 *     console.error("Ошибка при получении документов", error);
 *   });
 *
 * @example
 * // Вызов функции для получения всех документов из коллекции
 * fetchDocumentsByIds('users', null, [['status', '==', 'active']])
 *   .then(documents => {
 *     console.log("Все активные пользователи:", documents);
 *   })
 *   .catch(error => {
 *     console.error("Ошибка при получении документов", error);
 *   });
 */
export const fetchDocumentsByIds = async (
  collectionName,
  documentIds = null,
  additionalFilters = []
) => {
  if (typeof collectionName !== "string" || collectionName.trim() === "") {
    throw new Error("Invalid collection name");
  }

  if (
    documentIds !== null &&
    typeof documentIds !== "string" &&
    !Array.isArray(documentIds)
  ) {
    throw new Error("Invalid document IDs");
  }

  if (!Array.isArray(additionalFilters)) {
    throw new Error("Additional filters must be an array");
  }

  const db = getFirestore();
  const documents = [];

  try {
    let q;
    if (!documentIds) {
      // Если documentIds не предоставлен, создаём запрос ко всей коллекции
      q = query(
        collection(db, collectionName),
        ...additionalFilters.map((filter) => where(...filter))
      );

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        documents.push({ id: doc.id, ...doc.data() });
      });
    } else {
      let idChunks = [];
      // Обрабатываем одиночный ID или массив ID
      if (typeof documentIds === "string") {
        idChunks = [[documentIds]]; // Обработка одиночного ID
      } else if (Array.isArray(documentIds)) {
        // Разбиваем массив ID на подмассивы по 10 элементов
        for (let i = 0; i < documentIds.length; i += 10) {
          idChunks.push(documentIds.slice(i, i + 10));
        }
      }

      // Выполняем запросы к Firestore для каждого чанка
      for (let idChunk of idChunks) {
        q = query(
          collection(db, collectionName),
          where("__name__", "in", idChunk),
          ...additionalFilters.map((filter) => where(...filter))
        );

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          documents.push({ id: doc.id, ...doc.data() });
        });
      }
    }

    return documents;
  } catch (error) {
    console.error("Ошибка при получении документов:", error);
    throw error;
  }
};

/**
 * Подписывается на изменения в документе или коллекции Firestore и возвращает функцию для отмены подписки.
 * Эта функция позволяет динамично отслеживать изменения в данных Firestore, что полезно для реактивного обновления пользовательского интерфейса.
 *
 * @param {string} collectionName - Название коллекции Firestore.
 * @param {string|null} id - Опциональный идентификатор документа в коллекции. Если не указан, подписывается на всю коллекцию.
 * @param {string|null} fieldPath - Опциональное название поля документа для отслеживания. Если не указано, возвращается весь документ.
 * @param {function} callback - Функция обратного вызова, которая вызывается с данными при каждом обновлении. Для коллекции вызов происходит с массивом документов.
 *
 * @returns {function} Функция для отмены подписки. Вызовите эту функцию, чтобы прекратить подписку и предотвратить утечки памяти.
 * @example
 * // Подписка на изменения в документе пользователя с ID 'user1'
 * const unsubscribeUser = subscribeToDocument('users', 'user1', null, (data) => {
 *   console.log('Данные пользователя обновлены:', data);
 * });
 *
 * // Подписка на изменения конкретного поля 'status' в документе пользователя 'user1'
 * const unsubscribeStatus = subscribeToDocument('users', 'user1', 'status', (status) => {
 *   console.log('Статус пользователя обновлен:', status);
 * });
 *
 * // Подписка на всю коллекцию 'users' без указания конкретного документа
 * const unsubscribeUsers = subscribeToDocument('users', null, null, (documents) => {
 *   console.log('Обновление списка пользователей:', documents);
 * });
 *
 * // Для отмены подписки можно использовать возвращаемую функцию unsubscribe
 * // Например, отписаться после определенных условий:
 * setTimeout(() => {
 *   unsubscribeUser();
 *   unsubscribeStatus();
 *   unsubscribeUsers();
 *   console.log('Подписки отменены после 5 минут');
 * }, 300000); // Отписка через 5 минут
 */
export const subscribeToDocument = (
  collectionName,
  id = null,
  fieldPath = null,
  callback
) => {
  if (typeof collectionName !== "string" || collectionName.trim() === "") {
    throw new Error("Invalid collection name");
  }

  if (id !== null && (typeof id !== "string" || id.trim() === "")) {
    throw new Error("Invalid document ID");
  }

  if (
    fieldPath !== null &&
    (typeof fieldPath !== "string" || fieldPath.trim() === "")
  ) {
    throw new Error("Invalid field path");
  }

  if (typeof callback !== "function") {
    throw new Error("Callback must be a function");
  }

  const db = getFirestore();
  const ref = id ? doc(db, collectionName, id) : collection(db, collectionName);

  const unsubscribe = onSnapshot(ref, (snapshot) => {
    if (id && snapshot.exists()) {
      // Подписка на конкретное поле документа
      let data = snapshot.data();
      if (fieldPath) {
        const fields = fieldPath.split(".");
        for (let field of fields) {
          if (data && data.hasOwnProperty(field)) {
            data = data[field];
          } else {
            data = null;
            break;
          }
        }
      }
      if (fieldPath && data !== null) {
        callback(data);
      } else if (!fieldPath) {
        // Возвращаем весь документ, если поле не указано
        callback(snapshot.data());
      }
    } else if (!id && snapshot.docs) {
      // Подписка на всю коллекцию
      const documents = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      callback(documents);
    } else {
      console.log("Документ не найден или коллекция пуста.");
    }
  });

  return unsubscribe;
};

/**
 * Обновляет указанные поля документа в коллекции Firestore.
 *
 * @param {string} collectionName - Название коллекции Firestore.
 * @param {string} documentId - Идентификатор документа, который нужно обновить.
 * @param {Object} updatedFields - Объект с полями и их новыми значениями.
 * @returns {Promise<void>}
 * @throws {Error} Возникает при ошибке обновления документа.
 */

export const updateDocumentFields = async (
  collectionName,
  documentId,
  updatedFields
) => {
  if (typeof collectionName !== "string" || collectionName.trim() === "") {
    throw new Error("Invalid collection name");
  }

  if (typeof documentId !== "string" || documentId.trim() === "") {
    throw new Error("Invalid document ID");
  }

  if (typeof updatedFields !== "object" || updatedFields === null) {
    throw new Error("Invalid updated fields object");
  }

  const db = getFirestore();
  const docRef = doc(db, collectionName, documentId);

  try {
    await runTransaction(db, async (transaction) => {
      const docSnap = await transaction.get(docRef);
      if (!docSnap.exists()) {
        throw new Error("Document does not exist");
      }

      const docData = docSnap.data();
      const updates = {};

      const buildUpdates = (prefix, obj) => {
        Object.keys(obj).forEach((key) => {
          const value = obj[key];
          const newKey = prefix ? `${prefix}.${key}` : key;
          if (Array.isArray(value)) {
            // Используем arrayUnion для массивов
            updates[newKey] = arrayUnion(...value);
          } else if (typeof value === "object" && value !== null) {
            buildUpdates(newKey, value);
          } else {
            updates[newKey] = value;
          }
        });
      };

      buildUpdates("", updatedFields);

      // Применяем обновления к текущим данным документа
      Object.keys(updates).forEach((key) => {
        const keys = key.split(".");
        let current = docData;
        for (let i = 0; i < keys.length - 1; i++) {
          if (!current[keys[i]]) {
            current[keys[i]] = {};
          }
          current = current[keys[i]];
        }
        if (Array.isArray(current[keys[keys.length - 1]])) {
          current[keys[keys.length - 1]] = arrayUnion(updates[key])[0];
        } else {
          current[keys[keys.length - 1]] = updates[key];
        }
      });

      // Обновляем документ
      transaction.update(docRef, updates);
    });

    console.log("Document successfully updated!");
  } catch (error) {
    console.error("Error updating document: ", error);
    throw error;
  }
};

// export const updateDocumentFields = async (
//   collectionName,
//   documentId,
//   updatedFields
// ) => {
//   if (typeof collectionName !== "string" || collectionName.trim() === "") {
//     throw new Error("Invalid collection name");
//   }

//   if (typeof documentId !== "string" || documentId.trim() === "") {
//     throw new Error("Invalid document ID");
//   }

//   if (typeof updatedFields !== "object" || updatedFields === null) {
//     throw new Error("Invalid updated fields object");
//   }

//   const db = getFirestore();
//   const docRef = doc(db, collectionName, documentId);

//   try {
//     await runTransaction(db, async (transaction) => {
//       const docSnap = await transaction.get(docRef);
//       if (!docSnap.exists()) {
//         throw new Error("Document does not exist");
//       }

//       const docData = docSnap.data();
//       const updates = {};

//       const buildUpdates = (prefix, obj) => {
//         Object.keys(obj).forEach((key) => {
//           const value = obj[key];
//           const newKey = prefix ? `${prefix}.${key}` : key;
//           if (Array.isArray(value)) {
//             // Если значение - массив, используем arrayUnion
//             updates[newKey] = arrayUnion(...value);
//           } else if (typeof value === "object" && value !== null) {
//             // Если значение - объект, рекурсивно обрабатываем его
//             buildUpdates(newKey, value);
//           } else {
//             updates[newKey] = value;
//           }
//         });
//       };

//       buildUpdates("", updatedFields);

//       // Применяем обновления к текущим данным документа
//       Object.keys(updates).forEach((key) => {
//         const keys = key.split(".");
//         let current = docData;
//         for (let i = 0; i < keys.length - 1; i++) {
//           if (!current[keys[i]]) {
//             current[keys[i]] = {};
//           }
//           current = current[keys[i]];
//         }
//         current[keys[keys.length - 1]] = updates[key];
//       });

//       // Обновляем документ
//       transaction.update(docRef, docData);
//     });

//     console.log("Document successfully updated!");
//   } catch (error) {
//     console.error("Error updating document: ", error);
//     throw error;
//   }
// };

/**
 * Добавляет новый документ в указанную коллекцию Firestore и возвращает добавленные данные вместе с идентификатором документа.
 *
 * @param {string} collectionName - Название коллекции Firestore, в которую нужно добавить документ.
 * @param {Object} newData - Объект с данными, которые нужно добавить в коллекцию.
 * @returns {Promise<Object>} Объект, содержащий идентификатор добавленного документа и добавленные данные.
 * @throws {Error} Возникает при ошибке добавления данных в Firestore.
 * @example
 * // Пример использования функции addData
 * const newUserData = {
 *   name: "John Doe",
 *   email: "johndoe@example.com",
 *   createdAt: new Date(),
 * };
 * addData("users", newUserData)
 *   .then((addedData) => {
 *     console.log("Данные успешно добавлены:", addedData);
 *   })
 *   .catch((error) => {
 *     console.error("Ошибка при добавлении данных:", error);
 *   });
 */
export const addData = async (collectionName, newData) => {
  if (typeof collectionName !== "string" || collectionName.trim() === "") {
    throw new Error("Invalid collection name");
  }

  if (typeof newData !== "object" || newData === null) {
    throw new Error("Invalid data object");
  }

  try {
    const dataCollection = collection(db, collectionName);
    const docRef = await addDoc(dataCollection, newData);
    const addedData = { id: docRef.id, ...newData };
    return addedData;
  } catch (error) {
    throw new Error(`Error adding document to Firestore: ${error.message}`);
  }
};

/**
 * Удаляет документ из указанной коллекции Firestore по его идентификатору.
 *
 * @param {string} collectionName - Название коллекции Firestore, из которой нужно удалить документ.
 * @param {string} documentId - Идентификатор документа, который нужно удалить.
 * @returns {Promise<void>} Промис, который разрешается при успешном удалении документа.
 * @throws {Error} Возникает при ошибке удаления документа из Firestore.
 * @example
 * // Пример использования функции deleteData
 * deleteData("users", "documentId123")
 *   .then(() => {
 *     console.log("Документ успешно удален");
 *   })
 *   .catch((error) => {
 *     console.error("Ошибка при удалении документа:", error);
 *   });
 */
export const deleteData = async (collectionName, documentId) => {
  if (typeof collectionName !== "string" || collectionName.trim() === "") {
    throw new Error("Invalid collection name");
  }

  if (typeof documentId !== "string" || documentId.trim() === "") {
    throw new Error("Invalid document ID");
  }

  const db = getFirestore();
  const docRef = doc(db, collectionName, documentId);

  try {
    await deleteDoc(docRef);
    console.log("Document successfully deleted!");
  } catch (error) {
    throw new Error(`Error deleting document from Firestore: ${error.message}`);
  }
};

/**
 * Удаляет файлы из указанной коллекции Firestore и Firebase Storage.
 *
 * @param {string} collectionName - Название коллекции Firestore, из которой нужно удалить файлы.
 * @param {Array<string>} documentIds - Массив идентификаторов документов, из которых нужно удалить файлы.
 * @param {string} fieldName - Название поля, содержащего данные файлов в документе.
 * @param {Array<string>} fileIds - Массив идентификаторов файлов, которые нужно удалить.
 * @returns {Promise<void>}
 * @throws {Error} Возникает при ошибке удаления файлов.
 * @example
 * // Пример использования функции deleteFiles
 * const collectionName = "users";
 * const documentIds = ["doc1", "doc2"];
 * const fieldName = "files";
 * const fileIds = ["file1", "file2"];
 * deleteFiles(collectionName, documentIds, fieldName, fileIds)
 *   .then(() => {
 *     console.log("Файлы успешно удалены.");
 *   })
 *   .catch((error) => {
 *     console.error("Ошибка при удалении файлов:", error);
 *   });
 */

export const removeFiles = async (
  collectionName,
  documentIds,
  fieldName,
  fileIds
) => {
  try {
    console.log("removeFiles", collectionName, documentIds, fieldName, fileIds);
    // Проверка и преобразование documentIds в массив, если это не массив
    if (!Array.isArray(documentIds)) {
      documentIds = [documentIds];
    }

    // Проверка на пустоту массива documentIds
    if (documentIds.length === 0) {
      throw new Error("Invalid document IDs array");
    }

    // Проверка других параметров
    if (typeof collectionName !== "string" || collectionName.trim() === "") {
      throw new Error("Invalid collection name");
    }

    if (typeof fieldName !== "string" || fieldName.trim() === "") {
      throw new Error("Invalid field name");
    }

    if (!Array.isArray(fileIds) || fileIds.length === 0) {
      throw new Error("Invalid file IDs array");
    }

    // Удаляем файлы из хранилища (storage) для каждого документа
    await Promise.all(
      documentIds.map(async (documentId) => {
        await removeFileFirebase(
          collectionName,
          documentId,
          fieldName,
          fileIds
        );
      })
    );

    // Обходим каждый документ
    for (const documentId of documentIds) {
      const docRef = doc(db, collectionName, documentId);
      const docSnap = await getDoc(docRef);
      const documentData = docSnap.data();

      // Проверяем наличие данных документа
      if (!documentData) {
        throw new Error(`Документ с ID ${documentId} не найден.`);
      }

      // Разделяем составное имя поля на части
      const fieldParts = fieldName.split(".");
      let fileData = documentData;

      // Последовательно получаем доступ к вложенным объектам
      for (const part of fieldParts) {
        if (fileData[part] !== undefined) {
          fileData = fileData[part];
        } else {
          throw new Error(
            `Поле ${fieldName} не найдено в документе с ID ${documentId}`
          );
        }
      }

      // Проверяем наличие данных файла
      if (!fileData) {
        throw new Error(
          `Данные файла не найдены для документа с ID ${documentId}.`
        );
      }

      let updatedFileData = null;

      // Обновляем данные файла
      if (Array.isArray(fileData)) {
        // Если данные файла представлены в виде массива
        updatedFileData = fileData.filter((file) => !fileIds.includes(file.id));
      } else if (typeof fileData === "object") {
        // Если данные файла представлены в виде объекта
        const updatedFiles = { ...fileData };
        for (const fileId of fileIds) {
          delete updatedFiles[fileId];
        }
        updatedFileData = updatedFiles;
      } else {
        throw new Error("Недопустимый тип данных файла.");
      }

      // Обновляем вложенное поле в документе
      let updatedDocumentData = { ...documentData };
      let nestedObject = updatedDocumentData;
      for (let i = 0; i < fieldParts.length - 1; i++) {
        nestedObject = nestedObject[fieldParts[i]];
      }
      nestedObject[fieldParts[fieldParts.length - 1]] = updatedFileData;

      // Обновляем документ, удаляя ссылки на удаленные файлы
      await updateDoc(docRef, updatedDocumentData);

      console.log(
        `deleteFiles Файлы \n${fileIds.join(
          "\n "
        )} \nуспешно удалены из Firestore и Storage для документа с ID ${documentId}.`
      );
    }
  } catch (error) {
    console.error("Ошибка удаления файлов:", error);
  }
};

export const removeFilesV4 = async (
  collectionName,
  documentIds,
  fieldName,
  fileIds
) => {
  try {
    console.log("removeFiles", collectionName, documentIds, fieldName, fileIds);

    // Проверка и преобразование documentIds в массив, если это не массив
    if (!Array.isArray(documentIds)) {
      documentIds = [documentIds];
    }

    // Проверка на пустоту массива documentIds
    if (documentIds.length === 0) {
      throw new Error("Invalid document IDs array");
    }

    // Проверка других параметров
    if (typeof collectionName !== "string" || collectionName.trim() === "") {
      throw new Error("Invalid collection name");
    }

    if (typeof fieldName !== "string" || fieldName.trim() === "") {
      throw new Error("Invalid field name");
    }

    if (!Array.isArray(fileIds) || fileIds.length === 0) {
      throw new Error("Invalid file IDs array");
    }

    // Удаляем файлы из хранилища (storage) для каждого документа
    await Promise.all(
      documentIds.map(async (documentId) => {
        await removeFileFirebase(
          collectionName,
          documentId,
          fieldName,
          fileIds
        );
      })
    );

    let finalFiles = {};

    // Обходим каждый документ
    for (const documentId of documentIds) {
      const docRef = doc(db, collectionName, documentId);
      const docSnap = await getDoc(docRef);
      const documentData = docSnap.data();

      // Проверяем наличие данных документа
      if (!documentData) {
        throw new Error(`Документ с ID ${documentId} не найден.`);
      }

      // Разделяем составное имя поля на части
      const fieldParts = fieldName.split(".");
      let fileData = documentData;

      // Последовательно получаем доступ к вложенным объектам
      for (const part of fieldParts) {
        if (fileData[part] !== undefined) {
          fileData = fileData[part];
        } else {
          throw new Error(
            `Поле ${fieldName} не найдено в документе с ID ${documentId}`
          );
        }
      }

      // Проверяем наличие данных файла
      if (!fileData) {
        throw new Error(
          `Данные файла не найдены для документа с ID ${documentId}.`
        );
      }

      let updatedFileData = null;

      // Обновляем данные файла
      if (Array.isArray(fileData)) {
        // Если данные файла представлены в виде массива
        updatedFileData = fileData.filter((file) => !fileIds.includes(file.id));
      } else if (typeof fileData === "object") {
        // Если данные файла представлены в виде объекта
        const updatedFiles = { ...fileData };
        for (const fileId of fileIds) {
          delete updatedFiles[fileId];
        }
        updatedFileData = updatedFiles;
      } else {
        throw new Error("Недопустимый тип данных файла.");
      }

      // Обновляем вложенное поле в документе
      let updatedDocumentData = { ...documentData };
      console.log("updatedDocumentData", updatedDocumentData);

      let nestedObject = updatedDocumentData;
      for (let i = 0; i < fieldParts.length - 1; i++) {
        nestedObject = nestedObject[fieldParts[i]];
      }
      nestedObject[fieldParts[fieldParts.length - 1]] = updatedFileData;

      // Обновляем документ, удаляя ссылки на удаленные файлы
      await updateDoc(docRef, updatedDocumentData);

      // Сохраняем обновленный массив файлов для возвращения
      finalFiles = updatedDocumentData;
    }

    return finalFiles;
  } catch (error) {
    console.error("Ошибка удаления файлов:", error);
    throw error;
  }
};

/**
 * Удаляет файлы из Firebase Storage и обновляет документ в Firestore.
 *
 * @param {string} collectionName - Название коллекции Firestore, из которой нужно удалить файлы.
 * @param {string} documentId - Идентификатор документа, из которого нужно удалить файлы.
 * @param {string} fieldName - Название поля, содержащего данные файлов в документе.
 * @param {Array<string>} fileIds - Массив идентификаторов файлов, которые нужно удалить.
 * @returns {Promise<void>}
 * @throws {Error} Возникает при ошибке удаления файлов.
 * @example
 * // Пример использования функции deleteFileFirebase
 * const collectionName = "users";
 * const documentId = "doc1";
 * const fieldName = "files";
 * const fileIds = ["file1", "file2"];
 * deleteFileFirebase(collectionName, documentId, fieldName, fileIds)
 *   .then(() => {
 *     console.log("Файлы успешно удалены.");
 *   })
 *   .catch((error) => {
 *     console.error("Ошибка при удалении файлов:", error);
 *   });
 */

export const removeFileFirebase = async (
  collectionName,
  documentId,
  fieldName,
  fileIds
) => {
  try {
    const docRef = doc(db, collectionName, documentId);
    const docSnap = await getDoc(docRef);
    const documentData = docSnap.data();

    if (!documentData) {
      throw new Error(`Документ с ID ${documentId} не найден`);
    }

    // Разделяем составное имя поля на части
    const fieldParts = fieldName.split(".");
    let fileData = documentData;

    // Последовательно получаем доступ к вложенным объектам
    for (const part of fieldParts) {
      if (fileData[part] !== undefined) {
        fileData = fileData[part];
      } else {
        throw new Error(
          `Поле ${fieldName} не найдено в документе с ID ${documentId}`
        );
      }
    }

    // Проверяем, что fileData является массивом
    if (!Array.isArray(fileData)) {
      throw new Error(`Поле ${fieldName} должно быть массивом`);
    }

    // Удаляем файлы из хранилища
    for (const fileId of fileIds) {
      const file = fileData.find((file) => file.id === fileId);

      if (file) {
        const storageRef = ref(storage, `files/${file.name}`);
        try {
          await deleteObject(storageRef);
          console.log(`Файл ${file.name} успешно удален из Firebase Storage.`);
        } catch (error) {
          if (error.code === "storage/object-not-found") {
            console.warn(
              `Файл ${file.name} не найден в Firebase Storage, пропускаем.`
            );
          } else {
            throw error; // Если это другая ошибка, выбрасываем ее
          }
        }
      } else {
        console.warn(`Файл с ID ${fileId} не найден в поле ${fieldName}`);
      }
    }

    // Обновляем данные файла
    const updatedFileData = fileData.filter(
      (file) => !fileIds.includes(file.id)
    );

    // Обновляем вложенное поле в документе
    let updatedDocumentData = { ...documentData };
    let nestedObject = updatedDocumentData;
    for (let i = 0; i < fieldParts.length - 1; i++) {
      nestedObject = nestedObject[fieldParts[i]];
    }
    nestedObject[fieldParts[fieldParts.length - 1]] = updatedFileData;

    await updateDoc(docRef, updatedDocumentData);

    console.log(
      `deleteFileFirebase Файлы \n${fileIds.join(
        "\n "
      )} \nуспешно удалены из Firestore и Storage для документа с ID ${documentId}.`
    );
  } catch (error) {
    console.error(
      `Ошибка при удалении файлов для документа с ID ${documentId}:`,
      error
    );
  }
};

export const uploadFiles = async (
  collectionName,
  documentId,
  fieldName,
  files
) => {
  const documentIdAsString = documentId ? documentId.toString() : "";
  const fileArray = Array.isArray(files) ? files : [files];

  try {
    for (const file of fileArray) {
      const extension = file.name.split(".").pop();
      const fileId = uuidv4();
      const newFileName = `${fileId}.${extension}`;
      const storageRef = ref(storage, `files/${newFileName}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      await new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          () => {},
          (error) => {
            reject(new Error(`Error uploading file: ${error.message}`));
          },
          async () => {
            const downloadUrl = await getDownloadURL(storageRef);
            const newFileData = {
              name: newFileName,
              url: downloadUrl,
              id: fileId,
              priority: false,
            };

            const docRef = doc(db, collectionName, documentIdAsString);
            const docSnap = await getDoc(docRef);
            const documentData = docSnap.data() || {};

            // Разделяем составное имя поля на части
            const fieldParts = fieldName.split(".");
            let nestedObject = documentData;

            // Последовательно получаем доступ к вложенным объектам
            for (let i = 0; i < fieldParts.length - 1; i++) {
              if (!nestedObject[fieldParts[i]]) {
                nestedObject[fieldParts[i]] = {};
              }
              nestedObject = nestedObject[fieldParts[i]];
            }

            // Добавляем новый файл в массив
            if (
              !Array.isArray(nestedObject[fieldParts[fieldParts.length - 1]])
            ) {
              nestedObject[fieldParts[fieldParts.length - 1]] = [];
            }
            nestedObject[fieldParts[fieldParts.length - 1]].push(newFileData);

            await updateDoc(docRef, documentData);

            resolve();
          }
        );
      });
    }
    console.log("Все файлы успешно загружены.");
  } catch (error) {
    console.error("Ошибка при загрузке файлов:", error.message);
    throw new Error(error.message);
  }
};

/**
 * Обновляет существующий документ в указанной коллекции Firestore и возвращает обновленные данные.
 *
 * @param {string} collectionName - Название коллекции Firestore, в которой находится документ.
 * @param {string} id - Идентификатор документа, который нужно обновить.
 * @param {Object} newData - Объект с новыми данными для обновления документа.
 * @returns {Promise<Object>} Объект, содержащий обновленные данные документа.
 * @throws {Error} Возникает при ошибке обновления данных в Firestore.
 * @example
 * // Пример использования функции updateData
 * const updatedUserData = {
 *   name: "Jane Doe",
 *   email: "janedoe@example.com",
 * };
 * updateData("users", "user123", updatedUserData)
 *   .then((updatedData) => {
 *     console.log("Данные успешно обновлены:", updatedData);
 *   })
 *   .catch((error) => {
 *     console.error("Ошибка при обновлении данных:", error);
 *   });
 */
export const updateDocument = async (collectionName, id, newData) => {
  // Проверка параметра collectionName
  if (typeof collectionName !== "string" || collectionName.trim() === "") {
    throw new Error("Invalid collection name");
  }

  // Проверка параметра id
  if (typeof id !== "string" || id.trim() === "") {
    throw new Error("Invalid document ID");
  }

  // Проверка параметра newData
  if (typeof newData !== "object" || newData === null) {
    throw new Error("Invalid data object");
  }

  // Исключение поля files из newData по причине того, что с полем files работает другой компонент WindgetUploadFiles.js
  const { files, ...dataToUpdate } = newData;

  try {
    const dataDocRef = doc(db, collectionName, id);
    await updateDoc(dataDocRef, dataToUpdate);
    const updatedData = await getDoc(dataDocRef);
    return updatedData.data();
  } catch (error) {
    throw new Error(`Error updating document in Firestore: ${error.message}`);
  }
};

// ------------------- files functions -------------------

// Функция для доступа к вложенным свойствам объекта
const getNestedProperty = (obj, path) => {
  return path.split(".").reduce((acc, part) => {
    const match = part.match(/(\w+)\[(\d+)\]/);
    if (match) {
      const [, key, index] = match;
      return acc && acc[key] && acc[key][index];
    }
    return acc && acc[part];
  }, obj);
};

// Функция для обновления вложенного свойства объекта
const setNestedProperty = (obj, path, value) => {
  const parts = path.split(".");
  const last = parts.pop();
  const target = parts.reduce((acc, part) => {
    const match = part.match(/(\w+)\[(\d+)\]/);
    if (match) {
      const [, key, index] = match;
      if (!acc[key]) acc[key] = [];
      if (!acc[key][index]) acc[key][index] = {};
      return acc[key][index];
    }
    if (!acc[part]) acc[part] = {};
    return acc[part];
  }, obj);
  target[last] = value;
};

// Преобразование пути для Firestore
const convertPathForFirestore = (path) => {
  return path
    .split(".")
    .map((part) => {
      const match = part.match(/(\w+)\[(\d+)\]/);
      if (match) {
        const [, key, index] = match;
        return `${key}.${index}`;
      }
      return part;
    })
    .join(".");
};

// Обновление документа в Firestore
export const updateFieldsFiles = async (
  collectionName,
  documentId,
  updatedFields
) => {
  try {
    await runTransaction(db, async (transaction) => {
      const docRef = doc(db, collectionName, documentId);
      const docSnap = await transaction.get(docRef);

      if (!docSnap.exists()) {
        throw new Error("Document does not exist");
      }

      const data = docSnap.data();
      console.log("Current document data:", data);

      for (const [fieldPath, newValue] of Object.entries(updatedFields)) {
        const currentFieldValue = getNestedProperty(data, fieldPath);
        console.log(`Current value of ${fieldPath}:`, currentFieldValue);

        if (currentFieldValue === undefined) {
          throw new Error(`Field ${fieldPath} does not exist`);
        }

        // Обновляем значение поля
        setNestedProperty(data, fieldPath, newValue);
        console.log(`Updated value of ${fieldPath}:`, newValue);

        // Преобразуем путь для Firestore
        const firestoreFieldPath = convertPathForFirestore(fieldPath);
        console.log("Преобразуем путь для Firestore", firestoreFieldPath);

        // Обновляем документ
        transaction.update(docRef, { [firestoreFieldPath]: newValue });
        console.log("Обновляем документ");
      }
    });

    return { success: true };
  } catch (error) {
    console.error("Error in updateFieldsFiles:", error);
    return { success: false, errorMessage: error.message };
  }
};

// Функция для загрузки файлов в Firebase Storage
export const uploadFilesToFirebase = async (path, files) => {
  try {
    const fileUploadPromises = files.map(async (file) => {
      console.log("Загрузка файла:", file.name);

      // Создаем ссылку на файл в Firebase Storage по переданному пути
      const storageRef = ref(storage, `${path}/${file.name}`);
      const uploadResult = await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(uploadResult.ref);

      console.log("Файл успешно загружен:", {
        name: file.name,
        url: downloadURL,
      });

      return {
        id: file.name,
        url: downloadURL,
        name: file.name,
        priority: false,
      };
    });

    const uploadedFiles = await Promise.all(fileUploadPromises);

    console.log("Все файлы успешно загружены:", uploadedFiles);

    return uploadedFiles;
  } catch (error) {
    console.error("Ошибка при загрузке файлов:", error);
    throw new Error("Ошибка при загрузке файлов");
  }
};
