/* global grecaptcha */
import { httpsCallable } from "firebase/functions";
import { functions } from "../services/firebase/authFirebase";

const verifyReCAPTCHA = async (actionName, grade) => {
  const siteKey = "6Led-kwpAAAAAMQ6ht4k6l3_5d3cpw98d7KV00j4";

  if (typeof grecaptcha !== "undefined" && grecaptcha.enterprise) {
    try {
      const token = await grecaptcha.enterprise.execute(siteKey, {
        action: actionName,
      });
      console.error(token);

      const reCAPTCHA = httpsCallable(functions, "reCAPTCHA100-reCAPTCHA");
      const result = await reCAPTCHA({
        projectID: "biketripste",
        recaptchaKey: siteKey,
        token,
        recaptchaAction: actionName,
      });

      // Сравнение отклика reCAPTCHA с grade и возвращение результата
      return result.data.score >= grade;
    } catch (error) {
      console.error("Error:", error);
      return false;
    }
  } else {
    console.error("reCAPTCHA has not been loaded");
    return false;
  }
};

export default verifyReCAPTCHA;
