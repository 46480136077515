import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchData } from "../../services/firebase/crudFirebaseStorage";

// City
export const fetchCityData = createAsyncThunk(
  "city/fetchCityData/front",
  async () => {
    try {
      // Используем setTimeout для создания задержки в 1 секунду
      await new Promise((resolve) => setTimeout(resolve, 1000));

      const cityData = await fetchData("city");
      console.log("cityData ✅", cityData);
      return cityData;
    } catch (error) {
      throw new Error(`Ошибка при получении данных cityData: ${error.message}`);
    }
  }
);
