import React, { useEffect, useState } from "react";
import "./bikes.css";
import AddData from "../WidgetsProfile/AddData/AddData";
import PopupDirrectory from "../WidgetsProfile/Popups/PopupDirrectory";
import WindowBikeBasicInformation from "./WindowBikeBasicInformation";
import WindowBikeBasicInformationModeration from "../Moderation/WindowBikeBasicInformationModeration";
import CollapsableText from "../WidgetsProfile/Collapsable/CollapsableText";

const WidgetBikeBasicInformation = ({ mode, basicInformation }) => {
  const [windowBikeBasicInformation, setWindowBikeBasicInformation] =
    useState(false);
  const [
    windowBikeBasicInformationModeration,
    setWindowBikeBasicInformationModeration,
  ] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [editButton, setEditButton] = useState(false);

  useEffect(() => {
    setTitle(basicInformation?.title?.titleOriginal || "Add bike name");
    setDescription(
      basicInformation?.description?.descriptionOriginal ||
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
    );
    setEditButton(
      basicInformation?.title?.titleOriginal &&
        basicInformation?.description?.descriptionOriginal
    );
  }, [basicInformation]);

  const handleToggle = () => {
    if (mode === "new" || mode === "edit") {
      setWindowBikeBasicInformation(true);
    } else if (mode === "moderation" || mode === "moderatorAdded") {
      setWindowBikeBasicInformationModeration(true);
    }
  };

  const handleClose = () => {
    setWindowBikeBasicInformation(false);
    setWindowBikeBasicInformationModeration(false);
  };

  return (
    <>
      <div className="profile-widget__content-box">
        <AddData
          icon={
            editButton ? (
              <span className="material-symbols-outlined">edit</span>
            ) : (
              <span className="material-symbols-outlined">add</span>
            )
          }
          title={title}
          description={description && <CollapsableText text={description} />}
          onClick={handleToggle}
          onClose={handleClose}
        />
      </div>

      <PopupDirrectory
        isOpen={windowBikeBasicInformation}
        onClose={handleClose}
        title="Bike name adn description"
      >
        <WindowBikeBasicInformation mode={mode} onClose={handleClose} />
      </PopupDirrectory>

      <PopupDirrectory
        isOpen={windowBikeBasicInformationModeration}
        onClose={handleClose}
        title="Bike name adn description moderation 100"
      >
        <WindowBikeBasicInformationModeration
          mode={mode}
          onClose={handleClose}
        />
      </PopupDirrectory>
    </>
  );
};

export default WidgetBikeBasicInformation;
