import { Link } from "react-router-dom";
import WigetSelectedPopup from "./WigetSelectedPopup";
import { useSelector } from "react-redux";
import ImageWithLoader from "../../UIComponents/bars/gallery/ImageWithLoader";
import { useEffect, useState } from "react";
import Preloader from "../../UIElements/Preloader";
import {
  setCurrentCity,
  setCurrentCountry,
  setCityIdFilter,
  setBrandIdFilter,
  setCategoryIdFilter,
  setRentalPointIdFilter,
  setCurrentRentalPoint,
  setCurrentMinMaxPrice,
} from "../../../slice/stateFrontGUISlice";
import { useDispatch } from "react-redux";

const WigetSelectLocation = () => {
  const dispatch = useDispatch();

  const [activePopup, setActivePopup] = useState(null);
  const [countryAndCityes, setCountryAndCityes] = useState({});

  // Список стран и городов а также количество байков в каждом из городов
  const fullDataLocation = useSelector(
    (state) => state.stateFrontGUISlice.fullDataLocation
  );
  // Установленное текущее значение страны
  const currentCountry = useSelector(
    (state) => state.stateFrontGUISlice.currentCountry
  );

  // Установленное текущее значение города
  const currentCity = useSelector(
    (state) => state.stateFrontGUISlice.currentCity
  );

  // Страна в которой больше всего байков
  const defaultCountry = useSelector(
    (state) => state.stateFrontGUISlice.defaultCountry
  );

  // Город в котором больше всего байков
  const defaultCity = useSelector(
    (state) => state.stateFrontGUISlice.defaultCity
  );

  // состояние загрузки данных
  const isLoading = useSelector((state) => state.stateFrontGUISlice.isLoading);

  // Обработчик открытия попапа.
  const handleOpenPopup = (popupName) => {
    setActivePopup(popupName);
  };

  // Обработчик закрытия попапа.
  const handleClosePopup = () => {
    setActivePopup(null);
  };

  useEffect(() => {
    // Функция для проверки пустоты объекта
    const isObjectEmpty = (obj) => Object.keys(obj).length === 0;
    // Установка выбранных значений

    if (
      currentCountry &&
      !isObjectEmpty(currentCountry) &&
      fullDataLocation &&
      !isObjectEmpty(fullDataLocation)
    ) {
      const foundCityList = fullDataLocation.find(
        (location) => location.id === currentCountry.id
      );

      // Если объект найден, создаём соответствующий state
      if (foundCityList) {
        setCountryAndCityes(foundCityList);
        // Если в state нет объекта выбранного города
        if (isObjectEmpty(currentCity)) {
          dispatch(setCurrentCity(foundCityList.cities[0]));
          dispatch(setCityIdFilter(foundCityList.cities[0].id));
          // Если в state ЕСТЬ объект выбранного города
        } else {
          dispatch(setCurrentCity(currentCity));
          dispatch(setCityIdFilter(currentCity.id));
        }
      }
    } else if (
      defaultCountry &&
      !isObjectEmpty(defaultCountry) &&
      fullDataLocation &&
      !isObjectEmpty(fullDataLocation)
    ) {
      // console.log("Ищем в списке стран и городов страну по умолчанию");
      //Ищем в списке стран и городов страну по умолчанию
      const foundCityList = fullDataLocation.find(
        (location) => location.id === defaultCountry.id
      );
      // Если объект страна найдена
      if (foundCityList) {
        // console.log("Если объект страна найдена", foundCityList);
        setCountryAndCityes(foundCityList);
        dispatch(setCurrentCity(foundCityList.cities[0]));
        dispatch(setCityIdFilter(foundCityList.cities[0].id));
      }
    } else {
      // Если currentCountry и fullDataLocation пусты, устанавливаем cityList в пустое значение
      setCountryAndCityes([]);
    }
  }, [
    currentCountry,
    fullDataLocation,
    defaultCountry,
    defaultCity,
    currentCity,
    dispatch,
  ]);

  const locationPopup = () => (
    <WigetSelectedPopup
      isOpen={activePopup === "locationPopup"}
      img={currentCountry?.file_photo}
      // flag={countryFlag}
      //   map={map}
      onClose={() => {
        handleClosePopup();
        setActivePopup(null);
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: " 36px",
          height: "100%",
          boxSizing: "border-box",
          gap: "32px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            gap: "16px",
          }}
        >
          <h3
            style={{
              width: "100%",
              textAlign: "start",
            }}
          >
            Select a country and city to search
          </h3>

          <p
            style={{
              width: "60%",
              textAlign: "start",
            }}
            className="p-light"
          >
            To get the search result, please select the country, city and dates
            for which the bike will be rented
          </p>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            height: "100%",
            borderTop: "1px var(--color-gray-100) solid",
            padding: "24px 0",
            borderBottom: "1px var(--color-gray-100) solid",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              cursor: "pointer",
              borderRight: "1px var(--color-gray-100) solid",
              width: "30%",
            }}
          >
            <div
              style={{
                height: "40px",
                padding: " 8px ",
              }}
            >
              <h5>Selected country</h5>
            </div>

            {fullDataLocation?.map((country) => (
              <div
                key={country.id}
                className={`datepicker-country ${
                  countryAndCityes?.id === country?.id ? "selected" : ""
                }`}
                onClick={() => {
                  dispatch(setCurrentCountry(country));
                  dispatch(setCurrentCity({}));
                  // Сброс фильтра брендов
                  dispatch(setBrandIdFilter([]));
                  // Сброс фильтра категорий
                  dispatch(setCategoryIdFilter([]));
                  // Сброс фильтра карты
                  dispatch(setRentalPointIdFilter([]));
                  dispatch(setCurrentRentalPoint([]));
                  // Сброс ценовых фильтров
                  dispatch(setCurrentMinMaxPrice([]));
                }}
              >
                {country.title}
              </div>
            ))}
          </div>

          <div
            style={{
              gridColumn: "span 4",
              paddingLeft: "32px",
              width: "70%",
            }}
          >
            <div
              style={{
                height: "40px",
                padding: " 8px",
              }}
            >
              <h5>Selected city</h5>
            </div>

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
              }}
            >
              {countryAndCityes?.cities?.map((city) => (
                <div
                  key={city.id}
                  className={`datepicker-city ${
                    currentCity.id === city.id ? "selected" : ""
                  }`}
                  onClick={() => dispatch(setCurrentCity(city))}
                >
                  <p>
                    {city.title}
                    {/* {city.id} */}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="popup-button-bur">
          <div className="popupButtonBorder" onClick={handleClosePopup}>
            Cancel
          </div>

          <Link
            to="/catalog"
            className="popupButton"
            type="submit"
            onClick={handleClosePopup}
          >
            Search bike
          </Link>
        </div>
      </div>
    </WigetSelectedPopup>
  );

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "space-between",
          backgroundColor: "var(--color-gray-100)",
          borderRadius: "16px",
          padding: "16px",
          transition: "background-color 0.5s ease",
          cursor: "pointer",
          zIndex: "1000",
          aspectRatio: "1/1",
          // backgroundColor: 'rgba(255, 255, 255, 1)',
          backdropFilter: "blur(10px)",
        }}
        onClick={() => handleOpenPopup(`locationPopup`)}
      >
        {isLoading ? (
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Preloader />
          </div>
        ) : (
          <>
            <div className="wigetSearch-horisontal-flag">
              <p
                style={{
                  fontSize: "0.8rem",
                  color: "var(--color-gray-900)",
                }}
              >
                Location:
              </p>

              <div
                style={{
                  aspectRatio: "1/1",
                  height: "32px",
                  overflow: "hidden",
                  borderRadius: "80px",
                  boxSizing: "border-box",
                }}
              >
                <ImageWithLoader
                  aspectRatio="1/1"
                  url={countryAndCityes?.file_flag}
                  alt={countryAndCityes?.title}
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "2",
              }}
            >
              <h5>{countryAndCityes?.title}</h5>
              <h5> {currentCity?.title}</h5>
            </div>
          </>
        )}
      </div>
      {locationPopup()}
    </>
  );
};
export default WigetSelectLocation;
