import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchData } from "../../services/firebase/crudFirebaseStorage";

// Brand
export const fetchBrandData = createAsyncThunk(
  "brand/fetchBrandData/front",
  async () => {
    try {
      // Используем setTimeout для создания задержки в 1 секунду
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const brandData = await fetchData("brand");
      console.log("brandData ✅", brandData);
      return brandData;
    } catch (error) {
      throw new Error(
        `Ошибка при получении данных brandData: ${error.message}`
      );
    }
  }
);
