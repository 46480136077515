import { createAsyncThunk } from "@reduxjs/toolkit";
import { updateDocumentFields } from "../../../../services/firebase/crudFirestore";

export const deleteCityThunk = createAsyncThunk(
  "countryAndCity/deleteCity",
  async ({ countryId, cityId }, { getState, rejectWithValue }) => {
    if (!countryId || !cityId) {
      return rejectWithValue(
        "Invalid data: countryId and cityId are required."
      );
    }

    try {
      const state = getState();
      const countryDoc = state.countryAndCitySlice.countryWithCities.find(
        (country) => country.id === countryId
      );

      if (!countryDoc) {
        return rejectWithValue("Country not found");
      }

      const updatedCities = countryDoc.cities.filter(
        (city) => city.id !== cityId
      );

      await updateDocumentFields("country", countryId, {
        cities: updatedCities,
      });
      return { countryId, cityId };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
