import React from "react";
import { WigetStoriesDetail } from "./WigetStories/WigetStoriesDetail";
import SearchBars from "../../../../UIComponents/bars/searchBars/SearchBars";
import Icon44 from "../../../../UIComponents/icons/Icon44";
import WigetButtonAdd from "../../../../UniversalWidgets/WigetButtonAdd";
import { setPrivateSideBar } from "../../../../../slice/stateGUISlice";
import { useDispatch } from "react-redux";

const WigetStoriesBox = () => {
  const dispatch = useDispatch();

  return (
    <>
      {/* <div className="wigetRentalPointItsBox">
                <h3>Сторисы</h3>
                <p className='p-primary'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eleifend ipsum vitae nunc gravida facilisis.</p>
            </div> */}
      <SearchBars
        title="Stories"
        iconLeft={
          <Icon44
            click={() => dispatch(setPrivateSideBar(true))}
            icon={
              <span className="material-symbols-outlined">dock_to_right</span>
            }
          />
        }
        iconRight={
          <WigetButtonAdd
            collectionName="stories"
            // dataObject={structureCollection}
            nameButton={`Add stories`}
          />
        }
      />
      <div className="scrollBox">
        <WigetStoriesDetail collectionName="stories" title="Stories" />
      </div>
    </>
  );
};

export default WigetStoriesBox;
