import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import WidgetAddComments from "../UniversalWidgets/Comments/WidgetAddComments";
import WigetViewComments from "../UniversalWidgets/Comments/WigetViewComments";
import TimetableLRentalPoint from "./TimetableLRentalPoint";
import WigetBikesList from "../UniversalWidgets/WigetBikesList";
import WigetAdditionalServices from "./WigetAdditionalServices";
import { calculatePricePercentage } from "../../../functions/calculatePricePercentage";
import Preloader from "../../UIElements/Preloader";
import GoogleMapsFilters from "../../UIComponents/maps/GoogleMapsFilters";
import Timetable from "../ProductCard/Timetable";
import Title from "../ProductCard/Title";
import {
  WigetImageCarousel,
  WigetImageCarousel2,
} from "../UniversalWidgets/WigetImageCarousel";
import AncorNavigation from "./AncorNavigation";
import WidgetStatistic from "../UniversalWidgets/WidgetStatistic";
import { useSubscribeData } from "../../../services/firebase/crudFirebaseStorage";
import WigetSelectedPopupV2 from "../UniversalWidgets/WigetSelectedPopupV2";
import WigetAddComments from "../UniversalWidgets/Comments/WidgetAddComments";
import WidgetFooter from "../UniversalWidgets/WidgetFooter";

const UiRentalPoint = () => {
  const { id } = useParams();
  const currentUser = useSelector((state) => state.userSliceV2.currentUser);
  const role = currentUser ? currentUser.role : null;
  // const isLoading = useSelector((state) => state.userSliceV2.isLoading);

  const { data: commentsData, isLoading: loadingCommentsData } =
    useSubscribeData("comments");

  // const dispatch = useDispatch();

  // const [currentBike, setCurrentBike] = useState({});

  // состояние загрузки данных
  const isLoading = useSelector((state) => state.stateFrontGUISlice.isLoading);

  const fullDataRentalPoints = useSelector(
    (state) => state.stateFrontGUISlice.fullDataRentalPoints
  );

  const foundRentalPoint = fullDataRentalPoints.find(
    (rentalPoint) => rentalPoint.id === id
  );

  // useEffect(() => {
  //   if (id && fullDataBike.length > 0) {
  //     const data = fullDataBike.find((bike) => bike?.rentalPoint?.id === id);
  //     setCurrentBike(data);
  //     setRentalPointId(data.rentalPoint.id);
  //   } else {
  //     setCurrentBike({});
  //     setRentalPointId("");
  //   }
  // }, [id, fullDataBike, dispatch]);

  // const pricePercentage = calculatePricePercentage(currentBike?.rate);

  // useEffect(() => {
  //   if (id && fullDataBike.length > 0) {
  //     dispatch(setCurrentBike(fullDataBike.find((bike) => bike.id === id)));
  //   } else {
  //     dispatch(setCurrentBike({}));
  //   }
  // }, [id, fullDataBike, dispatch]);

  return (
    <>
      {/* <PublicMenu /> */}

      <div className="content-container">
        <div className="content-body">
          <div
            style={{
              gridColumn: "span 2",
            }}
          >
            <h1
              style={{
                fontSize: "3em",
                fontWeight: "300",
              }}
            >
              {foundRentalPoint?.title}
            </h1>
          </div>

          <div
            style={{
              gridColumn: "1",
              display: "flex",
              flexDirection: "column",
              boxSizing: "border-box",
              width: "100%",
              zIndex: "1",
            }}
          >
            <div
              style={{
                position: "sticky",
                top: "0",
              }}
            >
              <AncorNavigation
                commentsData={commentsData}
                rentalPointData={foundRentalPoint}
                loadingCommentsData={loadingCommentsData}
                isLoading={isLoading}
              />
            </div>
          </div>

          <div
            style={{
              gridColumn: "2",
              display: "flex",
              flexDirection: "column",
              gap: "32px",
              boxSizing: "border-box",
            }}
          >
            {isLoading ? (
              <Preloader />
            ) : (
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  backgroundColor: "var(--color-white)",
                  borderRadius: "24px",
                  overflow: "hidden",
                }}
              >
                <Timetable timetableData={foundRentalPoint?.timetableL} />
                <div
                  style={{
                    aspectRatio: "1/1",
                    width: "100%",
                  }}
                >
                  <GoogleMapsFilters rentalPoint={foundRentalPoint} />
                </div>
              </div>
            )}

            {isLoading ? (
              <Preloader />
            ) : (
              <div>
                <WigetBikesList bikes={foundRentalPoint?.bikes} />
              </div>
            )}

            {isLoading ? (
              <Preloader />
            ) : (
              foundRentalPoint?.additionalServices?.length && (
                <div>
                  <WigetAdditionalServices
                    additionalServices={foundRentalPoint?.additionalServices}
                  />
                </div>
              )
            )}

            {isLoading ? (
              <Preloader />
            ) : (
              <div>
                <WidgetAddComments
                  uid={currentUser?.uid}
                  // currentBike={currentBike}
                  rentalPointId={id}
                />
              </div>
            )}

            {isLoading ? (
              <Preloader />
            ) : (
              <WigetViewComments
                rentalPointId={id}
                uid={currentUser?.uid}
                commentsData={commentsData}
                loadingCommentsData={loadingCommentsData}
              />
            )}
          </div>
        </div>
      </div>
      <WidgetFooter />
    </>
  );
};

export default UiRentalPoint;
