// Импорт необходимых модулей и компонентов
import React, { useState, useEffect } from "react";
import "firebase/compat/firestore";
import Button from "../UIElements/Button";
import Popup from "../UIElements/Popup";
import {
  fetchDocumentById,
  updateDocumentById,
} from "../../services/firebase/crudFirebaseStorage";

import { TextareaWrite } from "../UIElements/Textarea";
import CheckboxV2 from "../UIElements/CheckboxV2";
import InputV2 from "../UIElements/InputV2";
export const WigetPriceBlock = ({
  collectionName,
  idDocument,
  fieldName,
  title,
  description,
  quantity,
}) => {
  const [activePopup, setActivePopup] = useState(null);
  const [existingData, setExistingData] = useState(null);
  const [pending, setPending] = useState(false);
  const [loading, setLoading] = useState(false);
  const [changed, setChanged] = useState(false);
  const [markedForDelete, setMarkedForDelete] = useState({});
  const [deleteLoading, setDeleteLoading] = useState(false);

  const maxLength = 1000;

  const [formData, setFormData] = useState({
    [fieldName]: [
      {
        title: "",
        description: "",
        characteristics: "",
        price: 0,
      },
    ],
  });

  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      const data = await fetchDocumentById(collectionName, idDocument);

      if (data && data[fieldName]) {
        setExistingData(data);
        const prices = Array.isArray(data[fieldName])
          ? data[fieldName]
          : [data[fieldName]];
        setFormData((prevFormData) => ({
          ...prevFormData,
          [fieldName]: [...prices],
        }));
        setPending(false);
      }
      setLoading(false);
    };

    fetchUserData();
  }, [collectionName, idDocument, setLoading, fieldName]);

  const handleMarkForDelete = (index, isChecked) => {
    setMarkedForDelete((prevMarkedForDelete) => ({
      ...prevMarkedForDelete,
      [index]: isChecked,
    }));
  };

  const isAnyMarkedForDelete = () => {
    return Object.values(markedForDelete).some((value) => value);
  };

  const handleDeleteMarked = async () => {
    setLoading(true);
    setFormData((prevFormData) => {
      const updatedData = prevFormData[fieldName].filter(
        (_, index) => !markedForDelete[index]
      );
      return { ...prevFormData, [fieldName]: updatedData };
    });
    setMarkedForDelete({});
    setDeleteLoading(true);
    await updateDocumentById(
      {
        [fieldName]: formData[fieldName].filter(
          (_, index) => !markedForDelete[index]
        ),
      },
      collectionName,
      idDocument
    );
    setDeleteLoading(false);
    setChanged(false);
    setLoading(false);
    console.log("Удаленные данные успешно обновлены");
  };

  const handleChange = (e, index, field) => {
    const { value } = e.target;
    setFormData((prevFormData) => {
      const updatedData = [...prevFormData[fieldName]];
      if (index >= 0 && index < updatedData.length && field === "price") {
        updatedData[index][field] = parseFloat(value);
      } else {
        updatedData[index][field] = value;
      }
      return { ...prevFormData, [fieldName]: updatedData };
    });
    setChanged(true);
  };

  const handleAddItemData = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: [
        {
          title: "",
          description: "",
          characteristics: "",
          price: 0,
        },
        ...prevFormData[fieldName],
      ],
    }));
    setPending(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const existingFieldData =
      existingData && existingData[fieldName] ? existingData[fieldName] : [];
    const newFieldData = formData[fieldName].filter(
      (item) => !existingFieldData.includes(item)
    );
    const dataToStore = {
      [fieldName]: [...existingFieldData, ...newFieldData],
    };

    await updateDocumentById(dataToStore, collectionName, idDocument);
    console.log("Данные успешно обновлены");
    setLoading(false);
    setPending(false);
    setLoading(false);
    setChanged(false);
  };

  const handleOpenPopup = (popupName) => {
    setActivePopup(popupName);
  };

  const handleClosePopup = () => {
    setActivePopup(null);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "0 16px",
          boxSizing: "border-box",
        }}
      >
        <div
          style={{
            backgroundColor: "var(--color-white)",
            boxSizing: "border-box",
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              height: "48px",
              padding: "0 16px",
              ...(formData[fieldName].length > 0 && {
                borderBottom: "1px var(--color-gray-200) solid",
              }),
            }}
          >
            <p
              style={{
                textTransform: "uppercase",
              }}
              className="p-light"
            >
              {title}
            </p>

            <button onClick={() => handleOpenPopup(`popu_${fieldName}`)}>
              {formData[fieldName].length > 0 ? "Edit" : "Add"}
            </button>
          </div>

          {formData[fieldName].map(
            (additionServices, index) =>
              additionServices.title && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    // marginLeft: '16px',
                    padding: "4px 4px 4px 16px",
                    boxSizing: "border-box",
                    minHeight: "48px",
                    ...(index > formData[fieldName].length - 1
                      ? {
                          borderBottom: "none",
                        }
                      : {
                          borderBottom: "1px var(--color-gray-200) solid",
                        }),
                  }}
                  key={index}
                  onClick={() => handleOpenPopup(`popu_${fieldName}`)}
                >
                  <div
                    style={{
                      width: "50%",
                    }}
                    className="inform-box-content-left"
                  >
                    <p className="p-primary">{additionServices.title}</p>
                  </div>

                  <div
                    style={{
                      width: "50%",
                    }}
                    className="inform-box-content-right"
                  >
                    <p
                      style={{
                        backgroundColor: "var(--color-gray-200)",
                        padding: "4px 8px",
                        borderRadius: "4px",
                      }}
                      className="p-light"
                    >
                      {additionServices.characteristics}
                    </p>
                  </div>
                </div>
              )
          )}
        </div>
        {description && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "8px 16px",
            }}
          >
            <p className="p-light">{description}</p>
          </div>
        )}
      </div>

      {activePopup !== null && (
        <Popup
          isOpen={true}
          onClose={handleClosePopup}
          title={title}
          leftMenu={
            <>
              {!pending &&
              quantity >= 1 &&
              formData[fieldName].length < quantity ? (
                <Button type="success" onClick={handleAddItemData}>
                  Add
                </Button>
              ) : (
                <Button type="disable">Add</Button>
              )}
              {/* {changed && formData[fieldName].every((value) => value !== "") ? (
                <Button
                  onClick={handleSubmit}
                  disabled={loading}
                  children={loading ? "...Loading" : "Save"}
                />
              ) : (
                <Button type="disable" children={"Save"} />
              )} */}
            </>
          }
          rightMenu={
            <>
              {changed && formData[fieldName].every((value) => value !== "") ? (
                <Button
                  onClick={handleSubmit}
                  disabled={loading}
                  children={loading ? "...Loading" : "Save"}
                />
              ) : (
                <Button type="disable" children={"Save"} />
              )}

              {isAnyMarkedForDelete() ? (
                <Button
                  type="alarm"
                  onClick={handleDeleteMarked}
                  disabled={deleteLoading}
                >
                  {deleteLoading ? "...Loading" : "Delete"}
                </Button>
              ) : (
                <Button type="disable">Delete</Button>
              )}
            </>
          }
        >
          <form onSubmit={handleSubmit}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "48px",
              }}
            >
              {formData[fieldName].map((address, index) => (
                <div key={index} className="inform-box-inputItem">
                  {quantity > 1 && (
                    <CheckboxV2
                      checked={!!markedForDelete[index]}
                      onChange={(isChecked) =>
                        handleMarkForDelete(index, isChecked)
                      }
                    />
                  )}

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "16px",
                      width: "100%",
                    }}
                  >
                    <InputV2
                      label={`Service name — ${index + 1}`}
                      type="text"
                      value={address.title}
                      onChange={(e) => handleChange(e, index, "title")}
                      description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                    />
                    <InputV2
                      label={`Characteristic ${index + 1}`}
                      type="text"
                      value={address.characteristics}
                      onChange={(e) =>
                        handleChange(e, index, "characteristics")
                      }
                      description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                    />
                    <TextareaWrite
                      label={`Description ${index + 1}`}
                      value={address.description}
                      onChange={(e) => handleChange(e, index, "description")}
                      maxLength={maxLength}
                      description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                    />
                    <div
                      style={{
                        width: "300px",
                      }}
                    >
                      <InputV2
                        label={`Price ${index + 1}`}
                        type="number"
                        value={address.price}
                        onChange={(e) => handleChange(e, index, "price")}
                        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </form>
        </Popup>
      )}
    </>
  );
};
