import React from "react";

const WIgetInform = ({
  windowControlOpen,
  windowControlClose,
  img,
  children,
}) => {
  if (!img) {
    img =
      "https://firebasestorage.googleapis.com/v0/b/testbikestripster.appspot.com/o/imgStatic%2Ferr7.jpg?alt=media&token=e2270e9b-8676-4592-aeb8-5e4182f78f66&_gl=1*11g1cum*_ga*NzE3MzY1ODU1LjE2ODQ3NTEzMTQ.*_ga_CW55HF8NVT*MTY5NzQzODQ5OC40LjEuMTY5NzQzOTg1MS40NC4wLjA.";
  }

  return (
    windowControlOpen && (
      <div className="popupBg">
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            borderRadius: "24px",
            position: "absolute",
            width: "800px",
            aspectRatio: "16/9",
            backgroundColor: "var(--color-white)",
            backdropFilter: "blur(10px)",
            zIndex: "100",
            boxSizing: "border-box",
            textAlign: "center",
            overflow: "hidden",
            padding: "8px",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "16px",
              right: "16px",
            }}
          >
            <div className="popup-back-button">
              <span
                style={{
                  color: "rgba(255, 255, 255, 0.8)",
                  borderRadius: "50%",
                  backgroundColor: "rgba(255, 255, 255, 0.3)",
                  height: "40px",
                  aspectRatio: "1/1",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                className="material-symbols-outlined"
                onClick={() => windowControlClose(false)}
              >
                close
              </span>
            </div>
          </div>

          <div
            style={{
              gridColumn: "span 2",
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {children}
          </div>

          <div
            style={{
              gridColumn: "span 1",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "var(--color-gray-200)",
              backgroundImage: `url(${img})`,
              height: "100%",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              borderRadius: "16px",
            }}
          />
        </div>
      </div>
    )
  );
};

export default WIgetInform;
