import React, { useState, useEffect, useRef } from 'react';

const ContextMenu = ({ children, priority }) => {

    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef(null);

    const handleClick = () => {
        setIsOpen(!isOpen);
    };

    const handleMenuItemClick = (menuItem) => {
        console.log('Clicked:', menuItem);
        setIsOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (

        <div className="context-menu" ref={menuRef}>
            <div className={priority ? 'context-menu-button context-menu-button-priority' : 'context-menu-button'} onClick={handleClick}>
                <i className="fa-solid fa-ellipsis-vertical"></i>
            </div>
            {isOpen && (
                <ul className="context-menu-list">
                    {React.Children.map(children, (child) => (
                        <li className="context-menu-item" onClick={() => handleMenuItemClick(child.props.children)}>
                            {child}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default ContextMenu;
