import { useSelector } from "react-redux";
import Preloader from "../../../../UIElements/Preloader";
import ImageWithLoader from "../../../../UIComponents/bars/gallery/ImageWithLoader";
import objectIsEmpty from "../../../../../functions/objectIsEmpty";
import prioritiUrl from "../../../../../functions/prioritiUrl";
import { useEffect, useState } from "react";

const OrderDetail = () => {
  const currentOrderData = useSelector(
    (state) => state.stateFrontGUISlice.currentOrderData
  );
  console.log("OrderDetail-currentOrderData", currentOrderData);
  const isLoadingOrdersData = useSelector(
    (state) => state.stateFrontGUISlice.isLoadingOrdersData
  );

  // Средняя скидка за период
  const averageDiscountRounded =
    currentOrderData?.order_info?.bikeCostDetails?.averageDiscount?.toFixed(2);
  // Стоимость ваучера
  const voucherPrice =
    currentOrderData?.order_info?.bikeCostDetails?.voucherPrice?.toLocaleString(
      "en-US",
      {
        style: "currency",
        currency: "USD",
      }
    );
  // Стоимость со скидкой
  const discountedVoucherPrice =
    currentOrderData?.order_info?.bikeCostDetails?.discountedVoucherPrice?.toLocaleString(
      "en-US",
      {
        style: "currency",
        currency: "USD",
      }
    );
  // Общая стоимость аренды
  const totalPrice =
    currentOrderData?.order_info?.bikeCostDetails?.totalPrice?.toLocaleString(
      "en-US",
      {
        style: "currency",
        currency: "USD",
      }
    );
  // Общая стоимость аренды со скидкой
  const discountedTotalPrice =
    currentOrderData?.order_info?.bikeCostDetails?.discountedTotalPrice?.toLocaleString(
      "en-US",
      {
        style: "currency",
        currency: "USD",
      }
    );
  // Оплата в точке проката
  const paymentAtTheRentalPoint =
    currentOrderData?.order_info?.bikeCostDetails?.paymentAtTheRentalPoint?.toLocaleString(
      "en-US",
      {
        style: "currency",
        currency: "USD",
      }
    );
  // Оплата в точке проката со скидкой
  const discountedPaymentAtTheRentalPoint =
    currentOrderData?.order_info?.bikeCostDetails?.discountedPaymentAtTheRentalPoint?.toLocaleString(
      "en-US",
      {
        style: "currency",
        currency: "USD",
      }
    );
  // Полная экономия благодаря скидке
  const priceBenefit =
    currentOrderData?.order_info?.bikeCostDetails?.priceBenefit?.toLocaleString(
      "en-US",
      {
        style: "currency",
        currency: "USD",
      }
    );
  // Стоимость дополнительных услуг
  const priceAdditionalServises =
    currentOrderData?.order_info?.bikeCostDetails?.priceAdditionalServises?.toLocaleString(
      "en-US",
      {
        style: "currency",
        currency: "USD",
      }
    );
  // Стоимость всех байков
  const totalPricPerBike =
    currentOrderData?.order_info?.bikeCostDetails?.totalPricPerBike?.toLocaleString(
      "en-US",
      {
        style: "currency",
        currency: "USD",
      }
    );

  // Стоимость всех байков со скидкой
  const discountedTotalPricPerBike =
    currentOrderData?.order_info?.bikeCostDetails?.discountedTotalPricPerBike?.toLocaleString(
      "en-US",
      {
        style: "currency",
        currency: "USD",
      }
    );

  // Количество дней в заказе
  const totalDaysForTheRentalPeriod =
    currentOrderData?.order_info?.bikeCostDetails?.totalDaysForTheRentalPeriod;

  // Количество байков
  const quantityBike = currentOrderData?.order_info?.quantityBike;

  // Интервал выбранных дат
  const dateInterval =
    currentOrderData?.order_info?.bikeCostDetails?.dateInterval;
  const dateIntervalTimestamp =
    currentOrderData?.order_info?.bikeCostDetails?.dateIntervalTimestamp;

  const [fromDate, setFromDate] = useState({});
  const [beforeDate, setBeforeDate] = useState({});

  useEffect(() => {
    if (dateIntervalTimestamp && dateIntervalTimestamp.length > 0) {
      // Получить название fromDate
      const fromDate = new Date(dateIntervalTimestamp[0]?.seconds * 1000);
      const fromMonth = fromDate?.toLocaleString("en-US", {
        month: "short",
      });
      const fromYear = fromDate?.getFullYear();
      const fromDay = fromDate?.getDate();
      setFromDate({
        fromMonth: fromMonth,
        fromYear: fromYear,
        fromDay: fromDay,
      });

      // Получить название BeforeDate
      const beforeDate = new Date(dateIntervalTimestamp[1]?.seconds * 1000);
      const beforeMonth = beforeDate?.toLocaleString("en-US", {
        month: "short",
      });
      const beforeYear = beforeDate?.getFullYear();
      const beforeDay = beforeDate?.getDate();
      setBeforeDate({
        beforeMonth: beforeMonth,
        beforeYear: beforeYear,
        beforeDay: beforeDay,
      });
      console.log(
        "Получить название fromDate",
        fromMonth,
        fromYear,
        fromDay,
        "Получить название BeforeDate",
        beforeMonth,
        beforeYear,
        beforeDay
      );
    }
  }, [dateIntervalTimestamp]);

  // фото байка
  const bikePhoto = currentOrderData?.order_info?.currentBike?.file_bikePhoto;

  // Название байка
  const bikeName =
    currentOrderData?.order_info?.currentBike?.title[0] || "Unknown";

  // Описание байка
  const description =
    currentOrderData?.order_info?.currentBike?.description[0] || "Unknown";

  // Категория
  let categoryName = "Unknown";
  if (!objectIsEmpty(currentOrderData?.order_info?.currentBike?.category)) {
    categoryName =
      currentOrderData?.order_info?.currentBike?.category?.title?.[0];
  }

  // Бренд
  let brandName = "Unknown";
  if (!objectIsEmpty(currentOrderData?.order_info?.currentBike?.brand)) {
    brandName =
      currentOrderData?.order_info?.currentBike?.brand?.title[0] || "Unknown";
  }

  // Страна
  const countryName =
    currentOrderData?.order_info?.currentBike?.country?.title[0] || "Unknown";

  // Флаг страны
  const flagСountry =
    currentOrderData?.order_info?.currentBike?.country?.file_flag;

  // Город
  const cityName =
    currentOrderData?.order_info?.currentBike?.city?.title[0] || "Unknown";

  // Название точки проката
  const rentalPointName =
    currentOrderData?.order_info?.currentBike?.rentalPoint?.title[0] ||
    "Unknown";

  // Лого точки проката
  const logoRentalPoint =
    currentOrderData?.order_info?.currentBike?.rentalPoint?.file_logo;

  // Описание точки проката
  const descriptionRentalPoint =
    currentOrderData?.order_info?.currentBike?.rentalPoint?.description[0] ||
    "Unknown";

  // Статусы заказа
  const confirmationReceived =
    currentOrderData?.orderStatus?.onfirmationReceived || "Unknown";

  // Статус замена
  const searchingForReplacement =
    currentOrderData?.orderStatus?.replacement?.searchingForReplacement ||
    "Unknown";

  // Варианты замены
  const replacementFound =
    currentOrderData?.orderStatus?.replacement?.replacementFound || "Unknown";

  return (
    <>
      {!isLoadingOrdersData ? (
        <Preloader />
      ) : (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              width: "100%",
              backgroundColor: "var(--color-priority)",
              padding: "8px",
              boxSizing: "border-box",
              borderRadius: "24px",
              gap: "8px",
            }}
          >
            <div
              style={{
                width: "100%",
                // height: "300px",
                aspectRatio: "16/9",
                backgroundColor: "var(--color-black)",
                borderRadius: "16px",
                color: "var(--color-white)",
                backgroundImage: `url(${prioritiUrl(bikePhoto)})`,
                backgroundSize: "100% auto",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                padding: "32px",
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "space-between",
              }}
            >
              <p>{currentOrderData?.order_info?.currentBike?.id}</p>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "16px",
                backgroundColor: "var(--color-black)",
                borderRadius: "16px",
                color: "var(--color-white)",
                padding: "16px",
                boxSizing: "border-box",
              }}
            >
              <div>
                <p>{fromDate?.fromMonth}</p>
                <p
                  style={{
                    fontSize: "3rem",
                    fontWeight: "bold",
                  }}
                >
                  {fromDate?.fromDay}
                </p>
                <p>{fromDate?.fromYear}</p>
                <p>From:</p>
              </div>

              <div
                style={{
                  width: "30%",
                  height: "4px",
                  backgroundColor: "var(--color-white)",
                  borderRadius: "80px",
                }}
              />
              <div
                style={{
                  backgroundColor: "var(--color-white)",
                  aspectRatio: "1/1",
                  width: "90px",
                  color: "var(--color-gray-900)",
                  boxSizing: "border-box",
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                }}
              >
                <p
                  style={{
                    fontSize: "3rem",
                    fontWeight: "bold",
                    position: "absolute",
                    bottom: "16px",
                  }}
                >
                  {totalDaysForTheRentalPeriod}
                </p>

                <p
                  style={{
                    position: "absolute",
                    bottom: "12px",
                    fontSize: "0.8rem",
                  }}
                >
                  day{totalDaysForTheRentalPeriod > 1 && "s"}
                </p>
              </div>
              <div
                style={{
                  width: "30%",
                  height: "4px",
                  backgroundColor: "var(--color-white)",
                  borderRadius: "80px",
                }}
              />
              <div>
                <p>{beforeDate?.beforeMonth}</p>
                <p
                  style={{
                    fontSize: "3rem",
                    fontWeight: "bold",
                  }}
                >
                  {beforeDate?.beforeDay}
                </p>
                <p>{beforeDate?.beforeYear}</p>
                <p>Before:</p>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "space-between",
                gap: "16px",
                backgroundColor: "var(--color-black)",
                borderRadius: "16px",
                color: "var(--color-white)",
                padding: "16px",
                boxSizing: "border-box",
              }}
            >
              <p>
                Название точки проката: {rentalPointName && rentalPointName}
              </p>
              <div style={{ width: "100px", aspectRatio: "1/1" }}>
                <ImageWithLoader
                  aspectRatio="1/1"
                  url={logoRentalPoint}
                  alt={"logoRentalPoint"}
                />
              </div>
              <p>
                Описание точки проката:
                {descriptionRentalPoint && descriptionRentalPoint}
              </p>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "space-between",
                gap: "16px",
                backgroundColor: "var(--color-black)",
                borderRadius: "16px",
                color: "var(--color-white)",
                padding: "16px",
                boxSizing: "border-box",
              }}
            >
              <p>Категория: {categoryName && categoryName}</p>
              <p>Бренд: {brandName && brandName}</p>
              <p>Название байка: {bikeName && bikeName}</p>
              <p>Описание байка: {description && description}</p>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "space-between",
                gap: "16px",
                backgroundColor: "var(--color-black)",
                borderRadius: "16px",
                color: "var(--color-white)",
                padding: "16px",
                boxSizing: "border-box",
              }}
            >
              <div style={{ width: "100px", aspectRatio: "1/1" }}>
                <ImageWithLoader
                  aspectRatio="1/1"
                  url={flagСountry}
                  alt={"flagСountry"}
                />
              </div>
              <p>Страна: {countryName && countryName}</p>
              <p>Город: {cityName && cityName}</p>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "space-between",
                gap: "16px",
                backgroundColor: "var(--color-black)",
                borderRadius: "16px",
                color: "var(--color-white)",
                padding: "16px",
                boxSizing: "border-box",
              }}
            >
              <div style={{ width: "100px", aspectRatio: "1/1" }}>
                <ImageWithLoader
                  aspectRatio="1/1"
                  url={bikePhoto}
                  alt={"photoBike"}
                />
              </div>
              <p>
                Название байка: {bikeName && bikeName} Количество байков:{" "}
                {quantityBike}
              </p>
              <p>Описание байка: {description && description}</p>

              <p>
                Интервал выбранных дат:
                {dateInterval ? dateInterval[0] : 0}-
                {dateInterval ? dateInterval[1] : 0}
              </p>
              <p>
                Средняя скидка за период:
                {averageDiscountRounded && averageDiscountRounded}%
              </p>
              <p>
                Стоимость ваучера со скидкой:
                {discountedVoucherPrice && discountedVoucherPrice}
              </p>
              <p>
                Общая стоимость аренды со скидкой:
                {discountedTotalPrice && discountedTotalPrice}
              </p>
              <p>
                Оплата в точке проката со скидкой:
                {discountedPaymentAtTheRentalPoint &&
                  discountedPaymentAtTheRentalPoint}
              </p>
            </div>

            {/* <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                width: "100%",
                backgroundColor: "var(--color-white)",
                padding: "24px",
                boxSizing: "border-box",
                borderRadius: "24px",
              }}
            >
              <p>ID: {currentOrderData?.order_info?.currentBike?.id}</p>
              <p>
                Средняя скидка за период:
                {averageDiscountRounded && averageDiscountRounded}
              </p>
              <p>Стоимость ваучера: {voucherPrice && voucherPrice}</p>
              <p>
                Стоимость со скидкой:
                {discountedVoucherPrice && discountedVoucherPrice}
              </p>
              <p>Общая стоимость аренды: {totalPrice && totalPrice}</p>
              <p>
                Общая стоимость аренды со скидкой:
                {discountedTotalPrice && discountedTotalPrice}
              </p>
              <p>
                Оплата в точке проката:
                {paymentAtTheRentalPoint && paymentAtTheRentalPoint}
              </p>
              <p>
                Оплата в точке проката со скидкой:
                {discountedPaymentAtTheRentalPoint &&
                  discountedPaymentAtTheRentalPoint}
              </p>
              <p>
                Полная экономия благодаря скидке: {priceBenefit && priceBenefit}
              </p>
              <p>
                Стоимость дополнительных услуг:
                {priceAdditionalServises && priceAdditionalServises}
              </p>
              <p>
                Стоимость всех байков: {totalPricPerBike && totalPricPerBike}
              </p>
              <p>
                Стоимость всех байков со скидкой:
                {discountedTotalPricPerBike && discountedTotalPricPerBike}
              </p>
              <p>
                Интервал выбранных дат:
                {dateInterval ? dateInterval[0] : 0}-
                {dateInterval ? dateInterval[1] : 0}
              </p>
              <div style={{ width: "100px", aspectRatio: "1/1" }}>
                <ImageWithLoader
                  aspectRatio="1/1"
                  url={bikePhoto}
                  alt={"photoBike"}
                />
              </div>
              <p>Название байка: {bikeName && bikeName}</p>
              <p>Описание байка: {description && description}</p>
              <p>Категория: {categoryName && categoryName}</p>
              <p>Бренд: {brandName && brandName}</p>
              <div style={{ width: "100px", aspectRatio: "1/1" }}>
                <ImageWithLoader
                  aspectRatio="1/1"
                  url={flagСountry}
                  alt={"flagСountry"}
                />
              </div>
              <p>Страна: {countryName && countryName}</p>
              <p>Город: {cityName && cityName}</p>
              <p>
                Название точки проката: {rentalPointName && rentalPointName}
              </p>
              <div style={{ width: "100px", aspectRatio: "1/1" }}>
                <ImageWithLoader
                  aspectRatio="1/1"
                  url={logoRentalPoint}
                  alt={"logoRentalPoint"}
                />
              </div>
              <p>
                Описание точки проката:
                {descriptionRentalPoint && descriptionRentalPoint}
              </p>
            </div> */}

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "space-between",
                gap: "16px",
                backgroundColor: "var(--color-black)",
                borderRadius: "16px",
                color: "var(--color-white)",
                padding: "16px",
                boxSizing: "border-box",
              }}
            >
              <p>Подтверждение получено: {confirmationReceived}</p>
              <p>Поиск для замены: {searchingForReplacement}</p>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "space-between",
                gap: "16px",
                backgroundColor: "var(--color-black)",
                borderRadius: "16px",
                color: "var(--color-white)",
                padding: "16px",
                boxSizing: "border-box",
              }}
            >
              <p>
                {/* {replacementFound.map((replacement) => (
                  <p>{replacement.selected ? "yes" : "no"}</p>
                ))} */}
              </p>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default OrderDetail;
