import React from "react";
import "./subtitles.css";

const SubtitleBlockSection = ({ title, description }) => {
  return (
    <div className="subtitle-block-section">
      <h6>{title}</h6>
      <p className="p-light">{description}</p>
    </div>
  );
};

export default SubtitleBlockSection;
