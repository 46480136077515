import React, { useEffect, useState } from "react";

const WigetRating = ({ grade, setGrade, errDescription }) => {
  const [hoveredRating, setHoveredRating] = useState(0);
  const [selectedRating, setSelectedRating] = useState(0);

  useEffect(() => {
    if (grade === 0) {
      setHoveredRating(0);
      setSelectedRating(0);
    }
  }, [grade]);

  const handleMouseEnter = (rating) => {
    setHoveredRating(rating);
  };

  const handleMouseLeave = () => {
    setHoveredRating(0);
  };

  const handleClick = (rating) => {
    setSelectedRating(rating);
    if (rating) {
      setGrade(rating);
    }
  };

  const getRatingClass = (rating) => {
    const ratingValue = selectedRating || hoveredRating;
    if (rating <= ratingValue) {
      if (ratingValue >= 4) {
        return "active-4-5";
      } else if (ratingValue === 3) {
        return "active-3";
      } else {
        return "active-1-2";
      }
    }
    return ""; // Вернуть пустую строку, если условия не выполняются
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        gap: "16px",
      }}
    >
      <div className="rating-container" onMouseLeave={handleMouseLeave}>
        {[1, 2, 3, 4, 5].map((rating) => (
          <span
            key={rating}
            className={`material-symbols-outlined rating-star ${getRatingClass(
              rating,
              hoveredRating,
              selectedRating
            )}`}
            onMouseEnter={() => handleMouseEnter(rating)}
            onClick={() => handleClick(rating)}
          >
            grade
          </span>
        ))}
      </div>
      <div
        style={{
          backgroundColor:
            errDescription && selectedRating === 0
              ? "var(--color-alarm)"
              : selectedRating >= 4
              ? "var(--color-success)"
              : selectedRating === 3
              ? "var(--color-warning)"
              : selectedRating === 0
              ? "var(--color-gray-600)"
              : "var(--color-alarm)",
          borderRadius: "80px",
          height: "32px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "0 16px",
        }}
      >
        <p
          style={{
            fontSize: "1rem",
            fontWeight: "500",
            color: "var(--color-white)",
          }}
        >
          {selectedRating === 0 && errDescription
            ? "Select rang"
            : `${selectedRating} / 5`}
        </p>
      </div>
    </div>
  );
};

export default WigetRating;
