// configureStore.js
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import createIdbStorage from "redux-persist-indexeddb-storage";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

import rentalPointsSliiceV2 from "../reducersToolkit/rentalPointsSliiceV2";
import bikesSliceV2 from "../reducersToolkit/bikesSliceV2";
import directorySliceV2 from "../reducersToolkit/directorySliceV2";
import moderationSliceV2 from "../reducersToolkit/moderationSliceV2";
import orderSliceV2 from "../reducersToolkit/orderSliceV2";
import navigateSliceV2 from "../reducersToolkit/navigateSliceV2";
import userSliceV2 from "../reducersToolkit/userSliceV2";
import catalogSliceV2 from "../reducersToolkit/catalogSliceV2";
import catalogSliceV4 from "../reducersToolkit/catalogSliceV4";
import rentalPointsSliiceV4 from "../reducersToolkit/rentalPointsSliiceV4";
import bikesSliceV4 from "../reducersToolkit/bikesSliceV4";
// Комбинируем редьюсеры

const rootReducer = combineReducers({
  rentalPointsSliiceV2,
  rentalPointsSliiceV4,

  bikesSliceV2,
  bikesSliceV4,

  directorySliceV2,
  moderationSliceV2,
  orderSliceV2,
  navigateSliceV2,
  userSliceV2,
  catalogSliceV2,
  catalogSliceV4,

  // Добавьте остальные редьюсеры по мере необходимости
});

// Используем IndexedDB вместо localStorage
const persistConfig = {
  key: "root",
  storage: createIdbStorage({ name: "myApp", storeName: "reduxPersist" }), // IndexedDB
  whitelist: [
    // Укажите редьюсеры, которые нужно сохранять в IndexedDB
    "rentalPointsSliiceV2",
    "rentalPointsSliiceV4",

    "bikesSliceV2",
    "bikesSliceV4",

    "directorySliceV2",
    "moderationSliceV2",
    "orderSliceV2",
    "navigateSliceV2",
    "userSliceV2",
    "catalogSliceV2",
    "catalogSliceV4",
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Создаем стор
const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production", // Включение DevTools в режиме разработки
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// Создаем persistor
const persistor = persistStore(store);

export { store, persistor };

// // configureStore.js
// import { configureStore, combineReducers } from "@reduxjs/toolkit";
// import storage from "redux-persist/lib/storage";
// import {
//   persistStore,
//   persistReducer,
//   FLUSH,
//   REHYDRATE,
//   PAUSE,
//   PERSIST,
//   PURGE,
//   REGISTER,
// } from "redux-persist";

// import countryReducer from "../slice/countrySlice";
// import cityReducer from "../slice/citySlice";
// import bikeReducer from "../slice/bikeSlice";
// import brandReducer from "../slice/brandSlice";
// import categoryReducer from "../slice/categorySlice";
// import newsReducer from "../slice/newsSlice";
// import storiesReducer from "../slice/storiesSlice";
// //
// import rentalPointsReducer from "../slice/rentalPointSlice";
// import rentalPointSlice from "../slice/rentalPointSlice";
// //
// import filterResultsSlice from "../slice/filterResultsSlice";
// import currencyReducer from "../slice/currencySlice";
// import orderSlice from "../slice/orderSlice";
// import configSlice from "../slice/configSlice";
// import stateGUISlice from "../slice/stateGUISlice";
// import stateFrontGUISlice from "../slice/stateFrontGUISlice";
// import orderDataSlice from "../slice/orderDataSlice";
// import sideBarSlice from "../slice/private/sideBar/sideBarSlice";
// // new slice
// import rentalPointsSlice from "../slice/private/rentalPoints/rentalPointsSlice";
// import directorySlice from "../slice/general/directory/directorySlice";
// import bikesslice from "../slice/private/bikes/bikesSlice";
// import filesSlice from "../slice/private/files/filesSlice";
// import privateDirectorySlice from "../slice/private/directory/privateDirectorySlice";
// import countryAndCitySlice from "../slice/private/directory/countryAndCity/countryAndCitySlice";
// import moderationSlice from "../slice/private/moderation/moderationSlice";
// import allDirectorySlice from "../slice/private/directory/allDirectorySlice";
// import rentalPointsSliiceV2 from "../reducersToolkit/rentalPointsSliiceV2";
// import bikesSliceV2 from "../reducersToolkit/bikesSliceV2";
// import directorySliceV2 from "../reducersToolkit/directorySliceV2";
// import moderationSliceV2 from "../reducersToolkit/moderationSliceV2";
// import orderSliceV2 from "../reducersToolkit/orderSliceV2";
// import navigateSliceV2 from "../reducersToolkit/navigateSliceV2";
// import userSliceV2 from "../reducersToolkit/userSliceV2";
// import catalogSliceV2 from "../reducersToolkit/catalogSliceV2";

// const rootReducer = combineReducers({
//   // country: countryReducer,
//   // city: cityReducer,
//   // bike: bikeReducer,
//   // brand: brandReducer,
//   // category: categoryReducer,
//   // news: newsReducer,
//   // stories: storiesReducer,
//   // rentalPoints: rentalPointsReducer,
//   // filterResultsSlice: filterResultsSlice,
//   // currency: currencyReducer,
//   user: userSliceV2,
//   // odrerStore: orderSlice,
//   // config: configSlice,
//   // // stateGUISlice: stateGUISlice,
//   // stateFrontGUISlice: stateFrontGUISlice,
//   // orderDataSlice: orderDataSlice,
//   // sideBarSlice: sideBarSlice,
//   // rentalPointSlice: rentalPointSlice,
//   // // new slice
//   // rentalPointsSlice: rentalPointsSlice,
//   // directorySlice: directorySlice,
//   // bikesSlice: bikesslice,
//   // filesSlice: filesSlice,
//   // privateDirectorySlice: privateDirectorySlice,
//   // countryAndCitySlice: countryAndCitySlice,
//   // moderationSlice: moderationSlice,
//   // allDirectorySlice: allDirectorySlice,

//   rentalPointsSliiceV2: rentalPointsSliiceV2,
//   bikesSliceV2: bikesSliceV2,
//   directorySliceV2: directorySliceV2,
//   moderationSliceV2: moderationSliceV2,
//   orderSliceV2: orderSliceV2,
//   navigateSliceV2: navigateSliceV2,
//   userSliceV2: userSliceV2,
//   catalogSliceV2: catalogSliceV2,
// });

// const persistConfig = {
//   key: "root",
//   storage,
//   whitelist: [
//     // "catalogSliceV2",
//     // "userSliceV2",
//     // "navigateSliceV2",
//     // "orderSliceV2",
//     // "moderationSliceV2",
//     // "directorySliceV2",
//     // "bikesSliceV2",
//     // "rentalPointsSliiceV2",
//   ], // Список редьюсеров для сохранения
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// const store = configureStore({
//   reducer: persistedReducer,
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware({
//       serializableCheck: {
//         ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
//       },
//     }),
// });

// const persistor = persistStore(store);

// export { store, persistor };
