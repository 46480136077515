import React, { useState } from "react";
import "../profile.css";
import WidgetRentalPointsList from "./WidgetRentalPointsList";
import AddData from "../WidgetsProfile/AddData/AddData";
import PopupDirrectory from "../WidgetsProfile/Popups/PopupDirrectory";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import WindowRentalPointDetail from "./WindowRentalPointDetail";
import WindowRentalPointDetailV4 from "./WindowRentalPointDetailV4";
import { useDispatch } from "react-redux";
import { clearForm } from "../../../../../reducersToolkit/rentalPointsSliiceV2";
import { clearFormV4 } from "../../../../../reducersToolkit/rentalPointsSliiceV4";

const WidgetRentalPointsV4 = ({ isLoading, rentalPointsList }) => {
  const dispatch = useDispatch();

  const [isOpenWindowRentalPointDetail, setIsOpenWindowRentalPointDetail] =
    useState(false);

  const handleClose = () => {
    setIsOpenWindowRentalPointDetail(false);
  };

  const handleWindowRentalPointDetail = () => {
    dispatch(clearFormV4());
    setIsOpenWindowRentalPointDetail(true);
  };

  return (
    <>
      <ContentBox isLoading={isLoading}>
        <AddData
          onClick={handleWindowRentalPointDetail}
          icon={<span className="material-symbols-outlined">add</span>}
          title="Create rental point"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
        />
        <WidgetRentalPointsList rentalPointsList={rentalPointsList} />
      </ContentBox>

      <PopupDirrectory
        isOpen={isOpenWindowRentalPointDetail}
        onClose={handleClose}
        title="Create rental point V4"
      >
        <WindowRentalPointDetailV4 mode="new" onClose={handleClose} />
      </PopupDirrectory>
    </>
  );
};

export default WidgetRentalPointsV4;
