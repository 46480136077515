import React, { useState } from "react";
import { functions } from "../../services/firebase/authFirebase";
import { getFunctions, httpsCallable } from "firebase/functions";

function WigetTranslator({
  collectionName,
  docId,
  fieldName,
  indexToTranslate,
}) {
  const [result, setResult] = useState(null);

  const callTranslatorGPTOnCall = async ({ language }) => {
    try {
      // Получите ссылку на удаленную функцию
      // const translatorGPTOnCall = functions.httpsCallable(
      //   "translatorGPTOnCall-translatorGPTOnCall"
      // );

      const translatorGPTOnCall = httpsCallable(
        functions,
        "translatorGPTOnCall-translatorGPTOnCall"
      );

      // Передайте данные, если необходимо (зависит от вашей функции)
      const data = {
        docId: docId,
        fieldName: fieldName,
        targetLanguages: [`${language}`], // Список языков для перевода
        collectionName: collectionName,
        indexToTranslate: indexToTranslate,
      };

      // Вызовите удаленную функцию и получите результат
      const response = await translatorGPTOnCall(data);

      // Обработайте результат
      setResult(response.data);
    } catch (error) {
      console.error("Error calling translatorGPTOnCall:", error);
    }
  };

  return (
    <div>
      <button onClick={() => callTranslatorGPTOnCall({ language: "en" })}>
        EN-{collectionName}-{docId}-{fieldName}
      </button>
      <button onClick={() => callTranslatorGPTOnCall({ language: "fr" })}>
        FR-{collectionName}-{docId}-{fieldName}
      </button>
      <button onClick={() => callTranslatorGPTOnCall({ language: "de" })}>
        DE-{collectionName}-{docId}-{fieldName}
      </button>

      {result && (
        <div>
          <p>Успех: {result.success.toString()}</p>
          <p>Сообщение: {result.message}</p>
        </div>
      )}
    </div>
  );
}

export default WigetTranslator;
