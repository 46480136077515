import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AlertProvider } from "./components/UIElements/Alerts";
import ProtectedRoutes from "./services/route/protectedRoutes";
import { useDispatch, useSelector } from "react-redux";
import PublicMenu from "./components/Public/Menu/PublicMenu";
import { LoadScript } from "@react-google-maps/api";
import { setLoadingStateGoogleMapApi } from "./slice/configSlice";
import { fetchDataThunkV4 } from "./reducersToolkit/thunk/catalog/V4/fetchDataThunkV4";

const googleAPIKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const googleMapsLibraries = ["places"];

function App() {
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(
  //     fetchDataThunkV4({
  //       countryId: "7d20a951-15b4-443b-9ed6-94bf8e8e022b",
  //     })
  //   );
  // }, [dispatch]);

  const { countries, updateCountryLoading, createCountryLoading } = useSelector(
    (state) => state.catalogSliceV4
  );

  return (
    <LoadScript
      googleMapsApiKey={googleAPIKey}
      libraries={googleMapsLibraries}
      onLoad={() => dispatch(setLoadingStateGoogleMapApi(true))}
    >
      <Router>
        <AlertProvider>
          <PublicMenu />

          <Routes>
            <Route path="/*" element={<ProtectedRoutes />} />
          </Routes>
        </AlertProvider>
      </Router>
    </LoadScript>
  );
}

export default App;

// import React, { useEffect, useState } from "react";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import { AlertProvider } from "./components/UIElements/Alerts";
// import ProtectedRoutes from "./services/route/protectedRoutes";
// import { useDispatch, useSelector } from "react-redux";
// import PublicMenu from "./components/Public/Menu/PublicMenu";
// import { LoadScript } from "@react-google-maps/api";
// import { setLoadingStateGoogleMapApi } from "./slice/configSlice";
// import { fetchAllDataThunk } from "./slice/thunk/fetchAllDataThunk";
// import { auth } from "./services/firebase/authFirebase";
// import { getUserDataThunk } from "./slice/general/user/getUserDataThank";
// import { getAuth, onAuthStateChanged } from "firebase/auth";

// const googleAPIKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
// const googleMapsLibraries = ["places"];

// function App() {
//   const dispatch = useDispatch();
//   const currentUser = useSelector((state) => state.userSliceV2.currentUser);
//   console.log("App-currentUser", currentUser);

//   // Устанавливаем максимальное количество попыток для dispatch
//   const maxDispatchAttempts = 1;
//   useEffect(() => {
//     // Функция для выполнения dispatch с ограничением по попыткам
//     const dispatchWithRetry = async (action, maxAttempts) => {
//       let attempts = 0;

//       while (attempts < maxAttempts) {
//         try {
//           await dispatch(action);
//           break; // Выходим из цикла, если dispatch выполнен успешно
//         } catch (error) {
//           console.error("dispatch error", error);
//           // Обрабатываем ошибку dispatch
//           // Увеличиваем счетчик попыток
//           attempts++;

//           if (attempts < maxAttempts) {
//             // Если не достигнуто максимальное количество попыток,
//             // можно выполнить дополнительные действия или ждать перед повторной попыткой
//           } else {
//             // Если достигнуто максимальное количество попыток,
//             // выбрасываем ошибку или выполните другое действие по вашему усмотрению
//           }
//         }
//       }
//     };
//     dispatchWithRetry(fetchAllDataThunk(), maxDispatchAttempts);
//   }, [dispatch]);

//   const [user, setUser] = useState(null);

//   useEffect(() => {
//     if (user && user.uid) {
//       console.log("App isAuthenticated", user?.uid);
//       dispatch(getUserDataThunk(user.uid))
//         .unwrap()
//         .then(({ currentUser }) => {
//           console.log("getUserDataThunk userData", currentUser);
//         })
//         .catch((error) => {
//           console.log("getUserDataThunk userData error", error);
//         });
//     }
//   }, [user, dispatch]);

//   // useEffect(() => {
//   //   const auth = getAuth();
//   //   const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
//   //     console.log("App isAuthenticated", user?.uid);
//   //     setUser(currentUser);
//   //   });

//   //   return () => {
//   //     unsubscribe();
//   //   };
//   // }, []);

//   return (
//     <LoadScript
//       googleMapsApiKey={googleAPIKey}
//       libraries={googleMapsLibraries}
//       onLoad={() => dispatch(setLoadingStateGoogleMapApi(true))}
//     >
//       <Router>
//         <AlertProvider>
//           <PublicMenu />

//           {/* <WigetBikeTripsterAssistant /> */}

//           <Routes>
//             <Route path="/*" element={<ProtectedRoutes />} />
//           </Routes>
//         </AlertProvider>
//       </Router>
//     </LoadScript>
//   );
// }

// export default App;
