import { createAsyncThunk } from "@reduxjs/toolkit";
import { getFirestore, doc } from "firebase/firestore";
import interpretAuthErrorV2 from "../../../functions/interpretAuthErrorV2";
import { updateDocumentFields } from "../../../services/firebase/crudFirestore";

// Создаем асинхронный thunk для обновления данных бренда
export const updateDirectoryThunk = createAsyncThunk(
  "brands/updateBrand",
  async (
    { collectionName, documentId, updatedFields },
    { rejectWithValue }
  ) => {
    if (typeof collectionName !== "string" || collectionName.trim() === "") {
      return rejectWithValue("Invalid collection name");
    }

    if (typeof documentId !== "string" || documentId.trim() === "") {
      return rejectWithValue("Invalid document ID");
    }

    if (typeof updatedFields !== "object" || updatedFields === null) {
      return rejectWithValue("Invalid updated fields object");
    }

    try {
      await updateDocumentFields(collectionName, documentId, updatedFields);
      console.log("Document successfully updated!");
      return { documentId, updatedFields }; // Возвращаем обновленные данные
    } catch (error) {
      const errorMessage = interpretAuthErrorV2(error.code);
      return rejectWithValue({ errorMessage });
    }
  }
);
