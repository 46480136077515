import React, { useEffect } from "react";

const Popup = ({
  isOpen,
  onClose,
  onConfirm,
  children,
  title,
  description,
  leftMenu,
  rightMenu,
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <div
      className="popup-overlay"
      style={{
        paddingTop: "48px",
        backgroundColor: "var(--color-black)",
        gap: "0",
      }}
    >
      <div
        style={{
          height: "100%",
          width: "100%",
          backgroundColor: "var(--color-gray-100)",
          borderRadius: "8px",
          overflow: "hidden",
        }}
      >
        <div className="popup-header">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "16px",
              width: "100%",
              padding: "0 16px",
              boxSizing: "border-box",
            }}
          >
            <div
              style={{
                height: "48px",
                aspectRatio: "1/1",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "80px",
                backgroundColor: "var(--color-gray-200)",
              }}
              onClick={onClose}
            >
              <span className="material-symbols-outlined">chevron_left</span>
            </div>
            <h3>{title}</h3>
          </div>
        </div>

        {leftMenu && rightMenu && (
          <div className="popup-menu">
            <div className="popup-menu-box">
              {leftMenu && (
                <div className="popup-menu-box-left">{leftMenu}</div>
              )}
              {rightMenu && (
                <div className="popup-menu-box-right">{rightMenu}</div>
              )}
            </div>
          </div>
        )}

        <div
          className="popup-overlay-scroll"
          style={{
            overflow: "auto",
          }}
        >
          <div className="popup-content">{children}</div>

          {description && (
            <div
              style={{
                padding: "32px 0",
              }}
              className="popup-content popup-footter"
            >
              <p className="popup-header-description">{description}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Popup;

// import React from 'react';

// const Popup = ({ isOpen, onClose, children, title }) => {
//     if (!isOpen) {
//         return null; // Если popup закрыт, ничего не отображаем
//     }

//     return (
//         <div className="popup-overlay">
//             <div className="popup-content">
//                 <div className='popup-header'>

//                     <div>
//                         <button className="close-button" onClick={onClose}>
//                             Close
//                         </button>
//                     </div>
//                     <div>
//                         <h1>{title}</h1>
//                     </div>
//                 </div>
//                 {children} {/* Контент, переданный в компонент Popup */}
//             </div>
//         </div>
//     );
// };

// export default Popup;
