import React, { useEffect, useState } from "react";
import Button from "../WidgetsProfile/Buttons/Button";
import InputText from "../WidgetsProfile/Inputs/InputText";
import { useDispatch, useSelector } from "react-redux";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import { setRateSelectedBikeModeration } from "../../../../../reducersToolkit/moderationSliceV2";
import Textarea from "../WidgetsProfile/Textarea/Textarea";

const WindowBikePricePerMonthModeration = ({ mode, index, onClose }) => {
  const dispatch = useDispatch();

  const [inputPrice, setInputPrice] = useState("");
  const [inputDiscount, setInputDiscount] = useState("");
  const [description, setDescription] = useState("");
  const [applyButtonActive, setApplyButtonActive] = useState(false);

  const { selectedBike, newBike } = useSelector(
    (state) => state.moderationSliceV2
  );

  const rateItem = {
    month: index,
    price: inputPrice === "" ? 0 : Number(inputPrice),
    discount: inputDiscount === "" ? 0 : Number(inputDiscount),
    description: {
      descriptionOriginal: description,
    },
  };

  useEffect(() => {
    if (mode === "edit" || mode === "moderation") {
      const rate = selectedBike?.rate[index] || {};
      setInputPrice(rate.price || "");
      setInputDiscount(rate.discount || "");
      setDescription(rate.description?.descriptionOriginal || "");
    } else if (mode === "moderatorAdded") {
      const rate = newBike?.rate[index] || {};
      setInputPrice(rate.price || "");
      setInputDiscount(rate.discount || "");
      setDescription(rate.description?.descriptionOriginal || "");
    }
  }, [mode, selectedBike, newBike, index]);

  useEffect(() => {
    const hasChanges = () => {
      if (mode === "edit" || mode === "moderation") {
        const currentRate = selectedBike?.rate[index] || {};
        return (
          currentRate.price !== Number(inputPrice) ||
          currentRate.discount !== Number(inputDiscount) ||
          currentRate.description?.descriptionOriginal !== description
        );
      } else if (mode === "moderatorAdded") {
        const currentRate = newBike?.rate[index] || {};
        return (
          currentRate.price !== Number(inputPrice) ||
          currentRate.discount !== Number(inputDiscount) ||
          currentRate.description?.descriptionOriginal !== description
        );
      }
      return false;
    };

    setApplyButtonActive(hasChanges());
  }, [
    index,
    inputDiscount,
    inputPrice,
    description,
    selectedBike,
    newBike,
    mode,
  ]);

  const handleUpdateRate = () => {
    dispatch(setRateSelectedBikeModeration({ mode, index, newRate: rateItem }));
    onClose();
  };

  const handleClearRate = () => {
    dispatch(
      setRateSelectedBikeModeration({
        index,
        newRate: {
          month: index,
          price: 0,
          discount: 0,
          description: {
            descriptionOriginal: "",
          },
        },
      })
    );
    onClose();
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "8px",
      }}
    >
      <ContentBox position="start">
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            gap: "16px",
          }}
        >
          <InputText
            label="Price"
            type="number"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            value={inputPrice}
            onChange={(event) => setInputPrice(event.target.value)}
          />

          <InputText
            label="Sale"
            type="number"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            value={inputDiscount}
            onChange={(event) => setInputDiscount(event.target.value)}
          />
        </div>
        <Textarea
          label="Rate description"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          maxLength={1000}
          value={description}
          onChange={(event) => setDescription(event.target.value)}
        />
      </ContentBox>
      <ContentBox position="end">
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <Button
            label="Clear"
            active={inputPrice !== ""}
            onClick={handleClearRate}
            color="--color-black"
            allert={
              <div
                style={{
                  padding: "48px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "16px",
                  textAlign: "center",
                }}
              >
                <h1>Are you sure you want to delete the current rate?</h1>
                <p className="p-light">
                  This action will reset all entered data.
                </p>
              </div>
            }
          />
          <Button
            label="Save"
            active={applyButtonActive}
            onClick={handleUpdateRate}
          />
        </div>
      </ContentBox>
    </div>
  );
};

export default WindowBikePricePerMonthModeration;
