import { createAsyncThunk } from "@reduxjs/toolkit";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import {
  removeFiles,
  deleteData,
} from "../../../services/firebase/crudFirestore";

export const deleteCityThunkV2 = createAsyncThunk(
  "directories/deleteCityThunkV2",
  async ({ cityId }, { rejectWithValue }) => {
    try {
      // Получаем документ города по его ID
      const db = getFirestore();
      const cityRef = doc(db, "city", cityId);
      const citySnap = await getDoc(cityRef);

      if (!citySnap.exists()) {
        throw new Error("City document does not exist");
      }

      const cityData = citySnap.data();

      // Удаляем файлы, связанные с городом
      const removeNestedFiles = async (basePath, data) => {
        for (const key in data) {
          const value = data[key];
          const fullPath = basePath ? `${basePath}.${key}` : key;

          if (Array.isArray(value) && value.length > 0) {
            // Удаляем файлы, используя функцию removeFiles
            await removeFiles(
              "city",
              cityId,
              fullPath,
              value.map((file) => file.id)
            );
          } else if (typeof value === "object" && value !== null) {
            await removeNestedFiles(fullPath, value);
          }
        }
      };

      if (cityData.files) {
        await removeNestedFiles("files", cityData.files);
      }

      // После удаления файлов проверяем, что все массивы пусты
      const citySnapAfterRemoval = await getDoc(cityRef);
      const cityDataAfterRemoval = citySnapAfterRemoval.data();

      let allFilesCleared = true;

      if (cityDataAfterRemoval.files) {
        const checkFilesCleared = (data) => {
          for (const key in data) {
            const value = data[key];
            if (Array.isArray(value) && value.length > 0) {
              return false;
            } else if (typeof value === "object" && value !== null) {
              if (!checkFilesCleared(value)) {
                return false;
              }
            }
          }
          return true;
        };
        allFilesCleared = checkFilesCleared(cityDataAfterRemoval.files);
      }

      // Удаляем документ города, только если все файлы действительно были удалены
      if (allFilesCleared) {
        await deleteData("city", cityId);
        console.log("City and its files successfully deleted!");
      } else {
        throw new Error("Not all files were deleted successfully.");
      }

      return { cityId };
    } catch (error) {
      console.error("Error deleting city:", error);
      return rejectWithValue({ errorMessage: error.message });
    }
  }
);
