// Импорт необходимых зависимостей
import React, { useState, useEffect } from "react";
import "firebase/compat/firestore";
import WigetButtonAdd from "../../../../../../UniversalWidgets/WigetButtonAdd";
import WigetButtonDelete from "../../../../../../UniversalWidgets/WigetButtonDelete";
import { useSubscribeData } from "../../../../../../../services/firebase/crudFirebaseStorage";
import Popup from "../../../../../../UIElements/Popup";
import Radio from "../../../../../../UIElements/Radio";

import Loading from "../../../../../../UIElements/Loading";
import PreviewGallery from "../../../../../../UIComponents/bars/gallery/PreviewGallery";
import { WigetInputV2 } from "../../../../../../UniversalWidgets/WigetInputV2";
import { WigetTextareaV2 } from "../../../../../../UniversalWidgets/WigetTextareaV2";
import "firebase/functions";
import { uuidv4 } from "@firebase/util";

export const WigetСategoriesBikeDetail = ({
  title,
  collectionName,
  structureCollection,
}) => {
  const [result, setResult] = useState(null);

  // Используем наш кастомный хук для подписки на данные Firestore и отслеживания состояния загрузки
  const { data: dataList, isLoading: loadingState } =
    useSubscribeData(collectionName);

  return (
    <div className="private_profile-mainBox">
      {loadingState ? (
        <Loading />
      ) : (
        dataList.map((directoryItem, index) => (
          <div className="directory" key={directoryItem.id}>
            <div className="rentalPoints_content-box">
              <div
                className="rentalPoints_content-box-left"
                style={{ width: "50%" }}
              >
                <PreviewGallery
                  aspectRatio="16/9"
                  title="Category name"
                  collectionName={collectionName}
                  idDocument={directoryItem.id}
                  fieldName="file_icon"
                  gallery={true}
                  description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eleifend ipsum vitae nunc gravida facilisis."
                />
              </div>

              <div
                className="rentalPoints_content-box-right"
                style={{ width: "50%" }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <WigetInputV2
                    title="Category name"
                    collectionName={collectionName}
                    idDocument={directoryItem.id}
                    fieldName="title"
                    quantity={2}
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eleifend ipsum vitae nunc gravida facilisis."
                  />

                  <WigetTextareaV2
                    title="description"
                    maxLength={1000}
                    collectionName={collectionName}
                    idDocument={directoryItem.id}
                    fieldName="description"
                    quantity={1}
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eleifend ipsum vitae nunc gravida facilisis."
                  />
                  <div
                    style={{
                      padding: "0 16px",
                    }}
                  >
                    <div className="wigetButtonDelete-imform">
                      <div className="wigetButtonDelete-imform-left">
                        <p className="p-light">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Fusce eleifend ipsum vitae nunc gravida
                          facilisis.
                        </p>
                      </div>

                      <div className="wigetButtonDelete-imform-right">
                        <WigetButtonDelete
                          collectionName={collectionName}
                          documentId={directoryItem.id}
                          title="Удалить"
                          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eleifend ipsum vitae nunc gravida facilisis."
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

/**
 * Компонент WigetBrandCheck.
 * Отображает список брендов с радиокнопками и кнопкой выбора.
 * @param {Object} props - Свойства компонента.
 * @param {string} props.title - Заголовок компонента.
 * @param {string} props.collectionName - Название коллекции для получения данных о брендах.
 * @param {string} props.parentCollectionName - Название родительской коллекции для получения данных родительского документа.
 * @param {string} props.documentId - Идентификатор документа родительской коллекции.
 *  @param {string} props.fieldName - Поле в которое выполняется запись выбранных значений, также это значение используется для фильтрации.
 * @returns {JSX.Element} Элемент компонента WigetBrandCheck.
 */
export const WigetСategoriesBikeCheck = ({
  title,
  collectionName,
  fieldName,
  parentCollectionName,
  documentId,
  description,
}) => {
  const [result, setResult] = useState(null);

  // Состояние активного попапа
  const [activePopup, setActivePopup] = useState(null);

  // Состояние выбранного бренда
  const [selectedBrand, setSelectedBrand] = useState(null);

  // Получение данных о брендах из коллекции
  const { data } = useSubscribeData(collectionName);

  // Получение данных родительского документа
  const { data: documentParentCollectionName, isLoading } = useSubscribeData(
    parentCollectionName,
    documentId
  );

  useEffect(() => {
    if (documentParentCollectionName?.[fieldName]) {
      setSelectedBrand(documentParentCollectionName[fieldName]);
    }
  }, [documentParentCollectionName, fieldName]);

  /**
   * Обработчик открытия попапа.
   * @param {string} popupName - Имя попапа.
   */
  const handleOpenPopup = (popupName) => {
    setActivePopup(popupName);
  };

  /**
   * Обработчик закрытия попапа.
   */
  const handleClosePopup = () => {
    setActivePopup(null);
  };

  /**
   * Обработчик изменения состояния радиокнопки.
   * @param {string} dataId - Идентификатор бренда.
   */
  const handleRadioButtonChange = (dataId) => {
    setSelectedBrand(dataId);
  };

  // Фильтруем список брендов на основе выбранного бренда и наличия данных родительского документа
  const filteredData =
    data && documentParentCollectionName?.[fieldName]
      ? data.filter((dataItem) =>
          documentParentCollectionName[fieldName].includes(dataItem.id)
        )
      : [];

  // Возвращаем JSX-элемент компонента
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "0 16px",
          boxSizing: "border-box",
        }}
      >
        <div
          style={{
            backgroundColor: "var(--color-white)",
            boxSizing: "border-box",
            borderRadius: "8px",
            overflow: "hidden",
          }}
          onClick={() => handleOpenPopup(`popu_${title}`)}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              height: "48px",
              padding: "0 16px",
              ...(filteredData.length > 0 && {
                borderBottom: "1px var(--color-gray-200) solid",
              }),
            }}
          >
            <p
              style={{
                textTransform: "uppercase",
              }}
              className="p-light"
            >
              {title}
            </p>

            <button onClick={() => handleOpenPopup(`popu`)}>
              {filteredData.length > 0 ? "Edit" : "Add"}
            </button>
          </div>

          {(filteredData.length > 0 ? filteredData : []).map(
            (directoryItem, index) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginLeft: "16px",
                  boxSizing: "border-box",
                  height: "48px",
                  ...(index === filteredData.length - 1
                    ? {
                        borderBottom: "none",
                      }
                    : {
                        borderBottom: "1px var(--color-gray-200) solid",
                      }),
                }}
                key={uuidv4()}
                onClick={() => handleOpenPopup(`popu_${fieldName}`)}
              >
                <div className="inform-box-content-left">
                  {directoryItem.title && directoryItem.title}
                </div>
                <div className="inform-box-content-right">
                  <span
                    style={{
                      color: "var(--color-gray-400)",
                    }}
                    className="material-symbols-outlined"
                  >
                    chevron_right
                  </span>
                </div>
              </div>
            )
          )}
        </div>

        {description && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "8px 16px",
            }}
          >
            <p className="p-light">{description}</p>
          </div>
        )}
      </div>

      {activePopup === `popu_${title}` && (
        <Popup isOpen={true} onClose={handleClosePopup} title={title}>
          <div className="inform-box-inputItem">
            <div className="inform-box">
              <div className="inform-box-header">{title}</div>
              <div className="inform-box-item">
                {isLoading ? (
                  <div>Loading...</div>
                ) : (
                  data.map((dataItem, index) => (
                    <>
                      <div key={uuidv4()} className="inform-box-content">
                        <div className="inform-box-content-left">
                          <Radio
                            value={dataItem.title}
                            id={dataItem.id}
                            checked={selectedBrand === dataItem.id}
                            onChange={handleRadioButtonChange}
                          />
                          {/* <input
                                                    type="radio"
                                                    name="brand"
                                                    value={dataItem.id}
                                                    checked={selectedBrand === dataItem.id}
                                                    onChange={() => handleRadioButtonChange(dataItem.id)}
                                                /> */}
                        </div>

                        <div className="inform-box-content-right">
                          {dataItem.file_icon &&
                            dataItem.file_icon
                              .filter((icon) => icon.priority)
                              .map((icon) => (
                                <div
                                  key={uuidv4()}
                                  className="inform-box-icon-right"
                                  style={{
                                    backgroundImage: `url(${icon.url})`,
                                  }}
                                ></div>
                              ))}
                        </div>
                      </div>
                    </>
                  ))
                )}
              </div>
            </div>
          </div>

          <div className="send-view">
            <div className="send-view-left"></div>
            <div className="send-view-right">
              <WigetButtonAdd
                collectionName={parentCollectionName}
                documentId={documentId}
                nameButton="Выбрать"
                dataObject={{
                  [fieldName]: selectedBrand,
                }}
              />
            </div>
          </div>
        </Popup>
      )}
    </>
  );
};

// /**
//  * Компонент WigetBrandCheck.
//  * Отображает список брендов с чекбоксами и кнопкой выбора.
//  * @param {Object} props - Свойства компонента.
//  * @param {string} props.title - Заголовок компонента.
//  * @param {string} props.collectionName - Название коллекции для получения данных о брендах.
//  * @param {string} props.parentCollectionName - Название родительской коллекции для получения данных родительского документа.
//  * @param {string} props.documentId - Идентификатор документа родительской коллекции.
//  * @returns {JSX.Element} Элемент компонента WigetBrandCheck.
//  */
// export const WigetBrandCheck = ({ title, collectionName, parentCollectionName, documentId }) => {
//     // Состояние активного попапа
//     const [activePopup, setActivePopup] = useState(null);

//     // Состояние выбранных брендов
//     const [selectedBrands, setSelectedBrands] = useState([]);

//     // Получение данных о брендах из коллекции
//     const { data } = useSubscribeData(collectionName);

//     // Получение данных родительского документа
//     const { data: documentParentCollectionName, isLoading } =
//         useSubscribeData(parentCollectionName, documentId);

//     useEffect(() => {
//         if (documentParentCollectionName?.brand_id) {
//             setSelectedBrands(documentParentCollectionName.brand_id);
//         }
//     }, [documentParentCollectionName]);

//     /**
//      * Обработчик открытия попапа.
//      * @param {string} popupName - Имя попапа.
//      */
//     const handleOpenPopup = (popupName) => {
//         setActivePopup(popupName);
//     };

//     /**
//      * Обработчик закрытия попапа.
//      */
//     const handleClosePopup = () => {
//         setActivePopup(null);
//     };

//     /**
//      * Обработчик изменения состояния чекбокса.
//      * @param {string} brandId - Идентификатор бренда.
//      */
//     const handleCheckboxChange = (brandId) => {
//         const updatedSelectedBrands = selectedBrands.includes(brandId)
//             ? selectedBrands.filter((id) => id !== brandId)
//             : [...selectedBrands, brandId];
//         setSelectedBrands(updatedSelectedBrands);
//     };

//     // Фильтруем список брендов на основе выбранных брендов и наличия данных родительского документа
//     const filteredData = data && documentParentCollectionName?.brand_id
//         ? data.filter((directoryItem) => documentParentCollectionName.brand_id.includes(directoryItem.id))
//         : [];

//     // Возвращаем JSX-элемент компонента
//     return (
//         <>
//             <h3 onClick={() => handleOpenPopup(`popu_${title}`)}>{title}</h3>

//             {filteredData.map((directoryItem) => (
//                 <div key={directoryItem.id}>
//                     {directoryItem.title}
//                     {directoryItem.file_icon &&
//                         directoryItem.file_icon
//                             .filter((icon) => icon.priority)
//                             .map((icon) => (
//                                 <img key={icon.url} src={icon.url} alt={directoryItem.title} />
//                             ))}
//                 </div>
//             ))}

//             {activePopup === `popu_${title}` && (
//                 <Popup isOpen={true} onClose={handleClosePopup} title={title}>
//                     {isLoading ? (
//                         <div>Loading...</div>
//                     ) : (
//                         data.map((directoryItem) => (
//                             <div key={directoryItem.id}>
//                                 <form>

//                                     <input
//                                         type="checkbox"
//                                         name="brand"
//                                         value={directoryItem.id}
//                                         checked={selectedBrands.includes(directoryItem.id)}
//                                         onChange={() => handleCheckboxChange(directoryItem.id)}
//                                     />
//                                     <label>{directoryItem.title}</label>

//                                 </form>
//                             </div>
//                         ))
//                     )}
//                     <WigetButtonAdd
//                         collectionName={parentCollectionName}
//                         documentId={documentId}
//                         nameButton="Выбрать"
//                         dataObject={{
//                             brand_id: selectedBrands,
//                         }}
//                     />
//                 </Popup>
//             )}
//         </>
//     );
// };
