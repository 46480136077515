import { createSlice } from "@reduxjs/toolkit";
import { fetchAllDataThunkV2 } from "./thunk/catalog/fetchAllDataThunkV2";
import { filterBikesThunkV2 } from "./thunk/catalog/filterBikesThunkV2";
import { fetchCurrentBikeDataThunkV2 } from "./thunk/catalog/fetchCurrentBikeDataThunkV2";

const filtersTeplate = {
  qualityBike: 1,
  country: {},
  city: {},
  brand: {},
  category: {},
  bookingDates: {
    startDay: Date.now(),
    endDay: Date.now() + 7 * 24 * 60 * 60 * 1000,
  },
};

const initialState = {
  isLoading: false,
  error: null,

  bikes: [],
  currentBike: {},
  rentalPoints: [],
  filteredBikes: [],

  filters: filtersTeplate,

  cities: [],
  countries: [],
  brands: [],
  categories: [],
};

const catalogSliceV2 = createSlice({
  name: "reducersToolkit/catalogSliceV2",
  initialState,
  reducers: {
    setFilters(state, action) {
      state.filters = action.payload;
    },
    clearFilters(state) {
      state.filters = filtersTeplate;
    },
    setCatalogBookingDates(state, action) {
      const { startDay, endDay } = action.payload;
      state.filters.bookingDates.startDay = startDay;
      state.filters.bookingDates.endDay = endDay;
    },
    setCatalogQualityBike(state, action) {
      const { qualityBike } = action.payload;
      state.filters.qualityBike = qualityBike;
    },
    setCatalogCounty(state, action) {
      const { country } = action.payload;
      state.filters.country = country;
    },
    setCatalogCity(state, action) {
      const { city } = action.payload;

      state.filters.city = city;
    },
    setCatalogBrand(state, action) {
      const { brand } = action.payload;
      state.filters.brand = brand;
    },

    setCatalogCategory(state, action) {
      const { category } = action.payload;
      state.filters.category = category;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllDataThunkV2.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchAllDataThunkV2.fulfilled, (state, action) => {
        const { bikes, rentalPoints, cities, countries, brands, categories } =
          action.payload || {};

        state.bikes = bikes || [];
        state.rentalPoints = rentalPoints || [];
        state.cities = cities || [];
        state.countries = countries || [];
        state.brands = brands || [];
        state.categories = categories || [];
        state.isLoading = false;
      })
      .addCase(fetchAllDataThunkV2.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload?.errorMessage || "Failed to fetch data";
      })
      .addCase(filterBikesThunkV2.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(filterBikesThunkV2.fulfilled, (state, action) => {
        state.isLoading = false;
        state.filteredBikes = action.payload || [];
      })
      .addCase(filterBikesThunkV2.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error?.message || "Failed to filter bikes";
      })
      .addCase(fetchCurrentBikeDataThunkV2.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchCurrentBikeDataThunkV2.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentBike = action.payload;
      })
      .addCase(fetchCurrentBikeDataThunkV2.rejected, (state, action) => {
        state.isLoading = false;
        state.error =
          action.payload?.errorMessage || "Failed to fetch bike data";
      });
  },
});

export const {
  setFilters,
  clearFilters,
  setCatalogBookingDates,
  setCatalogQualityBike,
  setCatalogCounty,
  setCatalogCity,
  setCatalogBrand,
  setCatalogCategory,
} = catalogSliceV2.actions;

export default catalogSliceV2.reducer;
