import {
  doc,
  setDoc,
  collection,
  addDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  getDoc,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import { db, storage } from "./authFirebase";
import { useState, useEffect } from "react";

export const getDocumentById = async (collectionName, fieldPath, opStr, id) => {
  try {
    // Создаем запрос для коллекции с условием, что поле 'bikes_id' содержит указанный 'id'
    const q = query(
      collection(db, collectionName),
      where(fieldPath, opStr, id)
    );

    // Получаем результаты запроса
    const querySnapshot = await getDocs(q);

    // Если найден хотя бы один документ с указанным 'id', возвращаем его данные
    if (!querySnapshot.empty) {
      const doc = querySnapshot.docs[0];
      return doc.data();
    } else {
      // Если ничего не найдено, возвращаем null или пустой объект в зависимости от ваших требований
      return null;
    }
  } catch (error) {
    console.error("Ошибка при получении документа из Firestore:", error);
    return null;
  }
};

// Функция для получения данных из указанной коллекции
// export const fetchData = async (collectionName) => {
//     try {
//         const dataCollection = collection(db, collectionName);
//         const snapshot = await getDocs(dataCollection);
//         const data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
//         return data;
//     } catch (error) {
//         throw new Error(error.message);
//     }
// };
export const fetchData = async (collectionName) => {
  try {
    const dataCollection = collection(db, collectionName);
    const snapshot = await getDocs(dataCollection);
    const data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    return data;
  } catch (error) {
    throw new Error(
      `Ошибка при получении данных из коллекции ${collectionName}: ${error.message}`
    );
  }
};

// Функция для получения документа по его ID из указанной коллекции
export const fetchDocumentById = async (collectionName, documentId) => {
  try {
    const documentIdAsString = documentId ? documentId.toString() : "";
    const docRef = doc(db, collectionName, documentIdAsString);
    // console.log(docRef)
    const docSnap = await getDoc(docRef);
    // console.log(docSnap)
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      return null;
    }
  } catch (error) {
    console.error(
      "crudFirebaseStorage / fetchDocumentById Ошибка при получении данных:",
      "\ncollectionName:",
      collectionName,
      "\ndocumentId:",
      documentId,
      "\nerror:",
      error
    );
    return null;
  }
};

// Функция для добавления документа с указанным ID в указанную коллекцию
export const addDocumentById = async (data, collectionName, documentId) => {
  const documentIdAsString = documentId ? documentId.toString() : "";
  try {
    const docRef = doc(db, collectionName, documentIdAsString);
    await setDoc(docRef, data);
    console.log("Данные успешно добавлены.", data);
    return documentIdAsString;
  } catch (error) {
    console.error("Ошибка при добавлении данных:", error);
    throw error;
  }
};

// Функция для добавления данных в указанную коллекцию
export const addData = async (collectionName, newData) => {
  try {
    const dataCollection = collection(db, collectionName);
    const docRef = await addDoc(dataCollection, newData);
    const addedData = { id: docRef.id, ...newData };
    return addedData;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Функция для обновления данных в указанной коллекции по указанному ID
export const updateData = async (collectionName, id, newData) => {
  try {
    const dataDocRef = doc(db, collectionName, id);
    await updateDoc(dataDocRef, newData);
    const updatedData = await getDoc(dataDocRef);
    return updatedData.data();
  } catch (error) {
    throw new Error(error.message);
  }
};

// Функция для обновления документа в указанной коллекции по его ID
export const updateDocumentById = async (data, collectionName, documentId) => {
  const documentIdAsString = documentId ? documentId.toString() : "";

  try {
    const docRef = doc(db, collectionName, documentIdAsString);
    await updateDoc(docRef, data);
    console.log("Данные успешно обновлены.");
    return true; // Возвращает true после успешного обновления
  } catch (error) {
    console.error("Ошибка при обновлении данных:", error);
    throw error; // Вызывает ошибку, если обновление не удалось
  }
};
// Функция для обновления документа в указанной коллекции по его ID
export const updateDocumentByIdV2 = async (
  collectionName,
  documentId,
  data
) => {
  const documentIdAsString = documentId ? documentId.toString() : "";

  try {
    const docRef = doc(db, collectionName, documentIdAsString);
    await updateDoc(docRef, data);
    console.log("Данные успешно обновлены.");
    return true; // Возвращает true после успешного обновления
  } catch (error) {
    console.error("Ошибка при обновлении данных:", error);
    throw error; // Вызывает ошибку, если обновление не удалось
  }
};

// Функция для удаления данных из указанной коллекции по указанному ID
export const deleteData = async (collectionName, itemId) => {
  try {
    const dataDocRef = doc(db, collectionName, itemId);
    await deleteDoc(dataDocRef);
    return itemId;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Функция для загрузки файла в указанную коллекцию и документ с указанным ID
export const uploadFile = async (
  collectionName,
  documentId,
  fieldName,
  file
) => {
  const documentIdAsString = documentId ? documentId.toString() : "";

  try {
    const extension = file.name.split(".").pop();
    const fileId = uuidv4();
    const newFileName = `${fileId}.${extension}`;
    const storageRef = ref(storage, `files/${newFileName}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    await new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        () => {},
        (error) => {
          reject(new Error(`Error uploading file: ${error.message}`));
        },
        async () => {
          const downloadUrl = await getDownloadURL(storageRef);
          const docRef = doc(db, collectionName, documentIdAsString);
          const docSnap = await getDoc(docRef);
          const existingData = docSnap.data();
          let updatedData = [];

          if (
            existingData &&
            existingData[fieldName] &&
            Array.isArray(existingData[fieldName])
          ) {
            const previousItems = existingData[fieldName];
            updatedData = [
              ...previousItems,
              {
                name: newFileName,
                url: downloadUrl,
                id: fileId,
                priority: false,
              },
            ];
          } else {
            updatedData = [
              {
                name: newFileName,
                url: downloadUrl,
                id: fileId,
                priority: false,
              },
            ];
          }

          await updateDoc(docRef, { [fieldName]: updatedData });
          resolve();
        }
      );
    });
  } catch (error) {
    throw new Error(error.message);
  }
};

// Функция для получения файлов из указанной коллекции
export const fetchFiles = async (collectionName) => {
  try {
    const querySnapshot = await getDocs(collection(db, collectionName));
    const files = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    return files;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Функция для полного удаления документа из указанной коллекции по его ID и удаления связанных файлов
export const totalDeleteDocument = async (
  collectionName,
  documentId,
  fileFields
) => {
  const documentIdAsString = documentId ? documentId.toString() : "";

  try {
    await Promise.all(
      fileFields.map(async (field) => {
        const docRef = doc(db, collectionName, documentIdAsString);
        const docSnapshot = await getDoc(docRef);
        const documentData = docSnapshot.data();
        const fileData = documentData[field] || [];

        if (fileData.length > 0) {
          await Promise.all(
            fileData.map(async (file) => {
              const storageRef = ref(storage, `files/${file.name}`);
              await deleteObject(storageRef);
            })
          );
        }
      })
    );

    const dataDocRef = doc(db, collectionName, documentIdAsString);
    await deleteDoc(dataDocRef);
    return documentIdAsString;
  } catch (error) {
    throw new Error(error.message);
  }
};

// deprecated Функция для подписки на изменения в указанной коллекции и вызова обратного вызова при получении данных
export const subscribeData = (collectionName, callback) => {
  const unsubscribe = onSnapshot(collection(db, collectionName), (snapshot) => {
    const data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    callback(data);
  });

  return unsubscribe;
};
// Вместо subscribeData используем useFirestoreSubscription
/**
 * Хук useSubscribeData предоставляет подписку на данные коллекции или документа Firestore.
 *
 * @param {string} collectionName - Название коллекции Firestore.
 * @param {string} documentId - Идентификатор документа Firestore (необязательный).
 * @returns {Object} Объект с данными и состоянием загрузки.
 */
export const useSubscribeData = (collectionName, documentId) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let unsubscribe;

    if (documentId) {
      const documentIdAsString = documentId ? documentId.toString() : "";

      // Подписываемся на изменения указанного документа
      unsubscribe = onSnapshot(
        doc(db, collectionName, documentIdAsString),
        (snapshot) => {
          setData({ id: snapshot.id, ...snapshot.data() });
          setIsLoading(false);
        },
        (error) => {
          // Обработка ошибок
          console.error("Error getting document:", error);
          setIsLoading(false); // Установка isLoading в false в случае ошибки
        }
      );
    } else {
      // Подписываемся на изменения коллекции
      unsubscribe = onSnapshot(
        collection(db, collectionName),
        (snapshot) => {
          const fetchedData = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setData(fetchedData);
          setIsLoading(false);
        },
        (error) => {
          // Обработка ошибок
          console.error("Error getting collection:", error);
          setIsLoading(false); // Установка isLoading в false в случае ошибки
        }
      );
    }

    // Возвращаем функцию отписки, которая будет вызвана при размонтировании компонента
    return () => {
      unsubscribe();
    };
  }, [collectionName, documentId]);

  return { data, isLoading };
};

// Функция для подписки на изменения в указанной коллекции и вызова обратного вызова при получении данных
export const subscribeDocumentId = (collectionName, documentId, callback) => {
  const docRef = doc(db, collectionName, documentId);
  const unsubscribe = onSnapshot(docRef, (doc) => {
    if (doc.exists()) {
      const data = { id: doc.id, ...doc.data() };
      callback(data);
    } else {
      callback(null); // Документ не существует
    }
  });

  return unsubscribe;
};

// Асинхронное удаление файла
// collectionName: Название коллекции, из которой происходит удаление файлов.
// documentIds: Массив идентификаторов документов, для которых нужно удалить файлы.
// fieldName: Название поля, содержащего файлы, которые нужно удалить.
// fileIds: Массив идентификаторов файлов, которые нужно удалить.
export const deleteFiles = async (
  collectionName,
  documentIds,
  fieldName,
  fileIds
) => {
  try {
    // Удаляем файлы из хранилища (storage) для каждого документа
    await Promise.all(
      documentIds.map(async (documentId) => {
        await deleteFileFirebase(
          collectionName,
          documentId,
          fieldName,
          fileIds
        );
      })
    );

    // Обходим каждый документ
    for (const documentId of documentIds) {
      const docRef = doc(db, collectionName, documentId);
      const docSnap = await getDoc(docRef);
      const fileData = docSnap.data()?.[fieldName];

      // Проверяем наличие данных файла
      if (!fileData) {
        throw new Error(
          `Данные файла не найдены для документа с ID ${documentId}.`
        );
      }

      let updatedFileData = null;

      // Обновляем данные файла
      if (Array.isArray(fileData)) {
        // Если данные файла представлены в виде массива
        updatedFileData = fileData.filter((file) => !fileIds.includes(file.id));
      } else if (typeof fileData === "object") {
        // Если данные файла представлены в виде объекта
        const updatedFiles = { ...fileData };
        for (const fileId of fileIds) {
          delete updatedFiles[fileId];
        }
        updatedFileData = updatedFiles;
      } else {
        throw new Error("Недопустимый тип данных файла.");
      }

      // Обновляем документ, удаляя ссылки на удаленные файлы
      await updateDoc(docRef, { [fieldName]: updatedFileData });

      console.log(
        `deleteFiles Файлы \n${fileIds.join(
          "\n "
        )} \nуспешно удалены из Firestore и Storage для документа с ID ${documentId}.`
      );
    }
  } catch (error) {
    console.error("Ошибка удаления файлов:", error);
  }
};
// Функция выполняет следующие шаги:

// Для каждого документа в documentIds вызывается функция deleteFileFirebase, которая удаляет файлы из хранилища (storage).
// Затем для каждого документа из documentIds получается ссылка на документ с использованием docRef.
// С помощью getDoc получаем снимок документа и извлекаем данные файла из поля fieldName.
// Проверяем наличие данных файла. Если данных нет, выбрасывается исключение.
// Создаем переменную updatedFileData для хранения обновленных данных файла.
// Если данные файла представлены в виде массива, удаляем из массива файлы с идентификаторами, указанными в fileIds.
// Если данные файла представлены в виде объекта, создаем копию объекта и удаляем из нее поля с идентификаторами, указанными в fileIds.
// Если тип данных файла не является массивом или объектом, выбрасывается исключение.
// Обновляем документ, устанавливая новые данные файла с помощью updateDoc.
// Выводим в консоль информацию о успешном удалении файлов для каждого документа из documentIds.

// Вспомогательная функция для удаления файлов из Firestore
const deleteFileFirebase = async (
  collectionName,
  documentId,
  fieldName,
  fileIds
) => {
  try {
    const docRef = doc(db, collectionName, documentId);
    const docSnap = await getDoc(docRef);
    const documentData = docSnap.data();
    const fileData = documentData[fieldName] || [];

    for (const fileId of fileIds) {
      const file = fileData.find((file) => file.id === fileId);

      if (file) {
        const storageRef = ref(storage, `files/${file.name}`);
        await deleteObject(storageRef);
      }
    }

    const updatedFileData = fileData.filter(
      (file) => !fileIds.includes(file.id)
    );

    await updateDoc(docRef, { [fieldName]: updatedFileData });

    console.log(
      `deleteFileFirebase Файлы \n${fileIds.join(
        "\n "
      )} \nуспешно удалены из Firestore и Storage для документа с ID ${documentId}.`
    );
  } catch (error) {
    console.error(
      `Ошибка при удалении файлов для документа с ID ${documentId}:`,
      error
    );
  }
};

// Функция для получения документов по массиву ID с возможностью дополнительной фильтрации по полям.

export const fetchDocumentsByIds = async (
  collectionName,
  documentIds,
  additionalFilters = []
) => {
  try {
    const chunkSize = 10;
    const documents = [];

    // Разбиваем массив ID на подмассивы по 10 элементов
    for (let i = 0; i < documentIds.length; i += chunkSize) {
      const idChunk = documentIds.slice(i, i + chunkSize);
      const q = query(
        collection(db, collectionName),
        where("__name__", "in", idChunk),
        ...additionalFilters.map((filter) => where(...filter))
      );

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        documents.push({ id: doc.id, ...doc.data() });
      });
    }

    return documents;
  } catch (error) {
    console.error("Ошибка при получении документов:", error);
    return [];
  }
};
