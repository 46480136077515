import React, { useState, useRef, useEffect } from "react";

export const TextareaWrite = ({
  label,
  name,
  value,
  onChange,
  index,
  maxLength,
  description,
  errDescription,
}) => {
  const [inputValue, setInputValue] = useState(value || "");
  const [isFocused, setIsFocused] = useState(false); // новое состояние
  const textareaRef = useRef(null);

  const handleInputChange = (e) => {
    let newValue = e.target.value;

    // Проверка maxLength
    if (maxLength && newValue.length > maxLength) {
      newValue = newValue.slice(0, maxLength);
    }

    setInputValue(newValue);
    adjustTextareaHeight();
    if (onChange && typeof onChange === "function") {
      onChange(e, index, newValue);
    }
  };

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    textarea.style.height = "auto";
    textarea.style.height = textarea.scrollHeight + "px";
  };

  useEffect(() => {
    setInputValue(value || "");
    adjustTextareaHeight();
  }, [value]);

  const handleContainerClick = () => {
    textareaRef.current.focus();
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const remainingChars = maxLength ? maxLength - inputValue.length : null;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        width: "100%",
        height: "100%",
      }}
    >
      <div
        className={`textarea-write-container ${isFocused ? "focused" : ""}`}
        onClick={handleContainerClick}
        style={{ height: "100%" }}
      >
        <label
          htmlFor={name}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "32px",
            ...(errDescription
              ? {
                  color: "var(--color-alarm)",
                }
              : {
                  color: "var(--color-gray-700)",
                }),
          }}
        >
          {maxLength && remainingChars !== null && (
            <>
              {label}
              <p
                style={{
                  padding: "4px 16px",
                  backgroundColor: "var(--color-gray-100)",
                  color: "var(--color-gray-700)",
                  borderRadius: "80px",
                }}
                className="p-light"
              >
                Characters left:&nbsp;{remainingChars}
              </p>
            </>
          )}
        </label>

        <textarea
          className="textarea-write-input"
          id={name}
          name={name}
          value={inputValue}
          onChange={handleInputChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          ref={textareaRef}
          maxLength={maxLength}
        />
      </div>

      {description && (
        <div
          style={{
            padding: "0 16px ",
          }}
        >
          <p
            className="input-clue"
            style={{
              ...(errDescription && { color: "var(--color-alarm)" }),
            }}
          >
            {errDescription ? errDescription : description}
          </p>
        </div>
      )}
    </div>
  );
};

export const TextareaRead = ({ label, name, value, onClick }) => {
  const sanitizedValue = value || "";

  return (
    <div className="InputRead-main-box" onClick={onClick}>
      {label}
      {sanitizedValue}
    </div>
  );
};
