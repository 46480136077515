import Preloader from "../../UIElements/Preloader";

const BikesInform = ({ rentalPointData, isLoading }) => {
  // Используем Map для отслеживания уникальных категорий по ID
  const uniqueCategoriesMap = new Map();

  rentalPointData?.bikes.forEach((bike) => {
    const category = bike?.category;
    // Проверяем, что объект категории не пуст и имеет id
    if (Object.keys(category).length > 0 && category?.id) {
      // Добавляем весь объект категории в Map, если его там ещё нет
      if (!uniqueCategoriesMap.has(category.id)) {
        uniqueCategoriesMap.set(category.id, category);
      }
    }
  });

  // Преобразуем Map обратно в массив
  const uniqueCategories = Array.from(uniqueCategoriesMap.values());

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        backgroundColor: "var(--color-gray-100)",
        width: "100%",
        height: "100%",
        borderRadius: "16px",
        boxSizing: "border-box",
      }}
    >
      {isLoading ? (
        <div
          style={{
            gridColumn: "span 2",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Preloader design="white" />
        </div>
      ) : (
        <>
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "space-between",
              position: "relative",
              padding: "16px",
              boxSizing: "border-box",
            }}
          >
            <p className="p-light">Bikes presented:</p>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "self-start",
              }}
            >
              {uniqueCategories.map((category) => (
                <h5 key={category.id}>{category?.title[0]}</h5>
              ))}
            </div>
          </div>
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                textAlign: "center",
                fontSize: "4rem",
                fontWeight: "600",
                color: "var(--color-black)",
              }}
            >
              {rentalPointData?.bikes?.length}
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default BikesInform;
