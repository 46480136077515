import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const WidgetNavigateMenu = ({ onClose, mainMenu }) => {
  // const location = useLocation();
  // const navigate = useNavigate();
  const [language, setLanguage] = useState("en");

  const { currentUser, isLoading } = useSelector((state) => state.userSliceV2);

  //   const { mainMenu, authMenu } = useSelector((state) => state.navigateSliceV2);

  useEffect(() => {
    setLanguage(currentUser?.settings?.language || "en");
  }, [currentUser]);
  // const role = currentUser ? currentUser.role : null;

  return (
    <>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gap: "8px",
        }}
      >
        {mainMenu?.map((itemMenu, index) => (
          <Link
            key={itemMenu.slug}
            style={{
              width: "100%",
              padding: "16px",
              boxSizing: "border-box",
              aspectRatio: "1/1",
              borderRadius: "16px",
              backgroundColor: "var(--color-gray-100)",
              ...(itemMenu.title === "Catalog" && {
                gridColumn: "1 / span 2",
                gridRow: "2 / span 2",
                backgroundColor: "var(--color-gray-100)",
              }),
            }}
            to={`/${language}${itemMenu.slug}`}
            onClick={onClose}
          >
            <h5>{itemMenu.title}</h5>
          </Link>
        ))}
      </div>
    </>
  );
};

export default WidgetNavigateMenu;
