import React, { useEffect, useState } from "react";
import getOrderByUserId from "./getOrderByUserId";
import { useSubscribeData } from "../../../../../services/firebase/crudFirebaseStorage";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserData } from "../../../../../slice/thunk/fetchUserdata";
import { v4 as uuidv4 } from "uuid";
import Preloader from "../../../../UIElements/Preloader";
import OrderDetail from "./OrderDetail";
import { setCurrentOrderData } from "../../../../../slice/stateFrontGUISlice";
import { fetchRentalPointsAndOrdersByUidThunk } from "../../../../../slice/thunk/fetchRentalPointsAndOrdersByUidThunk";

const Client = () => {
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.userSliceV2.currentUser);
  const uid = currentUser && currentUser.user ? currentUser.user.id : null;
  const isLoading = useSelector((state) => state.userSliceV2.isLoading);
  const userData = useSelector((state) => state.stateFrontGUISlice.userData);

  const ordersData = useSelector(
    (state) => state.stateFrontGUISlice.ordersData
  );

  const isLoadingOrdersData = useSelector(
    (state) => state.stateFrontGUISlice.isLoadingOrdersData
  );

  useEffect(() => {
    if (uid) {
      dispatch(fetchUserData(uid));
      dispatch(fetchRentalPointsAndOrdersByUidThunk(uid));
    }
  }, [dispatch, currentUser]);

  return (
    <div className="content-container">
      <div className="content-body">
        <div
          style={{
            gridColumn: "span 2",
          }}
        >
          <h1
            style={{
              fontSize: "3em",
              fontWeight: "300",
            }}
          >
            Title
          </h1>
        </div>

        <div
          style={{
            gridColumn: "1",
            display: "flex",
            flexDirection: "column",
            boxSizing: "border-box",
            width: "100%",
            zIndex: "1",
          }}
        >
          <div
            style={{
              position: "sticky",
              top: "0",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100vh",
                backgroundColor: "var(--color-white)",
              }}
            >
              {!isLoadingOrdersData ? (
                <Preloader />
              ) : (
                ordersData?.map((order) => (
                  <div
                    key={uuidv4()}
                    onClick={() => dispatch(setCurrentOrderData(order))}
                  >
                    {order?.order_info?.currentBike?.id}
                  </div>
                ))
              )}
            </div>
          </div>
        </div>

        <div
          style={{
            gridColumn: "2",
            display: "flex",
            flexDirection: "column",
            gap: "32px",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100vh",
            }}
          >
            <OrderDetail />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Client;
