import React, { useEffect, useState } from "react";
import Resizer from "react-image-file-resizer";
import { useDispatch } from "react-redux";
import Button from "../Buttons/Button";
import Preloader from "../../../../../UIElements/Preloader";
import { uploadRentalPointsThunk } from "../../../../../../slice/private/rentalPoints/uploadRentalPointsThunk";

const WidgetUploadFiles = ({ collectionName, documentId, fieldName }) => {
  const dispatch = useDispatch();
  const [dragging, setDragging] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isUploading, setIsUploading] = useState([]);
  const [uploadingInProgress, setUploadingInProgress] = useState(false);

  // Обработчики событий перетаскивания файлов
  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const files = Array.from(e.dataTransfer.files);
    processFiles(files);
  };

  // Обработчик выбора файлов через input
  const handleFileSelect = (e) => {
    const files = Array.from(e.target.files);
    processFiles(files);
  };

  // Обработка файлов: ресайз изображений и добавление в список
  const processFiles = (files) => {
    const resizedFilesPromises = files.map((file) => {
      if (file.type.startsWith("image/") && file.size > 512 * 512) {
        return resizeFile(file);
      }
      return Promise.resolve(file);
    });

    Promise.all(resizedFilesPromises).then((resizedFiles) => {
      setSelectedFiles((prevFiles) => [...prevFiles, ...resizedFiles]);
    });
  };

  // Функция ресайза изображения
  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        800, // max width
        800, // max height
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  // Удаление файла из списка
  const removeFile = (fileToRemove) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((file) => file !== fileToRemove)
    );
  };

  // Загрузка файлов
  const handleUpload = async () => {
    setUploadingInProgress(true);
    for (const file of selectedFiles) {
      setIsUploading((prev) => [...prev, file]);
      try {
        await dispatch(
          uploadRentalPointsThunk({
            selectedFiles: [file],
            collectionName,
            documentId,
            fieldName,
          })
        ).unwrap();
        setSelectedFiles((prevFiles) => prevFiles.filter((f) => f !== file));
      } catch (error) {
        console.error(`Ошибка при загрузке файла ${file.name}:`, error);
      } finally {
        setIsUploading((prev) => prev.filter((f) => f !== file));
      }
    }
    setUploadingInProgress(false);
  };

  // Очистка списка файлов
  const handleClearUploadList = () => {
    setSelectedFiles([]);
  };

  return (
    <>
      <div
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        style={{
          backgroundColor: dragging
            ? "var(--color-priority)"
            : "var(--color-gray-100)",
          textAlign: "center",
          width: "100%",
          aspectRatio: "16/9",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "16px",
          position: "relative",
          boxSizing: "border-box",
        }}
      >
        <input
          type="file"
          onChange={handleFileSelect}
          style={{ display: "none" }}
          multiple
          id="fileInput"
        />
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "8px",
            padding: "8px",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              width: "100%",
              aspectRatio: "16/9",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h1
              style={{
                color: dragging
                  ? "var(--color-white)"
                  : "var(--color-gray-300)",
              }}
            >
              Drag and drop files here
            </h1>
          </div>

          {selectedFiles.length > 0 && (
            <ul
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                listStyleType: "none",
                gap: "8px",
                flex: "1",
                width: "100%",
                boxSizing: "border-box",
              }}
            >
              {selectedFiles?.map((file, index) => (
                <li
                  key={index}
                  style={{
                    width: "100%",
                    boxSizing: "border-box",
                    ...(index === 2
                      ? {
                          gridColumn: "1 / span 2",
                          gridRow: "1 / span 2",
                        }
                      : {
                          aspectRatio: "16/9",
                        }),
                  }}
                >
                  {file.type.startsWith("image/") ? (
                    <div
                      className="container"
                      style={{
                        backgroundImage: `url(${URL.createObjectURL(file)})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        borderRadius: "8px",
                        position: "relative",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      {!uploadingInProgress && (
                        <span
                          className="material-symbols-outlined hidden-element"
                          onClick={() => removeFile(file)}
                          style={{
                            position: "absolute",
                            top: "8px",
                            right: "8px",
                            cursor: "pointer",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            borderRadius: "50%",
                            padding: "4px",
                            color: "white",
                          }}
                        >
                          close
                        </span>
                      )}

                      {isUploading.includes(file) && (
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            backgroundColor: "var(--color-gray-100-alpha-50)",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "8px",
                          }}
                        >
                          <Preloader />
                        </div>
                      )}
                    </div>
                  ) : (
                    <span>{file.name}</span>
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>

        <button
          onClick={() => document.getElementById("fileInput").click()}
          style={{
            backgroundColor: "var(--color-primary)",
            color: "white",
            border: "none",
            borderRadius: "80px",
            height: "40px",
            width: "auto",
            cursor: "pointer",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: "4px",
            padding: "0 16px 0 8px",
            position: "absolute",
            right: "16px",
            top: "16px",
          }}
        >
          <span
            className="material-symbols-outlined"
            style={{
              color: "var(--color-white)",
            }}
          >
            add
          </span>
          <p className="p-light" style={{ color: "var(--color-white)" }}>
            Select photo
          </p>
        </button>
      </div>
      {selectedFiles.length > 0 && !uploadingInProgress && (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <Button
            color="--color-black"
            label="Clear upload list"
            onClick={handleClearUploadList}
            active={selectedFiles.length > 0}
          />

          <Button
            label={`Upload ${
              selectedFiles.length > 1
                ? `${selectedFiles.length} files`
                : "file"
            }`}
            onClick={handleUpload}
            active={selectedFiles.length > 0}
          />
        </div>
      )}
    </>
  );
};

export default WidgetUploadFiles;
