import React, { useState } from 'react';

/**
 * Компонент Radio.
 * Отображает радиокнопку с логикой выбора.
 * @param {Object} props - Свойства компонента.
 * @param {string} props.value - Значение радиокнопки.
 * @param {boolean} props.checked - Флаг выбора радiокнопки.
 * @param {function} props.onChange - Обработчик изменения состояния радиокнопки.
 * @returns {JSX.Element} Элемент компонента Radio.
 */
const Radio = ({ id, checked, onChange, value }) => {
    const handleRadioChange = () => {
        onChange(id);
    };

    return (
        <div className="radio-container">
            <label >
                <span
                    className={`radio-checkmark ${checked ? "fa-regular fa-circle-dot radio-checkmark-focus" : "fa-regular fa-circle"}`}
                >
                </span>
                {value}
                <input
                    type="radio"
                    value={value}
                    checked={checked}
                    onChange={handleRadioChange}
                />
            </label>
        </div>
    );
};

export default Radio;


// import React, { useState } from 'react';

// /**
//  * Компонент Radio.
//  * Отображает радиокнопку с логикой выбора.
//  * @param {Object} props - Свойства компонента.
//  * @param {string} props.value - Значение радиокнопки.
//  * @param {boolean} props.checked - Флаг выбора радиокнопки.
//  * @param {function} props.onChange - Обработчик изменения состояния радиокнопки.
//  * @returns {JSX.Element} Элемент компонента Radio.
//  */
// const Radio = ({ value, checked, onChange }) => {
//     const handleRadioChange = () => {
//         onChange(value);
//     };

//     return (
//         <label>
//             <input type="radio" value={value} checked={checked} onChange={handleRadioChange} />
//             {value}
//         </label>
//     );
// };

// export default Radio;
