import { createAsyncThunk } from "@reduxjs/toolkit";
import interpretAuthErrorV2 from "../../../functions/interpretAuthErrorV2";

// Функция для сериализации Timestamp
const serializeTimestamp = (timestamp) => ({
  seconds: timestamp.seconds,
  nanoseconds: timestamp.nanoseconds,
});

export const fetchRentalPointsThunkV2 = createAsyncThunk(
  "rentalPoints/fetchRentalPointsThunkV2",
  async (rentalPointsIds, { getState, rejectWithValue }) => {
    try {
      // Получение данных из directorySliceV2
      const state = getState();
      const { rentalPoints, cities, countries } = state.directorySliceV2;

      // Фильтрация точек проката по переданным идентификаторам (если они есть)
      const filteredRentalPoints = rentalPointsIds
        ? rentalPoints.filter((point) => rentalPointsIds.includes(point.id))
        : rentalPoints;

      // Создание мапов для городов и стран
      const cityMap = cities.reduce((acc, city) => {
        acc[city.id] = city;
        return acc;
      }, {});

      const countryMap = countries.reduce((acc, country) => {
        acc[country.id] = country;
        return acc;
      }, {});

      // Преобразование Timestamps и добавление информации о городах и странах
      const rentalPointsWithDetails = filteredRentalPoints.map((point) => {
        const cityId = point.directory?.cityId || "";
        const countryId = point.directory?.countryId || "";

        // Сериализация Timestamps
        if (point.order_info?.bikeCostDetails?.dateIntervalTimestamp) {
          point.order_info.bikeCostDetails.dateIntervalTimestamp =
            point.order_info.bikeCostDetails.dateIntervalTimestamp.map(
              (timestamp) => serializeTimestamp(timestamp)
            );
        }

        return {
          ...point,
          directory: {
            ...point.directory,
            city: cityMap[cityId] || {},
            country: countryMap[countryId] || {},
          },
        };
      });

      return {
        rentalPoints: rentalPointsWithDetails,
        cities,
        countries,
      };
    } catch (error) {
      const errorMessage = interpretAuthErrorV2(error.code);
      return rejectWithValue({ errorMessage });
    }
  }
);

// import { createAsyncThunk } from "@reduxjs/toolkit";
// import { fetchDocumentsByIds } from "../../../services/firebase/crudFirestore";
// import interpretAuthErrorV2 from "../../../functions/interpretAuthErrorV2";

// // Функция для преобразования Timestamp в сериализуемый формат
// const serializeTimestamp = (timestamp) => ({
//   seconds: timestamp.seconds,
//   nanoseconds: timestamp.nanoseconds,
// });

// export const fetchRentalPointsThunkV2 = createAsyncThunk(
//   "rentalPoints/fetchRentalPointsThunkV2",
//   async (rentalPointsIds, { rejectWithValue }) => {
//     try {
//       const rentalPoints = await fetchDocumentsByIds(
//         "rentalPoints",
//         rentalPointsIds
//       );
//       const cities = await fetchDocumentsByIds("city");
//       const countries = await fetchDocumentsByIds("country");

//       const cityMap = cities.reduce((acc, city) => {
//         acc[city.id] = city;
//         return acc;
//       }, {});

//       const countryMap = countries.reduce((acc, country) => {
//         acc[country.id] = country;
//         return acc;
//       }, {});

//       // Преобразование Timestamps в сериализуемые объекты перед возвратом данных
//       const rentalPointsWithDetails = rentalPoints.map((point) => {
//         const cityId = point.directory?.cityId || "";
//         const countryId = point.directory?.countryId || "";

//         // Преобразование всех возможных Timestamps в point
//         if (point.order_info?.bikeCostDetails?.dateIntervalTimestamp) {
//           point.order_info.bikeCostDetails.dateIntervalTimestamp =
//             point.order_info.bikeCostDetails.dateIntervalTimestamp.map(
//               (timestamp) => serializeTimestamp(timestamp)
//             );
//         }

//         return {
//           ...point,
//           directory: {
//             ...point.directory,
//             city: cityMap[cityId] || {},
//             country: countryMap[countryId] || {},
//           },
//         };
//       });

//       return {
//         rentalPoints: rentalPointsWithDetails,
//         cities,
//         countries,
//       };
//     } catch (error) {
//       const errorMessage = interpretAuthErrorV2(error.code);
//       return rejectWithValue({ errorMessage });
//     }
//   }
// );
