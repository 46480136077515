import React, { useEffect, useState } from "react";
import PriorityImage from "../../Private/Login/Profile/WidgetsProfile/PhotosGallery/PriorityImage";
import PhotosGallery from "../../Private/Login/Profile/WidgetsProfile/PhotosGallery/PhotosGallery";
import { Link } from "react-router-dom";

const Rate = ({ rate, bookingDates }) => {
  const [filteredRates, setFilteredRates] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    if (rate && bookingDates.length === 2) {
      const startMonth = new Date(bookingDates[0]).getMonth();
      const endMonth = new Date(bookingDates[1]).getMonth();

      setStartDate(new Date(bookingDates[0]));
      setEndDate(new Date(bookingDates[1]));

      const filtered = rate.filter(
        (r) => r.month >= startMonth && r.month <= endMonth
      );
      setFilteredRates(filtered);
    }
  }, [rate, bookingDates]);

  const getMonthName = (monthNumber) => {
    const date = new Date();
    date.setMonth(monthNumber);
    return date.toLocaleString("en-US", { month: "long" });
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "2px",
      }}
    >
      {filteredRates?.length > 0 ? (
        filteredRates?.map((r, index) => (
          <div
            key={index}
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "8px",
            }}
          >
            <p>{getMonthName(r.month)}</p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <p>
                {r.price.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
                &nbsp;in day
              </p>

              {r.discount > 0 && (
                <div
                  style={{
                    background: "var(--gradient-primary-2)",
                    color: "var(--color-white)",
                    padding: "2px 4px",
                    borderRadius: "4px",
                  }}
                  className="p-light"
                >
                  -{r.discount}%&nbsp;off
                </div>
              )}
            </div>
          </div>
        ))
      ) : (
        <p>No rates available for selected dates.</p>
      )}
    </div>
  );
};
const WidgetPrewievItemBike = ({ itemBike, bookingDates, language }) => {
  const [titleBike, setTitleBike] = useState("No title");
  const [descriptionBike, setDescriptionBike] = useState("No description");
  const [bikePhotoFiles, setBikePhotoFiles] = useState([]);
  const [brandTitle, setBrandTitle] = useState("No brand");
  const [categoryTitle, setCategoryTitle] = useState("No category");
  const [rate, setRate] = useState([]);
  const [idBike, setIdBike] = useState("");
  const [logoRentalPoint, setLogoRentalPoint] = useState([]);
  const [titleRentalPoint, setTitleRentalPoint] = useState("");
  const [quantityBikes, setQuantityBikes] = useState(0);

  useEffect(() => {
    setIdBike(itemBike?.id || "");
    setTitleBike(
      itemBike?.basicInformation?.title?.titleOriginal || "No title"
    );

    setBikePhotoFiles(itemBike?.files?.bikePhotoFiles || []);
    setBrandTitle(
      itemBike?.directory?.brand?.title?.titleOriginal || "No brand"
    );
    setCategoryTitle(
      itemBike?.directory?.category?.title?.titleOriginal || "No category"
    );
    setDescriptionBike(
      itemBike?.basicInformation?.description?.descriptionOriginal ||
        "No category"
    );
    setRate(itemBike?.rate || []);
    setLogoRentalPoint(itemBike?.rentalPoint?.files?.logoFile || []);

    setTitleRentalPoint(
      itemBike?.rentalPoint?.basicInformation?.title?.titleOriginal || ""
    );
    setQuantityBikes(itemBike?.rentalPoint?.bikesIds.length || 0);
  }, [itemBike]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        backgroundColor: "var(--color-white)",
        borderRadius: "24px",
        padding: "8px",
      }}
    >
      <PhotosGallery photos={bikePhotoFiles} />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "8px",
          padding: "0 16px",
        }}
      >
        <PriorityImage
          photos={logoRentalPoint}
          style={{
            aspectRatio: "1/1",
            borderRadius: "100%",
            width: "48px",
          }}
        />

        <div>
          <div
            className="p-light"
            style={{
              color: "var(--color-black)",
              display: "-webkit-box",
              WebkitLineClamp: 1,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {titleRentalPoint}
            {/* <span className="material-symbols-outlined p-light">
              star_rate_half
            </span>
            5/5 */}
          </div>
          <p className="p-light">Bikes: {quantityBikes}</p>
        </div>
      </div>

      <Link
        to={`/${language}/productCard/${titleBike.replace(
          / /g,
          "-"
        )}/${idBike}`}
        style={{
          padding: "0 16px 16px 16px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "16px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "4px",
          }}
        >
          <p
            style={{
              backgroundColor: "var(--color-gray-100)",
              padding: "4px 8px",
              borderRadius: "4px",
            }}
            className="p-light"
          >
            {brandTitle}
          </p>
          <p
            style={{
              backgroundColor: "var(--color-gray-100)",
              padding: "4px 8px",
              borderRadius: "4px",
            }}
            className="p-light"
          >
            {categoryTitle}
          </p>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "2px",
          }}
        >
          <h5
            style={{
              color: "var(--color-black)",
              display: "-webkit-box",
              WebkitLineClamp: 1,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {titleBike}
          </h5>

          <p
            className="p-light"
            style={{
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {descriptionBike}
          </p>
        </div>

        <Rate rate={rate} bookingDates={bookingDates} />
      </Link>
    </div>
  );
};

export default WidgetPrewievItemBike;
