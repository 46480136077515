import React from "react";
import { format } from "date-fns";
import ImageWithLoader from "../../UIComponents/bars/gallery/ImageWithLoader";
import { useSelector } from "react-redux";
import Preloader from "../../UIElements/Preloader";

const PricesGradient = ({
  pricePercentage,
  currentDateInterval,
  currentBike,
}) => {
  const isLoading = useSelector((state) => state.stateFrontGUISlice.isLoading);

  const startDay = currentDateInterval[0];
  const startDate = new Date(startDay);
  const startMonth = startDate.getMonth() + 1;

  const endDay = currentDateInterval[1];
  const endDate = new Date(endDay);
  const endMonth = endDate.getMonth() + 1;

  return (
    <div className="wigetBikes-content-grid-left-content">
      {isLoading ? (
        <div className="wigetProdictCard-in-box">
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Preloader />
          </div>
        </div>
      ) : (
        <>
          <div className="wigetProdictCard-in-box">
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h5>Price dynamics for 12 months</h5>

              <div title={currentBike?.rentalPoint?.title.join(", ")}>
                <div
                  style={{
                    height: "40px",
                    aspectRatio: "1/1",
                    boxSizing: "border-box",
                    borderRadius: "80px",
                    overflow: "hidden",
                  }}
                >
                  <ImageWithLoader
                    aspectRatio="1/1"
                    url={currentBike?.rentalPoint?.file_logo}
                    alt={currentBike?.rentalPoint?.title}
                  />
                </div>
              </div>
            </div>

            <div className="wigetProdictCard-listPrice">
              {pricePercentage.length > 0 &&
                pricePercentage.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <p style={{ paddingBottom: "8px" }}>
                      {item.price.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                    </p>

                    <div
                      style={{
                        minHeight: "8px",
                        height: `${item.pricePercentage}px`,
                        backgroundColor:
                          startMonth <= item.month && item.month <= endMonth
                            ? "var(--color-success)"
                            : "var(--color-gray-100)",
                        width: "100%",
                        borderRadius: "4px 4px 0 0",
                      }}
                    />

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "start",
                        gap: "4px",
                      }}
                    >
                      {item?.month === new Date().getMonth() + 1 ? (
                        <div
                          className={`wigetProdictCard-listPrice-date dateNow`}
                          title={format(new Date(), "dd.MM.yyyy")}
                        />
                      ) : (
                        <div
                          className={`wigetProdictCard-listPrice-date`}
                          title={format(new Date(), "dd.MM.yyyy")}
                        />
                      )}
                      <p
                        className={`wigetProdictCard-listPrice-date ${
                          startMonth <= item.month && item.month <= endMonth
                            ? "selectedDate"
                            : ""
                        }`}
                      >
                        {item?.monthName?.slice(0, 3)}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PricesGradient;
