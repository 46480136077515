import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchData,
  fetchDocumentById,
  fetchDocumentsByIds,
} from "../../services/firebase/crudFirebaseStorage";
import { useState } from "react";
import { fetchBikeData } from "./fetchBikeData";

export const fetchRentalPointsAndOrdersByUidThunk = createAsyncThunk(
  "manage/fetchRentalPointsAndOrdersByUidThunk",
  async (uId, thunkAPI) => {
    // Получаем текущее состояние store
    // const state = thunkAPI.getState();

    // Доступ к данным о bike из bikeSlice
    // const bikeStatus = state.bike.isLoading; // Пример получения статуса bike

    // console.log("fetchRentalPointsAndOrdersByUidThunk-bikeStatus:", bikeStatus);

    try {
      // Вызываем fetchBikeData и ждем его завершения
      // const response = await thunkAPI.dispatch(fetchBikeData());

      // Доступ к результату выполнения fetchBikeData
      // const bikeData = response.payload;
      // console.log('fetchRentalPointsAndOrdersByUidThunk-bikeData', bikeData);

      // Используем setTimeout для создания задержки в 1 секунду
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // fetchRentalPointsByUidThunk может принимать uId как аргумент
      // const countryData = await fetchData("country");
      // console.log("fetchRentalPointsAndOrdersByUidThunk-countryData:", countryData);

      // const cityData = await fetchData("city");
      // console.log("fetchRentalPointsAndOrdersByUidThunk-cityData:", cityData);

      const userData = await fetchDocumentById("user", uId);

      console.log("fetchRentalPointsAndOrdersByUidThunk-userData", userData);

      // await для непосредственного ожидания получения документов из коллекции rentalPoints
      const rentalPointsData = await fetchDocumentsByIds(
        "rentalPoints",
        userData.rentalPoints_id,
        []
      );

      console.log(
        "fetchRentalPointsAndOrdersByUidThunk-rentalPointsData:",
        rentalPointsData
      );

      // Собираем все bikes_id в один массив с помощью reduce
      const allBikesIds = rentalPointsData.reduce((acc, item) => {
        return acc.concat(item.bikes_id);
      }, []);

      // Используем Set для удаления дубликатов и преобразуем его обратно в массив
      const uniqueBikesIds = Array.from(new Set(allBikesIds));

      console.log(
        "fetchRentalPointsAndOrdersByUidThunk-uniqueBikesIds:",
        uniqueBikesIds
      );

      // Собираем все city_id в один массив с помощью reduce
      const allCityIds = rentalPointsData.reduce((acc, item) => {
        return acc.concat(item.city_id); // Используем concat для добавления city_id в аккумулятор
      }, []);

      // Используем Set для удаления дубликатов и преобразуем его обратно в массив
      const uniqueCityIds = Array.from(new Set(allCityIds));

      console.log(
        "fetchRentalPointsAndOrdersByUidThunk-uniqueCityIds:",
        uniqueCityIds
      );

      // await для непосредственного ожидания получения документов из коллекции bikes
      const bikesData = await fetchDocumentsByIds("bikes", uniqueBikesIds, []);

      console.log("fetchRentalPointsAndOrdersByUidThunk-bikesData:", bikesData);

      // await для непосредственного ожидания получения документов из коллекции orders
      const ordersData = await fetchDocumentsByIds(
        "orders",
        userData.orders_id,
        []
      );

      console.log(
        "fetchRentalPointsAndOrdersByUidThunk-ordersData:",
        ordersData
      );

      // let matchedOrdersClient = [];
      // let matchedOrdersManage = [];
      // let matchedRentalPointsClient = [];

      // if (ordersData && userData && rentalPointsData) {

      //   // Фильтрация заказов конкретного пользователя
      //   matchedOrdersClient = ordersData.filter((order) =>
      //     userData.orders_id.includes(order.id)
      //   );

      //   console.log("ordersData matchedOrdersClient ✅", matchedOrdersClient);

      //   // Фильтрация заказов по точкам проката которые зарегистрировал пользователь
      //   matchedOrdersManage = ordersData.filter((order) =>
      //     userData?.rentalPoints_id.includes(order.order_info.rentalPoints_id)
      //   );

      //   console.log("ordersData matchedOrdersManage ✅", matchedOrdersManage);

      //   matchedRentalPointsClient = rentalPointsData.filter((rentalPoint) =>
      //     userData.rentalPoints_id.includes(rentalPoint.id)
      //   );

      //   console.log(
      //     "ordersData matchedRentalPointsClient ✅",
      //     matchedRentalPointsClient
      //   );
      // }

      // console.log("ordersData ✅", ordersData);

      // Возвращаем объект с необходимыми данными
      return {
        // matchedOrdersClient,
        // matchedOrdersManage,
        // ordersData,
        userData,
      };
    } catch (error) {
      // Если произошла ошибка при получении данных, выбрасываем ошибку с сообщением
      throw new Error(
        `Ошибка при получении данных ordersData: ${error.message}`
      );
    }
  }
);
