import React, { useState, useRef } from "react";
import validatePhoneNumber from "../../functions/validatePhoneNumber";

const InputPhoneV2 = ({
  label,
  name,
  value,
  onChange,
  description,
  maxLength,
  title,
}) => {
  const inputRef = useRef(null);

  const [isFocused, setIsFocused] = useState(false);
  const [error, setError] = useState("");
  const [country, setCountry] = useState("");

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
    // Выполнить проверку при потере фокуса
    const validation = validatePhoneNumber(value);
    if (!validation.valid) {
      setCountry("");
      setError("Номер телефона недействителен.");
    } else {
      setCountry(validation.country);
      setError(""); // Очистить ошибку, если номер валиден
    }
  };

  const handleChange = (e) => {
    onChange(e); // Передать изменение наверх
    setError(""); // Сбросить ошибку при каждом изменении
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "8px",
        width: "100%",
      }}
    >
      <div className="input-container">
        <label
          style={{
            color: error && "var(--color-alarm)",
          }}
          htmlFor={name}
          className={`input-label ${
            isFocused || value ? "input-label-up" : ""
          }`}
        >
          {label}
        </label>
        <div
          style={{
            position: "absolute",
            right: "16px",
            top: "20px",
          }}
        >
          {country}
        </div>
        <input
          className="input-write"
          type="tel" // Тип поля установлен как tel
          id={name}
          name={name}
          placeholder={name}
          value={value}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          ref={inputRef}
          required={true}
          autoComplete="off" // Исправлено значение свойства
          maxLength={maxLength}
        />
      </div>
      {description && (
        <div
          style={{
            padding: "0 16px",
            width: "100%",
          }}
        >
          <p
            style={{
              width: "70%",
              color: "var(--color-gray-700)",
            }}
            className="p-light"
          >
            <>
              {error ? (
                <span style={{ color: "var(--color-alarm)" }}>{error}</span>
              ) : (
                <>{description}</>
              )}
            </>
          </p>
        </div>
      )}
    </div>
  );
};

export default InputPhoneV2;
