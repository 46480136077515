import { initializeApp, getApps } from "firebase/app";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyA9e476qujEfoSTz1VqtU7L-zmJIHRc9o0",
  authDomain: "biketripste.firebaseapp.com",
  databaseURL:
    "https://biketripste-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "biketripste",
  storageBucket: "biketripste.appspot.com",
  messagingSenderId: "855588208493",
  appId: "1:855588208493:web:1ebc65b32f6fac37476464",
  measurementId: "G-RZZVLKC2Z9",
};

// const firebaseConfig = {
//   apiKey: "AIzaSyA9e476qujEfoSTz1VqtU7L-zmJIHRc9o0",
//   authDomain: "biketripste.firebaseapp.com",
//   projectId: "biketripste",
//   storageBucket: "biketripste.appspot.com",
//   messagingSenderId: "855588208493",
//   appId: "1:855588208493:web:1ebc65b32f6fac37476464",
//   measurementId: "G-RZZVLKC2Z9",
// };

// testBikesTripster
// const firebaseConfig = {
//     apiKey: "AIzaSyDoSLJ1_Nszl8N0MaZyEiDOewuZyCF5u2s",
//     authDomain: "testbikestripster.firebaseapp.com",
//     projectId: "testbikestripster",
//     storageBucket: "testbikestripster.appspot.com",
//     messagingSenderId: "398541877273",
//     appId: "1:398541877273:web:d52f20bef3a57f86d17a88"
// };

// BikesTripster
// const firebaseConfig = {
//   apiKey: "AIzaSyCyClCShAWr5q1Kq1Bb9iV25wjT06F-ltw",
//   authDomain: "bikestripster.firebaseapp.com",
//   databaseURL: "https://bikestripster-default-rtdb.firebaseio.com",
//   projectId: "bikestripster",
//   storageBucket: "bikestripster.appspot.com",
//   messagingSenderId: "1027359510735",
//   appId: "1:1027359510735:web:6d844ab18e5d4283c227ba",
// };

// testBikesTripsterV2
// const firebaseConfig = {
//     apiKey: "AIzaSyCJjd37K8p5Ql7Ir37YD5MwOctyAMqIS6k",
//     authDomain: "testbikestripsterv2.firebaseapp.com",
//     projectId: "testbikestripsterv2",
//     storageBucket: "testbikestripsterv2.appspot.com",
//     messagingSenderId: "500387920164",
//     appId: "1:500387920164:web:86966e61f8357bb7585bb1"
// };

let app;

if (!getApps().length) {
  app = initializeApp(firebaseConfig);
} else {
  app = getApps()[0];
}

const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);
const functions = getFunctions(app);

// ********** Подключение эмулятора **********
// / Подключение к эмулятору Firestore
// if (window.location.hostname === "localhost") {
//   connectFirestoreEmulator(db, "localhost", 8080);
// }

// // Подключение к эмулятору Authentication
// if (window.location.hostname === "localhost") {
//   connectAuthEmulator(auth, "http://localhost:9099");
// }

// // Подключение к эмулятору Storage
// if (window.location.hostname === "localhost") {
//   connectStorageEmulator(storage, "localhost", 9199);
// }

// // Подключение к эмулятору Functions
// if (window.location.hostname === "localhost") {
//   connectFunctionsEmulator(functions, "localhost", 5001);
// }

// ********** Подключение эмулятора **********

export { db, storage, auth, functions };
