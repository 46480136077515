import React from "react";
// import Menu from "../Menu/PrivateMenu";
import WigetFAQBox from "./WigetFAQBox";

// import { Outlet } from "react-router-dom";

const UIFAQ = () => {
  return (
    <>
      <div className="private_profile-mainBox">
        {/* <Menu /> */}
        <div className="private_profile">
          <div className="private_content-box">
            <div
              style={{
                height: "calc(100vh - 64px)",
              }}
              className="private_wiget-big-box"
            >
              <WigetFAQBox />
            </div>
            {/* <div className="private_wiget-big-box" >
                        <WigetFAQListBox />
                    </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default UIFAQ;
