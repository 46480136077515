import React from "react";
import TitleBlockSection from "../../Private/Login/Profile/WidgetsProfile/Titles/TitleBlockSection";

const PageAbout = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        boxSizing: "border-box",
        backgroundColor: "var(--color-gray-100)",
        padding: "16px",
      }}
    >
      <div
        style={{
          paddingTop: "80px",
          maxWidth: "var(--width-mobile-content)",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "8px",
          boxSizing: "border-box",
          position: "relative",
        }}
      >
        <TitleBlockSection
          title="About"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse efficitur, nunc eget malesuada tempus, justo leo ullamcorper sapien, ac rhoncus justo magna non turpis. Sed tristique odio eu magna convallis interdum. Pellentesque vitae tortor est."
        />
        <div
          style={{
            width: "100%",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            backgroundColor: "var(--color-white)",
            borderRadius: "24px",
            padding: "24px",
          }}
        >
          <div className="content-box">
            <h5>How to book a bicycle, scooter, motorcycle, or quad bike?</h5>

            <p
              className="p-light"
              style={{
                fontWeight: "600",
                width: "80%",
              }}
            >
              You can book transportation on the Bike For Your Trip website:
              open the "Catalog" section. Then, follow these steps:
            </p>

            <ul className="custom-list p-light">
              <li className="custom-list-item">
                <p className="custom-list-point">—</p>
                <p>
                  Specify the city and country, travel dates, and the number of
                  vehicles.
                </p>
              </li>

              <li className="custom-list-item">
                <p className="custom-list-point">—</p>
                <p>
                  Choose suitable transportation on the map or from the list.
                  You can use filters to limit your budget, select the make and
                  type of vehicle.
                </p>
              </li>

              <li className="custom-list-item">
                <p className="custom-list-point">—</p>
                <p>
                  Click on the transportation option you like to see detailed
                  information. If everything is satisfactory, select the
                  appropriate additional services, such as airport delivery.
                </p>
              </li>

              <li className="custom-list-item">
                <p className="custom-list-point">—</p>
                <p>
                  Fill in driver details: last name, first name, phone, and
                  email. Some rentals may require a photo of your driver's
                  license. If everything is in order, click "Book." If you
                  choose online payment, enter your card details.
                </p>
              </li>
            </ul>
            <p className="p-light">
              After the rental confirms the booking, you will receive an email
              with the booking details to the email you provided. They will also
              appear in your Bike For Your Trip account: go to your account and
              access the "My Bookings" section.
            </p>
          </div>
        </div>

        <div
          style={{
            width: "100%",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            backgroundColor: "var(--color-white)",
            borderRadius: "24px",
            padding: "24px",
          }}
        >
          <div className="content-box">
            <h5>Are driver's licenses required?</h5>

            <div className="custom-list p-light">
              <h5>Bicycle</h5>
              {/* <div
              style={{
                backgroundImage:
                  'url("https://firebasestorage.googleapis.com/v0/b/testbikestripster.appspot.com/o/imgStatic%2FBicycle.jpg?alt=media&token=2fc89209-2a62-46ea-90e9-1e401c4512c2&_gl=1*z9ojwh*_ga*NzE3MzY1ODU1LjE2ODQ3NTEzMTQ.*_ga_CW55HF8NVT*MTY5NzQ4NDAwNS44LjEuMTY5NzQ4NDAzMy4zMi4wLjA.")',
                backgroundSize: "100% auto",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                width: "100%",
                aspectRatio: "16/5",
                padding: "24px",
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "flex-end",
              }}
            >
              <h1
                style={{
                  fontSize: "4em",
                  color: "var(--color-white)",
                }}
              >
                Bicycle
              </h1>
            </div> */}

              <div className="custom-list-item">
                <p className="custom-list-point">—</p>
                <p>
                  In Latvia, Israel, and some other countries, a bicycle license
                  is required.
                </p>
              </div>
              <div className="custom-list-item">
                <p className="custom-list-point">—</p>
                <p>
                  In Australia, wearing a helmet is mandatory, and in Miami,
                  riding without a horn is prohibited.
                </p>
              </div>
              <p>
                Most countries do not require special licenses for riding
                bicycles, but you can find this information in the specific
                bicycle description when booking.
              </p>
            </div>

            <div className="custom-list p-light">
              <h5>Scooter</h5>
              {/* <div
              style={{
                backgroundImage:
                  'url("https://firebasestorage.googleapis.com/v0/b/testbikestripster.appspot.com/o/imgStatic%2FScooters.jpg?alt=media&token=371b474e-94c2-4c00-895b-3599fd147d7a&_gl=1*1716knt*_ga*NzE3MzY1ODU1LjE2ODQ3NTEzMTQ.*_ga_CW55HF8NVT*MTY5NzQ4NDAwNS44LjEuMTY5NzQ4NDM5Ni42MC4wLjA.")',
                backgroundSize: "100% auto",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                width: "100%",
                aspectRatio: "16/5",
                padding: "24px",
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "flex-end",
              }}
            >
              <h1
                style={{
                  fontSize: "4em",
                  color: "var(--color-white)",
                }}
              >
                Scooter
              </h1>
            </div> */}

              <div className="custom-list-item ">
                <p className="custom-list-point">—</p>
                <p>
                  In most cases, you can rent a scooter with a B or A1 category
                  license.
                </p>
              </div>
              <div className="custom-list-item">
                <p className="custom-list-point">—</p>
                <p>
                  Cambodia, China, and Sri Lanka only allow driving with local
                  licenses.
                </p>
              </div>
              <p>
                Not all rentals require licenses when booking, but this does not
                mean they are not required by law. In some countries, you may
                face minor fines, while in others, the penalties could be more
                significant.
              </p>
            </div>

            <div className="custom-list p-light">
              <h5>Motorcycle</h5>
              {/* <div
              style={{
                backgroundImage:
                  'url("https://firebasestorage.googleapis.com/v0/b/testbikestripster.appspot.com/o/imgStatic%2FMotorcycle.jpg?alt=media&token=347f6b19-9daa-4f09-bfa9-197754c15d13&_gl=1*1m05u26*_ga*NzE3MzY1ODU1LjE2ODQ3NTEzMTQ.*_ga_CW55HF8NVT*MTY5NzQ4NDAwNS44LjEuMTY5NzQ4NDU4Ny41OS4wLjA.")',
                backgroundSize: "100% auto",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                width: "100%",
                aspectRatio: "16/5",
                padding: "24px",
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "flex-end",
              }}
            >
              <h1
                style={{
                  fontSize: "4em",
                  color: "var(--color-white)",
                }}
              >
                Motorcycle
              </h1>
            </div> */}

              <div className="custom-list-item">
                <p className="custom-list-point">—</p>
                <p>Category A license is required for motorcycles.</p>
              </div>
              <div className="custom-list-item">
                <p className="custom-list-point">—</p>
                <p>
                  Cambodia, China, and Sri Lanka only allow driving with local
                  licenses.
                </p>
              </div>
              <p>
                While licenses are required by law in most countries, not all
                rental agencies ask for them.
              </p>
            </div>

            <div className="custom-list p-light">
              <h5>Quad bike</h5>
              {/* <div
              style={{
                backgroundImage:
                  'url("https://firebasestorage.googleapis.com/v0/b/testbikestripster.appspot.com/o/imgStatic%2FQuadBike2.jpg?alt=media&token=ed59645c-41cb-4f0e-a712-de473a39f024&_gl=1*88rsp6*_ga*NzE3MzY1ODU1LjE2ODQ3NTEzMTQ.*_ga_CW55HF8NVT*MTY5NzQ4NDAwNS44LjEuMTY5NzQ4NDg4OS41Ny4wLjA.")',
                backgroundSize: "100% auto",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                width: "100%",
                aspectRatio: "16/5",
                padding: "24px",
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "flex-end",
              }}
            >
              <h1
                style={{
                  fontSize: "4em",
                  color: "var(--color-white)",
                }}
              >
                Quad bike
              </h1>
            </div> */}

              <div className="custom-list-item">
                <p className="custom-list-point">—</p>
                <p>Category B is suitable for quad bikes.</p>
              </div>
              <div className="custom-list-item">
                <p className="custom-list-point">—</p>
                <p>
                  Despite having a license, some countries have age
                  restrictions.
                </p>
              </div>
              <p>
                In some countries, you can drive quad bikes at 16, while in
                others, the minimum age is 18. Specific limitations will be
                provided in the description of the particular quad bike.
              </p>
            </div>

            <div className="custom-list p-light">
              <h5>Scooters and Other Vehicles</h5>
              <div className="custom-list-item">
                <p className="custom-list-point">—</p>
                <p>
                  In most countries, no licenses are required for riding
                  scooters.
                </p>
              </div>
              <div className="custom-list-item">
                <p className="custom-list-point">—</p>
                <p>
                  Some countries prohibit scooter riding under the influence.
                </p>
              </div>
              <p>
                Scooters have the fewest legal restrictions in the legislation
                of many countries.
              </p>
              <p>Full terms of use for the Bike For Your Trip service.</p>
              {/* <button>
                        Полные условия начисления кэшбэка за покупки в Тинькофф Путешествиях
                    </button> */}
            </div>
          </div>
        </div>

        <div
          style={{
            width: "100%",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            backgroundColor: "var(--color-white)",
            borderRadius: "24px",
            padding: "24px",
          }}
        >
          <div className="content-box p-light">
            <h5>How to receive booking confirmations from the rental?</h5>
            <p>
              After payment for the vehicle reservation, a message about the new
              reservation is sent to the rental service. The rental service
              checks for the availability and condition of the vehicle on the
              date you've chosen.
            </p>
            <p>
              Once confirmed, the reservation is approved in the Bike For Your
              Trip service. In this case, you will receive a notification of the
              reservation confirmation and a voucher to the email address you
              provided. Please print the voucher or show it to the rental agent
              on your smartphone screen when picking up the vehicle. We cannot
              be held responsible in cases where the voucher was not presented.
            </p>
          </div>
        </div>

        <div
          style={{
            width: "100%",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            backgroundColor: "var(--color-white)",
            borderRadius: "24px",
            padding: "24px",
          }}
        >
          <div className="content-box p-light">
            <h5>Need to modify a reservation. What to do?</h5>
            <p>
              To modify a vehicle reservation, please contact the Bike For Your
              Trip customer support chat on the website or in your personal
              account. Our staff will get in touch with the rental agency to
              assist you.
            </p>
          </div>
        </div>

        <div
          style={{
            width: "100%",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            backgroundColor: "var(--color-white)",
            borderRadius: "24px",
            padding: "24px",
          }}
        >
          <div className="content-box p-light">
            <h5>How to cancel a reservation?</h5>
            <p>
              To modify a vehicle reservation, please contact the Bike For Your
              Trip customer support chat on the website or in your personal
              account. Our staff will get in touch with the rental agency to
              assist you.
            </p>
            <p>
              <b>In your personal account on the Bike For Your Trip website:</b>
              &nbsp;go to your personal account → go to your personal account →
              click on "Cancel Reservation"
            </p>
            <p>
              <b>Contact customer support:</b>
              &nbsp;provide the reservation number → include your last name and
              first name → specify the reason for canceling the reservation.
            </p>
            <p>
              After the cancellation is confirmed, the refunded amount will be
              returned to you within 2-3 weeks, depending on your region and
              your bank's terms. The funds will be refunded to the same card.
            </p>
          </div>
        </div>

        <div
          style={{
            width: "100%",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            backgroundColor: "var(--color-white)",
            borderRadius: "24px",
            padding: "24px",
          }}
        >
          <div className="content-box p-light">
            <h5>How to get in touch with customer support?</h5>
            <p>
              You can ask questions about vehicle reservations through the Bike
              For Your Trip customer support chat or via email at &nbsp;
              <a href="mailto:hotels@tinkoff.ru">help@BikeForYourTrip.com</a>.
              Our staff are available around the clock and will assist with any
              inquiries.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageAbout;
