import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addData,
  updateDocumentFields,
} from "../../../services/firebase/crudFirestore";

export const addRentalPointsThunkV2 = createAsyncThunk(
  "reducersToolkit/thunk/rentalPoints/addRentalPointsThunkV2",
  async (
    { collectionName, newData, userId },
    { getState, rejectWithValue }
  ) => {
    // const state = getState();
    // const currentUser = state.userSliceV2.currentUser;
    // const userId = currentUser ? currentUser.id : null;

    if (!userId) {
      return rejectWithValue("User ID not found");
    }

    try {
      const addedData = await addData(collectionName, newData);
      const rentalPointId = addedData.id;

      // Обновляем документ пользователя, добавляя новый rentalPointId в массив
      await updateDocumentFields("users", userId, {
        rentalPointsIds: [rentalPointId],
      });

      return addedData;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
