import React, { useEffect, useState } from "react";
import PopupDirrectory from "../Popups/PopupDirrectory";
import WindowFileDetail from "./WindowFileDetail";
import Preloader from "../../../../../UIElements/Preloader";
import Checkbox from "../Switcher/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { removeFilesThunk } from "../../../../../../slice/private/files/removeFilesThunk";
import Button from "../Buttons/Button";
import { subscribeToDocument } from "../../../../../../services/firebase/crudFirestore";

const WidgetListFile = ({
  // files,
  collectionName,
  documentId,
  fieldName,
  mode,
}) => {
  const dispatch = useDispatch();
  const [isOpenDetailPhoto, setIsOpenDetailPhoto] = useState(false);
  const [loadedImages, setLoadedImages] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [deletingFiles, setDeletingFiles] = useState([]);
  const [files, setFiles] = useState([]);

  const isRemove = useSelector((state) => state?.filesSlice?.isRemove);

  const handleClose = () => {
    setIsOpenDetailPhoto(false);
  };

  const handleToggleDetailPhoto = (fileItem) => {
    setSelectedFile(fileItem);
    setIsOpenDetailPhoto(!isOpenDetailPhoto);
  };

  const handleImageLoad = (index) => {
    setLoadedImages((prev) => ({ ...prev, [index]: true }));
  };

  const handleCheckboxChange = (event, fileItem) => {
    event.stopPropagation();
    setSelectedFiles((prevSelectedFiles) => {
      if (prevSelectedFiles.includes(fileItem)) {
        return prevSelectedFiles.filter((item) => item !== fileItem);
      } else {
        return [...prevSelectedFiles, fileItem];
      }
    });
  };

  const handleDeleteFiles = () => {
    const fileIds = selectedFiles.map((file) => file.id);
    setDeletingFiles(fileIds);
    dispatch(
      removeFilesThunk({
        collectionName,
        documentId,
        fieldName,
        fileIds,
      })
    ).then(() => {
      setDeletingFiles([]);
      setSelectedFiles([]);
    });
  };

  const handleReset = () => {
    setDeletingFiles([]);
    setSelectedFiles([]);
  };

  const getNestedField = (obj, path) => {
    return path.split(".").reduce((acc, part) => acc && acc[part], obj);
  };

  useEffect(() => {
    const unsubscribe = subscribeToDocument(
      collectionName,
      documentId,
      null,
      async (data) => {
        setFiles(data ? getNestedField(data, fieldName) : []);
      }
    );
    return () => {
      console.log("unsubscribe");
      unsubscribe();
    };
  }, [documentId, fieldName, collectionName]);

  return (
    files?.length > 0 && (
      <>
        <div
          style={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            flex: "1",
            gap: "8px",
            boxSizing: "border-box",
          }}
        >
          {files?.map((fileItem, index) => (
            <div
              key={index}
              style={{
                overflow: "hidden",
                backgroundImage: loadedImages[index]
                  ? `url(${fileItem.url})`
                  : "none",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundColor: "var(--color-gray-100)",
                borderRadius: "8px",
                cursor: "pointer",
                position: "relative",
                flex: "1",
                ...(index === 2
                  ? {
                      gridColumn: "1 / span 2",
                      gridRow: "1 / span 2",
                    }
                  : { aspectRatio: "16/9" }),
              }}
            >
              {!loadedImages[index] && (
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 1,
                  }}
                >
                  <Preloader color="--color-white" />
                </div>
              )}

              {deletingFiles.includes(fileItem.id) && (
                <div
                  style={{
                    position: "absolute",
                    zIndex: 1,
                    backgroundColor: "var(--color-gray-100-alpha-50)",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Preloader color="--color-white" />
                </div>
              )}

              <img
                src={fileItem.url}
                alt=""
                onLoad={() => handleImageLoad(index)}
                style={{ display: "none" }}
              />
              {mode === "edit" && (
                <div className="widgetListFile__checkbox-box">
                  <Checkbox
                    checked={selectedFiles.includes(fileItem)}
                    onChange={(e) => handleCheckboxChange(e, fileItem)}
                  />
                </div>
              )}

              {fileItem.priority && (
                <div
                  style={{
                    position: "absolute",
                    left: "0",
                    bottom: "0",
                    width: "auto",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    boxSizing: "border-box",
                    gap: "8px",
                    padding: "2px 8px 2px 2px",
                    backgroundColor: "var(--color-success)",
                    borderRadius: "4px",
                    margin: "8px",
                    color: "var(--color-white)",
                  }}
                >
                  <span className="material-symbols-outlined">task_alt</span>
                  <p
                    className="p-light"
                    style={{ color: "var(--color-white)" }}
                  >
                    main file
                  </p>
                </div>
              )}

              <div
                onClick={() => handleToggleDetailPhoto(fileItem)}
                className="widgetListFile__cleek-box"
              ></div>
            </div>
          ))}
        </div>
        {selectedFiles.length > 0 && !isRemove && (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "16px",
            }}
          >
            <>
              <Button
                color="--color-black"
                label="Cancel selections"
                onClick={handleReset}
                active={selectedFiles.length > 0 && !isRemove ? true : false}
              />
              <Button
                color="--color-alarm"
                label={`Remove ${
                  selectedFiles.length > 1
                    ? `${selectedFiles.length} files`
                    : "file"
                }`}
                onClick={handleDeleteFiles}
                active={selectedFiles.length > 0 && !isRemove ? true : false}
                allert={
                  <div
                    style={{
                      padding: "32px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "16px",
                      textAlign: "center",
                    }}
                  >
                    <h1>
                      Please confirm that you really want to delete
                      {selectedFiles.length === 1
                        ? "this file"
                        : `${selectedFiles.length} files.`}
                    </h1>
                    <p className="p-light">
                      This action is irreversible, and you will not be able to
                      recover it.
                    </p>
                  </div>
                }
              />
            </>
          </div>
        )}
        <PopupDirrectory
          isOpen={isOpenDetailPhoto}
          onClose={handleClose}
          title="Photo detail"
        >
          <WindowFileDetail
            currentFile={selectedFile}
            filesList={files}
            collectionName={collectionName}
            documentId={documentId}
            fieldName={fieldName}
            onClose={handleClose}
            mode={mode}
          />
        </PopupDirrectory>
      </>
    )
  );
};

export default WidgetListFile;
