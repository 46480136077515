import { createSlice } from "@reduxjs/toolkit";
import { fetchCountryAndCityThunk } from "./fetchCountryAndCityThunk";
import { addCountryThunk } from "./addCountryThunk";
import { updateCountryThunk } from "./updateCountryThunk";
import { deleteCountryThunk } from "./deleteCountryThunk";
import { deleteCityThunk } from "./deleteCityThunk";
import { fetchCountryThunk } from "./fetchCountryThunk";
import { addCityThunk } from "./addCityThunk";
import { updateCityThunk } from "./updateCityThunk";

const getCurrentTimestampInSeconds = () => Math.floor(Date.now() / 1000);

const initialState = {
  countryWithCities: [],
  newCountry: {
    createdat: getCurrentTimestampInSeconds(),
    title: {
      titleOriginal: "",
    },
    description: {
      descriptionOriginal: "",
    },
    files: {
      iconFiles: [],
    },
    cities: [],
  },
  newCity: {
    createdat: getCurrentTimestampInSeconds(),
    title: {
      titleOriginal: "",
    },
    description: {
      descriptionOriginal: "",
    },
    files: {
      iconFiles: [],
    },
  },
  selectedCountry: {},
  selectedCity: {},
  isLoading: false,
  error: null,
};

const countryAndCitySlice = createSlice({
  name: "countryAndCitySlice",
  initialState,
  reducers: {
    setDescriptionNew(state, action) {
      state.newCountry.description.descriptionOriginal = action.payload;
    },
    setTitleNew(state, action) {
      state.newCountry.title.titleOriginal = action.payload;
    },
    setSelectedCountry(state, action) {
      state.selectedCountry = action.payload;
    },
    setSelectedCity(state, action) {
      state.selectedCity = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCountryThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchCountryThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.countryWithCities = action.payload;
      })
      .addCase(fetchCountryThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(addCountryThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addCountryThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.countryWithCities.push(action.payload);
      })
      .addCase(addCountryThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(updateCountryThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateCountryThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        const { documentId, updatedFields } = action.payload;
        const index = state.countryWithCities.findIndex(
          (country) => country.id === documentId
        );
        if (index !== -1) {
          state.countryWithCities[index] = {
            ...state.countryWithCities[index],
            ...updatedFields,
          };
        }
      })
      .addCase(updateCountryThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(deleteCountryThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteCountryThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        const { countryId } = action.payload;
        state.countryWithCities = state.countryWithCities.filter(
          (country) => country.id !== countryId
        );
      })
      .addCase(deleteCountryThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(deleteCityThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteCityThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        const { countryId, cityId } = action.payload;
        const country = state.countryWithCities.find(
          (country) => country.id === countryId
        );
        if (country) {
          country.cities = country.cities.filter((city) => city.id !== cityId);
          if (state.selectedCountry.id === countryId) {
            state.selectedCountry = { ...country };
          }
        }
      })
      .addCase(deleteCityThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(addCityThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addCityThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        const { countryId, city } = action.payload;
        const country = state.countryWithCities.find(
          (country) => country.id === countryId
        );
        if (country) {
          country.cities.push(city);
          if (state.selectedCountry.id === countryId) {
            state.selectedCountry = { ...country };
          }
        }
      })
      .addCase(addCityThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(updateCityThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateCityThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        const { countryId, cityId, updatedFields } = action.payload;
        const country = state.countryWithCities.find(
          (country) => country.id === countryId
        );
        if (country) {
          const cityIndex = country.cities.findIndex(
            (city) => city.id === cityId
          );
          if (cityIndex !== -1) {
            country.cities[cityIndex] = {
              ...country.cities[cityIndex],
              ...updatedFields,
            };
            if (state.selectedCountry.id === countryId) {
              state.selectedCountry = { ...country };
            }
          }
        }
      })
      .addCase(updateCityThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const {
  setSelectedCountry,
  setSelectedCity,
  setTitleNew,
  setDescriptionNew,
} = countryAndCitySlice.actions;
export default countryAndCitySlice.reducer;
