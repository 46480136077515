import React, { useState } from "react";
import { Link } from "react-router-dom";
import Input from "../../UIElements/InputV2";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

const WigetResetPassword = ({ visible, onClose, img }) => {
  const [email, setEmail] = useState("");
  const [icon, setIcon] = useState(
    "https://firebasestorage.googleapis.com/v0/b/testbikestripster.appspot.com/o/imgStatic%2Fxxx.svg?alt=media&token=3509b01e-dbf4-4177-b194-f45fd353ed6e&_gl=1*1rg297l*_ga*NzE3MzY1ODU1LjE2ODQ3NTEzMTQ.*_ga_CW55HF8NVT*MTY5NzQ1MzcyMi42LjEuMTY5NzQ1NzQ0NS42MC4wLjA."
  );

  const [messageSendPass, setMessageSendPass] = useState(
    "Enter your email to reset your password"
  );

  if (!img) {
    img =
      "https://firebasestorage.googleapis.com/v0/b/testbikestripster.appspot.com/o/imgStatic%2Ferr7.jpg?alt=media&token=e2270e9b-8676-4592-aeb8-5e4182f78f66&_gl=1*11g1cum*_ga*NzE3MzY1ODU1LjE2ODQ3NTEzMTQ.*_ga_CW55HF8NVT*MTY5NzQzODQ5OC40LjEuMTY5NzQzOTg1MS40NC4wLjA.";
  }

  const [isVisible, setVisible] = useState(false);

  const handleCloseError = () => {
    setIcon(
      "https://firebasestorage.googleapis.com/v0/b/testbikestripster.appspot.com/o/imgStatic%2Fxxx.svg?alt=media&token=3509b01e-dbf4-4177-b194-f45fd353ed6e&_gl=1*1rg297l*_ga*NzE3MzY1ODU1LjE2ODQ3NTEzMTQ.*_ga_CW55HF8NVT*MTY5NzQ1MzcyMi42LjEuMTY5NzQ1NzQ0NS42MC4wLjA."
    );
    setVisible(false);
    onClose();
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  if (visible && !isVisible) {
    setVisible(true);
  }

  const handleResetPassword = async () => {
    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, email);
      setMessageSendPass(`Password reset request sent`);
      setIcon(
        "https://firebasestorage.googleapis.com/v0/b/testbikestripster.appspot.com/o/imgStatic%2Fv.svg?alt=media&token=439c1cec-689c-4ddf-975f-7c881dc1057d&_gl=1*1t3clcu*_ga*NzE3MzY1ODU1LjE2ODQ3NTEzMTQ.*_ga_CW55HF8NVT*MTY5NzQ1MzcyMi42LjEuMTY5NzQ1NzQ3Ni4yOS4wLjA."
      );
      // Успешно отправлен запрос на сброс пароля
      // Можете показать сообщение об успешной отправке или выполнить другие действия
    } catch (error) {
      console.error("Ошибка при отправке запроса на сброс пароля:", error);
      setIcon(
        "https://firebasestorage.googleapis.com/v0/b/testbikestripster.appspot.com/o/imgStatic%2Fx.svg?alt=media&token=32d238aa-4fb1-4306-b42e-f49c13b466f8&_gl=1*1ki858n*_ga*NzE3MzY1ODU1LjE2ODQ3NTEzMTQ.*_ga_CW55HF8NVT*MTY5NzQ1MzcyMi42LjEuMTY5NzQ1NzQ5OS42LjAuMA.."
      );
      setMessageSendPass(`Error sending password reset request`);
      // Обработка ошибки, например, показ сообщения об ошибке
    }
  };

  return (
    isVisible && (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "24px",
          color: "var(--color-alarm)",
          position: "absolute",
          top: "0",
          width: "100%",
          height: "100vh",
          left: "0",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          backdropFilter: "blur(10px)",
          zIndex: "100",
          boxSizing: "border-box",
        }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            borderRadius: "16px",
            color: "var(--color-alarm)",
            position: "absolute",
            width: "800px",
            aspectRatio: "16/9",
            backgroundColor: "var(--color-white)",
            backdropFilter: "blur(10px)",
            zIndex: "100",
            boxSizing: "border-box",
            textAlign: "center",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              gridColumn: "span 2",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "24px",
              padding: "40px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "16px",
              }}
            >
              <div
                style={{
                  backgroundImage: `url(${icon})`,
                  backgroundSize: "auto 100%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  width: "100%",
                  height: "40px",
                }}
              />
              <h1
                style={{
                  width: "80%",
                }}
              >
                {messageSendPass}
              </h1>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
              }}
            >
              <Input
                type="email"
                label="Email"
                value={email}
                onChange={handleEmailChange}
              />

              <p className="p-mini">
                If you have problems with the service and you cannot solve these
                problems yourself, &nbsp;
                <Link to={"/sipport"}>contact technical support</Link>&nbsp; and
                we will try to help you!
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: " 16px",
              }}
            >
              <button
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "16px",
                  width: "auto",
                  padding: "8px 24px",
                  borderRadius: "80px",
                  border: "none",
                  fontSize: "1em",
                  fontWeight: "500",
                  height: "56px",
                  backgroundColor: "var(--color-primary)",
                  color: "var(--color-white)",
                  cursor: "pointer",
                }}
                onClick={handleResetPassword}
              >
                Reset Password
              </button>
              <button
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "16px",
                  width: "auto",
                  padding: "8px 24px",
                  borderRadius: "80px",
                  border: "none",
                  fontSize: "1em",
                  fontWeight: "500",
                  height: "56px",
                  backgroundColor: "var(--color-black)",
                  color: "var(--color-white)",
                  cursor: "pointer",
                }}
                onClick={handleCloseError}
              >
                Close
              </button>
            </div>
          </div>

          <div
            style={{
              gridColumn: "span 1",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "var(--color-gray-200)",
              backgroundImage: `url(${img})`,
              height: "100%",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          />
        </div>
      </div>
    )
  );
};

export default WigetResetPassword;
