import React, { useEffect, useState } from "react";
import Button from "../WidgetsProfile/Buttons/Button.js";
import WidgetBikeRate from "./WidgetBikeRate.js";
import { useDispatch, useSelector } from "react-redux";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox.js";
import { updateBikeThunk } from "../../../../../slice/private/bikes/updateBikeThunk.js";
import WidgetBikeBasicInformation from "./WidgetBikeBasicInformation.js";
import WidgetBrandCategory from "./WidgetBrandCategory.js";
import { addBikesThunkV2 } from "../../../../../reducersToolkit/thunk/bikes/addBikesThunkV2.js";
import { deleteBikeThunk } from "../../../../../reducersToolkit/thunk/bikes/deleteBikesThunkV2.js";
import { clearForm } from "../../../../../reducersToolkit/bikesSliceV2.js";
import WidgetPhotosBike from "./WidgetPhotosBike.js";
import { updateBikeThunkV2 } from "../../../../../reducersToolkit/thunk/bikes/updateBikeThunkV2.js";
import WidgetQuntityBike from "./WidgetQuntityBike.js";
import WidgetBikeCharacteristicsList from "./WidgetBikeCharacteristicsList.js";
import ButtonsBar from "../WidgetsProfile/ButtonsBar/ButtonsBar.js";

const WindowBikeDetail = ({ mode, onClose }) => {
  const dispatch = useDispatch();

  const [basicInformation, setBasicInformation] = useState({});
  const [rate, setRate] = useState([]);
  const [brandId, setBrandId] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const [buttonState, setButtonState] = useState(null);
  const [isChanged, setIsChanged] = useState(false);
  const [metricsBike, setMetricsBike] = useState(1);
  const [characteristics, setCharacteristics] = useState([]);
  const [quantityBikes, setQuantityBikes] = useState(0);

  const { selectedRentalPoint } = useSelector(
    (state) => state.rentalPointsSliiceV2
  );

  const {
    isLoading: isLoadingBike,
    newBike,
    selectedBike,
  } = useSelector((state) => state.bikesSliceV2);

  const { countries, brands, categories } = useSelector(
    (state) => state.directorySliceV2
  );

  useEffect(() => {
    // Очистка формы
    dispatch(clearForm());
    // Формируем состояние для кнопки save
    if (mode === "new") {
      setButtonState(newBike);
    } else if (mode === "edit") {
      setButtonState(selectedBike);
    }
  }, [mode, dispatch]);

  useEffect(() => {
    if (mode === "new") {
      setBasicInformation(newBike?.basicInformation || {});
      setRate(newBike?.rate || []);
      setBrandId(newBike?.directory?.brandId || null);
      setCategoryId(newBike?.directory?.categoryId || null);
      setMetricsBike(newBike?.metricsBike || 1);
      setCharacteristics(newBike?.characteristics || []);
    } else if (mode === "edit") {
      setBasicInformation(selectedBike?.basicInformation || {});
      setRate(selectedBike?.rate || []);
      setBrandId(selectedBike?.directory?.brandId);
      setCategoryId(selectedBike?.directory?.categoryId);
      setMetricsBike(selectedBike?.metricsBike || 1);
      setCharacteristics(selectedBike?.characteristics || []);
    }
  }, [newBike, selectedBike, mode, dispatch]);

  useEffect(() => {
    const hasChanges = () => {
      return (
        JSON.stringify(buttonState?.basicInformation) !==
          JSON.stringify(basicInformation) ||
        JSON.stringify(buttonState?.rate) !== JSON.stringify(rate) ||
        buttonState?.directory?.brandId !== brandId ||
        buttonState?.directory?.categoryId !== categoryId ||
        buttonState?.metricsBike !== metricsBike ||
        buttonState?.characteristics !== characteristics
      );
    };

    setIsChanged(hasChanges());
  }, [
    basicInformation,
    rate,
    brandId,
    categoryId,
    metricsBike,
    buttonState,
    characteristics,
  ]);

  const handleAddBike = async () => {
    if (mode === "new") {
      dispatch(
        addBikesThunkV2({
          documentId: selectedRentalPoint.id,
          newBike: {
            ...newBike,
            basicInformation,
            rate,
            directory: {
              brandId: brandId,
              categoryId: categoryId,
            },
            metricsBike: {
              quantityBikes: quantityBikes,
            },
            characteristics: characteristics,
          },
        })
      );
    } else if (mode === "edit") {
      dispatch(
        updateBikeThunkV2({
          idBike: selectedBike?.id,
          bikeData: {
            ...selectedBike,
            basicInformation,
            rate,
            directory: {
              brandId: brandId,
              categoryId: categoryId,
            },
            metricsBike: {
              quantityBikes: quantityBikes,
            },
            characteristics: characteristics,
          },
        })
      );
    }
    onClose();
  };

  const handleDeleteBike = () => {
    dispatch(
      deleteBikeThunk({
        collectionName: "bikes",
        documentId: selectedRentalPoint.id,
        bikeId: selectedBike.id,
      })
    ).then(() => {
      onClose();
    });
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "8px",
          paddingBottom: "72px",
        }}
      >
        <ContentBox position="start" isLoading={isLoadingBike}>
          <WidgetBikeBasicInformation
            mode={mode}
            basicInformation={basicInformation}
            setBasicInformation={setBasicInformation}
          />
        </ContentBox>
        {mode !== "new" && (
          <ContentBox isLoading={isLoadingBike}>
            <WidgetPhotosBike mode={mode} selectedBike={selectedBike} />
          </ContentBox>
        )}

        <ContentBox isLoading={isLoadingBike}>
          <WidgetBrandCategory
            mode={mode}
            brands={brands}
            categories={categories}
            initialSelectedBrandId={brandId}
            initialSelectedCategoryId={categoryId}
            setBrandId={setBrandId}
            setCategoryId={setCategoryId}
          />
        </ContentBox>

        <ContentBox isLoading={isLoadingBike}>
          <WidgetQuntityBike
            mode={mode}
            metricsBike={metricsBike}
            setQuantityBikes={setQuantityBikes}
          />
        </ContentBox>

        <ContentBox isLoading={isLoadingBike}>
          <WidgetBikeRate mode={mode} rate={rate} setRate={setRate} />
        </ContentBox>

        <ContentBox>
          <WidgetBikeCharacteristicsList
            mode={mode}
            characteristics={characteristics}
          />
        </ContentBox>
      </div>
      <ButtonsBar>
        <div
          style={{
            width: "100%",
            display: "grid",
            // ...(mode === "edit" && { gridTemplateColumns: "repeat(3, 1fr)" }),
            // ...(mode === "new" && { gridTemplateColumns: "repeat(2, 1fr)" }),
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "16px",
          }}
        >
          <Button
            type="small"
            label="Cancel"
            active={true}
            onClick={onClose}
            color="--color-black"
          />

          {/* {mode === "edit" && (
            <Button
              type="small"
              label="Delete bike"
              active={true}
              onClick={handleDeleteBike}
              color="--color-alarm"
              allert={
                <div
                  style={{
                    padding: "48px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "16px",
                    textAlign: "center",
                  }}
                >
                  <h1>Are you sure you want to delete the current rate?</h1>
                  <p className="p-light">
                    This action will reset all entered data.
                  </p>
                </div>
              }
            />
          )} */}

          <Button
            type="small"
            label={mode === "new" ? "Create new bike" : "Save changes"}
            active={isChanged}
            onClick={() => handleAddBike()}
          />
        </div>
      </ButtonsBar>
    </>
  );
};

export default WindowBikeDetail;
