import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchData } from "../../services/firebase/crudFirebaseStorage";

export const fetchCategoryData = createAsyncThunk(
  "category/fetchCategoryData/front",
  async () => {
    try {
      // Используем setTimeout для создания задержки в 1 секунду
      await new Promise((resolve) => setTimeout(resolve, 1000));

      const categoryData = await fetchData("category");
      console.log("categoryData ✅", categoryData);
      return categoryData;
    } catch (error) {
      throw new Error(
        `Ошибка при получении данных categoryData: ${error.message}`
      );
    }
  }
);
