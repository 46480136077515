import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { emailSignUpThunk } from "../../../../slice/general/auth/signUp/emailSignUpThunk";
import InputV2 from "../../../UIElements/InputV2";
import Checkbox from "../../../UIElements/Checkbox";
import { Link } from "react-router-dom";

export const EmailSignUpV2 = () => {
  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [privacyPolicy, setPrivacyPolicy] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleEmailSignUp = (event) => {
    event.preventDefault();

    dispatch(emailSignUpThunk({ email, password, userName }))
      .unwrap()
      .then(({ user, addedUserDataId }) => {
        navigate("/profile");
      })
      .catch((error) => {
        console.error("Error during registration: ", error.errorMessage);
      });
  };

  const handleCheckboxChange = () => {
    setPrivacyPolicy(!privacyPolicy);
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flex: "1",
      }}
    >
      <form
        autoComplete="off"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: "24px",
          height: "100%",
        }}
        onSubmit={handleEmailSignUp}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
          }}
        >
          <InputV2
            type="text"
            label="Name"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          />

          <InputV2
            type="email"
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <InputV2
            type="password"
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "8px",
            }}
          >
            <Checkbox
              // label="Agree to terms"
              checked={privacyPolicy}
              onChange={handleCheckboxChange}
              id="termsAgreement"
            />

            <p className="p-mini">
              By creating an account you agree with our &nbsp;
              <Link to="/terms-of-service">Terms of Service</Link>
              ,&nbsp;
              <Link to="/privacy-policy">Privacy Policy</Link>
              ,&nbsp; and our default Notification Settings.
            </p>
          </div>

          <button
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "16px",
              width: "100%",
              padding: "8px 16px",
              borderRadius: "80px",
              border: "none",
              fontSize: "1em",
              fontWeight: "500",
              height: "56px",
              backgroundColor: privacyPolicy
                ? "var(--color-primary)"
                : "var(--color-gray-400)",
              color: "var(--color-white)",
              cursor: privacyPolicy ? "pointer" : "not-allowed",
            }}
            disabled={!privacyPolicy}
            type="submit"
          >
            Create Account
          </button>

          <p
            style={{
              textAlign: "center",
            }}
            className="p-mini"
          >
            Already have an account? &nbsp;
            <Link to="/login?page=signIn">Sign In</Link>
          </p>
        </div>
      </form>
    </div>
  );
};
