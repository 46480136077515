import React from "react";
import { Link } from "react-router-dom";

const ContactItem = ({ title, linkName, link, icon }) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "32px",
        boxSizing: "border-box",
        backgroundColor: "var(--color-white)",
        borderRadius: "24px",
        gap: "16px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          boxSizing: "border-box",
        }}
      >
        <h5>{title}</h5>
        <Link to={link}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <p className="p-light">{linkName}</p>
          </div>
        </Link>
      </div>

      <div
        className="contactItem-icon"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "56px",
          aspectRatio: "1/1",
          backgroundColor: "var(--color-gray-100)",
          boxSizing: "border-box",
          borderRadius: "80px",
        }}
      >
        {icon}
      </div>
    </div>
  );
};

export default ContactItem;
