import React from "react";

const Button = ({ onClick, children, type }) => {
    let styleName = 'button-default';
    switch (type) {
        case 'alarm':
            styleName = 'button-alarm';
            break;
        case 'warning':
            styleName = 'button-warning';
            break;
        case 'success':
            styleName = 'button-success';
            break;
        case 'disable':
            styleName = 'button-disable';
            break;
        default:
            styleName = 'button-default';
    }

    return (
        <button onClick={onClick} className={styleName}>{children}</button>
    );
};

export default Button;