import React, { useState } from "react";
import InputText from "../../Private/Login/Profile/WidgetsProfile/Inputs/InputText";
import WidgetSignIn from "../../Private/Login/Auth/WidgetSignIn";
import WidgetSignUp from "../../Private/Login/Auth/WidgetSignUp";
import SegmentedControls from "../../Private/Login/Profile/WidgetsProfile/SegmentedControls/SegmentedControls";
import ContentBoxPublic from "../PublickWidgets/ContentBox/ContentBoxPublic";
import { useSelector } from "react-redux";
import PopupWindow from "../PublickWidgets/Windows/PopupWindow";
import { setError } from "../../../slice/private/directory/privateDirectorySlice";
import WIgetError from "../UniversalWidgets/WigetError";

const WindowLogIn = ({ onClose }) => {
  const [logiType, setLoginType] = useState("signIn");
  const [errorInform, setErrorInform] = useState(false);

  const { currentUser, isLoading, error } = useSelector(
    (state) => state.userSliceV2
  );

  const handleErrorInform = () => {
    setErrorInform(true);
  };

  const constHandleClose = () => {
    setErrorInform(false);
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <ContentBoxPublic>
          <SegmentedControls
            options={[
              {
                title: "signIn",
                description:
                  "Please specify your gender for accurate addressing in our notifications and messages. Your data will remain confidential and secure.",
              },
              {
                title: "signUp",
                description:
                  "Please specify your gender for accurate addressing in our notifications and messages.",
              },
            ]}
            label={logiType && logiType === "signIn" ? "Signin" : "Signup"}
            selected={setLoginType}
            onSelect={logiType}
          />
        </ContentBoxPublic>

        <ContentBoxPublic>
          {logiType && logiType === "signIn" ? (
            <WidgetSignIn onClose={onClose} />
          ) : (
            <WidgetSignUp onClose={onClose} />
          )}
        </ContentBoxPublic>
      </div>
      <PopupWindow
        isOpen={error}
        onClose={constHandleClose}
        title=""
      ></PopupWindow>

      <WIgetError
        error={error}
        onClose={() => setError(null)}
        // img='https://firebasestorage.googleapis.com/v0/b/testbikestripster.appspot.com/o/imgStatic%2FErr9.jpg?alt=media&token=ac159c03-413d-4221-a6e5-b72165fdb1cc&_gl=1*1xy0iho*_ga*NzE3MzY1ODU1LjE2ODQ3NTEzMTQ.*_ga_CW55HF8NVT*MTY5NzQzODQ5OC40LjEuMTY5NzQ0MDc1Mi41OC4wLjA.'
      />
    </>
  );
};

export default WindowLogIn;
