import { createAsyncThunk } from "@reduxjs/toolkit";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../../../services/firebase/authFirebase";
import interpretAuthErrorV2 from "../../../../../functions/interpretAuthErrorV2";
import { isDataStale } from "./utils";

export const fetchCitiesDataThunkV4 = createAsyncThunk(
  "catalog/fetchCitiesDataThunkV4",
  async ({ countryId }, { getState, rejectWithValue }) => {
    const state = getState().catalogSliceV4;
    const currentTimestamp = Date.now();
    let shouldFetch = false;

    console.log(
      `Проверка необходимости обновления данных о городах в стране с id ${countryId}...`
    );
    const country = state.countries.find((country) => country.id === countryId);

    if (!country || !country.citiesData || country.citiesData.length === 0) {
      console.log(
        `Данные городов страны с id ${countryId} отсутствуют, выполняем запрос.`
      );
      shouldFetch = true;
    } else {
      shouldFetch = !country.lastUpdated || isDataStale(country.lastUpdated);
      if (shouldFetch) {
        console.log(
          `Данные городов страны с id ${countryId} устарели, выполняем запрос.`
        );
      }
    }

    if (!shouldFetch) {
      console.log("Данные о городах актуальны, запрос не выполняется.");
      return { fromIndexedDB: true };
    }

    try {
      console.log(`Запрос данных о городах в стране с id ${countryId}...`);
      const collectionRef = collection(db, "catalog", countryId, "cities");
      const snapshot = await getDocs(collectionRef);
      const data = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        lastUpdated: currentTimestamp,
      }));
      console.log("Данные о городах успешно получены из Firestore:", data);
      return { data };
    } catch (error) {
      console.error(
        "Ошибка при получении данных о городах из Firestore:",
        error
      );
      const errorMessage = interpretAuthErrorV2(error.code);
      return rejectWithValue({ errorMessage });
    }
  }
);
