import React, { useEffect, useState } from "react";
import SegmentedControls from "../WidgetsProfile/SegmentedControls/SegmentedControls";
import Button from "../WidgetsProfile/Buttons/Button";
import Textarea from "../WidgetsProfile/Textarea/Textarea";
import { useDispatch } from "react-redux";
import { updateUserModerationThunkV2 } from "../../../../../reducersToolkit/thunk/user/updateUserModerationThunkV2";

const WidgetUserModeration = ({ selectedUser, onClose, mode }) => {
  const dispatch = useDispatch();

  const [commentModeration, setCommentModeration] = useState("");
  const [userModerationStatus, setUserModerationStatus] = useState(null);

  useEffect(() => {
    setCommentModeration("");
  }, [userModerationStatus]);

  const handleUpdateUserStatus = (status) => {
    let comment = commentModeration;
    if (status === "approved") {
      comment =
        "Congratulations! You have successfully passed the verification, and your account is now accessible to all our users. This is fantastic news because now you can start receiving new orders and expand your customer base. Expect notifications about new orders soon. Please try to respond to them promptly to ensure high-quality service and customer satisfaction. Your responsiveness and attentiveness will help you gain the trust and loyalty of users. We are happy to welcome you to our friendly team and look forward to a long-term and mutually beneficial partnership. Our users are always looking for reliable and responsible partners, and we are confident that you can become one of the best. If you have any questions or need assistance, our support team is always ready to help. We are here to make your cooperation with us as convenient and productive as possible. Good luck and great orders! We believe in your success and are excited to see your achievements with us. Onward to new heights and greater opportunities!";
    }

    const { moderation, ...newUserData } = selectedUser;
    const collectionName = "users";
    const newData = {
      ...newUserData,
      moderation: {
        status: status,
        historyChanges: [
          ...(selectedUser?.moderation?.historyChanges || []),
          {
            userId: selectedUser.id,
            timestamp: Date.now(),
            comment: comment,
          },
        ],
      },
    };

    const id = selectedUser.id;
    dispatch(
      updateUserModerationThunkV2({
        collectionName,
        id,
        newData,
      })
    );

    onClose();
  };

  return (
    <>
      <SegmentedControls
        options={
          selectedUser?.moderation?.status === "moderation"
            ? [
                {
                  title: "accept",
                  description: (
                    <span>
                      By confirming the moderation of the rental point, you make
                      it available to all users.
                    </span>
                  ),
                },
                {
                  title: "reject",
                  description:
                    "By rejecting the moderation, the rental point will not be available to users.",
                },
                {
                  title: "bann",
                  description:
                    "By rejecting the moderation, the rental point will not be available to users.",
                },
              ].filter(Boolean)
            : selectedUser?.moderation?.status === "approved"
            ? [
                {
                  title: "reject",
                  description:
                    "By rejecting the moderation, the rental point will not be available to users.",
                },
                {
                  title: "bann",
                  description:
                    "By rejecting the moderation, the rental point will not be available to users.",
                },
              ].filter(Boolean)
            : selectedUser?.moderation?.status === "banned" &&
              [
                {
                  title: "moderation",
                  description:
                    "By rejecting the moderation, the rental point will not be available to users.",
                },
                {
                  title: "accept",
                  description: (
                    <span>
                      By confirming the moderation of the rental point, you make
                      it available to all users.
                    </span>
                  ),
                },
              ].filter(Boolean)
        }
        onSelect={
          selectedUser?.moderation?.status === "moderation"
            ? "accept"
            : selectedUser?.moderation?.status === "approved"
            ? "reject"
            : selectedUser?.moderation?.status === "banned" && "moderation"
        }
        label="Rental points"
        selected={setUserModerationStatus}
      />

      {userModerationStatus && (
        <>
          {userModerationStatus === "accept" && (
            <Button
              label="Accept"
              color="--color-success"
              active={true}
              onClick={() => handleUpdateUserStatus("approved")}
              allert={
                <div
                  style={{
                    padding: "24px",
                    boxSizing: "border-box",
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "16px",
                  }}
                >
                  <h1>
                    After approval of this rental point, users will be able to
                    fulfill orders.
                  </h1>
                  <p className="p-light" style={{ width: "70%" }}>
                    You can always change the status of the rental point in case
                    of violation of any conditions or customer complaints.
                  </p>
                </div>
              }
            />
          )}

          {userModerationStatus === "reject" && (
            <>
              <Textarea
                label="Rejection reason"
                maxLength={5000}
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                onChange={(event) => setCommentModeration(event.target.value)}
              />

              <Button
                label="Reject"
                color="--color-warning"
                active={commentModeration}
                onClick={() => handleUpdateUserStatus("moderation")}
                allert={
                  <div
                    style={{
                      padding: "24px",
                      boxSizing: "border-box",
                      textAlign: "center",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "16px",
                    }}
                  >
                    <h1>
                      Do you really want to reject the successful passage of the
                      mogerazzi rental point with the following motivation?
                    </h1>
                    <p className="p-light" style={{ width: "70%" }}>
                      Motivation: &nbsp;{commentModeration}
                    </p>
                  </div>
                }
              />
            </>
          )}

          {userModerationStatus === "bann" && (
            <>
              <Textarea
                label="Rejection reason"
                maxLength={5000}
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                onChange={(event) => setCommentModeration(event.target.value)}
              />

              <Button
                label="Bann"
                color="--color-alarm"
                active={commentModeration}
                onClick={() => handleUpdateUserStatus("banned")}
                allert={
                  <div
                    style={{
                      padding: "24px",
                      boxSizing: "border-box",
                      textAlign: "center",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "16px",
                    }}
                  >
                    <h1>
                      Do you really want to move the rental point to "bann"
                      status?
                    </h1>
                    <p className="p-light" style={{ width: "70%" }}>
                      Motivation: &nbsp;{commentModeration}
                    </p>
                  </div>
                }
              />
            </>
          )}

          {userModerationStatus === "moderation" && (
            <>
              <Textarea
                label="Rejection reason"
                maxLength={5000}
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                onChange={(event) => setCommentModeration(event.target.value)}
              />
              <Button
                label="Moderation"
                color="--color-success"
                active={commentModeration}
                onClick={() => handleUpdateUserStatus("moderation")}
                allert={
                  <div
                    style={{
                      padding: "24px",
                      boxSizing: "border-box",
                      textAlign: "center",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "16px",
                    }}
                  >
                    <h1>
                      Do you really want to move the rental point from the
                      "banned" status to the "Moderation" status?
                    </h1>
                    <p className="p-light" style={{ width: "70%" }}>
                      Motivation: &nbsp;{commentModeration}
                    </p>
                  </div>
                }
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default WidgetUserModeration;
