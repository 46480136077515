import { createAsyncThunk } from "@reduxjs/toolkit";

export const filterParamsThunk = createAsyncThunk(
  "filterParamsThunk/front",
  async (_, { getState }) => {
    const { bikeData, brandData, categoryData } = getState().stateFrontGUISlice;

    const brandsInBikes = findBrandsInBikes(bikeData, brandData);
    console.log("filterParamsThunk-brandsInBikes:", brandsInBikes);

    const categoriesInBikes = findCategoryInBikes(bikeData, categoryData);
    console.log("filterParamsThunk-categoriesInBikes:", categoriesInBikes);

    // Make sure to return the uniquePrices here
    return { brandsInBikes, categoriesInBikes };
  }
);

const findCategoryInBikes = (bikeData, categoryData) => {
  // Создаем массив всех categoryes_id из массива bikes

  const categoryIds = bikeData.map((bike) => bike.categoryes_id);

  // Создаем Set для получения уникальных categoryes_id
  const uniqueCategoryIds = new Set(categoryIds);
  // Преобразуем Set в массив уникальных categoryes_id
  const uniqueCategoryIdsArray = Array.from(uniqueCategoryIds);

  // Фильтруем массив categoryData, чтобы оставить только те категории, которые есть в уникальных categoryes_id
  const filteredCategory = categoryData.filter((category) =>
    uniqueCategoryIdsArray.includes(category.id)
  );

  return filteredCategory;
};

const findBrandsInBikes = (bikeData, brandData) => {
  // Создаем массив всех brand_id из массива bikes
  const brandIds = bikeData.map((bike) => bike.brand_id).flat();
  // Создаем Set для получения уникальных brand_id
  const uniqueBrandIds = new Set(brandIds);

  // Преобразуем Set в массив уникальных brand_id
  const uniqueBrandIdsArray = Array.from(uniqueBrandIds);
  // Фильтруем массив brands, чтобы оставить только те бренды, которые есть в уникальных brand_id
  const filteredBrands = brandData.filter((brand) =>
    uniqueBrandIdsArray.includes(brand.id)
  );

  return filteredBrands;
};
