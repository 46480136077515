import { useEffect, useState } from "react";
import { dateFormat } from "../../../functions/convertDate";
import { Link } from "react-router-dom";

const WidgetPopularСomment = ({ commentsData, rentalPoint }) => {
  console.log("PopularСomment-commentsData", commentsData);

  const [objectWithMostComments, setObjectWithMostComments] = useState(null);

  useEffect(() => {
    if (Array.isArray(commentsData) && commentsData.length > 0) {
      const objectWithMostComments = commentsData.reduce((prev, current) => {
        return (prev?.comments?.length || 0) > (current?.comments?.length || 0)
          ? prev
          : current;
      });
      setObjectWithMostComments(objectWithMostComments);
    }
  }, [commentsData]);
  console.log(
    "PopularСomment-commentsData-objectWithMostComments",
    objectWithMostComments
  );

  const getRatingClass = (rating, ratingValue) => {
    // const ratingValue = selectedRating || hoveredRating;
    if (rating <= ratingValue) {
      if (ratingValue >= 4) {
        return "active-4-5";
      } else if (ratingValue === 3) {
        return "active-3";
      } else {
        return "active-1-2";
      }
    }
    return ""; // Вернуть пустую строку, если условия не выполняются
  };

  const RangeRating = ({ currntRange }) => {
    return (
      <div
        style={{
          display: "flex",
        }}
      >
        {[1, 2, 3, 4, 5].map((rating) => (
          <span
            key={rating}
            className={`material-symbols-outlined rating-star ${getRatingClass(
              rating,
              currntRange
            )}`}
          >
            grade
          </span>
        ))}
      </div>
    );
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          height: "100%",
          // padding: "16px",
          // backgroundColor: "var(--color-gray-100)",
          borderRadius: "16px",
          backdropFilter: "blur(8px)",
          gap: "8px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <p className="p-light">
            {dateFormat(
              objectWithMostComments?.masterComment?.date?.seconds * 1000
            )}
          </p>
          <RangeRating
            currntRange={objectWithMostComments?.masterComment?.grade}
          />
          {/* {objectWithMostComments?.comments.length} */}
        </div>

        <p className="p-light">
          {objectWithMostComments?.masterComment?.comment}
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {rentalPoint?.title[0] && (
            <Link
              to={`/rentalPoint/${rentalPoint?.title[0].replace(/ /g, "-")}/${
                rentalPoint?.id
              }`}
            >
              <button
                style={{
                  background: "var(--color-success)",
                  color: "var(--color-white)",
                  height: "32px",
                  aspectRatio: "1/1",
                  border: "0",
                  borderRadius: "80px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",

                  // padding: '0 16px'
                }}
              >
                <span className="material-symbols-outlined">add</span>
              </button>
            </Link>
          )}
          {rentalPoint?.title[0] && (
            <Link
              to={`/rentalPoint/${rentalPoint?.title[0].replace(/ /g, "-")}/${
                rentalPoint?.id
              }`}
            >
              <button
                style={{
                  background: "var(--color-gray-600)",
                  color: "var(--color-white)",
                  height: "32px",
                  border: "0",
                  borderRadius: "80px",
                  padding: "0 16px",
                }}
              >
                Comments: {objectWithMostComments?.comments.length}
              </button>
            </Link>
          )}
        </div>
      </div>
    </>
  );
};

export default WidgetPopularСomment;
