import React from "react";
import WigetCollapseComponent from "../UniversalWidgets/WigetCollapseComponent";
import { useSelector } from "react-redux";
import Preloader from "../../UIElements/Preloader";
import objectIsEmpty from "../../../functions/objectIsEmpty";

const BikeInfo = () => {
  const currentBike = useSelector(
    (state) => state.stateFrontGUISlice.currentBike
  );

  const isLoading = useSelector((state) => state.stateFrontGUISlice.isLoading);

  return (
    <div className="wigetBikes-content-grid-left-content">
      {isLoading ? (
        <div className="wigetProdictCard-in-box">
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Preloader />
          </div>
        </div>
      ) : (
        <>
          {!objectIsEmpty(currentBike) &&
            currentBike?.description?.length > 0 && (
              <div className="wigetProdictCard-in-box">
                <h5>Description</h5>
                <p>{currentBike?.description}</p>
              </div>
            )}

          {!objectIsEmpty(currentBike) &&
            currentBike?.characteristics.lebgth > 0 && (
              <>
                <div className="wigetProdictCard-in-box">
                  <h5>Characteristics</h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Fusce eleifend ipsum vitae nunc gravida facilisis.
                    Pellentesque finibus, justo id tincidunt accumsan, mauris
                    elit consequat nulla, vel tempus mi lorem vel ipsum. Integer
                    id tincidunt sapien. Cras faucibus euismod massa eget
                    interdum. Sed commodo, ante eu tempor condimentum, elit dui
                    lacinia nisi, in pulvinar enim enim ut orci. Suspendisse
                    potenti.
                  </p>
                </div>

                <div>
                  {!objectIsEmpty(currentBike) &&
                    currentBike?.bike?.characteristics.map((item, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                          minHeight: "48px",
                        }}
                      >
                        <WigetCollapseComponent
                          item={
                            item.description.length > 0 ? (
                              <div
                                style={{
                                  padding: "16px 24px",
                                }}
                              >
                                {item.description}
                              </div>
                            ) : null
                          }
                          key={index}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <p>{item.title}</p>
                            <p className="wigetProdictCard-characteristics">
                              {item.characteristics}
                            </p>
                          </div>
                        </WigetCollapseComponent>
                      </div>
                    ))}
                </div>
              </>
            )}
        </>
      )}
    </div>
  );
};

export default BikeInfo;
