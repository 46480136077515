import WigetCollapseComponent from "../../../../../Public/UniversalWidgets/WigetCollapseComponent";

const OrderAdditionalServicesList = ({ orderDetail, title, description }) => {

    const additionalServices = orderDetail?.order_info?.additionalServices

    const atLeastOneNotEmpty = additionalServices;
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '0 16px',
            boxSizing: 'border-box',
        }}>

            <div style={{
                backgroundColor: 'var(--color-white)',
                boxSizing: 'border-box',
                borderRadius: '8px',
                overflow: 'hidden'
            }}>

                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    height: '48px',
                    padding: '0 16px',
                    // ...(atLeastOneNotEmpty && {
                    //     borderBottom: '1px var(--color-gray-200) solid',
                    // })

                }}>

                    <p style={{
                        textTransform: 'uppercase',
                    }}
                        className='p-light'
                    >
                        {title}
                    </p>
                    {/* 
                        <button
                            onClick={() => handleOpenPopup(`popu`)}
                        >
                            {items.length > 0 ? 'Edit' : 'Add'}
                        </button> */}

                </div>
                {
                    Array.isArray(additionalServices) && additionalServices.map((itemOrder, index) => (
                        <div key={index}>

                            <WigetCollapseComponent
                                item={
                                    <div>

                                        {/* <div className="private_ordersList_itemPrice private_ordersList_itemPrice_borderBottom">
                                            <p>Title</p>
                                            <p style={{
                                                fontWeight: '600'
                                            }}>
                                                {itemOrder?.title}
                                            </p>
                                        </div> */}

                                        <div className="private_ordersList_itemPrice private_ordersList_itemPrice_borderBottom">
                                            <p>Characteristics</p>
                                            <p style={{
                                                fontWeight: '600'
                                            }}>
                                                {itemOrder?.characteristics}
                                            </p>
                                        </div>

                                        <div className="private_ordersList_itemPrice private_ordersList_itemPrice_borderBottom">
                                            <p>Price</p>
                                            <p style={{
                                                fontWeight: '600'
                                            }}>
                                                {itemOrder?.price?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                                            </p>
                                        </div>

                                        {/* <div className="private_ordersList_itemPrice private_ordersList_itemPrice_borderBottom">
                                            <p>Description</p>
                                            <p>{itemOrder?.description}</p>
                                        </div> */}

                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '8px',
                                            padding: '8px 16px'
                                        }}>
                                            <p>
                                                Comment
                                            </p>
                                            <p style={{
                                                width: '70%'
                                            }}
                                                className="p-light">
                                                {itemOrder?.description}
                                            </p>
                                        </div>

                                    </div>
                                }
                            >
                                {itemOrder?.title ? itemOrder.title.charAt(0).toUpperCase() + itemOrder.title.slice(1).toLowerCase() : ''}
                            </WigetCollapseComponent>
                        </div>
                    ))}
            </div>

            {description && (
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    padding: '8px 16px'
                }}>
                    <p className='p-light'>
                        {description ? description.charAt(0).toUpperCase() + description.slice(1).toLowerCase() : ''}
                    </p>
                </div>
            )}

        </div>
    );
}

export default OrderAdditionalServicesList;
