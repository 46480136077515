import React, { useEffect, useState } from "react";
import PopupDirrectory from "../WidgetsProfile/Popups/PopupDirrectory";
import WindowCommentsListModerator from "./WindowCommentsListModerator";
import TitleBlockSection from "../WidgetsProfile/Titles/TitleBlockSection";
import AvatarUser from "../WidgetsProfile/Avatars/AvatarUser";
import priorityFile from "../../../../../functions/priorityFile";

const WidgetModeratorMessages = ({ selectedUser, users }) => {
  const [windowCommentsListModerator, setWindowCommentsListModerator] =
    useState(false);

  const [dateMessage, setDateMessage] = useState(0);
  const [userMessageId, setUserMessageId] = useState(null);
  const [message, setMessage] = useState("");
  const [commentAuthor, setCommentAuthor] = useState(null);

  const handleClose = () => {
    setWindowCommentsListModerator(false);
  };
  const openWindowCommentsListModerator = () => {
    setWindowCommentsListModerator(true);
  };

  useEffect(() => {
    const sortedMessages = [
      ...(selectedUser?.moderation?.historyChanges || []),
    ].sort((a, b) => b.timestamp - a.timestamp);

    const date = new Date(parseInt(sortedMessages[0]?.timestamp, 10));
    const formattedDate = date.toLocaleDateString("en-US");

    const userIdToFind = sortedMessages[0]?.userId;
    const foundUser = users.find((user) => user.id === userIdToFind);

    setCommentAuthor(foundUser);
    setDateMessage(formattedDate);
    setUserMessageId(sortedMessages[0]?.userId || null);
    setMessage(sortedMessages[0]?.comment || null);
  }, [selectedUser, users]);

  return (
    <>
      <TitleBlockSection
        title="Messages from the moderator"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
      />

      {message && userMessageId && dateMessage && (
        <div
          style={{
            width: "100%",
            backgroundColor: "var(--color-gray-100)",
            padding: "16px",
            boxSizing: "border-box",
            borderRadius: "16px",
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "16px",
          }}
          onClick={openWindowCommentsListModerator}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "4px",
            }}
          >
            <p className="p-light">{dateMessage}</p>
            <p className="p-light">{message}</p>
          </div>

          {commentAuthor?.user && (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "8px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <AvatarUser
                  size={24}
                  url={
                    priorityFile(commentAuthor?.user?.files?.avatarFiles)?.url
                  }
                />
                <p className="p-light">{commentAuthor?.user?.ownerName}</p>
              </div>
              <p className="p-light">{commentAuthor?.userContacts?.email}</p>
            </div>
          )}
        </div>
      )}

      <PopupDirrectory
        isOpen={windowCommentsListModerator}
        onClose={handleClose}
        title="All messages from moderator"
      >
        <WindowCommentsListModerator />
      </PopupDirrectory>
    </>
  );
};

export default WidgetModeratorMessages;
