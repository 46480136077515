import React, { useState, useCallback } from "react";
import { structureDb } from "../../services/firebase/structureDb";
import {
  deleteFiles,
  deleteData,
  fetchDocumentById,
  updateDocumentById,
  fetchData,
} from "../../services/firebase/crudFirebaseStorage";
import { useAlert } from "../UIElements/Alerts";
import Loading from "../UIElements/Loading";
import Button from "../UIElements/Button";

// WigetButtonDelete служит для удаления документа из определенной коллекции в Firestore (Firebase), включая удаление связанных файлов в Firebase Storage, а также обновление ссылок на удаляемый документ в других коллекциях.

// Принимает следующие пропсы:
// collectionName - название коллекции в Firebase, из которой нужно удалить элемент
// documentId - идентификатор документа, который нужно удалить
// title - заголовок кнопки

const WigetButtonDelete = ({
  collectionName,
  documentId,
  title,
  icon,
  freeze,
}) => {
  // Используем хук для отображения уведомлений
  const { showAlert } = useAlert();

  // Используем хук состояния для отслеживания состояния загрузки
  const [loading, setLoading] = useState(false);

  // Обработчик удаления записи в справочнике
  // Используем хук useCallback для оптимизации (создание функции будет происходить только при изменении зависимостей)
  const handleDeleteDirectory = useCallback(
    async (directoryId) => {
      try {
        setLoading(true); // Начинаем загрузку

        // Получаем данные записи из Firebase по идентификатору
        const directoryData = await fetchDocumentById(
          collectionName,
          directoryId
        );

        // Если данные успешно получены
        if (directoryData) {
          // Определяем поля, которые содержат файлы (начинаются с "file_")
          const fileFields = Object.keys(directoryData).filter((key) =>
            key.startsWith("file_")
          );

          // Если такие поля найдены
          if (fileFields.length > 0) {
            // Обходим каждое поле
            for (const field of fileFields) {
              const fileObjects = directoryData[field];

              // Если в поле есть файлы
              if (fileObjects) {
                // Получаем массив идентификаторов файлов
                const fileIdsArray = fileObjects.map((obj) => obj.id);

                // Удаляем файлы из Firebase Storage
                await deleteFiles(
                  collectionName,
                  [directoryId],
                  field,
                  fileIdsArray
                );
              }
            }
          }

          // Обходим все коллекции в структуре базы данных
          for (const collection in structureDb) {
            // Обходим все поля в каждой коллекции
            for (const field in structureDb[collection]) {
              // Проверяем, является ли поле ссылкой на удаляемую запись (начинается с 'id_' + collectionName)
              if (field === `${collectionName}_id`) {
                // Получаем все документы из текущей коллекции
                const docsData = await fetchData(collection);

                // Находим документы, которые ссылаются на удаляемую запись
                const docsToUpdate = docsData.filter(
                  (doc) => doc[field] && doc[field].includes(directoryId)
                );

                // Обновляем каждый найденный документ
                for (const docData of docsToUpdate) {
                  // Удаляем идентификатор удаляемого документа из массива ссылок
                  const updatedArray = docData[field].filter(
                    (id) => id !== directoryId
                  );

                  // Обновляем документ в Firebase с новым массивом ссылок
                  await updateDocumentById(
                    { [field]: updatedArray },
                    collection,
                    docData.id
                  );
                }
              }
            }
          }

          // Наконец, удаляем саму запись из указанной коллекции
          await deleteData(collectionName, directoryId);
        }

        // Заканчиваем загрузку
        setLoading(false);
      } catch (error) {
        // В случае ошибки выводим ее в консоль и заканчиваем загрузку
        console.error(
          `Ошибка удаления записи справочника ${collectionName} ${directoryId}:`,
          error
        );
        setLoading(false);
      }
    },
    [collectionName]
  );

  // Обработчик клика по кнопке
  const handleClick = () => {
    // Показываем уведомление с подтверждением удаления записи
    showAlert("Вы уверены, что хотите удалить эту запись?", () =>
      handleDeleteDirectory(documentId)
    );
  };

  // Возвращаем JSX
  return (
    <>
      {loading ? (
        <Loading title="Ожидайте завершения удаления" />
      ) : icon ? (
        <div
          className={freeze ? `icon44freeze` : "icon44"}
          onClick={handleClick}
        >
          {icon}
        </div>
      ) : (
        <Button onClick={handleClick}>{title}</Button>
      )}
    </>
  );
};

export default WigetButtonDelete;
