import React from "react";
import PhotosGallery from "../../Private/Login/Profile/WidgetsProfile/PhotosGallery/PhotosGallery";
import PriorityImage from "../../Private/Login/Profile/WidgetsProfile/PhotosGallery/PriorityImage";
import Table from "../../Private/Login/Profile/WidgetsProfile/Table/Table";
import WidgetTimetable from "./WidgetTimetable";

const WidgetPreviewRentalPoint = ({
  cityPhotos = [],
  countryPhotos = [],
  logoRentalPoint = [],
  titleRentalPoint = "No title",
  photoFiles = [],
  isLoading = true,
  descriptionRentalPoint = "No description",
  quantityBikes = 0,
  countryRentalPoint = "No country",
  cityRentalPoint = "No city",
  timetable = [],
}) => {
  return (
    <>
      <div
        style={{
          width: "100%",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          backgroundColor: "var(--color-white)",
          borderRadius: "24px",
          padding: "8px",
        }}
      >
        <div
          style={{
            width: "100%",
            aspectRatio: "16/9",
            position: "relative",
          }}
        >
          <PhotosGallery
            photos={cityPhotos}
            style={{
              width: "100%",
              aspectRatio: "16/9",
            }}
          />
          <div
            style={{
              position: "absolute",
              left: "16px",
              bottom: "24px",
            }}
          >
            <PriorityImage
              photos={countryPhotos}
              style={{
                width: "48px",
                aspectRatio: "1/1",
                borderRadius: "100%",
              }}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "8px",
            padding: "0 16px",
          }}
        >
          <PriorityImage
            photos={logoRentalPoint}
            info={
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <p>{titleRentalPoint}</p>
                <PhotosGallery photos={photoFiles} isLoading={isLoading} />

                <p className="p-light">{descriptionRentalPoint}</p>
              </div>
            }
            style={{
              aspectRatio: "1/1",
              borderRadius: "100%",
              width: "48px",
            }}
          />

          <div>
            <div
              className="p-light"
              style={{
                color: "var(--color-black)",
                display: "-webkit-box",
                WebkitLineClamp: 1,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {titleRentalPoint}
            </div>
            <p className="p-light">Bikes: {quantityBikes}</p>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "8px",
            padding: "0 16px",
          }}
        >
          <Table
            columns={1}
            title={titleRentalPoint}
            data={[
              {
                typeCell: "column",
                title: "Description",
                parameter: <p className="p-light">{descriptionRentalPoint}</p>,
                // parameter: <PhotosGallery photos={bikePhotoFiles} />,
              },
              {
                // typeCell: "column",
                title: "countryRentalPoint",
                parameter: (
                  <p
                    style={{
                      fontWeight: "600",
                    }}
                  >
                    {countryRentalPoint}
                  </p>
                ),
                // parameter: <PhotosGallery photos={bikePhotoFiles} />,
              },
              {
                // typeCell: "column",
                title: "cityRentalPoint",
                parameter: (
                  <p
                    style={{
                      fontWeight: "600",
                    }}
                  >
                    {cityRentalPoint}
                  </p>
                ),
                // parameter: <PhotosGallery photos={bikePhotoFiles} />,
              },
              {
                // typeCell: "column",
                title: "quantityBikes",
                parameter: (
                  <p
                    style={{
                      fontWeight: "600",
                    }}
                  >
                    {quantityBikes}
                  </p>
                ),
                // parameter: <PhotosGallery photos={bikePhotoFiles} />,
              },

              {
                typeCell: "column",
                // title: "Timetable",
                parameter: (
                  <WidgetTimetable
                    timetableData={timetable}
                    isLoading={false}
                  />
                ),
                // parameter: <PhotosGallery photos={bikePhotoFiles} />,
              },
            ]}
          />
        </div>
      </div>
    </>
  );
};

export default WidgetPreviewRentalPoint;
