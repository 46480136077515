import React, { useState, useEffect } from "react";
import "firebase/compat/firestore";
import { TextareaWrite } from "../UIElements/Textarea";
import Button from "../UIElements/Button";
import Popup from "../UIElements/Popup";
import {
  fetchDocumentById,
  updateDocumentById,
} from "../../services/firebase/crudFirebaseStorage";
import Checkbox from "../UIElements/Checkbox";
import Loading from "../UIElements/Loading";
import WigetTranslator from "./WigetTranslator";

export const WigetTextareaV2 = ({
  collectionName,
  idDocument,
  fieldName,
  title,
  quantity,
  description,
  maxLength,
}) => {
  const [activePopup, setActivePopup] = useState(null);
  const [data, setData] = useState([]);
  const [markedForDelete, setMarkedForDelete] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  // const maxLength = 1000; // Максимальная длина значения

  useEffect(() => {
    setIsLoading(true);
    const fetchUserData = async () => {
      const fetchedData = await fetchDocumentById(collectionName, idDocument);

      const fieldData =
        fetchedData && fetchedData[fieldName] ? fetchedData[fieldName] : [];
      setData(Array.isArray(fieldData) ? fieldData : [fieldData]);
      setIsLoading(false);
      setIsPending(false);
    };
    fetchUserData();
  }, [collectionName, idDocument, fieldName, setData]);

  const handleChange = (e, index) => {
    const { value } = e.target;
    // Проверка длины значения

    if (value.length > maxLength) {
      console.log("Превышена максимальная длина значения");
      return;
    }

    setData((prevData) =>
      prevData.map((item, idx) => (idx === index ? value : item))
    );
    setIsChanged(true);
  };

  const handleAddItemData = () => {
    if (data.length < quantity) {
      setData((prevData) => ["", ...prevData]);
    }
    setIsPending(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await updateDocumentById({ [fieldName]: data }, collectionName, idDocument);
    setIsLoading(false);
    setIsChanged(false);
    setIsPending(false);
  };

  const handleDeleteMarked = async () => {
    const updatedData = data.filter((_, index) => !markedForDelete[index]);
    setData(updatedData);
    setMarkedForDelete({});
    setIsLoading(true);
    setIsDeleteLoading(true);
    await updateDocumentById(
      { [fieldName]: updatedData },
      collectionName,
      idDocument
    );
    setIsDeleteLoading(false);
    setIsLoading(false);
    setIsChanged(false);
  };

  const handleOpenPopup = (popupName) => {
    setActivePopup(popupName);
  };

  const handleClosePopup = () => {
    setActivePopup(null);
  };

  // Проверить, есть ли помеченные для удаления элементы
  const isAnyMarkedForDelete = () => {
    return Object.values(markedForDelete).some((value) => value);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "0 16px",
          boxSizing: "border-box",
        }}
      >
        <div
          style={{
            backgroundColor: "var(--color-white)",
            boxSizing: "border-box",
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              height: "48px",
              padding: "0 16px",
              ...(data.length > 0 && {
                borderBottom: "1px var(--color-gray-200) solid",
              }),
            }}
          >
            <p
              style={{
                textTransform: "uppercase",
              }}
              className="p-light"
            >
              {title}
            </p>

            <button onClick={() => handleOpenPopup(`popu_${fieldName}`)}>
              {data.length > 0 ? "Edit" : "Add"}
            </button>
          </div>
          {data.map((descriptoinItem, index) => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                boxSizing: "border-box",
                minHeight: "48px",
                padding: "16px 16px",
                lineHeight: "20px",
                ...(index === data.length - 1
                  ? {
                      borderBottom: "none",
                    }
                  : {
                      borderBottom: "1px var(--color-gray-200) solid",
                    }),
              }}
              key={index}
              onClick={() => handleOpenPopup(`popu_${fieldName}`)}
            >
              <div className="inform-box-content-left">{descriptoinItem}</div>
            </div>
          ))}
        </div>
      </div>

      {activePopup !== null &&
        (isLoading ? (
          <Loading title="Ожидайте завершения" />
        ) : (
          <Popup
            isOpen={true}
            onClose={handleClosePopup}
            title={title}
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eleifend ipsum vitae nunc gravida facilisis. Pellentesque finibus, justo id tincidunt accumsan, mauris elit consequat nulla, vel tempus mi lorem vel ipsum. Integer id tincidunt sapien. Cras faucibus euismod massa eget interdum. Sed commodo, ante eu tempor condimentum, elit dui lacinia nisi, in pulvinar enim enim ut orci. Suspendisse potenti."
            leftMenu={
              <>
                {/* data.length < quantity */}
                {data.length < quantity ? (
                  <Button type="success" onClick={handleAddItemData}>
                    Add text
                  </Button>
                ) : (
                  <Button type="disable">Add text</Button>
                )}
              </>
            }
            rightMenu={
              <>
                {isChanged ? (
                  <Button
                    onClick={handleSubmit}
                    disabled={isLoading}
                    children={isLoading ? "...Loading" : "Save"}
                  />
                ) : (
                  <Button type={"disable"} children={"Save"} />
                )}
                {isAnyMarkedForDelete() ? (
                  <Button
                    type="alarm"
                    onClick={handleDeleteMarked}
                    disabled={isDeleteLoading}
                  >
                    {isDeleteLoading ? "...Loading" : "Delete"}
                  </Button>
                ) : (
                  <Button type="disable">Delete</Button>
                )}
              </>
            }
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              {data.map((content, index) => (
                <div key={index}>
                  {quantity > 1 && (
                    <Checkbox
                      checked={!!markedForDelete[index]}
                      onChange={(e) =>
                        setMarkedForDelete((prev) => ({
                          ...prev,
                          [index]: e.target.checked,
                        }))
                      }
                    />
                  )}

                  <TextareaWrite
                    label={title}
                    value={content}
                    onChange={(e) => handleChange(e, index)}
                    maxLength={maxLength}
                  />

                  {description && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        padding: "8px 16px",
                      }}
                    >
                      <p className="p-light">{description}</p>
                    </div>
                  )}

                  <WigetTranslator
                    collectionName={collectionName}
                    docId={idDocument}
                    fieldName={fieldName}
                    indexToTranslate={index}
                  />
                </div>
              ))}
            </div>
          </Popup>
        ))}
    </>
  );
};
