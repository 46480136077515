import { createAsyncThunk } from "@reduxjs/toolkit";
import { subscribeToDocument } from "../../../services/firebase/crudFirestore";
import interpretAuthErrorV2 from "../../../functions/interpretAuthErrorV2";

export const fetchDirectoryThunk = createAsyncThunk(
  "private/directory/fetchDirectoryThunk",
  async (collectionName, { getState, rejectWithValue, dispatch }) => {
    try {
      return new Promise((resolve, reject) => {
        const unsubscribe = subscribeToDocument(
          collectionName,
          null,
          null,
          async (data) => {
            if (Array.isArray(data)) {
              dispatch(fetchDirectoryThunk.fulfilled(data)); // Диспатчим fulfilled с данными
            } else {
              console.error("Data is not an array:", data);
            }
          }
        );
        resolve(); // Разрешаем промис сразу, чтобы не блокировать
        return () => {
          unsubscribe();
        };
      });
    } catch (error) {
      const errorMessage = interpretAuthErrorV2(error.code);
      return rejectWithValue({ errorMessage });
    }
  }
);
