import React, { useEffect, useState } from "react";
import SearchBars from "../../../../../UIComponents/bars/searchBars/SearchBars";
import Icon44 from "../../../../../UIComponents/icons/Icon44";
import WigetProportions from "../../../../../UIComponents/proportions/WigetProportions";
import WigetButtonDelete from "../../../../../UniversalWidgets/WigetButtonDelete";
import Geocoding from "../../../../../UIComponents/maps/Geocoding";
import GoogleMaps from "../../../../../UIComponents/maps/GoogleMaps";
import { updateDocumentById } from "../../../../../../services/firebase/crudFirebaseStorage";
import WigetTimetableL from "./WigetTimetablel";
// import { WigetPhones } from "../../RentalPointsV3/WigetRentalPoints/WigetPhonesV2";
// import { WigetEmailsV2 } from "../../RentalPointsV3/WigetRentalPoints/WigetEmailsV2";
import PreviewGallery from "../../../../../UIComponents/bars/gallery/PreviewGallery";
import { WigetPriceBlock } from "../../../../../UniversalWidgets/WigetPriceBlock";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { WigetInputV2 } from "../../../../../UniversalWidgets/WigetInputV2";
import { WigetTextareaV2 } from "../../../../../UniversalWidgets/WigetTextareaV2";
import { WigetCityCheck } from "../../City/WigetBikes/WigetCityDetail";
import Loading from "../../../../../UIElements/Loading";
import { WigetCurrencyCheck } from "../../Directory/Currency/WigetCurrency/WigetCurrencyDetail";

const WigetRentalPointDetail = () => {
  const currentRentalPoint = useSelector(
    (state) => state.stateGUISlice.currentRentalPoint || null
  );
  const currentRentalPointTitle = useSelector(
    (state) => state.stateGUISlice?.currentRentalPoint?.title?.[0] || null
  );
  const currentRentalPointId = useSelector(
    (state) => state.stateGUISlice.currentRentalPoint.id || null
  );

  const documentId = currentRentalPoint?.id;

  const priorityPhoto = currentRentalPoint?.file_photo?.find(
    (photo) => photo.priority || currentRentalPoint?.file_photo?.[0]
  );

  const imageURL = priorityPhoto ? priorityPhoto.url : null;

  const [address, setAddress] = useState(null);
  const [location, setLocation] = useState(null);
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [countryShortCode, setCountryShortCode] = useState(null);

  const addressInfo = {
    address: address,
    location: {
      lat: location?.lat ? location.lat : 0,
      lng: location?.lng ? location.lng : 0,
    },
    city: city ? city : "",
    country: country ? country : "",
    countryShortCode: countryShortCode,
  };

  const [loadingComponent, setLoadingComponent] = useState(false);

  useEffect(() => {
    setLoadingComponent(true);

    const timeoutId = setTimeout(() => {
      setLoadingComponent(false);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, []);

  if (loadingComponent) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <Loading />
      </div>
    );
  }

  // Сохранение значения в коллекцию rentalpoint
  const savePointAddress = async () => {
    if (address && location.lat && location.lng) {
      await updateDocumentById(
        {
          pointAddress: addressInfo,
        },
        "rentalPoints",
        documentId
      );
      console.log("Адрес сохранен");
    } else {
      console.log("Адрес не указан");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        height: "100%",
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      <SearchBars
        title={`Detail rental point: ${
          currentRentalPointTitle
            ? currentRentalPointTitle
            : "No name rental point"
        }`}
        iconLeft={
          <Icon44
            icon={
              <></>
              // <span className="material-symbols-outlined">
              //     dock_to_right
              // </span>
            }
          />
        }
        iconRight={
          <>
            <WigetButtonDelete
              collectionName={"rentalPoints"}
              documentId={currentRentalPoint?.id}
              title="Удалить точку"
              icon={<span className="material-symbols-outlined">delete</span>}
            />
          </>
        }
      />

      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            width: "500px",
            winWidth: "500px",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            padding: "16px 0",
            backgroundColor: "var(--color-gray-100)",
          }}
          className="scrollBox"
          id="idGeocoding"
        >
          {/* <div
                        style={{
                            position: 'relative',
                            backgroundImage: `url(${imageURL ? imageURL : ''})`,
                            backgroundColor: 'var(--color-gray-200)',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            aspectRatio: '16/9',
                            width: '100%',
                            boxSizing: 'border-box',
                            padding: '16px',
                        }}
                    /> */}

          <Geocoding
            title="Country and City"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          />

          {documentId && (
            <WigetInputV2
              title="Rental point name"
              collectionName={"rentalPoints"}
              idDocument={documentId}
              fieldName="title"
              quantity={1}
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            />
          )}

          {documentId && (
            <WigetCurrencyCheck
              title="Select currency"
              collectionName="currency"
              fieldName="currency_id"
              parentCollectionName="rentalPoints"
              documentId={documentId}
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            />
          )}

          {documentId && (
            <PreviewGallery
              title="Logo rental point"
              collectionName={"rentalPoints"}
              idDocument={documentId}
              fieldName="file_logo"
              gallery={true}
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            />
          )}

          {documentId && (
            <WigetCityCheck
              title="Country and City"
              collectionName="country"
              fieldName="city_id"
              parentCollectionName="city"
              documentId={documentId}
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            />
          )}

          {/* {documentId && (
            <WigetPhonesV2
              title="Phones"
              collectionName={"rentalPoints"}
              idDocument={documentId}
              fieldName="phonesNumber"
              addressInfo={addressInfo}
              quantity={3}
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            />
          )} */}

          {/* {documentId && (
            <WigetEmailsV2
              title="Emails"
              collectionName={"rentalPoints"}
              idDocument={documentId}
              fieldName="emails"
              addressInfo={addressInfo}
              quantity={3}
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            />
          )} */}

          {documentId && (
            <WigetTimetableL
              title="TimetableL"
              collectionName={"rentalPoints"}
              idDocument={documentId}
              fieldName="timetableL"
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            />
          )}

          {documentId && (
            <WigetTextareaV2
              title="Description"
              collectionName={"rentalPoints"}
              idDocument={documentId}
              fieldName="description"
              quantity={1}
              maxLength={1000}
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            />
          )}

          {documentId && (
            <WigetPriceBlock
              title="Aditional services"
              collectionName={"rentalPoints"}
              idDocument={documentId}
              fieldName="additionalServices"
              quantity={10}
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            />
          )}

          {documentId && (
            <PreviewGallery
              title="Photo rental point"
              collectionName={"rentalPoints"}
              idDocument={documentId}
              fieldName="file_photo"
              gallery={true}
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            />
          )}

          {/* <PreviewGallery
                            title="License rental point"
                            collectionName={'rentalPoints'}
                            idDocument={documentId}
                            fieldName="file_license"
                            gallery={true}
                            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                        /> */}
        </div>

        <WigetProportions idBlock="idGeocoding" />

        <div style={{ flex: "1" }}>
          <GoogleMaps />
        </div>
      </div>
    </div>
  );
};

export default WigetRentalPointDetail;
