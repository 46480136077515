import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Input from "../../UIElements/InputV2";
import { TextareaWrite } from "../../UIElements/Textarea";
import { useParams } from "react-router-dom";
import saveDataFirebase from "../../../services/firebase/saveDataFirebase";
import WigetDateInterval from "../UniversalWidgets/WigetDateInterval";
import Preloader from "../../UIElements/Preloader";
import { setQuantityBike } from "../../../slice/stateFrontGUISlice";
import { useDispatch, useSelector } from "react-redux";
import validateEmail from "../../../functions/validateEmail";
import validatePhoneNumber from "../../../functions/validatePhoneNumber";
import InputPhoneV2 from "../../UIElements/InputPhoneV2";
import WigetSelectedPopup from "../UniversalWidgets/WigetSelectedPopup";

const cardImg = [
  "https://firebasestorage.googleapis.com/v0/b/testbikestripster.appspot.com/o/imgStatic%2Famerican.svg?alt=media&token=8062c6ab-bbe6-4be2-94ae-3a6e5051ade1&_gl=1*5l8hcm*_ga*NzE3MzY1ODU1LjE2ODQ3NTEzMTQ.*_ga_CW55HF8NVT*MTY5ODU2MjY0NS40MC4xLjE2OTg1NjQ4MjEuNjAuMC4w",

  "https://firebasestorage.googleapis.com/v0/b/testbikestripster.appspot.com/o/imgStatic%2Fvisa.svg?alt=media&token=6d360c8a-b955-4521-849d-1fd50a47e79c&_gl=1*16u9qn5*_ga*NzE3MzY1ODU1LjE2ODQ3NTEzMTQ.*_ga_CW55HF8NVT*MTY5ODU2MjY0NS40MC4xLjE2OTg1NjU3ODUuNjAuMC4w",

  "https://firebasestorage.googleapis.com/v0/b/testbikestripster.appspot.com/o/imgStatic%2FmasterCard.svg?alt=media&token=32e39b30-52d4-4244-830d-fe2e4bfd824d&_gl=1*thv9tu*_ga*NzE3MzY1ODU1LjE2ODQ3NTEzMTQ.*_ga_CW55HF8NVT*MTY5ODU2MjY0NS40MC4xLjE2OTg1NjU4NzcuNTAuMC4w",

  "https://firebasestorage.googleapis.com/v0/b/testbikestripster.appspot.com/o/imgStatic%2FunionPay.svg?alt=media&token=1089bdd2-8292-4a25-89dc-c0f6938ac4e9&_gl=1*ku9nt7*_ga*NzE3MzY1ODU1LjE2ODQ3NTEzMTQ.*_ga_CW55HF8NVT*MTY5ODU2MjY0NS40MC4xLjE2OTg1NjU5NjUuNTEuMC4w",
];

const WigetReservation = ({
  bikeCostDetails,
  currentBike,
  quantityBike,
  isOpen,
  onClose,
}) => {
  // const currentDateInterval = useSelector(
  //   (state) => state.stateFrontGUISlice.currentDateInterval
  // );

  console.log("WigetReservation-currentBike", currentBike.rentalPoint.id);
  const dispatch = useDispatch();
  const { id } = useParams();

  const [driverName, setDriverName] = useState("");
  const [driverEmail, setDriverEmail] = useState("");
  const [driverRepeatEmail, setDriverRepeatEmail] = useState("");
  const [driverPhone, setDriverPhone] = useState("");
  const [driverComment, setDriverComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [errDriverName, setErrDriverName] = useState(null);
  const [errDriverEmail, setErrDriverEmail] = useState(null);
  const [errDriverPhone, setErrDriverPhone] = useState(false);

  const [activePopup, setActivePopup] = useState(null);
  const [orderId, setOrderId] = useState(null);

  const currentUser = useSelector((state) => state.userSliceV2.currentUser);
  const role = currentUser ? currentUser.role : null;
  const isLoading = useSelector((state) => state.userSliceV2.isLoading);

  const validPhoneResult = validatePhoneNumber(driverPhone);

  useEffect(() => {
    if (!driverName) {
      setErrDriverName("Поле имя должно быть обязательно заполнено");
    } else {
      setErrDriverName(null);
    }
    if (validateEmail(driverEmail)) {
      setErrDriverEmail("Не правильно указан email");
    } else {
      setErrDriverEmail(null);
    }
    if (validPhoneResult.valid) {
      setErrDriverPhone(true);
    } else {
      setErrDriverPhone(false);
    }
  }, [driverName, driverEmail, driverPhone]);

  const { v4: uuidv4 } = require("uuid");

  const uniqueId = uuidv4();

  // Обработчик открытия попапа.
  const handleOpenPopup = (popupName) => {
    setActivePopup(popupName);
  };

  // Обработчик закрытия попапа.
  const handleClosePopup = () => {
    setActivePopup(null);
  };

  // Средняя скидка за период
  const averageDiscountRounded = parseFloat(
    bikeCostDetails?.averageDiscount.toFixed(2)
  );

  // Стоимость ваучера
  const voucherPrice = bikeCostDetails?.voucherPrice?.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });

  // Стоимость со скидкой
  const discountedVoucherPrice =
    bikeCostDetails?.discountedVoucherPrice?.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });

  // Общая стоимость аренды
  const totalPrice = bikeCostDetails?.totalPrice?.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });

  // Общая стоимость аренды со скидкой
  const discountedTotalPrice =
    bikeCostDetails?.discountedTotalPrice?.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });

  // Оплата в точке проката
  const paymentAtTheRentalPoint =
    bikeCostDetails?.paymentAtTheRentalPoint?.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });

  // Оплата в точке проката со скидкой
  const discountedPaymentAtTheRentalPoint =
    bikeCostDetails?.discountedPaymentAtTheRentalPoint?.toLocaleString(
      "en-US",
      {
        style: "currency",
        currency: "USD",
      }
    );

  // Полная экономия благодаря скидке
  const priceBenefit = bikeCostDetails?.priceBenefit.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });

  // Стоимость дополнительных услуг
  const priceAdditionalServises =
    bikeCostDetails?.priceAdditionalServises.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });

  // Стоимость всех байков
  const totalPricPerBike = bikeCostDetails?.totalPricPerBike.toLocaleString(
    "en-US",
    {
      style: "currency",
      currency: "USD",
    }
  );

  // Стоимость всех байков со скидкой
  const discountedTotalPricPerBike =
    bikeCostDetails?.discountedTotalPricPerBike.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });

  // Интервал выбранных дат
  const dateInterval = bikeCostDetails?.dateInterval;
  // console.log("bikeCostDetails?.dateInterval", dateInterval);
  // Обработчик для увеличения quantityBikes
  const handleIncreaseQuantity = () => {
    if (typeof quantityBike === "number") {
      dispatch(setQuantityBike(quantityBike + 1));
    }
  };

  // Обработчик для уменьшения quantityBikes
  const handleDecreaseQuantity = () => {
    if (typeof quantityBike === "number" && quantityBike > 1) {
      dispatch(setQuantityBike(quantityBike - 1));
    }
  };

  const orders = {
    user: {
      userId: currentUser?.user?.uid ? currentUser?.user?.uid : uniqueId,
      currentUserEmail: currentUser?.user?.email
        ? currentUser?.user?.email
        : null,
      currentUserPhone: currentUser?.user?.phoneNumber
        ? currentUser?.user?.phoneNumber
        : null,
      currentUserPhoto: currentUser?.user?.photoURL
        ? currentUser?.user?.photoURL
        : null,
    },

    client: {
      name: driverName,
      email: driverEmail,
      phone: driverPhone,
      comment: driverComment,
    },

    order_info: {
      currentBike,
      bikeCostDetails,
      quantityBike: quantityBike,
      rentalPoints_id: currentBike.rentalPoint.id,
      // dateInterval: currentDateInterval,
    },

    orderStatus: {
      confirmationReceived: false,
      replacement: {
        searchingForReplacement: false,
        replacementFound: [
          {
            replacement: {},
            selected: false,
          },
        ],
      },
    },
  };

  const [emailMatch, setEmailMatch] = useState(true); // Статус совпадения emailquantity[rentalPointId]

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setDriverEmail(newEmail);

    // Сверяем новый email с уже введенным email
    if (newEmail === driverRepeatEmail) {
      setEmailMatch(true);
    } else {
      setEmailMatch(false);
    }
  };

  const handleRepeatEmailChange = (e) => {
    const newRepeatEmail = e.target.value;
    setDriverRepeatEmail(newRepeatEmail);

    // Сверяем новый repeat email с уже введенным email
    if (newRepeatEmail === driverEmail) {
      setEmailMatch(true);
    } else {
      setEmailMatch(false);
    }
  };

  const saveOrderData = async () => {
    setLoading(true);
    if (errDriverPhone) {
      const collectionName = "orders";
      const dataObject = orders;

      const idOrder = uuidv4();
      const parentCollectionName = "user";
      const parentDocumentId = currentUser?.uid;
      const result = await saveDataFirebase(
        collectionName,
        null,
        dataObject,
        parentCollectionName,
        parentDocumentId
      );
      console.log("result-result", currentUser.uid);
      if (result) {
        // Успешно добавлено или обновлено
        console.log("Успешно добавлено или обновлено");
        setActivePopup("OrderIsProcessed");
        // setOrderId
      } else {
        // Произошла ошибка
        console.log("Произошла ошибка");
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    // Устанавливаем интервал для смены картинок каждые 1 сек
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % cardImg.length);
    }, 1000);

    // Очистка интервала при размонтировании компонента
    return () => clearInterval(intervalId);
  }, []);

  // console.log('rentalPointContexValue', rentalPointContexValue)

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <div className="popupBg">
        <div className="popupWindowContent">
          <div
            style={{
              position: "absolute",
              top: "16px",
              right: "16px",
            }}
          ></div>
          <div
            style={{
              gridColumn: "span 2",
              height: "100%",
            }}
          >
            <div
              style={{
                padding: "40px 32px 32px 40px",
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "16px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                  width: "100%",
                }}
              >
                <h3
                  style={{
                    width: "100%",
                    textAlign: "start",
                  }}
                >
                  Information about your reservation
                </h3>

                <p
                  style={{
                    width: "60%",
                    textAlign: "start",
                  }}
                  className="p-light"
                >
                  To get the search result, please select the country, city and
                  dates for which the bike will be rented
                </p>
              </div>

              <WigetDateInterval type="input" />

              <Input
                type="text"
                label="Driver’s Name and Surname"
                onChange={(e) => setDriverName(e.target.value)}
                value={driverName}
              />

              {currentUser ? (
                <Input
                  type="email"
                  label="Email"
                  onChange={(e) => setDriverEmail(e.target.value)}
                  value={driverEmail}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "16px",
                    width: "100%",
                  }}
                >
                  <Input
                    type="email"
                    label="Email"
                    onChange={handleEmailChange}
                    value={driverEmail}
                    error={emailMatch}
                  />
                  <Input
                    type="email"
                    label="Repeat email"
                    onChange={handleRepeatEmailChange}
                    value={driverRepeatEmail}
                    error={emailMatch}
                  />
                </div>
              )}

              <InputPhoneV2
                type="phone"
                label="Phone number"
                description={
                  "Mobile phone with a country code. Make sure it will work if you’re ordering vehicle delivery."
                }
                onChange={(e) => setDriverPhone(e.target.value)}
                value={driverPhone}
              />

              <TextareaWrite
                type="text"
                label="Comment on the reservation"
                maxLength={500}
                description={
                  "Your preferences. Flight number or Hotel address (only if delivery service is available in the list of additional services)"
                }
                onChange={(e) => setDriverComment(e.target.value)}
              />

              <div
                style={{
                  width: "100%",
                }}
              >
                <button className="popupButtonBorder" onClick={onClose}>
                  Cancel
                </button>
              </div>
            </div>
          </div>

          <div
            style={{
              gridColumn: "span 1",
              display: "flex",
              flex: "1",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "8px",
              padding: "8px",
              // backgroundColor: 'violet'
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                backgroundColor: "var(--color-white)",
                // borderRadius: '16px',
                // overflow: 'hidden',
                // padding: '8px',
                // boxSizing: 'border-box',
                gap: "8px",
              }}
            >
              <>
                <div
                  style={{
                    background:
                      bikeCostDetails?.priceBenefit > 0
                        ? "linear-gradient(45deg, #FB5085 0%, #C740CA 30%, #3478FF 100%)"
                        : "var(--color-gray-100",
                    width: "100%",
                    height: "100%",
                    borderRadius: "16px",
                    padding: "8px",
                    boxSizing: "border-box",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "var(--color-white)",
                        padding: "24px",
                        borderRadius: "8px",
                        boxSizing: "border-box",
                        aspectRatio: "16/9",
                        display: "flex",
                        flexDirection: "column",
                        gap: "16px",
                      }}
                    >
                      {averageDiscountRounded > 0 ? (
                        <>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "spase-between",
                            }}
                          >
                            <div
                              style={{
                                width: "auto",
                              }}
                              className="averageDiscount"
                            >
                              - {averageDiscountRounded}% off
                            </div>
                            <div />
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <p>Pay now</p>
                        <div>
                          <h5>{discountedVoucherPrice}</h5>

                          <p
                            style={{
                              textDecoration: "line-through",
                              color: "var(--color-gray-700)",
                              textAlign: "right",
                            }}
                          >
                            {voucherPrice}
                          </p>
                        </div>
                      </div>

                      <p className="p-light" style={{ lineHeight: "1.4" }}>
                        To reserve&nbsp;
                        <span style={{ fontWeight: "bold" }}>
                          {currentBike?.title[0]}
                        </span>
                        &nbsp;for the period&nbsp;
                        <span style={{ fontWeight: "bold" }}>
                          {dateInterval[0]}—{dateInterval[1]}
                        </span>
                        &nbsp;you need to pay&nbsp;
                        <span style={{ fontWeight: "bold" }}>
                          {discountedVoucherPrice}
                        </span>
                        &nbsp;when receiving the bike you will need to pay the
                        remaining amount in the amount of&nbsp;
                        <span style={{ fontWeight: "bold" }}>
                          {discountedTotalPrice}
                        </span>{" "}
                        . You can cancel your reservation no later than two
                        weeks in advance. If you have any questions, you can
                        contact the technical&nbsp;
                        <Link to="/support">support of our service.</Link>
                      </p>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            gap: "16px",
                            width: "100%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: "8px",
                              backgroundColor: "var(--color-gray-100)",
                              padding: "0 16px",
                              height: "32px",
                              borderRadius: "80px",
                            }}
                          >
                            <p
                              style={{
                                // paddingRight: '8px',
                                lineHeight: "11px",
                                // fontSize: 'rem'
                              }}
                              className="p-light"
                            >
                              Quantity&nbsp;
                              {/* {currentBike?.category?.title[0]} */}
                            </p>

                            <span
                              style={{
                                fontSize: "1rem",
                              }}
                              className="material-symbols-outlined"
                            >
                              close
                            </span>

                            <h5>{quantityBike}</h5>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: "8px",
                            }}
                          >
                            <button
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                border: "none",
                                backgroundColor: "var(--color-black)",
                                aspectRatio: "1/1",
                                height: "32px",
                                color: "var(--color-white)",
                                borderRadius: "80px",
                                cursor: "pointer",
                              }}
                              onClick={handleDecreaseQuantity}
                            >
                              <span className="material-symbols-outlined">
                                remove
                              </span>
                            </button>

                            <button
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                border: "none",
                                backgroundColor: "var(--color-black)",
                                aspectRatio: "1/1",
                                height: "32px",
                                color: "var(--color-white)",
                                borderRadius: "80px",
                                cursor: "pointer",
                              }}
                              onClick={handleIncreaseQuantity}
                            >
                              <span className="material-symbols-outlined">
                                add
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        gap: "16px",
                        padding: "24px",
                        boxSizing: "border-box",
                      }}
                    >
                      {bikeCostDetails?.priceAdditionalServises > 0 ? (
                        <>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                              borderBottom:
                                bikeCostDetails?.priceBenefit > 0
                                  ? "1px rgba(255, 255, 255, 0.1) solid"
                                  : "1px var(--color-gray-200) solid",
                              paddingBottom: "16px",
                            }}
                          >
                            <p
                              style={{
                                color:
                                  bikeCostDetails?.priceBenefit > 0
                                    ? "var(--color-white)"
                                    : "var(--color-gray-500)",
                              }}
                              className="p-light"
                            >
                              Additional services price
                            </p>
                            <p
                              style={{
                                color:
                                  bikeCostDetails?.priceBenefit > 0
                                    ? "var(--color-white)"
                                    : "var(--color-gray-500)",
                              }}
                              className="p-light"
                            >
                              {priceAdditionalServises}
                            </p>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          borderBottom:
                            bikeCostDetails?.priceBenefit > 0
                              ? "1px rgba(255, 255, 255, 0.1) solid"
                              : "1px var(--color-gray-200) solid",
                          paddingBottom: "16px",
                        }}
                      >
                        <p
                          style={{
                            color:
                              bikeCostDetails?.priceBenefit > 0
                                ? "var(--color-white)"
                                : "var(--color-gray-500)",
                          }}
                          className="p-light"
                        >
                          Total price bikes:
                        </p>

                        <div>
                          <p
                            style={{
                              color:
                                bikeCostDetails?.priceBenefit > 0
                                  ? "var(--color-white)"
                                  : "var(--color-gray-500)",
                            }}
                            className="p-light"
                          >
                            {bikeCostDetails?.discountedTotalPricPerBike > 0
                              ? discountedTotalPricPerBike
                              : totalPricPerBike}
                          </p>
                          {bikeCostDetails?.discountedTotalPricPerBike > 0 && (
                            <p
                              style={{
                                opacity: "0.5",
                                color:
                                  bikeCostDetails?.priceBenefit > 0
                                    ? "var(--color-white)"
                                    : "var(--color-gray-500)",
                                textDecoration: "line-through",
                                // color: 'var(--color-gray-700)',
                                textAlign: "right",
                              }}
                              className="p-light"
                            >
                              {totalPricPerBike}
                            </p>
                          )}
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <p
                          style={{
                            color:
                              bikeCostDetails?.priceBenefit > 0
                                ? "var(--color-white)"
                                : "var(--color-gray-500)",
                          }}
                          className="p-light"
                        >
                          Total price order:
                        </p>

                        <div>
                          <p
                            style={{
                              color:
                                bikeCostDetails?.priceBenefit > 0
                                  ? "var(--color-white)"
                                  : "var(--color-gray-500)",
                            }}
                            className="p-light"
                          >
                            {bikeCostDetails?.discountedTotalPrice > 0
                              ? discountedTotalPrice
                              : totalPrice}
                          </p>
                          {bikeCostDetails?.totalPrice >
                            bikeCostDetails?.discountedTotalPrice && (
                            <p
                              style={{
                                opacity: "0.5",
                                color:
                                  bikeCostDetails?.priceBenefit > 0
                                    ? "var(--color-white)"
                                    : "var(--color-gray-500)",
                                textDecoration: "line-through",
                                textAlign: "right",
                              }}
                              className="p-light"
                            >
                              {totalPrice}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      gap: "8px",
                      padding: "16px",
                      boxSizing: "border-box",
                    }}
                  >
                    {bikeCostDetails?.priceBenefit > 0 ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "space-between",
                          boxSizing: "border-box",
                          width: "100%",
                          gap: "16px",
                          position: "relative",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            textAlign: "center",
                            color: "var(--color-white)",
                          }}
                        >
                          <h5
                            style={{
                              color: "var(--color-white)",
                            }}
                          >
                            Secure your order now and enjoy a friendly discount
                            of&nbsp;
                            {priceBenefit}
                          </h5>
                        </div>

                        <>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              paddingRight: "8px",
                              flexDirection: "row",
                              gap: "16px",
                              width: "100%",
                              borderRadius: "80px",
                              border: "none",
                              fontSize: "1em",
                              fontWeight: "500",
                              height: "56px",
                              backgroundColor: errDriverPhone
                                ? "var(--color-black)"
                                : "#00000030",
                              color: "var(--color-white)",
                              textDecoration: "none",
                              cursor: errDriverPhone
                                ? "pointer"
                                : "not-allowed",
                              boxSizing: "border-box",
                              paddingLeft: "24px",
                            }}
                            onClick={saveOrderData}
                            type="submit"
                          >
                            <p>Secure payment</p>

                            {loading ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  backgroundColor: "var(--color-black)",
                                  height: "40px",
                                  aspectRatio: "1/1",
                                  borderRadius: "80px",
                                }}
                              >
                                <Preloader />
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  backgroundColor: "var(--color-white)",
                                  height: "40px",
                                  aspectRatio: "1/1",
                                  borderRadius: "80px",
                                }}
                              >
                                <img
                                  style={{
                                    height: "24px",
                                    width: "auto",
                                  }}
                                  src={cardImg[currentImageIndex]}
                                  alt="Payment card"
                                />
                              </div>
                            )}
                          </div>
                        </>
                      </div>
                    ) : (
                      <>
                        <div
                          style={{
                            // opacity: errDriverPhone ? "0.5" : "1",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingRight: "8px",
                            flexDirection: "row",
                            gap: "16px",
                            width: "100%",
                            borderRadius: "80px",
                            border: "none",
                            fontSize: "1em",
                            fontWeight: "500",
                            height: "56px",
                            backgroundColor: errDriverPhone
                              ? "var(--color-black)"
                              : "#00000010",
                            color: "var(--color-white)",
                            textDecoration: "none",
                            cursor: errDriverPhone ? "pointer" : "not-allowed",
                            boxSizing: "border-box",
                            paddingLeft: "24px",
                          }}
                          onClick={saveOrderData}
                          type="submit"
                        >
                          <p>Secure payment</p>

                          {loading ? (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: "var(--color-black)",
                                height: "40px",
                                aspectRatio: "1/1",
                                borderRadius: "80px",
                              }}
                            >
                              <Preloader />
                            </div>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: "var(--color-white)",
                                height: "40px",
                                aspectRatio: "1/1",
                                borderRadius: "80px",
                              }}
                            >
                              <img
                                style={{
                                  height: "24px",
                                  width: "auto",
                                }}
                                src={cardImg[currentImageIndex]}
                                alt="Payment card"
                              />
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
      <WigetSelectedPopup
        isOpen={activePopup === "OrderIsProcessed"}
        // img={currentCountry?.file_photo}
        // flag={countryFlag}
        //   map={map}
        onClose={() => {
          handleClosePopup();
          onClose();
        }}
      >
        <div
          style={{
            gridColumn: "span 2",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "24px",
            padding: "40px",
            height: "100%",
            width: "100%",
            boxSizing: "border-box",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "24px",
              width: "70%",
            }}
          >
            <div
              style={{
                backgroundImage:
                  'url("https://firebasestorage.googleapis.com/v0/b/biketripste.appspot.com/o/static%2FlogoBikeTripster%2Fhappy.svg?alt=media&token=a30fa95c-e823-46f5-a986-1568771720f7")',
                backgroundSize: "auto auto",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                width: "100%",
                height: "100px",
                margin: "0 0 80px 0",
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
              }}
            >
              <h3
                style={{
                  width: "70%",
                  textAlign: "center",
                }}
              >
                Order was successfully created
              </h3>
              <p
                className="p-mini"
                style={{
                  textAlign: "center",
                  lineHeight: "1.3",
                }}
              >
                Great news! Your order has been successfully placed. Check your
                email – we've sent you a voucher. Thank you for your purchase,
                and enjoy our services!
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "16px",
              }}
            >
              <button
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "16px",
                  width: "auto",
                  padding: "8px 24px",
                  borderRadius: "80px",
                  border: "none",
                  fontSize: "1em",
                  fontWeight: "500",
                  height: "56px",
                  backgroundColor: "var(--color-primary)",
                  color: "var(--color-white)",
                  cursor: "pointer",
                }}
                onClick={onClose}
              >
                Great, I understand everything
              </button>
            </div>
          </div>
        </div>
      </WigetSelectedPopup>
    </>
  );
};

export default WigetReservation;
