import React, { useEffect, useState } from "react";
import Button from "../WidgetsProfile/Buttons/Button";
import InputText from "../WidgetsProfile/Inputs/InputText";
import { useDispatch, useSelector } from "react-redux";

import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import {
  setRateNewBike,
  setRateSelectedBike,
} from "../../../../../reducersToolkit/bikesSliceV2";
import Textarea from "../WidgetsProfile/Textarea/Textarea";
import ButtonsBar from "../WidgetsProfile/ButtonsBar/ButtonsBar";

const WindowBikePricePerMonth = ({ mode, index, onClose }) => {
  const dispatch = useDispatch();

  const [inputPrice, setInputPrice] = useState("");
  const [inputDiscount, setInputDiscount] = useState("");
  const [description, setDescription] = useState("");

  const { bikeTemplate, newBike, selectedBike } = useSelector(
    (state) => state.bikesSliceV2
  );

  const rateItem = {
    month: index,
    price: inputPrice === "" ? 0 : Number(inputPrice),
    discount: inputDiscount === "" ? 0 : Number(inputDiscount),
    description: {
      descriptionOriginal: description,
    },
  };

  useEffect(() => {
    if (inputPrice === 0) {
      setInputDiscount(0);
    }
  }, [inputPrice]);

  useEffect(() => {
    if (mode === "new") {
      setInputPrice(bikeTemplate?.rate[index]?.price || "");
      setInputDiscount(bikeTemplate?.rate[index]?.discount || "");
      setDescription(
        bikeTemplate?.rate[index]?.description?.descriptionOriginal || ""
      );
    } else if (mode === "edit") {
      setInputPrice(selectedBike?.rate[index]?.price || "");
      setInputDiscount(selectedBike?.rate[index]?.discount || "");
      setDescription(
        selectedBike?.rate[index]?.description?.descriptionOriginal || ""
      );
    }
  }, [mode, bikeTemplate, selectedBike, index]);

  const handleUpdateRate = (index, newRate) => {
    if (mode === "new") {
      dispatch(setRateNewBike({ index, newRate }));
    } else if (mode === "edit") {
      dispatch(setRateSelectedBike({ index, newRate }));
    }
    onClose();
  };

  const handleClearRate = (index) => {
    dispatch(
      setRateSelectedBike({
        index,
        newRate: {
          month: index,
          price: 0,
          discount: 0,
          description: {
            descriptionOriginal: "",
          },
        },
      })
    );
    onClose();
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "8px",
          paddingBottom: "72px",
        }}
      >
        <ContentBox position="start">
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              gap: "16px",
            }}
          >
            <InputText
              label="Price"
              type="number"
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
              value={inputPrice}
              onChange={(event) => setInputPrice(Number(event.target.value))}
            />

            <InputText
              label="Sale"
              type="number"
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
              value={inputDiscount}
              onChange={(event) => setInputDiscount(Number(event.target.value))}
            />
          </div>
          <Textarea
            label="Rate description"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            maxLength={1000}
            value={description}
            onChange={(event) => setDescription(event.target.value)}
          />
        </ContentBox>
      </div>
      <ButtonsBar>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: "16px",
            width: "100%",
          }}
        >
          <Button
            type="small"
            label="Cancel"
            color="--color-black"
            active={true}
            onClick={onClose}
          />
          <Button
            type="small"
            label="Clear"
            active={inputPrice !== ""}
            onClick={() => handleClearRate(index)}
            color="--color-warning"
            allert={
              <div
                style={{
                  padding: "48px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "16px",
                  textAlign: "center",
                }}
              >
                <h1>Are you sure you want to delete the current rate?</h1>
                <p className="p-light">
                  This action will reset all entered data.
                </p>
              </div>
            }
          />
          <Button
            type="small"
            label="Save"
            active={inputPrice > 0}
            onClick={() => handleUpdateRate(index, rateItem)}
          />
        </div>
      </ButtonsBar>
    </>
  );
};

export default WindowBikePricePerMonth;
