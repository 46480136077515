import React, { useEffect, useState } from "react";
import "../profile.css";
import AddData from "../WidgetsProfile/AddData/AddData";
import PopupDirrectory from "../WidgetsProfile/Popups/PopupDirrectory";
import WindowRentalPontLocationV4 from "./WindowRentalPontLocationV4";
import { useDispatch } from "react-redux";
import { clearFormV4 } from "../../../../../reducersToolkit/rentalPointsSliiceV4";

const WidgetRentalPointLocationV4 = ({ mode, location }) => {
  const dispatch = useDispatch();

  const [
    isOpenWindowRentalPontLocationV4,
    setIsOpenWindowRentalPontLocationV4,
  ] = useState(false);
  const [pointAddress, setPointAddress] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");

  useEffect(() => {
    setPointAddress(location?.pointAddress || "");
    setCountry(location?.country?.title || "");
    setCity(location?.city?.title || "");
  }, [location]);

  const handleToggleWindowRentalPontLocationV4 = () => {
    setIsOpenWindowRentalPontLocationV4(true);
  };

  const handleClose = () => {
    setIsOpenWindowRentalPontLocationV4(false);
  };

  return (
    <>
      <div className="profile-widget__content-box">
        <AddData
          onClick={handleToggleWindowRentalPontLocationV4}
          icon={
            pointAddress ? (
              <span className="material-symbols-outlined">edit</span>
            ) : (
              <span className="material-symbols-outlined">add</span>
            )
          }
          title={`${pointAddress ? "Edit location" : "Add location"}`}
          description={`${
            pointAddress && country && city
              ? `${country}, ${city}, ${pointAddress}`
              : "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          }`}
        />
      </div>

      <PopupDirrectory
        isOpen={isOpenWindowRentalPontLocationV4}
        onClose={handleClose}
        title="Add location"
      >
        <WindowRentalPontLocationV4 mode={mode} onClose={handleClose} />
      </PopupDirrectory>
    </>
  );
};

export default WidgetRentalPointLocationV4;
