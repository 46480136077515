import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { addDocumentById } from "../../../../services/firebase/crudFirebaseStorage";
import { Timestamp } from "firebase/firestore";
import interpretAuthErrorV2 from "../../../../functions/interpretAuthErrorV2";

export const emailSignUpThunk = createAsyncThunk(
  "manage/emailSignUpThunk",
  async ({ email, password, userName }, { rejectWithValue }) => {
    const auth = getAuth();

    try {
      // Регистрируем пользователя с помощью Firebase Auth
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      // Проверяем, аутентифицирован ли пользователь
      if (!userCredential.user) {
        console.error("User is not authenticated.");
        throw new Error("User is not authenticated.");
      }

      // Получаем текущую дату и время в формате Timestamp Firestore
      const timestamp = Timestamp.fromDate(new Date());
      const timestampSeconds = Math.floor(Date.now() / 1000);

      // Подготовка данных пользователя для Firestore
      const currentUser = {
        user: {
          contactingTheUser: userCredential.user.displayName || "",
          ownerName: "",
          ownerSurname: "",
          gender: "",
          yourGender: "",
          avatar: [
            {
              url: userCredential.user.photoURL || "",
              prioriry: false,
            },
          ],
        },
        userContacts: {
          email: email,
          phoneNumber: userCredential.user.phoneNumber || "",
        },
        company: {
          companyName: "",
          mailingAddress: "",
          timeZone: "",
        },

        id: userCredential.user.uid,
        stateProfile: "new",
        // name: "",
        // displayName: displayName || "",
        // photoURL: photoURL || "",
        role: "rental",
        providerData: userCredential.user.providerData || [],
        offerConfirm: timestampSeconds,
      };

      // Добавление данных пользователя в Firestore и получение ID добавленного документа
      await addDocumentById(currentUser, "users", userCredential.user.uid);

      // Возвращаем данные пользователя вместе с ID добавленного документа
      return { currentUser: currentUser.user };
    } catch (error) {
      const errorMessage = interpretAuthErrorV2(error.code);
      console.error(
        "Failed to load user data. Please try again later or contact support. Error: ",
        errorMessage
      );
      return rejectWithValue({ errorMessage });
    }
  }
);
