import React, { useEffect, useState } from "react";
import DropdownList from "../../Private/Login/Profile/WidgetsProfile/DropdownList/DropdownList";
import { useDispatch, useSelector } from "react-redux";
import {
  clearFilters,
  setCatalogBrand,
  setCatalogCategory,
} from "../../../reducersToolkit/catalogSliceV2";
import Button from "../../Private/Login/Profile/WidgetsProfile/Buttons/Button";
import { filterBikesThunkV2 } from "../../../reducersToolkit/thunk/catalog/filterBikesThunkV2";
import ContentBox from "../../Private/Login/Profile/WidgetsProfile/ContentBox/ContentBox";
import ButtonsBar from "../../Private/Login/Profile/WidgetsProfile/ButtonsBar/ButtonsBar";

const WindowFilterListing = ({ onClose }) => {
  const dispatch = useDispatch();

  // const [selectedBrandId, setSelectedBrandId] = useState(null);
  // const [selectedCategoryId, setSelectedCategoryId] = useState(null);

  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [brandsOptions, setBrandsOptions] = useState([]);
  const [categoriesOptions, setCategoriesOptions] = useState([]);

  const [bookingDates, setBookingDates] = useState([]);
  const [qualityBike, setQualityBike] = useState(1);
  const [cityIdFilter, setCityIdFilter] = useState(null);
  const [countyIdFilter, setCountyIdFilter] = useState(null);
  const [brandIdFilter, setBrandIdFilter] = useState(null);
  const [categoryIdFilter, setCategoryIdFilter] = useState(null);

  const {
    filteredBikes,
    bikes,
    rentalPoints,
    cities,
    countries,
    brands,
    categories,
    isLoading,
    filters,
  } = useSelector((state) => state.catalogSliceV2);

  useEffect(() => {
    setBookingDates([
      filters?.bookingDates?.startDay,
      filters?.bookingDates?.endDay,
    ]);
    setQualityBike(filters?.qualityBike || 1);
    setCityIdFilter(filters?.city?.id || null);
    setCountyIdFilter(filters?.country?.id || null);
    setBrandIdFilter(filters?.brand?.id || null);
    setCategoryIdFilter(filters?.category?.id || null);
  }, [filters]);

  useEffect(() => {
    const formattedBrands = brands.map((brand) => ({
      id: brand.id,
      title: brand.title.titleOriginal,
    }));

    setBrandsOptions(formattedBrands);

    const initialBrand = formattedBrands.find(
      (brand) => brand.id === brandIdFilter
    );
    setBrandIdFilter(initialBrand?.id || null);
    setSelectedBrand(initialBrand);
  }, [brands, brandIdFilter]);

  useEffect(() => {
    const formattedCategories = categories.map((category) => ({
      id: category.id,
      title: category.title.titleOriginal,
    }));

    setCategoriesOptions(formattedCategories);

    const initialCategory = formattedCategories.find(
      (category) => category.id === categoryIdFilter
    );
    setCategoryIdFilter(initialCategory?.id || null);
    setSelectedCategory(initialCategory);
  }, [categories, categoryIdFilter]);

  const handleBrandSelect = (brand) => {
    dispatch(setCatalogBrand({ brand }));
  };

  const handleCategorySelect = (category) => {
    dispatch(setCatalogCategory({ category }));
  };

  const buttonClear = () => {
    dispatch(clearFilters());
    onClose();
  };

  // Фильтрация списка bikes
  const buttonApply = () => {
    if (bikes.length > 0) {
      dispatch(
        filterBikesThunkV2({
          bikes: bikes,
          countryIdFilter: countyIdFilter,
          cityIdFilter: cityIdFilter,
          qualityBikeFilter: qualityBike,
          dateIntervalFilter: bookingDates,
          brandIdFilter: brandIdFilter,
          categoryIdFilter: categoryIdFilter,
        })
      );
    }
    onClose();
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingBottom: "72px",
        }}
      >
        <ContentBox position="start">
          <DropdownList
            options={brandsOptions}
            onSelect={handleBrandSelect}
            label="Select Brand"
            description="Please select a brand."
            fieldView="title"
            fieldId="id"
            initialSelectedOption={selectedBrand}
          />
          <DropdownList
            options={categoriesOptions}
            onSelect={handleCategorySelect}
            label="Select Category"
            description="Please select a category."
            fieldView="title"
            fieldId="id"
            initialSelectedOption={selectedCategory}
          />
        </ContentBox>
      </div>
      <ButtonsBar>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "16px",
          }}
        >
          <Button
            type="small"
            label="Clear"
            active={true}
            color="--color-black"
            onClick={buttonClear}
          />
          <Button
            type="small"
            label="Apply"
            active={true}
            onClick={buttonApply}
          />
        </div>
      </ButtonsBar>
    </>
  );
};

export default WindowFilterListing;

// import React, { useEffect, useState } from "react";
// import DropdownList from "../../Private/Login/Profile/WidgetsProfile/DropdownList/DropdownList";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   clearFilters,
//   setCatalogBrand,
//   setCatalogCategory,
// } from "../../../reducersToolkit/catalogSliceV2";
// import Button from "../../Private/Login/Profile/WidgetsProfile/Buttons/Button";

// const WindowFilterListing = ({ onClose }) => {
//   const dispatch = useDispatch();

//   const [selectedBrandId, setSelectedBrandId] = useState(null);
//   const [selectedCategoryId, setSelectedCategoryId] = useState(null);

//   const [selectedBrand, setSelectedBrand] = useState(null);
//   const [selectedCategory, setSelectedCategory] = useState(null);

//   const [brandsOptions, setBrandsOptions] = useState([]);
//   const [categoriesOptions, setCategoriesOptions] = useState([]);

//   const { filters, brands, categories } = useSelector(
//     (state) => state.catalogSliceV2
//   );

//   useEffect(() => {
//     setSelectedBrandId(filters?.brand?.id || null);
//     setSelectedCategoryId(filters?.category?.id || null);
//   }, [filters]);

//   useEffect(() => {
//     const formattedBrands = brands.map((brand) => ({
//       id: brand.id,
//       title: brand.title.titleOriginal,
//     }));

//     setBrandsOptions(formattedBrands);

//     const initialBrand = formattedBrands.find(
//       (brand) => brand.id === selectedBrandId
//     );

//     setSelectedBrand(initialBrand);
//   }, [brands, selectedBrandId]);

//   useEffect(() => {
//     const formattedCategories = categories.map((category) => ({
//       id: category.id,
//       title: category.title.titleOriginal,
//     }));

//     setCategoriesOptions(formattedCategories);

//     const initialCategory = formattedCategories.find(
//       (category) => category.id === selectedCategoryId
//     );

//     setSelectedCategory(initialCategory);
//   }, [categories, selectedCategoryId]);

//   const handleBrandSelect = (brand) => {
//     setSelectedBrand(brand);
//     setSelectedBrandId(brand.id);
//   };

//   const handleCategorySelect = (category) => {
//     setSelectedCategory(category);
//     setSelectedCategoryId(category.id);
//   };

//   const buttonClear = () => {
//     dispatch(clearFilters());
//     onClose();
//   };

//   const buttonApply = () => {
//     dispatch(setCatalogCategory({ category: selectedCategory }));
//     dispatch(setCatalogBrand({ brand: selectedBrand }));
//     onClose();
//   };

//   return (
//     <div style={{
//       display:'flex',
//       flexDirection:'column',
//       gap:'16px'
//     }}>
//       <DropdownList
//         options={brandsOptions}
//         onSelect={handleBrandSelect}
//         label="Select Brand"
//         description="Please select a brand."
//         fieldView="title"
//         fieldId="id"
//         initialSelectedOption={selectedBrand}
//       />
//       <DropdownList
//         options={categoriesOptions}
//         onSelect={handleCategorySelect}
//         label="Select Category"
//         description="Please select a category."
//         fieldView="title"
//         fieldId="id"
//         initialSelectedOption={selectedCategory}
//       />
//       <div
//         style={{
//           display: "grid",
//           gridTemplateColumns: "repeat(2, 1fr)",
//           gap: "16px",
//         }}
//       >
//         <Button
//           label="Clear"
//           active={true}
//           color="--color-black"
//           onClick={buttonClear}
//         />
//         <Button label="Apply" active={true} onClick={buttonApply} />
//       </div>
//     </div>
//   );
// };

// export default WindowFilterListing;
