import React from "react";
import CollapsableText from "../../Private/Login/Profile/WidgetsProfile/Collapsable/CollapsableText";
import Switcher from "../../Private/Login/Profile/WidgetsProfile/Switcher/Switcher";
import { formatCurrencyUSD } from "../../../functions/currencyFormatter";

const WidgetAditionalServices = ({ additionalServices = [] }) => {
  return (
    <>
      <div
        style={{
          width: "100%",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          backgroundColor: "var(--color-white)",
          borderRadius: "24px",
          padding: "8px",
        }}
      >
        {/* <p>services:</p> */}
        {additionalServices?.map((service) => (
          <div
            key={Date.now()}
            style={{
              backgroundColor: "var(--color-gray-100)",
              borderRadius: "16px",
              padding: "16px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "8px",
              boxSizing: "border-box",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                // gap: "8px",
              }}
            >
              <p className="p-light">{service.title.titleOriginal}</p>
              <CollapsableText text={service.description.descriptionOriginal} />
            </div>

            {/* <p
                  style={{
                    fontWeight: "600",
                  }}
                >
                  {formatCurrencyUSD(service.price)}
                </p> */}

            <Switcher
              isChecked={true}
              // onChange={setAcceptOffer}
              label={formatCurrencyUSD(service.price)}
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default WidgetAditionalServices;
