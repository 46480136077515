import { useSelector } from "react-redux";
import Preloader from "../../UIElements/Preloader";
import { useDispatch } from "react-redux";
import { setQuantityBike } from "../../../slice/stateFrontGUISlice";

const WigetQuntityBikes = () => {
  const dispatch = useDispatch();

  const quantityBike = useSelector(
    (state) => state.stateFrontGUISlice.quantityBike
  );

  const isLoading = useSelector((state) => state.stateFrontGUISlice.isLoading);

  // Обработчик для увеличения quantityBikes
  const handleIncreaseQuantity = () => {
    if (typeof quantityBike === "number") {
      dispatch(setQuantityBike(quantityBike + 1));
    }
  };

  // Обработчик для уменьшения quantityBikes
  const handleDecreaseQuantity = () => {
    if (typeof quantityBike === "number" && quantityBike > 1) {
      dispatch(setQuantityBike(quantityBike - 1));
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flex: "1",
        gridColumn: "1",
        backgroundColor: "var(--color-gray-100)",
        borderRadius: "16px",
        padding: "8px",
        boxSizing: "border-box",
        transition: "background-color 0.5s ease",
        aspectRatio: "1/1",
        backdropFilter: "blur(10px)",
      }}
    >
      {isLoading ? (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Preloader />
        </div>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              fontSize: "4rem",
              lineHeight: "0",
              fontWeight: "600",
              color: "var(--color-black)",
              boxSizing: "border-box",
            }}
          >
            {quantityBike}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                height: "32px",
                aspectRatio: "1/1",
                backgroundColor: "var(--color-gray-100)",
                borderRadius: "4px",
                transition: "background-color 0.3s",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = "var(--color-primary)";
                e.currentTarget.style.color = "var(--color-white)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "var(--color-gray-100)";
                e.currentTarget.style.color = "var(--color-gray-900)";
              }}
              onClick={handleIncreaseQuantity}
            >
              <span className="material-symbols-outlined">add</span>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                height: "32px",
                aspectRatio: "1/1",
                backgroundColor: "var(--color-gray-100)",
                borderRadius: "4px",
                transition: "background-color 0.3s",
              }}
              onClick={handleDecreaseQuantity}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = "var(--color-primary)";
                e.currentTarget.style.color = "var(--color-white)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "var(--color-gray-100)";
                e.currentTarget.style.color = "var(--color-gray-900)";
              }}
            >
              <span className="material-symbols-outlined">remove</span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default WigetQuntityBikes;
