import React, { useState, useEffect } from "react";
import InputText from "../WidgetsProfile/Inputs/InputText";
import Button from "../WidgetsProfile/Buttons/Button";
import { useDispatch, useSelector } from "react-redux";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import { removeContactsPhoneNumber } from "../../../../../reducersToolkit/rentalPointsSliiceV2";
import {
  removeContactsPhoneNumberModeration,
  setContactsPhoneNumbersModeration,
} from "../../../../../reducersToolkit/moderationSliceV2";

const WindowEditPhoneModerationV4 = ({ mode, onClose }) => {
  const dispatch = useDispatch();

  const [phone, setPhone] = useState("");
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [newPhoneNumbers, setNewPhoneNumbers] = useState([]);

  const currentUser = useSelector((state) => state.userSliceV2.currentUser);

  const { newRentalPoint, selectedRentalPoint } = useSelector(
    (state) => state.moderationSliceV2
  );

  useEffect(() => {
    if (mode === "moderatorAdded") {
      setPhoneNumbers(newRentalPoint?.contacts?.phoneNumbers || []);
    } else if (mode === "moderation") {
      setPhoneNumbers(selectedRentalPoint?.contacts?.phoneNumbers || []);
    }
  }, [mode, newRentalPoint, selectedRentalPoint]);

  const handleAddPhone = () => {
    const phoneObject = {
      key: Date.now().toString(),
      phone,
      moderatorId: mode === "moderation" ? currentUser.id : null,
    };
    setNewPhoneNumbers([...newPhoneNumbers, phoneObject]);
    setPhone("");
  };

  const handleRemovePhone = (index) => {
    setNewPhoneNumbers(newPhoneNumbers.filter((_, i) => i !== index));
  };

  const handleRemoveLoadedPhone = (phoneObject) => {
    dispatch(
      removeContactsPhoneNumberModeration({ mode: mode, key: phoneObject.key })
    );
  };

  const handleApply = () => {
    newPhoneNumbers.forEach((phoneObject) => {
      dispatch(
        setContactsPhoneNumbersModeration({ mode, phoneNumber: phoneObject })
      );
    });
    onClose();
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "8px",
      }}
    >
      <ContentBox position="start">
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            gap: "16px",
          }}
        >
          <InputText
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            label="Phone number"
            description="Enter the phone number."
          />
          <div
            style={{
              width: "auto",
            }}
          >
            <Button
              label="Add"
              active={phone ? true : false}
              onClick={handleAddPhone}
            />
          </div>
        </div>
      </ContentBox>
      {(phoneNumbers.length > 0 || newPhoneNumbers.length > 0) && (
        <ContentBox>
          <ul
            style={{
              width: "100%",
              listStyle: "none",
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            {phoneNumbers.map((phoneObj) => (
              <li
                key={phoneObj.key}
                style={{
                  height: "56px",
                  width: "100%",
                  boxSizing: "border-box",
                  borderRadius: "8px",
                  backgroundColor: "var(--color-gray-100)",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "0 8px 0 16px",
                }}
              >
                <h6>{phoneObj.phone || "No phone number"}</h6>
                <div
                  style={{
                    width: "auto",
                  }}
                >
                  <Button
                    onClick={() => handleRemoveLoadedPhone(phoneObj)}
                    allert={
                      <h1
                        style={{
                          padding: "48px",
                          textAlign: "center",
                        }}
                      >
                        Are you sure you want to delete this phone number?
                        Please confirm your action.
                      </h1>
                    }
                    active={true}
                    color="--color-gray-100"
                    type="icon"
                    icon={
                      <span className="material-symbols-outlined">delete</span>
                    }
                  />
                </div>
              </li>
            ))}
            {newPhoneNumbers.map((phoneObj, index) => (
              <li
                key={phoneObj.key}
                style={{
                  height: "56px",
                  width: "100%",
                  boxSizing: "border-box",
                  borderRadius: "8px",
                  backgroundColor: "var(--color-gray-100)",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "0 8px 0 16px",
                }}
              >
                <h6>{phoneObj.phone || "No phone number"}</h6>
                <div
                  style={{
                    width: "auto",
                  }}
                >
                  <Button
                    onClick={() => handleRemovePhone(index)}
                    allert={
                      <h1
                        style={{
                          padding: "48px",
                          textAlign: "center",
                        }}
                      >
                        Are you sure you want to delete this phone number?
                        Please confirm your action.
                      </h1>
                    }
                    active={true}
                    color="--color-gray-100"
                    type="icon"
                    icon={
                      <span className="material-symbols-outlined">delete</span>
                    }
                  />
                </div>
              </li>
            ))}
          </ul>
        </ContentBox>
      )}

      <ContentBox position="end">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "16px",
            width: "100%",
          }}
        >
          <Button
            label="Cancel"
            active={true}
            color="--color-alarm"
            onClick={onClose}
          />
          <Button
            label="Apply"
            active={newPhoneNumbers.length > 0 && true}
            onClick={handleApply}
          />
        </div>
      </ContentBox>
    </div>
  );
};

export default WindowEditPhoneModerationV4;
