import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useSubscribeData } from "../../../../services/firebase/crudFirebaseStorage";
import Preloader from "../../../UIElements/Preloader";
import { useDispatch, useSelector } from "react-redux";
import { dateFormat } from "../../../../functions/convertDate";
import WigetSelectedPopupV2 from "../WigetSelectedPopupV2";
import WigetAddComments from "./WidgetAddComments";
import { getFunctions, httpsCallable } from "firebase/functions";

const WigetViewComments = ({
  uid,
  rentalPointId,
  commentsData,
  loadingCommentsData,
}) => {
  const currentUser = useSelector((state) => state.userSliceV2.currentUser);
  const role = currentUser ? currentUser.role : null;
  const isLoading = useSelector((state) => state.userSliceV2.isLoading);

  const [addParentComment, setAddParentComment] = useState(false);
  const [currentCommetnId, setCurrentCommetnId] = useState(false);
  const [currentCommetn, setCurrentCommetn] = useState("");
  const [commentsLikeState, setCommentsLikeState] = useState(false);
  const [commentsDislikeState, setCommentsDislikeState] = useState(false);
  const [commentsRemoveLikeState, setCommentsRemoveLikeState] = useState(false);
  const [commentsRemoveDislikeState, setCommentsRemoveDislikeState] =
    useState(false);

  const dispatch = useDispatch();
  // console.log("WigetViewComments-currentCommetn", currentCommetn);
  // const commentsData = useSelector(
  //   (state) => state.stateFrontGUISlice.commentsData
  // );

  const [filteredComments, setFilteredComments] = useState([]);

  const getRatingClass = (rating, ratingValue) => {
    // const ratingValue = selectedRating || hoveredRating;
    if (rating <= ratingValue) {
      if (ratingValue >= 4) {
        return "active-4-5";
      } else if (ratingValue === 3) {
        return "active-3";
      } else {
        return "active-1-2";
      }
    }
    return ""; // Вернуть пустую строку, если условия не выполняются
  };

  const RangeRating = ({ currntRange }) => {
    return (
      <div
        style={{
          display: "flex",
        }}
      >
        {[1, 2, 3, 4, 5].map((rating) => (
          <span
            key={rating}
            className={`material-symbols-outlined rating-star ${getRatingClass(
              rating,
              currntRange
            )}`}
          >
            grade
          </span>
        ))}
      </div>
    );
  };
  useEffect(() => {
    if (commentsData) {
      const filteredComments = commentsData.filter(
        (comment) => comment.rentalPointId === rentalPointId
      );
      setFilteredComments(filteredComments);
    }
  }, [commentsData, rentalPointId]);
  // const dateFormat = (timestamp) => {
  //   return new Date(timestamp).toLocaleDateString("en-US", {
  //     day: "2-digit",
  //     month: "2-digit",
  //     year: "numeric",
  //   })
  // }
  const likeMasterComment = async (commentId, action) => {
    if (currentUser) {
      const functions = getFunctions();
      const likesFunction = httpsCallable(functions, "likes100-likes");
      switch (action) {
        case "like":
          setCommentsLikeState(true);
          try {
            const result = await likesFunction({
              userId: currentUser.uid,
              commentId,
              action: "like",
            });
            console.log("Like operation successful", result.data);
            setCommentsLikeState(false);
            // Здесь вы можете обновить состояние компонента или выполнить другие действия после успешного вызова функции
          } catch (error) {
            console.error("Error while liking comment", error);
            setCommentsLikeState(true);
            // Обработка ошибки, если что-то пошло не так
          }
          break;
        case "dislike":
          setCommentsDislikeState(true);
          try {
            const result = await likesFunction({
              userId: currentUser.uid,
              commentId,
              action: "dislike",
            });
            console.log("Dislike operation successful", result.data);
            setCommentsDislikeState(false);
            // Обновите состояние компонента или выполните действия после успешного вызова функции
          } catch (error) {
            console.error("Error while disliking comment", error);
            setCommentsDislikeState(true);
            // Обработка ошибки
          }
          break;
        case "removeLike":
          setCommentsRemoveLikeState(true);
          try {
            const result = await likesFunction({
              userId: currentUser.uid,
              commentId,
              action: "remove",
            });
            console.log("Remove operation successful", result.data);
            setCommentsRemoveLikeState(false);
            // Обновите состояние компонента или выполните действия после успешного вызова функции
          } catch (error) {
            console.error("Error while remove", error);
            setCommentsRemoveLikeState(true);
            // Обработка ошибки
          }
          break;

        case "removeDislike":
          setCommentsRemoveDislikeState(true);
          try {
            const result = await likesFunction({
              userId: currentUser.uid,
              commentId,
              action: "remove",
            });
            console.log("Remove operation successful", result.data);
            setCommentsRemoveDislikeState(false);
            // Обновите состояние компонента или выполните действия после успешного вызова функции
          } catch (error) {
            console.error("Error while remove", error);
            setCommentsRemoveDislikeState(true);
            // Обработка ошибки
          }
          break;
        default:
          setCommentsRemoveDislikeState(false);
          setCommentsRemoveLikeState(false);
          setCommentsDislikeState(false);
          setCommentsLikeState(false);
          // Обработка неизвестных действий
          console.log(`Unknown action: ${action}`);
      }
    }
  };

  return loadingCommentsData ? (
    <>
      <Preloader />
    </>
  ) : (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "24px",
        }}
      >
        <div
          style={{
            boxSizing: "border-box",
            padding: "0 16px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            gap: "16px",
          }}
        >
          <h5>
            {filteredComments.length > 0
              ? "Added comments"
              : "Be the first to comment! Your feedback is important to us. Share your thoughts and help us improve. Thank you for your contribution!"}
          </h5>
        </div>
        {filteredComments.map((comments) => (
          <div
            key={comments.id}
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "var(--color-white)",
              padding: "24px",
              gap: "16px",
              borderRadius: "24px",
              width: "100%",
              boxSizing: "border-box",
            }}
          >
            {/* <WidgetRentalPointPreview
                    rentalPointId={comments.rentalPointId}
                  /> */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <RangeRating currntRange={comments.masterComment.grade} />

              <p className="p-light">
                {dateFormat(comments.masterComment.date.seconds * 1000)}
              </p>
            </div>

            <p
              style={{
                lineHeight: "1.3",
                color: "var(--color-gray-900)",
              }}
            >
              {comments.masterComment.comment}
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "8px",
                }}
              >
                <div
                  style={{
                    height: "40px",
                    aspectRatio: "1/1",
                    borderRadius: "80px",
                    backgroundColor: "var(--color-gray-100)",
                  }}
                ></div>
                <p className="p-light">
                  {comments.comments?.length > 0
                    ? `Reply to comment: ${comments.comments?.length ?? null}`
                    : "Rate this comment"}
                </p>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  // justifyContent: "space-between",
                  gap: "8px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <button
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "8px",
                      height: "32px",
                      padding: "0 16px",
                      borderRadius: " 80px",
                      border: "0",
                      backgroundColor: "var(--color-gray-100)",
                    }}
                    onClick={() => {
                      setAddParentComment(true);
                      setCurrentCommetnId(comments.id);
                      setCurrentCommetn(comments);
                      console.log(
                        "WigetViewComments-currentCommetn-comments",
                        comments
                      );
                    }}
                  >
                    Answer
                    <span
                      className="material-symbols-outlined"
                      style={{
                        fontSize: "1.2rem",
                      }}
                    >
                      add_comment
                    </span>
                  </button>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "var(--color-gray-100)",
                    borderRadius: "80px",
                    overflow: "hidden",
                  }}
                >
                  <button
                    title={
                      !currentUser?.uid && "You can rate after signing in."
                    }
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "8px",
                      height: "32px",
                      padding: "0 16px",
                      borderRadius: "80px 0 0 80px",
                      border: "0",
                      backgroundColor: comments.masterComment.likes?.includes(
                        currentUser?.uid
                      )
                        ? "var(--color-gray-200)"
                        : "var(--color-gray-100)",
                    }}
                    onClick={() => {
                      likeMasterComment(
                        comments.id,
                        comments.masterComment.likes?.includes(currentUser?.uid)
                          ? "removeLike"
                          : "like"
                      );
                    }}
                  >
                    {commentsLikeState || commentsRemoveLikeState ? (
                      <Preloader
                        style={{
                          height: "24px",
                          aspectRatio: "1/1",
                        }}
                      />
                    ) : (
                      <>
                        <span
                          className="material-symbols-outlined"
                          style={{
                            fontSize: "1.2rem",
                            opacity: currentUser?.uid ? "1" : "0.4",
                          }}
                        >
                          thumb_up
                        </span>
                      </>
                    )}
                    <p className="p-light">
                      {comments.masterComment.likes?.length ?? 0}
                    </p>
                  </button>

                  <div
                    style={{
                      height: "16px",
                      width: "1px",
                      backgroundColor:
                        comments.masterComment.dislikes?.includes(
                          currentUser?.uid
                        ) ||
                        comments.masterComment.likes?.includes(currentUser?.uid)
                          ? "transparent"
                          : "var(--color-gray-400)",
                    }}
                  />

                  <button
                    title={
                      !currentUser?.uid && "You can rate after signing in."
                    }
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "8px",
                      height: "32px",
                      padding: "0 16px",
                      borderRadius: " 0 80px 80px 0 ",
                      border: "0",
                      backgroundColor:
                        comments.masterComment.dislikes?.includes(
                          currentUser?.uid
                        )
                          ? "var(--color-gray-200)"
                          : "var(--color-gray-100)",
                    }}
                    onClick={() =>
                      likeMasterComment(
                        comments.id,
                        comments.masterComment.dislikes?.includes(
                          currentUser?.uid
                        )
                          ? "removeDislike"
                          : "dislike"
                      )
                    }
                  >
                    {commentsDislikeState || commentsRemoveDislikeState ? (
                      <Preloader
                        style={{
                          height: "24px",
                          aspectRatio: "1/1",
                        }}
                      />
                    ) : (
                      <>
                        <span
                          className="material-symbols-outlined"
                          style={{
                            fontSize: "1.2rem",
                            opacity: currentUser?.uid ? "1" : "0.4",
                          }}
                        >
                          thumb_down
                        </span>
                      </>
                    )}
                    <p className="p-light">
                      {comments.masterComment.dislikes?.length ?? 0}
                    </p>
                  </button>
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              {comments.comments &&
                comments.comments.map((comment) => (
                  <div
                    key={uuidv4()}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      backgroundColor: "var(--color-gray-100)",
                      padding: "24px",
                      borderRadius: "16px",
                      gap: "8px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <p className="p-light">
                        {dateFormat(comment?.date?.seconds * 1000)}
                      </p>
                      <RangeRating currntRange={comment.grade} />
                    </div>

                    <p
                      className="p-light"
                      style={{
                        lineHeight: "1.5",
                      }}
                    >
                      {comment.comment}
                    </p>
                  </div>
                ))}
            </div>
          </div>
        ))}
        {/* <div
          style={{
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "16px",
            width: "100%",
          }}
        ></div> */}
      </div>

      <WigetSelectedPopupV2
        img={
          "https://firebasestorage.googleapis.com/v0/b/biketripste.appspot.com/o/static%2FaddedComment4.jpg?alt=media&token=4c6e81bd-8a91-4bff-8fff-2ad8343b4b8f"
        }
        windowControlOpen={addParentComment}
        windowControlClose={setAddParentComment}
      >
        <div
          style={{
            padding: "32px",
            height: "100%",
            width: "100%",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              backgroundColor: "var(--color-gray-100)",
              padding: "8px",
              boxSizing: "border-box",
              borderRadius: "8px",
              position: "relative",
              // overflow: "hidden",
            }}
          >
            <div
              style={{
                position: "relative",
                maxHeight: "150px",
              }}
              className="scrollBox"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "8px 8px 16px 8px",
                }}
              >
                <p style={{ color: "var(--color-gray-900)" }}>
                  {dateFormat(
                    currentCommetn?.masterComment?.date?.seconds * 1000
                  )}
                </p>
                <RangeRating currntRange={4} />
              </div>

              <p
                className="p-light"
                style={{ padding: "0 8px 16px 8px", lineHeight: "1.4" }}
              >
                {currentCommetn?.masterComment?.comment}
              </p>
            </div>
            <div
              style={{
                position: "absolute",
                bottom: "8px",
                left: "0",
                width: "100%",
                height: "24px",
                background:
                  "linear-gradient(to bottom, rgba(242, 242, 247, 0), rgba(242, 242, 247, 1))",
              }}
            />
          </div>

          <WigetAddComments
            uid={uid}
            commentId={currentCommetnId}
            closePopupSatate={setAddParentComment}
          />
        </div>
      </WigetSelectedPopupV2>
    </>
  );
};

export default WigetViewComments;
