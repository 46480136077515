import React, { useEffect, useState } from "react";
import InputText from "../WidgetsProfile/Inputs/InputText";
import Textarea from "../WidgetsProfile/Textarea/Textarea";
import Button from "../WidgetsProfile/Buttons/Button";
import { useSelector, useDispatch } from "react-redux";

import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import { setSelectedBikeBasicInformation } from "../../../../../reducersToolkit/moderationSliceV2";

const WindowBikeBasicInformationModeration = ({ onClose, mode }) => {
  const dispatch = useDispatch();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [applyButtonActive, setApplyButtonActive] = useState(false);

  const { selectedBike, newBike } = useSelector(
    (state) => state.moderationSliceV2
  );

  useEffect(() => {
    const hasChanges = () => {
      return (
        selectedBike?.basicInformation?.title?.titleOriginal !== title ||
        selectedBike?.basicInformation?.description?.descriptionOriginal !==
          description
      );
    };

    setApplyButtonActive(hasChanges());
  }, [title, description, selectedBike]);

  useEffect(() => {
    if (mode === "moderation") {
      setTitle(selectedBike?.basicInformation?.title?.titleOriginal);
      setDescription(
        selectedBike?.basicInformation?.description?.descriptionOriginal
      );
    } else if (mode === "moderatorAdded") {
      setTitle(newBike?.basicInformation?.title?.titleOriginal);
      setDescription(
        newBike?.basicInformation?.description?.descriptionOriginal
      );
    }
  }, [mode, selectedBike, newBike]);

  const applyButton = () => {
    dispatch(
      setSelectedBikeBasicInformation({
        mode: mode,
        title: title,
        description: description,
      })
    );

    onClose();
  };

  const cancelButton = () => {
    onClose();
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "8px",
      }}
    >
      <ContentBox position="start">
        <InputText
          label="Bike name"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          value={title}
          onChange={(event) => setTitle(event.target.value)}
        />

        <Textarea
          label="Bike description"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          maxLength={1000}
          value={description}
          onChange={(event) => setDescription(event.target.value)}
        />
      </ContentBox>

      <ContentBox position="end">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "16px",
            width: "100%",
          }}
        >
          <Button
            label="Cancel"
            active={true}
            color="--color-black"
            onClick={cancelButton}
          />
          <Button
            label="Apply"
            active={applyButtonActive}
            onClick={applyButton}
          />
        </div>
      </ContentBox>
    </div>
  );
};

export default WindowBikeBasicInformationModeration;
