import React from "react";

const Loading = ({ title }) => {
  return (
    <div className="popup-overlay">
      <img
        src="https://firebasestorage.googleapis.com/v0/b/bikestripster.appspot.com/o/ui%2FRolling-1s-40px.svg?alt=media&token=e580246b-0f05-4e9c-8712-506cf21d4451"
        alt=""
        style={{
          width: "40px",
          height: "40px",
        }}
      />
    </div>
  );
};

export default Loading;
