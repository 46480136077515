import React from "react";

const WigetTitle = ({ title, description, children }) => {
  return (
    <div className="main-content-box">
      <div className="content-box">
        <div className="wigetNews-box">
          <div className="wigetNews-content">
            <h1
              className="header"
              style={{
                fontSize: "3em",
                fontWeight: "300",
                textAlign: "left",
                lineHeight: "3rem",
              }}
            >
              {title}
            </h1>
          </div>
        </div>

        <p>{description}</p>
        {children}
      </div>
    </div>
  );
};

export default WigetTitle;
