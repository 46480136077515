import React from "react";
import GoogleMaps from "../../UIComponents/maps/GoogleMaps";
import { useSelector } from "react-redux";
import GoogleMapsFilters from "../../UIComponents/maps/GoogleMapsFilters";
import Preloader from "../../UIElements/Preloader";

const Timetable = ({ timetableData }) => {
  console.log("Timetable-timetableData", timetableData);
  const currentBike = useSelector(
    (state) => state.stateFrontGUISlice.currentBike
  );
  const isLoading = useSelector((state) => state.stateFrontGUISlice.isLoading);

  return (
    <div
      style={{
        gridColumn: "1",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "24px",
        gap: "8px",
        boxSizing: "border-box",
        height: "100%",
      }}
    >
      {isLoading ? (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Preloader />
        </div>
      ) : (
        <>
          <h5>Timetable</h5>
          {timetableData?.map((item, index) => (
            <div key={index} className="wigetProdictCard-schedule-box">
              <div className="wigetProdictCard-schedule-day">
                <p>{item.weekday}:</p>
              </div>

              <div className="wigetProdictCard-schedule-time">
                {item.holiday ? (
                  <p className="wigetProdictCard-schedule-holiday">holiday</p>
                ) : (
                  <>
                    <p style={{ fontWeight: "600" }}>
                      {item.open}&nbsp;:&nbsp;{item.close}
                    </p>
                  </>
                )}
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default Timetable;
