import React, { useEffect } from "react";
import { useSubscribeData } from "../../../../../services/firebase/crudFirebaseStorage";
import WigetBikeDetail from "./WigetBikes/WigetBikeDetail";
import WigetBikesRentalPointList from "./WigetBikes/WigetBikesRentalPointList";
import WigetBikesList from "./WigetBikes/WigetBikesList";
import { useSelector } from "react-redux";
import WigetProportions from "../../../../UIComponents/proportions/WigetProportions";
import { useDispatch } from "react-redux";
import { setCurrentUsersRentalPoints } from "../../../../../slice/stateGUISlice";

const UIBikes = () => {
  const dispatch = useDispatch();

  // Получаем данные о пользователе"
  const currentUser = useSelector((state) => state.userSliceV2.currentUser);
  const userId = currentUser ? currentUser.uid : null;

  // Получаем профиль пользователя
  const { data: userData, isLoading: loadingUserData } = useSubscribeData(
    "user",
    userId
  );

  // Получаем список точек проката
  const { data: rentalPointsData, isLoading: loadingRentalPointsData } =
    useSubscribeData("rentalPoints");

  // Фильтруем точки проката так, чтобы получить прокаты текущего пользователя
  useEffect(() => {
    if (!loadingRentalPointsData) {
      const filteredData =
        rentalPointsData &&
        rentalPointsData.filter((item) =>
          userData?.rentalPoints_id?.includes(item.id)
        );
      dispatch(setCurrentUsersRentalPoints(filteredData));
    }
  }, [rentalPointsData, userData, loadingRentalPointsData, dispatch]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        // gap: '16px',
        flex: "1",
        backgroundColor: "var(--color-black)",
        boxSizing: "border-box",
        // width: 'calc(100vw - 330px)',
        width: "100%",
        // height: 'calc(100vh - 32px)',
        height: "100%",
        overflowY: "hidden",
      }}
    >
      <div
        style={{
          width: "500px",
          height: "100%",
        }}
      >
        <WigetBikesRentalPointList />
      </div>

      <div
        style={{
          width: "calc(100% - 500px)",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          id="idBikesList"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "space-between",
            boxSizing: "border-box",
            overflow: "hidden",
            resize: "horizontal",
            width: "60%",
            height: "100%",
          }}
        >
          <WigetBikesList />
        </div>

        <WigetProportions idBlock="idBikesList" />

        <div
          style={{
            backgroundColor: "var(--color-gray-100)",
            flex: "1",
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          <WigetBikeDetail />
        </div>
      </div>
    </div>
  );
};

export default UIBikes;
