import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import WigetSelectedPopup from "./WigetSelectedPopup";
import Preloader from "../../UIElements/Preloader";
import { setCurrentDateInterval } from "../../../slice/stateFrontGUISlice";
import { useDispatch } from "react-redux";

const WigetDateInterval = ({ type }) => {
  const dispath = useDispatch();

  const currentDateInterval = useSelector(
    (state) => state.stateFrontGUISlice.currentDateInterval
  );

  const defaultCountry = useSelector(
    (state) => state.stateFrontGUISlice.defaultCountry
  );

  const defaultCity = useSelector(
    (state) => state.stateFrontGUISlice.defaultCity
  );

  const isLoading = useSelector((state) => state.stateFrontGUISlice.isLoading);

  const [activePopup, setActivePopup] = useState(null);
  const [dates, setDates] = useState([]);

  // Обработчик открытия попапа.
  const handleOpenPopup = (popupName) => {
    setActivePopup(popupName);
  };

  // Обработчик закрытия попапа.
  const handleClosePopup = () => {
    setActivePopup(null);
  };

  // Эффект для обновления dates когда defaultDateInterval изменяется
  useEffect(() => {
    const startDate = new Date();
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + 7);
    if (currentDateInterval[0] && currentDateInterval[1]) {
      setDates([
        new Date(currentDateInterval[0]),
        new Date(currentDateInterval[1]),
      ]);
    } else {
      setDates([startDate, endDate]);
    }
  }, []);

  // Отдельный эффект для диспатча setCurrentDateInterval
  useEffect(() => {
    if (dates[0] && dates[1]) {
      dispath(setCurrentDateInterval(dates.map((date) => date.getTime())));
    }
  }, [dispath, dates]);

  const renderDatePopup = () => (
    <WigetSelectedPopup
      isOpen={activePopup === "dateInterval"}
      // img={countryPhoto}
      // img={""}
      // flag={countryFlag}
      //   map={map}
      onClose={() => {
        handleClosePopup();
        setActivePopup(null); // Сбрасываем активный попап
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: " 36px",
          height: "100%",
          boxSizing: "border-box",
          gap: "32px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "24px",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              gap: "16px",
              width: "100%",
            }}
          >
            <h3
              style={{
                width: "100%",
                textAlign: "start",
              }}
            >
              Select a country and city to search
            </h3>

            <p className="p-light">
              Please indicate the dates for which you would like to rent a bike
            </p>
          </div>

          <div
            style={{
              borderTop: "1px var(--color-gray-100) solid",
              borderBottom: "1px var(--color-gray-100) solid",
              padding: "24px 0",
            }}
          >
            <DatePicker
              selected={dates[0]}
              onChange={(date) => {
                setDates(date); // вызов первой функции
                // dispath(setCurrentDateInterval(date)); // вызов второй функции
              }}
              startDate={dates[0]}
              endDate={dates[1]}
              selectsRange
              inline
              monthsShown={2}
              minDate={new Date()}
            />
          </div>

          <div className="popup-button-bur">
            <button className="popupButtonBorder" onClick={handleClosePopup}>
              Cancel
            </button>

            {defaultCountry.title && defaultCity.title ? (
              <Link
                to="/catalog"
                className="popupButton"
                type="submit"
                onClick={handleClosePopup}
              >
                View stories
              </Link>
            ) : (
              <button
                className="popupButton"
                // onClick={handleClosePopup}
                onClick={() => handleOpenPopup(`popu_country`)}
              >
                Select country and city
              </button>
            )}
          </div>
        </div>
      </div>
    </WigetSelectedPopup>
  );

  return type === "input" ? (
    <>
      {renderDatePopup()}
      <div
        style={{
          flex: "1",
          display: "flex",
          flexDirection: "row",
          gap: "8px",
          backgroundColor: "var(--color-gray-100)",
          padding: "8px",
          borderRadius: "8px",
          transition: "background-color 0.5s ease",
          cursor: "pointer",
          height: "100%",
          width: "100%",
          backdropFilter: "blur(10px)",
          boxSizing: "border-box",
        }}
        onClick={() => handleOpenPopup("dateInterval")}
      >
        {isLoading ? (
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Preloader />
          </div>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                flex: "1",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "8px",
                gap: "8px",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "16px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "8px",
                  }}
                >
                  <span className="material-symbols-outlined">flag</span>
                  <p
                    style={{
                      fontSize: "1rem",
                      color: "var(--color-gray-800)",
                    }}
                  >
                    Start rental day:&nbsp;
                    {dates[0] ? new Date(dates[0]).getDate() : "no"}&nbsp;{" "}
                    {dates[0]
                      ? new Date(dates[0]).toLocaleString("en-US", {
                          month: "long",
                        })
                      : "Month"}
                  </p>
                </div>
                <p>—</p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "8px",
                  }}
                >
                  <span className="material-symbols-outlined">flag</span>
                  <p
                    style={{
                      fontSize: "1rem",
                      color: "var(--color-gray-800)",
                    }}
                  >
                    End&nbsp;rental&nbsp;day:&nbsp;
                    {dates[1] ? new Date(dates[1]).getDate() : "no"}&nbsp;{" "}
                    {dates[1]
                      ? new Date(dates[1]).toLocaleString("en-US", {
                          month: "long",
                        })
                      : "Month"}
                  </p>
                </div>
              </div>
              <span className="material-symbols-outlined">calendar_today</span>
            </div>
          </>
        )}
      </div>
    </>
  ) : (
    <>
      {renderDatePopup()}
      <div
        style={{
          flex: "1",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          backgroundColor: "var(--color-gray-100)",
          padding: "8px",
          borderRadius: "16px",
          transition: "background-color 0.5s ease",
          cursor: "pointer",
          height: "100%",
          backdropFilter: "blur(10px)",
          boxSizing: "border-box",
        }}
      >
        {isLoading ? (
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Preloader />
          </div>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                flex: "1",
                flexDirection: "column",
                justifyContent: "center",
                // backgroundColor: 'var(--color-white)',
                borderRadius: "8px",
                padding: "8px",
                gap: "8px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  flex: "1",
                }}
                onClick={() => handleOpenPopup("dateInterval")}
              >
                <p
                  style={{
                    fontSize: "0.8rem",
                    color: "var(--color-gray-900)",
                  }}
                >
                  Start&nbsp;rental&nbsp;day
                </p>
                <div
                  style={{
                    fontSize: "4rem",
                    fontWeight: "700",
                    color: "var(--color-black)",
                  }}
                >
                  {dates[0] ? new Date(dates[0]).getDate() : "no"}
                </div>
                <div>
                  {dates[0]
                    ? new Date(dates[0]).toLocaleString("en-US", {
                        month: "long",
                      })
                    : "Month"}
                </div>
              </div>

              <div
                style={{
                  height: "8px",
                  width: "100%",
                  backgroundColor: "var(--color-black)",
                  borderRadius: "80px",
                }}
              />

              <div
                className="wigetSearch-horisontal-startDay"
                onClick={() => handleOpenPopup(`dateInterval`)}
              >
                <p
                  style={{
                    fontSize: "0.8rem",
                    color: "var(--color-gray-900)",
                  }}
                >
                  End rental day
                </p>
                <div
                  style={{
                    fontSize: "4rem",
                    fontWeight: "700",
                    color: "var(--color-black)",
                  }}
                >
                  {dates[1] ? new Date(dates[1]).getDate() : "no"}
                </div>
                <div>
                  {dates[1]
                    ? new Date(dates[1]).toLocaleString("en-US", {
                        month: "long",
                      })
                    : "Month"}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default WigetDateInterval;
