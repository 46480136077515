import { useRef, useState } from "react";
import SearchBars from "../../../../../UIComponents/bars/searchBars/SearchBars";
import Icon44 from "../../../../../UIComponents/icons/Icon44";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { setCurrentRentalPoint, setAllOrders } from "../../../../../../slice/stateOrdersSlice"
import ImageWithLoader from "../../../../../UIComponents/bars/gallery/ImageWithLoader";
import {
  setPrivateSideBar,
  setCurrentRentalPoint,
  setAllOrders,
} from "../../../../../../slice/stateGUISlice";
import { useSubscribeData } from "../../../../../../services/firebase/crudFirebaseStorage";

const WigetOrderRentalPointList = () => {
  const dispatch = useDispatch();
  const widthAllOrderIcon = 120;

  const [allOrderItems, setAllOrderItems] = useState(0);

  const refAllOrdersBox = useRef(null);

  const { data: ordersData, isLoading: loadingOrdersData } =
    useSubscribeData("orders");

  // Локальное состояние компонента (Исключая точки проката с нулевыми координатами)
  const [
    filteredCurrentUsersRentalPoints,
    setFilteredCurrentUsersRentalPoints,
  ] = useState(null);

  const [selectedItemIndex, setSelectedItemIndex] = useState(null);

  const currentRentalPoint = useSelector(
    (state) => state.stateGUISlice.currentRentalPoint
  );

  const idCurrentRentalPoint = useSelector(
    (state) => state?.stateGUISlice?.currentRentalPoint?.id
  );

  const currentUsersRentalPoints = useSelector(
    (state) => state.stateGUISlice.currentUsersRentalPoints
  );
  const allOrdersButton = useSelector((state) => state.stateGUISlice.allOrders);

  useEffect(() => {
    // Устанавливаем allOrdersButton в true при монтировании компонента
    dispatch(setAllOrders(true));

    // Очищаем значение allOrdersButton при размонтировании компонента
    return () => {
      dispatch(setAllOrders(false));
    };
  }, [dispatch]);

  useEffect(() => {
    const getContainerWidth = () => {
      const gap = 8;
      const containerWidth =
        refAllOrdersBox.current.getBoundingClientRect().width;

      // Расчет количества элементов в зависимости от ширины контейнера
      const itemCountPer100px = Math.floor(containerWidth / widthAllOrderIcon);
      const remainingItems =
        itemCountPer100px - filteredRentalPointsWithOrders.length;

      // Рассчитываем промежуток между элементами и общую ширину элементов
      const gapWidth = remainingItems * gap;
      const totalItemsWidth = containerWidth - gapWidth;

      // Рассчитываем окончательное количество элементов
      const finalItemCount = Math.floor(totalItemsWidth / widthAllOrderIcon);

      setAllOrderItems(finalItemCount);

      console.log("Width of the div:", Math.round(containerWidth));
      console.log("Final Item Count:", finalItemCount);
    };

    // Вызываем функцию при загрузке компонента и при изменении размеров окна
    getContainerWidth();
    window.addEventListener("resize", getContainerWidth);

    // Удаляем слушатель события при размонтировании компонента
    return () => {
      window.removeEventListener("resize", getContainerWidth);
    };
  }, []);

  // Эффект для управления активной точкой аренды
  useEffect(() => {
    if (currentUsersRentalPoints && currentUsersRentalPoints.length > 0) {
      // Если выбранная точка аренды уже сохранена, установите её
      const selectedPoint = currentUsersRentalPoints.find(
        (point) => point.id === idCurrentRentalPoint
      );

      if (selectedPoint) {
        dispatch(setCurrentRentalPoint(selectedPoint));
      } else {
        // В противном случае, установите первую точку аренды
        dispatch(setCurrentRentalPoint(currentUsersRentalPoints[0]));
      }
    }
  }, [currentUsersRentalPoints, idCurrentRentalPoint, dispatch]);

  // Эффект для фильтрации точек аренды с нулевыми координатами
  useEffect(() => {
    if (currentUsersRentalPoints && currentUsersRentalPoints.length > 0) {
      const filteredPoints = currentUsersRentalPoints.filter(
        (point) => point.pointAddress.location.lat !== null
      );
      setFilteredCurrentUsersRentalPoints(filteredPoints);
    }
  }, [currentUsersRentalPoints]);

  // Определяем index того элемента по котором был осуществлен клик
  const currentIndex = () => {
    const index = filteredCurrentUsersRentalPoints.findIndex(
      (bike) => bike.id === idCurrentRentalPoint
    );

    if (index !== -1) {
      console.log(
        `Элемент с ID ${idCurrentRentalPoint} найден, его индекс: ${index}`
      );
    } else {
      console.log(`Элемент с ID ${idCurrentRentalPoint} не найден в массиве.`);
    }
  };

  // Создаем пустой объект для хранения количества заказов по rentalPoints_id
  const quantity = {};

  // Проверяем наличие данных в ordersData
  if (ordersData && Array.isArray(ordersData)) {
    // Проходим по каждому заказу в массиве ordersData
    console.log("ordersQuantity-ordersData", ordersData);
    ordersData.forEach((order) => {
      const rentalPointId = order.order_info.rentalPoints_id;
      console.log("ordersQuantity-rentalPointId", rentalPointId);
      // Если rentalPoints_id уже есть в quantity, увеличиваем количество
      if (quantity[rentalPointId]) {
        quantity[rentalPointId]++;
      } else {
        // Если rentalPoints_id нет в quantity, инициализируем его с 1
        quantity[rentalPointId] = 1;
      }
    });
  }

  // else {
  //     console.error("ordersData is missing or not an array:", ordersData);
  // }

  // Преобразуем объект quantity в массив объектов ordersQuantity
  const ordersQuantity = Object.entries(quantity).map(
    ([rentalPoints_id, quantityOrder]) => ({
      rentalPoints_id,
      quantity: quantityOrder,
    })
  );

  // Виджет полного списка точек проката и количество заказов в каждой и общее
  const TotlalOrderItemList = () => {
    return (
      <div
        style={{
          padding: "8px",
        }}
      >
        <div
          style={{
            overflow: "hidden",
            borderRadius: "8px",
            backgroundColor:
              allOrdersButton === true
                ? "var(--color-primary)"
                : "var(--color-gray-200)",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
          onClick={() => dispatch(setAllOrders(true))}
        >
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "space-between",
              height: "40px",
              padding: "0 16px",
            }}
          >
            <h5
              style={{
                color: allOrdersButton
                  ? "var(--color-white)"
                  : "var(--color-black)",
              }}
            >
              Orders from all rental points
            </h5>
            <TotalOrderQuantity />
          </div>

          <div
            style={{
              padding: "16px",
              // gridColumn: 'span 2',
              // aspectRatio: '2/1',
              display: "grid",
              gridTemplateColumns: `repeat(auto-fill, minmax(${widthAllOrderIcon}px, 1fr))`,
              width: "100%",
              gap: "8px",
              boxSizing: "border-box",
            }}
          >
            {filteredRentalPointsWithOrders.map((rentalPointItem, index) => (
              <>
                <div
                  style={{
                    width: "100%",
                    aspectRatio: "5/6",
                    position: "relative",
                    backgroundColor: "var(--color-white)",
                    borderRadius: "8px",
                    flex: "1",
                  }}
                  key={rentalPointItem.id}
                >
                  <div
                    style={{
                      width: "100%",
                      padding: "16px 16px 0 16px",
                      boxSizing: "border-box",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        aspectRatio: "1/1",
                        borderRadius: "24px",
                        overflow: "hidden",
                        boxSizing: "border-box",
                      }}
                    >
                      <ImageWithLoader
                        url={rentalPointItem?.file_logo}
                        alt={"PreviewRentalPoint"}
                      />

                      <div
                        style={{
                          position: "absolute",
                          top: "4px",
                          right: "4px",
                          boxShadow: "0 1px 4px rgba(0, 0, 0, 0.2)",
                          borderRadius: "80px",
                        }}
                      >
                        <OrderQuantity
                          rentalPointItemId={rentalPointItem.id}
                          size="32px"
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      height: "48px",
                      padding: "0 8px",
                      boxSizing: "border-box ",
                    }}
                  >
                    <p
                      style={{
                        textAlign: "center",
                        lineHeight: "1",
                      }}
                      className="p-light"
                    >
                      {rentalPointItem.title[0]
                        ? rentalPointItem.title
                        : "No name"}
                    </p>
                  </div>
                </div>
              </>
            ))}

            {Array.from({ length: allOrderItems - 1 }, (_, index) => (
              <div
                key={index}
                style={{
                  width: "100%",
                  aspectRatio: "5/6",
                  position: "relative",
                  backgroundColor: "var(--color-white)",
                  borderRadius: "8px",
                  opacity: "0.2",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    padding: "16px 16px 0 16px",
                    boxSizing: "border-box",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      aspectRatio: "1/1",
                      borderRadius: "24px",
                      overflow: "hidden",
                      boxSizing: "border-box",
                      backgroundColor: "var(--color-gray-100)",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "4px",
                        right: "4px",
                        // boxShadow: '0 1px 4px rgba(0, 0, 0, 0.2)',
                        borderRadius: "80px",
                        backgroundColor: "var(--color-gray-200)",
                        minHeight: "32px",
                        minWidth: "32px",
                      }}
                    ></div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      height: "48px",
                    }}
                  >
                    <div
                      style={{
                        height: "8px",
                        width: "80%",
                        backgroundColor: "var(--color-gray-200)",
                        borderRadius: "80px",
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  // Отображение колличества заказов у каждой точки проката
  const OrderQuantity = ({ rentalPointItemId, size = "24px" }) => {
    const quantityOrder = ordersQuantity.find(
      (order) => order.rentalPoints_id === rentalPointItemId
    );

    return (
      quantityOrder &&
      quantityOrder.quantity && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: `${size}`,
            minWidth: `${size}`,
            padding: "0, 8px",
            boxSizing: "border-box",
            backgroundColor: "var(--color-alarm)",
            borderRadius: "80px",
          }}
        >
          <p
            style={{
              color: "var(--color-white)",
            }}
            className="p-light"
          >
            {quantityOrder && quantityOrder.quantity}
          </p>
        </div>
      )
    );
  };

  // Общее количество совпадений в заказе
  const totalMatchingCount = filteredCurrentUsersRentalPoints
    ? filteredCurrentUsersRentalPoints.reduce((total, rentalPoint) => {
        const matchingOrder = ordersQuantity.find(
          (order) => order.rentalPoints_id === rentalPoint.id
        );
        return total + (matchingOrder ? matchingOrder.quantity : 0);
      }, 0)
    : 0;

  // Отображение колличества заказов у каждой точки проката
  const TotalOrderQuantity = () => {
    return totalMatchingCount > 0 ? (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "24px",
          minWidth: "24px",
          padding: "0 8px",
          boxSizing: "border-box",
          backgroundColor: "var(--color-alarm)",
          borderRadius: "80px",
        }}
      >
        <p
          style={{
            color: "var(--color-white)",
          }}
          className="p-light"
        >
          {totalMatchingCount && totalMatchingCount}
        </p>
      </div>
    ) : null;
  };

  // Фильтруем filteredCurrentUsersRentalPoints так, чтобы остались только те item у которых есть заказы
  const filteredRentalPointsWithOrders = filteredCurrentUsersRentalPoints
    ? filteredCurrentUsersRentalPoints.filter((rentalPointItem) => {
        return ordersQuantity.some(
          (order) => order.rentalPoints_id === rentalPointItem.id
        );
      })
    : [];

  return (
    <div
      ref={refAllOrdersBox}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        height: "100%",
        width: "100%",
        boxSizing: "border-box",
        backgroundColor: "var(--color-white)",
      }}
    >
      <SearchBars
        title="Rental points"
        iconLeft={
          <Icon44
            click={() => dispatch(setPrivateSideBar(true))}
            icon={
              <span className="material-symbols-outlined">dock_to_right</span>
            }
          />
        }
        iconRight={<div />}
      />

      <div
        style={{
          height: "100%",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-start",
        }}
        className="scrollBox"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
          className="itemScroll"
        >
          <TotlalOrderItemList />

          {filteredCurrentUsersRentalPoints &&
            filteredCurrentUsersRentalPoints.length > 0 &&
            filteredCurrentUsersRentalPoints.map((rentalPointItem, index) => (
              <div
                key={rentalPointItem.id}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  padding: "0 8px",
                  boxSizing: "border-box",
                }}
                className="itemScroll"
                onClick={() => {
                  const orderExists = ordersQuantity.find(
                    (order) => order.rentalPoints_id === rentalPointItem.id
                  );
                  if (orderExists) {
                    console.log("rentalPointItem", rentalPointItem);
                    dispatch(setCurrentRentalPoint(rentalPointItem));
                    setSelectedItemIndex(index);
                    // currentIndex()
                    dispatch(setAllOrders(false));
                  }
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    gap: "16px",
                    backgroundColor:
                      idCurrentRentalPoint === rentalPointItem.id &&
                      index === selectedItemIndex &&
                      allOrdersButton === false
                        ? "var(--color-primary)"
                        : "var(--color-white)",
                    borderRadius: "8px",
                  }}
                >
                  <div
                    style={{
                      width: "100px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "0 0 0 8px",
                    }}
                  >
                    <div
                      style={{
                        width: "80px",
                        aspectRatio: "1/1",
                        borderRadius: "24px",
                        overflow: "hidden",
                        boxSizing: "border-box",
                        opacity: ordersQuantity.find(
                          (order) =>
                            order.rentalPoints_id === rentalPointItem.id
                        )
                          ? "1"
                          : "0.1",
                      }}
                    >
                      <ImageWithLoader
                        url={rentalPointItem?.file_logo}
                        alt={"PreviewRentalPoint"}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                      height: "100px",
                      borderBottom:
                        (index === selectedItemIndex ||
                          index === selectedItemIndex - 1) &&
                        allOrdersButton === false
                          ? "1px rgba(255,255, 255, 0) solid"
                          : "1px var(--color-gray-200) solid",
                      color:
                        idCurrentRentalPoint === rentalPointItem.id &&
                        index === selectedItemIndex &&
                        allOrdersButton === false &&
                        "var(--color-white)",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "center",
                        opacity: ordersQuantity.find(
                          (order) =>
                            order.rentalPoints_id === rentalPointItem.id
                        )
                          ? "1"
                          : "0.3",
                      }}
                    >
                      <h5
                        style={{
                          color:
                            idCurrentRentalPoint === rentalPointItem.id &&
                            index === selectedItemIndex &&
                            allOrdersButton === false &&
                            "var(--color-white)",
                        }}
                      >
                        {rentalPointItem.title[0]
                          ? rentalPointItem.title
                          : "No name"}
                      </h5>

                      <p
                        style={{
                          color:
                            idCurrentRentalPoint === rentalPointItem.id &&
                            index === selectedItemIndex &&
                            allOrdersButton === false &&
                            "var(--color-white)",
                        }}
                        className="p-light"
                      >
                        Quantity bike: {rentalPointItem.bikes_id.length}
                      </p>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        opacity: ordersQuantity.find(
                          (order) =>
                            order.rentalPoints_id === rentalPointItem.id
                        )
                          ? "1"
                          : "0.1",
                      }}
                    >
                      <OrderQuantity rentalPointItemId={rentalPointItem.id} />
                      <span className="material-symbols-outlined">
                        chevron_right
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
export default WigetOrderRentalPointList;
