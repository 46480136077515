// Импорт необходимых зависимостей
import React, { useState, useEffect } from "react";
import "firebase/compat/firestore";
import WigetButtonAdd from "../../../../../../UniversalWidgets/WigetButtonAdd";
import WigetButtonDelete from "../../../../../../UniversalWidgets/WigetButtonDelete";
import { useSubscribeData } from "../../../../../../../services/firebase/crudFirebaseStorage";
import Popup from "../../../../../../UIElements/Popup";

import Loading from "../../../../../../UIElements/Loading";
import { useDispatch } from "react-redux";
import PreviewGallery from "../../../../../../UIComponents/bars/gallery/PreviewGallery";
import { WigetInputV2 } from "../../../../../../UniversalWidgets/WigetInputV2";
import { WigetTextareaV2 } from "../../../../../../UniversalWidgets/WigetTextareaV2";
import ImageWithLoader from "../../../../../../UIComponents/bars/gallery/ImageWithLoader";
import Radio from "../../../../../../UIElements/Radio";
import { uuidv4 } from "@firebase/util";

export const WigetBrandDetail = ({
  title,
  collectionName,
  structureCollection,
}) => {
  const dispatch = useDispatch();
  // Используем наш кастомный хук для подписки на данные Firestore и отслеживания состояния загрузки
  const { data: dataList, isLoading: loadingState } =
    useSubscribeData(collectionName);

  return (
    <div className="private_profile-mainBox">
      {/* <div className='item-menu'>
                <div className='item-menu-content'>
                    <div className='item-menu-content-left'>
                        <Icon44
                            click={() => dispatch(setPrivateSideBar(true))}
                            icon={
                                <span className="material-symbols-outlined">
                                    dock_to_right
                                </span>
                            }
                        />
                    </div>
                    <div className='item-menu-content-right'>
                        <WigetButtonAdd
                            collectionName={collectionName}
                            // dataObject={structureCollection}
                            nameButton={`Добавить валюту`}
                        />
                    </div>
                </div>
            </div> */}

      {/* Если данные все еще загружаются, отображаем сообщение "Loading..." */}
      {/* Как только данные загружены, отображаем список брендов */}
      {loadingState ? (
        <Loading />
      ) : (
        dataList.map((directoryItem, index) => (
          <div
            // className='directory'
            key={directoryItem.id}
          >
            <div className="rentalPoints_content-box">
              <div
                className="rentalPoints_content-box-left"
                style={{ width: "50%" }}
              >
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <PreviewGallery
                    aspectRatio="16/9"
                    title="Icon brand"
                    collectionName={collectionName}
                    idDocument={directoryItem.id}
                    fieldName="file_icon"
                    gallery={true}
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                  />
                </div>
              </div>

              <div
                className="rentalPoints_content-box-right"
                style={{ width: "50%" }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <WigetInputV2
                    title="Brand name"
                    collectionName={collectionName}
                    idDocument={directoryItem.id}
                    fieldName="title"
                    quantity={1}
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                  />

                  <WigetTextareaV2
                    title="Description"
                    maxLength={1000}
                    collectionName={collectionName}
                    idDocument={directoryItem.id}
                    fieldName="description"
                    quantity={1}
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                  />

                  <div
                    style={{
                      padding: "0 16px",
                    }}
                  >
                    <div className="wigetButtonDelete-imform">
                      <div className="wigetButtonDelete-imform-left">
                        <p className="p-light">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Fusce eleifend ipsum vitae nunc gravida
                          facilisis.
                        </p>
                      </div>

                      <div className="wigetButtonDelete-imform-right">
                        <WigetButtonDelete
                          collectionName={collectionName}
                          documentId={directoryItem.id}
                          title="Удалить"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export const WigetBrandCheck = ({
  title,
  collectionName,
  parentCollectionName,
  documentId,
  description,
}) => {
  // Состояние активного попапа
  const [activePopup, setActivePopup] = useState(null);

  // Состояние выбранных брендов
  const [selectedBrands, setSelectedBrands] = useState([]);

  // Получение данных о брендах из коллекции
  const { data } = useSubscribeData(collectionName);

  // Получение данных родительского документа
  const { data: documentParentCollectionName, isLoading } = useSubscribeData(
    parentCollectionName,
    documentId
  );

  useEffect(() => {
    if (documentParentCollectionName?.currency_id) {
      setSelectedBrands(documentParentCollectionName.currency_id);
    }
  }, [documentParentCollectionName]);

  /**
   * Обработчик открытия попапа.
   * @param {string} popupName - Имя попапа.
   */
  const handleOpenPopup = (popupName) => {
    setActivePopup(popupName);
  };

  /**
   * Обработчик закрытия попапа.
   */
  const handleClosePopup = () => {
    setActivePopup(null);
  };

  /**
   * Обработчик изменения состояния чекбокса.
   * @param {string} brandId - Идентификатор бренда.
   */
  const handleCheckboxChange = (brandId) => {
    // const updatedSelectedBrands = selectedBrands.includes(brandId)
    //   ? selectedBrands.filter((id) => id !== brandId)
    //   : [...selectedBrands, brandId];
    // setSelectedBrands(updatedSelectedBrands);

    setSelectedBrands(brandId);
  };

  // Фильтруем список брендов на основе выбранных брендов и наличия данных родительского документа
  const filteredData =
    data && documentParentCollectionName?.currency_id
      ? data.filter((directoryItem) =>
          documentParentCollectionName.currency_id.includes(directoryItem.id)
        )
      : [];

  // Возвращаем JSX-элемент компонента
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "0 16px",
          boxSizing: "border-box",
        }}
      >
        <div
          style={{
            backgroundColor: "var(--color-white)",
            boxSizing: "border-box",
            borderRadius: "8px",
            overflow: "hidden",
          }}
          onClick={() => handleOpenPopup(`popu_${title}`)}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              height: "48px",
              padding: "0 16px",
              // backgroundColor: 'violet',
              ...(filteredData.length > 0 && {
                borderBottom: "1px var(--color-gray-200) solid",
              }),
            }}
          >
            <p
              style={{
                textTransform: "uppercase",
              }}
              className="p-light"
            >
              {title}
            </p>

            <button onClick={() => handleOpenPopup(`popu`)}>
              {filteredData.length > 0 ? "Edit" : "Add"}
            </button>
          </div>

          {filteredData.map((directoryItem, index) => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                marginLeft: "16px",
                boxSizing: "border-box",
                height: "48px",
                ...(index === filteredData.length - 1
                  ? {
                      borderBottom: "none",
                    }
                  : {
                      borderBottom: "1px var(--color-gray-200) solid",
                    }),
              }}
              key={index}
              onClick={() => handleOpenPopup(`popu_${title}`)}
            >
              <div className="inform-box-content-left">
                {directoryItem.title}
              </div>

              <div className="inform-box-content-right">
                <span
                  style={{
                    color: "var(--color-gray-400)",
                  }}
                  className="material-symbols-outlined"
                >
                  chevron_right
                </span>
              </div>
            </div>
          ))}
        </div>
        {description && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "8px 16px",
            }}
          >
            <p className="p-light">{description}</p>
          </div>
        )}
      </div>

      {activePopup === `popu_${title}` && (
        <Popup
          isOpen={true}
          onClose={handleClosePopup}
          title={title}
          leftMenu={<></>}
          rightMenu={
            <>
              <WigetButtonAdd
                collectionName={parentCollectionName}
                documentId={documentId}
                nameButton="Выбрать"
                dataObject={{
                  currency_id: selectedBrands,
                }}
              />
            </>
          }
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eleifend ipsum vitae nunc gravida facilisis. Pellentesque finibus, justo id tincidunt accumsan, mauris elit consequat nulla, vel tempus mi lorem vel ipsum. Integer id tincidunt sapien. Cras faucibus euismod massa eget interdum. Sed commodo, ante eu tempor condimentum, elit dui lacinia nisi, in pulvinar enim enim ut orci. Suspendisse potenti."
        >
          <div className="inform-box-inputItem">
            <div className="inform-box">
              <div className="inform-box-header">{title}</div>
              <div className="inform-box-item">
                {isLoading ? (
                  <div>Loading...</div>
                ) : (
                  data.map((dataItem) => (
                    <>
                      <div key={uuidv4()} className="inform-box-content">
                        <div className="inform-box-content-left">
                          {/* <Checkbox
                            value={dataItem.id}
                            checked={selectedBrands.includes(dataItem.id)}
                            onChange={() => handleCheckboxChange(dataItem.id)}
                            label={dataItem.title}
                          /> */}
                          <Radio
                            value={dataItem.title}
                            id={dataItem.id}
                            checked={selectedBrands.includes(dataItem.id)}
                            onChange={() => handleCheckboxChange(dataItem.id)}
                          />
                        </div>

                        <div className="inform-box-content-right">
                          <div
                            style={{
                              width: "100px",
                              aspectRatio: "1/1",
                            }}
                          >
                            <ImageWithLoader
                              url={dataItem?.file_icon}
                              alt={"PreviewRentalPoint"}
                              icon={true}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  ))
                )}
              </div>
            </div>
          </div>
        </Popup>
      )}
    </>
  );
};
