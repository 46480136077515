import React, { useState, useRef } from "react";
import InputMask from "react-input-mask";
import "./inputs.css";

const InputText = ({
  label,
  type,
  name,
  value = "",
  onChange,
  description,
  maxLength,
  title,
  error,
  mask,
  active,
}) => {
  const inputRef = useRef(null);

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "8px",
        width: "100%",
      }}
    >
      <div className="input-text__content-box">
        <label
          style={{ textTransform: "capitalize" }}
          htmlFor={name}
          className={`input-text__label ${
            isFocused || value ? "input-text__label--up" : ""
          }`}
        >
          {label}
        </label>

        {mask ? (
          <InputMask
            mask={mask}
            value={value}
            onChange={onChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
          >
            {(inputProps) => (
              <input
                {...inputProps}
                className="input-text--write"
                type={type}
                id={name}
                name={name}
                placeholder={name}
                ref={inputRef}
                required={true}
                autoComplete={
                  type === "password"
                    ? "new-password"
                    : "off" || type === "email"
                    ? "new-email"
                    : "off"
                }
                minLength={6}
              />
            )}
          </InputMask>
        ) : (
          <input
            className="input-text--write"
            type={type}
            id={name}
            name={name}
            placeholder={name}
            value={value}
            onChange={onChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            ref={inputRef}
            required={true}
            autoComplete={
              type === "password"
                ? "new-password"
                : "off" || type === "email"
                ? "new-email"
                : "off"
            }
            minLength={6}
          />
        )}
      </div>

      {description && (
        <div
          style={{
            width: "100%",
          }}
        >
          <p
            style={{
              width: "90%",
            }}
            className="p-light"
          >
            {description}
          </p>
        </div>
      )}
    </div>
  );
};

export default InputText;
