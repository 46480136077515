import React, { useEffect, useState } from "react";
import "../profile.css";
import PopupDirrectory from "../WidgetsProfile/Popups/PopupDirrectory";
import AddData from "../WidgetsProfile/AddData/AddData";
import WindowEditBasicInformation from "./WindowEditBasicInformation";
import WindowEditBasicInformationModeration from "../Moderation/WindowEditBasicInformationModeration";

const RentalPointBasicInformation = ({ mode, basicInformation }) => {
  const [isOpenRentalPoint, setIsOpenRentalPoint] = useState(false);
  const [isOpenModeration, setIsOpenModeration] = useState(false);

  const title = basicInformation?.title?.titleOriginal;
  const description = basicInformation?.description?.descriptionOriginal;

  const handleToggle = () => {
    if (mode === "new" || mode === "edit") {
      setIsOpenRentalPoint(true);
    } else if (mode === "moderation" || mode === "moderatorAdded") {
      setIsOpenModeration(true);
    }
  };

  const handleClose = () => {
    setIsOpenRentalPoint(false);
    setIsOpenModeration(false);
  };

  return (
    <>
      <div className="profile-widget__content-box">
        <AddData
          onClick={handleToggle}
          icon={
            title || description ? (
              <span className="material-symbols-outlined">edit</span>
            ) : (
              <span className="material-symbols-outlined">add</span>
            )
          }
          title={`${title ? title : "Rental point name"}`}
          description={`${
            description
              ? description
              : "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          }`}
        />
      </div>

      <PopupDirrectory
        isOpen={isOpenRentalPoint}
        onClose={handleClose}
        title="Rental point inform"
      >
        <WindowEditBasicInformation mode={mode} onClose={handleClose} />
      </PopupDirrectory>

      <PopupDirrectory
        isOpen={isOpenModeration}
        onClose={handleClose}
        title="Rental point inform Moderation"
      >
        <WindowEditBasicInformationModeration
          mode={mode}
          onClose={handleClose}
        />
      </PopupDirrectory>
    </>
  );
};

export default RentalPointBasicInformation;
