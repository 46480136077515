import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addData,
  updateDocumentFields,
} from "../../../services/firebase/crudFirestore";
import interpretAuthErrorV2 from "../../../functions/interpretAuthErrorV2";

export const addDirectoryCityThunkV2 = createAsyncThunk(
  "directories/addDirectoryCityThunkV2",
  async ({ newData, documentId }, { rejectWithValue }) => {
    try {
      const addedDocument = await addData("city", newData);

      await updateDocumentFields("country", documentId, {
        citiesIds: [addedDocument.id],
      });

      return { addedDocument, documentId };
    } catch (error) {
      const errorMessage = interpretAuthErrorV2(error.code);
      return rejectWithValue({ errorMessage });
    }
  }
);
