export const calculatePricePercentage = (rates) => {
  if (rates) {
    // Находим максимальное значение price в массиве объектов rate
    const maxPrice = Math.max(...rates.map((rate) => rate.price));

    // Возвращаем новый массив с процентным соотношением каждого price относительно максимального
    return rates.map((rate) => ({
      ...rate,
      pricePercentage: (rate.price / maxPrice) * 100, // Процентное соотношение
    }));
  } else {
    return {};
  }
};
