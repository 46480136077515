import { createAsyncThunk } from "@reduxjs/toolkit";
import interpretAuthErrorV2 from "../../../functions/interpretAuthErrorV2";
import { uploadFiles } from "../../../services/firebase/crudFirestore";

export const uploadRentalPointsThunk = createAsyncThunk(
  "private/RentalPoints/uploadRentalPointsThunk",
  async (
    { selectedFiles, collectionName, documentId, fieldName },
    { rejectWithValue }
  ) => {
    try {
      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        await uploadFiles(collectionName, documentId, fieldName, [file]);
        console.log(`Файл ${file.name} успешно загружен`);
      }
      console.log("Все файлы успешно загружены");
    } catch (error) {
      console.error("Ошибка при загрузке файлов:", error.message);
      const errorMessage = interpretAuthErrorV2(error.code);
      return rejectWithValue({ errorMessage });
    }
  }
);
