import React from "react";
import { WigetСategoriesBikeDetail } from "./WigetСategoriesBike/WigetСategoriesBikeDetail";
import SearchBars from "../../../../../UIComponents/bars/searchBars/SearchBars";
import Icon44 from "../../../../../UIComponents/icons/Icon44";
import WigetButtonAdd from "../../../../../UniversalWidgets/WigetButtonAdd";
import { useDispatch } from "react-redux";
import { setPrivateSideBar } from "../../../../../../slice/stateGUISlice";

const WigetСategoriesBikeBox = () => {

    const dispatch = useDispatch();

    return (
        <>
            {/* <div className="wigetRentalPointItsBox">
                <h3>Категории</h3>
                <p className='p-primary'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eleifend ipsum vitae nunc gravida facilisis.</p>
            </div> */}
            <SearchBars
                title='Categories'

                iconLeft={
                    <Icon44
                        click={() => dispatch(setPrivateSideBar(true))}
                        icon={
                            <span className="material-symbols-outlined">
                                dock_to_right
                            </span>
                        }
                    />
                }
                iconRight={
                    <WigetButtonAdd
                        collectionName='category'
                        // dataObject={structureCollection}
                        nameButton={`Add category`}
                    />
                }
            />
            <div className='scrollBox'>
                <WigetСategoriesBikeDetail
                    collectionName="category"
                    title="Категории байков"
                />
            </div>

        </>




    );
}

export default WigetСategoriesBikeBox;


