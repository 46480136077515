import { useEffect, useState } from "react";
import WigetReservation from "./WigetReservation";
import verifyReCAPTCHA from "../../../functions/verifyReCAPTCHA";
import Preloader from "../../UIElements/Preloader";
import WigetSelectedPopup from "../UniversalWidgets/WigetSelectedPopup";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import prioritiUrl from "../../../functions/prioritiUrl";

const Voucher = ({
  bikeCostDetails = {},
  currentBike = {},
  quantityBike = 0,
}) => {
  const currentUser = useSelector((state) => state.userSliceV2.currentUser);
  const role = currentUser ? currentUser.role : null;
  // const isLoading = useSelector((state) => state.userSliceV2.isLoading);

  // console.log("Voucher-currentBike", currentUser?.uid);
  const [isVerified, setIsVerified] = useState(false);
  const [isLoadingReCAPTCHA, setIsLoadingReCAPTCHA] = useState(false);
  const [activePopup, setActivePopup] = useState(null);
  const [error, setError] = useState(null);

  // состояние загрузки данных
  const isLoading = useSelector((state) => state.stateFrontGUISlice.isLoading);

  // Стоимость со скидкой
  const discountedVoucherPrice =
    bikeCostDetails?.discountedVoucherPrice?.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });

  // Стоимость ваучера
  const voucherPrice = bikeCostDetails?.voucherPrice?.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });

  // Средняя скидка за период
  const averageDiscountRounded = parseFloat(
    bikeCostDetails?.averageDiscount.toFixed(2)
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [quantityBikes, setQuantityBikes] = useState(0);

  const handleReservationClick = async () => {
    // Проверяем, зарегистрирован ли пользователь
    if (currentUser?.uid === undefined) {
      setIsLoadingReCAPTCHA(true); // Начинаем загрузку
      try {
        const actionName = "submit"; // Необходимо определить действие
        const grade = 0.6; // Указываем минимальный балл reCAPTCHA, который ожидаем
        const result = await verifyReCAPTCHA(actionName, grade);
        setIsVerified(result);
        if (result) {
          console.log("reCAPTCHA verification passed");
          setIsModalOpen(true);
        } else {
          console.log("reCAPTCHA verification failed");
          setIsModalOpen(false);
          handleOpenPopup("warning");
          setError("reCAPTCHA verification failed");
        }
      } catch (error) {
        console.error("Error during reCAPTCHA verification:", error);
      } finally {
        setIsLoadingReCAPTCHA(false); // Завершаем загрузку, независимо от результата
      }
    } else {
      setIsModalOpen(true);
      setIsLoadingReCAPTCHA(false);
    }
  };

  useEffect(() => {
    const quantityBikes = localStorage.getItem("quantityBikes");

    if (quantityBikes) {
      setQuantityBikes(quantityBikes);
    }
  }, [currentBike]);

  const handleDecreaseQuantity = () => {
    if (quantityBikes > 0) {
      setQuantityBikes(parseInt(quantityBikes) - 1);
      localStorage.setItem("quantityBikes", parseInt(quantityBikes) - 1);
    }
  };

  const handleIncreaseQuantity = () => {
    setQuantityBikes(parseInt(quantityBikes) + 1);
    localStorage.setItem("quantityBikes", parseInt(quantityBikes) + 1);
  };

  // Обработчик открытия попапа.
  const handleOpenPopup = (popupName) => {
    setActivePopup(popupName);
  };

  // Обработчик закрытия попапа.
  const handleClosePopup = () => {
    setActivePopup(null);
  };

  return (
    <>
      <div
        className="wigetProdictCard-price-payToRental"
        style={{
          overflow: "hidden",
          backgroundColor: "var(--color-priority)",
          height: "100%",
          // backgroundColor: "var(--color-primary)",
          backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/biketripste.appspot.com/o/static%2FlogoBikeTripster%2Fvooucher.svg?alt=media&token=ec216512-1119-4b71-a928-794e28211155)`,
          backgroundSize: "110% auto",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center -20%",
          padding: "0",
        }}
      >
        <div
          className="wigetProdictCard-price-payToRental"
          style={{
            backgroundColor: "transparent",
            overflow: "hidden",
            height: "100%",
            // backgroundColor: "var(--color-primary)",
            backgroundImage: `url(${prioritiUrl(
              currentBike?.category?.file_icon
            )})`,
            backgroundSize: "auto 80%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "120% center",
          }}
        >
          {isLoading ? (
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Preloader />
            </div>
          ) : (
            <>
              {/* Цена ваучера и скидка */}
              <div
                className="wigetProdictCard-lineContent"
                style={{
                  flex: "0",
                }}
              >
                <div>
                  <h5>{discountedVoucherPrice && discountedVoucherPrice}</h5>
                  <p
                    className="p-mini"
                    style={{
                      textDecoration: "line-through",
                      color: "var(--color-gray-500)",
                    }}
                  >
                    {voucherPrice && voucherPrice}
                  </p>
                </div>

                <div>
                  {averageDiscountRounded > 0 ? (
                    <div className="averageDiscount">
                      - {averageDiscountRounded}% off
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {/* Детали заказа */}
              <div
                style={{
                  aspectRatio: "1/1",
                  height: "32px",
                  overflow: "hidden",
                  borderRadius: "80px",
                  boxSizing: "border-box",
                }}
              >
                {/* <ImageWithLoader
                aspectRatio="1/1"
                url={currentBike?.category?.file_icon}
                alt={currentBike?.category?.description}
              /> */}
              </div>

              {/* кнопка ЗАКАЗАТЬ (Reservation) */}
              <button
                style={{
                  padding: "0px",
                  position: "absolute",
                  bottom: "16px",
                  left: "16px",
                  width: "calc(100% - 32px)",
                  backgroundColor: "var(--color-success)",
                }}
                className="popupButton"
                onClick={handleReservationClick}
              >
                {isLoadingReCAPTCHA ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                      gap: "8px",
                      padding: "0 8px 0 24px",
                    }}
                  >
                    <h5
                      style={{
                        height: "100%",
                        color: "var(--color-white)",
                      }}
                    >
                      Robot Detector
                    </h5>
                    <Preloader design="white" />
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                      gap: "8px",
                      padding: "0 24px",
                    }}
                  >
                    <h5
                      style={{
                        color: "var(--color-white)",
                      }}
                    >
                      Get a voucher for:
                    </h5>
                    <h5
                      style={{
                        color: "var(--color-white)",
                      }}
                    >
                      {discountedVoucherPrice && discountedVoucherPrice}
                    </h5>
                  </div>
                )}
              </button>
              {/* количество байков (большая белая цифра) */}

              <p
                style={{
                  position: "absolute",
                  left: "150px",
                  top: "80px",
                  fontSize: "380px",
                  fontWeight: "600",
                  color: "var(--color-white)",
                  lineHeight: "0.7",
                  letterSpacing: "-50px",
                  textShadow: "0px 0px 80px rgba(195, 34, 230, 0.5)",
                }}
              >
                {/* {quantityBike && quantityBike} */}
              </p>

              {/* Картина категории (байк) */}
              <div
                style={{
                  position: "absolute",
                  // ...(isTransform
                  //   ? {
                  //       top: "150px",
                  //       transition: "top 0.3s ease-in",
                  //     }
                  //   : {
                  //       top: "150px",
                  //       transition: "top 0.3s ease-in",
                  //     }),

                  transition: "top 0.3s ease-in",
                  left: "0px",
                  height: "250px",
                  width: "auto",
                  aspectRatio: "16/9",
                  // backgroundImage: `url(${priorityIconUrl})`,
                  backgroundPosition: "right 120px center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  zIndex: 1,
                }}
              />
            </>
          )}
        </div>
      </div>

      {isModalOpen && (
        <WigetReservation
          bikeCostDetails={bikeCostDetails}
          currentBike={currentBike}
          quantityBike={quantityBike}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          handleIncreaseQuantity={handleIncreaseQuantity}
          handleDecreaseQuantity={handleDecreaseQuantity}
        />
      )}
      <WigetSelectedPopup
        isOpen={activePopup === "warning"}
        img={
          "https://firebasestorage.googleapis.com/v0/b/testbikestripster.appspot.com/o/imgStatic%2Ferr7.jpg?alt=media&token=e2270e9b-8676-4592-aeb8-5e4182f78f66&_gl=1*11g1cum*_ga*NzE3MzY1ODU1LjE2ODQ3NTEzMTQ.*_ga_CW55HF8NVT*MTY5NzQzODQ5OC40LjEuMTY5NzQzOTg1MS40NC4wLjA"
        }
        // flag={countryFlag}
        //   map={map}
        onClose={() => {
          handleClosePopup();
          setActivePopup(null);
        }}
      >
        <div
          style={{
            gridColumn: "span 2",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "24px",
            padding: "40px",
            height: "100%",
            width: "100%",
            boxSizing: "border-box",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "24px",
              width: "70%",
            }}
          >
            <div
              style={{
                backgroundImage:
                  'url("https://firebasestorage.googleapis.com/v0/b/biketripste.appspot.com/o/static%2FlogoBikeTripster%2Fsceptical.svg?alt=media&token=bd4bc713-4e4d-4894-bf3e-e11f3b386d5c")',
                backgroundSize: "auto 70%",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                width: "100%",
                height: "300px",
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
              }}
            >
              <h3
                style={{
                  width: "70%",
                  textAlign: "center",
                }}
              >
                Oops! Let's Register
              </h3>
              <p
                className="p-mini"
                style={{
                  textAlign: "center",
                  lineHeight: "1.3",
                }}
              >
                For your safety, we've implemented Google's verification service
                and received a notification of potential robotic activity. To
                continue using our services, please register or log in to your
                account. Thank you for your understanding!
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "16px",
              }}
            >
              <Link to="/login?page=signIn">
                <button
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "16px",
                    width: "auto",
                    padding: "8px 24px",
                    borderRadius: "80px",
                    border: "none",
                    fontSize: "1em",
                    fontWeight: "500",
                    height: "56px",
                    backgroundColor: "var(--color-black)",
                    color: "var(--color-white)",
                    cursor: "pointer",
                  }}
                >
                  Sign In
                </button>
              </Link>

              <Link to="/login?page=signUp">
                <button
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "16px",
                    width: "auto",
                    padding: "8px 24px",
                    borderRadius: "80px",
                    border: "none",
                    fontSize: "1em",
                    fontWeight: "500",
                    height: "56px",
                    backgroundColor: "var(--color-primary)",
                    color: "var(--color-white)",
                    cursor: "pointer",
                  }}
                >
                  Sign Up Now
                </button>
              </Link>
            </div>
          </div>
        </div>
      </WigetSelectedPopup>
    </>
  );
};

export default Voucher;
