import React from "react";
// import PrivateMenu from "../Menu/PrivateMenu";
import WigetCountryBox from "./WigetCountryBox";

// import { Outlet } from "react-router-dom";

const UICountry = () => {
  return (
    <div className="private_profile-mainBox">
      {/* <PrivateMenu /> */}
      <div className="private_profile">
        <div className="private_content-box">
          <div
            style={{
              height: "calc(100vh - 64px)",
            }}
            className="private_wiget-big-box"
          >
            <WigetCountryBox />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UICountry;
