import { createAsyncThunk } from "@reduxjs/toolkit";
import { updateDocumentFields } from "../../../../services/firebase/crudFirestore";
import { v4 as uuidv4 } from "uuid";

export const addCityThunk = createAsyncThunk(
  "countryAndCity/addCity",
  async ({ countryId, city }, { getState, rejectWithValue }) => {
    if (!countryId || !city || !city.title || !city.description) {
      return rejectWithValue(
        "Invalid data: countryId and city details are required."
      );
    }

    try {
      const state = getState();
      const countryDoc = state.countryAndCitySlice.countryWithCities.find(
        (country) => country.id === countryId
      );

      if (!countryDoc) {
        return rejectWithValue("Country not found");
      }

      const newCity = {
        ...city,
        id: uuidv4(), // Генерация уникального идентификатора
      };

      const updatedFields = {
        cities: [...countryDoc.cities, newCity],
      };
      await updateDocumentFields("country", countryId, updatedFields);
      return { countryId, city: newCity };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
