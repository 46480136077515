import { createAsyncThunk } from "@reduxjs/toolkit";
import interpretAuthErrorV2 from "../../../functions/interpretAuthErrorV2";

export const fetchBikesThunkV2 = createAsyncThunk(
  "private/directory/fetchBikesThunk",
  async (bikesIds, { getState, rejectWithValue }) => {
    try {
      // Получение данных из directorySliceV2
      const state = getState();
      const bikesFromState = state.directorySliceV2.bikes;
      const brandsFromState = state.directorySliceV2.brands;
      const categoriesFromState = state.directorySliceV2.categories;

      // Фильтрация bikes на основе переданных bikesIds, если они есть
      const bikes = bikesIds
        ? bikesFromState.filter((bike) => bikesIds.includes(bike.id))
        : bikesFromState;

      // Создание мап для быстрого доступа к брендам и категориям по их ID
      const brandMap = brandsFromState.reduce((acc, brand) => {
        acc[brand.id] = brand;
        return acc;
      }, {});

      const categoryMap = categoriesFromState.reduce((acc, category) => {
        acc[category.id] = category;
        return acc;
      }, {});

      // Добавление объектов brand и category в directory каждого bike
      const bikesWithDetails = bikes.map((bike) => {
        const brandId = bike.directory?.brandId || "";
        const categoryId = bike.directory?.categoryId || "";

        return {
          ...bike,
          directory: {
            ...bike.directory,
            brand: brandMap[brandId] || {},
            category: categoryMap[categoryId] || {},
          },
        };
      });

      return {
        bikes: bikesWithDetails,
        brands: brandsFromState,
        categories: categoriesFromState,
      };
    } catch (error) {
      const errorMessage = interpretAuthErrorV2(error.code);
      return rejectWithValue({ errorMessage });
    }
  }
);

// import { createAsyncThunk } from "@reduxjs/toolkit";
// import { fetchDocumentsByIds } from "../../../services/firebase/crudFirestore";
// import interpretAuthErrorV2 from "../../../functions/interpretAuthErrorV2";

// export const fetchBikesThunkV2 = createAsyncThunk(
//   "private/directory/fetchBikesThunk",
//   async (bikesIds, { rejectWithValue }) => {
//     try {
//       // Выполнение запросов для получения данных о bikes, brands и categories
//       const bikes = await fetchDocumentsByIds("bikes", bikesIds ? bikesIds : 0);
//       const brands = await fetchDocumentsByIds("brand");
//       const categories = await fetchDocumentsByIds("category");

//       // Создание мап для быстрого доступа к брендам и категориям по их ID
//       const brandMap = brands.reduce((acc, brand) => {
//         acc[brand.id] = brand;
//         return acc;
//       }, {});

//       const categoryMap = categories.reduce((acc, category) => {
//         acc[category.id] = category;
//         return acc;
//       }, {});

//       // Добавление объектов brand и category в directory каждого bike
//       const bikesWithDetails = bikes.map((bike) => {
//         const brandId = bike.directory?.brandId || "";
//         const categoryId = bike.directory?.categoryId || "";

//         return {
//           ...bike,
//           directory: {
//             ...bike.directory,
//             brand: brandMap[brandId] || {},
//             category: categoryMap[categoryId] || {},
//           },
//         };
//       });

//       return {
//         bikes: bikesWithDetails,
//         brands,
//         categories,
//       };
//     } catch (error) {
//       const errorMessage = interpretAuthErrorV2(error.code);
//       return rejectWithValue({ errorMessage });
//     }
//   }
// );
