import { createAsyncThunk } from "@reduxjs/toolkit";
import { collection, doc, setDoc, updateDoc } from "firebase/firestore";
import { uploadFilesToFirebase } from "../../../../services/firebase/crudFirestore";
import { db } from "../../../../services/firebase/authFirebase";

export const createRentalPointThunkV4 = createAsyncThunk(
  "rentalPoints/createRentalPointThunkV4",
  async (
    { countryId, cityId, rentalPointId, data, files },
    { rejectWithValue }
  ) => {
    try {
      // Создание ссылки на документ в коллекции "rentalPoints" внутри города
      const rentalPointDocRef = doc(
        collection(db, "catalog", countryId, "cities", cityId, "rentalPoints"),
        rentalPointId ||
          doc(
            collection(
              db,
              "catalog",
              countryId,
              "cities",
              cityId,
              "rentalPoints"
            )
          ).id
      );

      // Сохранение данных rental point в Firestore без файлов
      await setDoc(rentalPointDocRef, data);

      console.log("Точка проката успешно добавлена в Firestore:", {
        countryId,
        cityId,
        rentalPointId: rentalPointDocRef.id,
        data,
      });

      // Загрузка файлов, если они есть
      let uploadedFiles = {};

      // Функция для загрузки файлов по типам
      const uploadFilesByType = async (fileArray, folderName) => {
        if (fileArray && fileArray.length > 0) {
          return await uploadFilesToFirebase(
            `rentalPoints/${rentalPointDocRef.id}/${folderName}`,
            fileArray
          );
        }
        return [];
      };

      // Обработка всех массивов в объекте files
      if (files) {
        for (const [key, fileArray] of Object.entries(files)) {
          const uploaded = await uploadFilesByType(fileArray, key);
          uploadedFiles[key] = uploaded;
        }
      }

      // Обновление документа rental point с ссылками на загруженные файлы
      if (Object.keys(uploadedFiles).length > 0) {
        await updateDoc(rentalPointDocRef, { files: uploadedFiles });
        console.log("Документ точки проката обновлен с загруженными файлами.");
      }

      return {
        countryId,
        cityId,
        rentalPointId: rentalPointDocRef.id,
        data: { ...data, files: uploadedFiles },
      };
    } catch (error) {
      console.error("Ошибка при добавлении точки проката в Firestore:", error);
      return rejectWithValue(error.message);
    }
  }
);

// import { createAsyncThunk } from "@reduxjs/toolkit";
// import { collection, doc, setDoc, updateDoc } from "firebase/firestore";
// import { uploadFilesToFirebase } from "../../../../services/firebase/crudFirestore";
// import { db } from "../../../../services/firebase/authFirebase";

// export const createRentalPointThunkV4 = createAsyncThunk(
//   "rentalPoints/createRentalPointThunkV4",
//   async (
//     { countryId, cityId, rentalPointId, data, files },
//     { rejectWithValue }
//   ) => {
//     console.log(
//       "createRentalPointThunkV4",
//       countryId,
//       cityId,
//       rentalPointId,
//       data,
//       files
//     );
//     try {
//       // Создание ссылки на документ в коллекции "rentalPoints" внутри города
//       const rentalPointDocRef = doc(
//         collection(db, "catalog", countryId, "cities", cityId, "rentalPoints"),
//         rentalPointId ||
//           doc(
//             collection(
//               db,
//               "catalog",
//               countryId,
//               "cities",
//               cityId,
//               "rentalPoints"
//             )
//           ).id
//       );

//       // Сохранение данных rental point в Firestore без файлов
//       await setDoc(rentalPointDocRef, data);

//       console.log("Точка проката успешно добавлена в Firestore:", {
//         countryId,
//         cityId,
//         rentalPointId: rentalPointDocRef.id,
//         data,
//       });

//       // Загрузка файлов, если они есть
//       let uploadedFiles = [];
//       if (files && files.length > 0) {
//         console.log("Загрузка файлов:", { files });
//         uploadedFiles = await uploadFilesToFirebase(
//           `rentalPoints/${rentalPointDocRef.id}`,
//           files
//         );
//       }

//       // Обновление документа rental point с ссылками на загруженные файлы
//       const updatedData = {
//         files: {
//           photoFiles: uploadedFiles.photoFiles || [],
//           licenseFiles: uploadedFiles.licenseFiles || [],
//           logoFiles: uploadedFiles.logoFiles || [],
//         },
//       };

//       if (uploadedFiles.length > 0) {
//         await updateDoc(rentalPointDocRef, updatedData);
//         console.log("Документ точки проката обновлен с загруженными файлами.");
//       }

//       return {
//         countryId,
//         cityId,
//         rentalPointId: rentalPointDocRef.id,
//         data: { ...data, ...updatedData },
//       };
//     } catch (error) {
//       console.error("Ошибка при добавлении точки проката в Firestore:", error);
//       return rejectWithValue(error.message);
//     }
//   }
// );
