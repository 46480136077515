import React, { useEffect, useState, useRef } from "react";
import InputText from "../WidgetsProfile/Inputs/InputText";
import Textarea from "../WidgetsProfile/Textarea/Textarea";
import Button from "../WidgetsProfile/Buttons/Button";
import { useDispatch } from "react-redux";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import { formatCurrencyUSD } from "../../../../../functions/currencyFormatter";
import {
  addAdditionalService,
  deleteAdditionalService,
  updateAdditionalService,
} from "../../../../../reducersToolkit/rentalPointsSliiceV2";
import {
  addAdditionalServiceModeration,
  deleteAdditionalServiceModeration,
  updateAdditionalServiceModeration,
} from "../../../../../reducersToolkit/moderationSliceV2";
import Counter from "../WidgetsProfile/Counter/Counter";
import ButtonsBar from "../WidgetsProfile/ButtonsBar/ButtonsBar";
import {
  addAdditionalServiceV4,
  deleteAdditionalServiceV4,
  updateAdditionalServiceV4,
} from "../../../../../reducersToolkit/rentalPointsSliiceV4";

const WindowAditionalServiceDetailV4 = ({
  mode,
  onClose,
  selectedAditionalService,
}) => {
  const dispatch = useDispatch();

  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState("");
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [counterValues, setCounterValues] = useState(0);

  const prevCounterValues = useRef(counterValues);

  let aditionalServise = {
    title: {
      titleOriginal: title,
    },
    description: {
      descriptionOriginal: description,
    },
    price: price,
    id: selectedServiceId || Date.now().toString(),
    counter: counterValues,
  };

  // useEffect(()=>{
  //   console.log("aditionalServise", aditionalServise);
  // },[aditionalServise])

  useEffect(() => {
    if (mode === "edit" || mode === "moderation") {
      setTitle(selectedAditionalService?.title?.titleOriginal || "");
      setDescription(
        selectedAditionalService?.description?.descriptionOriginal || ""
      );
      setPrice(selectedAditionalService?.price || "");
      setSelectedServiceId(selectedAditionalService?.id || null);
      setCounterValues(selectedAditionalService?.counter || 0);
    }
  }, [mode, selectedAditionalService]);

  const handleAddAdditionalService = () => {
    if (mode === "new" || mode === "edit") {
      dispatch(addAdditionalServiceV4({ mode, aditionalServise }));
    }
    if (mode === "moderation") {
      dispatch(addAdditionalServiceModeration(aditionalServise));
    }
    onClose();
  };

  const handleUpdateAdditionalService = () => {
    if (mode === "edit") {
      dispatch(
        updateAdditionalServiceV4({
          id: selectedServiceId,
          additionalService: aditionalServise,
        })
      );
    } else if (mode === "moderation") {
      dispatch(
        updateAdditionalServiceModeration({
          id: selectedServiceId,
          additionalService: aditionalServise,
        })
      );
    }
    onClose();
  };

  const handleDeleteService = () => {
    if (selectedServiceId) {
      if (mode === "edit") {
        dispatch(
          deleteAdditionalServiceV4({
            mode,
            id: selectedServiceId,
          })
        );
      } else if (mode === "moderation") {
        dispatch(deleteAdditionalServiceModeration(selectedServiceId));
      }
    }
    aditionalServise = {};
    onClose();
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "8px",
          paddingBottom: "72px",
        }}
      >
        <ContentBox position="start">
          <InputText
            label="Service name"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            onChange={(e) => setTitle(e.target.value)}
            value={title}
          />
          <Textarea
            label="Description aditional service"
            maxLength={500}
            onChange={(e) => setDescription(e.target.value)}
            value={description}
          />
          <InputText
            label="Price"
            type="number"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            onChange={(e) => setPrice(Number(e.target.value))}
            value={price}
          />

          <Counter
            maxValue={50}
            minValue={1}
            value={counterValues}
            onChange={(newCount) => {
              if (prevCounterValues.current !== newCount) {
                setCounterValues(newCount);
                prevCounterValues.current = newCount;
              }
            }}
            label="Number of additional services"
            description="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
          />
        </ContentBox>
      </div>
      <ButtonsBar>
        <div
          style={{
            display: "grid",
            ...(selectedAditionalService
              ? { gridTemplateColumns: "repeat(3, 1fr)" }
              : { gridTemplateColumns: "repeat(2, 1fr)" }),

            gap: "16px",
            width: "100%",
          }}
        >
          <Button
            type="small"
            label="Cancel"
            onClick={onClose}
            active={true}
            color="--color-black"
          />
          {!selectedAditionalService ? (
            <Button
              type="small"
              label="Add"
              onClick={handleAddAdditionalService}
              active={true}
              allert={
                <div
                  style={{
                    padding: "48px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "16px",
                    textAlign: "center",
                  }}
                >
                  <h1>Are you sure you want to save this information?</h1>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "2px",
                    }}
                  >
                    <p className="p-light">Service name: {description}</p>
                    <p className="p-light">
                      Description aditional service: {title}
                    </p>
                    <p className="p-light">Price: {formatCurrencyUSD(price)}</p>
                  </div>
                </div>
              }
            />
          ) : (
            <>
              <Button
                type="small"
                label="Remove"
                onClick={handleDeleteService}
                active={true}
                allert={
                  <div
                    style={{
                      padding: "48px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "16px",
                      textAlign: "center",
                    }}
                  >
                    <h1>
                      Are you sure you want to delete this additional service?
                    </h1>
                    <p className="p-light">
                      This action is irreversible, and you will lose all data
                      associated with this service.
                    </p>
                  </div>
                }
                color="--color-alarm"
              />

              <Button
                type="small"
                label="Update"
                onClick={handleUpdateAdditionalService}
                active={true}
                allert={
                  <div
                    style={{
                      padding: "48px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "16px",
                      textAlign: "center",
                    }}
                  >
                    <h1>Are you sure you want to save this information?</h1>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <p className="p-light">Service name: {description}</p>
                      <p className="p-light">
                        Description aditional service: {title}
                      </p>
                      <p className="p-light">
                        Price: {formatCurrencyUSD(price)}
                      </p>
                    </div>
                  </div>
                }
              />
            </>
          )}
        </div>
      </ButtonsBar>
    </>
  );
};

export default WindowAditionalServiceDetailV4;
