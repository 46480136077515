import React, { useEffect, useState } from "react";
import DropdownList from "../../../../Private/Login/Profile/WidgetsProfile/DropdownList/DropdownList";
import Counter from "../../../../Private/Login/Profile/WidgetsProfile/Counter/Counter";
import DateInterval from "../../../PublickWidgets/Date/DateInterval";
import { useDispatch, useSelector } from "react-redux";
import { filterBikesThunkV2 } from "../../../../../reducersToolkit/thunk/catalog/filterBikesThunkV2";
import {
  clearFilters,
  setCatalogCity,
  setCatalogCounty,
  setCatalogQualityBike,
} from "../../../../../reducersToolkit/catalogSliceV2";
import Button from "../../../../Private/Login/Profile/WidgetsProfile/Buttons/Button";
import ButtonsBar from "../../../../Private/Login/Profile/WidgetsProfile/ButtonsBar/ButtonsBar";
import ContentBox from "../../../../Private/Login/Profile/WidgetsProfile/ContentBox/ContentBox";

const WindowMainFilter = ({ onClose }) => {
  const dispatch = useDispatch();

  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [countriesOptions, setCountriesOptions] = useState([]);
  const [countyIdFilter, setCountyIdFilter] = useState(null);
  const [cityIdFilter, setCityIdFilter] = useState(null);
  const [qualityBike, setQualityBike] = useState(1);
  const [bookingDates, setBookingDates] = useState([]);
  const [brandIdFilter, setBrandIdFilter] = useState(null);
  const [categoryIdFilter, setCategoryIdFilter] = useState(null);

  const {
    filteredBikes,
    bikes,
    rentalPoints,
    cities,
    countries,
    brands,
    categories,
    isLoading,
    filters,
  } = useSelector((state) => state.catalogSliceV2);

  useEffect(() => {
    setBookingDates([
      filters?.bookingDates?.startDay,
      filters?.bookingDates?.endDay,
    ]);
    setQualityBike(filters?.qualityBike || 1);
    setCityIdFilter(filters?.city?.id || null);
    setCountyIdFilter(filters?.country?.id || null);
    setBrandIdFilter(filters?.brand?.id || null);
    setCategoryIdFilter(filters?.category?.id || null);
  }, [filters]);

  // Фильтрация списка bikes
  const buttonAplay = () => {
    if (bikes.length > 0) {
      dispatch(
        filterBikesThunkV2({
          bikes: bikes,
          countryIdFilter: countyIdFilter,
          cityIdFilter: cityIdFilter,
          qualityBikeFilter: qualityBike,
          dateIntervalFilter: bookingDates,
          brandIdFilter: brandIdFilter,
          categoryIdFilter: categoryIdFilter,
        })
      );
    }
    dispatch(setCatalogCity({ city: selectedCity }));
    dispatch(setCatalogCounty({ country: selectedCountry }));
    dispatch(setCatalogQualityBike({ qualityBike: qualityBike }));
    onClose();
  };

  const buttonClear = () => {
    dispatch(clearFilters());
    if (bikes.length > 0) {
      dispatch(
        filterBikesThunkV2({
          bikes: bikes,
          countryIdFilter: null,
          cityIdFilter: null,
          qualityBikeFilter: 1,
          dateIntervalFilter: bookingDates,
        })
      );
    }
    onClose();
  };

  useEffect(() => {
    const formattedCountries = countries?.map((country) => ({
      id: country.id,
      title: country.title.titleOriginal,
      citiesIds: country.citiesIds || [],
    }));
    setCountriesOptions(formattedCountries);

    const initialCountry = formattedCountries.find(
      (country) => country.id === countyIdFilter
    );
    setSelectedCountry(initialCountry);
  }, [countries, countyIdFilter]);

  useEffect(() => {
    if (selectedCountry) {
      const filteredCities = cities
        .filter((city) => selectedCountry.citiesIds?.includes(city.id))
        .map((city) => ({
          id: city.id,
          title: city.title.titleOriginal,
        }));
      setCitiesOptions(filteredCities);

      const initialCity = filteredCities.find(
        (city) => city.id === cityIdFilter
      );
      setSelectedCity(initialCity);
    } else {
      const formattedCities = cities?.map((city) => ({
        id: city.id,
        title: city.title.titleOriginal,
      }));
      setCitiesOptions(formattedCities);

      const initialCity = formattedCities.find(
        (city) => city.id === cityIdFilter
      );
      setSelectedCity(initialCity);
    }
  }, [cities, cityIdFilter, selectedCountry]);

  const handleCitySelect = (city) => {
    setSelectedCity(city || {});
    setCityIdFilter(city?.id || null);
  };

  const handleCountrySelect = (country) => {
    setSelectedCountry(country || {});
    setCountyIdFilter(country?.id || null);
  };

  const handleQualityBike = (qualityBike) => {
    setQualityBike(qualityBike || 1);
  };
  return (
    <>
      <div
        style={{
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          marginBottom: "72px",
        }}
      >
        <ContentBox position="start">
          <div
            style={{
              width: "100%",
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "16px",
            }}
          >
            <DropdownList
              options={countriesOptions}
              onSelect={handleCountrySelect}
              label="Select country"
              description="Please select a country."
              fieldView="title"
              fieldId="id"
              initialSelectedOption={selectedCountry}
            />

            <DropdownList
              options={citiesOptions}
              onSelect={handleCitySelect}
              label="Select city"
              description="Please select a city."
              fieldView="title"
              fieldId="id"
              initialSelectedOption={selectedCity}
            />
          </div>

          <Counter
            minValue={1}
            maxValue={100}
            value={qualityBike}
            onChange={(newCount) => handleQualityBike(newCount)}
            label="Quantity bikes"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
          />

          <DateInterval
            dates={bookingDates}
            mode="new"
            // onChange={(newDates) => setDateIntervalFilter(newDates)}
            description="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
          />
        </ContentBox>
      </div>

      <ButtonsBar>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "16px",
          }}
        >
          <Button
            type="small"
            label="Clear"
            active={true}
            color="--color-black"
            onClick={buttonClear}
          />
          <Button
            type="small"
            label="Aplay"
            active={true}
            onClick={buttonAplay}
          />
        </div>
      </ButtonsBar>
    </>
  );
};

export default WindowMainFilter;
