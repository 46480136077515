import { createAsyncThunk } from "@reduxjs/toolkit";

export const filterOrdersByIdsThunkV2 = createAsyncThunk(
  "orders/filterOrdersByIdsThunkV2",
  async ({ ordersList, userOrdersIds }, { rejectWithValue }) => {
    try {
      // Проверка входных данных
      if (!Array.isArray(ordersList) || !Array.isArray(userOrdersIds)) {
        throw new Error("Invalid input data");
      }

      // Фильтрация заказов по ID
      const filteredOrders = ordersList.filter((order) =>
        userOrdersIds.includes(order.id)
      );
      console.log("orders/filterOrdersByIdsThunkV2", filteredOrders);
      return filteredOrders;
    } catch (error) {
      console.error("Error filtering orders: ", error);
      return rejectWithValue({ message: error.message });
    }
  }
);
