import React, { useState, useEffect } from "react";
import DropdownList from "../WidgetsProfile/DropdownList/DropdownList";
import { useDispatch } from "react-redux";
import {
  setNewBikeBrandId,
  setNewBikeCategoryId,
  setSelectedBikeBrandId,
  setSelectedBikeCategoryId,
} from "../../../../../reducersToolkit/bikesSliceV2";
import {
  setSelectedBikeBrandIdModeration,
  setSelectedBikeCategoryIdModeration,
} from "../../../../../reducersToolkit/moderationSliceV2";

const WidgetBrandCategory = ({
  mode,
  brands,
  categories,
  initialSelectedBrandId,
  initialSelectedCategoryId,
}) => {
  const dispatch = useDispatch();
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [brandOptions, setBrandOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);

  useEffect(() => {
    // Формируем массив объектов для брендов
    const formattedBrands = brands.map((brand) => ({
      id: brand.id,
      title: brand.title.titleOriginal,
    }));
    setBrandOptions(formattedBrands);

    // Формируем массив объектов для категорий
    const formattedCategories = categories.map((category) => ({
      id: category.id,
      title: category.title.titleOriginal,
    }));
    setCategoryOptions(formattedCategories);

    // Находим начально выбранные бренд и категорию
    const initialBrand = formattedBrands.find(
      (brand) => brand.id === initialSelectedBrandId
    );
    const initialCategory = formattedCategories.find(
      (category) => category.id === initialSelectedCategoryId
    );

    setSelectedBrand(initialBrand);
    setSelectedCategory(initialCategory);
  }, [brands, categories, initialSelectedBrandId, initialSelectedCategoryId]);

  const handleBrandSelect = (brand) => {
    if (mode === "new") {
      dispatch(setNewBikeBrandId(brand.id));
    } else if (mode === "edit") {
      dispatch(setSelectedBikeBrandId(brand.id));
    } else if (mode === "moderation") {
      dispatch(setSelectedBikeBrandIdModeration(brand.id));
    }
    setSelectedBrand(brand);
  };

  const handleCategorySelect = (category) => {
    if (mode === "new") {
      dispatch(setNewBikeCategoryId(category.id));
    } else if (mode === "edit") {
      dispatch(setSelectedBikeCategoryId(category.id));
    } else if (mode === "moderation") {
      dispatch(setSelectedBikeCategoryIdModeration(category.id));
    }
    setSelectedCategory(category);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        gap: "16px",
        width: "100%",
      }}
    >
      <DropdownList
        options={brandOptions}
        onSelect={handleBrandSelect}
        label="Select Brand"
        description="Please select a brand."
        fieldView="title"
        fieldId="id"
        initialSelectedOption={selectedBrand}
      />
      <DropdownList
        options={categoryOptions}
        onSelect={handleCategorySelect}
        label="Select Category"
        description="Please select a category."
        fieldView="title"
        fieldId="id"
        initialSelectedOption={selectedCategory}
      />
    </div>
  );
};

export default WidgetBrandCategory;
