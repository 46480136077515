import { createSlice } from "@reduxjs/toolkit";
import { createCountryThunkV4 } from "./thunk/catalog/V4/createCountryThunkV4";
import { createCityThunkV4 } from "./thunk/catalog/V4/createCityThunkV4";
import { createRentalPointThunkV4 } from "./thunk/catalog/V4/createRentalPointThunkV4";
import { createBikeThunkV4 } from "./thunk/catalog/V4/createBikeThunkV4";
import { updateFilePriorityThunkV4 } from "./thunk/catalog/V4/updateFilePriorityThunkV4";
import { updateCountryThunkV4 } from "./thunk/catalog/V4/updateCountryThunkV4";
import { deleteCountryThunkV4 } from "./thunk/catalog/V4/deleteCountryThunkV4";
import { updateCityThunkV4 } from "./thunk/catalog/V4/updateCityThunkV4";
import { deleteCityThunkV4 } from "./thunk/catalog/V4/deleteCityThunkV4";
import { deleteFilesThunkV4 } from "./thunk/catalog/V4/deleteFilesThunkV4";
import { fetchDataThunkV4 } from "./thunk/catalog/V4/fetchDataThunkV4";
import { fetchCountriesDataThunkV4 } from "./thunk/catalog/V4/fetchCatalogData/fetchCountriesDataThunkV4";
import { fetchCitiesDataThunkV4 } from "./thunk/catalog/V4/fetchCatalogData/fetchCitiesDataThunkV4";

const initialState = {
  // countriesData: [],

  loadingCountries: false,
  loadingCities: false,
  loadingRentalPoints: false,
  loadingBikes: false,

  errorCountries: null,
  errorCities: null,
  errorRentalPoints: null,
  errorBikes: null,

  // catalog: [],
  catalogLoading: false,

  countries: [],
  currentCountry: {},
  updateCountryLoading: false,
  createCountryLoading: false,

  currentCity: {},
  createCityLoading: false,
  updateCityLoading: false,

  rentalPoints: [],

  bikes: [],

  locations: [],
  loadingLocations: false,

  loading: false,

  loadingPriority: false,
  deleteFilesLoading: false,

  isInitialized: false,

  error: null,
};

const catalogSliceV4 = createSlice({
  name: "catalogSliceV4",
  initialState,
  reducers: {
    setInitialized: (state) => {
      state.isInitialized = true;
    },
    // setNullcatalogSliceV4: (state, action) => {
    //   state.countries = [];
    //   state.currentCountry = {};
    //   state.currentCity = {};
    // },

    setCurrentCountry: (state, action) => {
      state.currentCountry = action.payload;
    },
    setCurrentCity: (state, action) => {
      state.currentCity = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Обработка fetchCountriesDataThunkV4
      .addCase(fetchCountriesDataThunkV4.pending, (state) => {
        state.loadingCountries = true;
        state.errorCountries = null;
      })
      .addCase(fetchCountriesDataThunkV4.fulfilled, (state, action) => {
        // Проверка наличия данных в payload
        if (!action.payload || action.payload.fromIndexedDB) {
          console.log("Данные были получены из IndexedDB или отсутствуют.");
          state.loadingCountries = false;
          return;
        }

        const { data } = action.payload;
        console.log("Обновляем данные о странах:", data);

        state.countries = data.map((country) => ({
          ...country,
          cities: country.cities || [],
          lastUpdated: country.lastUpdated, // Убедитесь, что обновляется и lastUpdated для стран
        }));

        state.loadingCountries = false;
        console.log("Данные о странах обновлены:", state.countries);

        // Обновление currentCountry, если текущая страна есть в новом списке
        if (state.currentCountry.id) {
          const updatedCountry = state.countries.find(
            (country) => country.id === state.currentCountry.id
          );
          if (updatedCountry) {
            state.currentCountry = updatedCountry;
          }
        }
      })
      .addCase(fetchCountriesDataThunkV4.rejected, (state, action) => {
        state.errorCountries = action.payload;
        state.loadingCountries = false;
      })

      // Обработка fetchDataThunkV4
      .addCase(fetchCitiesDataThunkV4.pending, (state) => {
        state.loadingCities = true;
        state.errorCities = null;
      })
      .addCase(fetchCitiesDataThunkV4.fulfilled, (state, action) => {
        const { countryId } = action.meta.arg || {};

        if (!action.payload || action.payload.fromIndexedDB) {
          console.log("Данные были получены из IndexedDB или отсутствуют.");
          state.loadingCities = false;
          return;
        }

        const { data } = action.payload;
        console.log(
          `Обновляем данные о городах в стране с id ${countryId}:`,
          data
        );

        state.countries = state.countries.map((country) => {
          if (country.id === countryId) {
            const updatedCountry = {
              ...country,
              cities: data.map((city) => ({
                ...city,
                rentalPointsData: city.rentalPointsData || [],
                lastUpdated: city.lastUpdated,
              })),
              lastUpdated: country.lastUpdated,
            };

            if (state.currentCountry.id === countryId) {
              state.currentCountry = updatedCountry;
            }

            console.log(
              `Страна после обновления городов с id ${countryId}:`,
              JSON.stringify(updatedCountry, null, 2)
            );
            return updatedCountry;
          }
          return country;
        });
        state.loadingCities = false;
      })
      .addCase(fetchCitiesDataThunkV4.rejected, (state, action) => {
        state.errorCities = action.payload;
        state.loadingCities = false;
      })

      //  Обработка fetchDataThunkV4
      // .addCase(fetchDataThunkV4.pending, (state, action) => {
      //   const { countryId, cityId, rentalPointId, bikeId } =
      //     action.meta.arg || {};

      //   if (!countryId && !cityId && !rentalPointId && !bikeId) {
      //     state.loadingCountries = true;
      //     state.errorCountries = null;
      //   } else if (countryId && !cityId) {
      //     state.loadingCities = true;
      //     state.errorCities = null;
      //   } else if (countryId && cityId && !rentalPointId) {
      //     state.loadingRentalPoints = true;
      //     state.errorRentalPoints = null;
      //   } else if (countryId && cityId && rentalPointId && !bikeId) {
      //     state.loadingBikes = true;
      //     state.errorBikes = null;
      //   }
      // })
      // .addCase(fetchDataThunkV4.fulfilled, (state, action) => {
      //   const { countryId, cityId, rentalPointId, bikeId } =
      //     action.meta.arg || {};

      //   // Проверка наличия данных в payload
      //   if (!action.payload || action.payload.fromIndexedDB) {
      //     console.log("Данные были получены из IndexedDB или отсутствуют.");
      //     state.loadingCountries = false;
      //     state.loadingCities = false;
      //     state.loadingRentalPoints = false;
      //     state.loadingBikes = false;
      //     return;
      //   }

      //   const { data, lastUpdated } = action.payload;

      //   if (!countryId && !cityId && !rentalPointId && !bikeId) {
      //     console.log("Обновляем данные о странах:", data);

      //     state.countries = data.map((country) => ({
      //       ...country,
      //       cities: country.cities || [],
      //       lastUpdated, // Убедитесь, что обновляется и lastUpdated для стран
      //     }));

      //     state.loadingCountries = false;
      //     console.log("Данные о странах обновлены:", state.countries);
      //     // Обновление currentCountry, если текущая страна есть в новом списке

      //     console.log(
      //       "Обновление currentCountry, если текущая страна есть в новом списке:",
      //       state.currentCountry
      //     );

      //     if (state.currentCountry.id) {
      //       const updatedCountry = state.countries.find(
      //         (country) => country.id === state.currentCountry.id
      //       );
      //       if (updatedCountry) {
      //         state.currentCountry = updatedCountry;
      //       }
      //     }
      //   } else if (countryId && !cityId) {
      //     // Обновляем данные о городах в конкретной стране
      //     state.countries = state.countries.map((country) => {
      //       if (country.id === countryId) {
      //         console.log(
      //           `Обновляем данные о городах в стране: ${JSON.stringify(
      //             country,
      //             null,
      //             2
      //           )} с id ${countryId}:`,
      //           data
      //         );

      //         // Обновляем lastUpdated для каждого города и страны
      //         const updatedCountry = {
      //           ...country,
      //           cities: data.map((city) => ({
      //             ...city,
      //             rentalPointsData: city.rentalPointsData || [],
      //             lastUpdated, // Устанавливаем lastUpdated для каждого города
      //           })),
      //           lastUpdated, // Устанавливаем lastUpdated для страны
      //         };

      //         // Обновляем currentCountry, если обновляется текущая страна
      //         if (state.currentCountry.id === countryId) {
      //           state.currentCountry = updatedCountry;
      //         }

      //         // Выводим обновленную страну в консоль после обновления городов
      //         console.log(
      //           `Страна после обновления городов с id ${countryId}:`,
      //           JSON.stringify(updatedCountry, null, 2)
      //         );
      //         return updatedCountry;
      //       }
      //       return country;
      //     });
      //     state.loadingCities = false;
      //   } else if (countryId && cityId && !rentalPointId) {
      //     // Обновляем данные о точках проката в городе
      //     state.countries = state.countries.map((country) => {
      //       if (country.id === countryId) {
      //         const updatedCitiesData = country.cities.map((city) => {
      //           if (city.id === cityId) {
      //             console.log(
      //               `Обновляем данные о точках проката в городе с id ${cityId}:`,
      //               data
      //             );
      //             const updatedCity = {
      //               ...city,
      //               rentalPointsData: data.map((point) => ({
      //                 ...point,
      //                 bikesData: point.bikesData || [],
      //               })),
      //               lastUpdated, // Устанавливаем lastUpdated для города
      //             };

      //             // Обновляем currentCity, если обновляется текущий город
      //             if (state.currentCity.id === cityId) {
      //               state.currentCity = updatedCity;
      //             }

      //             return updatedCity;
      //           }
      //           return city;
      //         });
      //         const updatedCountry = {
      //           ...country,
      //           cities: updatedCitiesData,
      //         };

      //         // Обновляем currentCountry, если обновляется текущая страна
      //         if (state.currentCountry.id === countryId) {
      //           state.currentCountry = updatedCountry;
      //         }

      //         // Выводим обновленную страну в консоль после обновления точек проката в городах
      //         console.log(
      //           `Страна после обновления точек проката в городах с id ${countryId}:`,
      //           JSON.stringify(updatedCountry, null, 2)
      //         );

      //         return updatedCountry;
      //       }
      //       return country;
      //     });
      //     state.loadingRentalPoints = false;
      //   } else if (countryId && cityId && rentalPointId && !bikeId) {
      //     // Обновляем данные о велосипедах в точке проката
      //     state.countries = state.countries.map((country) => {
      //       if (country.id === countryId) {
      //         const updatedCitiesData = country.cities.map((city) => {
      //           if (city.id === cityId) {
      //             const updatedRentalPointsData = city.rentalPointsData.map(
      //               (point) => {
      //                 if (point.id === rentalPointId) {
      //                   console.log(
      //                     `Обновляем данные о велосипедах в точке проката с id ${rentalPointId}:`,
      //                     data
      //                   );
      //                   return {
      //                     ...point,
      //                     bikesData: data.map((bike) => ({ ...bike })),
      //                     lastUpdated, // Устанавливаем lastUpdated для точки проката
      //                   };
      //                 }
      //                 return point;
      //               }
      //             );
      //             const updatedCity = {
      //               ...city,
      //               rentalPointsData: updatedRentalPointsData,
      //             };

      //             // Обновляем currentCity, если обновляется текущий город
      //             if (state.currentCity.id === cityId) {
      //               state.currentCity = updatedCity;
      //             }

      //             return updatedCity;
      //           }
      //           return city;
      //         });
      //         const updatedCountry = {
      //           ...country,
      //           cities: updatedCitiesData,
      //         };

      //         // Обновляем currentCountry, если обновляется текущая страна
      //         if (state.currentCountry.id === countryId) {
      //           state.currentCountry = updatedCountry;
      //         }

      //         return updatedCountry;
      //       }
      //       return country;
      //     });
      //     state.loadingBikes = false;
      //   } else {
      //     console.log("Условия для обновления данных не совпали.");
      //   }
      // })

      // .addCase(fetchDataThunkV4.rejected, (state, action) => {
      //   const { countryId, cityId, rentalPointId, bikeId } =
      //     action.meta.arg || {};
      //   const errorMessage = action.payload;

      //   if (!countryId && !cityId && !rentalPointId && !bikeId) {
      //     state.errorCountries = errorMessage;
      //     state.loadingCountries = false;
      //   } else if (countryId && !cityId) {
      //     state.errorCities = errorMessage;
      //     state.loadingCities = false;
      //   } else if (countryId && cityId && !rentalPointId) {
      //     state.errorRentalPoints = errorMessage;
      //     state.loadingRentalPoints = false;
      //   } else if (countryId && cityId && rentalPointId && !bikeId) {
      //     state.errorBikes = errorMessage;
      //     state.loadingBikes = false;
      //   }
      // })
      // Обработка createCountryThunkV4
      .addCase(createCountryThunkV4.pending, (state) => {
        state.createCountryLoading = true;
      })
      .addCase(createCountryThunkV4.fulfilled, (state, action) => {
        state.createCountryLoading = false;
        const { countryId, data } = action.payload;
        state.countries.push({
          id: countryId,
          cities: [],
          ...data,
        });
      })
      .addCase(createCountryThunkV4.rejected, (state, action) => {
        state.createCountryLoading = false;
        state.error = action.payload;
      })

      // Обработка createCityThunkV4
      .addCase(createCityThunkV4.pending, (state) => {
        state.createCityLoading = true;
      })
      .addCase(createCityThunkV4.fulfilled, (state, action) => {
        state.createCityLoading = false;

        const { countryId, cityId, data } = action.payload;

        const country = state.countries.find((c) => c.id === countryId);

        if (country) {
          if (!country.cities) country.cities = [];

          // Добавляем новый город в список городов страны
          const newCity = {
            id: cityId,
            ...data,
          };
          country.cities.push(newCity);

          // Обновляем currentCountry, если добавленный город относится к нему
          if (state.currentCountry.id === countryId) {
            state.currentCountry.cities = [
              ...(state.currentCountry.cities || []),
              newCity,
            ];
          }
        }
      })

      .addCase(createCityThunkV4.rejected, (state, action) => {
        state.createCityLoading = false;
        state.error = action.payload;
      })

      // Обработка createRentalPointThunkV4
      .addCase(createRentalPointThunkV4.pending, (state) => {
        state.loading = true;
      })

      .addCase(createRentalPointThunkV4.fulfilled, (state, action) => {
        state.loading = false;
        const country = state.countries.find(
          (c) => c.id === action.payload.countryId
        );
        const city = country.cities.find(
          (city) => city.id === action.payload.cityId
        );
        if (city) {
          if (!city.rentalPoints) city.rentalPoints = [];
          city.rentalPoints.push({
            id: action.payload.rentalPointId,
            name: action.payload.rentalPointName,
            address: action.payload.address,
            contactInfo: action.payload.contactInfo,
          });
        }
      })

      .addCase(createRentalPointThunkV4.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Обработка createBikeThunkV4
      .addCase(createBikeThunkV4.pending, (state) => {
        state.loading = true;
      })

      .addCase(createBikeThunkV4.fulfilled, (state, action) => {
        state.loading = false;
        const country = state.countries.find(
          (c) => c.id === action.payload.countryId
        );
        const city = country.cities.find(
          (city) => city.id === action.payload.cityId
        );
        const rentalPoint = city.rentalPoints.find(
          (rp) => rp.id === action.payload.rentalPointId
        );
        if (rentalPoint) {
          if (!rentalPoint.bikes) rentalPoint.bikes = [];
          rentalPoint.bikes.push({
            id: action.payload.bikeId,
            model: action.payload.model,
            brandId: action.payload.brandId,
            categoryId: action.payload.categoryId,
            availability: action.payload.availability,
          });
        }
      })

      .addCase(createBikeThunkV4.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Обработка updateFilePriorityThunkV4
      .addCase(updateFilePriorityThunkV4.pending, (state) => {
        state.loadingPriority = true;
      })

      .addCase(updateFilePriorityThunkV4.fulfilled, (state, action) => {
        state.loadingPriority = false;
        const { updatedFiles, collectionName, documentId } = action.payload;

        // Если коллекция - это страны (countries)
        if (collectionName.startsWith("catalog")) {
          const country = state.countries.find((c) => c.id === documentId);
          if (country && country.files && country.files.iconFiles) {
            country.files.iconFiles = updatedFiles;
          }
          if (state.currentCountry.id === documentId) {
            state.currentCountry.files.iconFiles = updatedFiles;
          }
        }

        // Если коллекция - это города (cities)
        if (collectionName.includes("cities")) {
          const [countryId] = collectionName.split("/").slice(1, 2); // Извлекаем countryId из collectionName
          const country = state.countries.find((c) => c.id === countryId);
          if (country) {
            const city = country.cities.find((city) => city.id === documentId);
            if (city && city.files && city.files.iconFiles) {
              city.files.iconFiles = updatedFiles;
            }
            // Обновление текущего города в currentCity
            if (state.currentCity.id === documentId) {
              state.currentCity.files.iconFiles = updatedFiles;
            }
            // Обновление городов в currentCountry
            if (state.currentCountry.id === countryId) {
              const cityIndex = state.currentCountry.cities.findIndex(
                (city) => city.id === documentId
              );
              if (cityIndex !== -1) {
                state.currentCountry.cities[cityIndex].files.iconFiles =
                  updatedFiles;
              }
            }
          }
        }
      })

      .addCase(updateFilePriorityThunkV4.rejected, (state, action) => {
        state.loadingPriority = false;
        state.error = action.payload;
      })

      // Обработка updateCountryThunkV4
      .addCase(updateCountryThunkV4.pending, (state) => {
        state.updateCountryLoading = true;
      })

      .addCase(updateCountryThunkV4.fulfilled, (state, action) => {
        state.updateCountryLoading = false;
        const { countryId, updatedData } = action.payload;
        const countryIndex = state.countries.findIndex(
          (c) => c.id === countryId
        );
        if (countryIndex !== -1) {
          state.countries[countryIndex] = {
            ...state.countries[countryIndex],
            ...updatedData,
          };
        }
        if (state.currentCountry.id === countryId) {
          state.currentCountry = {
            ...state.currentCountry,
            ...updatedData,
          };
        }
      })

      .addCase(updateCountryThunkV4.rejected, (state, action) => {
        state.updateCountryLoading = false;
        state.error = action.payload;
      })

      // Обработка deleteCountryThunkV4
      .addCase(deleteCountryThunkV4.pending, (state) => {
        state.loading = true;
      })

      .addCase(deleteCountryThunkV4.fulfilled, (state, action) => {
        state.loading = false;
        const { countryId, remove } = action.payload;
        const country = state.countries.find((c) => c.id === countryId);
        if (country) {
          country.remove = remove;
        }
        if (state.currentCountry.id === countryId) {
          state.currentCountry.remove = remove;
        }
      })

      .addCase(deleteCountryThunkV4.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Обработка updateCityThunkV4
      .addCase(updateCityThunkV4.pending, (state) => {
        state.updateCityLoading = true;
      })

      .addCase(updateCityThunkV4.fulfilled, (state, action) => {
        state.updateCityLoading = false;

        const { countryId, cityId, updatedData } = action.payload;

        const country = state.countries.find((c) => c.id === countryId);
        if (country) {
          const cityIndex = country.cities.findIndex(
            (city) => city.id === cityId
          );
          if (cityIndex !== -1) {
            country.cities[cityIndex] = {
              ...country.cities[cityIndex],
              ...updatedData,
            };
          }
        }

        if (state.currentCountry.id === countryId) {
          const cityIndex = state.currentCountry.cities.findIndex(
            (city) => city.id === cityId
          );
          if (cityIndex !== -1) {
            state.currentCountry.cities[cityIndex] = {
              ...state.currentCountry.cities[cityIndex],
              ...updatedData,
            };
          }
        }

        if (state.currentCity.id === cityId) {
          state.currentCity = {
            ...state.currentCity,
            ...updatedData,
          };
        }
      })

      .addCase(updateCityThunkV4.rejected, (state, action) => {
        state.updateCityLoading = false;
        state.error = action.payload;
      })

      // Обработка deleteCityThunkV4
      .addCase(deleteCityThunkV4.pending, (state) => {
        state.loading = true;
        state.error = null;
      })

      .addCase(deleteCityThunkV4.fulfilled, (state, action) => {
        state.loading = false;
        const { countryId, cityId, remove } = action.payload;

        // Обновление данных в массиве стран
        const country = state.countries.find((c) => c.id === countryId);
        if (country) {
          const city = country.cities.find((city) => city.id === cityId);
          if (city) {
            city.remove = remove;
          }
        }

        // Обновление данных в текущем городе
        if (state.currentCity.id === cityId) {
          state.currentCity.remove = remove;
        }

        // Обновление данных в текущей стране
        if (state.currentCountry.id === countryId) {
          const cityInCurrentCountry = state.currentCountry.cities.find(
            (city) => city.id === cityId
          );
          if (cityInCurrentCountry) {
            cityInCurrentCountry.remove = remove;
          }
        }
      })

      .addCase(deleteCityThunkV4.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Обработка deleteFilesThunkV4
      .addCase(deleteFilesThunkV4.pending, (state) => {
        state.deleteFilesLoading = true;
      })

      .addCase(deleteFilesThunkV4.fulfilled, (state, action) => {
        state.deleteFilesLoading = false;
        const { documentId, fieldName, finalFiles } = action.payload;

        const updateFieldInState = (target, fieldName, finalFiles) => {
          const fieldParts = fieldName.split(".");
          let targetField = target;

          // Проходим по структуре, чтобы найти нужное поле
          for (let i = 0; i < fieldParts.length - 1; i++) {
            if (!targetField[fieldParts[i]]) {
              targetField[fieldParts[i]] = {};
            }
            targetField = targetField[fieldParts[i]];
          }

          // Заменяем соответствующее поле на значение из finalFiles
          targetField[fieldParts[fieldParts.length - 1]] =
            finalFiles.files[fieldParts[fieldParts.length - 1]];
        };

        // Обновление для текущей страны
        if (state.currentCountry.id === documentId) {
          updateFieldInState(state.currentCountry, fieldName, finalFiles);
        }

        // Обновление для текущего города
        if (state.currentCity.id === documentId) {
          updateFieldInState(state.currentCity, fieldName, finalFiles);
        }

        // Обновление в массиве стран
        state.countries = state.countries.map((country) => {
          if (country.id === documentId) {
            updateFieldInState(country, fieldName, finalFiles);
          }
          return country;
        });
      })
      .addCase(deleteFilesThunkV4.rejected, (state, action) => {
        state.deleteFilesLoading = false;
        state.error = action.payload;
      });
  },
});

export const {
  setCurrentCountry,
  setCurrentCity,
  setNullcatalogSliceV4,
  setInitialized,
} = catalogSliceV4.actions;
export const { reducer: catalogReducer } = catalogSliceV4;
export default catalogSliceV4.reducer;

// import { createSlice } from "@reduxjs/toolkit";
// import { createCountryThunkV4 } from "./thunk/catalog/V4/createCountryThunkV4";
// import { createCityThunkV4 } from "./thunk/catalog/V4/createCityThunkV4";
// import { createRentalPointThunkV4 } from "./thunk/catalog/V4/createRentalPointThunkV4";
// import { createBikeThunkV4 } from "./thunk/catalog/V4/createBikeThunkV4";
// import { updateFilePriorityThunkV4 } from "./thunk/catalog/V4/updateFilePriorityThunkV4";
// import { updateCountryThunkV4 } from "./thunk/catalog/V4/updateCountryThunkV4";
// import { deleteCountryThunkV4 } from "./thunk/catalog/V4/deleteCountryThunkV4";
// import { updateCityThunkV4 } from "./thunk/catalog/V4/updateCityThunkV4";
// import { deleteCityThunkV4 } from "./thunk/catalog/V4/deleteCityThunkV4";
// import { deleteFilesThunkV4 } from "./thunk/catalog/V4/deleteFilesThunkV4";
// import { fetchDataThunkV4 } from "./thunk/catalog/V4/fetchDataThunkV4";

// const initialState = {
//   // countriesData: [],

//   loadingCountries: false,
//   loadingCities: false,
//   loadingRentalPoints: false,
//   loadingBikes: false,

//   errorCountries: null,
//   errorCities: null,
//   errorRentalPoints: null,
//   errorBikes: null,

//   // catalog: [],
//   catalogLoading: false,

//   countries: [],
//   currentCountry: {},
//   updateCountryLoading: false,
//   createCountryLoading: false,

//   currentCity: {},
//   createCityLoading: false,
//   updateCityLoading: false,

//   rentalPoints: [],

//   bikes: [],

//   locations: [],
//   loadingLocations: false,

//   loading: false,

//   loadingPriority: false,
//   deleteFilesLoading: false,

//   isInitialized: false,

//   error: null,
// };

// const catalogSliceV4 = createSlice({
//   name: "catalogSliceV4",
//   initialState,
//   reducers: {
//     setInitialized: (state) => {
//       state.isInitialized = true;
//     },
//     // setNullcatalogSliceV4: (state, action) => {
//     //   state.countries = [];
//     //   state.currentCountry = {};
//     //   state.currentCity = {};
//     // },

//     setCurrentCountry: (state, action) => {
//       state.currentCountry = action.payload;
//     },
//     setCurrentCity: (state, action) => {
//       state.currentCity = action.payload;
//     },
//   },
//   extraReducers: (builder) => {
//     builder
//       //  Обработка fetchDataThunkV4
//       .addCase(fetchDataThunkV4.pending, (state, action) => {
//         const { countryId, cityId, rentalPointId, bikeId } =
//           action.meta.arg || {};

//         if (!countryId && !cityId && !rentalPointId && !bikeId) {
//           state.loadingCountries = true;
//           state.errorCountries = null;
//         } else if (countryId && !cityId) {
//           state.loadingCities = true;
//           state.errorCities = null;
//         } else if (countryId && cityId && !rentalPointId) {
//           state.loadingRentalPoints = true;
//           state.errorRentalPoints = null;
//         } else if (countryId && cityId && rentalPointId && !bikeId) {
//           state.loadingBikes = true;
//           state.errorBikes = null;
//         }
//       })
//       .addCase(fetchDataThunkV4.fulfilled, (state, action) => {
//         const { countryId, cityId, rentalPointId, bikeId } =
//           action.meta.arg || {};

//         // Проверка наличия данных в payload
//         if (!action.payload || action.payload.fromIndexedDB) {
//           console.log("Данные были получены из IndexedDB или отсутствуют.");
//           state.loadingCountries = false;
//           state.loadingCities = false;
//           state.loadingRentalPoints = false;
//           state.loadingBikes = false;
//           return;
//         }

//         const { data, lastUpdated } = action.payload;

//         if (!countryId && !cityId && !rentalPointId && !bikeId) {
//           console.log("Обновляем данные о странах:", data);

//           state.countries = data.map((country) => ({
//             ...country,
//             cities: country.cities || [],
//             lastUpdated, // Убедитесь, что обновляется и lastUpdated для стран
//           }));

//           state.loadingCountries = false;
//           console.log("Данные о странах обновлены:", state.countries);
//           // Обновление currentCountry, если текущая страна есть в новом списке

//           console.log(
//             "Обновление currentCountry, если текущая страна есть в новом списке:",
//             state.currentCountry
//           );

//           if (state.currentCountry.id) {
//             const updatedCountry = state.countries.find(
//               (country) => country.id === state.currentCountry.id
//             );
//             if (updatedCountry) {
//               state.currentCountry = updatedCountry;
//             }
//           }
//         } else if (countryId && !cityId) {
//           // Обновляем данные о городах в конкретной стране
//           state.countries = state.countries.map((country) => {
//             if (country.id === countryId) {
//               console.log(
//                 `Обновляем данные о городах в стране: ${JSON.stringify(
//                   country,
//                   null,
//                   2
//                 )} с id ${countryId}:`,
//                 data
//               );

//               // Обновляем lastUpdated для каждого города и страны
//               const updatedCountry = {
//                 ...country,
//                 cities: data.map((city) => ({
//                   ...city,
//                   rentalPointsData: city.rentalPointsData || [],
//                   lastUpdated, // Устанавливаем lastUpdated для каждого города
//                 })),
//                 lastUpdated, // Устанавливаем lastUpdated для страны
//               };

//               // Обновляем currentCountry, если обновляется текущая страна
//               if (state.currentCountry.id === countryId) {
//                 state.currentCountry = updatedCountry;
//               }

//               // Выводим обновленную страну в консоль после обновления городов
//               console.log(
//                 `Страна после обновления городов с id ${countryId}:`,
//                 JSON.stringify(updatedCountry, null, 2)
//               );
//               return updatedCountry;
//             }
//             return country;
//           });
//           state.loadingCities = false;
//         } else if (countryId && cityId && !rentalPointId) {
//           // Обновляем данные о точках проката в городе
//           state.countries = state.countries.map((country) => {
//             if (country.id === countryId) {
//               const updatedCitiesData = country.cities.map((city) => {
//                 if (city.id === cityId) {
//                   console.log(
//                     `Обновляем данные о точках проката в городе с id ${cityId}:`,
//                     data
//                   );
//                   const updatedCity = {
//                     ...city,
//                     rentalPointsData: data.map((point) => ({
//                       ...point,
//                       bikesData: point.bikesData || [],
//                     })),
//                     lastUpdated, // Устанавливаем lastUpdated для города
//                   };

//                   // Обновляем currentCity, если обновляется текущий город
//                   if (state.currentCity.id === cityId) {
//                     state.currentCity = updatedCity;
//                   }

//                   return updatedCity;
//                 }
//                 return city;
//               });
//               const updatedCountry = {
//                 ...country,
//                 cities: updatedCitiesData,
//               };

//               // Обновляем currentCountry, если обновляется текущая страна
//               if (state.currentCountry.id === countryId) {
//                 state.currentCountry = updatedCountry;
//               }

//               // Выводим обновленную страну в консоль после обновления точек проката в городах
//               console.log(
//                 `Страна после обновления точек проката в городах с id ${countryId}:`,
//                 JSON.stringify(updatedCountry, null, 2)
//               );

//               return updatedCountry;
//             }
//             return country;
//           });
//           state.loadingRentalPoints = false;
//         } else if (countryId && cityId && rentalPointId && !bikeId) {
//           // Обновляем данные о велосипедах в точке проката
//           state.countries = state.countries.map((country) => {
//             if (country.id === countryId) {
//               const updatedCitiesData = country.cities.map((city) => {
//                 if (city.id === cityId) {
//                   const updatedRentalPointsData = city.rentalPointsData.map(
//                     (point) => {
//                       if (point.id === rentalPointId) {
//                         console.log(
//                           `Обновляем данные о велосипедах в точке проката с id ${rentalPointId}:`,
//                           data
//                         );
//                         return {
//                           ...point,
//                           bikesData: data.map((bike) => ({ ...bike })),
//                           lastUpdated, // Устанавливаем lastUpdated для точки проката
//                         };
//                       }
//                       return point;
//                     }
//                   );
//                   const updatedCity = {
//                     ...city,
//                     rentalPointsData: updatedRentalPointsData,
//                   };

//                   // Обновляем currentCity, если обновляется текущий город
//                   if (state.currentCity.id === cityId) {
//                     state.currentCity = updatedCity;
//                   }

//                   return updatedCity;
//                 }
//                 return city;
//               });
//               const updatedCountry = {
//                 ...country,
//                 cities: updatedCitiesData,
//               };

//               // Обновляем currentCountry, если обновляется текущая страна
//               if (state.currentCountry.id === countryId) {
//                 state.currentCountry = updatedCountry;
//               }

//               return updatedCountry;
//             }
//             return country;
//           });
//           state.loadingBikes = false;
//         } else {
//           console.log("Условия для обновления данных не совпали.");
//         }
//       })

//       .addCase(fetchDataThunkV4.rejected, (state, action) => {
//         const { countryId, cityId, rentalPointId, bikeId } =
//           action.meta.arg || {};
//         const errorMessage = action.payload;

//         if (!countryId && !cityId && !rentalPointId && !bikeId) {
//           state.errorCountries = errorMessage;
//           state.loadingCountries = false;
//         } else if (countryId && !cityId) {
//           state.errorCities = errorMessage;
//           state.loadingCities = false;
//         } else if (countryId && cityId && !rentalPointId) {
//           state.errorRentalPoints = errorMessage;
//           state.loadingRentalPoints = false;
//         } else if (countryId && cityId && rentalPointId && !bikeId) {
//           state.errorBikes = errorMessage;
//           state.loadingBikes = false;
//         }
//       })
//       // Обработка createCountryThunkV4
//       .addCase(createCountryThunkV4.pending, (state) => {
//         state.createCountryLoading = true;
//       })
//       .addCase(createCountryThunkV4.fulfilled, (state, action) => {
//         state.createCountryLoading = false;
//         const { countryId, data } = action.payload;
//         state.countries.push({
//           id: countryId,
//           cities: [],
//           ...data,
//         });
//       })
//       .addCase(createCountryThunkV4.rejected, (state, action) => {
//         state.createCountryLoading = false;
//         state.error = action.payload;
//       })

//       // Обработка createCityThunkV4
//       .addCase(createCityThunkV4.pending, (state) => {
//         state.createCityLoading = true;
//       })
//       .addCase(createCityThunkV4.fulfilled, (state, action) => {
//         state.createCityLoading = false;

//         const { countryId, cityId, data } = action.payload;

//         const country = state.countries.find((c) => c.id === countryId);

//         if (country) {
//           if (!country.cities) country.cities = [];

//           // Добавляем новый город в список городов страны
//           const newCity = {
//             id: cityId,
//             ...data,
//           };
//           country.cities.push(newCity);

//           // Обновляем currentCountry, если добавленный город относится к нему
//           if (state.currentCountry.id === countryId) {
//             state.currentCountry.cities = [
//               ...(state.currentCountry.cities || []),
//               newCity,
//             ];
//           }
//         }
//       })

//       .addCase(createCityThunkV4.rejected, (state, action) => {
//         state.createCityLoading = false;
//         state.error = action.payload;
//       })

//       // Обработка createRentalPointThunkV4
//       .addCase(createRentalPointThunkV4.pending, (state) => {
//         state.loading = true;
//       })

//       .addCase(createRentalPointThunkV4.fulfilled, (state, action) => {
//         state.loading = false;
//         const country = state.countries.find(
//           (c) => c.id === action.payload.countryId
//         );
//         const city = country.cities.find(
//           (city) => city.id === action.payload.cityId
//         );
//         if (city) {
//           if (!city.rentalPoints) city.rentalPoints = [];
//           city.rentalPoints.push({
//             id: action.payload.rentalPointId,
//             name: action.payload.rentalPointName,
//             address: action.payload.address,
//             contactInfo: action.payload.contactInfo,
//           });
//         }
//       })

//       .addCase(createRentalPointThunkV4.rejected, (state, action) => {
//         state.loading = false;
//         state.error = action.payload;
//       })

//       // Обработка createBikeThunkV4
//       .addCase(createBikeThunkV4.pending, (state) => {
//         state.loading = true;
//       })

//       .addCase(createBikeThunkV4.fulfilled, (state, action) => {
//         state.loading = false;
//         const country = state.countries.find(
//           (c) => c.id === action.payload.countryId
//         );
//         const city = country.cities.find(
//           (city) => city.id === action.payload.cityId
//         );
//         const rentalPoint = city.rentalPoints.find(
//           (rp) => rp.id === action.payload.rentalPointId
//         );
//         if (rentalPoint) {
//           if (!rentalPoint.bikes) rentalPoint.bikes = [];
//           rentalPoint.bikes.push({
//             id: action.payload.bikeId,
//             model: action.payload.model,
//             brandId: action.payload.brandId,
//             categoryId: action.payload.categoryId,
//             availability: action.payload.availability,
//           });
//         }
//       })

//       .addCase(createBikeThunkV4.rejected, (state, action) => {
//         state.loading = false;
//         state.error = action.payload;
//       })

//       // Обработка updateFilePriorityThunkV4
//       .addCase(updateFilePriorityThunkV4.pending, (state) => {
//         state.loadingPriority = true;
//       })

//       .addCase(updateFilePriorityThunkV4.fulfilled, (state, action) => {
//         state.loadingPriority = false;
//         const { updatedFiles, collectionName, documentId } = action.payload;

//         // Если коллекция - это страны (countries)
//         if (collectionName.startsWith("catalog")) {
//           const country = state.countries.find((c) => c.id === documentId);
//           if (country && country.files && country.files.iconFiles) {
//             country.files.iconFiles = updatedFiles;
//           }
//           if (state.currentCountry.id === documentId) {
//             state.currentCountry.files.iconFiles = updatedFiles;
//           }
//         }

//         // Если коллекция - это города (cities)
//         if (collectionName.includes("cities")) {
//           const [countryId] = collectionName.split("/").slice(1, 2); // Извлекаем countryId из collectionName
//           const country = state.countries.find((c) => c.id === countryId);
//           if (country) {
//             const city = country.cities.find((city) => city.id === documentId);
//             if (city && city.files && city.files.iconFiles) {
//               city.files.iconFiles = updatedFiles;
//             }
//             // Обновление текущего города в currentCity
//             if (state.currentCity.id === documentId) {
//               state.currentCity.files.iconFiles = updatedFiles;
//             }
//             // Обновление городов в currentCountry
//             if (state.currentCountry.id === countryId) {
//               const cityIndex = state.currentCountry.cities.findIndex(
//                 (city) => city.id === documentId
//               );
//               if (cityIndex !== -1) {
//                 state.currentCountry.cities[cityIndex].files.iconFiles =
//                   updatedFiles;
//               }
//             }
//           }
//         }
//       })

//       .addCase(updateFilePriorityThunkV4.rejected, (state, action) => {
//         state.loadingPriority = false;
//         state.error = action.payload;
//       })

//       // Обработка updateCountryThunkV4
//       .addCase(updateCountryThunkV4.pending, (state) => {
//         state.updateCountryLoading = true;
//       })

//       .addCase(updateCountryThunkV4.fulfilled, (state, action) => {
//         state.updateCountryLoading = false;
//         const { countryId, updatedData } = action.payload;
//         const countryIndex = state.countries.findIndex(
//           (c) => c.id === countryId
//         );
//         if (countryIndex !== -1) {
//           state.countries[countryIndex] = {
//             ...state.countries[countryIndex],
//             ...updatedData,
//           };
//         }
//         if (state.currentCountry.id === countryId) {
//           state.currentCountry = {
//             ...state.currentCountry,
//             ...updatedData,
//           };
//         }
//       })

//       .addCase(updateCountryThunkV4.rejected, (state, action) => {
//         state.updateCountryLoading = false;
//         state.error = action.payload;
//       })

//       // Обработка deleteCountryThunkV4
//       .addCase(deleteCountryThunkV4.pending, (state) => {
//         state.loading = true;
//       })

//       .addCase(deleteCountryThunkV4.fulfilled, (state, action) => {
//         state.loading = false;
//         const { countryId, remove } = action.payload;
//         const country = state.countries.find((c) => c.id === countryId);
//         if (country) {
//           country.remove = remove;
//         }
//         if (state.currentCountry.id === countryId) {
//           state.currentCountry.remove = remove;
//         }
//       })

//       .addCase(deleteCountryThunkV4.rejected, (state, action) => {
//         state.loading = false;
//         state.error = action.payload;
//       })

//       // Обработка updateCityThunkV4
//       .addCase(updateCityThunkV4.pending, (state) => {
//         state.updateCityLoading = true;
//       })

//       .addCase(updateCityThunkV4.fulfilled, (state, action) => {
//         state.updateCityLoading = false;

//         const { countryId, cityId, updatedData } = action.payload;

//         const country = state.countries.find((c) => c.id === countryId);
//         if (country) {
//           const cityIndex = country.cities.findIndex(
//             (city) => city.id === cityId
//           );
//           if (cityIndex !== -1) {
//             country.cities[cityIndex] = {
//               ...country.cities[cityIndex],
//               ...updatedData,
//             };
//           }
//         }

//         if (state.currentCountry.id === countryId) {
//           const cityIndex = state.currentCountry.cities.findIndex(
//             (city) => city.id === cityId
//           );
//           if (cityIndex !== -1) {
//             state.currentCountry.cities[cityIndex] = {
//               ...state.currentCountry.cities[cityIndex],
//               ...updatedData,
//             };
//           }
//         }

//         if (state.currentCity.id === cityId) {
//           state.currentCity = {
//             ...state.currentCity,
//             ...updatedData,
//           };
//         }
//       })

//       .addCase(updateCityThunkV4.rejected, (state, action) => {
//         state.updateCityLoading = false;
//         state.error = action.payload;
//       })

//       // Обработка deleteCityThunkV4
//       .addCase(deleteCityThunkV4.pending, (state) => {
//         state.loading = true;
//         state.error = null;
//       })

//       .addCase(deleteCityThunkV4.fulfilled, (state, action) => {
//         state.loading = false;
//         const { countryId, cityId, remove } = action.payload;

//         // Обновление данных в массиве стран
//         const country = state.countries.find((c) => c.id === countryId);
//         if (country) {
//           const city = country.cities.find((city) => city.id === cityId);
//           if (city) {
//             city.remove = remove;
//           }
//         }

//         // Обновление данных в текущем городе
//         if (state.currentCity.id === cityId) {
//           state.currentCity.remove = remove;
//         }

//         // Обновление данных в текущей стране
//         if (state.currentCountry.id === countryId) {
//           const cityInCurrentCountry = state.currentCountry.cities.find(
//             (city) => city.id === cityId
//           );
//           if (cityInCurrentCountry) {
//             cityInCurrentCountry.remove = remove;
//           }
//         }
//       })

//       .addCase(deleteCityThunkV4.rejected, (state, action) => {
//         state.loading = false;
//         state.error = action.payload;
//       })

//       // Обработка deleteFilesThunkV4
//       .addCase(deleteFilesThunkV4.pending, (state) => {
//         state.deleteFilesLoading = true;
//       })

//       .addCase(deleteFilesThunkV4.fulfilled, (state, action) => {
//         state.deleteFilesLoading = false;
//         const { documentId, fieldName, finalFiles } = action.payload;

//         const updateFieldInState = (target, fieldName, finalFiles) => {
//           const fieldParts = fieldName.split(".");
//           let targetField = target;

//           // Проходим по структуре, чтобы найти нужное поле
//           for (let i = 0; i < fieldParts.length - 1; i++) {
//             if (!targetField[fieldParts[i]]) {
//               targetField[fieldParts[i]] = {};
//             }
//             targetField = targetField[fieldParts[i]];
//           }

//           // Заменяем соответствующее поле на значение из finalFiles
//           targetField[fieldParts[fieldParts.length - 1]] =
//             finalFiles.files[fieldParts[fieldParts.length - 1]];
//         };

//         // Обновление для текущей страны
//         if (state.currentCountry.id === documentId) {
//           updateFieldInState(state.currentCountry, fieldName, finalFiles);
//         }

//         // Обновление для текущего города
//         if (state.currentCity.id === documentId) {
//           updateFieldInState(state.currentCity, fieldName, finalFiles);
//         }

//         // Обновление в массиве стран
//         state.countries = state.countries.map((country) => {
//           if (country.id === documentId) {
//             updateFieldInState(country, fieldName, finalFiles);
//           }
//           return country;
//         });
//       })
//       .addCase(deleteFilesThunkV4.rejected, (state, action) => {
//         state.deleteFilesLoading = false;
//         state.error = action.payload;
//       });
//   },
// });

// export const {
//   setCurrentCountry,
//   setCurrentCity,
//   setNullcatalogSliceV4,
//   setInitialized,
// } = catalogSliceV4.actions;
// export const { reducer: catalogReducer } = catalogSliceV4;
// export default catalogSliceV4.reducer;
