import React, { useState } from "react";
import PopupDirrectory from "../Popups/PopupDirrectory";
import Preloader from "../../../../../UIElements/Preloader";
import Checkbox from "../Switcher/Checkbox";
import { useDispatch } from "react-redux";
import { deleteFilesThunkV4 } from "../../../../../../reducersToolkit/thunk/catalog/V4/deleteFilesThunkV4";
import Button from "../Buttons/Button";
import WindowFileDetailV4 from "./WindowFileDetailV4";
import { updateFilePriorityThunkV4 } from "../../../../../../reducersToolkit/thunk/catalog/V4/updateFilePriorityThunkV4";
import styled, { keyframes } from "styled-components";
import WidgetAlert from "../Alerts/WidgetAlert";

const fadeInOut = keyframes`
  0% {
    background-color: rgba(128, 128, 128, 0.1);
  }
  100% {
    background-color: rgba(128, 128, 128, 0.9);
  }
`;

const AnimatedDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${fadeInOut} 0.5s infinite alternate;
`;

const WidgetListFileV4 = ({
  isLoading,
  files,
  collectionName,
  documentId,
  fieldName,
  mode,
}) => {
  const dispatch = useDispatch();

  const [isOpenDetailPhoto, setIsOpenDetailPhoto] = useState(false);
  const [loadedImages, setLoadedImages] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [deletingFiles, setDeletingFiles] = useState([]);

  const handleClose = () => {
    setIsOpenDetailPhoto(false);
  };

  const handleToggleDetailPhoto = (fileItem) => {
    setSelectedFile(fileItem);
    setIsOpenDetailPhoto(true);
  };

  const handleImageLoad = (index) => {
    setLoadedImages((prev) => ({ ...prev, [index]: true }));
  };

  const handleCheckboxChange = (event, fileItem) => {
    event.stopPropagation();
    setSelectedFiles((prevSelectedFiles) => {
      if (prevSelectedFiles.includes(fileItem)) {
        return prevSelectedFiles.filter((item) => item !== fileItem);
      } else {
        return [...prevSelectedFiles, fileItem];
      }
    });
  };

  const handleDeleteFiles = () => {
    const fileIds = selectedFiles.map((file) => file.id);
    setDeletingFiles(fileIds);
    dispatch(
      deleteFilesThunkV4({
        collectionName,
        documentId,
        fieldName,
        fileIds,
      })
    ).then(() => {
      setDeletingFiles([]);
      setSelectedFiles([]);
    });
  };

  const handleDeleteOneFile = ({
    collectionName,
    documentId,
    fieldName,
    fileIds,
  }) => {
    setDeletingFiles(fileIds);
    dispatch(
      deleteFilesThunkV4({
        collectionName,
        documentId,
        fieldName,
        fileIds,
      })
    ).then(() => {
      setDeletingFiles([]);
      setSelectedFiles([]);
    });
  };

  const handleReset = () => {
    setDeletingFiles([]);
    setSelectedFiles([]);
  };

  const handlePriorityUpdate = async ({
    collectionName,
    documentId,
    fieldName,
    filesList,
    currentFile,
  }) => {
    try {
      const resultAction = await dispatch(
        updateFilePriorityThunkV4({
          collectionName,
          documentId,
          fieldName,
          filesList,
          currentFile,
        })
      );

      if (updateFilePriorityThunkV4.fulfilled.match(resultAction)) {
        console.log("Priority updated successfully");
      } else {
        console.error("Failed to update priority:", resultAction.payload);
      }
    } catch (error) {
      console.error("Error updating priority:", error);
    }
  };

  return (
    files?.length > 0 && (
      <>
        <div
          style={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            flex: "1",
            gap: "8px",
            boxSizing: "border-box",
          }}
        >
          {files?.map((fileItem, index) => (
            <div
              key={index}
              style={{
                overflow: "hidden",
                backgroundImage: loadedImages[index]
                  ? `url(${fileItem.url})`
                  : "none",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundColor: "var(--color-gray-100)",
                borderRadius: "8px",
                cursor: "pointer",
                position: "relative",
                flex: "1",
                ...(index === 2
                  ? {
                      gridColumn: "1 / span 2",
                      gridRow: "1 / span 2",
                    }
                  : { aspectRatio: "16/9" }),
              }}
            >
              {!loadedImages[index] && (
                <AnimatedDiv>
                  <Preloader color="--color-white" />
                </AnimatedDiv>
              )}

              {isLoading && (
                <AnimatedDiv>
                  <Preloader color="--color-white" />
                </AnimatedDiv>
              )}

              {deletingFiles.includes(fileItem.id) && (
                <div
                  style={{
                    position: "absolute",
                    zIndex: 1,
                    backgroundColor: "var(--color-gray-100-alpha-50)",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Preloader color="--color-white" />
                </div>
              )}

              <img
                src={fileItem.url}
                alt=""
                onLoad={() => handleImageLoad(index)}
                style={{ display: "none" }}
              />

              {mode === "edit" && !isLoading && (
                <div className="widgetListFile__checkbox-box">
                  <Checkbox
                    checked={selectedFiles.includes(fileItem)}
                    onChange={(e) => handleCheckboxChange(e, fileItem)}
                  />
                </div>
              )}

              {fileItem.priority && !isLoading && (
                <div
                  style={{
                    position: "absolute",
                    left: "0",
                    bottom: "0",
                    width: "auto",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    boxSizing: "border-box",
                    gap: "4px",
                    padding: "4px",
                    backgroundColor: "var(--color-success)",
                    borderRadius: "80px",
                    margin: "8px",
                    color: "var(--color-white)",
                  }}
                >
                  <span
                    className="material-symbols-outlined"
                    style={{
                      fontSize: "1rem",
                    }}
                  >
                    task_alt
                  </span>
                </div>
              )}

              <div
                onClick={() => handleToggleDetailPhoto(fileItem)}
                className="widgetListFile__cleek-box"
              ></div>
            </div>
          ))}
        </div>

        {selectedFiles.length > 0 && (
          <div
            style={{
              width: "100%",
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <>
              <Button
                type="small"
                color="--color-black"
                label="Cancel selections"
                onClick={handleReset}
                active={selectedFiles.length > 0 ? true : false}
              />
              <Button
                type="small"
                color="--color-alarm"
                label={`Remove ${
                  selectedFiles.length > 1
                    ? `${selectedFiles.length} files`
                    : "file"
                }`}
                onClick={handleDeleteFiles}
                active={selectedFiles.length > 0 ? true : false}
                allert={
                  <WidgetAlert
                    title={`Please confirm that you really want to delete
                      ${
                        selectedFiles.length === 1
                          ? "this file"
                          : `${selectedFiles.length} files.`
                      }`}
                  />
                }
              />
            </>
          </div>
        )}

        <PopupDirrectory
          isOpen={isOpenDetailPhoto}
          onClose={handleClose}
          title="Photo detail"
        >
          <WindowFileDetailV4
            onPriorityUpdate={handlePriorityUpdate}
            onDeleteFile={handleDeleteOneFile}
            currentFile={selectedFile}
            filesList={files}
            collectionName={collectionName}
            documentId={documentId}
            fieldName={fieldName}
            onClose={handleClose}
            mode={mode}
          />
        </PopupDirrectory>
      </>
    )
  );
};

export default WidgetListFileV4;
