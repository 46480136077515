// Функция для расчета стоимости аренды велосипеда
export const calculateBikeCost = (
  currentDateInterval, // Массив с начальной и конечной датой интервала
  bikeRates, // Массив ставок аренды велосипеда
  quantityBike = 1, // Количество велосипедов для аренды
  selectedAdditionalServices = [], // Массив выбранных дополнительных услуг
  serviceCommission = 10
) => {
  // Преобразование временных меток в объекты Date
  const startDate = new Date(currentDateInterval[0]);
  const endDate = new Date(currentDateInterval[1]);

  // Объект для хранения количества дней в каждом месяце интервала
  let monthDays = {};

  // Инициализация количества дней в месяце для каждого месяца между начальной и конечной датами
  for (
    let date = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
    date <= endDate;
    date.setMonth(date.getMonth() + 1)
  ) {
    monthDays[date.getMonth() + 1] = 0;
  }

  // Подсчет количества дней для каждого месяца в интервале
  for (
    let date = new Date(startDate);
    date <= endDate;
    date.setDate(date.getDate() + 1)
  ) {
    monthDays[date.getMonth() + 1]++;
  }

  // *** Расчет общей стоимости аренды велосипедов и скидок ***
  let totalBikeCost = 0; // Общая стоимость аренды велосипедов
  let totalDiscountAmount = 0; // Общая сумма скидок
  let orderDetailsForEachMonth = []; // Массив детализации заказа за каждый месяц

  Object.keys(monthDays).forEach((month) => {
    // Количество выбранныъ дней в месяце
    const daysInMonth = monthDays[month];
    const rate = bikeRates.find((rate) => rate.month === parseInt(month));

    if (rate && rate.price > 0) {
      // Стоимость заказа в конкретном месяце
      const monthlyCost = rate.price * daysInMonth * quantityBike;
      // Расчет суммы скидки
      const discountValue = (monthlyCost * (rate.discount || 0)) / 100;
      // Стоимость за месяц со скидкой
      const discountedMonthlyCost = monthlyCost - discountValue;

      // Добавляем детализацию заказа за каждый месяц в массив
      orderDetailsForEachMonth.push({
        monthName: rate.monthName,
        daysInMonth: daysInMonth,
        totalPriceInMonth: monthlyCost,
        discountAmount: discountValue,
        discountedMonthlyCost: discountedMonthlyCost,
      });

      // Общая стоимость байка
      totalBikeCost += monthlyCost;

      // Общая сумма скидки
      totalDiscountAmount += discountValue;
    }
  });

  // Стоимость (Сумма) дополнительных услуг
  const totalAdditionalServicesPrice = selectedAdditionalServices.reduce(
    (total, service) => total + service.servicePrice,
    0
  );

  // *** Итоговые расчеты ***

  // Общая стоимость аренды
  const totalPrice = totalBikeCost + totalAdditionalServicesPrice;
  // Общая стоимость аренды со скидкой
  const discountedTotalPrice = totalPrice - totalDiscountAmount;
  // ИТОГО дней на период аренды
  const totalDaysForTheRentalPeriod = Object.keys(monthDays).reduce(
    (total, month) => total + monthDays[month],
    0
  );

  // Объект с детальной информацией о стоимости аренды
  const costDetails = {
    // Средняя скидка за период
    averageDiscount: (totalDiscountAmount / totalBikeCost) * 100,
    // Стоимость ваучера
    voucherPrice: (totalPrice / 100) * serviceCommission,
    //Стоимость ваучера со скидкой
    discountedVoucherPrice: (discountedTotalPrice / 100) * serviceCommission,
    // Общая стоимость аренды
    totalPrice,
    // Общая стоимость аренды со скидкой
    discountedTotalPrice,
    // Оплата в точке проката
    paymentAtTheRentalPoint:
      totalPrice - (totalPrice / 100) * serviceCommission,
    // Оплата в точке проката со скидкой
    discountedPaymentAtTheRentalPoint:
      discountedTotalPrice - (discountedTotalPrice / 100) * serviceCommission,
    // Полная экономия благодаря скидке
    priceBenefit: totalDiscountAmount,
    // Средняя цена за байк в день
    averagePriceInDay: totalBikeCost / totalDaysForTheRentalPeriod,
    // Средняя цена за байк в день со скидкой
    discountedAveragePriceInDay:
      discountedTotalPrice / totalDaysForTheRentalPeriod,
    // Общее количество месяцев в интервале
    averagePriceInMonth: Object.keys(monthDays).length,
    // ИТОГО дней на период аренды
    totalDaysForTheRentalPeriod,
    // ИТОГО байков в заказе
    totalBikesOnOrder: quantityBike,
    // Стоимость дополнительных услуг
    priceAdditionalServises: totalAdditionalServicesPrice,
    // Интервал выбранных дат
    dateInterval: [
      startDate.toLocaleDateString("en-US", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }),
      endDate.toLocaleDateString("en-US", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }),
    ],
    dateIntervalTimestamp: [startDate, endDate],
    //детализация заказа за каждый месяц
    orderDetailsForEachMonth,
    //Цена за 1 байк
    pricPerBike: totalBikeCost / quantityBike,
    //Цена за 1 байк соскидкой
    discountedPricPerBike: discountedTotalPrice / quantityBike,
    // Стоимость всех байков
    totalPricPerBike: totalBikeCost,
    // Стоимость всех байков со скидкой
    discountedTotalPricPerBike: discountedTotalPrice,
  };

  console.log("calculateBikeCost-costDetails", costDetails);
  return costDetails;
};
