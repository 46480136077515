import React, { useEffect, useRef, useState } from "react";
import InputText from "../WidgetsProfile/Inputs/InputText";
import Textarea from "../WidgetsProfile/Textarea/Textarea";
import Button from "../WidgetsProfile/Buttons/Button";
import { useDispatch, useSelector } from "react-redux";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import { formatCurrencyUSD } from "../../../../../functions/currencyFormatter";
import {
  addAdditionalServiceModeration,
  deleteAdditionalServiceModeration,
  updateAdditionalServiceModeration,
} from "../../../../../reducersToolkit/moderationSliceV2";
import Counter from "../WidgetsProfile/Counter/Counter";

const WindowAditionalServiceDetailModerationV4 = ({
  mode,
  onClose,
  selectedAditionalService,
}) => {
  const dispatch = useDispatch();

  console.log("selectedAditionalService", selectedAditionalService);

  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState("");
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [counterValues, setCounterValues] = useState(0);
  const prevCounterValues = useRef(counterValues);

  const currentUser = useSelector((state) => state.userSliceV2.currentUser);

  let additionalService = {
    title: {
      titleOriginal: title,
    },
    description: {
      descriptionOriginal: description,
    },
    price: price,
    id: selectedServiceId || Date.now().toString(),
    counter: counterValues,
  };

  useEffect(() => {
    if (mode === "moderation") {
      setTitle(selectedAditionalService?.title?.titleOriginal);
      setDescription(
        selectedAditionalService?.description?.descriptionOriginal
      );
      setPrice(selectedAditionalService?.price);
      setSelectedServiceId(selectedAditionalService?.id);
      setCounterValues(selectedAditionalService?.counter || 0);
    }
  }, [mode, selectedAditionalService]);

  const handleAddAdditionalService = () => {
    dispatch(
      addAdditionalServiceModeration({
        additionalService: additionalService,
        userId: currentUser.id,
        mode: mode,
      })
    );

    onClose();
  };

  const handleUpdateAdditionalService = () => {
    dispatch(
      updateAdditionalServiceModeration({
        id: selectedServiceId,
        additionalService: additionalService,
        userId: currentUser.id,
        mode: mode,
      })
    );

    onClose();
  };

  const handleDeleteService = () => {
    if (selectedServiceId) {
      dispatch(
        deleteAdditionalServiceModeration({
          mode: mode,
          additionalServicesId: selectedServiceId,
        })
      );
    }
    additionalService = {};
    onClose();
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "8px",
      }}
    >
      <ContentBox position="start">
        <InputText
          label="Service name"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          onChange={(e) => setTitle(e.target.value)}
          value={title}
        />

        <Textarea
          label="Description aditional service"
          maxLength={500}
          onChange={(e) => setDescription(e.target.value)}
          value={description}
        />

        <InputText
          label="Price"
          type="number"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          onChange={(e) => setPrice(Number(e.target.value))}
          value={price}
        />

        <Counter
          maxValue={50}
          minValue={1}
          value={counterValues}
          onChange={(newCount) => {
            if (prevCounterValues.current !== newCount) {
              setCounterValues(newCount);
              prevCounterValues.current = newCount;
            }
          }}
          label="Number of additional services"
          description="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        />
      </ContentBox>
      <ContentBox position="end">
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "16px",
          }}
        >
          {!selectedAditionalService ? (
            <Button
              label="Add"
              onClick={handleAddAdditionalService}
              active={true}
              allert={
                <div
                  style={{
                    padding: "48px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "16px",
                    textAlign: "center",
                  }}
                >
                  <h1>Are you sure you want to save this information?</h1>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "2px",
                    }}
                  >
                    <p className="p-light">Service name: {description}</p>
                    <p className="p-light">
                      Description aditional service: {title}
                    </p>
                    <p className="p-light">Price: {formatCurrencyUSD(price)}</p>
                  </div>
                </div>
              }
            />
          ) : (
            <>
              <Button
                label="Remove"
                onClick={handleDeleteService}
                active={true}
                allert={
                  <div
                    style={{
                      padding: "48px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "16px",
                      textAlign: "center",
                    }}
                  >
                    <h1>
                      Are you sure you want to delete this additional service?
                    </h1>
                    <p className="p-light">
                      This action is irreversible, and you will lose all data
                      associated with this service.
                    </p>
                  </div>
                }
                color="--color-alarm"
              />

              <Button
                label="Update"
                onClick={handleUpdateAdditionalService}
                active={true}
                allert={
                  <div
                    style={{
                      padding: "48px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "16px",
                      textAlign: "center",
                    }}
                  >
                    <h1>Are you sure you want to save this information?</h1>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <p className="p-light">Service name: {description}</p>
                      <p className="p-light">
                        Description aditional service: {title}
                      </p>
                      <p className="p-light">
                        Price: {formatCurrencyUSD(price)}
                      </p>
                    </div>
                  </div>
                }
              />
            </>
          )}
        </div>
      </ContentBox>
    </div>
  );
};

export default WindowAditionalServiceDetailModerationV4;
