function priorityFile(files) {
  // Проверяем, что входной аргумент является массивом
  if (!Array.isArray(files)) {
    console.error("Input should be an array");
    return null; // Или можно вернуть какое-то значение по умолчанию
  }

  // Ищем объект с флагом priority === true
  const priorityObject = files.find((file) => file.priority === true);

  // Если такой объект найден, возвращаем его, иначе возвращаем первый объект в массиве
  return priorityObject || files[0];
}

export default priorityFile;
