import React, { useState, useEffect } from "react";
import "firebase/compat/firestore";
import Button from "../../../../../UIElements/Button";
import Popup from "../../../../../UIElements/Popup";
import {
  fetchDocumentById,
  updateDocumentById,
} from "../../../../../../services/firebase/crudFirebaseStorage";
import { TextareaWrite } from "../../../../../UIElements/Textarea";
import InputV2 from "../../../../../UIElements/InputV2";

/**
 * Компонент WigetRate для работы с рейтами.
 * @param {string} collectionName - Название коллекции в Firestore.
 * @param {string} idDocument - Идентификатор документа в коллекции.
 * @param {string} fieldName - Имя поля, содержащего рейты.
 * @param {string} title - Заголовок компонента.
 */
export const WigetRate = ({
  collectionName,
  idDocument,
  fieldName,
  title,
  description,
  quantity,
}) => {
  // Инициализация состояний компонента
  const [activePopup, setActivePopup] = useState(null);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [markedForDelete, setMarkedForDelete] = useState({});

  const maxLength = 1000; // Максимальная длина значения

  // Получение данных при монтировании компонента
  useEffect(() => {
    // Инициализация формы с начальными данными
    const initialFormData = Array.from({ length: 12 }, (_, index) => ({
      month: index + 1,
      price: 0,
      discount: 0,
      description: "",
    }));

    /**
     * Функция для получения данных пользователя из Firestore.
     */
    const fetchUserData = async () => {
      setIsLoading(true);
      const data = await fetchDocumentById(collectionName, idDocument);
      if (data && data[fieldName]) {
        const rates = Array.isArray(data[fieldName])
          ? data[fieldName]
          : [data[fieldName]];
        setData(rates);
      } else {
        setData(initialFormData);
      }
      setIsLoading(false);
    };

    // Вызов функции для получения данных пользователя при монтировании компонента
    fetchUserData();
  }, [collectionName, idDocument, fieldName]);

  /**
   * Обработчик удаления отмеченных элементов.
   */
  const handleDeleteMarked = async () => {
    setIsLoading(true);
    const updatedData = data.filter((_, index) => !markedForDelete[index]);
    setData(updatedData);
    await updateDocumentById(
      { [fieldName]: updatedData },
      collectionName,
      idDocument
    );
    setIsLoading(false);
    setIsChanged(false);
    setMarkedForDelete({});
    console.log("Удаленные данные успешно обновлены");
  };

  /**
   * Обработчик изменения значений полей формы.
   * @param {object} e - Событие изменения поля ввода.
   * @param {number} index - Индекс рейта в форме.
   * @param {string} field - Название поля (price или description).
   */
  const handleChange = (e, index, field) => {
    const { value } = e.target;

    // Если поле price, проверяем, является ли значение числом
    if (field === "price" && isNaN(Number(value))) {
      return;
    }

    setData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index][field] = value;
      return updatedData;
    });
    setIsChanged(true);
  };

  /**
   * Обработчик отметки элементов для удаления.
   * @param {number} index - Индекс рейта.
   * @param {boolean} isChecked - Флаг отметки.
   */
  const handleMarkForDelete = (index, isChecked) => {
    setMarkedForDelete((prevState) => ({
      ...prevState,
      [index]: isChecked,
    }));
  };

  /**
   * Обработчик отправки формы.
   * @param {object} e - Событие отправки формы.
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Преобразование значения price в число
    const updatedData = data.map((rate) => ({
      ...rate,
      price: Number(rate.price),
    }));

    await updateDocumentById(
      { [fieldName]: updatedData },
      collectionName,
      idDocument
    );
    setIsLoading(false);
    setIsChanged(false);
    console.log("Данные успешно обновлены");
  };

  /**
   * Обработчики открытия и закрытия всплывающего окна.
   * @param {number} index - Индекс рейта.
   */
  const handleOpenPopup = (index) => setActivePopup(index);
  const handleClosePopup = () => setActivePopup(null);

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();

  // Проверить, есть ли помеченные для удаления элементы
  const isAnyMarkedForDelete = () => {
    return Object.values(markedForDelete).some((value) => value);
  };

  const formatUSD = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  // Рендеринг компонента
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "0 16px",
          boxSizing: "border-box",
        }}
      >
        <div
          style={{
            backgroundColor: "var(--color-white)",
            boxSizing: "border-box",
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              height: "48px",
              padding: "0 16px",
              ...(data.length > 0 && {
                borderBottom: "1px var(--color-gray-200) solid",
              }),
            }}
          >
            <p
              style={{
                textTransform: "uppercase",
              }}
              className="p-light"
            >
              {title}
            </p>
          </div>

          <div
            style={{
              display: "flex",
              flex: "1",
              boxSizing: "border-box",
              backgroundColor: "var(--color-white)",
              width: "100%",
              padding: "8px",
            }}
            // className='wigetRate'
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                gap: "8px",
                boxSizing: "border-box",
                width: "100%",
              }}
            >
              {data.map((rate, index) => (
                <div
                  style={{
                    aspectRatio: "2/1",
                    borderRadius: "8px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                    padding: "8px",
                    boxSizing: "border-box",
                    cursor: "pointer",
                    ...(rate.price > 0
                      ? {
                          backgroundColor: "var(--color-success)",
                          color: "var(--color-white)",
                        }
                      : {
                          backgroundColor: "var(--color-gray-100)",
                        }),
                  }}
                  // className={rate.price <= 0 ? 'wigetRate-mounth-item wigetRate-mounth-noPrice' : 'wigetRate-mounth-item'}

                  key={index}
                  onClick={() => handleOpenPopup(index)}
                >
                  <div className="wigetRate-currentMonth">
                    <p
                      style={{
                        fontWeight: "500",
                        padding: "4px",
                      }}
                    >
                      {rate.monthName && rate.monthName.slice(0, 3)}
                    </p>

                    {index === currentMonth ? (
                      <div
                        className={
                          rate.price <= 0
                            ? "wigetRate-currentMonth-point"
                            : "wigetRate-currentMonth-point wigetRate-currentMonth-noPrice"
                        }
                      ></div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <p>{formatUSD.format(rate.price)}</p>

                    {rate.discount > 0 && (
                      <div
                        style={{
                          // background: 'var(--gradient-primary-2)',
                          backgroundColor: "var(--color-warning)",
                          padding: "4px 8px",
                          borderRadius: "4px",
                        }}
                      >
                        <p
                          style={{
                            color: "var(--color-black)",
                          }}
                          className="p-mini"
                        >
                          -{rate.discount}%
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {description && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "8px 16px",
          }}
        >
          <p className="p-light">{description}</p>
        </div>
      )}

      {data.map((rate, index) => (
        <Popup
          key={index}
          isOpen={activePopup === index}
          onClose={handleClosePopup}
          title={title}
          leftMenu={<></>}
          rightMenu={
            <>
              {isChanged ? (
                <Button
                  onClick={handleSubmit}
                  disabled={isLoading}
                  children={isLoading ? "...Loading" : "Save"}
                />
              ) : (
                <Button type={"disable"} children={"Save"} />
              )}
            </>
          }
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eleifend ipsum vitae nunc gravida facilisis. Pellentesque finibus, justo id tincidunt accumsan, mauris elit consequat nulla, vel tempus mi lorem vel ipsum. Integer id tincidunt sapien. Cras faucibus euismod massa eget interdum. Sed commodo, ante eu tempor condimentum, elit dui lacinia nisi, in pulvinar enim enim ut orci. Suspendisse potenti."
        >
          <form
            onSubmit={handleSubmit}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              width: "100%",
            }}
          >
            {/* <input
                            type="checkbox"
                            checked={!!markedForDelete[index]}
                            onChange={(e) => handleMarkForDelete(index, e.target.checked)}
                        /> */}

            <div className="wigetRate-price-box">
              <InputV2
                label="price"
                type="number"
                value={rate.price}
                onChange={(e) => handleChange(e, index, "price")}
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
              />

              <InputV2
                label="Discount"
                type="number"
                value={rate.discount}
                onChange={(e) => handleChange(e, index, "discount")}
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
              />
            </div>

            <TextareaWrite
              label="Description"
              value={rate.description}
              onChange={(e) => handleChange(e, index, "description")}
              maxLength={maxLength}
              escription="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            />
          </form>
        </Popup>
      ))}
    </>
  );
};
