import React, { useState } from "react";
import WindowMainFilter from "./WindowMainFilter";
import PopupDirrectory from "../../../../Private/Login/Profile/WidgetsProfile/Popups/PopupDirrectory";

const WidgetMainFilter = ({
  countyFilter = {},
  cityFilter = {},
  qualityBike = 0,
  bookingDates = [0, 0],
}) => {
  const [isOpenWindowMainFilter, setIsOpenWindowMainFilter] = useState(false);

  const handleOpenWindowMainFilter = () => {
    setIsOpenWindowMainFilter(true);
  };

  const onClose = () => {
    setIsOpenWindowMainFilter(false);
  };

  return (
    <>
      <div
        style={{
          position: "fixed",
          top: "80px",
          left: "50%",
          transform: "translateX(-50%)",
          width: "calc(100% - 32px)", // Ширина учитывая отступы по 16px с каждой стороны
          maxWidth: "calc(var(--width-mobile-content) - 0px)",
          background: "var(--color-black)",
          boxSizing: "border-box",
          backgroundColor: "var(--color-white)",
          padding: "4px 4px 4px 16px",
          borderRadius: "18px",
          zIndex: "5",
          boxShadow: "0px 16px 16px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div
          onClick={handleOpenWindowMainFilter}
          style={{
            width: "100%",
            cursor: "pointer",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <p className="p-light">{countyFilter?.title || "All country"},</p>
            <p className="p-light">{cityFilter?.title || "All city"},</p>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "4px",
                }}
                className="p-light"
              >
                <p>
                  {bookingDates[0]
                    ? new Date(bookingDates[0]).getDate()
                    : "Date not selected"}
                </p>
                <p>
                  {bookingDates[0]
                    ? new Date(bookingDates[0]).toLocaleString("en-US", {
                        month: "short",
                      })
                    : "Month"}
                </p>
              </div>
              <p className="p-light">—</p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "4px",
                }}
                className="p-light"
              >
                <p>
                  {bookingDates[1]
                    ? new Date(bookingDates[1]).getDate()
                    : "Date not selected"}
                </p>

                <p>
                  {bookingDates[1]
                    ? new Date(bookingDates[1]).toLocaleString("en-US", {
                        month: "short",
                      })
                    : "Month"}
                </p>
              </div>
            </div>

            {/* <p className="p-light">{qualityBike}</p> */}
          </div>

          <div
            style={{
              background: "var(--color-black)",
              aspectRatio: "1/1",
              width: "48px",
              borderRadius: "16px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src="https://firebasestorage.googleapis.com/v0/b/biketripste.appspot.com/o/static%2Ffiles%2Fsearch.svg?alt=media&token=e7592b0f-6580-4120-95c0-7adbfca2928a"
              alt="search icon"
              style={{
                width: "24px",
              }}
            />
            {/* <span className="material-symbols-outlined">search</span> */}
          </div>
        </div>
      </div>
      <PopupDirrectory
        title="title"
        isOpen={isOpenWindowMainFilter}
        onClose={onClose}
      >
        <WindowMainFilter onClose={onClose} />
      </PopupDirrectory>
    </>
  );
};

export default WidgetMainFilter;
