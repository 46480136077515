import React, { useState } from "react";
import ImageWithLoader from "../../UIComponents/bars/gallery/ImageWithLoader";

const WigetListSinglePhoto = ({ photos }) => {
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);

  // Проверяем, есть ли фотографии
  if (!photos || photos.length === 0) {
    return null; // Если нет фотографий, ничего не отображаем
  }

  // Функция для переключения на следующую фотографию
  const nextPhoto = () => {
    const newIndex = (currentPhotoIndex + 1) % photos.length;
    setCurrentPhotoIndex(newIndex);
  };

  // Функция для переключения на определенную фотографию
  const goToPhoto = (index) => {
    setCurrentPhotoIndex(index);
  };

  // Извлекаем текущую фотографию из массива
  const currentPhoto = photos[currentPhotoIndex];

  // Создаем индикаторы для фотографий
  const indicators = photos.map((_, index) => (
    <div
      key={index}
      onClick={() => goToPhoto(index)}
      style={{
        width: "5px",
        height: "5px",
        borderRadius: "80px",
        backgroundColor: "var(--color-white)",
        opacity: index === currentPhotoIndex ? "1" : "0.5",
        cursor: "pointer",
        marginRight: "8px",
        boxSizing: "border-box",
      }}
    />
  ));

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        aspectRatio: "16/9",
        // padding: "8px",
        flex: "1",
        // backgroundColor: "var(--color-gray-200)",
        // // backgroundImage: `url(${currentPhoto.url})`,
        // height: "100%",
        // width: "100%",
        // backgroundRepeat: "no-repeat",
        // backgroundSize: "cover",
        // backgroundPosition: "center",
        // borderRadius: "0",
        // boxSizing: "border-box",
        // objectFit: "cover",
      }}
      onClick={nextPhoto}
      alt={currentPhoto.name}
    >
      <div
        style={{
          position: "absolute",
          bottom: "0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
          height: "24px",
          width: "100%",
          boxSizing: "border-box",
          //   backgroundColor: "violet",
        }}
      >
        {indicators}
      </div>

      <div
        style={{
          width: "100%",
          aspectRatio: "16/9",
        }}
      >
        <ImageWithLoader
          aspectRatio="16/9"
          url={currentPhoto.url}
          alt={currentPhoto.url}
        />
      </div>
    </div>
  );
};

export default WigetListSinglePhoto;
