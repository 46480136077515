import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchDocumentsByIds } from "../../../../services/firebase/crudFirestore";

export const fetchCountryAndCityThunk = createAsyncThunk(
  "directory/countryAndCity/fetchCountryAndCityThunk",
  async (_, { rejectWithValue }) => {
    try {
      const countryData = await fetchDocumentsByIds("country");
      console.log("countryWithCities countryData", countryData);
      const cityData = await fetchDocumentsByIds("city");
      console.log("countryWithCities cityData", cityData);

      // Проверка наличия данных
      if (!countryData || !cityData) {
        throw new Error("Не удалось получить данные стран или городов");
      }

      // Фильтрация городов на основе country.city_id
      const countryWithCities = countryData.map((country) => {
        console.log("Обработка страны:", country);
        const cities = cityData.filter((city) => {
          console.log("Проверка города:", city);
          return country?.city_id?.includes(city?.id);
        });
        console.log("Найденные города для страны:", cities);
        return {
          ...country,
          cities,
        };
      });

      console.log("countryWithCities 500", countryWithCities);

      return countryWithCities;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
