import React, { useEffect, useState } from "react";
import InputText from "../WidgetsProfile/Inputs/InputText";
import Button from "../WidgetsProfile/Buttons/Button";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import { useDispatch, useSelector } from "react-redux";
import { setLocationAddress } from "../../../../../reducersToolkit/rentalPointsSliiceV2";
import ButtonsBar from "../WidgetsProfile/ButtonsBar/ButtonsBar";

const WindowRentalPontLocation = ({ mode, onClose }) => {
  const dispatch = useDispatch();

  const [pointAddress, setPointAddress] = useState("");

  const { selectedRentalPoint, newRentalPoint } = useSelector(
    (state) => state.rentalPointsSliiceV2
  );

  useEffect(() => {
    if (mode === "new") {
      setPointAddress(newRentalPoint.location.pointAddress);
    } else if (mode === "edit") {
      setPointAddress(selectedRentalPoint.location.pointAddress);
    }
  }, [newRentalPoint, selectedRentalPoint, mode]);

  const handleSetLocation = (event) => {
    setPointAddress(event.target.value);
  };

  const handleApply = () => {
    dispatch(setLocationAddress({ mode, pointAddress }));
    onClose();
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "8px",
          paddingBottom: "72px",
        }}
      >
        <ContentBox position="start">
          <InputText
            label="Address"
            value={pointAddress}
            onChange={handleSetLocation}
          />
        </ContentBox>
      </div>
      <ButtonsBar>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "16px",
            width: "100%",
          }}
        >
          <Button
            type="small"
            label="Cancel"
            active={true}
            color="--color-black"
            onClick={handleCancel}
          />
          <Button
            type="small"
            label="Apply"
            active={true}
            onClick={handleApply}
          />
        </div>
      </ButtonsBar>
    </>
  );
};

export default WindowRentalPontLocation;
