import React, { useEffect, useState } from "react";
import "../profile.css";
import "./bikes.css";
import PopupDirrectory from "../WidgetsProfile/Popups/PopupDirrectory";
import WindowBikePricePerMonth from "./WindowBikePricePerMonth";
import { formatCurrencyUSD } from "../../../../../functions/currencyFormatter";
import WindowBikePricePerMonthModeration from "../Moderation/WindowBikePricePerMonthModeration";
import { useDispatch } from "react-redux";

const getMonthName = (monthNumber) => {
  const date = new Date(2000, monthNumber, 1);
  const monthName = date.toLocaleString("en-US", { month: "long" });
  return monthName;
};

const WeekMonthItem = ({ itemKey, month, rateInDay, onClick, discount }) => {
  const monthName = getMonthName(month);
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();

  return (
    <div
      key={itemKey}
      className="bikes__month-content-box"
      onClick={onClick}
      style={{
        ...(rateInDay > 0 && {
          backgroundColor: "var(--color-success)",
          color: "var(--color-white)",
        }),
      }}
    >
      <div className="bikes__month">
        <p>{monthName}</p>
        {currentMonth === month && (
          <span className="material-symbols-outlined">check_small</span>
        )}
      </div>
      <div className="bikes__rate-in-day">
        <p
          className="p-light"
          style={{
            ...(rateInDay > 0 && {
              color: "var(--color-white)",
            }),
          }}
        >
          {formatCurrencyUSD(rateInDay)}
        </p>
        {discount > 0 && (
          <div className="bikes__sale">
            <p
              className="p-light"
              style={{ color: "var(--color-black)" }}
            >{`-${discount}% off`}</p>
          </div>
        )}
      </div>
    </div>
  );
};

const WidgetBikeRate = ({ mode, rate }) => {
  const [windowBikePricePerMonth, setWindowBikePricePerMonth] = useState(false);
  const [
    windowBikePricePerMonthModeration,
    setWindowBikePricePerMonthModeration,
  ] = useState(false);
  const [indexMonth, setIndexMonth] = useState(null);

  const handleClose = () => {
    setIndexMonth(null);
    setWindowBikePricePerMonth(false);
    setWindowBikePricePerMonthModeration(false);
  };

  const handleToggle = (index) => {
    if (mode === "edit" || mode === "new") {
      setWindowBikePricePerMonth(true);
    }
    if (mode === "moderation" || mode === "moderatorAdded") {
      setWindowBikePricePerMonthModeration(true);
    }
    setIndexMonth(index);
  };

  return (
    <>
      <div className="profile-widget__content-box">
        <div className="bikes-rate">
          {rate?.map((rateItem, index) => (
            <WeekMonthItem
              key={index}
              itemKey={index}
              month={index}
              rateInDay={rateItem.price}
              discount={rateItem.discount}
              onClick={() => handleToggle(index)}
            />
          ))}
        </div>
      </div>
      <PopupDirrectory
        isOpen={windowBikePricePerMonth}
        onClose={handleClose}
        title="Edit timetable"
      >
        <WindowBikePricePerMonth
          mode={mode}
          index={indexMonth}
          onClose={handleClose}
        />
      </PopupDirrectory>

      <PopupDirrectory
        isOpen={windowBikePricePerMonthModeration}
        onClose={handleClose}
        title="Edit timetable moderation"
      >
        <WindowBikePricePerMonthModeration
          mode={mode}
          index={indexMonth}
          onClose={handleClose}
        />
      </PopupDirrectory>
    </>
  );
};

export default WidgetBikeRate;
