const currentTimestamp = new Date().getTime();

export const structureDb = {
  user: {
    rentalPoints_id: [],
    userData: "",
    userRole: "",
    name: "",
    setings: {
      currency_id: [],
    },
    user: {},
    orders_id: [],
    OrderStatus: {
      confirmationReceived: false,
      replacement: {
        searchingForReplacement: false,
        replacementFound: [{ Replacement: {}, selected: false }],
      },
    },
  },

  rentalPoints: {
    created: currentTimestamp,
    title: [],
    currency_id: [],
    // pointName: [],
    pointAddress: {
      address: "",
      location: {
        lat: null,
        lng: null,
      },
      city: "",
      country: "",
    },
    file_map: [],
    bikes_id: [],
    emails: [],
    phonesNumber: [],
    file_license: [],
    file_photo: [],
    file_logo: [],
    description: [],
    city_id: [],
    timetableL: [
      {
        day: 1,
        weekday: "Monday",
        open: "9:00",
        close: "18:00",
        description: "",
        holiday: false,
      },
      {
        day: 2,
        weekday: "Tuesday",
        open: "9:00",
        close: "18:00",
        description: "",
        holiday: false,
      },
      {
        day: 3,
        weekday: "Wednesday",
        open: "9:00",
        close: "18:00",
        description: "",
        holiday: false,
      },
      {
        day: 4,
        weekday: "Thursday",
        open: "9:00",
        close: "18:00",
        description: "",
        holiday: false,
      },
      {
        day: 5,
        weekday: "Friday",
        open: "9:00",
        close: "18:00",
        description: "",
        holiday: false,
      },
      {
        day: 6,
        weekday: "Saturday",
        open: "9:00",
        close: "18:00",
        description: "",
        holiday: true,
      },
      {
        day: 7,
        weekday: "Sunday",
        open: "9:00",
        close: "18:00",
        description: "",
        holiday: true,
      },
    ],
    additionalServices: [
      {
        title: "",
        description: "",
        characteristics: "",
        price: 0,
      },
    ],
  },

  bikes: {
    created: currentTimestamp,
    title: [],
    bikeName: [],
    characteristics: [],
    description: [],
    url: "",
    rate: [
      {
        month: 1,
        monthName: "January",
        price: 0,
        discount: 0,
        description: "",
      },
      {
        month: 2,
        monthName: "February",
        price: 0,
        discount: 0,
        description: "",
      },
      {
        month: 3,
        monthName: "March",
        price: 0,
        discount: 0,
        description: "",
      },
      {
        month: 4,
        monthName: "April",
        price: 0,
        discount: 0,
        description: "",
      },
      {
        month: 5,
        monthName: "May",
        price: 0,
        discount: 0,
        description: "",
      },
      {
        month: 6,
        monthName: "June",
        price: 0,
        discount: 0,
        description: "",
      },
      {
        month: 7,
        monthName: "July",
        price: 0,
        discount: 0,
        description: "",
      },
      {
        month: 8,
        monthName: "August",
        price: 0,
        discount: 0,
        description: "",
      },
      {
        month: 9,
        monthName: "September",
        price: 0,
        discount: 0,
        description: "",
      },
      {
        month: 10,
        monthName: "October",
        price: 0,
        discount: 0,
        description: "",
      },
      {
        month: 11,
        monthName: "November",
        price: 0,
        discount: 0,
        description: "",
      },
      {
        month: 12,
        monthName: "December",
        price: 0,
        discount: 0,
        description: "",
      },
    ],
    brand_id: [],
    categoryes_id: [],
    file_bikePhoto: [],
    active: false,
  },

  brand: {
    created: currentTimestamp,
    title: [],
    description: [],
    file_icon: [],
  },

  currency: {
    created: currentTimestamp,
    title: [],
    description: [],
    file_icon: [],
  },

  category: {
    created: currentTimestamp,
    title: [],
    description: [],
    file_icon: [],
  },

  faq: {
    created: currentTimestamp,
    question: [],
    answer: [],
    file_icon: [],
  },

  news: {
    created: currentTimestamp,
    date: [],
    title: [],
    description: [],
    file_photo: [],
  },

  stories: {
    created: currentTimestamp,
    date: [],
    title: [],
    description: [],
    file_photo: [],
    linc: [],
  },

  country: {
    created: currentTimestamp,
    title: [],
    description: [],
    file_photo: [],
    file_flag: [],
    file_map: [],
    city_id: [],
  },

  city: {
    created: currentTimestamp,
    title: [],
    description: [],
    file_photo: [],
  },

  orders: {
    user: {
      user_id: "",
      currentUserEmail: "",
      currentUserPhone: "",
      currentUserPhoto: "",
    },
    client: {
      name: "",
      email: "",
      phone: "",
      comment: "",
    },
    order_info: {
      created: currentTimestamp,
      rentalPoints_id: "",
      bikes_id: "",
      dateInterval: "",
      quantity: 0,
      pricePayNow: 0,
      priceOneBike: 0,
      pricePayNowDiscount: 0,
      rentalPrice: 0,
      rentalPriceDiscount: 0,
      rentalPriceSaving: 0,
      totalOrderPriceCurrency: 0,
      totalOrderPriceDiscountCurrency: 0,
      additionalServices: [],
      additionalServicesPrice: 0,
    },
  },
};
