import React from "react";
import Preloader from "../../../UIElements/Preloader";
import "./ContentBoxPublic.css";

const ContentBoxPublic = ({ children, position, isLoading, label }) => {
  return (
    <div
      className={`content-box-public ${
        position === "start"
          ? "block-section__start-box"
          : position === "end"
          ? "block-section__end-box"
          : position === "one"
          ? "block-section__one-box"
          : "block-section__medium-box"
      }`}
    >
      <div
        className="profile-block-section__content-box"
        style={{
          width: "100%",

          ...(!isLoading &&
            {
              // aspectRatio: "16/9",
            }),
        }}
      >
        {isLoading ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
            }}
          >
            <Preloader />
            {label && (
              <p className="p-light" style={{ color: "var(--color-gray-500)" }}>
                {label}
              </p>
            )}
          </div>
        ) : (
          <div
            style={{
              width: "100%",
            }}
          >
            {children}
          </div>
        )}
      </div>
    </div>
  );
};

export default ContentBoxPublic;
