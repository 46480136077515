import { useEffect, useState } from "react";
import { useSubscribeData } from "../../../../../../services/firebase/crudFirebaseStorage";
import { useDispatch, useSelector } from "react-redux";
import {
  setAllOrders,
  setCurrentOrder,
} from "../../../../../../slice/stateGUISlice";
import SearchBars from "../../../../../UIComponents/bars/searchBars/SearchBars";
import Icon44 from "../../../../../UIComponents/icons/Icon44";
import { useRef } from "react";
import OdrderDetailPrice from "./OdrderDetailPrice";
import ImageWithLoader from "../../../../../UIComponents/bars/gallery/ImageWithLoader";
import OrderTimeline from "./OrderTimeline";
import PreviewGallery from "../../../../../UIComponents/bars/gallery/PreviewGallery";
import Loading from "../../../../../UIElements/Loading";

const OrdersList = () => {
  // Инициализация Redux
  const dispatch = useDispatch();

  // Локальные состояния для управления заказами и идентификаторами точек аренды
  const [orders, setOrders] = useState([]);
  const [loadingComponent, setLoadingComponent] = useState(false);
  const [rentalPointIds, setRentalPointIds] = useState([]);

  const containerRef = useRef(null);

  const brandData = useSelector((state) => state.brand.data);
  console.log("OrdersList200-brandData", brandData);
  const categoryData = useSelector((state) => state.category.data);
  console.log("OrdersList200-categoryData", categoryData);
  const bikeData = useSelector((state) => state.bike.data);
  console.log("OrdersList200-bikeData", bikeData);

  // Получение данных о заказах из Firebase
  const { data: ordersData, isLoading: loadingOrdersData } =
    useSubscribeData("orders");
  console.log("OrdersList200-ordersData", ordersData);

  // Селекторы Redux для получения необходимых данных из хранилища
  const currentUsersRentalPoints = useSelector(
    (state) => state.stateGUISlice.currentUsersRentalPoints
  );

  const allOrdersButton = useSelector((state) => state.stateGUISlice.allOrders);

  const currentRentalPoint = useSelector(
    (state) => state.stateGUISlice.currentRentalPoint
  );

  const activeRentalPointId = useSelector(
    (state) => state?.stateGUISlice?.currentRentalPoint?.id
  );

  const idCurrentOrder = useSelector(
    (state) => state?.stateGUISlice?.currentOrder?.id
  );

  const allOrders = useSelector((state) => state.stateGUISlice.allOrders);

  useEffect(() => {
    if (activeRentalPointId) {
      setLoadingComponent(true);

      const timeoutId = setTimeout(() => {
        setLoadingComponent(false);
      }, 1000);

      return () => clearTimeout(timeoutId);
    }
  }, [activeRentalPointId]);

  // Эффект для обновления идентификаторов точек аренды при изменении данных пользователя
  useEffect(() => {
    const rentalPointIds =
      currentUsersRentalPoints &&
      currentUsersRentalPoints.map((rentalPoint) => rentalPoint.id);
    setRentalPointIds(rentalPointIds);
  }, [currentUsersRentalPoints]);

  // Эффект для фильтрации и обновления заказов в зависимости от выбранного вида (все заказы или конкретная точка аренды)
  useEffect(() => {
    let filteredOrders;
    if (allOrders) {
      filteredOrders = (ordersData ?? []).filter((order) =>
        rentalPointIds.includes(order?.order_info?.rentalPoints_id)
      );
    } else {
      filteredOrders = ordersData?.filter(
        (order) => order?.order_info?.rentalPoints_id === activeRentalPointId
      );
    }

    const formattedOrders = formatOrders(filteredOrders);

    setOrders(formattedOrders);

    if (formattedOrders.length > 0) {
      // Если allOrders === true, устанавливаем первый заказ в списке как текущий
      if (allOrders) {
        dispatch(setCurrentOrder(formattedOrders[0]));
      } else {
        // Если текущий заказ уже не в списке, также устанавливаем первый заказ в списке как текущий
        const currentOrderExists = formattedOrders.some(
          (order) => order.id === idCurrentOrder
        );
        if (!currentOrderExists) {
          dispatch(setCurrentOrder(formattedOrders[0]));
        }
      }
    }
  }, [
    allOrders,
    ordersData,
    activeRentalPointId,
    rentalPointIds,
    idCurrentOrder,
    dispatch,
  ]);

  useEffect(() => {
    if ((activeRentalPointId && containerRef.current) || allOrders) {
      // Вызываем скролл с некоторой задержкой, чтобы убедиться, что DOM обновлен
      setTimeout(() => {
        containerRef.current.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 0); // 0 мс задержки, но это можно изменить в зависимости от потребностей
    }
  }, [activeRentalPointId, allOrders]);

  // Эффект для управления текущим выбранным заказом
  useEffect(() => {
    if (orders.length > 0) {
      // Поиск выбранного заказа в списке
      const selectedOrder = orders.find(
        (orderItem) => orderItem.id === idCurrentOrder
      );
      if (selectedOrder) {
        // Если выбранный заказ найден, обновление хранилища Redux
        dispatch(setCurrentOrder(selectedOrder));
      } else {
        // Если не найден, установка первого заказа как выбранного
        dispatch(setCurrentOrder(orders[0]));
      }
    } else {
      // Если список заказов пуст, устанавливаем текущий заказ в пустой объект
      dispatch(setCurrentOrder({}));
    }
  }, [orders, idCurrentOrder, dispatch]);

  // Функция для форматирования заказов, убеждаясь, что dateInterval имеет требуемый формат
  console.log("formatOrders-orders", orders);
  const formatOrders = (orders) => {
    return (
      orders?.map((orderItem) => ({
        ...orderItem,
        order_info: {
          ...orderItem.order_info,
          dateInterval:
            orderItem.order_info.bikeCostDetails.dateIntervalTimestamp?.map(
              (timestamp) => ({
                seconds: timestamp.seconds,
                nanoseconds: timestamp.nanoseconds,
              })
            ) || [],
        },
      })) || []
    );
  };

  const TitleBike = ({ orderItem, typeContent }) => {
    if (orderItem) {
      const orderItemBike = bikeData.find(
        (itemBike) => itemBike.id === orderItem.order_info.bikes_id
      );

      if (orderItemBike?.title.length > 0) {
        return <h5>{orderItemBike.title[0]}</h5>;
      } else {
        return <h5>No matching title found</h5>;
      }
    }

    // Если orderItem не существует, вы можете вернуть пустой JSX или null в зависимости от ваших потребностей
    return null;
  };

  const PhotoBike = ({ orderItem, typeContent }) => {
    if (orderItem) {
      const orderItemBike = bikeData.find(
        (itemBike) => itemBike.id === orderItem.order_info.bikes_id
      );

      // const filteredBikeData = bikeData.find((brand) => orderItemBike?.brand_id.includes(brand.id));

      if (typeContent === "img") {
        return (
          <div
            style={{
              padding: "16px 16px 16px 0",
            }}
          >
            <div
              style={{
                borderRadius: "8px",
                overflow: "hidden",
              }}
            >
              <ImageWithLoader
                // aspectRatio='16/9'
                url={orderItemBike?.file_bikePhoto}
                alt={"PhotoBike"}
              />
            </div>
          </div>
        );
      }
    }

    return null;
  };

  const BrendBike = ({ orderItem, typeContent }) => {
    if (orderItem) {
      const brandBike = bikeData.find(
        (itemBike) => itemBike.id === orderItem.order_info.bikes_id
      );

      const filteredBrandData = brandData.find((brand) =>
        brandBike?.brand_id.includes(brand.id)
      );

      if (typeContent === "img") {
        return (
          <ImageWithLoader
            aspectRatio="1/1"
            url={filteredBrandData?.file_icon}
            alt={"PreviewLogo"}
          />
        );
      }
    }

    return null;
  };

  const CategoryBike = ({ orderItem }) => {
    if (orderItem) {
      const orderItemBike = bikeData.find(
        (itemBike) => itemBike.id === orderItem.order_info.bikes_id
      );

      const filteredCategoryData =
        categoryData.find(
          (category) =>
            orderItemBike?.categoryes_id &&
            orderItemBike.categoryes_id.includes(category.id)
        ) || {};

      if (filteredCategoryData.length > 0) {
        return <div>{filteredCategoryData.title[0]}</div>;
      } else {
        return <div>No matching category found.</div>;
      }
    }

    return null;
  };

  const DateInterval = ({ orderItem }) => {
    return (
      orderItem?.order_info?.dateInterval &&
      orderItem?.order_info?.dateInterval.length === 2 && (
        <div
          style={{
            padding: "0 16px",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "16px",
              width: "100%",
              padding: "16px",
              boxSizing: "border-box",
              backgroundColor: "var(--color-white)",
              borderRadius: "8px",
            }}
          >
            <p
              style={{
                fontWeight: "600",
              }}
            >
              {formatDate(orderItem.order_info.dateInterval[0].seconds)}
            </p>

            <div
              style={{
                width: "100%",
                height: "100%",
              }}
            >
              <div
                style={{
                  borderBottom: "1px solid var(--color-gray-200)",
                  height: "50%",
                }}
              />
            </div>

            <p
              style={{
                fontWeight: "600",
              }}
            >
              {formatDate(orderItem.order_info.dateInterval[1].seconds)}
            </p>
          </div>
        </div>
      )
    );
  };

  // const formatTimestamp = (timestamp) => {
  //   const date = new Date(timestamp * 1000);
  //   const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
  //     .toString()
  //     .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")} ${date
  //     .getHours()
  //     .toString()
  //     .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}:${date
  //     .getSeconds()
  //     .toString()
  //     .padStart(2, "0")}`;
  //   return formattedDate;
  // };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000); // Умножаем на 1000, так как timestamp измеряется в миллисекундах
    const options = { year: "numeric", month: "long", day: "numeric" };
    // const date = new Date(timestamp * 1000); // Умножаем на 1000, так как timestamp измеряется в миллисекундах
    const day = date.getDate();
    const month = date.getMonth() + 1; // Месяцы в объекте Date начинаются с 0, поэтому добавляем 1
    const year = date.getFullYear();

    return day, month, year, date.toLocaleDateString("en-US", options);
  };

  // if (loadingComponent) {
  //     return <div style={{
  //         width: '100%',
  //         height: '100%'
  //     }}>
  //         <Loading />
  //     </div>;
  // }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          // height: "calc(100vh - 56px)",
          height: "100%",
          width: "100%",
          boxSizing: "border-box",
          backgroundColor: "var(--color-gray-100)",
        }}
      >
        <SearchBars
          title={`${
            allOrdersButton
              ? "All orders"
              : currentRentalPoint.title[0]
              ? `Orders list from ${currentRentalPoint.title[0]}`
              : ""
          }`}
          iconLeft={
            <div
              style={{
                height: "44px",
              }}
            />
            // <Icon44
            //     icon={
            //         <span className="material-symbols-outlined">dock_to_right</span>
            //     }
            // />
          }
          iconRight={<div />}
        />

        <div
          style={{
            height: "100%",
            scrollBehavior: "smooth",
          }}
          ref={containerRef}
          className="scrollBox"
        >
          <div
            style={{
              width: "100%",
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(700px, 1fr))",
              gap: "16px",
              padding: "16px",
            }}
          >
            {orders &&
              orders.map((orderItem) => (
                <div
                  key={orderItem.id}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "8px",
                    overflow: "hidden",
                    backgroundColor:
                      idCurrentOrder === orderItem?.id
                        ? "var(--color-white)"
                        : "var(--color-white)",
                    boxSizing: "border-box",
                  }}
                  className="itemScroll"
                  onClick={() => {
                    dispatch(setCurrentOrder(orderItem));
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      // gap: '16px',
                      padding: "16px",
                      backgroundColor: "var(--color-white)",
                      width: "100%",
                      boxSizing: "border-box",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <TitleBike orderItem={orderItem} />

                      <BrendBike orderItem={orderItem} />

                      <CategoryBike orderItem={orderItem} />
                    </div>

                    <div
                      style={{
                        width: "60px",
                        aspectRatio: "1/1",
                        borderRadius: "80px",
                        backgroundColor: "var(--color-gray-100)",
                        overflow: "hidden",
                      }}
                    >
                      <BrendBike typeContent="img" orderItem={orderItem} />
                    </div>
                  </div>

                  {/* <OrderTimeline
                                        orderDetail={orderItem}
                                        orders={ordersData}
                                    /> */}

                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(2, 1fr)",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        // gap: '16px',
                        padding: "16px 0",
                      }}
                    >
                      <DateInterval orderItem={orderItem} />

                      <OdrderDetailPrice
                        title="Order detail price"
                        orderDetail={orderItem}
                      />
                    </div>
                    <PhotoBike typeContent="img" orderItem={orderItem} />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default OrdersList;

// import { useEffect, useState } from "react";
// import { useSubscribeData } from "../../../../../../services/firebase/crudFirebaseStorage";
// import { useDispatch, useSelector } from "react-redux";
// import { setCurrentOrder } from "../../../../../../slice/stateOrdersSlice";
// import SearchBars from "../../../../../UIComponents/bars/searchBars/SearchBars";
// import Icon44 from "../../../../../UIComponents/icons/Icon44";

// const OrdersList = () => {
//     const dispatch = useDispatch();

//     // Состояние компонента - массив заказов
//     // const[allOrders, setAllOrders] = useState(false);

//     const [orders, setOrders] = useState([]);
//     const [rentalPointIds, setRentalPointIds] = useState([])
//     const { data: ordersData, isLoading: loadingOrdersData } = useSubscribeData('orders');

//     const currentUsersRentalPoints = useSelector((state)=> state.stateOrdersSlice.currentUsersRentalPoints)
//     const activeRentalPointId = useSelector((state)=> state?.stateOrdersSlice?.currentRentalPoint?.id)
//     const currentRentalPoint = useSelector((state) => state.stateOrdersSlice.currentRentalPoint)
//     const currentOrder = useSelector((state) => state.stateOrdersSlice.currentOrder)
//     const idCurrentOrder = useSelector((state) => state.stateOrdersSlice.currentOrder.id)
//     const allOrders = useSelector((state) => state.stateOrdersSlice.allOrders)

//     // Эффект для получения и установки кнопки all orders
//     useEffect(() => {
//         const rentalPointIds = currentUsersRentalPoints && currentUsersRentalPoints.map(rentalPoint => rentalPoint.id);
//         setRentalPointIds(rentalPointIds);
//     }, [currentUsersRentalPoints])

//     useEffect(() => {
//         // Если props.allOrders равен true
//         if (allOrders) {
//             // Фильтрация данных заказов по rentalPointIds чтобы сформировать массив из всех элементов а не тьолько из элементов отдельной точки проката
//             const allOrdersData = (ordersData ?? []).filter(order => rentalPointIds && rentalPointIds.includes(order?.order_info?.rentalPoints_id));
//             setOrders(allOrdersData);
//             // console.log('activeRentalPointId', props.rentalPointIds);
//         } else {
//             // Если props.allOrders не равен true
//             // Фильтрация данных заказов по activeRentalPointId для отображения заказов выбраной точки проката
//             const orderData = ordersData?.filter(ordersItem => ordersItem?.order_info?.rentalPoints_id === activeRentalPointId);
//             setOrders(orderData);
//             // console.log('props.ordersData', props.ordersData, '\nprops.activeRentalPointId', props.activeRentalPointId, '\norderData', orderData);
//         }
//     }, [allOrders, ordersData, activeRentalPointId, rentalPointIds]);

//      // Эффект для управления активной точкой аренды
//      useEffect(() => {
//         if (orders && orders.length > 0) {
//             // Если выбранная точка аренды уже сохранена, установите её
//             const selectedOrder = orders.find(orderItem => orderItem.id === idCurrentOrder);
//             if (selectedOrder) {
//                 dispatch(setCurrentOrder(selectedOrder));
//             } else {
//                 // В противном случае, установите первую точку аренды
//                 dispatch(setCurrentOrder(orders[0]));
//             }
//         }
//     }, [orders, idCurrentOrder, dispatch]);

//     return (
//         <>
//             <div style={{
//             display: 'flex',
//             flexDirection: 'column',
//             alignItems: 'flex-start',
//             justifyContent: 'flex-start',
//             height: 'calc(100vh - 200px)',
//             width: '100%',
//             boxSizing: 'border-box',
//             backgroundColor:'var(--color-gray-100)'
//         }}>

//                 <SearchBars
//                 title='Orders list'
//                 iconLeft={
//                     <Icon44
//                         icon={
//                             <span className="material-symbols-outlined">
//                                 dock_to_right
//                             </span>
//                         }
//                     />
//                 }

//                 iconRight={<></>}
//             />

//                <div
//                 className="scrollBox"
//                 style={{
//                     height: '100%'
//                 }}
//             >

//                 <div style={{
//                     width: '100%',
//                     display: 'grid',
//                     gridTemplateColumns: 'repeat(auto-fill, minmax(400px, 1fr))',
//                     gap: '16px',
//                     padding: '16px',
//                 }}
//                 >

//                     {orders && orders.map((orderItem) => (
//                         <div
//                             key={orderItem.id}
//                             style={{
//                                 aspectRatio: '2/1',
//                                 backgroundColor: idCurrentOrder === orderItem?.id ? 'var(--color-primary)' : 'var(--color-gray-200)',
//                                 display: 'flex',
//                                 alignItems: 'center',
//                                 justifyContent: 'center',
//                             }}
//                             className="itemScroll"
//                             onClick={() => {
//                                 dispatch(setCurrentOrder(orderItem))
//                             }}

//                         >
//                             <p>{orderItem && orderItem?.id}</p>
//                         </div>
//                     ))}
//                 </div>

//             </div>
//             </div>
//         </>

//     );
// };

// export default OrdersList;

// import { useEffect, useState } from "react";

// const OrdersList = (props) => {

//     // Состояние компонента - массив заказов
//     const [orders, setOrders] = useState([]);
//     const [activeOrderId, setActiveOrderId] = useState(null);

//     useEffect(() => {
//         // Если props.allOrders равен true
//         if (props.allOrders) {
//             // Фильтрация данных заказов по rentalPointIds чтобы сформировать массив из всех элементов а не тьолько из элементов отдельной точки проката
//             const allOrdersData = (props.ordersData ?? []).filter(order => props.rentalPointIds && props.rentalPointIds.includes(order?.order_info?.rentalPoints_id));
//             setOrders(allOrdersData);
//             // console.log('activeRentalPointId', props.rentalPointIds);
//         } else {
//             // Если props.allOrders не равен true
//             // Фильтрация данных заказов по activeRentalPointId для отображения заказов выбраной точки проката
//             const orderData = props.ordersData?.filter(ordersItem => ordersItem?.order_info?.rentalPoints_id === props.activeRentalPointId);
//             setOrders(orderData);

//             console.log('props.activeRentalPointId', props.activeRentalPointId)
//             console.log('props.ordersData', props.ordersData)
//             console.log('orderData', orderData)
//             // console.log('props.ordersData', props.ordersData, '\nprops.activeRentalPointId', props.activeRentalPointId, '\norderData', orderData);
//         }
//     }, [props.allOrders, props.ordersData, props.activeRentalPointId, props.rentalPointIds]);

//     // Установка первого элемента из отфильтрованных данных в props.setOrderDetailObject
//     useEffect(() => {
//         props.setOrderDetailObject(orders && orders[0]);
//         setActiveOrderId(orders && orders[0]?.id);
//     }, [orders]);

//     return (
//         <>
//             <div style={{
//                 width: '100%',
//                 height: '100%',

//             }}
//                 className="scrollBox"
//             >
//                 <div style={{
//                     width: '100%',
//                     display: 'grid',
//                     gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
//                     gap: '16px',
//                 }}>
//                     {orders && orders.map((orderItem) => (
//                         <div
//                             key={orderItem.id}
//                             style={{
//                                 aspectRatio: '2/1',
//                                 backgroundColor: activeOrderId === orderItem?.id ? 'var(--color-primary)' : 'var(--color-gray-200)',
//                                 display: 'flex',
//                                 alignItems: 'center',
//                                 justifyContent: 'center',
//                             }}
//                             className="itemScroll"
//                             onClick={() => {
//                                 props.setOrderDetailObject(orderItem);
//                                 setActiveOrderId(orderItem?.id);
//                             }}

//                         >
//                             <p>{orderItem && orderItem?.id}</p>
//                         </div>
//                     ))}
//                 </div>

//             </div>
//         </>

//     );
// };

// export default OrdersList;
