import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchData } from "../../services/firebase/crudFirebaseStorage";

// Country
export const fetchCountryData = createAsyncThunk(
  "country/fetchCountryData/front",
  async () => {
    try {
      // Используем setTimeout для создания задержки в 1 секунду
      await new Promise((resolve) => setTimeout(resolve, 1000));

      const countryData = await fetchData("country");
      console.log("countryData ✅", countryData);
      return countryData;
    } catch (error) {
      throw new Error(
        `Ошибка при получении данных countryData: ${error.message}`
      );
    }
  }
);
