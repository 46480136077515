import { createAsyncThunk } from "@reduxjs/toolkit";

export const filterParamsMapRentalPoint = createAsyncThunk(
  "filterParamsMapRentalPoint/front",
  async (_, { getState }) => {
    const { fullDataRentalPoints, bikesFiltredList } =
      getState().stateFrontGUISlice;

    const currentRentalPointsList = filterRentalPointsByBikes(
      fullDataRentalPoints,
      bikesFiltredList
    );

    return currentRentalPointsList;
  }
);

const filterRentalPointsByBikes = (fullDataRentalPoints, bikesFiltredList) => {
  // Создаем массив всех id точек аренды из bikesFiltredList
  const rentalPointIds = bikesFiltredList.map((bike) => bike.rentalPoint.id);
  console.log("filterParamsMapRentalPoint-bikesFiltredList", bikesFiltredList);
  // Фильтруем fullDataRentalPoints, оставляя только те, у которых id присутствует в rentalPointIds
  const filteredRentalPoints = fullDataRentalPoints.filter((rentalPoint) =>
    rentalPointIds.includes(rentalPoint.id)
  );
  console.log(
    "filterParamsMapRentalPoint-bikesFiltredList-filteredRentalPoints",
    filteredRentalPoints
  );
  return filteredRentalPoints;
};
