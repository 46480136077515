function validatePhoneNumber(phoneNumber) {
  // Массив регулярных выражений для разных стран
  const regexPatterns = [
    {
      country: "Germany",
      regex: /^(?:\+49|0049)?0?[\s.-]?\(?\d{1,5}\)?[\s.-]?\d[\s.-]?\d{1,8}$/,
    },
    {
      country: "Italy",
      regex: /^(?:\+39|0039)?\s?0?\d{2,4}\s?\d{6,10}$/,
    },
    {
      country: "Spain",
      regex: /^(?:\+34|0034)?\s?(6|7|8|9)\d{2}\s?\d{3}\s?\d{3}$/,
    },
    {
      country: "UK",
      regex: /^(?:\+44|0044)?\s?(?:0|\(0\))?\d{2,4}\s?\d{2,3}\s?\d{3,4}$/,
    },
    {
      country: "France",
      regex: /^(?:(?:\+33|0033)\s?0?|0)(\d\s?){9}$/,
    },
  ];

  // Удаляем все пробелы, тире и скобки для упрощения проверки
  const cleanedNumber = phoneNumber.replace(/[\s-()]/g, "");
  console.log("validatePhoneNumber", phoneNumber, "===", cleanedNumber);
  // Проверяем номер по каждому регулярному выражению
  for (const { country, regex } of regexPatterns) {
    if (regex.test(cleanedNumber)) {
      console.log(
        "validatePhoneNumber",
        phoneNumber,
        "===",
        cleanedNumber,
        "===",
        true,
        country
      );
      return { valid: true, country }; // Возвращаем объект с информацией о стране, если номер соответствует
    }
  }

  return { valid: false, country: null }; // Возвращаем объект с отрицательным результатом, если номер не соответствует ни одной маске
}
export default validatePhoneNumber;
//   // Примеры использования German:
//   console.log(validatePhoneNumber('+49 30 123456'));  // Должно быть true
//   console.log(validatePhoneNumber('0049 30 1234567')); // Должно быть true
//   console.log(validatePhoneNumber('+49 (0)30 123456')); // Должно быть true
//   // Примеры использования Italian:
// console.log(validateItalianPhoneNumber('+39 02 1234567'));  // Должно быть true
// console.log(validateItalianPhoneNumber('0039 312 123 4567')); // Должно быть true
// console.log(validateItalianPhoneNumber('+39 333 123 4567')); // Должно быть true
//   // Примеры использования Spanish:
// console.log(validateSpanishPhoneNumber('+34 912345678'));  // Должно быть true
// console.log(validateSpanishPhoneNumber('+34 612 345 678')); // Должно быть true
// console.log(validateSpanishPhoneNumber('0034 712 345 678')); // Должно быть true// Примеры использования English:
// console.log(validateEnglishPhoneNumber('+44 20 1234 5678'));  // Должно быть true
// console.log(validateEnglishPhoneNumber('+44 7700 123456'));   // Должно быть true
// console.log(validateEnglishPhoneNumber('0044 20 1234 5678')); // Должно быть true
// // Примеры использования French:
// console.log(validateFrenchPhoneNumber('+33 1 23 45 67 89'));  // Должно быть true
// console.log(validateFrenchPhoneNumber('0033 1 23 45 67 89')); // Должно быть true
// console.log(validateFrenchPhoneNumber('01 23 45 67 89'));     // Должно быть true
