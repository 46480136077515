import React, { useEffect } from "react";
import ImageWithLoader from "../../UIComponents/bars/gallery/ImageWithLoader";

const WigetSelectedPopupV2 = ({
  windowControlOpen,
  windowControlClose,
  isOpen,
  onClose,
  onConfirm,
  children,
  title,
  description,
  img,
  flag,
  map,
}) => {
  if (!img) {
    img =
      "https://firebasestorage.googleapis.com/v0/b/testbikestripster.appspot.com/o/imgStatic%2Fkirillpyatnitsev_clean_Bright_white_background_92b6fcac-be0d-429c-8d9b-bb24269898272.jpg?alt=media&token=6cdd69a8-155c-4e75-8240-0f403470710f&_gl=1*17587bv*_ga*NzE3MzY1ODU1LjE2ODQ3NTEzMTQ.*_ga_CW55HF8NVT*MTY5ODA0MDc5OS4zMS4xLjE2OTgwNDA4NTAuOS4wLjA.";
  }

  useEffect(() => {
    if (windowControlOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [windowControlOpen]);

  if (!windowControlOpen) {
    return null;
  }

  return (
    windowControlOpen && (
      <div className="popupBg">
        <div className="popupWindowContent">
          <div
            style={{
              position: "absolute",
              top: "16px",
              right: "16px",
            }}
          >
            <div
              // className="popup-back-button"
              onClick={() => windowControlClose(false)}
            >
              <span
                style={{
                  // color: 'var(--color-white)',
                  color: "rgba(255, 255, 255, 0.8)",
                  borderRadius: "50%",
                  backgroundColor: "rgba(255, 255, 255, 0.3)",
                  height: "40px",
                  aspectRatio: "1/1",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                className="material-symbols-outlined"
              >
                close
              </span>
            </div>
          </div>

          <div
            style={{
              gridColumn: "span 2",
              height: "100%",
            }}
          >
            {children}
          </div>

          <div
            style={{
              gridColumn: "span 1",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              flex: "1",
              gap: "8px",
              padding: "8px",
              // backgroundColor: 'violet'
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
                backgroundColor: "var(--color-gray-200)",
                height: "100%",
                boxSizing: "border-box",
                borderRadius: "16px",
                overflow: "hidden",
              }}
            >
              <ImageWithLoader aspectRatio="9/16" url={img} alt={img} />
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default WigetSelectedPopupV2;
