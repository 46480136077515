import React, { useState, useEffect } from "react";
import "firebase/compat/firestore";
import Button from "../UIElements/Button";
import Popup from "../UIElements/Popup";
import {
  fetchDocumentById,
  updateDocumentById,
} from "../../services/firebase/crudFirebaseStorage";
import Loading from "../UIElements/Loading";
import CheckboxV2 from "../UIElements/CheckboxV2";
import WigetTranslator from "./WigetTranslator";
import InputV2 from "../UIElements/InputV2";
/**
 * Компонент WigetPhones для работы с телефонами
 * @param {string} collectionName - Имя коллекции в Firebase Firestore
 * @param {string} id - Идентификатор документа
 * @param {string} fieldName - Имя поля, содержащего телефоны
 * @param {string} title - Заголовок компонента
 * @param {number} quantity - Максимальное количество телефонов
 * @param {string} description - Описание компонента
 * @returns {JSX.Element} - JSX-элемент компонента
 */
export const WigetInputV2 = ({
  collectionName,
  idDocument,
  fieldName,
  title,
  quantity,
  description,
}) => {
  // Состояния
  const [activePopup, setActivePopup] = useState(null); // Состояние активного всплывающего окна
  const [existingData, setExistingData] = useState(null); // Состояние существующих данных
  const [isPending, setIsPending] = useState(false); // Состояние ожидания
  const [isLoading, setIsLoading] = useState(false); // Состояние загрузки
  const [isChanged, setIsChanged] = useState(false); // Состояние изменений
  const [markedForDelete, setMarkedForDelete] = useState({}); // Состояние помеченных для удаления данных
  const [isDeleteLoading, setIsDeleteLoading] = useState(false); // Состояние загрузки удаления
  const [result, setResult] = useState(null);
  const [formData, setFormData] = useState({
    [fieldName]: [],
  });

  const items = formData[fieldName] ? formData[fieldName] : [];

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const data = await fetchDocumentById(collectionName, idDocument);

      if (data && data[fieldName]) {
        setExistingData(data);
        const numbers = Array.isArray(data[fieldName])
          ? data[fieldName]
          : [data[fieldName]];
        setFormData((prevFormData) => ({
          ...prevFormData,
          [fieldName]: [...numbers],
        }));
        setIsPending(false);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [collectionName, idDocument, setFormData, fieldName, setIsPending]);

  // Пометить элемент для удаления
  const handleMarkForDelete = (index, isChecked) => {
    setMarkedForDelete((prevMarkedForDelete) => ({
      ...prevMarkedForDelete,
      [index]: isChecked,
    }));
  };

  // Проверить, есть ли помеченные для удаления элементы
  const isAnyMarkedForDelete = () => {
    return Object.values(markedForDelete).some((value) => value);
  };

  // Удалить помеченные элементы
  const handleDeleteMarked = async () => {
    setIsLoading(true);
    setFormData((prevFormData) => {
      const updatedData = prevFormData[fieldName].filter(
        (_, index) => !markedForDelete[index]
      );
      return { ...prevFormData, [fieldName]: updatedData };
    });
    setMarkedForDelete({});

    setIsDeleteLoading(true);
    await updateDocumentById(
      {
        [fieldName]: items.filter((_, index) => !markedForDelete[index]),
      },
      collectionName,
      idDocument
    );
    setIsDeleteLoading(false);
    setIsChanged(false);
    setIsLoading(false);
    setIsPending(false);
    console.log("Удаленные данные успешно обновлены");
  };

  // Изменить значение элемента
  const handleChange = (e, index) => {
    const { value } = e.target;
    setFormData((prevFormData) => {
      const updatedData = prevFormData[fieldName].map((number, i) => {
        if (i === index) {
          return value;
        }
        return number;
      });
      return { ...prevFormData, [fieldName]: updatedData };
    });
    setIsChanged(true);
  };

  // Добавить новый элемент
  const handleAddItemData = () => {
    if (items.length < quantity) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [fieldName]: ["", ...prevFormData[fieldName]],
      }));
      setIsPending(true);
    }
  };

  // Обработать отправку данных
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (existingData) {
      await updateDocumentById(
        {
          [fieldName]: items,
        },
        collectionName,
        idDocument
      );
      console.log("Данные успешно обновлены");
    } else {
      await updateDocumentById(
        {
          [fieldName]: items,
        },
        collectionName,
        idDocument
      );
      setIsLoading(false);
      console.log("Данные успешно добавлены");
    }
    setIsPending(false);
    setIsChanged(false);
    setIsLoading(false);
  };

  // Открыть всплывающее окно
  const handleOpenPopup = (popupName) => {
    setActivePopup(popupName);
  };

  // Закрыть всплывающее окно
  const handleClosePopup = () => {
    setActivePopup(null);
  };

  // Функция для проверки, есть ли элементы в items
  const hasItems = items.length > 0;
  // Функция для вызова удаленной функции

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "0 16px",
          boxSizing: "border-box",
        }}
      >
        <div
          style={{
            backgroundColor: "var(--color-white)",
            boxSizing: "border-box",
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              height: "48px",
              padding: "0 16px",
              ...(items.length > 0 && {
                borderBottom: "1px var(--color-gray-200) solid",
              }),
            }}
          >
            <p
              style={{
                textTransform: "uppercase",
              }}
              className="p-light"
            >
              {title}
            </p>

            <button onClick={() => handleOpenPopup(`popu`)}>
              {items.length > 0 ? "Edit" : "Add"}
            </button>
          </div>

          {items.map((item, index) => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                marginLeft: "16px",
                boxSizing: "border-box",
                height: "48px",
                ...(index === items.length - 1
                  ? {
                      borderBottom: "none",
                    }
                  : {
                      borderBottom: "1px var(--color-gray-200) solid",
                    }),
              }}
              key={index}
              onClick={() => handleOpenPopup(`popu_${fieldName}`)}
            >
              <div className="inform-box-content-left">{item}</div>

              <div className="inform-box-content-right">
                <span
                  style={{
                    color: "var(--color-gray-400)",
                  }}
                  className="material-symbols-outlined"
                >
                  chevron_right
                </span>
              </div>
            </div>
          ))}
        </div>

        {description && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "8px 16px",
            }}
          >
            <p className="p-light">{description}</p>
          </div>
        )}
      </div>

      {activePopup !== null &&
        (isLoading ? (
          <Loading title="Ожидайте завершения" />
        ) : (
          <Popup
            isOpen={true}
            onClose={handleClosePopup}
            title={title}
            leftMenu={
              <>
                {!isPending && quantity >= 1 && items.length < quantity ? (
                  <Button type="success" onClick={handleAddItemData}>
                    Add
                  </Button>
                ) : (
                  <Button type="disable">Add</Button>
                )}
              </>
            }
            rightMenu={
              <>
                {isAnyMarkedForDelete() ? (
                  <Button
                    type="alarm"
                    onClick={handleDeleteMarked}
                    disabled={isDeleteLoading}
                  >
                    {isDeleteLoading ? "...Loading" : "Delete"}
                  </Button>
                ) : (
                  <>
                    <Button type="disable">Delete</Button>
                    {isChanged === true &&
                    items.every((value) => value !== "") ? (
                      <Button
                        onClick={handleSubmit}
                        disabled={isLoading}
                        children={isLoading ? "...Loading" : "Save"}
                      />
                    ) : (
                      <Button type={"disable"} children={"Save"} />
                    )}
                  </>
                )}
              </>
            }
          >
            {items.map((item, index) => (
              <>
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                    gap: "8px",
                  }}
                  // className="inform-box-inputItem"
                >
                  {quantity > 1 && (
                    <CheckboxV2
                      checked={!!markedForDelete[index]}
                      onChange={(isChecked) =>
                        handleMarkForDelete(index, isChecked)
                      }
                    />
                  )}

                  <InputV2
                    label={`${title} ${index + 1 === 1 ? "" : index + 1}`}
                    type="text"
                    value={item}
                    onChange={(e) => handleChange(e, index)}
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                    // collectionName={collectionName}
                    // idDocument={idDocument}
                    // fieldName={fieldName}
                    // index={index}
                  />
                </div>

                <WigetTranslator
                  collectionName={collectionName}
                  docId={idDocument}
                  fieldName={fieldName}
                  indexToTranslate={index}
                />
              </>
            ))}
          </Popup>
        ))}
    </>
  );
};
