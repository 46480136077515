import { format } from "date-fns";
import { v4 as uuidv4 } from 'uuid';

const WigetRateBike = ({ bikeRate }) => {

    if (bikeRate) {
        const hasPositivePrice = bikeRate.some(rate => rate.price > 0);

        const allPricesAreZero = bikeRate.every(rate => rate.price === 0);

        const calculateParabolicHeight = (index) => {
            // Начальная высота
            const initialHeight = 20;
            // Шаг изменения высоты
            const step = 20;
            // Максимальная высота
            const maxHeight = 100;

            // Вычисляем количество шагов, чтобы достичь максимальной высоты
            const stepsToMax = (maxHeight - initialHeight) / step;

            // Определяем текущий шаг в "параболическом цикле"
            const currentStep = index % (stepsToMax * 2);

            // Если текущий шаг меньше количества шагов до максимума, увеличиваем высоту
            // Иначе уменьшаем высоту
            let height;

            if (currentStep < stepsToMax) {
                height = initialHeight + step * currentStep;
            } else {
                height = maxHeight - step * (currentStep - stepsToMax);
            }

            return height;
        };

        const maxPrice = Math.max(...bikeRate.map(rateItem => rateItem.price));

        const formatUSD = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
        });

        return (
            hasPositivePrice ? (
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px'
                }}>
                    <h6>
                        Bike rate
                    </h6>
                    <div style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(12, 1fr)',
                        gap: '4px'
                    }}>

                        {bikeRate.map((rateItem, index) => (

                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                gap: '4px',
                            }}
                                key={uuidv4()}
                            >

                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    gap: '4px',
                                    height: '100%',
                                    width: '100%',
                                    overflow: 'hidden',
                                }}>

                                    <p className="p-mini">
                                        {formatUSD.format(rateItem.price)}
                                    </p>

                                    <div style={{
                                        width: '100%',
                                        ...(Math.round((rateItem.price / maxPrice) * 100) === 100 ? {
                                            height: '100px'
                                        } : {
                                            height: `${Math.round((rateItem.price / maxPrice) * 100)}%`
                                        }),
                                        minHeight: '8px',
                                        backgroundColor: rateItem.price > 0
                                            ? 'var(--color-success)'
                                            : 'var(--color-gray-100)',
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                        justifyContent: 'flex-end',
                                        overflow: 'hidden',
                                        boxSizing: 'border-box',
                                        borderRadius: '4px'
                                    }} >

                                        {rateItem.discount.length > 0 && (
                                            <div style={{
                                                width: '100%',
                                                height: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'flex-end',
                                                gap: '2px'
                                            }}>
                                                {Math.round((rateItem.price / maxPrice) * 100) > 20 && (
                                                    <p style={{
                                                        color: 'var(--color-white)',
                                                        opacity: '0.8'
                                                    }}
                                                        className="p-mini">
                                                        - {rateItem.discount} off
                                                    </p>
                                                )}

                                                <div style={{
                                                    width: '100%',
                                                    minHeight: rateItem.discount && '4px',
                                                    height: `${rateItem.discount}%`,
                                                    backgroundColor: 'var(--color-warning)',
                                                    // background: 'var(--gradient-primary)',
                                                    borderTop: '1px rgba(255, 255, 255, 0.5) solid',
                                                }} />
                                            </div>

                                        )}

                                    </div>
                                </div>

                                <p style={{
                                    fontWeight: '500'
                                }}
                                    className="p-light"
                                >
                                    {format(new Date(2022, rateItem.month - 1, 1), 'MMM')}
                                </p>

                            </div>
                        ))}
                    </div>

                </div>
            ) : (
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px'
                }}>
                    <h6>
                        Bike rate
                    </h6>
                    <div style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(12, 1fr)',
                        gap: '4px'
                    }}>

                        {bikeRate.map((rateItem, index) => (

                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                gap: '4px',
                            }}
                                key={uuidv4()}
                            >

                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    gap: '4px',
                                    height: '100%',
                                    width: '100%',
                                    overflow: 'hidden',
                                }}>

                                    {/* <p className="p-mini">
                                        {formatUSD.format(rateItem.price)}
                                    </p> */}
                                    <div style={{
                                        height: '8px',
                                        width: '50%',
                                        backgroundColor: 'var(--color-gray-100)',
                                        borderRadius: '80px'
                                    }} />

                                    <div style={{
                                        width: '100%',
                                        height: `${calculateParabolicHeight(index)}px`,
                                        backgroundColor: 'var(--color-gray-100)',
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                        justifyContent: 'flex-end',
                                        overflow: 'hidden',
                                        boxSizing: 'border-box',
                                        borderRadius: '4px'
                                    }} >
                                        {rateItem.discount.length > 0 && (
                                            <>

                                                <div style={{
                                                    width: '100%',
                                                    minHeight: rateItem.discount && '4px',
                                                    height: `${rateItem.discount}%`,
                                                    backgroundColor: 'var(--color-warning)',
                                                    borderTop: '1px rgba(255, 255, 255, 0.5) solid',
                                                }} />
                                            </>
                                        )}

                                    </div>
                                </div>

                                <p style={{
                                    fontWeight: '500'
                                }}
                                    className="p-light"
                                >
                                    {format(new Date(2022, rateItem.month - 1, 1), 'MMM')}
                                </p>

                            </div>
                        ))}
                    </div>

                </div>
            )

        );
    }

    return null;
}

export default WigetRateBike




// import { format } from "date-fns";

// const WigetRateBike = ({ bikeRate }) => {
//     if (bikeRate) {

//         const maxPrice = Math.max(...bikeRate.map(rateItem => rateItem.price));

//         const formatUSD = new Intl.NumberFormat('en-US', {
//             style: 'currency',
//             currency: 'USD'
//         });

//         return (
//             <div>
//                 <h6>
//                     Bike rate
//                 </h6>

//                 {bikeRate.map((rateItem, index) => (
//                     <div key={index}>
//                         <div style={{
//                             display: 'flex',
//                             flexDirection: 'row',
//                             alignItems: 'center',
//                             justifyContent: 'space-between',
//                             // maxWidth: '80%',
//                             gap: '8px'
//                         }}>
//                             <div style={{
//                                 width: '40px'
//                             }}>
//                                 <p className="p-light">
//                                     {format(new Date(2022, rateItem.month - 1, 1), 'MMM')}
//                                 </p>
//                             </div>

//                             <div style={{
//                                 width: '100%',
//                                 backgroundColor: 'var(--color-gray-100)',
//                                 borderRadius: '80px',
//                             }}>
//                                 <div style={{
//                                     height: '8px',
//                                     width: `${Math.round((rateItem.price / maxPrice) * 100)}%`,
//                                     backgroundColor: rateItem.price > 0 && 'var(--color-success)',
//                                     borderRadius: '80px',
//                                     display: 'flex',
//                                     justifyContent: 'flex-end'
//                                 }} >
//                                     <div style={{
//                                         height: '8px',
//                                         minWidth: rateItem.discount && '8px',
//                                         width: `${rateItem.discount}%`,
//                                         backgroundColor: 'var(--color-gray-300)',
//                                         borderRadius: '0 80px 80px 0'
//                                     }}>

//                                     </div>
//                                 </div>
//                             </div>

//                             <div style={{
//                                 width: '70px',
//                                 textAlign: 'left'
//                             }}>
//                                 <p className="p-light">
//                                     {formatUSD.format(rateItem.price)}
//                                 </p>
//                             </div>

//                         </div>
//                     </div>
//                 ))}
//             </div>
//         );
//     }

//     return null;
// }

// export default WigetRateBike