import React, { useState } from "react";
import Preloader from "../../../UIElements/Preloader";
import prioritiUrl from "../../../../functions/prioritiUrl";

const ImageWithLoader = ({ url, alt, aspectRatio, style, icon, objectFit }) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
      }}
    >
      {/* <div style={backgroundImageStyle}> */}
      {isLoading && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            aspectRatio: aspectRatio ? aspectRatio : "1/1",
            boxSizing: "border-box",
          }}
        >
          <Preloader design="white" />
        </div>
      )}
      {url && url.length > 0 && (
        <img
          src={Array.isArray(url) ? prioritiUrl(url) : url}
          alt={alt.length > 0 ? alt : "Alt img"}
          style={{
            opacity: icon ? "0" : "1",
            display: isLoading ? "none" : "block",
            objectFit: objectFit ? objectFit : "cover",
            width: "100%",
            aspectRatio: aspectRatio ? aspectRatio : "1/1",
            ...style,
          }}
          onLoad={handleImageLoad}
        />
      )}
    </div>
    // </div>
  );
};

export default ImageWithLoader;
