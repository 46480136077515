// Импорт необходимых зависимостей
import React, { useState, useEffect } from "react";
import "firebase/compat/firestore";
import WigetButtonAdd from "../../../../../../UniversalWidgets/WigetButtonAdd";
import WigetButtonDelete from "../../../../../../UniversalWidgets/WigetButtonDelete";
import { useSubscribeData } from "../../../../../../../services/firebase/crudFirebaseStorage";
import Popup from "../../../../../../UIElements/Popup";
import Checkbox from "../../../../../../UIElements/Checkbox";
import Loading from "../../../../../../UIElements/Loading";
import { useDispatch } from "react-redux";
import PreviewGallery from "../../../../../../UIComponents/bars/gallery/PreviewGallery";
import { WigetInputV2 } from "../../../../../../UniversalWidgets/WigetInputV2";
import { WigetTextareaV2 } from "../../../../../../UniversalWidgets/WigetTextareaV2";

export const WigetCurrencyDetail = ({
  title,
  collectionName,
  structureCollection,
}) => {
  // Используем наш кастомный хук для подписки на данные Firestore и отслеживания состояния загрузки
  const { data: dataList, isLoading: loadingState } =
    useSubscribeData(collectionName);
  const dispatch = useDispatch();

  return (
    <div className="private_profile-mainBox">
      {loadingState ? (
        <Loading />
      ) : (
        dataList.map((directoryItem, index) => (
          <div className="directory" key={directoryItem.id}>
            <div className="rentalPoints_content-box">
              <div
                className="rentalPoints_content-box-left"
                style={{ width: "50%" }}
              >
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <PreviewGallery
                    aspectRatio="16/9"
                    title="Icon currency"
                    collectionName={collectionName}
                    idDocument={directoryItem.id}
                    fieldName="file_icon"
                    gallery={true}
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eleifend ipsum vitae nunc gravida facilisis."
                  />
                </div>
              </div>
              <div
                className="rentalPoints_content-box-right"
                style={{ width: "50%" }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <WigetInputV2
                    title="Currency name"
                    collectionName={collectionName}
                    idDocument={directoryItem.id}
                    fieldName="title"
                    quantity={1}
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                  />

                  <WigetTextareaV2
                    title="Description"
                    maxLength={1000}
                    collectionName={collectionName}
                    idDocument={directoryItem.id}
                    fieldName="description"
                    quantity={1}
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                  />

                  <div
                    style={{
                      padding: "0 16px",
                    }}
                  >
                    <div className="wigetButtonDelete-imform">
                      <div className="wigetButtonDelete-imform-left">
                        <p className="p-light">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Fusce eleifend ipsum vitae nunc gravida
                          facilisis.
                        </p>
                      </div>

                      <div className="wigetButtonDelete-imform-right">
                        <WigetButtonDelete
                          collectionName={collectionName}
                          documentId={directoryItem.id}
                          title="Delete"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

/**
 * Компонент WigetBrandCheck.
 * Отображает список брендов с чекбоксами и кнопкой выбора.
 * @param {Object} props - Свойства компонента.
 * @param {string} props.title - Заголовок компонента.
 * @param {string} props.collectionName - Название коллекции для получения данных о брендах.
 * @param {string} props.parentCollectionName - Название родительской коллекции для получения данных родительского документа.
 * @param {string} props.documentId - Идентификатор документа родительской коллекции.
 * @returns {JSX.Element} Элемент компонента WigetBrandCheck.
 */
export const WigetCurrencyCheck = ({
  title,
  collectionName,
  parentCollectionName,
  documentId,
  description,
}) => {
  // Состояние активного попапа
  const [activePopup, setActivePopup] = useState(null);

  // Состояние выбранных брендов
  const [selectedBrands, setSelectedBrands] = useState([]);

  // Получение данных о брендах из коллекции
  const { data } = useSubscribeData(collectionName);

  // Получение данных родительского документа
  const { data: documentParentCollectionName, isLoading } = useSubscribeData(
    parentCollectionName,
    documentId
  );

  useEffect(() => {
    if (documentParentCollectionName?.currency_id) {
      setSelectedBrands(documentParentCollectionName.currency_id);
    }
  }, [documentParentCollectionName]);

  /**
   * Обработчик открытия попапа.
   * @param {string} popupName - Имя попапа.
   */
  const handleOpenPopup = (popupName) => {
    setActivePopup(popupName);
  };

  /**
   * Обработчик закрытия попапа.
   */
  const handleClosePopup = () => {
    setActivePopup(null);
  };

  /**
   * Обработчик изменения состояния чекбокса.
   * @param {string} brandId - Идентификатор бренда.
   */
  const handleCheckboxChange = (brandId) => {
    const updatedSelectedBrands = selectedBrands.includes(brandId)
      ? selectedBrands.filter((id) => id !== brandId)
      : [...selectedBrands, brandId];
    setSelectedBrands(updatedSelectedBrands);
  };

  // Фильтруем список брендов на основе выбранных брендов и наличия данных родительского документа
  const filteredData =
    data && documentParentCollectionName?.currency_id
      ? data.filter((directoryItem) =>
          documentParentCollectionName.currency_id.includes(directoryItem.id)
        )
      : [];

  // Возвращаем JSX-элемент компонента
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "0 16px",
          boxSizing: "border-box",
        }}
      >
        <div
          style={{
            backgroundColor: "var(--color-white)",
            boxSizing: "border-box",
            borderRadius: "8px",
            overflow: "hidden",
          }}
          onClick={() => handleOpenPopup(`popu_${documentId}`)}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              height: "48px",
              padding: "0 16px",
              ...(filteredData.length > 0 && {
                borderBottom: "1px var(--color-gray-200) solid",
              }),
            }}
          >
            <p
              style={{
                textTransform: "uppercase",
              }}
              className="p-light"
            >
              {title}
            </p>

            <button onClick={() => handleOpenPopup(`popu`)}>
              {filteredData.length > 0 ? "Edit" : "Add"}
            </button>
          </div>

          {filteredData.map((directoryItem, index) => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                marginLeft: "16px",
                boxSizing: "border-box",
                height: "48px",
                ...(index === filteredData.length - 1
                  ? {
                      borderBottom: "none",
                    }
                  : {
                      borderBottom: "1px var(--color-gray-200) solid",
                    }),
              }}
              key={index}
              onClick={() => handleOpenPopup(`popu_${documentId}`)}
            >
              <div className="inform-box-content-left">
                {directoryItem.title ? directoryItem.title : title}
              </div>
              <div className="inform-box-content-right">
                <span
                  style={{
                    color: "var(--color-gray-400)",
                  }}
                  className="material-symbols-outlined"
                >
                  chevron_right
                </span>
              </div>
            </div>
          ))}
        </div>

        {description && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "8px 16px",
            }}
          >
            <p className="p-light">{description}</p>
          </div>
        )}
      </div>

      {activePopup === `popu_${documentId}` && (
        <Popup
          isOpen={true}
          onClose={handleClosePopup}
          title={title}
          leftMenu={<></>}
          rightMenu={
            <>
              <WigetButtonAdd
                collectionName={parentCollectionName}
                documentId={documentId}
                nameButton="Save"
                dataObject={{
                  currency_id: selectedBrands,
                }}
              />
            </>
          }
        >
          <div className="inform-box-inputItem">
            <div className="inform-box">
              <div className="inform-box-header">{title}</div>
              <div className="inform-box-item">
                {isLoading ? (
                  <div>Loading...</div>
                ) : (
                  data.map((dataItem, index) => (
                    <>
                      <div key={index} className="inform-box-content">
                        <div className="inform-box-content-left">
                          <Checkbox
                            value={dataItem.id}
                            checked={selectedBrands.includes(dataItem.id)}
                            onChange={() => handleCheckboxChange(dataItem.id)}
                            label={dataItem.title}
                          />
                        </div>

                        <div className="inform-box-content-right">
                          {dataItem.file_icon &&
                            dataItem.file_icon
                              .filter((icon) => icon.priority)
                              .map((icon) => (
                                <div
                                  className="inform-box-icon-right"
                                  style={{
                                    backgroundImage: `url(${icon.url})`,
                                  }}
                                ></div>
                              ))}
                        </div>
                      </div>
                    </>
                  ))
                )}
              </div>
            </div>
          </div>
        </Popup>
      )}
    </>
  );
};
