import React from "react";
import { WigetImageCarousel2 } from "../UniversalWidgets/WigetImageCarousel";
import { useSelector } from "react-redux";
import ImageWithLoader from "../../UIComponents/bars/gallery/ImageWithLoader";
import Preloader from "../../UIElements/Preloader";
import objectIsEmpty from "../../../functions/objectIsEmpty";
const Title = () => {
  const currentBike = useSelector(
    (state) => state.stateFrontGUISlice.currentBike
  );

  const isLoading = useSelector((state) => state.stateFrontGUISlice.isLoading);

  return isLoading ? (
    <div
      style={{
        gridColumn: "span 2",
        backgroundColor: "var(--color-white)",
        borderRadius: "24px",
        overflow: "hidden",
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "16px",
        boxSizing: "border-box",
      }}
    >
      <Preloader />
    </div>
  ) : (
    !objectIsEmpty(currentBike.file_bikePhoto) && (
      <div
        style={{
          gridColumn: "span 2",
          backgroundColor: "var(--color-white)",
          borderRadius: "24px",
          overflow: "hidden",
          padding: "8px",
        }}
      >
        <div
          style={{
            width: "100%",
            borderRadius: "16px",
            overflow: "hidden",
          }}
        >
          <WigetImageCarousel2
            images={currentBike.file_bikePhoto || []}
            quantity={2}
          />
        </div>
      </div>
    )
  );
};

export default Title;
