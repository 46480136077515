import React, { useState, useEffect, useRef } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  Autocomplete,
  InfoWindow,
} from "@react-google-maps/api";
import { useSubscribeData } from "../../../services/firebase/crudFirebaseStorage";
import { useSelector } from "react-redux/es/hooks/useSelector";
import {
  setAllOrders,
  setCurrentRentalPoint,
} from "../../../slice/stateGUISlice";
import { useDispatch } from "react-redux";
import ImageWithLoader from "../bars/gallery/ImageWithLoader";
import { v4 as uuidv4 } from "uuid";
const GoogleMaps = () => {
  const dispatch = useDispatch();

  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [
    filteredCurrentUsersRentalPoints,
    setFilteredCurrentUsersRentalPoints,
  ] = useState(null);

  const currentUsersRentalPoints = useSelector(
    (state) => state.stateGUISlice.currentUsersRentalPoints
  );

  const quantityCurrentUsersRentalPoints = currentUsersRentalPoints?.length;

  const currentRentalPoint = useSelector(
    (state) => state.stateGUISlice.currentRentalPoint
  );

  const location = useSelector(
    (state) => state.stateGUISlice.newAddress.location
  );

  // const allOrders = useSelector((state) => state.stateGUISlice.allOrders)

  const loadingStateGoogleMapApi = useSelector(
    (state) => state.config.loadingStateGoogleMapApi
  );

  // Получаем данные о пользователе"
  const currentUser = useSelector((state) => state.userSliceV2.currentUser);
  const role = currentUser ? currentUser.role : null;
  const isLoading = useSelector((state) => state.userSliceV2.isLoading);
  const userId = currentUser ? currentUser.uid : null;

  // Состояния компонента LoadScript
  // const [isApiLoaded, setIsApiLoaded] = useState(false);

  // Состояние для выбранного маркера
  const [selectedMarker, setSelectedMarker] = useState(null);

  const [error, setError] = useState(null);

  // const autocompleteRef = useRef(null);
  const mapRef = useRef(null);

  const allPointsInMap = () => {
    // Функция для создания границ, в которые будут вписаны все маркеры
    const fitBoundsToMarkers = () => {
      if (isMapLoaded && filteredCurrentUsersRentalPoints && mapRef.current) {
        const bounds = new window.google.maps.LatLngBounds();

        filteredCurrentUsersRentalPoints.forEach((point) => {
          if (point.pointAddress && point.pointAddress.location) {
            bounds.extend(
              new window.google.maps.LatLng(
                point.pointAddress.location.lat,
                point.pointAddress.location.lng
              )
            );
          }
        });

        mapRef.current.fitBounds(bounds);

        // Уменьшаем масштаб карты после установки границ
        const zoomOutLevel = mapRef.current.getZoom() + 0; // Уменьшаем на 1 уровень масштабирования
        mapRef.current.setZoom(zoomOutLevel);
      }
    };

    fitBoundsToMarkers();
  };

  useEffect(() => {
    if (filteredCurrentUsersRentalPoints) {
      allPointsInMap();
    }
  }, [filteredCurrentUsersRentalPoints]);

  // Эффект для фильтрации точек аренды с нулевыми координатами
  useEffect(() => {
    const filteredPoints = currentUsersRentalPoints?.filter(
      (point) => point.pointAddress.location.lat !== null
    );
    setFilteredCurrentUsersRentalPoints(filteredPoints);
  }, [currentUsersRentalPoints]);

  // Определение координат (геолокация)
  // useEffect(() => {
  //     const fetchInitialLocation = async () => {
  //         try {
  //             // Запрашиваем геопозицию у браузера
  //             const position = await new Promise((resolve, reject) => {
  //                 navigator.geolocation.getCurrentPosition(resolve, reject);
  //             });

  //             if (position && position.coords) {
  //                 // Получаем координаты из объекта геопозиции
  //                 const { latitude, longitude } = position.coords;

  //                 // Обновляем состояние текущей точки аренды с использованием dispatch
  //                 dispatch(
  //                     setCurrentRentalPoint({
  //                         pointAddress: {
  //                             location: {
  //                                 lat: latitude,
  //                                 lng: longitude
  //                             }
  //                         }
  //                     })
  //                 );

  //             } else {
  //                 console.error('Error fetching initial location: Coordinates are undefined');
  //             }

  //         } catch (error) {
  //             console.error('Error fetching initial location:', error);
  //             // Здесь можно обработать ошибку, например, предложить ввести адрес вручную
  //         }
  //     };

  //     // fetchInitialLocation();
  //     // // Вызываем функцию запроса геопозиции при загрузке компонента
  //     if (currentRentalPoint.pointAddress?.location?.lat === undefined && currentRentalPoint?.pointAddress?.location?.lng === undefined) {

  //         fetchInitialLocation();
  //     }

  // }, []); // Пустой массив зависимостей, чтобы эффект выполнился только один раз при загрузке

  // Обработчик загрузки карты
  const onMapLoad = (mapInstance) => {
    mapRef.current = mapInstance;
    setIsMapLoaded(true);
  };

  // Обработчик ошибок
  const handleError = (event) => {
    console.error(
      "Global error:",
      event.message,
      event.filename,
      event.lineno,
      event.colno,
      event.error
    );
    setError(event.message);
    return true;
  };

  // Обработчик ошибок
  useEffect(() => {
    window.addEventListener("error", handleError);

    return () => {
      window.removeEventListener("error", handleError);
    };
  }, []);

  // Обработчик клика по маркеру, который устанавливает выбранный маркер
  // const onMarkerClick = (rentalPoint) => {
  //     if (window.google) {
  //         setSelectedMarker(rentalPoint);
  //     }
  // };

  const getLogoUrl = (fileLogos) => {
    const prioritizedLogo = fileLogos.find((logo) => logo.priority === true);

    if (prioritizedLogo) {
      // Возвращаем URL логотипа с флагом priority равным true
      return prioritizedLogo.url;
    } else if (fileLogos.length > 0) {
      // Возвращаем URL первого логотипа, если массив не пустой
      return fileLogos[0].url;
    } else {
      // Если массив пустой, можно вернуть какое-то значение по умолчанию или null
      return null;
    }
  };

  useEffect(() => {
    if (isMapLoaded) {
      try {
        // if (allOrders) {
        //     allPointsInMap()
        // } else {
        // console.log('currentRentalPoint.pointAddress', currentRentalPoint.pointAddress.location.lat)

        if (
          currentRentalPoint &&
          currentRentalPoint?.pointAddress?.location?.lat &&
          currentRentalPoint?.pointAddress?.location?.lng &&
          window.google
        ) {
          // setSelectedMarker(currentRentalPoint);

          // Анимированное приближение к выбранному маркеру
          const targetLatLng = new window.google.maps.LatLng({
            lat: currentRentalPoint.pointAddress.location.lat,
            lng: currentRentalPoint.pointAddress.location.lng,
          });

          // Центрируем карту и задаем уровень масштаба
          if (mapRef.current) {
            mapRef.current.panTo(targetLatLng);
            mapRef.current.setZoom(16);
          } else {
            console.error("mapRef.current is not defined.");
          }
        } else {
          allPointsInMap();
        }
        // }
      } catch (error) {
        console.error("Error in useEffect:", error);
      }
    }
  }, [currentRentalPoint, mapRef.current]);

  // Обработчик клика по маркеру, который устанавливает выбранный маркер
  const onMarkerClick = (rentalPoint) => {
    if (window.google) {
      setSelectedMarker(rentalPoint);

      // Анимированное приближение к выбранному маркеру
      const targetLatLng = new window.google.maps.LatLng({
        lat: rentalPoint.pointAddress.location.lat,
        lng: rentalPoint.pointAddress.location.lng,
      });

      // Центрируем карту и задаем уровень масштаба
      if (mapRef.current) {
        mapRef.current.panTo(targetLatLng);
        mapRef.current.setZoom(19);
      }
    }
  };

  const closeInfoWindow = () => {
    setSelectedMarker(null);
    mapRef.current.setZoom(16);
  };
  // Рендер компонента
  return (
    loadingStateGoogleMapApi && (
      <div
        style={{
          position: "relative",
          height: "100%",
          width: "100%",
        }}
      >
        <GoogleMap
          mapContainerStyle={{
            // borderRadius: '8px',
            // aspectRatio: '16/9',
            height: "100%",
            width: "100%",
            styles: [
              {
                featureType: "poi",
                elementType: "labels",
                stylers: [{ visibility: "off" }],
              },
            ],
          }}
          center={location}
          zoom={16}
          onLoad={(mapInstance) => onMapLoad(mapInstance)}
          // onClick={onMapClick}
          options={{
            disableDefaultUI: true,
            zoomControl: true,
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            styles: [
              {
                featureType: "poi",
                elementType: "labels",
                stylers: [{ visibility: "off" }],
              },
            ],
          }}
        >
          {loadingStateGoogleMapApi &&
            filteredCurrentUsersRentalPoints &&
            filteredCurrentUsersRentalPoints.map(
              (rentalPointItem) =>
                rentalPointItem?.pointAddress?.location?.lat &&
                rentalPointItem?.pointAddress?.location?.lng && (
                  <Marker
                    key={uuidv4()}
                    position={{
                      lat: rentalPointItem?.pointAddress?.location?.lat,
                      lng: rentalPointItem?.pointAddress?.location?.lng,
                    }}
                    onClick={() => {
                      onMarkerClick(rentalPointItem);
                      dispatch(setCurrentRentalPoint(rentalPointItem));
                      dispatch(setAllOrders(false));
                      // setCurrentRentalPoint(rentalPointItem)
                      // setSelectedRentalPointId(rentalPointItem.id)
                    }}
                    // draggable={true}

                    icon={
                      currentRentalPoint &&
                      currentRentalPoint?.pointAddress &&
                      currentRentalPoint?.pointAddress?.location?.lat ===
                        rentalPointItem?.pointAddress?.location?.lat &&
                      currentRentalPoint?.pointAddress?.location?.lng ===
                        rentalPointItem?.pointAddress?.location?.lng
                        ? {
                            url: "https://firebasestorage.googleapis.com/v0/b/testbikestripster.appspot.com/o/imgStatic%2FselectedIconMapPointShadowV2.svg?alt=media&token=cd674ee7-73a5-4f3f-893d-176feb68c400",
                            // "https://firebasestorage.googleapis.com/v0/b/testbikestripster.appspot.com/o/imgStatic%2FselectedIconMapPoint.svg?alt=media&token=6f72e59a-52d5-4801-9090-93b953a4b83c"
                            scaledSize: new window.google.maps.Size(80, 80),
                          }
                        : {
                            url: "https://firebasestorage.googleapis.com/v0/b/testbikestripster.appspot.com/o/imgStatic%2FiconMapPoint.svg?alt=media&token=e08358a3-22d1-4e3c-999f-39ec11c47b40",
                            scaledSize: new window.google.maps.Size(24, 24),
                          }
                    }
                  />
                )
            )}

          {/* Отображаем InfoWindow рядом с выбранным маркером */}
          {selectedMarker && (
            <InfoWindow
              position={{
                lat: selectedMarker?.pointAddress?.location?.lat,
                lng: selectedMarker?.pointAddress?.location?.lng,
              }}
              onCloseClick={() => closeInfoWindow()}
              options={{
                pixelOffset: new window.google.maps.Size(0, -52),
              }}
            >
              <div>
                <h3>{selectedMarker?.title}</h3>
                <p>{selectedMarker?.pointAddress?.address}</p>

                <div
                  style={{
                    height: "100px",
                    aspectRatio: "1/1",
                    backgroundColor: "var(--color-gray-100)",
                  }}
                >
                  <ImageWithLoader
                    src={getLogoUrl(selectedMarker.file_logo)}
                    alt={getLogoUrl(selectedMarker.file_logo)}
                  />
                </div>

                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns:
                      "repeat(auto-fill, minmax(100px, 1fr))",
                    gap: "4px",
                    overflow: "hidden",
                    padding: "4px",
                    width: "100%",
                  }}
                >
                  {selectedMarker.file_photo.map((item, index) => (
                    <div
                      style={{
                        aspectRatio: "1/1",
                        overflow: "hidden",
                      }}
                      key={uuidv4()}
                    >
                      <ImageWithLoader src={item.url} alt={item.url} />
                    </div>
                  ))}
                </div>
                {selectedMarker?.phonesNumber.map((item) => (
                  <p key={uuidv4()}>{item}</p>
                ))}

                {selectedMarker?.emails.map((item) => (
                  <p key={uuidv4()}>{item}</p>
                ))}

                {selectedMarker?.description.map((item) => (
                  <p key={uuidv4()}>{item}</p>
                ))}
              </div>
            </InfoWindow>
          )}
        </GoogleMap>

        {quantityCurrentUsersRentalPoints && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              top: "8px",
              right: "8px",
              zIndex: 100,
              backgroundColor: "var(--color-white)",
              padding: "8px 16px", // Отступы внутри кнопки
              cursor: "pointer", // Курсор в виде указателя для кнопки
              borderRadius: "4px", // Скругление углов кнопки
              boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.05)",
              height: "32px",
            }}
            onClick={allPointsInMap}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "8px",
              }}
            >
              All rental points
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "var(--color-alarm)",
                  borderRadius: "80px",
                  minHeight: "24px",
                  minWidth: "24px",
                }}
              >
                <p
                  style={{
                    color: "var(--color-white)",
                    padding: "0 8px",
                  }}
                  className="p-light"
                >
                  {quantityCurrentUsersRentalPoints}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  );
};

export default GoogleMaps;

// import React, { useState, useEffect, useRef } from 'react';
// import { GoogleMap, LoadScript, Marker, Autocomplete, InfoWindow } from '@react-google-maps/api';

// const googleAPIKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

// // Задаем библиотеки Google Maps, которые будут загружены. Нужно инициализировать за пределами компонента, чтобы не было обновлений карты после перерендера компонента

// // const googleMapsLibraries = ["places"];

// const GoogleMaps = ({
//     googleMapsLibraries,
//     currentRentalPoint,
//     setLocation,
//     setAddress,
//     address,
//     location,
//     setCountry,
//     setCity,
//     filteredRentalPointsData,
//     setCurrentRentalPoint,
//     setSelectedRentalPointId
// }) => {

//     // Состояния компонента LoadScript
//     const [isApiLoaded, setIsApiLoaded] = useState(false);

//     // Состояние открытия InfoWindow
//     const [isInfoWindowOpen, setIsInfoWindowOpen] = useState(false);

//     const [error, setError] = useState(null);

//     const autocompleteRef = useRef(null);
//     const mapRef = useRef(null);

//     // Эффект, срабатывающий при загрузке API
//     useEffect(() => {
//         if (isApiLoaded) {
//             setAddress(address);
//             geocodeAddress(address);
//         }
//     }, [isApiLoaded]);

//     // Определение координат (геолокация)
//     useEffect(() => {
//         const fetchInitialLocation = async () => {
//             try {
//                 // Запрашиваем геопозицию у браузера
//                 const position = await new Promise((resolve, reject) => {
//                     navigator.geolocation.getCurrentPosition(resolve, reject);
//                 });

//                 // Получаем координаты из объекта геопозиции
//                 const { coords } = position;

//                 // Обновляем состояние начальной геопозиции
//                 setLocation({
//                     lat: coords.latitude,
//                     lng: coords.longitude,
//                 });
//             } catch (error) {
//                 console.error('Error fetching initial location:', error);
//                 // Здесь можно обработать ошибку, например, предложить ввести адрес вручную
//             }
//         };

//         // Вызываем функцию запроса геопозиции при загрузке компонента
//         fetchInitialLocation();
//     }, []); // Пустой массив зависимостей, чтобы эффект выполнился только один раз при загрузке

//     // Обработчик загрузки карты
//     const onMapLoad = (mapInstance) => {
//         mapRef.current = mapInstance;
//     };

//     const onMapClick = (event) => {
//         const coordinates = event.latLng.toJSON();

//         // Проверяем, что coordinates содержит корректные координаты
//         if (coordinates && typeof coordinates.lat === 'number' && typeof coordinates.lng === 'number') {
//             setLocation(coordinates);

//             const geocoder = new window.google.maps.Geocoder();

//             geocoder.geocode({ location: coordinates }, (results, status) => {
//                 if (status === 'OK') {
//                     if (results[0]) {
//                         setAddress(results[0].formatted_address);
//                     } else {
//                         setError('No results found');
//                     }
//                 } else {
//                     setError('Geocoder failed due to: ' + status);
//                 }
//             });
//         } else {
//             setError('Invalid coordinates');
//         }
//     };

//     // Кодирование адреса в координаты
//     const geocodeAddress = (addressToGeocode) => {
//         if (window.google) {
//             const geocoder = new window.google.maps.Geocoder();

//             geocoder.geocode({ address: addressToGeocode }, (results, status) => {

//                 if (status === 'OK') {
//                     if (results[0]) {
//                         const { location } = results[0].geometry;

//                         setLocation({
//                             lat: location.lat(),
//                             lng: location.lng(),
//                         });

//                         // Устанавливаем адрес в состояние
//                         setAddress(results[0].formatted_address);

//                         // Извлекаем название города
//                         const cityComponent = results[0].address_components.find(
//                             component => component.types.includes('locality')
//                         );

//                         // Извлекаем название страны
//                         const countryComponent = results[0].address_components.find(
//                             component => component.types.includes('country')
//                         );

//                         // Обновляем состояния страны и города
//                         setCity(cityComponent ? cityComponent.long_name : '');
//                         setCountry(countryComponent ? countryComponent.long_name : '');

//                         if (mapRef.current) {
//                             mapRef.current.panTo(location);
//                         }
//                     } else {
//                         setError('No results found for the provided address.');
//                     }
//                 } else {
//                     setError('Geocoder failed due to: ' + status);
//                 }
//             });
//         } else {
//             console.error('Google Maps JavaScript API library is not loaded.');
//         }
//     };

//     // ... другие состояния и рефы
//     const [selectedMarker, setSelectedMarker] = useState(null); // Состояние для выбранного маркера

//     // Обработчик клика по маркеру, который устанавливает выбранный маркер
//     const onMarkerClick = (rentalPoint) => {
//         if (window.google) {
//             setSelectedMarker(rentalPoint);
//         }
//     };

//     // Рендер компонента
//     return (

//         <LoadScript
//             googleMapsApiKey={googleAPIKey}
//             libraries={googleMapsLibraries}
//             onLoad={() => setIsApiLoaded(true)}
//         >
//             <GoogleMap
//                 mapContainerStyle={{
//                     borderRadius: '8px',
//                     // aspectRatio: '16/9',
//                     height: '100%',
//                     width: '100%',
//                     styles: [
//                         {
//                             featureType: 'poi',
//                             elementType: 'labels',
//                             stylers: [{ visibility: 'off' }],
//                         },
//                     ],

//                 }}
//                 center={location}
//                 zoom={8}
//                 onLoad={onMapLoad}
//                 // onClick={onMapClick}
//                 options={{
//                     disableDefaultUI: true,
//                     zoomControl: true,
//                     mapTypeControl: false,
//                     streetViewControl: false,
//                     fullscreenControl: false,
//                 }}
//             >
//                 {filteredRentalPointsData && filteredRentalPointsData.map(rentalPointItem => (
//                     rentalPointItem?.pointAddress?.location?.lat && rentalPointItem?.pointAddress?.location?.lng && (
//                         <Marker
//                             key={rentalPointItem.id}
//                             position={{
//                                 lat: rentalPointItem?.pointAddress?.location?.lat,
//                                 lng: rentalPointItem?.pointAddress?.location?.lng,
//                             }}
//                             onClick={() => {
//                                 onMarkerClick(rentalPointItem)
//                                 setCurrentRentalPoint(rentalPointItem)
//                                 setSelectedRentalPointId(rentalPointItem.id)
//                             }}
//                             // draggable={true}

//                             icon={
//                                 currentRentalPoint && currentRentalPoint?.pointAddress && currentRentalPoint?.pointAddress?.location?.lat
//                                     === rentalPointItem?.pointAddress?.location?.lat &&
//                                     currentRentalPoint?.pointAddress?.location?.lng
//                                     === rentalPointItem?.pointAddress?.location?.lng ?
//                                     {
//                                         url: "https://firebasestorage.googleapis.com/v0/b/testbikestripster.appspot.com/o/imgStatic%2FselectedIconMapPointShadowV2.svg?alt=media&token=cd674ee7-73a5-4f3f-893d-176feb68c400"
//                                         // "https://firebasestorage.googleapis.com/v0/b/testbikestripster.appspot.com/o/imgStatic%2FselectedIconMapPoint.svg?alt=media&token=6f72e59a-52d5-4801-9090-93b953a4b83c"
//                                         ,
//                                         scaledSize: new window.google.maps.Size(80, 80),
//                                     } : {
//                                         url: "https://firebasestorage.googleapis.com/v0/b/testbikestripster.appspot.com/o/imgStatic%2FiconMapPoint.svg?alt=media&token=e08358a3-22d1-4e3c-999f-39ec11c47b40",
//                                         scaledSize: new window.google.maps.Size(24, 24),
//                                     }}

//                         // label={
//                         //     currentRentalPoint?.pointAddress.location.lat === rentalPointItem.pointAddress.location.lat && currentRentalPoint?.pointAddress.location.lng === rentalPointItem.pointAddress.location.lng ? "A" : "B"}

//                         />
//                     )

//                 ))}

//                 {/* Отображаем InfoWindow рядом с выбранным маркером */}
//                 {selectedMarker && (
//                     <InfoWindow

//                         position={{
//                             lat: selectedMarker?.pointAddress?.location?.lat,
//                             lng: selectedMarker?.pointAddress?.location?.lng,
//                         }}
//                         onCloseClick={() => setSelectedMarker(null)}
//                         options={{
//                             pixelOffset: new window.google.maps.Size(0, -52),
//                         }}

//                     >
//                         <div>
//                             <h3>{selectedMarker?.title}</h3>
//                             <p>{selectedMarker?.pointAddress?.address}</p>
//                             {/* ... другая информация, которую вы хотите отобразить */}
//                         </div>
//                     </InfoWindow>
//                 )}

//             </GoogleMap>
//             {error && <p>Error: {error}</p>}
//         </LoadScript>
//     );
// };

// export default GoogleMaps;

// import React, { useState, useEffect, useRef } from 'react';
// import { GoogleMap, LoadScript, Marker, Autocomplete, InfoWindow } from '@react-google-maps/api';

// const googleAPIKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

// // Задаем библиотеки Google Maps, которые будут загружены. Нужно инициализировать за пределами компонента, чтобы не было обновлений карты после перерендера компонента

// // const googleMapsLibraries = ["places"];

// const GoogleMaps = ({
//     googleMapsLibraries,
//     currentRentalPoint,
//     setLocation,
//     setAddress,
//     address,
//     location,
//     setCountry,
//     setCity,
//     clickAllRentalPoints,
//     filteredRentalPointsData,
// }) => {

//     // Состояния компонента LoadScript
//     const [isApiLoaded, setIsApiLoaded] = useState(false);

//     // Состояние открытия InfoWindow
//     const [isInfoWindowOpen, setIsInfoWindowOpen] = useState(false);

//     const [error, setError] = useState(null);

//     const autocompleteRef = useRef(null);
//     const mapRef = useRef(null);

//     // Эффект, срабатывающий при загрузке API
//     useEffect(() => {
//         if (isApiLoaded) {
//             setAddress(address);
//             geocodeAddress(address);
//         }
//     }, [isApiLoaded]);

//     // Определение координат (геолокация)
//     useEffect(() => {
//         const fetchInitialLocation = async () => {
//             try {
//                 // Запрашиваем геопозицию у браузера
//                 const position = await new Promise((resolve, reject) => {
//                     navigator.geolocation.getCurrentPosition(resolve, reject);
//                 });

//                 // Получаем координаты из объекта геопозиции
//                 const { coords } = position;

//                 // Обновляем состояние начальной геопозиции
//                 setLocation({
//                     lat: coords.latitude,
//                     lng: coords.longitude,
//                 });
//             } catch (error) {
//                 console.error('Error fetching initial location:', error);
//                 // Здесь можно обработать ошибку, например, предложить ввести адрес вручную
//             }
//         };

//         // Вызываем функцию запроса геопозиции при загрузке компонента
//         fetchInitialLocation();
//     }, []); // Пустой массив зависимостей, чтобы эффект выполнился только один раз при загрузке

//     // Обработчик загрузки карты
//     const onMapLoad = (mapInstance) => {
//         mapRef.current = mapInstance;
//     };

//     const onMapClick = (event) => {
//         const coordinates = event.latLng.toJSON();

//         // Проверяем, что coordinates содержит корректные координаты
//         if (coordinates && typeof coordinates.lat === 'number' && typeof coordinates.lng === 'number') {
//             setLocation(coordinates);

//             const geocoder = new window.google.maps.Geocoder();

//             geocoder.geocode({ location: coordinates }, (results, status) => {
//                 if (status === 'OK') {
//                     if (results[0]) {
//                         setAddress(results[0].formatted_address);
//                     } else {
//                         setError('No results found');
//                     }
//                 } else {
//                     setError('Geocoder failed due to: ' + status);
//                 }
//             });
//         } else {
//             setError('Invalid coordinates');
//         }
//     };

//     // Кодирование адреса в координаты
//     const geocodeAddress = (addressToGeocode) => {
//         if (window.google) {
//             const geocoder = new window.google.maps.Geocoder();

//             geocoder.geocode({ address: addressToGeocode }, (results, status) => {

//                 if (status === 'OK') {
//                     if (results[0]) {
//                         const { location } = results[0].geometry;

//                         setLocation({
//                             lat: location.lat(),
//                             lng: location.lng(),
//                         });

//                         // Устанавливаем адрес в состояние
//                         setAddress(results[0].formatted_address);

//                         // Извлекаем название города
//                         const cityComponent = results[0].address_components.find(
//                             component => component.types.includes('locality')
//                         );

//                         // Извлекаем название страны
//                         const countryComponent = results[0].address_components.find(
//                             component => component.types.includes('country')
//                         );

//                         // Обновляем состояния страны и города
//                         setCity(cityComponent ? cityComponent.long_name : '');
//                         setCountry(countryComponent ? countryComponent.long_name : '');

//                         if (mapRef.current) {
//                             mapRef.current.panTo(location);
//                         }
//                     } else {
//                         setError('No results found for the provided address.');
//                     }
//                 } else {
//                     setError('Geocoder failed due to: ' + status);
//                 }
//             });
//         } else {
//             console.error('Google Maps JavaScript API library is not loaded.');
//         }
//     };

//     const onMarkerClick = () => {
//         setIsInfoWindowOpen(true);
//     };

//     // Рендер компонента
//     return (

//         <LoadScript
//             googleMapsApiKey={googleAPIKey}
//             libraries={googleMapsLibraries}
//             onLoad={() => setIsApiLoaded(true)}
//         >
//             <GoogleMap
//                 mapContainerStyle={{
//                     borderRadius: '8px',
//                     // aspectRatio: '16/9',
//                     height: '100%',
//                     width: '100%',
//                     styles: [
//                         {
//                             featureType: 'poi',
//                             elementType: 'labels',
//                             stylers: [{ visibility: 'off' }],
//                         },
//                     ],

//                 }}
//                 center={location}
//                 zoom={15}
//                 onLoad={onMapLoad}
//                 onClick={onMapClick}
//                 options={{
//                     disableDefaultUI: true,
//                     zoomControl: true,
//                     mapTypeControl: false,
//                     streetViewControl: false,
//                     fullscreenControl: false,
//                 }}
//             >
//                 {location && (
//                     <Marker
//                         position={location}
//                         onClick={onMarkerClick}
//                     />
//                 )}

//                 {isInfoWindowOpen && (
//                     <InfoWindow
//                         position={location}
//                         onCloseClick={() => setIsInfoWindowOpen(false)}
//                     >
//                         {/* Ваш контент для InfoWindow */}
//                         <div>
//                             <h3>Местоположение</h3>
//                             <p>{currentRentalPoint?.pointAddress?.address}</p>
//                         </div>
//                     </InfoWindow>
//                 )}

//             </GoogleMap>
//             {error && <p>Error: {error}</p>}
//         </LoadScript>
//     );
// };

// export default GoogleMaps;
