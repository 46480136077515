import React from 'react';

const CheckboxV2 = ({ label, checked, onChange, priority }) => {

    const handleCheckboxChange = (event) => {
        const isChecked = event.target.checked;
        onChange(isChecked);
    };

    return (
        <label className={priority ? 'checkbox-priority' : 'checkbox'}>
            <input
                type="checkbox"
                checked={checked}
                onChange={handleCheckboxChange}
            />
            <span
                className={priority ? 'checkbox-priority-label' : 'checkbox-label'}>
                {label}
            </span>
        </label>
    );
};

export default CheckboxV2;




// import React, { useState } from 'react';

// /**
//  * Компонент Checkbox.
//  * Отображает чекбокс с логикой выбора.
//  * @param {Object} props - Свойства компонента.
//  * @param {string} props.value - Значение чекбокса.
//  * @param {boolean} props.checked - Флаг выбора чекбокса.
//  * @param {function} props.onChange - Обработчик изменения состояния чекбокса.
//  * @returns {JSX.Element} Элемент компонента Checkbox.
//  */
// const Checkbox = ({ value, checked, onChange }) => {
//     const handleCheckboxChange = (event) => {
//         console.log(value, checked, onChange);
//         onChange(value, event.target.checked);
//     };

//     return (
//         <label>
//             <input type="checkbox" value={value} checked={checked} onChange={handleCheckboxChange} />
//             {/* {value} */}
//         </label>
//     );
// };

// export default Checkbox;
