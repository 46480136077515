import React, { useState, useEffect, useRef } from "react";
import Preloader from "../../UIElements/Preloader";
import WidgetSortList from "./WidgetSortList";
import WidgetPrewievItemBike from "./WidgetPrewievItemBike";
import WidgetFilterListing from "./WidgetFilterListing";

const WidgetBikesList = ({
  bikesData,
  isLoading,
  bookingDates,
  language,
  categoryFilter,
  brandFilter,
}) => {
  const [sortedBikesData, setSortedBikesData] = useState([]);
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (sortedBikesData.length > 3) {
      setActive(true);
    }
  }, [sortedBikesData]);

  return isLoading ? (
    <Preloader />
  ) : (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        marginTop: "48px",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          height: "64px",
        }}
      >
        <WidgetFilterListing
          active={active}
          brandFilter={brandFilter}
          categoryFilter={categoryFilter}
        />
        <WidgetSortList
          active={active}
          bikesData={bikesData}
          bookingDates={bookingDates}
          setSortedBikesData={setSortedBikesData}
        />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
        //  ref={listRef} style={{ overflowY: "auto", height: "70vh" }}
      >
        {/* <h1> ({sortedBikesData?.length})</h1> */}

        {sortedBikesData.map((itemBike, index) => (
          <div key={`${itemBike.id}-${index}`}>
            <WidgetPrewievItemBike
              itemBike={itemBike}
              bookingDates={bookingDates}
              language={language}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default WidgetBikesList;
