import { createAsyncThunk } from "@reduxjs/toolkit";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../../services/firebase/authFirebase";
import interpretAuthErrorV2 from "../../../../functions/interpretAuthErrorV2";

// Функция проверки устаревших данных через 12 часов
function isDataStale(lastUpdated) {
  if (!lastUpdated) return true;

  const twelveHoursInMillis = 12 * 60 * 60 * 1000; // 12 часов в миллисекундах
  const currentTime = Date.now();

  // Проверяем, прошло ли больше 12 часов с момента последнего обновления
  const isStale = currentTime - lastUpdated > twelveHoursInMillis;
  console.log(
    `Проверка устаревания: lastUpdated = ${new Date(
      lastUpdated
    ).toISOString()}, текущие время = ${new Date(
      currentTime
    ).toISOString()}, прошло 12 часов = ${isStale ? "да" : "нет"}`
  );

  return isStale;
}

export const fetchDataThunkV4 = createAsyncThunk(
  "catalog/fetchDataThunkV4",
  async (args = {}, { getState, rejectWithValue }) => {
    const { countryId, cityId, rentalPointId, bikeId } = args;
    const state = getState().catalogSliceV4;
    const currentTimestamp = Date.now();
    let collectionRef;
    let shouldFetch = false;

    console.log("Запуск fetchDataThunkV4 с аргументами:", args);

    // Проверяем актуальность данных и необходимость выполнения запроса
    if (!countryId && !cityId && !rentalPointId && !bikeId) {
      console.log("Проверка необходимости обновления данных о странах...");
      if (state.countries.length === 0) {
        console.log("Данные о странах отсутствуют, выполняем запрос.");
        shouldFetch = true;
      } else {
        console.log("Доступные страны из indexedDb: ", state.countries);
        shouldFetch = state.countries.some((country) => {
          const isStale =
            !country.lastUpdated || isDataStale(country.lastUpdated);
          if (isStale) {
            console.log(
              `Данные страны с id ${country.id} устарели ${country.lastUpdated}, выполняем запрос.`
            );
          }
          return isStale;
        });
        console.log(
          "Результат проверки устаревания данных о странах:",
          shouldFetch
        );
      }
    } else if (countryId && !cityId) {
      console.log(
        `Проверка необходимости обновления данных о городах в стране с id ${countryId}...`
      );
      const country = state.countries.find(
        (country) => country.id === countryId
      );

      if (!country || !country?.cities || country?.cities?.length === 0) {
        console.log(
          `Данные городов страны с id ${countryId} отсутствуют, выполняем запрос.`
        );
        shouldFetch = true;
      } else {
        shouldFetch =
          !country?.lastUpdated || isDataStale(country?.lastUpdated);

        if (shouldFetch) {
          console.log(
            `${shouldFetch} --- ${country?.lastUpdated} --- ${isDataStale(
              country?.lastUpdated
            )} Данные городов страны с id ${countryId} устарели, выполняем запрос.`
          );
        }
      }
      console.log(
        "Результат проверки устаревания данных о городах:",
        shouldFetch
      );
    } else if (countryId && cityId && !rentalPointId) {
      console.log(
        `Проверка необходимости обновления данных о точках проката в городе с id ${cityId}...`
      );
      const country = state.countries.find(
        (country) => country.id === countryId
      );
      const city = country?.cities?.find((city) => city.id === cityId);
      if (
        !city ||
        !city.rentalPointsData ||
        city.rentalPointsData.length === 0
      ) {
        console.log(
          `Данные точек проката города с id ${cityId} отсутствуют, выполняем запрос.`
        );
        shouldFetch = true;
      } else {
        shouldFetch = !city.lastUpdated || isDataStale(city.lastUpdated);
        if (shouldFetch) {
          console.log(
            `Данные точек проката города с id ${cityId} устарели, выполняем запрос.`
          );
        }
      }
      console.log(
        "Результат проверки устаревания данных о точках проката:",
        shouldFetch
      );
    } else if (countryId && cityId && rentalPointId && !bikeId) {
      console.log(
        `Проверка необходимости обновления данных о велосипедах в точке проката с id ${rentalPointId}...`
      );
      const country = state.countries.find(
        (country) => country.id === countryId
      );
      const city = country?.cities?.find((city) => city.id === cityId);
      const rentalPoint = city?.rentalPointsData?.find(
        (point) => point.id === rentalPointId
      );
      if (
        !rentalPoint ||
        !rentalPoint.bikesData ||
        rentalPoint.bikesData.length === 0
      ) {
        console.log(
          `Данные велосипедов точки проката с id ${rentalPointId} отсутствуют, выполняем запрос.`
        );
        shouldFetch = true;
      } else {
        shouldFetch =
          !rentalPoint.lastUpdated || isDataStale(rentalPoint.lastUpdated);
        if (shouldFetch) {
          console.log(
            `Данные велосипедов точки проката с id ${rentalPointId} устарели, выполняем запрос.`
          );
        }
      }
      console.log(
        "Результат проверки устаревания данных о велосипедах:",
        shouldFetch
      );
    }

    if (!shouldFetch) {
      console.log("Данные актуальны, запрос не выполняется.");
      return { fromIndexedDB: true }; // Возвращаем специальное значение, чтобы указать на данные из IndexedDB
    }

    try {
      // Определяем ссылку на нужную коллекцию
      if (!countryId && !cityId && !rentalPointId && !bikeId) {
        console.log("Запрос данных о странах...");
        collectionRef = collection(db, "catalog");
      } else if (countryId && !cityId) {
        console.log(`Запрос данных о городах в стране с id ${countryId}...`);
        collectionRef = collection(db, "catalog", countryId, "cities");
      } else if (countryId && cityId && !rentalPointId) {
        console.log(
          `Запрос данных о точках проката в городе с id ${cityId}...`
        );
        collectionRef = collection(
          db,
          "catalog",
          countryId,
          "cities",
          cityId,
          "rentalPoints"
        );
      } else if (countryId && cityId && rentalPointId && !bikeId) {
        console.log(
          `Запрос данных о велосипедах в точке проката с id ${rentalPointId}...`
        );
        collectionRef = collection(
          db,
          "catalog",
          countryId,
          "cities",
          cityId,
          "rentalPoints",
          rentalPointId,
          "bikes"
        );
      }

      if (collectionRef) {
        const snapshot = await getDocs(collectionRef);
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Возвращаем данные и lastUpdated
        console.log("Данные успешно получены из Firestore:", data);
        return { data, lastUpdated: currentTimestamp };
      } else {
        console.error("Ошибка: Не удалось определить ссылку на коллекцию.");
        return rejectWithValue("No valid collection reference found.");
      }
    } catch (error) {
      console.error("Ошибка при получении данных из Firestore:", error);
      const errorMessage = interpretAuthErrorV2(error.code);
      return rejectWithValue({ errorMessage });
    }
  }
);
