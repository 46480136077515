import React, { useState, useEffect } from "react";

const SwitcherOnOff = ({ isChecked, onChange, label, align }) => {
  const [internalChecked, setInternalChecked] = useState(isChecked);

  useEffect(() => {
    setInternalChecked(isChecked);
  }, [isChecked]);

  const handleChange = () => {
    const newValue = !internalChecked;
    setInternalChecked(newValue);
    onChange(newValue);
  };

  return internalChecked ? (
    <>
      <div className="switcher-box">
        {align === "left" ? (
          <>
            <p>{label}</p>
            <div className={`switcher-checked`} onClick={handleChange}>
              <div className={`switcher__handle switcher__handle--checked`} />
            </div>
          </>
        ) : (
          <>
            <div className={`switcher-checked`} onClick={handleChange}>
              <div className={`switcher__handle switcher__handle--checked`} />
            </div>
            <p>{label}</p>
          </>
        )}
      </div>
    </>
  ) : (
    <>
      <div className="switcher-box">
        {align === "left" ? (
          <>
            <p>{label}</p>
            <div className={`switcher`} onClick={handleChange}>
              <div className={`switcher__handle`} />
            </div>
          </>
        ) : (
          <>
            <div className={`switcher`} onClick={handleChange}>
              <div className={`switcher__handle`} />
            </div>
            <p>{label}</p>
          </>
        )}
      </div>
    </>
  );
};

export default SwitcherOnOff;

// import React, { useState, useEffect } from 'react';

// const SwitcherOnOff = ({ isChecked }) => {
//     const [internalChecked, setInternalChecked] = useState(isChecked);

//     useEffect(() => {
//         setInternalChecked(isChecked);
//     }, [isChecked]);

//     const handleChange = () => {
//         setInternalChecked(!internalChecked);
//     };

//     return (
//         <div className={`switcher ${internalChecked ? 'switcher--checked' : ''}`} onClick={handleChange}>
//             <div className={`switcher__handle ${internalChecked ? 'switcher__handle--checked' : ''}`} />
//         </div>
//     );
// };

// export default SwitcherOnOff;

// import React, { useState } from 'react';

// const SwitcherOnOff = () => {
//     const [isChecked, setIsChecked] = useState(false);

//     const handleChange = () => {
//         setIsChecked(!isChecked);
//     };

//     return (
//         <div className={`switcher ${isChecked ? 'switcher--checked' : ''}`} onClick={handleChange}>
//             <div className={`switcher__handle ${isChecked ? 'switcher__handle--checked' : ''}`} />
//         </div>
//     );
// };

// export default SwitcherOnOff;
