import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PopupDirrectory from "../WidgetsProfile/Popups/PopupDirrectory";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import AvatarUser from "../WidgetsProfile/Avatars/AvatarUser";
import priorityFile from "../../../../../functions/priorityFile";
import CollapsableText from "../WidgetsProfile/Collapsable/CollapsableText";

const WindowCommentsListModerator = () => {
  const [commentsModerator, setCommentsModerator] = useState([]);

  const {
    isLoading: isLoadingUsers,
    users,
    selectedUser,
  } = useSelector((state) => state.moderationSliceV2);

  useEffect(() => {
    const sortedMessages = [...(selectedUser?.moderation?.historyChanges || [])]
      .map((item) => ({
        ...item,
        user: users.find((user) => user.id === item.userId),
      }))
      .sort((a, b) => b.timestamp - a.timestamp);

    setCommentsModerator(sortedMessages);
  }, [selectedUser, users]);

  const dateFormat = (timestamp) => {
    const date = new Date(parseInt(timestamp, 10));
    return date.toLocaleDateString("en-US");
  };

  return (
    <>
      <ContentBox position="one">
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          {commentsModerator.map(
            (commentItem) =>
              commentItem?.comment &&
              commentItem?.comment !== "" && (
                <div
                  key={commentItem?.timestamp}
                  style={{
                    width: "100%",
                    backgroundColor: "var(--color-gray-100)",
                    padding: "16px",
                    boxSizing: "border-box",
                    borderRadius: "8px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "16px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: "4px",
                    }}
                  >
                    <p className="p-light">
                      {dateFormat(commentItem?.timestamp)}
                    </p>
                    <CollapsableText text={commentItem?.comment} />
                    {/* <p className="p-light">{commentItem?.comment}</p> */}
                  </div>

                  {commentItem?.user && (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: "8px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <AvatarUser
                          size={24}
                          url={
                            priorityFile(
                              commentItem?.user?.user?.files?.avatarFiles
                            )?.url
                          }
                        />
                        <p className="p-light">
                          {commentItem?.user?.user?.ownerName}
                        </p>
                      </div>

                      <p className="p-light">
                        {commentItem?.user?.userContacts?.email}
                      </p>
                    </div>
                  )}
                </div>
              )
          )}
        </div>
      </ContentBox>
    </>
  );
};

export default WindowCommentsListModerator;
