import React, { useEffect, useState } from "react";
import Button from "../WidgetsProfile/Buttons/Button";
import SegmentedControls from "../WidgetsProfile/SegmentedControls/SegmentedControls";
import { useDispatch, useSelector } from "react-redux";
import { getWeekDayName } from "./functions/getWeekDayName";
import Textarea from "../WidgetsProfile/Textarea/Textarea";
import { setTimetableModerationSelectedRentalPoint } from "../../../../../slice/private/moderation/moderationSlice";
import DoubleDropdownList from "../WidgetsProfile/DropdownList/DoubleDropdownList";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import {
  setTimetableInCurrentRentalPoint,
  setTimetableInNewRentalPoint,
} from "../../../../../reducersToolkit/rentalPointsSliiceV2";
import { setTimetableInModeration } from "../../../../../reducersToolkit/moderationSliceV2";
import ButtonsBar from "../WidgetsProfile/ButtonsBar/ButtonsBar";

const WindowEditItemTimetable = ({ mode, indexDay, onClose }) => {
  const dispatch = useDispatch();

  const [timetable, setTimetable] = useState([]);
  const [openingHoursTime, setOpeningHoursTime] = useState("");
  const [openingMinutesTime, setOpeningMinutesTime] = useState("");

  const [closingHoursTime, setClosingHoursTime] = useState("");
  const [closingMinutesTime, setClosingMinutesTime] = useState("");

  const [selectedDay, setSelectedDay] = useState();
  const [timeTableDescription, setTimeTableDescription] = useState();

  const currentUser = useSelector((state) => state.userSliceV2.currentUser);

  const { selectedRentalPoint: moderationSelectedRentalPoint } = useSelector(
    (state) => state.moderationSliceV2
  );

  const { selectedRentalPoint, newRentalPoint } = useSelector(
    (state) => state.rentalPointsSliiceV2
  );

  useEffect(() => {
    if (mode === "new") {
      setTimetable(newRentalPoint?.timetable || []);
    } else if (mode === "edit") {
      setTimetable(selectedRentalPoint?.timetable || []);
    } else if (mode === "moderation") {
      setTimetable(moderationSelectedRentalPoint?.timetable || []);
    }
  }, [
    mode,
    newRentalPoint,
    selectedRentalPoint,
    moderationSelectedRentalPoint,
  ]);

  useEffect(() => {
    const currentTimetable = timetable[indexDay] || {};
    setOpeningHoursTime(currentTimetable.openHours || "");
    setOpeningMinutesTime(currentTimetable.openMinutes || "");

    setClosingHoursTime(currentTimetable.closeHours || "");
    setClosingMinutesTime(currentTimetable.closeMinutes || "");

    setSelectedDay(currentTimetable.isHoliday ? "Day off" : "Working day");
    setTimeTableDescription(
      currentTimetable.description?.descriptionOriginal || ""
    );
  }, [timetable, indexDay]);

  const dayTimetable = {
    index: indexDay,
    newTimetable: {
      weekday: { weekday_En: getWeekDayName(indexDay) },
      openHours: openingHoursTime.toString(),
      openMinutes: openingMinutesTime.toString(),
      closeHours: closingHoursTime.toString(),
      closeMinutes: closingMinutesTime.toString(),
      description: { descriptionOriginal: timeTableDescription },
      isHoliday: selectedDay === "Day off" ? true : false,
      moderatorId: mode === "moderation" ? currentUser.id : null,
    },
  };

  const handleApply = () => {
    if (mode === "new") {
      dispatch(setTimetableInNewRentalPoint(dayTimetable));
    } else if (mode === "edit") {
      dispatch(setTimetableInCurrentRentalPoint(dayTimetable));
    } else if (mode === "moderation") {
      dispatch(setTimetableInModeration(dayTimetable));
    }
    onClose();
  };

  const hours = Array.from({ length: 25 }, (_, index) => index);
  const minutes = Array.from({ length: 61 }, (_, index) =>
    index.toString().padStart(2, "0")
  );

  const hoursOptions = {
    label: "Hours",
    value: hours,
  };

  const minutesOptions = {
    label: "Minutes",
    value: minutes,
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "8px",
          paddingBottom: "72px",
        }}
      >
        <ContentBox position="start">
          <div className="profile-widget__content-box">
            <SegmentedControls
              options={["Working day", "Day off"]}
              onSelect={selectedDay}
              selected={setSelectedDay}
              label="SegmentedControls"
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
            />

            {selectedDay === "Working day" && (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    gap: "16px",
                  }}
                >
                  <DoubleDropdownList
                    optionsFirst={hoursOptions}
                    optionsSecond={minutesOptions}
                    onSelectFirst={setOpeningHoursTime}
                    onSelectSecond={setOpeningMinutesTime}
                    label={
                      openingHoursTime && openingMinutesTime
                        ? `${openingHoursTime}:${openingMinutesTime}`
                        : "Open time"
                    }
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                    selectedOptionFirst={openingHoursTime}
                    selectedOptionSecond={openingMinutesTime}
                  />

                  <DoubleDropdownList
                    optionsFirst={hoursOptions}
                    optionsSecond={minutesOptions}
                    onSelectFirst={setClosingHoursTime}
                    onSelectSecond={setClosingMinutesTime}
                    label={
                      closingHoursTime && closingMinutesTime
                        ? `${closingHoursTime}:${closingMinutesTime}`
                        : "Close time"
                    }
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                    selectedOptionFirst={closingHoursTime}
                    selectedOptionSecond={closingMinutesTime}
                  />
                </div>
                <Textarea
                  label="Description"
                  maxLength={500}
                  onChange={(e) => setTimeTableDescription(e.target.value)}
                  value={timeTableDescription}
                  description={`Please describe the details of your rental point's operation on this day, if any.`}
                />
              </>
            )}
          </div>
        </ContentBox>
      </div>
      <ButtonsBar>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "16px",
            width: "100%",
          }}
        >
          <Button
            type="small"
            label="Cancel"
            active={true}
            color="--color-black"
            onClick={onClose}
          />
          <Button
            type="small"
            label="Apply"
            active={true}
            onClick={handleApply}
          />
        </div>
      </ButtonsBar>
    </>
  );
};

export default WindowEditItemTimetable;
