import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addData,
  updateDocumentFields,
} from "../../../services/firebase/crudFirestore";

export const addBikeModerationThunkV2 = createAsyncThunk(
  "moderation/addBikeModerationThunkV2",
  async ({ newData, rentalPointId = null }, { rejectWithValue }) => {
    if (!rentalPointId) {
      return rejectWithValue("User ID not found");
    }
    try {
      const addedData = await addData("bikes", newData);
      const bikeId = addedData.id;

      await updateDocumentFields("rentalPoints", rentalPointId, {
        bikesIds: [bikeId],
      });

      return addedData;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
