import React from 'react';

/**
 * Компонент для отображения отформатированной даты из timestamp.
 * @param {string | number} timestamp - Timestamp, представляющий дату и время в миллисекундах с 1 января 1970 года (UNIX-время).
 * @returns {JSX.Element} Компонент возвращает элемент span с отформатированной датой.
 */
const DateFormat = ({ timestamp }) => {
    /**
     * Функция для форматирования даты из timestamp.
     * @param {string | number} timestamp - Timestamp, представляющий дату и время в миллисекундах с 1 января 1970 года (UNIX-время).
     * @returns {string} Отформатированная дата в виде строки (например, "July 17, 2023").
     */
    const formatDate = (timestamp) => {
        // Проверяем тип timestamp
        if (typeof timestamp === 'string') {
            // Преобразуем строковое значение timestamp в числовой формат
            timestamp = parseInt(timestamp);
        }

        // Проверяем, что значение timestamp является корректным числом
        if (isNaN(timestamp)) {
            return "Invalid Date";
        }

        // Создаем объект даты из числового timestamp
        const date = new Date(timestamp);

        // Опции для форматирования даты
        const options = { year: 'numeric', month: 'long', day: 'numeric' };

        // Применяем метод toLocaleDateString для получения отформатированной даты в виде строки
        // В данном случае, дата будет отформатирована в формате "Month day, Year" (например, "July 17, 2023")
        return date.toLocaleDateString('en-US', options);
    };

    // Возвращаем элемент span с отформатированной датой, вызывая функцию formatDate
    // и передавая ей значение prop timestamp
    return (
        <span>{formatDate(timestamp)}</span>
    );
};

export default DateFormat;
