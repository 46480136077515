const WidgetAlert = ({ title, description, img }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "48px",
        padding: "48px",
        textAlign: "center",
      }}
    >
      <img
        alt=""
        src={
          img ||
          "https://firebasestorage.googleapis.com/v0/b/biketripste.appspot.com/o/static%2FlogoBikeTripster%2FbikeTripster.svg?alt=media&token=66931d08-c4f8-4754-9c10-c1d8233a2df7"
        }
        style={{
          width: "48%",
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <h1>{title || "No title"}</h1>
        {description && (
          <p className="p-light">{description || "No description"}</p>
        )}
      </div>
    </div>
  );
};

export default WidgetAlert;
