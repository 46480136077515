import React, { useState } from "react";
import "../profile.css";
import AddData from "../WidgetsProfile/AddData/AddData";
import PopupDirrectory from "../WidgetsProfile/Popups/PopupDirrectory";
import WindowRentalPontLocation from "./WindowRentalPontLocation";

const WidgetRentalPointLocation = ({ mode, location }) => {
  const [isOpen, setIsOpen] = useState(false);

  const pointAddress = location?.pointAddress;

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div className="profile-widget__content-box">
        <AddData
          onClick={handleToggle}
          icon={
            pointAddress ? (
              <span className="material-symbols-outlined">edit</span>
            ) : (
              <span className="material-symbols-outlined">add</span>
            )
          }
          title={`${pointAddress ? "Edit location" : "Add location"}`}
          description={`${
            pointAddress
              ? pointAddress
              : "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          }`}
        />
      </div>

      <PopupDirrectory
        isOpen={isOpen}
        onClose={handleClose}
        title="Add location"
      >
        <WindowRentalPontLocation mode={mode} onClose={handleClose} />
      </PopupDirrectory>
    </>
  );
};

export default WidgetRentalPointLocation;
