import React, { useEffect, useState } from "react";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import InputText from "../WidgetsProfile/Inputs/InputText";
import Textarea from "../WidgetsProfile/Textarea/Textarea";
import Button from "../WidgetsProfile/Buttons/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedBikeEditCharacteristic,
  setSelectedBikeNewCharacteristic,
} from "../../../../../reducersToolkit/bikesSliceV2";
import ButtonsBar from "../WidgetsProfile/ButtonsBar/ButtonsBar";

const WindowBikeCharacteristicDetail = ({
  mode,
  onClose,
  characteristicId,
}) => {
  const dispatch = useDispatch();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [characteristic, setCharacteristic] = useState("");

  const { selectedBike } = useSelector((state) => state.bikesSliceV2);

  useEffect(() => {
    if (mode === "new") {
      setTitle("");
      setDescription("");
      setCharacteristic("");
    } else if (mode === "edit") {
      const selectedCharacteristic = selectedBike.characteristics.find(
        (item) => item.id === characteristicId
      );
      if (selectedCharacteristic) {
        setTitle(selectedCharacteristic.title.titleOriginal);
        setDescription(selectedCharacteristic.description.descriptionOriginal);
        setCharacteristic(selectedCharacteristic.characteristic);
      }
    }
  }, [mode, characteristicId, selectedBike]);

  const handleConfirm = () => {
    const newCharacteristic = {
      title: { titleOriginal: title },
      description: { descriptionOriginal: description },
      characteristic: characteristic,
      id: Date.now().toString(),
    };

    if (mode === "new") {
      dispatch(setSelectedBikeNewCharacteristic(newCharacteristic));
    } else if (mode === "edit") {
      dispatch(
        setSelectedBikeEditCharacteristic({
          newCharacteristic,
          id: characteristicId,
        })
      );
    }
    onClose();
  };

  return (
    <>
      {" "}
      <div
        style={{
          width: "100%",
          height: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "8px",
          paddingBottom: "72px",
        }}
      >
        <ContentBox position="start">
          <InputText
            label="Title Characteristic"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            onChange={(event) => setTitle(event.target.value)}
            value={title}
          />
          <Textarea
            label="Description Characteristic"
            maxLength={1000}
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            onChange={(event) => setDescription(event.target.value)}
            value={description}
          />
          <InputText
            label="Characteristic"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            onChange={(event) => setCharacteristic(event.target.value)}
            value={characteristic}
          />
        </ContentBox>
      </div>
      <ButtonsBar>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "16px",
            width: "100%",
          }}
        >
          <Button
            type="small"
            label="Cancel"
            active={true}
            color="--color-black"
            onClick={onClose}
          />
          <Button
            type="small"
            label="Apply"
            active={true}
            onClick={handleConfirm}
          />
        </div>
      </ButtonsBar>
    </>
  );
};

export default WindowBikeCharacteristicDetail;
