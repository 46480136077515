import { createAsyncThunk } from "@reduxjs/toolkit";
import { db } from "../../../../services/firebase/authFirebase";

export const createRentalPointThunkV4 = createAsyncThunk(
  "rentalPoints/createRentalPointThunkV4",
  async (
    {
      countryId,
      cityId,
      rentalPointId,
      rentalPointName,
      address,
      contactInfo,
      files,
    },
    { rejectWithValue }
  ) => {
    try {
      await db
        .collection("countries")
        .doc(countryId)
        .collection("cities")
        .doc(cityId)
        .collection("rentalPoints")
        .doc(rentalPointId)
        .set({
          name: rentalPointName,
          address: address,
          contactInfo: contactInfo,
          files: {
            photos: files.photos || [],
          },
        });
      return {
        countryId,
        cityId,
        rentalPointId,
        rentalPointName,
        address,
        contactInfo,
        files,
      };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
