import React, { useEffect, useState } from "react";
// import "../profile.css";
import "../UserProfile/userProfile.css";
import AvatarUser from "../WidgetsProfile/Avatars/AvatarUser";
import PopupDirrectory from "../WidgetsProfile/Popups/PopupDirrectory";
import WindowUserProfile from "../UserProfile/WindowUserProfile";
import { useSelector } from "react-redux";
import priorityFile from "../../../../../functions/priorityFile";
import WindowUserProfileModeration from "./WindowUserProfileModeration";
import WidgetUserAvatar from "../UserProfile/WidgetUserAvatar";

const WidgetModerationUserPreview = ({ user }) => {
  const [urlAvatarFile, setUrlAvatarFile] = useState("");
  const [windowUserProfile, setWindowUserProfile] = useState(false);
  const [ownerName, setOwnerName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [uId, setUId] = useState("");
  useEffect(() => {
    const priorityFileselected = priorityFile(
      user?.user?.files?.avatarFiles || []
    );
    setUrlAvatarFile(priorityFileselected?.url || "");
    setOwnerName(user?.user?.ownerName || "No name");
    setEmail(user?.userContacts?.email || "No email");
    setPhoneNumber(user?.userContacts?.phoneNumber || "No phone number");
    setUId(user?.id);
  }, [user]);

  const handleToggle = () => {
    setWindowUserProfile(true);
  };

  const handleClose = () => {
    setWindowUserProfile(false);
  };

  return (
    <>
      <div
        className="user-preview"
        onClick={handleToggle}
        style={{
          cursor: "pointer",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "16px",
          }}
        >
          <WidgetUserAvatar
            currentUser={user}
            collectionName="users"
            documentId={uId}
            fieldName="user.files.avatarFiles"
          />
          {/* <AvatarUser size={80} url={urlAvatarFile} /> */}
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "4px",
            }}
          >
            <h6>{ownerName}</h6>
            <div>
              <p className="p-light">{email}</p>
              <p className="p-light">{phoneNumber}</p>
            </div>
          </div>
        </div>
        <div>
          <span className="material-symbols-outlined">edit</span>
        </div>
      </div>

      <PopupDirrectory
        isOpen={windowUserProfile}
        onClose={handleClose}
        title="User profile moderation"
      >
        <WindowUserProfileModeration />
      </PopupDirrectory>
    </>
  );
};

export default WidgetModerationUserPreview;
