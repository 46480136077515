import { createAsyncThunk } from "@reduxjs/toolkit";
import { arrayUnion } from "firebase/firestore";
import interpretAuthErrorV2 from "../../../functions/interpretAuthErrorV2";
import { addData } from "../../../services/firebase/crudFirestore";

export const addDirectoryThunk = createAsyncThunk(
  "directory/addDirectoryThunk",
  async ({ collection, newData }, { rejectWithValue }) => {
    try {
      console.log("addDirectoryThunk", collection, newData);
      // Добавляем новый байк в коллекцию bikes
      const newBike = await addData(collection, newData);

      return newBike;
    } catch (error) {
      const errorMessage = interpretAuthErrorV2(error.code);
      return rejectWithValue({ errorMessage });
    }
  }
);
