import React from "react";
import WigetCollapseComponent from "../UniversalWidgets/WigetCollapseComponent";
import { v4 as uuidv4 } from "uuid";

const WigetAdditionalServices = ({ additionalServices, title }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "var(--color-white)",
        borderRadius: "24px",
      }}
    >
      <div
        style={{
          padding: "16px 24px",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          boxSizing: "border-box",
        }}
      >
        <h5>Additional services</h5>
        <p className="p-light">
          available services: {additionalServices.length}
        </p>
      </div>

      {additionalServices.map((item) => {
        return (
          <div key={uuidv4()}>
            <WigetCollapseComponent
              item={
                <div
                  style={{
                    padding: "16px 24px",
                    lineHeight: "1.4",
                    color: "var(--color-gray-900)",
                    width: "70%",
                  }}
                >
                  {item.description}
                </div>
              }
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  gap: "64px",
                }}
              >
                <div>
                  <p
                    style={{
                      lineHeight: "1.4",
                    }}
                  >
                    {item.title}
                  </p>
                  <p
                    style={{
                      lineHeight: "1.4",
                    }}
                    className="wigetProdictCard-characteristics-bottom"
                  >
                    {item.characteristics}
                  </p>
                </div>
                {item.price > 0 ? (
                  <p style={{ fontWeight: "700" }}>
                    {item.price.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </p>
                ) : (
                  <p style={{ fontWeight: "700" }}>Free</p>
                )}
              </div>
            </WigetCollapseComponent>
          </div>
        );
      })}
    </div>
  );
};

export default WigetAdditionalServices;
