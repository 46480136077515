import React, { useState, useEffect, useRef } from "react";
import { Autocomplete } from "@react-google-maps/api";
import Button from "../../UIElements/Button";
import { updateDocumentById } from "../../../services/firebase/crudFirebaseStorage";
import SearchAddewssInput from "./SearchAddewssInput";
import { useDispatch, useSelector } from "react-redux";
import { setLocation } from "../../../slice/stateGUISlice";
import Popup from "../../UIElements/Popup";

// Библиотека которая будет использоваться в GoogleMaps
const googleMapsLibraries = ["places"];

const googleAPIKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const Geocoding = ({ description, title }) => {
  const dispatch = useDispatch();

  const currentRentalPoint = useSelector(
    (state) => state.stateGUISlice.currentRentalPoint
  );
  const idCurrentRentalPoint = useSelector(
    (state) => state.stateGUISlice.currentRentalPoint.id
  );
  const location = useSelector(
    (state) => state.stateGUISlice.newAddress.location
  );
  const isApiLoaded2 = useSelector(
    (state) => state.config.loadingStateGoogleMapApi
  );

  // const [isApiLoaded, setIsApiLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [countryShortCode, setCountryShortCode] = useState(null);
  const [activePopup, setActivePopup] = useState(null);
  const [address, setAddress] = useState(""); // Состояние для поля ввода адреса
  const [isChanged, setIsChanged] = useState(false); // Состояние для отслеживания изменений
  const [isLoading, setIsLoading] = useState(false); // Состояние для отслеживания загрузки

  const autocompleteRef = useRef(null);
  const mapRef = useRef(null);

  const addressInfo = {
    address: address,
    location: {
      lat: location?.lat ? location.lat : 0,
      lng: location?.lng ? location.lng : 0,
    },
    city: city ? city : "",
    country: country ? country : "",
    countryShortCode: countryShortCode,
  };
  const pointAddress = currentRentalPoint?.pointAddress?.address
    ? currentRentalPoint?.pointAddress?.address
    : "Set rental point address";

  useEffect(() => {
    const handleError = (event) => {
      console.error(
        "Global error:",
        event.message,
        event.filename,
        event.lineno,
        event.colno,
        event.error
      );
      setError(event.message);
      return true;
    };

    window.addEventListener("error", handleError);

    return () => {
      window.removeEventListener("error", handleError);
    };
  }, []);

  // Обработчик изменения currentRentalPoint
  useEffect(() => {
    if (
      isApiLoaded2 &&
      currentRentalPoint &&
      currentRentalPoint?.pointAddress?.address
    ) {
      setAddress(currentRentalPoint.pointAddress.address);
      geocodeAddress(currentRentalPoint.pointAddress.address);
    } else if (isApiLoaded2) {
      setAddress("");
      // fetchInitialLocation();
    }
  }, [currentRentalPoint, isApiLoaded2]);

  // Определение геопозиции (геолокация)
  // const fetchInitialLocation = async () => {
  //     try {

  //         // Запрашиваем геопозицию у браузера
  //         const position = await new Promise((resolve, reject) => {
  //             navigator.geolocation.getCurrentPosition(
  //                 (position) => resolve(position),
  //                 (error) => reject(error)
  //             );
  //         });

  //         // Получаем координаты из объекта геопозиции
  //         const { coords } = position;

  //         // Обновляем состояние начальной геопозиции
  //         dispatch(setLocation({
  //             lat: coords.latitude,
  //             lng: coords.longitude,
  //         }));

  //     } catch (error) {
  //         console.error('Error fetching initial location:', error);
  //         // Здесь можно обработать ошибку, например, предложить ввести адрес вручную
  //     }

  // };

  // Сохранение значения в коллекцию rentalpoint
  // const savePointAddress = async () => {
  //     if (address
  //         && location.lat
  //         && location.lng) {
  //         await updateDocumentById(
  //             {
  //                 'pointAddress': {
  //                     'address': address,
  //                     'location': {
  //                         'lat': location.lat,
  //                         'lng': location.lng
  //                     },
  //                     'city': city,
  //                     'country': country
  //                 }
  //             },
  //             'rentalPoints',
  //             idDocument
  //         );
  //         console.log('Адрес сохранен')
  //     } else {
  //         console.log('Адрес не указан')
  //     }
  // };

  // Обработчик загрузки автозаполнения
  // Autocomplete - выпадающий список с предложениями, которые соответствуют введенному тексту
  const onLoad = (autocomplete) => {
    autocompleteRef.current = autocomplete;
  };

  // Обработчик изменения поля ввода адреса
  const handleAddressChange = (e) => {
    setAddress(e.target.value); // Обновляем состояние поля адреса
    setIsChanged(true); // Устанавливаем флаг изменения
  };

  // Обработчик сохранения адреса
  const handleSaveAddress = () => {
    setIsLoading(true); // Устанавливаем флаг загрузки (для демонстрации)
    // Ваша логика сохранения адреса здесь
    // В конце, сбросим флаг изменения и флаг загрузки
    setIsChanged(false);
    setIsLoading(false);
  };

  // Обработчик выбора места из автозаполнения
  const onPlaceSelected = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();

      // Если объект place и его геометрия (place.geometry) существуют, это означает, что пользователь выбрал вариант из выпадающего списка, который содержит информацию о местоположении.
      if (place && place.geometry) {
        const { location } = place.geometry;

        dispatch(
          setLocation({
            lat: location.lat(),
            lng: location.lng(),
          })
        );

        setAddress(place.formatted_address);

        setError(null);

        // Если присутствует ссылка на карту (mapRef.current), то карта плавно перемещается к новым координатам местоположения.
        if (mapRef.current) {
          mapRef.current.panTo(location);
        }

        // если place или его геометрия отсутствуют, устанавливается ошибка, сообщающая пользователю, что необходимо выбрать опцию из выпадающего списка, и состояние location устанавливается в null
      } else {
        setError("Please select an option from the dropdown.");
        dispatch(setLocation(null));
      }
    }
  };

  // Кодирование адреса в координаты
  const geocodeAddress = (addressToGeocode) => {
    // console.log('addressToGeocode', addressToGeocode)

    if (window.google) {
      const geocoder = new window.google.maps.Geocoder();

      geocoder.geocode({ address: addressToGeocode }, (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            const { location } = results[0].geometry;

            dispatch(
              setLocation({
                lat: location.lat(),
                lng: location.lng(),
              })
            );

            // Устанавливаем адрес в состояние
            setAddress(results[0].formatted_address);

            // Извлекаем название города
            const cityComponent = results[0].address_components.find(
              (component) => component.types.includes("locality")
            );

            // Извлекаем название страны
            const countryComponent = results[0].address_components.find(
              (component) => component.types.includes("country")
            );

            // Обновляем состояния страны и города
            setCity(cityComponent ? cityComponent.long_name : "");

            setCountry(countryComponent ? countryComponent.long_name : "");

            // Код страны
            if (countryComponent) {
              setCountry(countryComponent.long_name);

              // Код страны
              const countryShortCode = countryComponent.short_name;

              // Обновляем состояние кода страны
              setCountryShortCode(countryShortCode);
            } else {
              setError(
                "Country information not found in the address components."
              );
            }

            if (mapRef.current) {
              mapRef.current.panTo(location);
            }
          } else {
            setError("No results found for the provided address.");
          }
        } else {
          setError("Geocoder failed due to: " + status);
        }
      });
    } else {
      console.error("Google Maps JavaScript API library is not loaded.");
    }
  };

  const handleOpenPopup = (popupName) => {
    setActivePopup(popupName);
  };

  const handleClosePopup = () => {
    setActivePopup(null);
  };

  const savePointAddress = async () => {
    setIsLoading(true);
    if (address && location.lat && location.lng) {
      await updateDocumentById(
        {
          pointAddress: addressInfo,
        },
        "rentalPoints",
        idCurrentRentalPoint
      );
      console.log("Адрес сохранен");
      setIsLoading(false);
    } else {
      console.log("Адрес не указан");
      setIsLoading(false);
    }
  };

  // const centerShiftX = -300;
  // const centerCoordinates = { lat: location.lat, lng: location.lng };

  // const shiftedCenterCoordinates = { lat: location.lat, lng: location.lng + centerShiftX / (111320 * Math.cos((location.lat * Math.PI) / 180)) };

  // const sizeMapPrewiew = '600x300'
  // const urlMap = `https://maps.googleapis.com/maps/api/staticmap?center=${shiftedCenterCoordinates.lat},${shiftedCenterCoordinates.lng}&zoom=15&size=${sizeMapPrewiew}&maptype=roadmap&markers=${location.lat},${location.lng}&format=png&key=${googleAPIKey}`;

  return (
    <div>
      {/* <img src={urlMap} alt='map' /> */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "0 16px",
          boxSizing: "border-box",
        }}
      >
        <div
          style={{
            backgroundColor: "var(--color-white)",
            boxSizing: "border-box",
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              height: "48px",
              padding: "0 16px",
              ...(addressInfo?.address.length > 0 && {
                borderBottom: "1px var(--color-gray-200) solid",
              }),
            }}
          >
            <p
              style={{
                textTransform: "uppercase",
              }}
              className="p-light"
            >
              {title}
            </p>

            <button onClick={() => handleOpenPopup(`popu`)}>
              {addressInfo?.address.length > 0 ? "Edit" : "Add"}
            </button>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginLeft: "16px",
              boxSizing: "border-box",
              height: "48px",
            }}
          >
            <div className="inform-box-content-left">{pointAddress}</div>

            <div className="inform-box-content-right">
              <span
                style={{
                  color: "var(--color-gray-400)",
                }}
                className="material-symbols-outlined"
              >
                chevron_right
              </span>
            </div>
          </div>
        </div>

        {description && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "8px 16px",
            }}
          >
            <p className="p-light">{description}</p>
          </div>
        )}
      </div>
      {/* <LoadScript
                googleMapsApiKey={googleAPIKey}
                libraries={googleMapsLibraries}
                onLoad={() => setIsApiLoaded(true)}
            > */}
      {isApiLoaded2 && activePopup !== null && (
        <Popup
          isOpen={true}
          onClose={handleClosePopup}
          title={title}
          leftMenu={<></>}
          rightMenu={
            <>
              {/* <button onClick={() => savePointAddress()}>Save</button> */}
              {isChanged ? (
                <Button onClick={() => savePointAddress()} disabled={isLoading}>
                  {isLoading ? "...Loading" : "Save"}
                </Button>
              ) : (
                <Button type="disable">Save</Button>
              )}
            </>
          }
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eleifend ipsum vitae nunc gravida facilisis. Pellentesque finibus, justo id tincidunt accumsan, mauris elit consequat nulla, vel tempus mi lorem vel ipsum. Integer id tincidunt sapien. Cras faucibus euismod massa eget interdum. Sed commodo, ante eu tempor condimentum, elit dui lacinia nisi, in pulvinar enim enim ut orci. Suspendisse potenti."
        >
          <Autocomplete
            onLoad={onLoad}
            onPlaceChanged={onPlaceSelected}
            options={{
              zIndex: 1000,
            }}
          >
            <SearchAddewssInput
              label={`Address`}
              type="text"
              value={address}
              onChange={handleAddressChange}
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            />
          </Autocomplete>
        </Popup>
      )}
      {error && <p>Error: {error}</p>}
      {/* </LoadScript> */}
    </div>
  );
};

export default Geocoding;
