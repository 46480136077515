import { createAsyncThunk } from "@reduxjs/toolkit";
import interpretAuthErrorV2 from "../../../functions/interpretAuthErrorV2";

export const fetchDirectoriesThunkV2 = createAsyncThunk(
  "private/directory/fetchDirectoriesThunkV2",
  async (_, { getState, rejectWithValue }) => {
    try {
      // Получаем данные из состояния Redux
      const state = getState();
      const { countries, cities, brands, categories } = state.directorySliceV2;

      // Проверяем, загружены ли данные
      if (
        !countries.length ||
        !cities.length ||
        !brands.length ||
        !categories.length
      ) {
        throw new Error("One or more directories are not loaded.");
      }

      // Возвращаем данные из состояния Redux
      return {
        countries,
        cities,
        brands,
        categories,
      };
    } catch (error) {
      const errorMessage = interpretAuthErrorV2(error.code || error.message);
      return rejectWithValue({ errorMessage });
    }
  }
);

// import { createAsyncThunk } from "@reduxjs/toolkit";
// import { fetchDocumentsByIds } from "../../../services/firebase/crudFirestore";
// import interpretAuthErrorV2 from "../../../functions/interpretAuthErrorV2";

// export const fetchDirectoriesThunkV2 = createAsyncThunk(
//   "private/directory/fetchDirectoriesThunkV2",
//   async (_, { rejectWithValue }) => {
//     try {
//       const [country, city, brand, category] = await Promise.all([
//         fetchDocumentsByIds("country"),
//         fetchDocumentsByIds("city"),
//         fetchDocumentsByIds("brand"),
//         fetchDocumentsByIds("category"),
//       ]);

//       return {
//         countries: country,
//         cities: city,
//         brands: brand,
//         categories: category,
//       };
//     } catch (error) {
//       const errorMessage = interpretAuthErrorV2(error.code);
//       return rejectWithValue({ errorMessage });
//     }
//   }
// );
