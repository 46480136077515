import React from "react";
import { WigetFAQDetail } from "./WigetFAQ/WigetFAQDetail";
import SearchBars from "../../../../UIComponents/bars/searchBars/SearchBars";
import Icon44 from "../../../../UIComponents/icons/Icon44";
import { setPrivateSideBar } from "../../../../../slice/stateGUISlice";
import WigetButtonAdd from "../../../../UniversalWidgets/WigetButtonAdd";
import { useDispatch } from "react-redux";

const WigetFAQListBox = () => {

    const dispatch = useDispatch();


    return (
        <>
            {/* <div className="wigetRentalPointItsBox">
                <h3>FAQ</h3>
                <p className='p-primary'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eleifend ipsum vitae nunc gravida facilisis.</p>
            </div> */}

            <SearchBars
                title='Currencyes'

                iconLeft={
                    <Icon44
                        click={() => dispatch(setPrivateSideBar(true))}
                        icon={
                            <span className="material-symbols-outlined">
                                dock_to_right
                            </span>
                        }
                    />
                }
                iconRight={
                    <WigetButtonAdd
                        collectionName='faq'
                        // dataObject={structureCollection}
                        nameButton={`Add FAQ`}
                    />
                }
            />

            <div className='scrollBox'>
                <WigetFAQDetail
                    collectionName="faq"
                    title="FAQ" />
            </div>

        </>
    );
}

export default WigetFAQListBox;


