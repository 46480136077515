import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchCurrentBikeDataThunkV2 } from "../../../reducersToolkit/thunk/catalog/fetchCurrentBikeDataThunkV2";
import PriorityImage from "../../Private/Login/Profile/WidgetsProfile/PhotosGallery/PriorityImage";
import PhotosGallery from "../../Private/Login/Profile/WidgetsProfile/PhotosGallery/PhotosGallery";
import WidgetRate from "./WidgetRate";
import Preloader from "../../UIElements/Preloader";
import ButtonsBar from "../../Private/Login/Profile/WidgetsProfile/ButtonsBar/ButtonsBar";
import Button from "../../Private/Login/Profile/WidgetsProfile/Buttons/Button";
import Switcher from "../../Private/Login/Profile/WidgetsProfile/Switcher/Switcher";
import PopupWindow from "../PublickWidgets/Windows/PopupWindow";
import WindowAddToCart from "./WindowAddToCart";
import WindowOrderDetailModeration from "../../Private/Login/Profile/OrdersV3/WindowOrderDetailModeration";
import {
  setBookingDates,
  setOrderBike,
  setOrderStatus,
} from "../../../reducersToolkit/orderSliceV2";
import PopupDirrectory from "../../Private/Login/Profile/WidgetsProfile/Popups/PopupDirrectory";
import ContentBox from "../../Private/Login/Profile/WidgetsProfile/ContentBox/ContentBox";
import Table from "../../Private/Login/Profile/WidgetsProfile/Table/Table";
import CollapsableText from "../../Private/Login/Profile/WidgetsProfile/Collapsable/CollapsableText";
import { formatCurrencyUSD } from "../../../functions/currencyFormatter";
import WidgetBikeDetail from "./WidgetBikeDetail";
import WidgetPreviewRentalPoint from "./WidgetPreviewRentalPoint";
import WidgetAditionalServices from "./WidgetAditionalServices";

const PageProductCardV2 = () => {
  const dispatch = useDispatch();
  const { language, bikeTitle, id } = useParams();

  const [titleBike, setTitleBike] = useState("No title");
  const [descriptionBike, setDescriptionBike] = useState("No description");
  const [bikePhotoFiles, setBikePhotoFiles] = useState([]);
  const [brandTitle, setBrandTitle] = useState("No brand");
  const [categoryTitle, setCategoryTitle] = useState("No category");
  const [rate, setRate] = useState([]);
  const [idBike, setIdBike] = useState("");
  const [startBookingDates, setStartBookingDates] = useState(0);
  const [endBookingDates, setEndBookingDates] = useState(0);
  const [timetable, setTimetable] = useState([]);
  const [logoFile, setLogoFile] = useState([]);
  const [titleRentalPoint, setTitleRentalPoint] = useState("");
  const [descriptionRentalPoint, setDescriptionRentalPoint] = useState("");
  const [cityRentalPoint, setCityRentalPoint] = useState("No city");
  const [countryRentalPoint, setCountryRentalPoint] = useState("No country");
  const [photoFiles, setPhotoFiles] = useState([]);
  const [quantityBikes, setQuantityBikes] = useState(0);
  const [additionalServices, setSdditionalServices] = useState([]);
  const [
    isOpenWindowOrderDetailModeration,
    setIsOpenWindowOrderDetailModeration,
  ] = useState(false);
  const [selectedRentalPoint, setSelectedRentalPoint] = useState({});
  const [selectedBike, setSelectedBike] = useState({});
  const [logoRentalPoint, setLogoRentalPoint] = useState([]);
  const [countryPhotos, setCountryPhotos] = useState([]);
  const [cityPhotos, setCityPhotos] = useState([]);
  const [characteristicsBike, setCharacteristicsBike] = useState([]);

  const currentUser = useSelector((state) => state.userSliceV2.currentUser);

  const {
    filteredBikes,
    bikes,
    rentalPoints,
    cities,
    countries,
    brands,
    categories,
    isLoading,
    filters,
    currentBike,
  } = useSelector((state) => state.catalogSliceV2);

  useEffect(() => {
    dispatch(fetchCurrentBikeDataThunkV2({ idCurrentBike: id }));
  }, [dispatch, id]);

  useEffect(() => {
    setIdBike(currentBike?.id || "");
    setTitleBike(
      currentBike?.basicInformation?.title?.titleOriginal || "No title"
    );
    setBikePhotoFiles(currentBike?.files?.bikePhotoFiles || []);
    setBrandTitle(
      currentBike?.directory?.brand?.title?.titleOriginal || "No brand"
    );
    setCategoryTitle(
      currentBike?.directory?.category?.title?.titleOriginal || "No category"
    );
    setDescriptionBike(
      currentBike?.basicInformation?.description?.descriptionOriginal ||
        "No description"
    );
    setRate(currentBike?.rate || []);
    setTimetable(currentBike?.rentalPoint?.timetable || []);
    setLogoFile(currentBike?.rentalPoint?.files?.logoFile || []);
    setTitleRentalPoint(
      currentBike?.rentalPoint?.basicInformation?.title?.titleOriginal || ""
    );
    setDescriptionRentalPoint(
      currentBike?.rentalPoint?.basicInformation?.description
        ?.descriptionOriginal || ""
    );
    setCityRentalPoint(
      currentBike?.rentalPoint?.location?.city?.title?.titleOriginal ||
        "No city"
    );
    setCountryPhotos(
      currentBike?.rentalPoint?.location?.city?.file_photo || []
    );

    setCityPhotos(
      currentBike?.rentalPoint?.location?.country?.files?.iconFiles || []
    );

    setCountryRentalPoint(
      currentBike?.rentalPoint?.location?.country?.title?.titleOriginal ||
        "No country"
    );
    setPhotoFiles(currentBike?.rentalPoint?.files?.photoFiles || []);
    setQuantityBikes(currentBike?.rentalPoint?.bikesIds?.length || 0);
    setSdditionalServices(
      currentBike?.rentalPoint?.additionalServices?.services || []
    );
    setSelectedRentalPoint(currentBike?.rentalPoint || {});
    setSelectedBike(currentBike);
    setLogoRentalPoint(currentBike?.rentalPoint?.files?.logoFile || []);
    setCharacteristicsBike(currentBike?.characteristics || []);
  }, [currentBike]);

  useEffect(() => {
    setStartBookingDates(filters.bookingDates.startDay);
    setEndBookingDates(filters.bookingDates.endDay);
  }, [filters]);

  const handleOpenWindowMainFilter = () => {
    setIsOpenWindowOrderDetailModeration(true);
  };

  const onClose = () => {
    setIsOpenWindowOrderDetailModeration(false);
  };

  const addCart = () => {
    dispatch(
      setOrderStatus({
        mode: "new",
        status: "new",
        userId: currentUser.id,
        timestamp: Date.now(),
        comment: "",
      })
    );
    dispatch(
      setOrderBike({
        mode: "new",
        createdat: Date.now(),
        userCustomer: { ...currentUser.user },
        orderRentalPoint: selectedRentalPoint,
        orderBike: selectedBike,
      })
    );
    dispatch(
      setBookingDates({
        mode: "new",
        startDay: startBookingDates,
        endDay: endBookingDates,
      })
    );
    handleOpenWindowMainFilter();
  };

  return isLoading ? (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Preloader />
    </div>
  ) : (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          boxSizing: "border-box",
          backgroundColor: "var(--color-gray-100)",
          padding: "0 16px 72px 16px",
        }}
      >
        <div
          style={{
            paddingTop: "80px",
            maxWidth: "var(--width-mobile-content)",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "8px",
            boxSizing: "border-box",
            position: "relative",
          }}
        >
          <WidgetBikeDetail
            bikePhotoFiles={bikePhotoFiles}
            isLoading={isLoading}
            brandTitle={brandTitle}
            categoryTitle={categoryTitle}
            titleBike={titleBike}
            descriptionBike={descriptionBike}
            startBookingDates={startBookingDates}
            endBookingDates={endBookingDates}
            rate={rate}
          />

          <WidgetPreviewRentalPoint
            cityPhotos={cityPhotos}
            countryPhotos={countryPhotos}
            logoRentalPoint={logoRentalPoint}
            titleRentalPoint={titleRentalPoint}
            photoFiles={photoFiles}
            isLoading={isLoading}
            descriptionRentalPoint={descriptionRentalPoint}
            quantityBikes={quantityBikes}
            countryRentalPoint={countryRentalPoint}
            cityRentalPoint={cityRentalPoint}
            timetable={timetable}
          />

          {additionalServices?.length > 0 && (
            <WidgetAditionalServices additionalServices={additionalServices} />
          )}
        </div>
      </div>
      <ButtonsBar>
        <div
          style={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "1fr",
            gap: "16px",
          }}
        >
          <Button
            label="Booking biket"
            active={true}
            onClick={addCart}
            type="small"
          />
        </div>
      </ButtonsBar>
      <PopupDirrectory
        title="Book a bike"
        isOpen={isOpenWindowOrderDetailModeration}
        onClose={onClose}
      >
        <WindowOrderDetailModeration mode="new" onClose={onClose} />
      </PopupDirrectory>
    </>
  );
};

export default PageProductCardV2;
