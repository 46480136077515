import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchData,
  fetchDocumentById,
} from "../../services/firebase/crudFirebaseStorage";

export const fetchOrdersThunk = createAsyncThunk(
  "orders/fetchOrdersThunk/",
  async (uId, thunkAPI) => {
    try {
      // Используем setTimeout для создания задержки в 1 секунду
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Предположим, что fetchData теперь может принимать uId как аргумент
      const ordersData = await fetchData("orders");
      const userData = await fetchDocumentById("user", uId);
      const rentalPointsData = await fetchData("rentalPoints");
      console.log("ordersData rentalPointsData ✅", rentalPointsData);

      let matchedOrdersClient = [];
      let matchedOrdersManage = [];
      let matchedRentalPointsClient = [];

      if (ordersData && userData && rentalPointsData) {
        // Фильтрация заказов конкретного пользователя
        matchedOrdersClient = ordersData.filter((order) =>
          userData.orders_id.includes(order.id)
        );

        console.log("ordersData matchedOrdersClient ✅", matchedOrdersClient);
        // Фильтрация заказов по точкам проката которые зарегистрировал пользователь
        matchedOrdersManage = ordersData.filter((order) =>
          userData?.rentalPoints_id.includes(order.order_info.rentalPoints_id)
        );

        console.log("ordersData matchedOrdersManage ✅", matchedOrdersManage);

        matchedRentalPointsClient = rentalPointsData.filter((rentalPoint) =>
          userData.rentalPoints_id.includes(rentalPoint.id)
        );
        console.log(
          "ordersData matchedRentalPointsClient ✅",
          matchedRentalPointsClient
        );
      }

      console.log("ordersData ✅", ordersData);

      // Возвращаем объект с необходимыми данными
      return {
        matchedOrdersClient,
        matchedOrdersManage,
        ordersData,
        userData,
      };
    } catch (error) {
      // Если произошла ошибка при получении данных, выбрасываем ошибку с сообщением
      throw new Error(
        `Ошибка при получении данных ordersData: ${error.message}`
      );
    }
  }
);
