import React, { useEffect, useState } from "react";
import PopupDirrectory from "../WidgetsProfile/Popups/PopupDirrectory";
import { useDispatch } from "react-redux";
import "../profile.css";
import WindowRentalPointDetail from "../RentalPointsV3/WindowRentalPointDetail";
import { setSelectedRentalPoint } from "../../../../../reducersToolkit/rentalPointsSliiceV2";
import SegmentedControls from "../WidgetsProfile/SegmentedControls/SegmentedControls";
import WindowRentalPointDetailModeration from "./WindowRentalPointDetailModeration";
import { setSelectedRentalPointModeration } from "../../../../../reducersToolkit/moderationSliceV2";

const RentalPointsListStatus = ({ rentalPointsList, state, mode }) => {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [sortedRentalPoints, setSortedRentalPoints] = useState([]);
  const [currentRentalPointItem, setCurrentRentalPointItem] = useState(null);
  const [
    isOpenWindowRentalPointDetailModeration,
    setIsOpenWindowRentalPointDetailModeration,
  ] = useState(false);

  useEffect(() => {
    const sorted = [...rentalPointsList].sort((a, b) => b.created - a.created);
    setSortedRentalPoints(sorted);
  }, [rentalPointsList]);

  const handleToggle = (data) => {
    if (mode === "moderation") {
      dispatch(setSelectedRentalPointModeration(data));
      setCurrentRentalPointItem(data);
      setIsOpenWindowRentalPointDetailModeration(true);
    } else if (mode === "edit") {
      setIsOpen(true);
      setCurrentRentalPointItem(data);
      dispatch(setSelectedRentalPoint(data));
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setIsOpenWindowRentalPointDetailModeration(false);
  };

  return (
    <>
      {sortedRentalPoints?.length > 0 && (
        <div
          style={{
            width: "100%",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "16px",
              boxSizing: "border-box",
              width: "100%",
            }}
          >
            {sortedRentalPoints?.map(
              (rentalPointItem) =>
                rentalPointItem?.rentalPointStatus?.currentStatus === state && (
                  <div
                    key={rentalPointItem.id}
                    onClick={() => handleToggle(rentalPointItem)}
                    style={{
                      display: "grid",
                      gridTemplateRows: "repeat(4, 1fr)",
                      flex: "1",
                      backgroundColor: "var(--color-gray-100)",
                      boxSizing: "border-box",
                      gap: "8px",
                      aspectRatio: "1/1",
                      borderRadius: "16px",
                      padding: "4px",
                      cursor: "pointer",
                      width: "100%",
                      backgroundImage:
                        "url(https://firebasestorage.googleapis.com/v0/b/biketripste.appspot.com/o/static%2Fmap%2F2024-05-11_11-05-16.png?alt=media&token=abba679b-3c9a-4dee-bd49-2f6872af8eb7)",
                      backgroundSize: "auto 300%",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      position: "relative",
                    }}
                  >
                    {rentalPointItem.rentalPointStatus && (
                      <div
                        style={{
                          padding: "4px 8px",
                          position: "absolute",
                          left: "8px",
                          top: "8px",
                          backgroundColor: "var(--color-alarm)",
                          borderRadius: "80px",
                        }}
                      >
                        <p
                          className="p-light"
                          style={{
                            color: "var(--color-white)",
                          }}
                        >
                          {state}
                        </p>
                      </div>
                    )}

                    <div
                      style={{
                        borderRadius: "8px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gridRow: "1 / span 2",
                      }}
                    >
                      <div>
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/biketripste.appspot.com/o/static%2Fmap%2FselectedIconMapPointShadow.svg?alt=media&token=79f1fe50-77cf-4185-9d00-65e758d0f7fb"
                          alt=""
                          style={{
                            width: "80px",
                          }}
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        gridRow: "3 / span 5",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "end",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          justifyContent: "center",
                          gap: "4px",
                          backgroundColor: "var(--color-white)",
                          borderRadius: "12px",
                          boxSizing: "border-box",
                          padding: "16px",
                          width: "100%",
                        }}
                      >
                        <h6>{rentalPointItem?.title?.titleOriginal}</h6>
                        <p
                          className="p-light"
                          style={{
                            textOverflow: "ellipsis",
                          }}
                        >
                          {rentalPointItem?.location?.pointAddress
                            ? rentalPointItem?.location?.pointAddress
                            : "Please enter your address"}
                        </p>
                      </div>
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
      )}
      <PopupDirrectory
        isOpen={isOpen}
        onClose={handleClose}
        title={
          currentRentalPointItem?.title?.titleOriginal
            ? currentRentalPointItem?.title?.titleOriginal
            : "Rental point"
        }
      >
        <WindowRentalPointDetail
          onClose={handleClose}
          mode="edit"
          state={state}
        />
      </PopupDirrectory>
      <PopupDirrectory
        isOpen={isOpenWindowRentalPointDetailModeration}
        onClose={handleClose}
        title="Rental point moderation"
      >
        <WindowRentalPointDetailModeration
          mode="moderation"
          onClose={handleClose}
        />
      </PopupDirrectory>
    </>
  );
};

const WidgetRentalPointsList = ({ rentalPointsList, mode }) => {
  const [renalPointState, setRenalPointState] = useState("moderation");

  return (
    <>
      <SegmentedControls
        options={["moderation", "activated", "deactivated"]}
        onSelect="moderation"
        // label="Partners in project"
        selected={setRenalPointState}
      />
      {renalPointState && (
        <>
          {renalPointState === "moderation" && (
            <RentalPointsListStatus
              rentalPointsList={rentalPointsList}
              mode={mode}
              state="moderation"
            />
          )}
          {renalPointState === "activated" && (
            <RentalPointsListStatus
              rentalPointsList={rentalPointsList}
              mode={mode}
              state="approved"
            />
          )}
          {renalPointState === "deactivated" && (
            <RentalPointsListStatus
              rentalPointsList={rentalPointsList}
              mode={mode}
              state="banned"
            />
          )}
        </>
      )}
    </>
  );
};

export default WidgetRentalPointsList;

// import React, { useEffect, useState } from "react";
// import PopupDirrectory from "../WidgetsProfile/Popups/PopupDirrectory";
// import { useDispatch } from "react-redux";
// import WindowRentalPointDetailModeration from "./WindowRentalPointDetailModeration";
// import { setSelectedRentalPointModeration } from "../../../../../reducersToolkit/moderationSliceV2";
// import SegmentedControls from "../WidgetsProfile/SegmentedControls/SegmentedControls";
// import Preloader from "../../../../UIElements/Preloader";

// const WidgetRentalPointsList = ({ rentalPointsList, isLoadingBikes }) => {
//   const dispatch = useDispatch();

//   const [
//     isOpenWindowRentalPointDetailModeration,
//     setIsOpenWindowRentalPointDetailModeration,
//   ] = useState(false);
//   const [sortedRentalPoints, setSortedRentalPoints] = useState([]);
//   const [rentalPointState, setRentalPointState] = useState([]);

//   useEffect(() => {
//     if (rentalPointsList) {
//       const sorted = [...rentalPointsList].sort(
//         (a, b) => b.created - a.created
//       );
//       setSortedRentalPoints(sorted);
//     }
//   }, [rentalPointsList]);

//   const handleClose = () => {
//     setIsOpenWindowRentalPointDetailModeration(false);
//   };

//   const handleToggle = (data) => {
//     dispatch(setSelectedRentalPointModeration(data));

//     setIsOpenWindowRentalPointDetailModeration(true);
//   };

//   return (
//     <>
//       {rentalPointsList.length > 0 && (
//         <>
//           <SegmentedControls
//             options={["moderation", "approved", "banned"]}
//             onSelect="moderation"
//             selected={setRentalPointState}
//           />
//           <div
//             style={{
//               width: "100%",
//               boxSizing: "border-box",
//             }}
//           >
//             {isLoadingBikes ? (
//               <div
//                 style={{
//                   width: "100%",
//                   display: "flex",
//                   alignItems: "center",
//                   justifyContent: "center",
//                 }}
//               >
//                 <Preloader />
//               </div>
//             ) : (
//               <div
//                 style={{
//                   display: "grid",
//                   gridTemplateColumns: "repeat(2, 1fr)",
//                   gap: "16px",
//                   boxSizing: "border-box",
//                   width: "100%",
//                 }}
//               >
//                 {sortedRentalPoints?.map(
//                   (rentalPointItem) =>
//                     rentalPointItem?.rentalPointStatus?.currentStatus ===
//                       rentalPointState && (
//                       <div
//                         key={rentalPointItem?.id}
//                         onClick={() => handleToggle(rentalPointItem)}
//                         style={{
//                           display: "grid",
//                           gridTemplateRows: "repeat(4, 1fr)",
//                           flex: "1",
//                           backgroundColor: "var(--color-gray-100)",
//                           boxSizing: "border-box",
//                           gap: "8px",
//                           aspectRatio: "1/1",
//                           borderRadius: "16px",
//                           padding: "4px",
//                           cursor: "pointer",
//                           width: "100%",
//                           backgroundImage:
//                             "url(https://firebasestorage.googleapis.com/v0/b/biketripste.appspot.com/o/static%2Fmap%2F2024-05-11_11-05-16.png?alt=media&token=abba679b-3c9a-4dee-bd49-2f6872af8eb7)",
//                           backgroundSize: "auto 300%",
//                           backgroundPosition: "center",
//                           backgroundRepeat: "no-repeat",
//                           position: "relative",
//                         }}
//                       >
//                         {rentalPointItem?.rentalPointStatus === "new" && (
//                           <div
//                             style={{
//                               padding: "4px 8px",
//                               position: "absolute",
//                               left: "8px",
//                               top: "8px",
//                               backgroundColor: "var(--color-alarm)",
//                               borderRadius: "80px",
//                             }}
//                           >
//                             <p
//                               className="p-light"
//                               style={{
//                                 color: "var(--color-white)",
//                               }}
//                             >
//                               New
//                             </p>
//                           </div>
//                         )}

//                         <div
//                           style={{
//                             borderRadius: "8px",
//                             display: "flex",
//                             alignItems: "center",
//                             justifyContent: "center",
//                             gridRow: "1 / span 2",
//                           }}
//                         >
//                           <div>
//                             <img
//                               src="https://firebasestorage.googleapis.com/v0/b/biketripste.appspot.com/o/static%2Fmap%2FselectedIconMapPointShadow.svg?alt=media&token=79f1fe50-77cf-4185-9d00-65e758d0f7fb"
//                               alt=""
//                               style={{
//                                 width: "80px",
//                               }}
//                             />
//                           </div>
//                         </div>

//                         <div
//                           style={{
//                             gridRow: "3 / span 5",
//                             display: "flex",
//                             flexDirection: "column",
//                             alignItems: "center",
//                             justifyContent: "end",
//                           }}
//                         >
//                           <div
//                             style={{
//                               display: "flex",
//                               flexDirection: "column",
//                               alignItems: "flex-start",
//                               justifyContent: "center",
//                               gap: "4px",
//                               backgroundColor: "var(--color-white)",
//                               borderRadius: "12px",
//                               boxSizing: "border-box",
//                               padding: "16px",
//                               width: "100%",
//                             }}
//                           >
//                             <h6>
//                               {rentalPointItem?.title?.titleOriginal ||
//                                 "No name"}
//                             </h6>
//                             <p className="p-light">
//                               {rentalPointItem?.location?.pointAddress ||
//                                 "No address"}
//                             </p>
//                             {rentalPointItem?.contacts?.phoneNumbers?.length >
//                             0 ? (
//                               rentalPointItem?.contacts?.phoneNumbers
//                                 .slice(0, 1)
//                                 .map((phoneNumbersItem, index) => (
//                                   <p className="p-light" key={index}>
//                                     {phoneNumbersItem.phone}
//                                   </p>
//                                 ))
//                             ) : (
//                               <p className="p-light">No phone</p>
//                             )}

//                             {rentalPointItem?.contacts?.emailAddresses?.length >
//                             0 ? (
//                               rentalPointItem.contacts.emailAddresses
//                                 .slice(0, 1)
//                                 .map((emailAddressesItem, index) => (
//                                   <p className="p-light" key={index}>
//                                     {emailAddressesItem.email}
//                                   </p>
//                                 ))
//                             ) : (
//                               <p className="p-light">No email</p>
//                             )}
//                           </div>
//                         </div>
//                       </div>
//                     )
//                 )}
//               </div>
//             )}
//           </div>
//         </>
//       )}

//       <PopupDirrectory
//         isOpen={isOpenWindowRentalPointDetailModeration}
//         onClose={handleClose}
//         title="Rental point moderation"
//       >
//         <WindowRentalPointDetailModeration
//           mode="moderation"
//           onClose={handleClose}
//         />
//       </PopupDirrectory>
//     </>
//   );
// };

// export default WidgetRentalPointsList;
