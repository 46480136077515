import React, { useState, useEffect } from "react";
import SearchBars from "../../../../UIComponents/bars/searchBars/SearchBars";
import Icon44 from "../../../../UIComponents/icons/Icon44";
import { useDispatch } from "react-redux";
import { setPrivateSideBar } from "../../../../../slice/stateGUISlice";
import axios from "axios";
import { structureDb } from "../../../../../services/firebase/structureDb";
import io from "socket.io-client";

const Translater = () => {
  const dispatch = useDispatch();
  const [responseText, setResponseText] = useState(""); // Состояние для хранения ответа от функции
  const [topicName, setTopicName] = useState("your-topic-name");
  const [message, setMessage] = useState("Your message");
  // ------------ FIRESTORE ------------
  const addDataToFirestore = async (collectionName, newData) => {
    try {
      const response = await fetch(
        "https://us-central1-biketripste.cloudfunctions.net/addDataToFirestore",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ collectionName, newData }),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      console.log("Data added with ID:", result.id);
    } catch (error) {
      console.error("Ошибка при добавлении данных в Firestore:", error);
    }
  };

  // ------------ PUB/SUB send message ------------
  const publishMessage = async (message) => {
    try {
      const response = await fetch(
        "https://us-central1-biketripste.cloudfunctions.net/publishMessage",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ message }),
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const responseMessage = await response.text();
      console.log(responseMessage);
    } catch (error) {
      console.error("Ошибка при публикации сообщения:", error);
    }
  };
  // ------------ PUB/SUB read message ------------

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          backgroundColor: "var(--color-gray-100)",
          width: "100%",
        }}
      >
        <SearchBars
          title="Translater"
          iconLeft={
            <Icon44
              click={() => dispatch(setPrivateSideBar(true))}
              icon={
                <span className="material-symbols-outlined">dock_to_right</span>
              }
            />
          }
          iconRight={<></>}
        />
      </div>

      <div
        className="scrollBox"
        style={{
          height: "calc(100vh - 120px)",
        }}
      >
        <div className="scrollBox" style={{ height: "calc(100vh - 120px)" }}>
          <div
            onClick={() =>
              addDataToFirestore("news", { name: "John Doe", age: 30 })
            }
          >
            addDataToFirestore
          </div>
          <div onClick={() => publishMessage("Привет, мир!")}>
            publishMessage
          </div>
        </div>
      </div>
    </div>
  );
};

export default Translater;
