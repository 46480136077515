import React from "react";
import { WigetCurrencyDetail } from "./WigetCurrency/WigetCurrencyDetail";
import SearchBars from "../../../../../UIComponents/bars/searchBars/SearchBars";
import Icon44 from "../../../../../UIComponents/icons/Icon44";
import WigetButtonAdd from "../../../../../UniversalWidgets/WigetButtonAdd";
import { useDispatch } from "react-redux";
import { setPrivateSideBar } from "../../../../../../slice/stateGUISlice";

const WigetCurrencyBox = () => {
    const dispatch = useDispatch();

    return (
        <>
            {/* <div className="wigetRentalPointItsBox">
                <h3>Валюта</h3>
                <p className='p-primary'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eleifend ipsum vitae nunc gravida facilisis.</p>
            </div> */}
            <SearchBars
                title='Currencyes'

                iconLeft={
                    <Icon44
                        click={() => dispatch(setPrivateSideBar(true))}
                        icon={
                            <span className="material-symbols-outlined">
                                dock_to_right
                            </span>
                        }
                    />
                }
                iconRight={
                    <WigetButtonAdd
                        collectionName='currency'
                        // dataObject={structureCollection}
                        nameButton={`Add currency`}
                    />
                }
            />

            <div className='scrollBox'>
                <WigetCurrencyDetail
                    collectionName="currency"
                    title="Валюта" />
            </div>
        </>
    );
}

export default WigetCurrencyBox;


