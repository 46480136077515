/* global grecaptcha */
import React, { useState } from "react";
import Preloader from "./Preloader";
import { Link } from "react-router-dom";
import WigetSelectedPopup from "../Public/UniversalWidgets/WigetSelectedPopup";
import verifyReCAPTCHA from "../../functions/verifyReCAPTCHA";
import { useSelector } from "react-redux";

const ReCAPTCHAButton = ({
  grade,
  textButton,
  description,
  styleButton,
  buttonFunction,
  reCapthaActionName,
  buttonFunctionState,
}) => {
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [response, setResponse] = useState(null);
  // const [isLoading, setIsLoading] = useState(false);
  const [activePopup, setActivePopup] = useState(null);
  const [isLoadingReCAPTCHA, setIsLoadingReCAPTCHA] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState(null);

  const currentUser = useSelector((state) => state.userSliceV2.currentUser);
  const role = currentUser ? currentUser.role : null;
  const isLoading = useSelector((state) => state.userSliceV2.isLoading);

  // const siteKey = "6Led-kwpAAAAAMQ6ht4k6l3_5d3cpw98d7KV00j4";

  // Если оценка ReCAPTCHA выше или равна grade выполянем
  // useEffect(() => {
  //   if (response <= grade) {
  //     buttonFunction();
  //   } else {
  //   }
  // }, [response]);

  // Обработчик открытия попапа.
  const handleOpenPopup = (popupName) => {
    setActivePopup(popupName);
  };

  // Обработчик закрытия попапа.
  const handleClosePopup = () => {
    setActivePopup(null);
  };

  const callReCAPTCHAFunction = async () => {
    // Проверяем, зарегистрирован ли пользователь
    if (currentUser?.uid === undefined) {
      setIsLoadingReCAPTCHA(true); // Начинаем загрузку
      try {
        const result = await verifyReCAPTCHA(reCapthaActionName, grade);
        setIsVerified(result);
        if (result) {
          buttonFunction();
          setIsModalOpen(true);
        } else {
          console.log("reCAPTCHA verification failed");
          setIsModalOpen(false);
          handleOpenPopup("warning");
          setError("reCAPTCHA verification failed");
        }
      } catch (error) {
        console.error("Error during reCAPTCHA verification:", error);
      } finally {
        setIsLoadingReCAPTCHA(false); // Завершаем загрузку, независимо от результата
      }
    } else {
      setIsModalOpen(true);
      buttonFunction();
      setIsLoadingReCAPTCHA(false);
    }
  };

  return (
    <>
      {isLoadingReCAPTCHA ? (
        <button
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            height: "56px",
            borderRadius: "80px",
            backgroundColor: "black",
            padding: "0 8px 0 24px",
            border: "0",
            gap: "16px",
            fontSize: "1.2rem",
            fontWeight: "500",
            color: "var(--color-black)",
            ...styleButton,
          }}
        >
          Robot detector
          <Preloader design="white" />
        </button>
      ) : (
        <button
          onClick={!buttonFunctionState ? callReCAPTCHAFunction : undefined}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            height: "56px",
            cursor: "pointer",
            borderRadius: "80px",
            backgroundColor: "",
            padding: buttonFunctionState ? "0 8px 0 24px" : "0 24px",
            border: "0",
            gap: "16px",
            fontSize: "1.2rem",
            fontWeight: "500",
            color: "var(--color-black)",
            ...styleButton,
          }}
        >
          {/* {!buttonFunctionState && textButton} */}
          {buttonFunctionState ? "Please wait for completion" : textButton}
          {buttonFunctionState && <Preloader design="white" />}
        </button>
      )}

      {response <= grade && (
        <WigetSelectedPopup
          isOpen={activePopup === "warning"}
          img={
            "https://firebasestorage.googleapis.com/v0/b/testbikestripster.appspot.com/o/imgStatic%2Ferr7.jpg?alt=media&token=e2270e9b-8676-4592-aeb8-5e4182f78f66&_gl=1*11g1cum*_ga*NzE3MzY1ODU1LjE2ODQ3NTEzMTQ.*_ga_CW55HF8NVT*MTY5NzQzODQ5OC40LjEuMTY5NzQzOTg1MS40NC4wLjA"
          }
          // flag={countryFlag}
          //   map={map}
          onClose={() => {
            handleClosePopup();
            setActivePopup(null);
          }}
        >
          <div
            style={{
              gridColumn: "span 2",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "24px",
              padding: "40px",
              height: "100%",
              width: "100%",
              boxSizing: "border-box",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "24px",
                width: "70%",
              }}
            >
              <div
                style={{
                  backgroundImage:
                    'url("https://firebasestorage.googleapis.com/v0/b/biketripste.appspot.com/o/static%2FlogoBikeTripster%2Fsceptical.svg?alt=media&token=bd4bc713-4e4d-4894-bf3e-e11f3b386d5c")',
                  backgroundSize: "auto 70%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  width: "100%",
                  height: "300px",
                }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "8px",
                }}
              >
                <h3
                  style={{
                    width: "70%",
                    textAlign: "center",
                  }}
                >
                  Oops! Let's Register
                </h3>
                <p
                  className="p-mini"
                  style={{
                    textAlign: "center",
                    lineHeight: "1.3",
                  }}
                >
                  For your safety, we've implemented Google's verification
                  service and received a notification of potential robotic
                  activity. To continue using our services, please register or
                  log in to your account. Thank you for your understanding!
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "16px",
                }}
              >
                <Link to="/login?page=signIn">
                  <button
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "16px",
                      width: "auto",
                      padding: "8px 24px",
                      borderRadius: "80px",
                      border: "none",
                      fontSize: "1em",
                      fontWeight: "500",
                      height: "56px",
                      backgroundColor: "var(--color-black)",
                      color: "var(--color-white)",
                      cursor: "pointer",
                    }}
                  >
                    Sign In
                  </button>
                </Link>

                <Link to="/login?page=signUp">
                  <button
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "16px",
                      width: "auto",
                      padding: "8px 24px",
                      borderRadius: "80px",
                      border: "none",
                      fontSize: "1em",
                      fontWeight: "500",
                      height: "56px",
                      backgroundColor: "var(--color-primary)",
                      color: "var(--color-white)",
                      cursor: "pointer",
                    }}
                  >
                    Sign Up Now
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </WigetSelectedPopup>
      )}
    </>
  );
};

export default ReCAPTCHAButton;
