import React, { useState } from "react";
import GoogleAuthV2 from "./FirebaseAuth/GoogleAuthV2";
import { Link } from "react-router-dom";
import { EmailSignUpV2 } from "./FirebaseAuth/EmailSignUpV2";

const SignUp = () => {
  const [isEmailSignUpVisible, setIsEmailSignUpVisible] = useState(false);

  const handleEmailSignUpClick = () => {
    setIsEmailSignUpVisible("emailSignUp");
  };

  return (
    <>
      <div
        style={{
          gridColumn: "1",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: "24px",
          flex: "1",
          padding: "48px 24px",
        }}
      >
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "24px",
              height: "100%",
            }}
          >
            <h1
              style={{
                fontWeight: "300",
                fontSize: "2em",
                textAlign: "center",
              }}
            >
              <b>Sign up to</b>
              <br />
              Bike For You Trip
            </h1>

            {isEmailSignUpVisible === "emailSignUp" ? (
              <EmailSignUpV2 />
            ) : (
              <>
                <GoogleAuthV2 />

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "16px",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      borderBottom: "1px solid var(--color-gray-200)",
                      height: "1px",
                      margin: "0",
                    }}
                  ></div>
                  <p className="p-light">or</p>
                  <div
                    style={{
                      flex: "1",
                      borderBottom: "1px solid var(--color-gray-200)",
                      height: "1px",
                      margin: "0",
                    }}
                  ></div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "56px",
                    borderRadius: "80px",
                    border: "1px solid var(--color-gray-200)",
                    cursor: "pointer",
                  }}
                  onClick={handleEmailSignUpClick}
                >
                  Continue with email
                </div>
              </>
            )}
          </div>
          {isEmailSignUpVisible === false && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "24px",
              }}
            >
              <p
                style={{
                  textAlign: "center",
                }}
                className="p-mini"
              >
                By creating an account you agree with our &nbsp;
                <Link to="/terms-of-service">Terms of Service</Link>
                ,&nbsp;
                <Link to="/privacy-policy">Privacy Policy</Link>
                ,&nbsp; and our default Notification Settings.
              </p>

              <p
                style={{
                  textAlign: "center",
                }}
                className="p-mini"
              >
                Already have an account? &nbsp;
                <Link to="/login?page=signIn">Sign In</Link>
              </p>
            </div>
          )}
        </>
      </div>
    </>
  );
};

export default SignUp;
