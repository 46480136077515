import React, { useState, useEffect, useRef } from "react";
import "./counter.css";

const Counter = ({
  maxValue = 0,
  minValue = 0,
  value = 0,
  onChange,
  label,
  description,
  children,
}) => {
  const [count, setCount] = useState(value);
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    setCount(value);
  }, [value]);

  const handleIncrement = () => {
    setCount((prevCount) => {
      const newCount =
        maxValue !== undefined
          ? Math.min(prevCount + 1, maxValue)
          : prevCount + 1;
      onChange(newCount);
      return newCount;
    });
  };

  const handleDecrement = () => {
    setCount((prevCount) => {
      const newCount = Math.max(prevCount - 1, minValue);
      onChange(newCount);
      return newCount;
    });
  };

  const handleChange = (event) => {
    const newValue = parseInt(event.target.value, 10);
    if (!isNaN(newValue)) {
      const newCount =
        newValue >= minValue && (maxValue === undefined || newValue <= maxValue)
          ? newValue
          : newValue < minValue
          ? minValue
          : maxValue;
      setCount(newCount);
      onChange(newCount);
    } else {
      setCount(minValue);
      onChange(minValue);
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <div className="counter__content-box">
      {children}
      <div className={`counter__controls-box ${children && "children "}`}>
        <button
          className={`counter__quantity-button ${
            count === minValue ? "disable" : "active"
          }`}
          onClick={handleDecrement}
          disabled={count <= minValue}
        >
          <span className="material-symbols-outlined">remove</span>
        </button>

        <div className="input-text__content-box">
          <label
            style={{ textTransform: "capitalize" }}
            htmlFor={label}
            className={`input-text__label ${
              isFocused || label ? "input-text__label--up" : ""
            }`}
          >
            {label}
          </label>

          <input
            className="input-text--write"
            type="number"
            id={label}
            name={label}
            placeholder={label}
            value={count}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            ref={inputRef}
            required={true}
            min={minValue}
          />
        </div>

        <button
          className={`counter__quantity-button ${
            count >= maxValue ? "disable" : "active"
          }`}
          onClick={handleIncrement}
          disabled={maxValue !== undefined && count >= maxValue}
        >
          <span className="material-symbols-outlined">add</span>
        </button>
      </div>

      {description && (
        <div style={{ width: "100%" }}>
          <p style={{ width: "70%" }} className="p-light">
            {description}
          </p>
        </div>
      )}
    </div>
  );
};

export default Counter;
