import { useSelector } from "react-redux";
import ImageWithLoader from "../../UIComponents/bars/gallery/ImageWithLoader";
import React, { useCallback, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const WidgetFooter = () => {
  const menuItems = useSelector((state) => state.stateFrontGUISlice.menuItems);

  const location = useLocation();
  const navigate = useNavigate();

  // Функция для проверки правильности пути.
  const isValidSlug = useCallback(
    (slug) => {
      // Проверяем, что слаг соответствует одной из разрешенных записей или нужной маске
      return (
        menuItems.some((menuItem) => menuItem.slug === slug) ||
        /^(\/newsDatail\/[A-Za-z0-9-]+\/[A-Za-z0-9]+)$/ ||
        /^(\/rentalPoint\/[A-Za-z0-9-]+\/[A-Za-z0-9]+)$/ ||
        slug
      );
    },
    [menuItems]
  );

  // Навигация в случае неверного пути, выполняется в хуке useEffect
  useEffect(() => {
    if (!isValidSlug(location.pathname)) {
      navigate("/");
    }
  }, [location.pathname, isValidSlug, navigate]);
  return (
    <div
      style={{
        width: "100%",
        height: "250px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: "var(--widthContent)",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <img
            style={{
              width: "330px",
            }}
            src="https://firebasestorage.googleapis.com/v0/b/biketripste.appspot.com/o/static%2FlogoBikeTripster%2Fbooking4.svg?alt=media&token=465a0db4-0927-435e-ac8e-c7a6198677b4"
            alt=""
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <div
            style={{
              width: "auto",
            }}
          >
            <ul
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "24px",
              }}
            >
              {menuItems.map((menuItem, index) =>
                menuItem.allowedRoles ? (
                  <React.Fragment key={index}>
                    <Link key={menuItem.slug} to={menuItem.slug}>
                      <li
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "8px 24px",
                          fontSize: "0.8rem",
                          ...(menuItem.slug === location.pathname
                            ? {
                                backgroundColor: "var(--color-gray-900)",
                                borderRadius: "4px",
                                padding: "4px 8px",
                                color: "var(--color-white)",
                              }
                            : {
                                color: "var(--color-gray-900)",
                              }),
                        }}
                      >
                        <p>{menuItem.title}</p>
                      </li>
                    </Link>
                  </React.Fragment>
                ) : null
              )}
            </ul>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "var(--widthContent)",
        }}
      >
        <p
          className="p-light"
          style={{
            padding: "16px 0",
          }}
        >
          SIA MDA Capital Account: LV02PARX0032201580001 <br />
          AS "Citadele banka" Republikas laukums 2A Riga, <br />
          LV-1010, Latvia SWIFT code: PARXLV22
        </p>
      </div>
    </div>
  );
};
export default WidgetFooter;
