import React, { memo } from "react";
import { useEffect, useState } from "react";
import Preloader from "../../../../../UIElements/Preloader";

const PhotosGallery = ({ photos, onClick, isLoading, info, style }) => {
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const [isLoadingFiles, setIsLoadingFiles] = useState(true); // Состояние для отслеживания загрузки

  // Найти фото с флагом priority
  const priorityPhotoIndex = photos?.findIndex((photo) => photo?.priority || 0);

  useEffect(() => {
    if (priorityPhotoIndex !== -1) {
      setCurrentPhotoIndex(priorityPhotoIndex);
    }
  }, [priorityPhotoIndex]);

  const handlePhotoChange = (index) => {
    setIsLoadingFiles(true);
    setCurrentPhotoIndex(index);
  };

  const handleImageLoad = () => {
    setIsLoadingFiles(false);
  };

  return (
    <div
      style={{
        width: "100%",
        aspectRatio: "16/9",
        backgroundColor: "var(--color-gray-100)",
        boxSizing: "border-box",
        borderRadius: "16px",
        position: "relative",
        backgroundImage: `url(${photos ? photos[currentPhotoIndex]?.url : ""})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        ...style,
      }}
    >
      <div
        style={{
          width: "100%",
          height: "84%",
        }}
        onClick={onClick}
      />
      {isLoading ||
        (isLoadingFiles && (
          <div
            onClick={onClick}
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 1,
              // backgroundColor: "rgba(255, 255, 255, 0.5)",
            }}
          >
            <Preloader
              colorSecond="--color-white"
              colorPrimary="--color-primary"
            />
          </div>
        ))}
      <img
        src={photos ? photos[currentPhotoIndex]?.url : ""}
        alt={`Photo ${currentPhotoIndex}`}
        onLoad={handleImageLoad}
        style={{ display: "none" }}
      />

      <div
        style={{
          position: "absolute",
          bottom: "8px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          gap: "8px",
          width: "100%",
          padding: "0 16%",
          boxSizing: "border-box",
          // backgroundColor: "violet",
          height: "100%",
        }}
      >
        {photos?.map((photo, index) => (
          <div
            key={index}
            onClick={() => handlePhotoChange(index)}
            style={{
              width: `calc(100% / ${photos.length})`,
              height: "calc(100% - 8px)",
              cursor: "pointer",
              display: "flex",
              alignItems: "flex-end",
              // backgroundColor: "yellowgreen",
              boxSizing: "border-box",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "6px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                key={index}
                style={{
                  width: "100%",
                  backgroundColor: "var(--color-white)",
                  height: index === currentPhotoIndex ? "6px" : "4px",
                  borderRadius: "80px",
                  opacity: index === currentPhotoIndex ? "1" : "0.5",
                }}
              />
            </div>
          </div>
        ))}
      </div>
      {info}
    </div>
  );
};

export default memo(PhotosGallery);

// import React, { memo } from "react";
// import { useEffect, useState } from "react";
// import Preloader from "../../../../../UIElements/Preloader";

// const PhotosGallery = ({ photos, onClick, isLoading, info, style }) => {
//   const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
//   const [isLoadingFiles, setIsLoadingFiles] = useState(true); // Состояние для отслеживания загрузки

//   // Найти фото с флагом priority
//   const priorityPhotoIndex = photos?.findIndex((photo) => photo?.priority || 0);

//   useEffect(() => {
//     if (priorityPhotoIndex !== -1) {
//       setCurrentPhotoIndex(priorityPhotoIndex);
//     }
//   }, [priorityPhotoIndex]);

//   const handlePhotoChange = (index) => {
//     setIsLoadingFiles(true);
//     setCurrentPhotoIndex(index);
//   };

//   const handleImageLoad = () => {
//     setIsLoadingFiles(false);
//   };

//   return (
//     <div
//       style={{
//         width: "100%",
//         aspectRatio: "16/9",
//         backgroundColor: "var(--color-gray-100)",
//         boxSizing: "border-box",
//         borderRadius: "16px",
//         position: "relative",
//         backgroundImage: `url(${photos ? photos[currentPhotoIndex]?.url : ""})`,
//         backgroundSize: "cover",
//         backgroundPosition: "center",
//         backgroundRepeat: "no-repeat",
//         ...style,
//       }}
//     >
//       <div
//         style={{
//           width: "100%",
//           height: "84%",
//         }}
//         onClick={onClick}
//       />
//       {isLoading ||
//         (isLoadingFiles && (
//           <div
//             onClick={onClick}
//             style={{
//               position: "absolute",
//               left: 0,
//               right: 0,
//               top: 0,
//               bottom: 0,
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "center",
//               zIndex: 1,
//               // backgroundColor: "rgba(255, 255, 255, 0.5)",
//             }}
//           >
//             <Preloader
//               colorSecond="--color-white"
//               colorPrimary="--color-primary"
//             />
//           </div>
//         ))}
//       <img
//         src={photos ? photos[currentPhotoIndex]?.url : ""}
//         alt={`Photo ${currentPhotoIndex}`}
//         onLoad={handleImageLoad}
//         style={{ display: "none" }}
//       />

//       <div
//         style={{
//           position: "absolute",
//           bottom: "8px",
//           display: "flex",
//           flexDirection: "row",
//           alignItems: "center",
//           justifyContent: "center",
//           gap: "8px",
//           width: "100%",
//           padding: "0 16%",
//           boxSizing: "border-box",
//           // backgroundColor: "violet",
//           height: "100%",
//         }}
//       >
//         {photos?.map((photo, index) => (
//           <div
//             onClick={() => handlePhotoChange(index)}
//             style={{
//               width: `calc(100% / ${photos.length})`,
//               height: "calc(100% - 8px)",
//               cursor: "pointer",
//               display: "flex",
//               alignItems: "flex-end",
//               // backgroundColor: "yellowgreen",
//               boxSizing: "border-box",
//             }}
//           >
//             <div
//               style={{
//                 width: "100%",
//                 height: "6px",
//                 display: "flex",
//                 flexDirection: "column",
//                 alignItems: "center",
//                 justifyContent: "center",
//               }}
//             >
//               <div
//                 key={index}
//                 style={{
//                   width: "100%",
//                   backgroundColor: "var(--color-white)",
//                   height: index === currentPhotoIndex ? "6px" : "4px",
//                   borderRadius: "80px",
//                   opacity: index === currentPhotoIndex ? "1" : "0.5",
//                 }}
//               />
//             </div>
//           </div>
//         ))}
//       </div>
//       {info}
//     </div>
//   );
// };

// export default memo(PhotosGallery);
