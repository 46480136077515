import { createSlice } from "@reduxjs/toolkit";

const mainMenu = [
  {
    title: "Home",
    slug: "/",
    icon: "fa-regular fa-circle-user",
    allowedRoles: ["rental", "client", "admin"],
  },
  {
    title: "Catalog",
    slug: "/catalog",
    icon: "fa-regular fa-circle-user",
    allowedRoles: ["rental", "client", "admin"],
  },
  {
    title: "About",
    slug: "/about",
    icon: "fa-regular fa-circle-user",
    allowedRoles: ["rental", "client", "admin"],
  },
  {
    title: "Inform",
    slug: "/inform",
    icon: "fa-regular fa-circle-user",
    allowedRoles: ["rental", "client", "admin"],
  },
  {
    title: "Support",
    slug: "/support",
    icon: "fa-regular fa-circle-user",
    allowedRoles: ["rental", "client", "admin"],
  },
  {
    title: "Offer",
    slug: "/offer",
    icon: "fa-regular fa-circle-user",
    allowedRoles: ["rental", "client", "admin"],
  },
  //   {
  //     title: "Client",
  //     slug: "/client",
  //     icon: "location_on",
  //     allowedRoles: ["rental", "client", "admin"],
  //   },
];

const navigateSliceV2 = createSlice({
  name: "reducersToolkit/navigateSliceV2",
  initialState: {
    isLoading: false,
    error: null,
    mainMenu: mainMenu,
  },
  reducers: {},
});

export const {
  // updateMenuItem, addMenuItem, removeMenuItem
} = navigateSliceV2.actions;

export default navigateSliceV2.reducer;
