import { createAsyncThunk } from "@reduxjs/toolkit";
import { updateDocumentFields } from "../../../services/firebase/crudFirestore";
import interpretAuthErrorV2 from "../../../functions/interpretAuthErrorV2";

export const confirmOrderStatusThunk = createAsyncThunk(
  "orders/confirmOrderStatus",
  async ({ orderId, updateData }, { rejectWithValue }) => {
    try {
      const updatedOrder = await updateDocumentFields(
        "orders",
        orderId,
        updateData
      );

      return updatedOrder;
    } catch (error) {
      // Обрабатываем возможные ошибки
      const errorMessage = interpretAuthErrorV2(error.code);
      return rejectWithValue({ errorMessage });
    }
  }
);
