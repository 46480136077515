// Импорт необходимых модулей и компонентов
import React, { useState, useEffect } from "react";
import "firebase/compat/firestore";
import Button from "../../../../../UIElements/Button";
import Popup from "../../../../../UIElements/Popup";
import {
  fetchDocumentById,
  updateDocumentById,
} from "../../../../../../services/firebase/crudFirebaseStorage";
import { TextareaWrite } from "../../../../../UIElements/Textarea";
import Checkbox from "../../../../../UIElements/Checkbox";
import InputV2 from "../../../../../UIElements/InputV2";

// Компонент WigetCharacteristics
export const WigetCharacteristics = ({
  collectionName,
  idDocument,
  fieldName,
  title,
  description,
  quantity,
}) => {
  // Состояния компонента
  const [activePopup, setActivePopup] = useState(null);
  const [activePopupInform, setActivePopupInform] = useState(null);
  const [existingData, setExistingData] = useState(null);
  const [pending, setPending] = useState(false);
  const [loading, setLoading] = useState(false);
  const [changed, setChanged] = useState(false);
  const [markedForDelete, setMarkedForDelete] = useState({});
  const [deleteLoading, setDeleteLoading] = useState(false);

  const maxLength = 1000; // Максимальная длина значения

  // Инициализация firebase.firestore.Timestamp
  // const { Timestamp } = firebase.firestore;
  // const editDate = Timestamp.now();

  const [formData, setFormData] = useState({
    [fieldName]: [
      {
        title: "",
        description: "",
        characteristics: "",
      },
    ],
  });

  // Загрузка данных при монтировании компонента
  useEffect(() => {
    setLoading(true);
    const fetchUserData = async () => {
      const data = await fetchDocumentById(collectionName, idDocument);

      if (data && data[fieldName]) {
        setExistingData(data);
        const prices = Array.isArray(data[fieldName])
          ? data[fieldName]
          : [data[fieldName]];
        setFormData((prevFormData) => ({
          ...prevFormData,
          [fieldName]: [...prices],
        }));
        setPending(false);
      }
      setLoading(false);
    };

    fetchUserData();
  }, [collectionName, idDocument, setLoading, fieldName]);

  // Обработчик отметки на удаление
  const handleMarkForDelete = (index, isChecked) => {
    setMarkedForDelete((prevMarkedForDelete) => ({
      ...prevMarkedForDelete,
      [index]: isChecked,
    }));
  };

  // Проверка, есть ли отмеченные записи для удаления
  const isAnyMarkedForDelete = () => {
    return Object.values(markedForDelete).some((value) => value);
  };

  // Обработчик удаления отмеченных записей
  const handleDeleteMarked = async () => {
    setLoading(true);
    setFormData((prevFormData) => {
      const updatedData = prevFormData[fieldName].filter(
        (_, index) => !markedForDelete[index]
      );
      return { ...prevFormData, [fieldName]: updatedData };
    });
    setMarkedForDelete({});

    setDeleteLoading(true);
    await updateDocumentById(
      {
        [fieldName]: formData[fieldName].filter(
          (_, index) => !markedForDelete[index]
        ),
      },
      collectionName,
      idDocument
    );
    setDeleteLoading(false);
    setChanged(false);
    setLoading(false);
    console.log("Удаленные данные успешно обновлены");
  };

  // Обработчик изменения значений в полях ввода
  const handleChange = (e, index, field) => {
    const { value } = e.target;
    setFormData((prevFormData) => {
      const updatedData = [...prevFormData[fieldName]];
      if (index >= 0 && index < updatedData.length) {
        updatedData[index][field] = value;
      }
      return { ...prevFormData, [fieldName]: updatedData };
    });
    setChanged(true);
  };

  // Обработчик добавления данных
  const handleAddItemData = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: [
        {
          title: "",
          description: "",
          characteristics: "",
        },
        ...prevFormData[fieldName],
      ],
    }));
    setPending(true);
  };

  // Обработчик отправки формы
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setLoading(true);
    const existingFieldData =
      existingData && existingData[fieldName] ? existingData[fieldName] : [];

    const newFieldData = formData[fieldName].filter(
      (item) => !existingFieldData.includes(item)
    );

    const dataToStore = {
      [fieldName]: [...existingFieldData, ...newFieldData],
    };

    await updateDocumentById(dataToStore, collectionName, idDocument);
    console.log("Данные успешно обновлены");
    setLoading(false);
    setPending(false);
    setLoading(false);
    setChanged(false);
  };

  // Обработчик открытия всплывающего окна
  const handleOpenPopup = (popupName) => {
    setActivePopup(popupName);
  };

  // Обработчик закрытия всплывающего окна
  const handleClosePopup = () => {
    setActivePopup(null);
  };

  const handleOpenPopupInform = () => {
    setActivePopupInform(true);
  };

  const handleClosePopupInform = () => {
    setActivePopupInform(false);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "0 16px",
          boxSizing: "border-box",
        }}
      >
        <div
          style={{
            backgroundColor: "var(--color-white)",
            boxSizing: "border-box",
            borderRadius: "8px",
            overflow: "hidden",
          }}
          onClick={() => handleOpenPopup(`popu_${title}`)}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              height: "48px",
              padding: "0 16px",
              ...(formData[fieldName].length > 0 && {
                borderBottom: "1px var(--color-gray-200) solid",
              }),
            }}
          >
            <p
              style={{
                textTransform: "uppercase",
              }}
              className="p-light"
            >
              {title}
            </p>

            <button onClick={() => handleOpenPopup(`popu`)}>
              {formData[fieldName].length > 0 ? "Edit" : "Add"}
            </button>
          </div>
          {(formData[fieldName] && formData[fieldName].length > 0
            ? formData[fieldName]
            : []
          ).map((address, index) => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                marginLeft: "16px",
                boxSizing: "border-box",
                height: "48px",
                ...(index === formData[fieldName].length - 1
                  ? {
                      borderBottom: "none",
                    }
                  : {
                      borderBottom: "1px var(--color-gray-200) solid",
                    }),
              }}
              key={index}
              onClick={() => handleOpenPopup(`popu_${fieldName}`)}
            >
              <div className="inform-box-content-left">
                <p className="p-primary">{address.title}</p>
              </div>

              <div className="inform-box-content-right">
                <p
                  style={{
                    textAlign: "right",
                    padding: "0 16px",
                  }}
                  className="p-mini"
                >
                  {address.characteristics}
                </p>
              </div>
            </div>
          ))}
        </div>

        {description && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "8px 16px",
            }}
          >
            <p className="p-light">{description}</p>
          </div>
        )}
      </div>

      {activePopup !== null && (
        <Popup
          isOpen={true}
          onClose={handleClosePopup}
          title={title}
          description={description}
          leftMenu={
            <>
              {!pending &&
              quantity >= 1 &&
              formData[fieldName].length < quantity ? (
                <Button type="success" onClick={handleAddItemData}>
                  Add
                </Button>
              ) : (
                <Button type="disable">Add</Button>
              )}

              {changed === true &&
              formData[fieldName].every((value) => value !== "") ? (
                <Button
                  onClick={handleSubmit}
                  disabled={loading}
                  children={loading ? "...Loading" : "Save"}
                />
              ) : (
                <Button type={"disable"} children={"Save"} />
              )}
            </>
          }
          rightMenu={
            <>
              {isAnyMarkedForDelete() ? (
                <Button
                  type="alarm"
                  onClick={handleDeleteMarked}
                  disabled={deleteLoading}
                >
                  {deleteLoading ? "...Loading" : "Delete"}
                </Button>
              ) : (
                <Button type="disable">Delete</Button>
              )}
            </>
          }
        >
          <form onSubmit={handleSubmit}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "48px",
              }}
            >
              {Array.isArray(formData[fieldName]) &&
                formData[fieldName].map((address, index) => (
                  <div key={index} className="inform-box-inputItem">
                    {quantity > 1 && (
                      <Checkbox
                        checked={!!markedForDelete[index]}
                        onChange={(e) =>
                          handleMarkForDelete(index, e.target.checked)
                        }
                      />
                    )}
                    <div className="inform-box-item">
                      <InputV2
                        label={`Service name`}
                        type="text"
                        value={address.title}
                        onChange={(e) => handleChange(e, index, "title")}
                        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                      />

                      <TextareaWrite
                        label={`Description`}
                        value={address.description}
                        onChange={(e) => handleChange(e, index, "description")}
                        maxLength={maxLength}
                        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                      />

                      <InputV2
                        label={`Characteristics`}
                        type="text"
                        value={address.characteristics}
                        onChange={(e) =>
                          handleChange(e, index, "characteristics")
                        }
                        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                      />
                    </div>
                  </div>
                ))}

              <div className="send-view">
                <div></div>
                <div className="send-view-right">
                  {changed === true &&
                  formData[fieldName].every((value) => value !== "") ? (
                    <Button
                      onClick={handleSubmit}
                      disabled={loading}
                      children={loading ? "...Loading" : "Save"}
                    />
                  ) : (
                    <Button type={"disable"} children={"Save"} />
                  )}
                </div>
              </div>
            </div>
          </form>
        </Popup>
      )}
      {activePopupInform !== null && (
        <Popup
          isOpen={activePopupInform}
          onClose={handleClosePopupInform}
          title={title}
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
        >
          {description}
        </Popup>
      )}
    </>
  );
};
