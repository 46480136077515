import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import UIRentalPoint from "../../components/Private/Login/Profile/RentalPoints/UIRentalPoint";
import UIBikes from "../../components/Private/Login/Profile/Bikes/UIBikes";
import UIOrders from "../../components/Private/Login/Profile/Orders/UIOrders";
import UIHome from "../../components/Public/Home/UIHome";
import UIBrand from "../../components/Private/Login/Profile/Directory/Brand/UIBrand";
import UICurrency from "../../components/Private/Login/Profile/Directory/Currency/UICurrency";
import UIСategoriesBike from "../../components/Private/Login/Profile/Directory/СategoriesBike/UIСategoriesBike";
import UIFAQ from "../../components/Private/Login/Profile/FAQ/UIFAQ";
import UINews from "../../components/Private/Login/Profile/News/UINews";
import UIStories from "../../components/Private/Login/Profile/Stories/UIStories";
import UILogin from "../../components/Private/Login/UILogin";
// import UICatalog from "../../components/Public/Catalog/UICatalog";
import UIProductCard from "../../components/Public/ProductCard/UIProductCard";
import UICountry from "../../components/Private/Login/Profile/Country/UICountry";
import UICity from "../../components/Private/Login/Profile/City/UICity";
import UiAbout from "../../components/Public/About/UiAbout";
import UiInform from "../../components/Public/Inform/UiInform";
import UiSupport from "../../components/Public/Support/UiSupport";
import UiNewsPublic from "../../components/Public/News/UiNews";
import UiNewsDetail from "../../components/Public/News/NewsDetail/UiNewsDetail";
import UiRentalPoint from "../../components/Public/RentalPoint/UiRentalPoint";
import UiOffer from "../../components/Public/Offer/UiOffer";
import Client from "../../components/Private/Login/Profile/Client/Client";
import { useSelector } from "react-redux";
import UIProfileV3 from "../../components/Private/Login/Profile/UIProfileV3";
import PageCatalogV2 from "../../components/Public/CatalogV2/PageCatalogV2";
import PageProductCardV2 from "../../components/Public/ProductCardV2/PageProductCardV2";
import PageOffer from "../../components/Public/OfferV2/PageOffer";
import PageInform from "../../components/Public/InformV2/PageInform";
import PageAbout from "../../components/Public/AboutV2/PageAbout";
import PageSupport from "../../components/Public/SupportV2/PageSupport";

function ProtectedRoutes() {
  const [role, setRole] = useState(null);
  const [uId, setUid] = useState(null);

  const { currentUser, isLoading } = useSelector((state) => state.userSliceV2);

  useEffect(() => {
    setRole(currentUser?.role?.currentRole || null);
    setUid(currentUser.id || null);
  }, [currentUser]);

  return (
    <Routes>
      {/* <Route path="/" element={<UIHome />} /> */}
      <Route path="/:language/" element={<PageCatalogV2 />} />

      <Route path="/:language/offer" element={<PageOffer />} />
      <Route path="/client" element={<Client />} />
      <Route path="/login" element={<UILogin />} />
      <Route path="/:language/catalog" element={<PageCatalogV2 />} />

      <Route
        path="/:language/catalog/:typeBike/:id"
        element={<PageCatalogV2 />}
      />

      <Route path="/productCard/:bikeTitle/:id" element={<UIProductCard />} />

      <Route
        path="/catalog/productCard/:bikeTitle/:id"
        element={<UIProductCard />}
      />

      <Route
        path="/:language/productCard/:bikeTitle/:id"
        element={<PageProductCardV2 />}
      />

      <Route path="/:language/about" element={<PageAbout />} />
      <Route path="/:language/inform" element={<PageInform />} />
      {/* <Route path="/:language/support" element={<UiSupport />} /> */}
      <Route path="/:language/support" element={<PageSupport />} />

      <Route path="/news" element={<UiNewsPublic />} />
      <Route
        path="/news/newsDatail/:newsTitle/:id"
        element={<UiNewsDetail />}
      />
      <Route path="/newsDatail/:newsTitle/:id" element={<UiNewsDetail />} />
      <Route
        path="/rentalPoint/:rentalPointTitle/:id"
        element={<UiRentalPoint />}
      />

      {role === "rental" && (
        <>
          <Route path="/profile" element={<UIProfileV3 />} />
          <Route path="/rental_points" element={<UIRentalPoint />} />
          <Route path="/bikes" element={<UIBikes />} />
          <Route path="/orders" element={<UIOrders />} />
        </>
      )}
      {role === "client" && (
        <>
          <Route path="/profile" element={<UIProfileV3 />} />
        </>
      )}

      {role === "admin" && (
        <>
          <Route path="/profile" element={<UIProfileV3 />} />
          <Route path="/rental_points" element={<UIRentalPoint />} />
          <Route path="/bikes" element={<UIBikes />} />
          <Route path="/orders" element={<UIOrders />} />
          <Route path="/brand" element={<UIBrand />} />
          <Route path="/currency" element={<UICurrency />} />
          <Route path="/category" element={<UIСategoriesBike />} />
          <Route path="/faq" element={<UIFAQ />} />
          <Route path="/editNews" element={<UINews />} />
          <Route path="/stories" element={<UIStories />} />
          <Route path="/country" element={<UICountry />} />
          <Route path="/city" element={<UICity />} />
        </>
      )}
    </Routes>
  );
}

export default ProtectedRoutes;
