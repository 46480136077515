import { createAsyncThunk } from "@reduxjs/toolkit";
import { updateDocumentFields } from "../../../services/firebase/crudFirestore";
import interpretAuthErrorV2 from "../../../functions/interpretAuthErrorV2";

export const activatedRentalPointThunkV2 = createAsyncThunk(
  "rentalPoints/activatedRentalPointThunkV2",
  async (rentalPointId, { rejectWithValue }) => {
    try {
      await updateDocumentFields("rentalPoints", rentalPointId, {
        rentalPointStatus: { currentStatus: "activated" },
      });
      return { rentalPointId, rentalPointStatus: "activated" };
    } catch (error) {
      const errorMessage = interpretAuthErrorV2(error.code);
      return rejectWithValue({ errorMessage });
    }
  }
);
