import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const DateWrite = ({
  label,
  type,
  name,
  value,
  onChange,
  description,
  maxLength,
  title,
}) => {
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    if (value && !isNaN(value)) {
      const timestamp = parseInt(value, 10);
      if (!isNaN(timestamp)) {
        setSelectedDate(new Date(timestamp));
      }
    }
  }, [value]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (onChange) {
      onChange({ target: { value: date.getTime().toString() } });
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <div className={`datePicker-box`}>
        {/* <label htmlFor={name} className={`input-write-label`}>
                    {label}
                </label> */}
        <DatePicker
          selected={selectedDate}
          onChange={handleDateChange}
          className="custom-datepicker"
          calendarClassName="custom-datepicker-calendar"
          inline
        />
      </div>
      <p className="input-clue">{description}</p>
    </div>
  );
};
