import { createAsyncThunk } from "@reduxjs/toolkit";

export const filterBikesThunk = createAsyncThunk(
  "filterBikesThunk/front",
  async (_, { getState }) => {
    const {
      categoryIdFilter,
      countryIdFilter,
      brandIdFilter,
      rentalPointIdFilter,
      cityIdFilter,
      currentDateInterval,
      fullDataBike,
      currentMinMaxPrice,
    } = getState().stateFrontGUISlice;
    // Определение месяцев из currentDateInterval
    const startMonth = getMonthFromTimestamp(currentDateInterval[0]);
    const endMonth = getMonthFromTimestamp(currentDateInterval[1]);

    // Функция для фильтрации списка велосипедов
    const bikesFiltredList = fullDataBike.filter((bike) => {
      const passesBrandFilter =
        !brandIdFilter.length ||
        brandIdFilter.some((brandId) => bike.brand_id.includes(brandId));

      const passesCityFilter =
        !cityIdFilter.length || cityIdFilter.includes(bike.city.id);

      const passesCountryFilter =
        !countryIdFilter.length || bike.country_id.includes(countryIdFilter);

      const passesCategoryFilter =
        !categoryIdFilter.length ||
        categoryIdFilter.includes(bike.categoryes_id);

      const passesPriceFilter =
        !currentMinMaxPrice.length ||
        bike.rate.some(
          (rate) =>
            rate.price >= currentMinMaxPrice[0] &&
            rate.price <= currentMinMaxPrice[1]
        );

      // Фильтр по месяцам
      const passesMonthFilter = bike.rate.some(
        (rate) =>
          rate.month >= startMonth && rate.month <= endMonth && rate.price > 0
      );

      // Фильтр по идентификатору точки проката
      const passesRentalPointFilter =
        rentalPointIdFilter.length === 0 || // Если фильтр пуст, пропускаем все объекты
        (bike.rentalPoint && rentalPointIdFilter.includes(bike.rentalPoint.id)); // Проверяем, что rentalPoint существует и его идентификатор входит в фильтр
      return (
        passesBrandFilter &&
        passesCityFilter &&
        passesCountryFilter &&
        passesCategoryFilter &&
        passesPriceFilter &&
        passesMonthFilter &&
        passesRentalPointFilter
      );
    });
    return bikesFiltredList;
  }
);

function getMonthFromTimestamp(timestamp) {
  const date = new Date(timestamp);
  return date.getMonth() + 1;
}
