import React from "react";
import TitleBlockSection from "../WidgetsProfile/Titles/TitleBlockSection";
import BrandDirectories from "./Directory/WidgetDirectories";
import BrandDirectories2 from "./Directory/WidgetDirectories";
import WidgetCategories from "./Categories/WidgetCategories";
import WidgetCurrencies from "./Сurrencies/WidgetCurrencies";
import WidgetCities from "./Cities/WidgetCities";
import WidgetCountries from "./Countries/WidgetCountries";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import WidgetCountriesV4 from "./CountriesV4/WidgetCountriesV4";

const WidgetDirectory = () => {
  return (
    <ContentBox>
      <TitleBlockSection
        title="Dirrectory"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
      />

      <div
        style={{
          width: "100%",
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gap: "8px",
          flex: "1",
        }}
      >
        {/* <BrandDirectories
          titleDirectory="brand"
          collectionNameDirectory="brand"
          sliceNameDirectory="privateDirectorySlice"
          fieldNameDirectory="files.iconFiles"
        />

        <BrandDirectories
          titleDirectory="category"
          collectionNameDirectory="category"
          sliceNameDirectory="privateDirectorySlice"
          fieldNameDirectory="files.iconFiles"
        />

        <WidgetCountries /> */}
        <WidgetCountriesV4 />
      </div>
    </ContentBox>
  );
};

export default WidgetDirectory;
