import React, { useEffect, useState } from "react";
import PopupDirrectory from "../../WidgetsProfile/Popups/PopupDirrectory";
import AddData from "../../WidgetsProfile/AddData/AddData";
import WindowCountryDetailV4 from "./WindowCountryDetailV4";
import { useDispatch, useSelector } from "react-redux";
import ContentBox from "../../WidgetsProfile/ContentBox/ContentBox";
import WidgetCountriesListV4 from "./WidgetCountriesListV4";
import {
  setCurrentCountry,
  setNullcatalogSliceV4,
} from "../../../../../../reducersToolkit/catalogSliceV4";
import { fetchDataThunkV4 } from "../../../../../../reducersToolkit/thunk/catalog/V4/fetchDataThunkV4";
import { fetchCountriesDataThunkV4 } from "../../../../../../reducersToolkit/thunk/catalog/V4/fetchCatalogData/fetchCountriesDataThunkV4";

const UpdateCatalogButton = () => {
  const updateCatalog = async () => {
    try {
      const response = await fetch(
        "https://us-central1-biketripste.cloudfunctions.net/onRequestCreateLocations"
      );
      const result = await response.text();
      console.log(result);
    } catch (error) {
      console.error("Error updating catalog:", error);
    }
  };

  return <button onClick={updateCatalog}>Update locations</button>;
};

const WindowCountriesListV4 = () => {
  const dispatch = useDispatch();

  const [openWindowCountryDetail, setOpenWindowCountryDetail] = useState(false);
  const [sortedCountries, setSortedCountries] = useState([]);

  const nullcatalogSliceV4 = () => {
    dispatch(setNullcatalogSliceV4());
  };

  useEffect(() => {
    // dispatch(fetchDataThunkV4({}));
    dispatch(fetchCountriesDataThunkV4({}));
  }, [dispatch]);

  const handleToggleOpenWindowCountryDetail = () => {
    dispatch(setCurrentCountry({}));
    setOpenWindowCountryDetail(true);
  };

  const handleClose = () => {
    setOpenWindowCountryDetail(false);
  };

  const {
    countries,
    updateCountryLoading,
    createCountryLoading,
    loadingCountries,
  } = useSelector((state) => state.catalogSliceV4);

  // console.log(
  //   "WindowCountriesListV4 countries",
  //   countries,
  //   updateCountryLoading,
  //   createCountryLoading
  // );

  useEffect(() => {
    if (Array.isArray(countries)) {
      const sortedData = [...countries]?.sort((a, b) => {
        return new Date(b.createdat) - new Date(a.createdat);
      });
      setSortedCountries(sortedData);
    }
  }, [countries]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      <ContentBox
        position="one"
        isLoading={
          updateCountryLoading || createCountryLoading || loadingCountries
        }
      >
        <UpdateCatalogButton />
        <button onClick={nullcatalogSliceV4}>setNullcatalogSliceV4</button>
        <AddData
          onClick={() => handleToggleOpenWindowCountryDetail()}
          icon={<span className="material-symbols-outlined">add</span>}
          title="Add country"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
        />
        <WidgetCountriesListV4 countries={sortedCountries} />
      </ContentBox>

      <PopupDirrectory
        isOpen={openWindowCountryDetail}
        onClose={handleClose}
        title={"Add country V4"}
      >
        <WindowCountryDetailV4 mode="new" onClose={handleClose} />
      </PopupDirrectory>
    </div>
  );
};

export default WindowCountriesListV4;
