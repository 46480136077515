import { createAsyncThunk } from "@reduxjs/toolkit";
import { subscribeToDocument } from "../../../services/firebase/crudFirestore";
import { updateDirectoryData } from "./privateDirectorySlice";
import interpretAuthErrorV2 from "../../../functions/interpretAuthErrorV2";

// Функция для подписки на коллекции Firestore
const subscribeToCollections = async (
  collectionName,
  levelCollections,
  dispatch
) => {
  return new Promise((resolve, reject) => {
    const unsubscribe = subscribeToDocument(
      collectionName,
      null,
      null,
      async (data) => {
        if (Array.isArray(data)) {
          dispatch(updateDirectoryData({ collectionName, data })); // Диспатчим обновление данных

          const result = await Promise.all(
            data.map(async (item) => {
              const nestedResult = {};

              for (const [key, subCollection] of Object.entries(
                levelCollections
              )) {
                if (subCollection && subCollection.collectionName) {
                  const nestedCollectionName = subCollection.collectionName;
                  const nestedCollectionIds =
                    item[`${nestedCollectionName}_id`];

                  if (Array.isArray(nestedCollectionIds)) {
                    const nestedData = await Promise.all(
                      nestedCollectionIds.map(async (id) => {
                        const { result: nestedItems } =
                          await subscribeToCollections(
                            nestedCollectionName,
                            subCollection,
                            dispatch
                          );
                        return nestedItems.find(
                          (nestedItem) => nestedItem.id === id
                        );
                      })
                    );
                    nestedResult[nestedCollectionName] =
                      nestedData.filter(Boolean);
                  }
                }
              }

              return {
                ...item,
                ...nestedResult,
              };
            })
          );

          resolve({ result });
        } else {
          console.error("Data is not an array:", data);
          reject(new Error("Data is not an array"));
        }
      }
    );
  });
};

// Асинхронный thunk для подписки на корневую коллекцию
export const recursiveSubscriptionThunk = createAsyncThunk(
  "private/directory/recursiveSubscriptionThunk",
  async (levelCollections, { getState, rejectWithValue, dispatch }) => {
    try {
      const [rootKey, rootCollection] = Object.entries(levelCollections)[0];
      const collectionName = rootCollection.name;
      console.log("unsubscribe 1", collectionName);
      const { result } = await subscribeToCollections(
        collectionName,
        rootCollection,
        dispatch
      );
      console.log("unsubscribe 2", result);
      return { result };
    } catch (error) {
      const errorMessage = interpretAuthErrorV2(error.code);
      return rejectWithValue({ errorMessage });
    }
  }
);
