import React from "react";

import "react-datepicker/dist/react-datepicker.css";

import WigetSelectLocation from "./WigetSelectLocation";
import WigetDateInterval from "./WigetDateInterval";
import WigetQuntityBikes from "./WigetQuntityBikes";

const WigetSearch = () => {
  return (
    <div
      className="wigetBikes-content-grid-left-content"
      style={{
        backgroundColor: "transparent",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gridTemplateRows: "repeat(2, 1fr)",
          gap: "8px",
          boxSizing: "border-box",
        }}
      >
        <div
          style={{
            gridColumn: "1",
            gridRow: "1",
            width: "100%",
            aspectRatio: "1/1",
          }}
        >
          <WigetSelectLocation />
        </div>
        <div
          style={{
            gridColumn: "1",
            gridRow: "2",
            width: "100%",
            aspectRatio: "1/1",
          }}
        >
          <WigetQuntityBikes />
        </div>
        <div
          style={{
            gridColumn: "2",
            gridRow: "span 2",
            width: "100%",
          }}
        >
          <WigetDateInterval />
        </div>
      </div>
    </div>
  );
};

export default WigetSearch;
