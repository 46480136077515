import { useEffect, useState } from "react";
import { useSubscribeData } from "../../../../../../services/firebase/crudFirebaseStorage";

const OdrderDetailPrice = ({ orderDetail, title, description }) => {

    const additionalServicesPrice = orderDetail?.order_info?.additionalServicesPrice
    const priceOneBike = orderDetail?.order_info?.priceOneBike
    const pricePayNow = orderDetail?.order_info?.pricePayNow
    const pricePayNowDiscount = orderDetail?.order_info?.pricePayNowDiscount
    const rentalPrice = orderDetail?.order_info?.rentalPrice
    const rentalPriceDiscount = orderDetail?.order_info?.rentalPriceDiscount
    const rentalPriceSaving = orderDetail?.order_info?.rentalPriceSaving
    const totalOrderPriceCurrency = orderDetail?.order_info?.totalOrderPriceCurrency
    const totalOrderPriceDiscountCurrency = orderDetail?.order_info?.totalOrderPriceDiscountCurrency


    const atLeastOneNotEmpty = additionalServicesPrice || rentalPrice || rentalPriceDiscount || rentalPriceSaving || totalOrderPriceDiscountCurrency;
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '0 16px',
            boxSizing: 'border-box',
        }}>

            <div style={{
                backgroundColor: 'var(--color-white)',
                boxSizing: 'border-box',
                borderRadius: '8px',
            }}>

                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    minHeight: '48px',
                    padding: '0 16px',
                    ...(atLeastOneNotEmpty && {
                        borderBottom: '1px var(--color-gray-200) solid',
                    })

                }}>


                    <p style={{
                        textTransform: 'uppercase',
                    }}
                        className='p-light'
                    >
                        {title}
                    </p>
                </div>


                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    boxSizing: 'border-box'
                }}>

                    <div className="private_ordersList_itemPrice private_ordersList_itemPrice_borderBottom">
                        <p>Rental Price:</p>

                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <p style={{
                                fontWeight: '600'
                            }}>
                                {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(rentalPrice)}
                            </p>
                            &nbsp;—&nbsp;
                            <p style={{
                                fontWeight: '600'
                            }}>
                                {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(rentalPriceSaving)}
                            </p>
                        </div>

                    </div>

                    <div className="private_ordersList_itemPrice private_ordersList_itemPrice_borderBottom">
                        <p>
                            Rental Price Discount:
                        </p>
                        <p style={{
                            fontWeight: '600'
                        }}>
                            {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(rentalPriceDiscount)}
                        </p>
                    </div>

                    <div className="private_ordersList_itemPrice private_ordersList_itemPrice_borderBottom">
                        <p>
                            Additional Services Price:
                        </p>
                        <p style={{
                            fontWeight: '600'
                        }}>
                            {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(additionalServicesPrice)}
                        </p>
                    </div>

                    <div className="private_ordersList_itemPrice">
                        <p>
                            Total Order Price Discount Currency:
                        </p>
                        <p style={{
                            fontWeight: '600'
                        }}>
                            {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totalOrderPriceDiscountCurrency)}
                        </p>
                    </div>
                </div>
            </div>

            {description && (
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    padding: '8px 16px'
                }}>
                    <p className='p-light'>
                        {description}
                    </p>
                </div>
            )}

        </div>
    );
}

export default OdrderDetailPrice;
