import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchData } from "../../services/firebase/crudFirebaseStorage";

// Bike
export const fetchBikeData = createAsyncThunk(
  "bike/fetchBikeData/front",
  async () => {
    try {
      // Используем setTimeout для создания задержки в 1 секунду
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Получаем данные о велосипедах с помощью функции fetchData из Firebase
      const bikeData = await fetchData("bikes");
      console.log("bikeData ✅", bikeData);
      // Возвращаем полученные данные
      return bikeData;
    } catch (error) {
      // Если произошла ошибка при получении данных, выбрасываем ошибку с сообщением
      throw new Error(`Ошибка при получении данных bikeData: ${error.message}`);
    }
  }
);
