import { createSlice } from "@reduxjs/toolkit";
import { addBikesThunkV2 } from "./thunk/bikes/addBikesThunkV2";
import { fetchBikesThunkV2 } from "./thunk/bikes/fetchBikesThunkV2";
import { updateBikeThunkV2 } from "./thunk/bikes/updateBikeThunkV2";
// import { fetchAllBikesThunkV2 } from "./thunk/bikes/fetchAllBikesThunkV2";

const bike = {
  bikeStatus: "new",
  created: 0,

  basicInformation: {
    title: {
      titleOriginal: "",
    },
    description: {
      descriptionOriginal: "",
    },
  },

  directory: {
    categoryId: "",
    brandId: "",
  },

  characteristics: [],

  metricsBike: {
    quantityBikes: 0,
  },

  files: {
    bikePhotoFiles: [],
  },

  rate: [
    {
      month: 1,
      price: 0,
      discount: 0,
      description: {
        descriptionOriginal: "",
      },
    },
    {
      month: 2,
      price: 0,
      discount: 0,
      description: {
        descriptionOriginal: "",
      },
    },
    {
      month: 3,
      price: 0,
      discount: 0,
      description: {
        descriptionOriginal: "",
      },
    },
    {
      month: 4,
      price: 0,
      discount: 0,
      description: {
        descriptionOriginal: "",
      },
    },
    {
      month: 5,
      price: 0,
      discount: 0,
      description: {
        descriptionOriginal: "",
      },
    },
    {
      month: 6,
      price: 0,
      discount: 0,
      description: {
        descriptionOriginal: "",
      },
    },
    {
      month: 7,
      price: 0,
      discount: 0,
      description: {
        descriptionOriginal: "",
      },
    },
    {
      month: 8,
      price: 0,
      discount: 0,
      description: {
        descriptionOriginal: "",
      },
    },
    {
      month: 9,
      price: 0,
      discount: 0,
      description: {
        descriptionOriginal: "",
      },
    },
    {
      month: 10,
      price: 0,
      discount: 0,
      description: {
        descriptionOriginal: "",
      },
    },
    {
      month: 11,
      price: 0,
      discount: 0,
      description: {
        descriptionOriginal: "",
      },
    },
    {
      month: 12,
      price: 0,
      discount: 0,
      description: {
        descriptionOriginal: "",
      },
    },
  ],
};

const bikesSliceV4 = createSlice({
  name: "reducersToolkit/bikesSliceV4",
  initialState: {
    error: null,
    isLoadingBikes: false,

    bikesList: [],
    selectedBike: {},
  },
  reducers: {
    clearFormV4(state, action) {
      state.newBike = bike;
    },

    setMetricsBikeV4(state, action) {
      const { mode, counterValues } = action.payload;

      if (mode === "edit") {
        state.selectedBike.metricsBike.quantityBikes = counterValues;
      } else if (mode === "new") {
        state.newBike.metricsBike.quantityBikes = counterValues;
      }
    },

    setSelectedBikeV4(state, action) {
      state.selectedBike = action.payload;
    },

    setNewBikeBasicInformationV4(state, action) {
      const { title, description } = action.payload;
      state.newBike.basicInformation.title.titleOriginal = title;
      state.newBike.basicInformation.description.descriptionOriginal =
        description;
    },

    setSelectedBikeBasicInformationV4(state, action) {
      const { title, description } = action.payload;
      state.selectedBike.basicInformation.title.titleOriginal = title;
      state.selectedBike.basicInformation.description.descriptionOriginal =
        description;
    },

    setCategoryIdV4(state, action) {
      state.newBike.directory.categoryId = action.payload;
    },

    setNewBikeBrandIdV4(state, action) {
      state.newBike.directory.brandId = action.payload;
    },

    setSelectedBikeBrandIdV4(state, action) {
      state.selectedBike.directory.brandId = action.payload;
    },

    setNewBikeCategoryIdV4(state, action) {
      state.newBike.directory.categoryId = action.payload;
    },

    setSelectedBikeCategoryIdV4(state, action) {
      state.selectedBike.directory.categoryId = action.payload;
    },

    setRateNewBikeV4(state, action) {
      const { index, newRate } = action.payload;
      if (
        state.newBike.rate &&
        index >= 0 &&
        index < state.newBike.rate.length
      ) {
        state.newBike.rate[index] = newRate;
      }
    },
    setRateSelectedBikeV4(state, action) {
      const { index, newRate } = action.payload;
      if (
        state.selectedBike.rate &&
        index >= 0 &&
        index < state.selectedBike.rate.length
      ) {
        state.selectedBike.rate[index] = newRate;
      }
    },
    setSelectedBikeNewCharacteristicV4(state, action) {
      if (!state.selectedBike.characteristics) {
        state.selectedBike.characteristics = [];
      }
      state.selectedBike.characteristics.push(action.payload);
    },
    setSelectedBikeEditCharacteristicV4(state, action) {
      const { newCharacteristic, id } = action.payload;
      state.selectedBike.characteristics =
        state.selectedBike.characteristics.map((characteristic) =>
          characteristic.id === id ? newCharacteristic : characteristic
        );
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(addBikesThunkV2.pending, (state) => {
        state.isLoadingBikes = true;
        state.error = null;
      })
      .addCase(addBikesThunkV2.fulfilled, (state, action) => {
        state.isLoadingBikes = false;
        state.bikesList.push(action.payload.newBike);
      })
      .addCase(addBikesThunkV2.rejected, (state, action) => {
        state.isLoadingBikes = false;
        state.error = action.payload;
      })
      .addCase(fetchBikesThunkV2.pending, (state) => {
        state.isLoadingBikes = true;
        state.error = null;
      })
      .addCase(fetchBikesThunkV2.fulfilled, (state, action) => {
        state.isLoadingBikes = false;
        state.bikesList = action.payload.bikes;
      })
      .addCase(fetchBikesThunkV2.rejected, (state, action) => {
        state.isLoadingBikes = false;
        state.error = action.payload;
      })
      .addCase(updateBikeThunkV2.pending, (state) => {
        state.isLoadingBikes = true;
        state.error = null;
      })
      .addCase(updateBikeThunkV2.fulfilled, (state, action) => {
        state.isLoadingBikes = false;
        const { idBike, updatedData } = action.payload;
        const index = state.bikesList.findIndex((bike) => bike.id === idBike);
        if (index !== -1) {
          state.bikesList[index] = {
            ...state.bikesList[index],
            ...updatedData,
          };
        }
      })
      .addCase(updateBikeThunkV2.rejected, (state, action) => {
        state.isLoadingBikes = false;
        state.error = action.payload;
      });

    // .addCase(fetchAllBikesThunkV2.pending, (state) => {
    //   state.isLoadingBikes = true;
    //   state.error = null;
    // })
    // .addCase(fetchAllBikesThunkV2.fulfilled, (state, action) => {
    //   state.isLoadingBikes = false;
    //   state.bikesList = action.payload.bikes;
    // })
    // .addCase(fetchAllBikesThunkV2.rejected, (state, action) => {
    //   state.isLoadingBikes = false;
    //   state.error = action.payload;
    // });
  },
});

export const {
  clearFormV4,
  setMetricsBikeV4,
  setSelectedBikeV4,
  setNewBikeBasicInformationV4,
  setSelectedBikeBasicInformationV4,
  setCategoryIdV4,
  setNewBikeBrandIdV4,
  setSelectedBikeBrandIdV4,
  setNewBikeCategoryIdV4,
  setSelectedBikeCategoryIdV4,
  setRateNewBikeV4,
  setRateSelectedBikeV4,
  setSelectedBikeNewCharacteristicV4,
  setSelectedBikeEditCharacteristicV4,
} = bikesSliceV4.actions;
export const { actions, reducer } = bikesSliceV4;
export default bikesSliceV4.reducer;
