import React, { useState, useEffect } from "react";
import Button from "../Buttons/Button";
import "./uploadFiles.css";
import { useDispatch, useSelector } from "react-redux";
import { removeFilesThunk } from "../../../../../../slice/private/files/removeFilesThunk";
import Preloader from "../../../../../UIElements/Preloader";
import { updateFieldsFiles } from "../../../../../../services/firebase/crudFirestore";

const WindowFileDetail = ({
  currentFile,
  filesList,
  collectionName,
  documentId,
  fieldName,
  onClose,
  mode,
}) => {
  const dispatch = useDispatch();
  const [foundFile, setFoundFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const isRemove = useSelector((state) => state.filesSlice.isRemove);

  useEffect(() => {
    if (currentFile && filesList) {
      const file = filesList.find((file) => file.id === currentFile.id);
      setFoundFile(file);
    }
  }, [currentFile, filesList]);

  const handlePriority = async () => {
    if (filesList) {
      const updatedFiles = filesList.map((file) =>
        file.id === currentFile.id
          ? { ...file, priority: true }
          : { ...file, priority: false }
      );

      setIsLoading(true);
      try {
        await updateFieldsFiles(collectionName, documentId, {
          [fieldName]: updatedFiles,
        });
        console.log("Priority updated successfully");
      } catch (error) {
        console.error("Error updating priority:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleDeleteFiles = () => {
    setIsLoading(true);
    dispatch(
      removeFilesThunk({
        collectionName,
        documentId,
        fieldName,
        fileIds: [currentFile.id],
      })
    ).then(() => {
      setIsLoading(false);
      onClose();
    });
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "8px",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            left: "0",
            right: "0",
            top: "0",
            bottom: "0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor:
              (isRemove || isLoading) && "var(--color-gray-100-alpha-50)",
          }}
        >
          {(isRemove || isLoading) && <Preloader />}
        </div>
        {foundFile && (
          <img
            src={foundFile.url}
            alt="Current File"
            style={{ width: "100%", height: "auto" }}
          />
        )}

        <div
          style={{
            width: "100%",
            boxSizing: "border-box",
            padding: "24px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "16px",
            position: "absolute",
            bottom: "0",
          }}
        >
          <Button
            active={isRemove || isLoading || foundFile?.priority ? false : true}
            label={foundFile?.priority ? "Start photo" : "Set as start photo"}
            icon={
              foundFile && foundFile.priority ? (
                <span className="material-symbols-outlined">task_alt</span>
              ) : (
                <span className="material-symbols-outlined">
                  radio_button_unchecked
                </span>
              )
            }
            color="--color-primary"
            onClick={handlePriority}
          />
          {mode === "edit" && (
            <Button
              active={isRemove || isLoading ? false : true}
              label="Delete"
              allert={
                <div
                  style={{
                    padding: "32px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "16px",
                    textAlign: "center",
                  }}
                >
                  <h1>
                    Please confirm that you really want to delete this file.
                  </h1>
                  <p className="p-light">
                    This action is irreversible, and you will not be able to
                    recover it.
                  </p>
                </div>
              }
              icon={<span className="material-symbols-outlined">delete</span>}
              color="--color-alarm"
              onClick={handleDeleteFiles}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default WindowFileDetail;
