import React from "react";
import "./swither.css";

const Checkbox = ({ label, checked, onChange, priority, id, active }) => {
  const handleCheckboxChange = (event) => {
    if (onChange) {
      onChange(event, id !== undefined ? id : !checked);
    }
  };

  return (
    <div
      // style={{
      //   ...(!active&&{})
      // }}
      className={priority ? "swither__checkbox-priority" : "swither__checkbox"}
    >
      <input
        type="checkbox"
        checked={checked || false}
        onChange={handleCheckboxChange}
      />

      {label && (
        <span
          className={
            priority
              ? "swither__checkbox-priority-label"
              : "swither__checkbox-label"
          }
        >
          {label}
        </span>
      )}
    </div>
  );
};

export default Checkbox;
