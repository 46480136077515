import React, { useState, useEffect, useRef } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { useSelector, useDispatch } from "react-redux";
import {
  setRentalPointIdFilter,
  setCurrentRentalPoint,
} from "../../../slice/stateFrontGUISlice";
import Preloader from "../../UIElements/Preloader";
import objectIsEmpty from "../../../functions/objectIsEmpty";

const GoogleMapsFilters = ({ rentalPoint, rentalPoints }) => {
  const dispatch = useDispatch();
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [
    filteredCurrentUsersRentalPoints,
    setFilteredCurrentUsersRentalPoints,
  ] = useState([]);
  const mapRef = useRef(null);

  const currentRentalPointsList = useSelector(
    (state) => state.stateFrontGUISlice.currentRentalPointsList
  );
  const isLoading = useSelector((state) => state.stateFrontGUISlice.isLoading);
  const loadingStateGoogleMapApi = useSelector(
    (state) => state.config.loadingStateGoogleMapApi
  );
  // const location = useSelector(
  //   (state) => state.stateGUISlice.newAddress.location
  // );

  useEffect(() => {
    if (rentalPoints) {
      // Проверяем, является ли rentalPoints массивом. Если нет, преобразуем его в массив.
      const points = Array.isArray(rentalPoints)
        ? rentalPoints
        : [rentalPoints];
      // Фильтруем точки, чтобы убедиться, что у каждой есть location
      const filteredPoints = points.filter(
        (point) =>
          point?.pointAddress?.location?.lat !== null && !objectIsEmpty(point)
      );
      setFilteredCurrentUsersRentalPoints(filteredPoints);
    } else if (!objectIsEmpty(rentalPoint)) {
      setFilteredCurrentUsersRentalPoints([rentalPoint]);
    } else {
      const filteredPoints = currentRentalPointsList.filter(
        (point) => point.pointAddress.location.lat !== null
      );
      setFilteredCurrentUsersRentalPoints(filteredPoints);
    }
  }, [currentRentalPointsList, rentalPoint]);

  const onMapLoad = (mapInstance) => {
    mapRef.current = mapInstance;
    setIsMapLoaded(true);
  };

  // Функция для установки границ карты для всех маркеров или установки зума для одного маркера
  const allPointsInMap = () => {
    if (isMapLoaded && mapRef.current) {
      const bounds = new window.google.maps.LatLngBounds();

      // Если на карте только одна точка проката, устанавливаем зум 15
      if (filteredCurrentUsersRentalPoints.length === 1) {
        const singlePoint = filteredCurrentUsersRentalPoints[0];
        if (singlePoint.pointAddress && singlePoint.pointAddress.location) {
          const singleLocation = new window.google.maps.LatLng(
            singlePoint.pointAddress.location.lat,
            singlePoint.pointAddress.location.lng
          );
          mapRef.current.setCenter(singleLocation); // Центрирование карты на одной точке
          mapRef.current.setZoom(15); // Установка уровня приближения 15
        }
      } else {
        // Если на карте несколько точек проката, устанавливаем границы, чтобы они все были видны
        filteredCurrentUsersRentalPoints.forEach((point) => {
          if (point.pointAddress && point.pointAddress.location) {
            bounds.extend(
              new window.google.maps.LatLng(
                point.pointAddress.location.lat,
                point.pointAddress.location.lng
              )
            );
          }
        });
        mapRef.current.fitBounds(bounds); // Автоматическая настройка зума и центра карты для отображения всех точек
      }
    }
  };

  // Функция для сброса выбранных точек проката
  const resetSelectedPoints = () => {
    dispatch(setCurrentRentalPoint(null)); // Сброс текущей выбранной точки проката
    dispatch(setRentalPointIdFilter([])); // Сброс фильтра точек проката
    allPointsInMap(); // Вызов функции для установки границ, чтобы показать все точки
  };

  useEffect(() => {
    if (isMapLoaded) {
      setTimeout(() => {
        allPointsInMap();
      }, 300);
    }
  }, [filteredCurrentUsersRentalPoints, isMapLoaded]);

  const zoomToMarker = (lat, lng) => {
    if (mapRef.current) {
      const zoomLevel = 15;
      mapRef.current.panTo({ lat, lng });
      mapRef.current.setZoom(zoomLevel);
    }
  };

  const handleMarkerClick = (rentalPoint) => {
    dispatch(setCurrentRentalPoint(rentalPoint));
    dispatch(setRentalPointIdFilter([rentalPoint.id]));
    zoomToMarker(
      rentalPoint.pointAddress.location.lat,
      rentalPoint.pointAddress.location.lng
    );
  };

  return isLoading && loadingStateGoogleMapApi ? (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage:
          "url(https://firebasestorage.googleapis.com/v0/b/biketripste.appspot.com/o/static%2FlogoBikeTripster%2FnapBackground.png?alt=media&token=84018d77-b848-44b0-8d8e-fa59b9394732)",
        backgroundSize: "auto 100%" /* Ширина автоматически, высота 100% */,
        backgroundRepeat: "no-repeat" /* Без повторений */,
        backgroundPosition: "center center" /* Выравнивание по центру */,
      }}
    >
      <Preloader />
    </div>
  ) : (
    <div style={{ height: "100%", width: "100%", position: "relative" }}>
      {filteredCurrentUsersRentalPoints?.length === 0 && (
        <div
          className="verySad"
          style={{
            position: "relative",
            backgroundColor: "var(--color-gray-100)",
          }}
        >
          <img
            style={{
              aspectRatio: "1/1",
              width: "56px",
            }}
            src="https://firebasestorage.googleapis.com/v0/b/biketripste.appspot.com/o/static%2FlogoBikeTripster%2FverySad.svg?alt=media&token=5fab59ea-9c21-4ea5-8226-74108f62a18a"
            alt="verySad"
          />
        </div>
      )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          top: "8px",
          right: "8px",
          zIndex: "2",
          backgroundColor: "var(--color-white)",
          padding: "8px 16px", // Отступы внутри кнопки
          cursor: "pointer", // Курсор в виде указателя для кнопки
          borderRadius: "4px", // Скругление углов кнопки
          boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.05)",
          height: "32px",
        }}
        onClick={() => {
          resetSelectedPoints();
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "8px",
          }}
        >
          Reset
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "var(--color-alarm)",
              borderRadius: "80px",
              minHeight: "24px",
              minWidth: "24px",
              aspectRatio: "1/1",
            }}
          >
            <span
              className="material-symbols-outlined"
              style={{
                color: "var(--color-white)",
                fontSize: "0.8rem",
              }}
            >
              close
            </span>
          </div>
        </div>
      </div>
      <GoogleMap
        mapContainerStyle={{ height: "100%", width: "100%" }}
        // center={location}
        zoom={10}
        onLoad={onMapLoad}
        options={{
          disableDefaultUI: true,
          zoomControl: true,
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false,
          styles: [
            {
              featureType: "poi",
              elementType: "labels",
              stylers: [{ visibility: "off" }],
            },
          ],
        }}
      >
        {filteredCurrentUsersRentalPoints.map((point) => (
          <Marker
            key={point.id}
            position={point.pointAddress.location}
            onClick={() => handleMarkerClick(point)}
            icon={{
              url: "https://firebasestorage.googleapis.com/v0/b/testbikestripster.appspot.com/o/imgStatic%2FselectedIconMapPointShadowV2.svg?alt=media&token=cd674ee7-73a5-4f3f-893d-176feb68c400",

              scaledSize: new window.google.maps.Size(80, 80),
            }}
          />
        ))}
      </GoogleMap>
    </div>
  );
};

export default GoogleMapsFilters;

// import React, { useState, useEffect, useRef } from "react";
// import { GoogleMap, Marker } from "@react-google-maps/api";
// import { useSelector, useDispatch } from "react-redux";
// import {
//   setRentalPointIdFilter,
//   setCurrentRentalPoint,
// } from "../../../slice/stateFrontGUISlice";
// import Preloader from "../../UIElements/Preloader";
// import objectIsEmpty from "../../../functions/objectIsEmpty";

// const GoogleMapsFilters = ({ rentalPoint }) => {
//   const dispatch = useDispatch();
//   const [isMapLoaded, setIsMapLoaded] = useState(false);
//   const [
//     filteredCurrentUsersRentalPoints,
//     setFilteredCurrentUsersRentalPoints,
//   ] = useState([]);
//   const mapRef = useRef(null);

//   const currentRentalPointsList = useSelector(
//     (state) => state.stateFrontGUISlice.currentRentalPointsList
//   );
//   const isLoading = useSelector((state) => state.stateFrontGUISlice.isLoading);
//   const loadingStateGoogleMapApi = useSelector(
//     (state) => state.config.loadingStateGoogleMapApi
//   );
//   // const location = useSelector(
//   //   (state) => state.stateGUISlice.newAddress.location
//   // );

//   useEffect(() => {
//     if (!objectIsEmpty(rentalPoint)) {
//       setFilteredCurrentUsersRentalPoints([rentalPoint]);
//     } else {
//       const filteredPoints = currentRentalPointsList.filter(
//         (point) => point.pointAddress.location.lat !== null
//       );
//       setFilteredCurrentUsersRentalPoints(filteredPoints);
//     }
//   }, [currentRentalPointsList, rentalPoint]);

//   const onMapLoad = (mapInstance) => {
//     mapRef.current = mapInstance;
//     setIsMapLoaded(true);
//   };

//   // Функция для установки границ карты для всех маркеров или установки зума для одного маркера
//   const allPointsInMap = () => {
//     if (isMapLoaded && mapRef.current) {
//       const bounds = new window.google.maps.LatLngBounds();

//       // Если на карте только одна точка проката, устанавливаем зум 15
//       if (filteredCurrentUsersRentalPoints.length === 1) {
//         const singlePoint = filteredCurrentUsersRentalPoints[0];
//         if (singlePoint.pointAddress && singlePoint.pointAddress.location) {
//           const singleLocation = new window.google.maps.LatLng(
//             singlePoint.pointAddress.location.lat,
//             singlePoint.pointAddress.location.lng
//           );
//           mapRef.current.setCenter(singleLocation); // Центрирование карты на одной точке
//           mapRef.current.setZoom(15); // Установка уровня приближения 15
//         }
//       } else {
//         // Если на карте несколько точек проката, устанавливаем границы, чтобы они все были видны
//         filteredCurrentUsersRentalPoints.forEach((point) => {
//           if (point.pointAddress && point.pointAddress.location) {
//             bounds.extend(
//               new window.google.maps.LatLng(
//                 point.pointAddress.location.lat,
//                 point.pointAddress.location.lng
//               )
//             );
//           }
//         });
//         mapRef.current.fitBounds(bounds); // Автоматическая настройка зума и центра карты для отображения всех точек
//       }
//     }
//   };

//   // Функция для сброса выбранных точек проката
//   const resetSelectedPoints = () => {
//     dispatch(setCurrentRentalPoint(null)); // Сброс текущей выбранной точки проката
//     dispatch(setRentalPointIdFilter([])); // Сброс фильтра точек проката
//     allPointsInMap(); // Вызов функции для установки границ, чтобы показать все точки
//   };

//   useEffect(() => {
//     if (isMapLoaded) {
//       setTimeout(() => {
//         allPointsInMap();
//       }, 300);
//     }
//   }, [filteredCurrentUsersRentalPoints, isMapLoaded]);

//   const zoomToMarker = (lat, lng) => {
//     if (mapRef.current) {
//       const zoomLevel = 15;
//       mapRef.current.panTo({ lat, lng });
//       mapRef.current.setZoom(zoomLevel);
//     }
//   };

//   const handleMarkerClick = (rentalPoint) => {
//     dispatch(setCurrentRentalPoint(rentalPoint));
//     dispatch(setRentalPointIdFilter([rentalPoint.id]));
//     zoomToMarker(
//       rentalPoint.pointAddress.location.lat,
//       rentalPoint.pointAddress.location.lng
//     );
//   };

//   return isLoading && loadingStateGoogleMapApi ? (
//     <div
//       style={{
//         height: "100%",
//         width: "100%",
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//         backgroundImage:
//           "url(https://firebasestorage.googleapis.com/v0/b/biketripste.appspot.com/o/static%2FlogoBikeTripster%2FnapBackground.png?alt=media&token=84018d77-b848-44b0-8d8e-fa59b9394732)",
//         backgroundSize: "auto 100%" /* Ширина автоматически, высота 100% */,
//         backgroundRepeat: "no-repeat" /* Без повторений */,
//         backgroundPosition: "center center" /* Выравнивание по центру */,
//       }}
//     >
//       <Preloader />
//     </div>
//   ) : (
//     <div style={{ height: "100%", width: "100%", position: "relative" }}>
//       {filteredCurrentUsersRentalPoints?.length === 0 && (
//         <div
//           className="verySad"
//           style={{
//             position: "relative",
//             backgroundColor: "var(--color-gray-100)",
//           }}
//         >
//           <img
//             style={{
//               aspectRatio: "1/1",
//               width: "56px",
//             }}
//             src="https://firebasestorage.googleapis.com/v0/b/biketripste.appspot.com/o/static%2FlogoBikeTripster%2FverySad.svg?alt=media&token=5fab59ea-9c21-4ea5-8226-74108f62a18a"
//             alt="verySad"
//           />
//         </div>
//       )}
//       <div
//         style={{
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "center",
//           position: "absolute",
//           top: "8px",
//           right: "8px",
//           zIndex: "2",
//           backgroundColor: "var(--color-white)",
//           padding: "8px 16px", // Отступы внутри кнопки
//           cursor: "pointer", // Курсор в виде указателя для кнопки
//           borderRadius: "4px", // Скругление углов кнопки
//           boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.05)",
//           height: "32px",
//         }}
//         onClick={() => {
//           resetSelectedPoints();
//         }}
//       >
//         <div
//           style={{
//             display: "flex",
//             flexDirection: "row",
//             alignItems: "center",
//             justifyContent: "space-between",
//             gap: "8px",
//           }}
//         >
//           Reset
//           <div
//             style={{
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "center",
//               backgroundColor: "var(--color-alarm)",
//               borderRadius: "80px",
//               minHeight: "24px",
//               minWidth: "24px",
//               aspectRatio: "1/1",
//             }}
//           >
//             <span
//               className="material-symbols-outlined"
//               style={{
//                 color: "var(--color-white)",
//                 fontSize: "0.8rem",
//               }}
//             >
//               close
//             </span>
//           </div>
//         </div>
//       </div>
//       <GoogleMap
//         mapContainerStyle={{ height: "100%", width: "100%" }}
//         // center={location}
//         zoom={10}
//         onLoad={onMapLoad}
//         options={{
//           disableDefaultUI: true,
//           zoomControl: true,
//           mapTypeControl: false,
//           streetViewControl: false,
//           fullscreenControl: false,
//           styles: [
//             {
//               featureType: "poi",
//               elementType: "labels",
//               stylers: [{ visibility: "off" }],
//             },
//           ],
//         }}
//       >
//         {filteredCurrentUsersRentalPoints.map((point) => (
//           <Marker
//             key={point.id}
//             position={point.pointAddress.location}
//             onClick={() => handleMarkerClick(point)}
//             icon={{
//               url: "https://firebasestorage.googleapis.com/v0/b/testbikestripster.appspot.com/o/imgStatic%2FselectedIconMapPointShadowV2.svg?alt=media&token=cd674ee7-73a5-4f3f-893d-176feb68c400",

//               scaledSize: new window.google.maps.Size(80, 80),
//             }}
//           />
//         ))}
//       </GoogleMap>
//     </div>
//   );
// };

// export default GoogleMapsFilters;
